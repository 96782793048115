import React from "react";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { CiDeliveryTruck } from "react-icons/ci";
import {
  DeliveryConfirmedContainer,
  DeliveryContainer,
} from "./DeliveryConfirmed-styled";
import CompanyAndEventData from "../../data/CompanyAndEventData";
import { Days, Months } from "../../data/DatesAndMonths";
import momentTz from "moment-timezone";
import timeZoneHeader from "../../../../../services/timezone-header";
const timezone = { ...timeZoneHeader() }
const DeliveryConfirmed = ({ passingData, task }) => {
  return (
    <DeliveryConfirmedContainer>
      <hr className="line" />

      <div className="wrapper">
        <h4>{task?.userId.companyName}</h4>
        <h3>Confirmed</h3>
        <p>Your delivery is scheduled</p>
        <hr className="for-pc" />
        <DeliveryContainer>
          <div className="furniture-delivery-icn">
            <CiDeliveryTruck className="icon" />
            <h2>{CompanyAndEventData.event}</h2>
          </div>

          <div className="duration selected-date-time">
            <div className="selected-time-range">
              <MdOutlineAccessTimeFilled className="icon" />
              <p>
                {passingData.selectedSlot?.after}-
                {passingData.selectedSlot?.before}
              </p>
            </div>

            <div className="selected-date">
              <BsFillCalendarCheckFill
                className="icon"
                style={{ width: "14px", height: "14px" }}
              />
              <p className="day">
                {momentTz(passingData.passingDate).tz(timezone.timezone).format("dddd")}, {momentTz(passingData.passingDate).tz(timezone.timezone).format("LL")}
              </p>
            </div>
            <div className="selected-time-zone">
              <p>
                {passingData.passingTimeName.slice(0, 20)}
              </p>
            </div>
          </div>
        </DeliveryContainer>
        <h5>A confirmation has been send to your email address</h5>
        <hr className="for-pc" />
      </div>
    </DeliveryConfirmedContainer>
  );
};

export default DeliveryConfirmed;
