import {
  AUTH_SAVE_DATA,
  HIDE_LOADER,
  SHOW_LOADER,
  SAVE_PROFILE_DATA,
  SAVE_SETTINGS,
  USER_LOGOUT,
  SAVE_COMMON_SERVICES,
} from './actionTypes';

export const login = payload => ({
  type: AUTH_SAVE_DATA,
  payload,
})

export const saveProfile = payload => ({
  type: SAVE_PROFILE_DATA,
  payload,
})

export const showLoader = payload => ({
  type: SHOW_LOADER,
  payload,
})

export const hideLoader = payload => ({
  type: HIDE_LOADER,
  payload,
})

export const saveSettings = payload => ({
  type: SAVE_SETTINGS,
  payload
})

export const logOutHandler = payload => ({
  type: USER_LOGOUT,
  payload
})

export const saveCommonServices = payload => ({
  type: SAVE_COMMON_SERVICES,
  payload
})