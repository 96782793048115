import { InfoWindow } from "@react-google-maps/api";
import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import personalicon from "../../assets/image/personalicon.png"
import moment from "moment";
import { KmToMiles } from "../../helpers/helperFunctions";

const InfoWindowComponent = (props) => {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const renderName = (obj) => {
        if (obj.customer && obj.customer.firstName) {
            return `${obj.customer.firstName ? obj.customer.firstName : ''} ${obj.customer.lastName ?
                obj.customer.lastName : ''}`
        }
        else if (obj.customerId) {
            return `${obj.customerId.firstName ? obj.customerId.firstName : ''} ${obj.customerId.lastName ?
                obj.customerId.lastName : ''}`
        }
        else {
            return ``
        }
    }

    const distance = (totalDist, distanceUnit) => {
        let value = totalDist;
        let distanceMetric = "KM"
        if (distanceUnit && distanceUnit === "m") {
            value = KmToMiles(value)
            distanceMetric = "Miles"
        }
        return `${value.toFixed(2)} ${distanceMetric}`
    }

    const InfoWindowDiv = ({ marker }) => {
        let data = marker;
        if (data.markerType && data.markerType === "AGENT") {
            return (<div className="infowindow">
                <Tabs className="maintablist">
                    <TabList className="maintabheading">
                        <Tab>Agent Details</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="agent-details">
                            <div className="detail-call-chat">
                                <div className="detail-address-pic">
                                    <div>
                                        <img src={personalicon} alt="profile pic" />
                                    </div>
                                    <div className="detail-address">
                                        <div className="title">
                                            {data
                                                ? data.firstName
                                                : "Sebastien Godbout"}{" "}
                                            {data ? data.lastName : ""}
                                        </div>
                                        <div className="address">
                                            <span>
                                                {data && data.teamId ? data.teamId.teamName : ""}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div >)
        }
        else {
            return (<div className="infowindow">
                <Tabs className="maintablist">
                    <TabList className="maintabheading">
                        <Tab>Task Details</Tab>
                        {data.agentId && <Tab>Agent Details</Tab>}
                    </TabList>
                    <TabPanel>
                        <div className="task-details">
                            <div className="task-field-box">
                                <div className="task-detail">
                                    <div className="task-title">
                                        <span className="unls">
                                            {data.orderType}
                                        </span>
                                        <span className="title">
                                            {`${data.position || data.position === 0 ? `${data.position + 1}.` : ``} ${renderName(data)}`}
                                        </span>
                                    </div>
                                </div>
                                <div className="task-description">
                                    <span className="description">
                                        {data && data.address
                                            ? data.address
                                            : `124 Verdun St, Montreal, H4G2Y5, Qc, Canada`}
                                    </span>
                                </div>
                            </div>
                            <div className="task-status-details">
                                {/* <span>{data.taskLength ? data.taskLength : 0} Tasks</span>
                        <span>{data.optimised ? `${data.missionDetails.totalTime} Hours` : '__ Hours'}</span>
                        <span>17 Cap</span>
                        <span>{data.optimised ? `${data.missionDetails.totalDist} Km` : '__ Km'}</span> */}
                                <span className={`infowindow-${data.taskStatus}`}>{data.taskStatus ? capitalizeFirstLetter(data.taskStatus) : "Unassigned"}</span>
                                {data.missioned && data.taskStatus !== "reached" && data.taskStatus !== "completed" && data.taskStatus !== "failed" && <span>ETA: {data.eta ? `${data.eta}` : '__ Hours'}</span>}
                                {!data.missioned && <span>ETA: {'__'}</span>}
                                {(data.taskStatus === "completed" || data.taskStatus === "failed") && <span>Completed At: {moment(data.orderCompletedDate).format("lll")}</span>}
                                <span>{data.missionDetails && (data.missionDetails.totalDist || data.missionDetails.totalDist === 0) ? `${distance(data.missionDetails.totalDist, data.distanceUnit)}` : '__ Km'}</span>
                                <span>{data.capacity || data.capacity === 0 ? `${data.capacity} ${data.capacityUnit ? data.capacityUnit : "Cap"}` : "__ Cap"}</span>
                            </div>
                        </div>
                    </TabPanel>
                    {data.agentId && <TabPanel>
                        <div className="agent-details">
                            <div className="detail-call-chat">
                                <div className="detail-address-pic">
                                    <div>
                                        <img src={personalicon} alt="profile pic" />
                                    </div>
                                    <div className="detail-address">
                                        <div className="title">
                                            {data && data.agentId
                                                ? data.agentId.firstName
                                                : "Sebastien Godbout"}{" "}
                                            {data && data.agentId ? data.agentId.lastName : ""}
                                        </div>
                                        <div className="address">
                                            <span>
                                                {data && data.teamId ? data.teamId.teamName : ""}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="chat-call-btn">
                            <div>
                                <button type="button" className="call-btn btn">Call</button>
                            </div>
                            <div>
                                <button type="button" className="chat-btn btn">Chat</button>
                            </div>
                        </div> */}
                            </div>
                            <div className="task-status-details">
                                <span className={`infowindow-${data.taskStatus}`}>{data.taskStatus ? capitalizeFirstLetter(data.taskStatus) : "Unassigned"}</span>
                                <span>ETA: {data.eta ? `${data.eta}` : '__ Hours'}</span>
                                <span>{data.optimised ? `${data.missionDetails.totalDist} Km` : '__ Km'}</span>
                                <span>{data.capacity ? `${data.capacity} ${data.capacityUnit ? data.capacityUnit : "Cap"}` : "__ Cap"}</span>
                            </div>
                        </div>
                    </TabPanel>}
                </Tabs>
            </div >)
        }
    }
    return (
        <InfoWindow
            key={props.marker.id}
            onCloseClick={() => {
                props.setSelectedCenter(null);
            }}
            position={{
                lat: props.selectedCenter.lat,
                lng: props.selectedCenter.lng
            }}
        >
            <InfoWindowDiv {...props} />
        </InfoWindow>
    )

}

export default InfoWindowComponent