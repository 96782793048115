import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import rootSagas from '../redux/rootSaga'
import reducer from '../redux/rootReducer'

const saga = createSagaMiddleware()

const middleWares = [saga, thunk]

if (process.env.NODE_ENV === "development") {
    middleWares.push(logger);
}

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(...middleWares),
    devTools: process.env.NODE_ENV !== 'production'
})



saga.run(rootSagas)

export default store;

