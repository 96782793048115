import React from "react";
import "./checkBox-styled.css";
import { EventContainer } from "../SelectDateAndTime/SelectDateAndTime-styled";
import { DetailsFormSection, FormContainer } from "./DetailsForm-styled";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { useLocation, useHistory } from "react-router-dom";
import CompanyAndEventData from "../../data/CompanyAndEventData";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Days, Months } from "../../data/DatesAndMonths";

const DetailsForm = ({ passingData }) => {
  const navigate = useHistory();
  const sendData = () => {
    navigate("/delivery-confirmed", {
      state: {
        passingDate: passingData.passingDate,
        passingTimeName: passingData.passingTimeName,
        passingTimeLabal: passingData.passingTimeLabal,
        passingStartTime: passingData.passingStartTime,
        passingEndtime: passingData.passingEndtime,
      },
    });
  };

  const navigateBack = () => {
    navigate("/select-time", {
      state: {
        passingDate: passingData.passingDate,
        passingTimeName: passingData.passingTimeName,
        passingTimeLabal: passingData.passingTimeLabal,
        passingStartTime: passingData.passingStartTime,
        passingEndtime: passingData.passingEndtime,
      },
    });
  };

  return (
    <DetailsFormSection>
      <div className="wrapper">
        <div className="back-btn">
          <BsFillArrowLeftCircleFill
            onClick={() => navigateBack()}
            className="icon"
          />
        </div>
        <hr className="line" />
        <div className="company-event">
          <EventContainer>
            <div className="company-header">
              <h4>{CompanyAndEventData.company}</h4>
              <h2>{CompanyAndEventData.event}</h2>
            </div>
            <div className="duration selected-date-time">
              <div className="selected-time-range">
                <MdOutlineAccessTimeFilled className="icon" />
                <p>
                  {passingData.passingStartTime}
                  {passingData.passingStartTime > "12" ? "pm" : "am"}-
                  {passingData.passingEndtime}
                  {passingData.passingEndtime > "12" ? "pm" : "am"}
                </p>
              </div>

              <div className="selected-date">
                <BsFillCalendarCheckFill
                  className="icon"
                  style={{ width: "14px", height: "14px" }}
                />
                {Days.map((item) => {
                  return (
                    <p className="day">
                      {item.slice(0, 2) === passingData.passingDate.slice(0, 2)
                        ? item + ","
                        : ""}
                    </p>
                  );
                })}
                {Months.map((item) => {
                  return (
                    <p>
                      {item.slice(0, 3) === passingData.passingDate.slice(4, 7)
                        ? item
                        : ""}
                    </p>
                  );
                })}
                <p className="date-num">
                  {passingData.passingDate.slice(8, 10)} ,{" "}
                  {passingData.passingDate.slice(11, 15)}
                </p>
              </div>

              <div className="selected-time-zone">
                <p>
                  {passingData.passingTimeName.slice(0, 20)} &
                  {passingData.passingTimeLabal.slice(0, 11)}
                </p>
              </div>
            </div>
          </EventContainer>
        </div>

        <FormContainer>
          <h4>Enter Details</h4>
          <form action="">
            <div className="input-area">
              <label for="name">Name</label>
              <input type="text" id="name" name="name" />
            </div>
            <div className="input-area">
              <label for="email">Email</label>
              <input type="text" id="email" name="email" />
            </div>

            <div className="guests">
              <button>Add Guests</button>
              <p>Notify additional persons of the scheduled delivery</p>
            </div>

            <div className="check-box-area">
              <p>Do you need Assembly and Installation service?</p>
              <div className="check-boxx">
                <p id="no">No</p>
                <label class="toggler-wrapper style-1">
                  <input type="checkbox" />
                  <div class="toggler-slider">
                    <div class="toggler-knob"></div>
                  </div>
                </label>
                <p id="yes">Yes</p>
              </div>
            </div>

            <div className="text-area">
              <label for="comment">
                Please share anything that will help prepare for your delivery
              </label>

              <textarea
                id="comment"
                name="comment"
                rows="4"
                cols="50"
              ></textarea>
            </div>
            <button className="delivery-btn" onClick={() => sendData()}>
              Schedule Delivery
            </button>
          </form>
        </FormContainer>
      </div>
    </DetailsFormSection>
  );
};

export default DetailsForm;
