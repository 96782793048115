import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Card, Row, Col, Form, InputGroup, FormControl, Button, Dropdown, Modal, Container } from 'react-bootstrap';
import logo from '../../assets/image/logo.png';
import nexticon from '../../assets/image/nexticon.png';
const Deliveryinfo = () => {
  return (
    <div>
      <div className="signuptopbar">
        <Container fluid>
          <p>Already a member?  <Link>Sign in</Link></p>
        </Container>
      </div>
      <div className="delivery-form">
        <div className="delivery-heading">
          <img src={logo} alt="logo" />
          <p>Wellcome to the futur of the last mile delivery!</p>
        </div>
        <div className="subheading">
          We just send you a verify code. Check your inbox to get it.
        </div>

        <div className="delivery-form-box">

          <div className="counter-box">
            <Form>
              <Form.Control type="text" value="5" />
              <Form.Control type="text" value="7" />
              <Form.Control type="text" value="8" />
              <Form.Control type="text" value="6" />
            </Form>
          </div>
          <div className="submitbtn">
            <button type="submit" class="btn btn-primary"><img src={nexticon} alt="icon" />Continue</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deliveryinfo;