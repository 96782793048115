import { USER_ROLES } from "../../helpers/configDefaults";
import {
    SAVE_STRIPE_PLANS_REDUX,
    SAVE_USER_REDUX,
    SAVE_USER_COUNT_REDUX,
    SAVE_STRIPE_TRANSACTIONS_REDUX,
    CUSTOM_SAVE_USER_REDUX,
    MULTI_TYPE_SAVE_USER_REDUX
} from "./actionTypes";

let initialState = {
    userList: [],
    customerList: [],
    merchantList: [],
    agentList: [],
    agentCount: 0,
    stripePlans: [],
    pageCount: 1,
    pageLimit: 10,
    transactions: []
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER_REDUX:
            let users = state.userList;
            if (action.payload.role === USER_ROLES.AGENT) {
                users = state.agentList
            }
            else if (action.payload.role === USER_ROLES.MERCHANT) {
                users = state.merchantList
            }
            else if (action.payload.role === USER_ROLES.CUSTOMER) {
                users = state.customerList
            }
            let userList = JSON.parse(JSON.stringify(users))
            if (action.payload.createApi) {
                delete action.payload.createApi;
                if (action.payload.sortBy) {
                    let split = action.payload.sortBy.split(":");
                    if (split[1] === "desc") {
                        if (action.payload.currentPage === 1) {
                            userList.unshift(action.payload.user)
                            if (userList.length > action.payload.pageLimit) {
                                userList.splice(action.payload.pageLimit, 1)
                            }
                        }
                    }
                    else {
                        if (action.payload.currentPage === action.payload.pageCount) {
                            if (userList.length < action.payload.pageLimit) {
                                userList.push(action.payload.user)
                            }
                        }
                    }
                }

            }
            else if (action.payload.editApi) {
                delete action.payload.editApi;
                let userIndex = userList.findIndex(i => {
                    return i.id === action.payload.user.id
                })
                userList[userIndex] = action.payload.user
            }
            else {
                userList = action.payload.results || [];
            }
            let dataToReturn = {
                ...state,
                agentList: userList,
                ...(action.payload.totalPages && !action.payload.saveLimit && { pageCount: action.payload.totalPages }),
                ...(action.payload.limit && !action.payload.saveLimit && { pageLimit: action.payload.limit })
            }
            if (action.payload.role === USER_ROLES.AGENT) {
                dataToReturn = {
                    ...state,
                    agentList: userList,
                    ...(action.payload.totalPages && !action.payload.saveLimit && { pageCount: action.payload.totalPages }),
                    ...(action.payload.limit && !action.payload.saveLimit && { pageLimit: action.payload.limit })
                }
            }
            else if (action.payload.role === USER_ROLES.MERCHANT) {
                dataToReturn = {
                    ...state,
                    merchantList: userList,
                    ...(action.payload.totalPages && !action.payload.saveLimit && { pageCount: action.payload.totalPages }),
                    ...(action.payload.limit && !action.payload.saveLimit && { pageLimit: action.payload.limit })
                }
            }
            else if (action.payload.role === USER_ROLES.USER || action.payload.role === USER_ROLES.ADMIN) {
                dataToReturn = {
                    ...state,
                    userList: userList,
                    ...(action.payload.totalPages && !action.payload.saveLimit && { pageCount: action.payload.totalPages }),
                    ...(action.payload.limit && !action.payload.saveLimit && { pageLimit: action.payload.limit })
                }
            }
            else if (action.payload.role === USER_ROLES.CUSTOMER) {
                dataToReturn = {
                    ...state,
                    customerList: userList,
                    ...(action.payload.totalPages && !action.payload.saveLimit && { pageCount: action.payload.totalPages }),
                    ...(action.payload.limit && !action.payload.saveLimit && { pageLimit: action.payload.limit })
                }
            }
            return dataToReturn
        case SAVE_STRIPE_PLANS_REDUX:
            return {
                ...state,
                stripePlans: action.payload.plansPrices
            }
        case CUSTOM_SAVE_USER_REDUX:
            let dataToReturnCustom = {
                ...state,
            }
            dataToReturnCustom[action.key] = action[action.key]
            return dataToReturnCustom
        case SAVE_USER_COUNT_REDUX:
            return {
                ...state,
                agentCount: action.payload.result
            }
        case SAVE_STRIPE_TRANSACTIONS_REDUX:
            return {
                ...state,
                transactions: action.payload.charges && action.payload.charges.data ? action.payload.charges.data : []
            }
        case MULTI_TYPE_SAVE_USER_REDUX:
            let usersList = action.payload?.results?.filter(u => u.role === USER_ROLES.USER)
            let merchantsList = action.payload?.results?.filter(u => u.role === USER_ROLES.MERCHANT)
            // let agentsList = action.payload?.results?.filter(u => u.role === USER_ROLES.AGENT)
            return {
                ...state,
                userList: usersList,
                merchantList: merchantsList,
                // agentList: agentsList,
                userMerchantList: [...usersList, ...merchantsList]
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;