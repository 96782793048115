import {
    SAVE_CUSTOMER_LIST, SAVE_DASHBOARD_METRICS, SAVE_INVOICE_LIST, SAVE_INVOICE_TASK_LIST, SAVE_PAYMENT_LIST
} from './actionTypes';

export const saveCustomerList = payload => {
    return {
        type: SAVE_CUSTOMER_LIST,
        payload
    }
}
export const saveTaskList = payload => {
    return {
        type: SAVE_INVOICE_TASK_LIST,
        payload
    }
}
export const saveInvoiceList = payload => {
    return {
        type: SAVE_INVOICE_LIST,
        payload
    }
}
export const savePaymentList = payload => {
    return {
        type: SAVE_PAYMENT_LIST,
        payload
    }
}
export const saveDashbaordMetrics = payload => {
    return {
        type: SAVE_DASHBOARD_METRICS,
        payload
    }
}