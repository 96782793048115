import { useState } from "react";
import momentTz from "moment-timezone"
import righticon from "../../../../assets/image/arrow.png"
import lefticon from "../../../../assets/image/arrow.png"
import { FiSearch } from "react-icons/fi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import appDefaults from "../../../../helpers/app-defaults";
import searchicon from "../../../../assets/image/searchicon.png"
import { Button, Form, FormControl } from "react-bootstrap";

const TopBar = (props) => {
    let dateText = () => {
        let startDate = props.currentDate;
        if (props.viewType === appDefaults.VIEWS.PLANNER_CONTROL) {
            startDate = props.date.startDate
        }
        if (props.viewType === appDefaults.VIEWS.SUPERVISE_CONTROL || props.viewType === appDefaults.VIEWS.LIST_VIEW) {
            return (<span>
                {`${momentTz(startDate).format("LL")}`}
            </span>)
        }
        else {
            return (<span>
                {`${momentTz(startDate).format("MMMM DD")}${props.viewType === appDefaults.VIEWS.PLANNER_CONTROL ? ` - ${momentTz(props.date.endDate).format("LL")}` : ""}`}
            </span>)
        }
    }
    let leftClickHandler = () => {
        let type = "W";
        if (props.viewType === appDefaults.VIEWS.SUPERVISE_CONTROL) {
            type = "D";
            props.handleArrowClickS("back")
        }
        else if (props.viewType === appDefaults.VIEWS.LIST_VIEW) {
            type = "D";
            props.handleArrowClickS("back")
        }
        props.setDirections(null)
        props.setDate({
            startDate: momentTz(props.date.startDate).subtract(1, type).startOf(type),
            endDate: momentTz(props.date.startDate).subtract(1, type).endOf(type)
        })
        props.dateRef.current = {
            startDate: momentTz(props.date.startDate).subtract(1, type).startOf(type),
            endDate: momentTz(props.date.startDate).subtract(1, type).endOf(type)
        }
    }
    let rightClickHandler = () => {
        let type = "W";
        if (props.viewType === appDefaults.VIEWS.SUPERVISE_CONTROL) {
            type = "D";
            props.handleArrowClickS("forward")
        }
        else if (props.viewType === appDefaults.VIEWS.LIST_VIEW) {
            type = "D";
            props.handleArrowClickS("forward")
        }
        props.setDirections(null)
        props.setDate({
            startDate: momentTz(props.date.startDate).add(1, type).startOf(type),
            endDate: momentTz(props.date.startDate).add(1, type).endOf(type)
        })
        props.dateRef.current = {
            startDate: momentTz(props.date.startDate).add(1, type).startOf(type),
            endDate: momentTz(props.date.startDate).add(1, type).endOf(type)
        }
    }
    
    const [isHoveringcreate, setIsHoveringcreate] = useState(false);

    const handleMouseOvercreate = () => {
        setIsHoveringcreate(true);
    };

    const handleMouseOutcreate = () => {
        setIsHoveringcreate(false);
    };

    return (<div className="top-bar-container">
        <div className="inner-container">
            <div className="button-container">
                <div className="buttons" onClick={leftClickHandler}>
                    <img style={{ transform: "scaleX(-1)" }} src={lefticon} alt={"left"} />
                </div>
                <div className="buttons" onClick={rightClickHandler}>
                    <img src={righticon} alt={"right"} />
                </div>
            </div>
            <div className="main-container">
                <div className="date-container">
                    {dateText()}
                </div>
                {props.viewType !== appDefaults.VIEWS.LIST_VIEW && <div className="right-container">
                    <div className="search-box">
                        <div className="searchbar">
                            <Form className="d-flex">
                                <FormControl
                                    type="search"
                                    onChange={(e) => {
                                        if (!e.target.value) {
                                            props.setSearchData({});
                                            props.setShowSearchComponent(false);
                                        }
                                        props.setSearchText(e.target.value)
                                    }}
                                    placeholder="Search Everything..."
                                    className="me-2"
                                    aria-label="Search"
                                />
                                <Button className="searchicon">
                                    <img src={searchicon} alt="searchicon" />
                                </Button>
                            </Form>
                        </div>
                    </div>
                    <div className="create-area" onMouseOver={handleMouseOvercreate}
                        onMouseOut={handleMouseOutcreate} onClick={() => { props.setButtonText("Create Task"); props.setCreateTask(true); props.openSidebar() }} >
                        {isHoveringcreate && (
                            <div className="create-text">
                                <p>Add tasks or import data</p>
                            </div>
                        )}
                        <AiOutlinePlusCircle className="create-icon" />
                        <p>Create</p>
                    </div>
                </div>}
            </div>
        </div>
    </div>)
}

export default TopBar