// TODO: Resolve lint issues
/* eslint-disable */
import {
    put,
    takeLatest,
    all,
    call
} from 'redux-saga/effects'

/********************* ACTION TYPE ********************* */
import { CREATE_EXTENSION_API, DELETE_EXTENSION_API, EDIT_EXTENSION_API, GET_EXTENSION_LIST, GET_USER_EXTENSIONS } from './actionTypes';

/*************************** ACTIONS *******************/
import { saveExtensions, userExtensions } from './actions';
import { apiSagaHandler } from '../network/saga';
import { errorReducerHandler } from '../alert/actions'

const USER_API_URL = "users/"
const API_URL = "extensions/"
/************************ USER SAGA *******************************/
function* saga() {
    yield all([
        call(createExtensionApi),
        call(editExtensionApi),
        call(deleteExtensionApi),
        call(userExtensionsApi),
        call(getExtensionListApi)
    ]);
}

function* userExtensionsApi() {
    yield takeLatest(GET_USER_EXTENSIONS, userExtensionHandler)
}
function* getExtensionListApi() {
    yield takeLatest(GET_EXTENSION_LIST, getExtensionListHandler)
}

function* createExtensionApi() {
    yield takeLatest(CREATE_EXTENSION_API, createExtensionHandler)
}

function* deleteExtensionApi() {
    yield takeLatest(DELETE_EXTENSION_API, deleteExtensionHandler)
}

function* editExtensionApi() {
    yield takeLatest(EDIT_EXTENSION_API, editExtensionHandler)
}

function* userExtensionHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${USER_API_URL}extensions/${payload.userId}`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield put(userExtensions(apiRes))
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* getExtensionListHandler(data) {
    let { payload, resCall } = data?.data;
    if (!payload.searchText) delete payload.searchText
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield put(saveExtensions(apiRes))
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* createExtensionHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.createApi = true;
        console.log({ apiRes })
        yield put(saveExtensions(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* deleteExtensionHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}/${payload.id}`,
        "auth": true,
        "method": "DELETE",
        "localApi": false,
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.deleteApi = true;
        yield put(saveExtensions(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* editExtensionHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}/${payload.id}`,
        "auth": true,
        "method": "PATCH",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.editApi = true;
        yield put(saveExtensions(apiRes))
        resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

export default saga;
