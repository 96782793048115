import React from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
} from "react-bootstrap";
import "./Import.scss";
import closeicon from "../../assets/image/close-icon.png";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
const ImportData = (props) => {
  let userData = useSelector((state) => state.auth.user)
  const backHandler = () => {
    props.setStep(props.step - 1);
  };

  const TableHead = () => {
    return (<thead>
      <tr>
        {props.importData.map(i => {
          return <th>{i.name}</th>
        })}
      </tr>
    </thead>)
  }

  const TableBody = () => {
    let bodyArray = [];
    for (let i = 0; i < 5; i++) {
      let tdArray = [];
      for (let j = 0; j < props.importData.length; j++) {
        tdArray.push({ keyName: props.importData[j].name, dataValue: props.importData[j].data[i], property: props.importData[j].value })
      }
      bodyArray.push(tdArray)
    }
    return (<tbody>
      {bodyArray.map(i => {
        return (<tr>
          {i.map(j => {
            return (<td>{j.dataValue}</td>)
          })}
        </tr>)
      })}
    </tbody>)
  }

  const escapeRegex = (str) => {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "").replace(/ /g, "");
  };


  const submitTasksFromSheet = async () => {
    props.setLoading(true)
    let newImportTask = JSON.parse(JSON.stringify(props.importData))
    newImportTask = newImportTask.map(i => {
      i.data = [];
      return i
    })
    let rowArray = []
    for (let i = 0; i < props.rows; i++) {

      let columnArray = [];
      for (let j = 0; j < props.importData.length; j++) {
        columnArray.push({ keyName: props.importData[j].name, dataValue: props.importData[j].data[i], property: props.importData[j].value })
      }
      rowArray.push(columnArray)
    }

    let taskArray = []
    for (let array of rowArray) {
      let obj = {}
      for (let key of array) {
        obj[key["property"]] = key["dataValue"]
      }
      taskArray.push(obj)
    }

    let tasks = [], invalidTasks = [];

    for (let task of taskArray) {
      if (!task["customerPhoneNumber"] || !task["address"] || !task["merchantEmail"] || !task["orderType"] /*|| !task["deliveryDate"]*/) {
        invalidTasks = mapInvalidTasks(newImportTask, invalidTasks, task)
      }
      else {
        let findMerchant = props.merchantList.find(i => { return i.email === task["merchantEmail"] });
        if (userData && userData.role === 1) {
          findMerchant = props.userList.find(i => { return i.email === task["merchantEmail"] })
        }
        if (findMerchant) {
          let defaultTaskDuration = props.defaultDurationTime;
          let orderType = "D";
          if (props.taskSettingData && (props.taskSettingData.defaultTaskDuration || props.taskSettingData.defaultTaskDuration === 0)) {
            defaultTaskDuration = props.taskSettingData.defaultTaskDuration
          }
          if (task["orderType"]) {
            if (task["orderType"] === "Drop") {
              orderType = "D";
              if (props.taskSettingData && (props.taskSettingData.defaultTaskDuration || props.taskSettingData.defaultTaskDuration === 0)) {
                defaultTaskDuration = props.taskSettingData.defaultTaskDuration
              }
            }
            else if (task["orderType"] === "Drop and Install" || task["orderType"] === "Drop & Install") {
              orderType = "DI";
              if (props.taskSettingData && (props.taskSettingData.defaultInstallationTime || props.taskSettingData.defaultInstallationTime === 0)) {
                defaultTaskDuration = props.taskSettingData.defaultInstallationTime
              }
            }
            else if (task["orderType"] === "Pick Up" || task["orderType"] === "Pick up") {
              orderType = "P";
              if (props.taskSettingData && (props.taskSettingData.defaultTaskDuration || props.taskSettingData.defaultTaskDuration === 0)) {
                defaultTaskDuration = props.taskSettingData.defaultTaskDuration
              }
            }
            else if (task["orderType"] === "Exchange") {
              orderType = "E";
              if (props.taskSettingData && (props.taskSettingData.defaultInstallationTime || props.taskSettingData.defaultInstallationTime === 0)) {
                defaultTaskDuration = props.taskSettingData.defaultInstallationTime
              }
            }
          };
          let location;
          if (task["longitude"] && task["latitude"]) {
            location = {
              lat: Number(task["latitude"]),
              lng: Number(task["longitude"])
            }
          }
          else {
            task["address"] = task["address"].replace(/[\-\[\]\/\{\}\(\)\*\/\#\+\?\.\\\^\$\|]/g, "")
            let response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${task["address"]}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
            console.log("responseresponse", response)
            if (response && response.data) {
              location = {
                lat: response.data.results[0].geometry.location.lat,
                lng: response.data.results[0].geometry.location.lng
              }
            }
          }

          if (location) {

            let data = {
              orderType: orderType,
              merchantId: findMerchant.id,
              notes: task["taskNotes"],
              orderDate: task["deliveryDate"] ? `${moment(task["deliveryDate"]).format()}` : null,
              after: task["deliveryAfterTime"],
              afterTimePicker: null,
              before: task["deliveryBeforeTime"],
              beforeTimePicker: null,
              scheduledTimePicker: null,
              scheduledTime: task["scheduledTime"],
              afterSeconds: 0,
              beforeSeconds: 0,
              scheduledTimeSeconds: 0,
              durationTime: task["taskDuration"],
              firstName: task["customerFirstName"],
              lastName: task["customerLastName"],
              ISO: "ca",
              countryCode: "1",
              phoneNumber: escapeRegex(task["customerPhoneNumber"].toString()),
              email: task["customerEmail"],
              address: task["address"],
              apartment: task["apt"],
              location: location,
              capacity: task["capacity"],
              price: Number(task["price"]) || 0
            }
            if (userData && userData.role === 1) {
              data.userId = findMerchant.id
            }
            if (data.scheduledTime) {
              let time = data.scheduledTime.split(":");
              data.scheduledTimeSeconds = Number((time[0] * 3600) + (time[1] * 60))
            }
            if (data.before) {
              let time = data.before.split(":");
              data.beforeSeconds = Number((time[0] * 3600) + (time[1] * 60))
            }
            if (data.after) {
              let time = data.after.split(":");
              data.afterSeconds = Number((time[0] * 3600) + (time[1] * 60))
            }
            if (!data.durationTime) {
              data.durationTime = defaultTaskDuration;
            }
            if (!data.capacity) {
              data.capacity = props.taskSettingData && (props.taskSettingData.defaultTaskCapacity || props.taskSettingData.defaultTaskCapacity === 0) ? props.taskSettingData.defaultTaskCapacity : props.defaultMissionCapacity;
              data.capacityUnit = props.regionalSettingData && (props.regionalSettingData.volumeUnit) ? props.regionalSettingData.volumeUnit : "m3";
            }
            tasks.push(data)
          }
          else { invalidTasks = mapInvalidTasks(newImportTask, invalidTasks, task) }
        }
        else {
          invalidTasks = mapInvalidTasks(newImportTask, invalidTasks, task)
        }
      }

    }
    if (!tasks.length) {
      if (invalidTasks.length) props.setImportData(invalidTasks)
      props.setLoading(false)
      return props.errorAlert("There are some rows with invalid values please check all values!!!")
    }
    let response = await props.taskService.createMultipleTasks({ tasks });
    let successMsg = "Task imported successfully"
    if (response && response.data && response.data.success) {
      if (!invalidTasks.length) {
        successMsg = "Task imported successfully"

        props.getTaskListDashboard();
        props.setLoading(false)
        props.setImportToggle(false)
      }
      else {
        props.setLoading(false)
        props.errorAlert("Some tasks were not imported due to invalid values!!!")
        props.setImportData(invalidTasks)
        return
      }
    }
    else {
      props.setLoading(false)
      props.errorAlert("Some tasks were not imported due to invalid values!!!")
      return
    }
    props.setLoading(false)
    return props.successAlert(successMsg)
  }

  const mapInvalidTasks = (newImportTask, invalidTasks, task) => {
    for (let column of newImportTask) {
      column.data.push(task[column["value"]])
    }
    return newImportTask
  }
  return (
    <>
      <div className="openimport-popup">
        <div className="addimport-popup">
          <div className="addimport-slide datafile-popup">
            <div className="customer-import">
              <Button className="import-close"
                onClick={() => props.setImportToggle(false)}>
                <img src={closeicon} alt="icon" />
              </Button>
            </div>
            <div className="addimport-filebox">
              <div className="customer-form">
                <div className="import-pheading">
                  <div className="import-heading">
                    <h2>Preview import data</h2>
                    <p>
                      Please validate the data based on the 5 first row of your file.
                    </p>
                  </div>
                  <div className="import-breadcurm">
                    <div className="breadcurm">
                      <Link className="fill">Upload</Link>
                      <MdOutlineArrowForwardIos />
                      <Link className="fill">Match</Link>
                      <MdOutlineArrowForwardIos />
                      <Link className="active">Complete</Link>
                    </div>
                    <div className="bkbtn">
                      <Button onClick={backHandler} className="backbtn">Go Back</Button>
                      <Button className="nextbtn" onClick={submitTasksFromSheet}>Start import</Button>
                    </div>
                  </div>
                </div>
                <div className="import-datafile">
                  <Table>
                    <TableHead />
                    <TableBody />
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportData;
