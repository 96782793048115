import React, { useState, useEffect, memo } from "react";
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer, InfoWindow, DrawingManager } from '@react-google-maps/api';
import AssignImage from "../../assets/image/started-icon.png"
import "./Planner.scss"
import map_options from "../dashboard/map_options";
import moment from "moment";
import personalicon from "../../assets/image/personalicon.png"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";


const MyMapComponent = (props) => {
    const MarkerComponent = (props) => {
        let marker = props.marker;
        if (marker.lat && marker.lng) {
            return (<Marker
                key={marker.id}
                position={{ lat: marker.lat, lng: marker.lng }}
                label={{
                    // text: alphabets(marker.index),
                    text: marker.showMarkerLabel && marker.orderType && marker.orderType !== "SL" && marker.orderType !== "EL" ? (marker.markerIndex).toString() : " ",
                    color: 'white',
                }}
                // animation={window.google.maps.Animation.DROP}
                onClick={(e) => {
                    markerClickHandler(e, props, marker)
                }}
                icon={{
                    url: renderMarkerImage(marker),
                    // size: { width: 45, height: 70 },
                    // anchor: { x: 10, y: 35 },
                    ...(!marker.markerImage && { scaledSize: { width: 22, height: 30 } }),
                }}
            >
                {props.selectedCenter && ((props.selectedCenter.from === "marker" && marker.id === props.selectedCenter.id) || (props.selectedCenter.from === "task" && marker.id === props.selectedCenter.id)) && (
                    <InfoWindowComponent {...props} />
                )}
            </Marker>)
        }
        else {
            return null;
        }
    }

    const renderMarkerImage = (marker) => {
        let markerImage = AssignImage;
        if (marker.markerImage) {
            markerImage = marker.markerImage
        }
        if (marker.selected && marker.selectedMarkerImage) {
            markerImage = marker.selectedMarkerImage
        }
        return markerImage
    }

    const markerClickHandler = (e, props, marker) => {
        if (!marker.notClickable) {
            if (e && (!e.domEvent.ctrlKey && !e.domEvent.metaKey)) {
                // props.setDefaultCenter(marker);
                // props.setSelectedCenter({ ...marker, from: "marker" });
                // props.taskDetailHandler(marker);
            }
            props.taskSingleClickHandler({ ...props.taskClickProps, e: e.domEvent, task: marker, i: null })
        }
    }

    const InfoWindowComponent = (props) => {
        return (
            <InfoWindow
                key={props.marker.id}
                onCloseClick={() => {
                    props.setSelectedCenter(null);
                }}
                position={{
                    lat: props.selectedCenter.lat,
                    lng: props.selectedCenter.lng
                }}
            >
                <InfoWindowDiv {...props} />
            </InfoWindow>
        )
    }
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const renderName = (obj) => {
        if (obj.customer && obj.customer.firstName) {
            return `${obj.customer.firstName ? obj.customer.firstName : ''} ${obj.customer.lastName ?
                obj.customer.lastName : ''}`
        }
        else if (obj.customerId) {
            return `${obj.customerId.firstName ? obj.customerId.firstName : ''} ${obj.customerId.lastName ?
                obj.customerId.lastName : ''}`
        }
        else {
            return ``
        }
    }

    const InfoWindowDiv = ({ marker }) => {
        let data = marker;
        if (data.markerType && data.markerType === "AGENT") {
            return (<div className="infowindow">
                <Tabs className="maintablist">
                    <TabList className="maintabheading">
                        <Tab>Agent Details</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="agent-details">
                            <div className="detail-call-chat">
                                <div className="detail-address-pic">
                                    <div>
                                        <img src={personalicon} alt="profile pic" />
                                    </div>
                                    <div className="detail-address">
                                        <div className="title">
                                            {data
                                                ? data.firstName
                                                : "Sebastien Godbout"}{" "}
                                            {data ? data.lastName : ""}
                                        </div>
                                        <div className="address">
                                            <span>
                                                {data && data.teamId ? data.teamId.teamName : ""}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div >)
        }
        else {
            return (<div className="infowindow">
                <Tabs className="maintablist">
                    <TabList className="maintabheading">
                        <Tab>Task Details</Tab>
                        {data.agentId && <Tab>Agent Details</Tab>}
                    </TabList>
                    <TabPanel>
                        <div className="task-details">
                            <div className="task-field-box">
                                <div className="task-detail">
                                    <div className="task-title">
                                        <span className="unls">
                                            {data.orderType}
                                        </span>
                                        <span className="title">
                                            {`${data.position || data.position === 0 ? `${data.position + 1}.` : ``} ${renderName(data)}`}
                                        </span>
                                    </div>
                                </div>
                                <div className="task-description">
                                    <span className="description">
                                        {data && data.address
                                            ? data.address
                                            : `124 Verdun St, Montreal, H4G2Y5, Qc, Canada`}
                                    </span>
                                </div>
                            </div>
                            <div className="task-status-details">
                                {/* <span>{data.taskLength ? data.taskLength : 0} Tasks</span>
                        <span>{data.optimised ? `${data.missionDetails.totalTime} Hours` : '__ Hours'}</span>
                        <span>17 Cap</span>
                        <span>{data.optimised ? `${data.missionDetails.totalDist} Km` : '__ Km'}</span> */}
                                <span className={`infowindow-${data.taskStatus}`}>{data.taskStatus ? capitalizeFirstLetter(data.taskStatus) : "Unassigned"}</span>
                                {data.missioned && data.taskStatus !== "reached" && data.taskStatus !== "completed" && data.taskStatus !== "failed" && <span>ETA: {data.eta ? `${data.eta}` : '__ Hours'}</span>}
                                {!data.missioned && <span>ETA: {'__'}</span>}
                                {(data.taskStatus === "completed" || data.taskStatus === "failed") && <span>Completed At: {moment(data.orderCompletedDate).format("lll")}</span>}
                                <span>{data.missionDetails && (data.missionDetails.totalDist || data.missionDetails.totalDist === 0) ? `${data.missionDetails.totalDist} Km` : '__ Km'}</span>
                                <span>{data.capacity || data.capacity === 0 ? `${data.capacity} ${data.capacityUnit ? data.capacityUnit : "Cap"}` : "__ Cap"}</span>
                            </div>
                        </div>
                    </TabPanel>
                    {data.agentId && <TabPanel>
                        <div className="agent-details">
                            <div className="detail-call-chat">
                                <div className="detail-address-pic">
                                    <div>
                                        <img src={personalicon} alt="profile pic" />
                                    </div>
                                    <div className="detail-address">
                                        <div className="title">
                                            {data && data.agentId
                                                ? data.agentId.firstName
                                                : "Sebastien Godbout"}{" "}
                                            {data && data.agentId ? data.agentId.lastName : ""}
                                        </div>
                                        <div className="address">
                                            <span>
                                                {data && data.teamId ? data.teamId.teamName : ""}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="chat-call-btn">
                            <div>
                                <button type="button" className="call-btn btn">Call</button>
                            </div>
                            <div>
                                <button type="button" className="chat-btn btn">Chat</button>
                            </div>
                        </div> */}
                            </div>
                            <div className="task-status-details">
                                <span className={`infowindow-${data.taskStatus}`}>{data.taskStatus ? capitalizeFirstLetter(data.taskStatus) : "Unassigned"}</span>
                                <span>ETA: {data.eta ? `${data.eta}` : '__ Hours'}</span>
                                <span>{data.optimised ? `${data.missionDetails.totalDist} Km` : '__ Km'}</span>
                                <span>{data.capacity ? `${data.capacity} ${data.capacityUnit ? data.capacityUnit : "Cap"}` : "__ Cap"}</span>
                            </div>
                        </div>
                    </TabPanel>}
                </Tabs>
            </div >)
        }
    }


    const RenderDirections = memo((props) => {
        let bookMarks = props.bookMarks;
        let datedBookMarks = props.datedBookMarks;
        let pendingBookMarks = props.pendingBookMarks;
        let result = [];
        if ((bookMarks && bookMarks.length) || (datedBookMarks && datedBookMarks.length) || (pendingBookMarks && pendingBookMarks.length)) {
            let markerResult = RenderMarkers(props);
            result = [...result, ...markerResult]
            // console.log("resultresultresult", result)
        }
        return result
    })

    const RenderMarkers = (props) => {
        let markers = [];
        // let oldBookmarks = [];
        if (props.bookMarks) {
            markers = [...markers, ...props.bookMarks]
        }
        if (props.datedBookMarks) {
            markers = [...markers, ...props.datedBookMarks]
        }
        if (props.pendingBookMarks) {
            markers = [...markers, ...props.pendingBookMarks]
        }
        let markerIndex = 1
        return (
            markers &&
            // markers.length && (!missionDirection.length || missionDirection.length === 1) &&
            markers.length &&
            markers.map((marker, i) => {
                if (!!marker) {
                    let markerProps = { ...props }
                    markerProps.marker = marker;
                    markerProps.marker.index = i;
                    markerProps.marker.markerIndex = markerIndex;
                    // markerProps.marker.showMarkerLabel = showMarkerLabel;
                    if (marker.orderType && marker.orderType !== "SL" && marker.orderType !== "EL" && markerProps.marker.showMarkerLabel) markerIndex += 1;
                    return (
                        <MarkerComponent key={markerProps.marker.index} {...markerProps} />
                    );
                }
            })
        )
    }

    const mapContainerStyle = {
        width: '100%',
        height: '100%'
    };

    const onLoad = map => {
        props.setMap(map)
    }

    const onUnmount = map => {
        props.setMap(null)
    }
    const onPolygonLoad = drawingManager => {
        console.log(drawingManager)
    }

    const onPolygonComplete = polygon => {
        console.log(polygon)
        let polygonBounds = polygon.getPath();
        let bounds = [];
        for (let i = 0; i < polygonBounds.length; i++) {
            let point = {
                lng: polygonBounds.getAt(i).lng(),
                lat: polygonBounds.getAt(i).lat()
            };
            bounds.push(point);
        }
        props.getTaskList(null, null, {
            coordinates: JSON.stringify(bounds)
        }, true)
        polygon.setPaths([]);
    }

    const symbolOne = {
        path: "M -2,0 0,-2 2,0 0,2 z",
        strokeColor: "#F00",
        fillColor: "#F00",
        fillOpacity: 1,
    };
    const symbolTwo = {
        path: "M -1,0 A 1,1 0 0 0 -3,0 1,1 0 0 0 -1,0M 1,0 A 1,1 0 0 0 3,0 1,1 0 0 0 1,0M -3,3 Q 0,5 3,3",
        strokeColor: "#00F",
        rotation: 45,
    };

    const symbolThree = {
        path: "M -2,-2 2,2 M 2,-2 -2,2",
        strokeColor: "#292",
        strokeWeight: 4,
    };
    return (
        <><GoogleMap
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={map_options}
            mapContainerStyle={mapContainerStyle}
            center={props.defaultCenter}
            clickableIcons={false}
            zoom={15}
        // defaultOptions={{ styles: mapStyles }}
        >
            <RenderDirections key={"renderdirections"} {...props} />
            {/* <DrawingManager
                onLoad={onPolygonLoad}
                onPolygonComplete={onPolygonComplete}
                drawingMode={"polygon"}
                options={{
                    drawingControl: false,
                    polygonOptions: {
                        fillColor: "#ffffff",
                        strokeColor: "#00FF55",
                        strokeWeight: 5,
                        icons: [{
                            icon: symbolOne,
                            offset: "0%",
                            repeat: "100%"
                        }, {
                            icon: symbolThree,
                            offset: "0%",
                            repeat: "100%"
                        }],
                    }

                }}
            /> */}
        </GoogleMap>
        </>

    )
};

export default MyMapComponent;


