import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Builder.scss";
import ContentLayout from "../../components/contentlayout";
import { ToastContainer } from "react-toastify";
import userService from "../../services/user.service";
import Switch from "react-switch";
import { MdOutlineCheck } from "react-icons/md";
import {
  Row,
  Col,
  Button,
} from "react-bootstrap";
import closeicon from "../../assets/image/close-icon.png";

import builderService from "../../services/builder.service";
import { extensions } from "../../slices/extensions";
import StripePay from "./StripePay";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import plugin from "../../assets/image/plugin.png";
import purpleMarker from "../../assets/image/assignedMarker.png"
import yellowMarker from "../../assets/image/yellowMarker.png"
import greenMarker from "../../assets/image/completedMarker.png"
import blueMarker from "../../assets/image/startedMarker.png"
import { useHistory } from "react-router-dom";
import { CountryRegionData } from "react-country-region-selector";

const markers = {
  yellowMarker,
  blueMarker,
  purpleMarker,
  greenMarker
}
const colors = {
  green: "#00B330",
  purple: "#AE16F2",
  yellow: "#F8D92B",
  blue: "#316BB4"
}

const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const Customer = (props) => {
  const userData = useSelector((state) => state.auth.user)
  const dispatch = useDispatch();
  const history = useHistory();
  const [builder, setBuilder] = useState([])
  const [planResponse, setPlanResponse] = useState([])
  const [createCustomer, setcreateCustomer] = useState("false");
  const [isChecked, setIsChecked] = useState(false);
  const [builderId, setBuilderId] = useState('');
  const [productId, setProductId] = useState('');
  const [planName, setPlanName] = useState('');
  const [planType, setPlanType] = useState('all');
  const [priceId, setPriceId] = useState('');
  const [amount, setAmount] = useState(0);
  const [plans, setPlans] = useState([]);
  const [interval, setInterval] = useState("")
  const [selectedButton, setSelectedButton] = useState("month")
  const [selectedBuilderData, setSelectedBuilderData] = useState(null);
  const [subscriptionAction, setSubscriptionAction] = useState(null)
  const [countryCode, setCountryCode] = useState("")
  const [currency, setCurrency] = useState("usd");

  const createCustomerToggle = () => {
    setcreateCustomer(!createCustomer);
  };

  useEffect(() => {
    if (!countryCode) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        displayLocation(position.coords.latitude, position.coords.longitude);
      });
    }
  }, []);

  function displayLocation(latitude, longitude) {
    var geocoder;
    geocoder = new window.google.maps.Geocoder();
    var latlng = new window.google.maps.LatLng(latitude, longitude);

    geocoder.geocode({ latLng: latlng }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          let findCountry = results[0].address_components.find((items) => {
            return items.types[0] === "country";
          });
          if (findCountry) {
            setCountryCode(findCountry.short_name);
          } else {
            setCountryCode("CA");
          }
        } else {
          setCountryCode("CA");
        }
      } else {
        setCountryCode("CA");
      }
    });
  }

  const colors = {
    green: "#00B330",
    purple: "#AE16F2",
    yellow: "#F8D92B",
    blue: "#316BB4"
  }
  const getStripePlans = async () => {
    let country = "US"
    let countryData = CountryRegionData.find(i => i[0] === userData.country);
    if (countryData && countryData.length) {
      country = countryData[1]
    }
    const response = await userService.getStripePlans({ country: country });
    console.log(response, "--response")
    if (Number(response.status) === 200) {
      if (response.data && response.data.plansPrices) {
        setPlanResponse(response.data.plansPrices);
      }
    }
    console.log("plan list response  :::", response);
  }

  useEffect(() => {
    getBuilder({});
  }, [])

  useEffect(() => {
    if (countryCode)
      getStripePlans()
  }, [countryCode])

  useEffect(() => {
    if (planResponse) {
      const plandata = planResponse.filter((data) => data.metadata && data.metadata.planType && data.metadata.planType === 'extension')
      const data = plandata.sort((a, b) => a.sortOrder - b.sortOrder)
      const plan = data.map((planData) => {
        planData.price = planData.price.sort((a, b) => a.sortBy - b.sortBy)
        let price = planData.price.filter(item => { return item.recurring && item.recurring.interval === selectedButton })
        planData.plans = price
        // planData.price = planData.price[0]
        return {
          name: planData.name,
          plans: planData.plans,
          price: price[0] && price[0].unit_amount ? price[0].unit_amount / 100 : 0,
          interval: price[0] && price[0].recurring && price[0].recurring.interval ? price[0].recurring.interval : 'month',
          priceId: price[0] && price[0].id ? price[0].id : '',
          description: planData.description,
          status: planData.active,
          currency: price[0] && price[0].currency ? price[0].currency.toUpperCase() : 'USD',
          productId: planData.id,
          isChecked: false,
          isEnabled: false,
          isSelected: false,
          paymentStatus: 0,
          extensions: planData.extensions,
          imgUrl: planData.images && planData.images.length > 0 ? planData.images[0] : plugin,
          marker: planData.metadata && planData.metadata.marker ? planData.metadata.marker : "",
          _id: undefined
        }
      })

      setPlans(plan)
      setSelectedBuilderData(plan[0])
      console.log(plan, "---plan")

    }
  }, [planResponse, planType])

  useEffect(() => {
    if (planResponse) {
      const plandata = planResponse.filter((data) => data.metadata && data.metadata.planType && data.metadata.planType === 'extension')
      const data = plandata.sort((a, b) => a.sortOrder - b.sortOrder)
      let builderPrice = 0;
      if (builder) {
        let findEnabledBuilder = builder.find(i => i.paymentStatus === 1)
        if (findEnabledBuilder) {
          let findPlan = data.find(i => { return findEnabledBuilder.productId === i.id })
          if (findPlan) {
            let prices = findPlan.price
            let findPrice = prices.find(item => { return item.recurring && item.recurring.interval === selectedButton })
            if (findPrice) {
              builderPrice = findPrice.unit_amount / 100
            }
          }
        }
      }
      const plan = data.map((planData) => {
        planData.price = planData.price.sort((a, b) => a.sortBy - b.sortBy)
        let price = planData.price.filter(item => { return item.recurring && item.recurring.interval === selectedButton })
        planData.plans = price
        const builderData = builder.filter((buildData) => buildData.productId === planData.id && buildData.subscription && buildData.subscription.plan && buildData.subscription.plan.interval === selectedButton)
        if (builderData && builderData.length > 0) {
          let dataToReturn = {
            name: planData.name,
            plans: planData.plans,
            price: price[0] && price[0].unit_amount ? price[0].unit_amount / 100 : 0,
            interval: price[0] && price[0].recurring && price[0].recurring.interval ? price[0].recurring.interval : 'month',
            priceId: price[0] && price[0].id ? price[0].id : '',
            currency: price[0] && price[0].currency ? price[0].currency.toUpperCase() : 'USD',
            description: planData.description,
            status: planData.active,
            productId: planData.id,
            isChecked: false,
            isSelected: false,
            extensions: planData.extensions,
            isEnabled: builderData[0].isEnabled,
            paymentStatus: builderData[0].paymentStatus,
            imgUrl: planData.images && planData.images.length > 0 ? planData.images[0] : plugin,
            marker: planData.metadata && planData.metadata.marker ? planData.metadata.marker : "",
            _id: builderData[0].id,
            type: builderData[0].type
          }
          dataToReturn.buttonText = dataToReturn.paymentStatus === 1 ? "Active!" : "Starting when current plan ends"
          return dataToReturn
        } else {
          let dataToReturn = {
            name: planData.name,
            plans: planData.plans,
            price: price[0] && price[0].unit_amount ? price[0].unit_amount / 100 : 0,
            interval: price[0] && price[0].recurring && price[0].recurring.interval ? price[0].recurring.interval : 'month',
            priceId: price[0] && price[0].id ? price[0].id : '',
            currency: price[0] && price[0].currency ? price[0].currency.toUpperCase() : 'USD',
            description: planData.description,
            status: planData.active,
            productId: planData.id,
            isChecked: false,
            isEnabled: false,
            isSelected: false,
            paymentStatus: 0,
            extensions: planData.extensions,
            imgUrl: planData.images && planData.images.length > 0 ? planData.images[0] : plugin,
            marker: planData.metadata && planData.metadata.marker ? planData.metadata.marker : "",
            _id: undefined
          }
          dataToReturn.subscriptionAction = dataToReturn.price >= builderPrice ? "Upgrade" : "Downgrade"
          return dataToReturn
        }

      })

      setPlans(plan)
    }
  }, [planResponse, builder, planType, selectedButton])


  const getBuilder = async (filter) => {
    const response = await builderService.getUserBuilder(filter);
    if (response.status == 200) {
      console.log(response.data, "response-data")
      setBuilder(response.data || []);
    }
  }

  const handleOnChange = (id, price, productId, priceId, planName, isChecked, paymentStatus, isEnabled, data, subscriptionAction, currency) => {
    console.log(id, price, productId, priceId, planName, isChecked, paymentStatus, isEnabled, data)
    if (!isChecked) {
      setAmount(price * 100)
      setBuilderId(id)
      setProductId(productId)
      setPlanName(planName)
      setPriceId(priceId)
      setInterval(data.interval)
      setSubscriptionAction(subscriptionAction)
      setCurrency(currency)
      createCustomerToggle();
    }
  }


  const handleExtension = () => {
    createCustomerToggle();
    getBuilder({});
  }


  const renderIncluded = (data, markerColor) => {
    if (data && data.extensions) {
      let allExtensions = {};
      for (let extension of data.extensions) {
        delete extension.features.taskCreationAddDocs;
        delete extension.features.accessToMerchant;
        let features = extension.features
        if (features.featuresFromProd === "INITIATE" || features.featuresFromProd === "OPERATE") {
          delete features.accessToGomoveone
          delete features.numberOfTasks
          delete features.numberOfDrivers
          delete features.createAllocateRoutes
          delete features.advancedRoutesOptimization
          delete features.vehicleCapacityOptimization
          delete features.liveTrackRouteEta
          delete features.importSpreadSheets
          delete features.photoAndSignaturePOD
          delete features.accessToLibrary
        }
        if (features.featuresFromProd === "OPERATE") {
          delete features.everyThingInInitiate
          delete features.unlimitedTasks
          delete features.unlimitedAgents
          delete features.smsNotificationsTriggers
          delete features.customizeRecipientSms
          delete features.landingPage
          delete features.brandedTrackingPage
          delete features.routeOptimization
        }
        allExtensions = { ...allExtensions, ...features }
      }
      let allExtensionKeys = Object.keys(allExtensions);
      allExtensionKeys = allExtensionKeys.map(i => {
        let sortNumber = 0;
        let show = false;
        switch (i) {
          case "accessToGomoveone":
            sortNumber = 1;
            show = true;
            break;
          case "numberOfTasks":
            sortNumber = 2;
            show = true;
            break;
          case "numberOfDrivers":
            sortNumber = 3;
            show = true;
            break;
          case "createAllocateRoutes":
            sortNumber = 4;
            show = true;
            break;
          case "advancedRoutesOptimization":
            sortNumber = 5;
            show = true;
            break;
          case "vehicleCapacityOptimization":
            sortNumber = 6;
            show = true;
            break;
          case "liveTrackRouteEta":
            sortNumber = 7;
            show = true;
            break;
          case "importSpreadSheets":
            sortNumber = 8;
            show = true;
            break;
          case "photoAndSignaturePOD":
            sortNumber = 9;
            show = true;
            break;
          case "liveChatSupport":
            sortNumber = 100;
            show = true;
            break;
          case "accessToLibrary":
            sortNumber = 11;
            show = false;
            break;
          case "everyThingInInitiate":
            sortNumber = 12;
            show = false;
            break;
          case "unlimitedTasks":
            sortNumber = 13;
            show = false;
            break;
          case "unlimitedAgents":
            sortNumber = 14;
            show = false;
            break;
          case "smsNotificationsTriggers":
            sortNumber = 15;
            show = false;
            break;
          case "customizeRecipientSms":
            sortNumber = 16;
            show = false;
            break;
          case "landingPage":
            sortNumber = 17;
            show = false;
            break;
          case "brandedTrackingPage":
            sortNumber = 18;
            show = false;
            break;
          case "routeOptimization":
            sortNumber = 18;
            break;

          case "everyThingInOperate":
            sortNumber = 19;
            show = false;
            break;
          case "smartDamageManagement":
            sortNumber = 20;
            show = false;
            break;
          case "deliveryConfirmationForm":
            sortNumber = 21;
            show = false;
            break;
          case "responsibiltyDischargeForm":
            sortNumber = 22;
            show = false;
            break;
          case "linkedTask":
            sortNumber = 23;
            show = false;
            break;
          case "exportTaskPdf":
            sortNumber = 24;
            break;
          case "exportMissionPdf":
            sortNumber = 25;
            break;
          case "plannerOperations":
            sortNumber = 25;
            break;

          case "driverChat":
            sortNumber = 26;
            show = false;
            break;
          case "apiIntegration":
            sortNumber = 27;
            show = false;
            break;
          case "standardOnboarding":
            sortNumber = 28;
            show = false;
            break;
          case "pictureAssessment":
            sortNumber = 29;
            show = false;
            break;

          case "accessToMerchant":
            sortNumber = 100;
            break;
          case "exportHistory_all":
            sortNumber = 16;
            break;
          case "taskCreationAddDocs":
            sortNumber = 100;
            break;
          case "multiMission":
            sortNumber = 3;
            break;
          case "vehicleCapacityManagement":
            sortNumber = 2;
            break;
          case "installationTeam":
            sortNumber = 5;
            break;
          default:
            sortNumber = 0;
        }
        return {
          name: i,
          sortBy: sortNumber,
          showText: show
        }
      })
      let sortedallExtensionKeys = allExtensionKeys.sort((a, b) => a.sortBy - b.sortBy).map(i => { return i.name })
      return (<ul>
        {sortedallExtensionKeys.map(ext => {
          let text = "";
          switch (ext) {
            case "accessToGomoveone":
              text = "Access To Gomove One";
              break;
            case "numberOfTasks":
              text = "250 tasks";
              break;
            case "numberOfDrivers":
              text = "3 drivers";
              break;
            case "createAllocateRoutes":
              text = "Create and allocate routes";
              break;
            case "advancedRoutesOptimization":
              text = "Advanced Routes optimization";
              break;
            case "vehicleCapacityOptimization":
              text = "Vehicle capacity optimization";
              break;
            case "liveTrackRouteEta":
              text = "Live track route progress - ETA";
              break;
            case "importSpreadSheets":
              text = "Import spreadsheets";
              break;
            case "photoAndSignaturePOD":
              text = "Photo & signature proof of delivery";
              break;
            case "liveChatSupport":
              text = "Live Chat Support";
              break;

            // case "accessToLibrary":
            //   text = "Access to library";
            //   break;


            case "everyThingInInitiate":
              text = "Everything in INITIATE";
              break;
            case "unlimitedTasks":
              text = "Unlimited tasks";
              break;
            case "unlimitedAgents":
              text = "Unlimited drivers";
              break;
            case "smsNotificationsTriggers":
              text = "Customer email and sms updates";
              break;
            case "customizeRecipientSms":
              text = "Branded sms and e-mails";
              break;
            case "brandedTrackingPage":
              text = "Branded recipient tracking page";
              break;
            // case "landingPage":
            //   text = "Responsibility Discharge";
            //   break;

            case "everyThingInOperate":
              text = "EveryThing In OPERATE";
              break;
            case "smartDamageManagement":
              text = "Smart damage management";
              break;
            case "deliveryConfirmationForm":
              text = "Delivery Confirmation form";
              break;
            case "responsibiltyDischargeForm":
              text = "Responsibility Discharge Form";
              break;
            case "linkedTask":
              text = "Tasks linking";
              break;
            case "driverChat":
              text = "Driver Chat";
              break;
            case "plannerOperations":
              text = "Planner";
              break;
            case "apiIntegration":
              text = "Api Integration*";
              break;
            case "standardOnboarding":
              text = "Standard onboarding";
              break;
            case "pictureAssessment":
              text = "Picture assessment";
              break;

            // case "exportHistory_all":
            //   text = "Lean performance";
            //   break;
            // case "landingPage":
            //   text = "Landing page";
            //   break;
            // case "taskCreationAddDocs":
            //   text = "";
            //   break;
            case "routeOptimization":
              text = "Route Optimization";
              break;
            // case "multiMission":
            //   text = "Multi-Mission";
            //   break;
            // case "vehicleCapacityManagement":
            //   text = "Capacity Management";
            //   break;
            case "exportMissionPdf":
              text = "Print Mission Manifest";
              break;
            // case "installationTeam":
            //   text = "Teams Synchro";
            //   break;
            case "exportTaskPdf":
              text = "Task History Report";
              break;
            default:
              text = ""
          }
          if (text) {
            return (
              <li><MdOutlineCheck style={{ color: colors[markerColor] }} />
                <span> {text}</span>
              </li>)
          }
          return (null)
        })}
      </ul>)
    }
    return null
  }

  const filterPlanHandler = (button) => {
    setSelectedButton(button)
  }

  return (
    <div>
      <ContentLayout>
        <div className="control-layout-wrapper">
          <h1 className="subscription-heading">Choose the right plan for your business</h1>
          <h1 className="subscription-subheading">You can cancel at any time if you think Gomove One isn't right for you</h1>
          <div className="signup-form-box-business business-info planinfo">
            {/* <h2>Plans info</h2> */}
            <div className="planinfo-buttons">
              <span>Monthly</span>
              <Switch
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={40}
                onChange={(e) => {
                  setIsChecked(e)
                  if (e) {
                    filterPlanHandler("year")
                  }
                  else {
                    filterPlanHandler("month")
                  }
                }}
                defaultValue={false}
                checked={isChecked}
                className="react-switch"
                id="material-switch"
              />
              <span>Annual billing (save 20%)</span>
            </div>
            <div className="plancard">
              <div className="plan-card-wrapper">

                {plans && plans.length > 0 && plans.map((data) => {
                  if (data.price > 0) {
                    return (<div className={`card__content`} >
                      <header className="card__header">
                        <img src={data.marker ? markers[`${data.marker}Marker`] : yellowMarker} alt="marker"></img>
                        <h1 className="card__header-title">{data.name}</h1>
                      </header>
                      <p className="description">{data.description}</p>
                      <div className="plan-price">
                        <span className="price">
                          ${data.price}
                        </span>
                        <span className="currency">
                          {data.currency}/{data.interval === "month" ? "m" : "y"}
                        </span>
                      </div>
                      <div className="proceed-button">
                        {userData && userData.role !== 1 && data.paymentStatus !== 1 && data.type !== "DOWNGRADED" && <Button
                          disabled={data.paymentStatus === 1 ? true : false}
                          onClick={() => {
                            handleOnChange(
                              data._id,
                              data.price,
                              data.productId,
                              data.priceId,
                              data.name,
                              data.isChecked,
                              data.paymentStatus,
                              data.isEnabled,
                              data,
                              data.subscriptionAction,
                              data.currency
                            )
                          }}
                        >{data.trialDays ? `Start ${data.trialDays}-day free trial` : (data.subscriptionAction ? data.subscriptionAction : "Let's go")}</Button>}
                        {data.paymentStatus === 1 && <span
                          className="active-span">{data.buttonText}</span>}
                        {data.paymentStatus === 0 && data.type === "DOWNGRADED" && <span
                          className="downgrade-span">{data.buttonText}</span>}
                      </div>

                      <div className="separator">
                        <hr></hr>
                      </div>
                      <div className="card__list">
                        <span>
                          KEY FEATURES
                        </span>
                        <div className="ct-option-list">
                          {renderIncluded(data, data.marker)}
                        </div>
                      </div>

                    </div>)
                  }
                  return <></>
                })}
              </div>
            </div>
            <div className="learn-about-pricing">
              <span>
                Learn more about <a href="https://gomove.ai/delivery-management-software" target="_blank" rel="noreferrer">pricing</a>.
              </span>
            </div>

            <div className="bsbtn">
              <Row>
                <Col xs={2}>
                  <div className="submitbtn">
                    <Button onClick={() => { history.goBack() }}>Back</Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div
            className={
              createCustomer
                ? "opencustomer-popup"
                : "opencustomer-popup createCustomer"
            }
          >
            <div className="addCustomer-popup">
              <div className="addcustomer-slide">
                <div className="addcustomer-field">
                  <div className="customer-import">
                    <Button
                      className="createcustomer-close"
                      onClick={createCustomerToggle}
                    >
                      <img src={closeicon} alt="icon" />
                    </Button>
                  </div>
                </div>

                <div className="addcustomer-information Business-cus-popup">
                  <div className="customer-form">
                    <Elements stripe={stripeTestPromise}>
                      <StripePay
                        builderId={builderId}
                        productId={productId}
                        priceId={priceId}
                        planName={planName}
                        amount={amount}
                        handleExtension={handleExtension}
                        interval={interval}
                        currency={currency}
                        subscriptionAction={subscriptionAction}
                      />
                    </Elements>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          style={{ color: "black" }}
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ContentLayout >
    </div >
  );
};

export default Customer;
