import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Card,
  Form as BootstrapForm,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
  NavDropdown,
} from "react-bootstrap";
import "./Library.scss";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import logo from "../../assets/image/logo.png";
import Sidebarmenu from "../../components/sidebarmenu";
import Union from "../../assets/image/Union.png";
import downloadicon from "../../assets/image/downloadicon.png";
import searchicon from "../../assets/image/searchicon.png";
import searchF from "../../assets/image/search-f.png";
import ShortIcon from "../../assets/image/filter-icon.png";
import filterremoveicon from "../../assets/image/filter-removeicon.png";
import closeicon from "../../assets/image/close-icon.png";
import builderpopupimg from "../../assets/image/builderppimg.png";
import plusuploadicon from "../../assets/image/plusuploadicon.png";
import ContentLayout from "../../components/contentlayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { ExportToCsv } from "export-to-csv";

import {
  successAlert,
  errorAlert,
  infoAlert,
} from "../../services/toast.service";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import libraryService from "../../services/library.service";

const Library = (props) => {
  const initialValues = {
    id: "",
    name: "",
    youtubeUrl: "",
    categoryId: "",
  };

  const [createCustomer, setcreateCustomer] = useState("false");
  const [builderformData, setBuilderFormData] = useState(initialValues);
  const [builders, setBuilders] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const uploadInput = useRef(null);
  const [images, setImages] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [category, setCategory] = useState([]);
  const [imageError, setImageError] = useState("");
  const createCustomerToggle = () => {
    console.log("createCustomercreateCustomer", createCustomer)
    setcreateCustomer(!createCustomer);
  };

  const userData = useSelector((state) => state.auth.user)
  useEffect(() => {
    console.log("in use effect");
    getCategory();
  }, []);

  const getCategory = async () => {
    const response = await libraryService.getAllCategoryList({});
    console.log(response, "--cat");
    if (response.status == 200) {
      console.log(response.data.results, "--catgory");
      setCategory(response.data.results || []);
    }
  };

  const priceRegex = /^(0|[1-9]\d*)(\.\d+)?$/;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    categoryId: Yup.string().required("Application  is required"),
  });

  const formatStatus = (cell, row) => {
    if (row.blocked) return <span>Blocked</span>;
    return <span>Active</span>;
  };
  function NameFormatter(cell, row) {
    return <span>{row.name}</span>;
  }
  function CategoryFormatter(cell, row) {
    return <span>{row.categoryId && row.categoryId.category}</span>;
  }
  const changeProfileClick = (e) => {
    uploadInput.current.click();
  };

  const uploadFiles = async (event) => {
    console.log("event.target.files coount", event.target.files.length);
    console.log("event.target.files", event.target.files);
    let img = [];
    for (let i = 0; i < event.target.files.length; i++) {
      console.log("event.target.files[i]", event.target.files[i]);
      img.push(event.target.files[i]);
      setImages([...images, ...img]);
    }
  };
  function deleteFile(e) {
    const s = images.filter((item, index) => index !== e);
    setImages(s);
    console.log(s);
  }
  function deleteAttachment(e) {
    const s = attachments.filter((item, index) => index !== e);
    setAttachments(s);
    console.log(s);
  }
  const handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  const [isOpened, setIsOpened] = useState(false);

  const Remove = (cell, row) => {
    return (
      <>
        <span>
          <span className="filter-edit">
            <NavDropdown
              title={<img src={filterremoveicon} />}
              id="nav-dropdown"
            >
              <NavDropdown.Item
                eventKey="4.1"
                onClick={() => {
                  editHandler(row);
                }}
              >
                Edit
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="4.2"
                onClick={() => {
                  deleteHandler(row);
                }}
              >
                Delete
              </NavDropdown.Item>
            </NavDropdown>
          </span>
        </span>
      </>
    );
  };

  const getLibraryList = async (params) => {
    const response = await libraryService.getLibraryList(params);
    if (response.status == 200) {
      if (response.data && response.data.totalPages) {
        setPageCount(response.data.totalPages);
      }
      setBuilders(response.data.results || []);
    }
    console.log("user list response  :::", response);
  };

  const exportToCsv = async () => {
    console.log("i export");
    libraryService
      .getAllList({})
      .then((result) => {
        if (result.status === 200) {
          let data = [];

          result.data.map((item, index) => {
            let d = {
              name: item.name || "N/A",
              price: item.price || "N/A",
              category:
                item.categoryId && item.categoryId.category
                  ? item.categoryId.category
                  : "N/A",
              description: item.description || "N/A",
              status: item.blocked ? "UnActive" : "Active",
            };
            data.push(d);
          });

          const options = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: true,
            title: "Library List",
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: "library",
          };

          const csvExporter = new ExportToCsv(options);
          if (data.length > 0) {
            csvExporter.generateCsv(data);
          } else {
            errorAlert("No data found");
          }
        } else {
          errorAlert(result.message ? result.message : "Something went wronng");
        }
      })
      .catch(console.log);
  };

  //handlers
  const editHandler = (row) => {
    console.log(row, "row");
    setBuilderFormData({
      id: row.id ? row.id : "",
      name: row.name ? row.name : "",
      categoryId: row.categoryId && row.categoryId.id ? row.categoryId.id : "",
      youtubeUrl: row.youtubeUrl ? row.youtubeUrl : "",
    });
    setAttachments(row.attachments ? row.attachments : []);
    createCustomerToggle();
  };
  const deleteHandler = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      text: "Deleted records can't be restored!",
      color: "#ffffff",
      background: "#151719",
      confirmButtonColor: "#1A1D1F",
      cancelButtonColor: "#1A1D1F",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await libraryService.deleteBuilder(row.id);
        if (response) {
          console.log("delete response ::::", response);
          Swal.fire(
            "Deleted!",
            "Library has been Deleted Successfully !",
            "success"
          );
          getLibraryList({ page: currentPage, searchText });
        }
      }
    });
  };

  const RowSelectHandler = (row, isSelect, rowIndex, e) => {
    // ...
    if (isSelect) {
      const item = row.id || "";
      setSelectedItems([...selectedItems, item]);
    } else {
      const items = selectedItems.filter((sItem) => sItem != row.id);
      setSelectedItems(items);
    }
  };

  const selectAllRowsHandler = (isSelect, rows, e) => {
    if (isSelect) {
      const items = rows
        .map((rowItem) => rowItem.id)
        .filter((itemId) => {
          if (!selectedItems.includes(itemId)) return itemId;
        });
      setSelectedItems([...selectedItems, ...items]);
    } else {
      const ids = rows.map((rowItem) => rowItem.id);
      const items = selectedItems.filter((sItem) => {
        if (!ids.includes(sItem)) return sItem;
      });
      setSelectedItems(items);
    }
  };

  const deleteAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        text: "Deleted records can't be restored!",
        color: "#ffffff",
        background: "#151719",
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await libraryService.deleteManyLibrary(
            selectedItems
          );
          if (data.success) {
            getLibraryList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Library Deleted Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
        }
      });
    }
  };

  const blockAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        text: "You Want to Block selectd Builders !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await libraryService.blockManyLibrary(selectedItems);
          if (data.success) {
            getLibraryList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Library Blocked Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: RowSelectHandler,
    onSelectAll: selectAllRowsHandler,
    bgColor: "rgb(238, 193, 213)",
  };

  useEffect(() => {
    getLibraryList({ page: currentPage, searchText });
  }, []);

  useEffect(() => {
    console.log("builders::::", builders);
  }, [builders]);

  useEffect(() => {
    console.log("search text ::::", searchText);
    const delayDebounceFn = setTimeout(async () => {
      getLibraryList({ page: 1, searchText });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const handleSubmit = async (formValue) => {
    console.log("in submit");
    try {
      console.log(images, "--formValue");
      if (formValue.id) {
        //update here
        let response = {};
        if (images && images.length > 0) {
          const formData = new FormData();
          formData.append("id", formValue.id);
          for (let i = 0; i < images.length; i++) {
            formData.append(`images[${i}]`, images[i]);
          }
          // for(let i=0; i< attachments.length ; i++)
          // {
          //   formData.append(`attachments[${i}]`, attachments[i]);
          // }
          formData.append("attachments", JSON.stringify(attachments));
          formData.append("name", formValue.name);
          formData.append("youtubeUrl", formValue.youtubeUrl);
          formData.append("categoryId", formValue.categoryId);

          response = await libraryService.editLibrary(formData, formValue.id);
        } else {
          formValue.attachments = attachments;
          response = await libraryService.editLibrary(formValue, formValue.id);
        }

        if (response.data) {
          setBuilderFormData(initialValues);
          setcreateCustomer("false");
          setImages([]);
          setAttachments([]);
          getLibraryList({ page: currentPage, searchText });
          successAlert(
            response.data.message
              ? response.data.message
              : "Library Updated Successfully !"
          );
        }
        if (!response.data) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
          setAttachments([]);
        }
      } else {
        if (images.length === 0) {
          setImageError("Please select Image");
        } else {
          setImageError("");
          setAttachments([]);
          const formData = new FormData();
          for (let i = 0; i < images.length; i++) {
            formData.append(`images[${i}]`, images[i]);
          }
          // formData.append('images', images);
          formData.append("name", formValue.name);
          formData.append("youtubeUrl", formValue.youtubeUrl);
          formData.append("categoryId", formValue.categoryId);
          if (userData.userId) {
            formData.append("userId", userData.userId)
          }
          const response = await libraryService.createLibrary(formData);
          if (response.data.success) {
            console.log("after sucess", initialValues);
            setBuilderFormData(initialValues);
            setImages([]);
            setcreateCustomer("false");
            successAlert(
              response.data.message
                ? response.data.message
                : "Library Added Successfully !"
            );
            getLibraryList({ page: currentPage, searchText });
          }
          if (response.data.success === false) {
            errorAlert(
              response.data.message
                ? response.data.message
                : "Something Went Wrong !"
            );
          }
        }
      }
    } catch (err) {
      console.log("error:::", err);
    }
  };

  const cancelhandler = () => {
    setBuilderFormData(initialValues);
    setcreateCustomer("false");
  };

  const handlePageClick = async ({ selected }) => {
    console.log("current page::", selected);
    if (selected + 1 !== currentPage) {
      setCurrentPage(selected + 1);
      await getLibraryList({ page: selected + 1, searchText });
    }
  };
  const [bulkOpened, setbulkOpened] = useState(false);

  function bulktoggle() {
    setbulkOpened((wasOpened) => !wasOpened);
  }
  return (
    <>
      <ContentLayout>
        <div className="customer-page-wrapper d-flex">
          {/* customer layout start */}
          <div className="customer-form-wrapper">
            <div className="customer-heading-box">
              <Row>
                <Col xs={6}>
                  <h1>Library</h1>
                </Col>
                <Col xs={6}>
                  <div className="createmsbox">
                    <div onClick={createCustomerToggle}>
                      <Button className="cticon">
                        <img src={Union} alt="icon" />
                      </Button>
                      <span>Add Library</span>
                    </div>
                    <div>
                      <Button onClick={() => exportToCsv()}>
                        <img src={downloadicon} alt="icon" />
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="customer-searching-box">
              <Row>
                <Col xs={6}>
                  <div className="create-action-option mb-3">
                    <Dropdown>
                      <Dropdown.Toggle className="bulk-action">
                        Bulk Action
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={blockAllHandler}>
                          Block All
                        </Dropdown.Item>
                        <Dropdown.Item onClick={deleteAllHandler}>
                          Delete Selected
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="searchbar">
                    <BootstrapForm className="d-flex">
                      <FormControl
                        type="search"
                        placeholder="Search or type a name"
                        className="me-2"
                        autoComplete="off"
                        onChange={(e) => setSearchText(e.target.value)}
                        aria-label="Search"
                      />
                      <Button className="searchicon">
                        <img src={searchicon} alt="searchicon" />
                      </Button>
                      {/* <span className="searchF">
                        <img src={searchF} alt="icon" />
                      </span> */}
                    </BootstrapForm>
                  </div>
                </Col>
              </Row>
            </div>

            {/* customer list start */}
            <div className="customer-list-wrapper">
              <BootstrapTable
                data={builders}
                striped={true}
                hover={true}
                selectRow={selectRowProp}
                wrapperClasses="table-responsive"
              >
                <TableHeaderColumn
                  dataField="id"
                  isKey={true}
                  dataAlign="left"
                  dataSort={true}
                  dataFormat={NameFormatter}
                  width="200px"
                >
                  Name <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="youtubeUrl"
                  dataSort={true}
                  width="200px"
                >
                  Url
                  <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="categoryId.category"
                  dataSort={true}
                  dataFormat={CategoryFormatter}
                  width="250px"
                >
                  Category
                  <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="block"
                  dataFormat={formatStatus}
                  width="150px"
                >
                  Status
                  <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="Action"
                  dataAlign="right"
                  dataFormat={Remove}
                  width="150px"
                >
                  Action
                </TableHeaderColumn>
              </BootstrapTable>
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="Prev"
                renderOnZeroPageCount={null}
                className="pagination"
              />
            </div>

            {/* customer list end */}
          </div>

          {/* customer layout end */}
        </div>
        {/* create customer popup start */}
        <div
          className={
            createCustomer
              ? "opencustomer-popup"
              : "opencustomer-popup createBuilder"
          }
        >
          <div className="addCustomer-popup builderpopup">
            <div className="addcustomer-slide">
              <div className="addcustomer-field lbfield">
                <div className="customer-import">
                  <Button
                    className="createcustomer-close"
                    onClick={createCustomerToggle}
                  >
                    <img src={closeicon} alt="icon" />
                  </Button>
                </div>
              </div>
              <div className="addbuilder-information">
                <div className="addcustomer-information">
                  <div className="customer-form">
                    <Formik
                      enableReinitialize
                      initialValues={builderformData}
                      render={(formProps) => {
                        return (
                          <Form>
                            <Row className="custinfo-form library-upload-btn">
                              <div className="builderpp-img">
                                <div className="adddoc-library">
                                  <div className="adddocbtn">
                                    <button
                                      className="add-docbtn"
                                      type="button"
                                      onClick={changeProfileClick}
                                    >
                                      <img src={plusuploadicon} />
                                      <span>Add Doc</span>
                                    </button>
                                    <input
                                      className="file-upload"
                                      ref={uploadInput}
                                      type="file"
                                      name="images"
                                      multiple
                                      onChange={(e) => {
                                        uploadFiles(e);
                                      }}
                                      style={{ display: "none" }}
                                    />
                                    <span>{imageError}</span>
                                  </div>
                                  {/* <span>{images && images.length}</span> */}
                                  <div className="adddoc-img">
                                    {images &&
                                      images.length > 0 &&
                                      images.map((item, index) => {
                                        return (
                                          <div className="imgbox" key={index}>
                                            <span className="imgname">
                                              {item.name}
                                            </span>
                                            <button
                                              className="remove-limg"
                                              type="button"
                                              onClick={() => deleteFile(index)}
                                            >
                                              <img src={closeicon} alt="icon" />
                                            </button>
                                          </div>
                                        );
                                      })}

                                    {attachments &&
                                      attachments.length > 0 &&
                                      attachments.map((item, index) => {
                                        return (
                                          <div className="imgbox" key={index}>
                                            <span className="imgname">
                                              {item}
                                            </span>
                                            <button
                                              type="button"
                                              onClick={() =>
                                                deleteAttachment(index)
                                              }
                                            >
                                              <img src={closeicon} alt="icon" />
                                            </button>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                              <Col xs={12}>
                                <label>Name</label>
                                <InputGroup>
                                  <Field
                                    className="form-control"
                                    placeholder="Name"
                                    name="name"
                                    maxLength="25"
                                    aria-label="Name"
                                  />
                                  <ErrorMessage name="name" component="div" />
                                </InputGroup>
                              </Col>
                              <Col xs={12}>
                                <label>Url</label>
                                <InputGroup>
                                  <Field
                                    className="form-control"
                                    placeholder="Url"
                                    name="youtubeUrl"
                                    maxLength="200"
                                    aria-label="Url"
                                  />
                                  <ErrorMessage
                                    name="youtubeUrl"
                                    component="div"
                                  />
                                </InputGroup>
                              </Col>
                              <Col xs={12}>
                                <label>Application</label>
                                <InputGroup>
                                  <Field
                                    className="form-control"
                                    placeholder="Name"
                                    name="categoryId"
                                    as="select"
                                    maxLength="25"
                                    aria-label="Category"
                                  >
                                    <option selected value="">
                                      Select Application
                                    </option>
                                    {category &&
                                      category.length > 0 &&
                                      category.map((data) => (
                                        <option value={data.id}>
                                          {data.category}
                                        </option>
                                      ))}
                                  </Field>
                                  <ErrorMessage
                                    name="categoryId"
                                    component="div"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                            <div className="ct-btn">
                              <Button
                                className="cncle-btn"
                                onClick={cancelhandler}
                              >
                                Cancel
                              </Button>
                              <Button className="ctaskbtn" type="submit">
                                Let’s Go
                              </Button>
                            </div>
                          </Form>
                        );
                      }}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    ></Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* create customer popup end */}
      </ContentLayout>
      <ToastContainer
        style={{ color: "black" }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Library;
