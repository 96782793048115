import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "extensions/";


const getExtensionList = (params) => {
    if (!params.searchText) delete params.searchText
    return axios.get(API_URL, { headers: authHeader(), params: {  ...params } });
};

const createExtension = (data) => {
    data = clean(data)
    return axios.post(API_URL, data, { headers: authHeader() });
};

const editExtension = (data) => {
    data = clean(data)
    return axios.patch(`${API_URL}/${data.id}`, data, { headers: authHeader() });
};
const deleteExtension = (id) => {
    return axios.delete(`${API_URL}/${id}`, { headers: authHeader() });
};


const deleteManyExtensions = (extensionsIds) => {
    const headerObj = { data: { extensionsIds }, headers: authHeader() }
    console.log("headers::", headerObj)

    return axios.delete(API_URL + 'multipleDelete', headerObj);

};

function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
    }
    return obj
}

const extensionService = {
    getExtensionList,
    createExtension,
    editExtension,
    deleteExtension,
    deleteManyExtensions,

};

export default extensionService