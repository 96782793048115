import {
    MULTI_TYPE_SAVE_USER_REDUX,
    SAVE_STRIPE_PLANS_REDUX,
    SAVE_STRIPE_TRANSACTIONS_REDUX,
    SAVE_USER_COUNT_REDUX,
    SAVE_USER_REDUX
} from './actionTypes';

export const saveUserList = payload => {
    return {
        type: SAVE_USER_REDUX,
        payload
    }
}
export const saveMultiTypeUserList = payload => {
    return {
        type: MULTI_TYPE_SAVE_USER_REDUX,
        payload
    }
}

export const saveStripePlans = payload => {
    return {
        type: SAVE_STRIPE_PLANS_REDUX,
        payload
    }
}

export const saveUserCount = payload => {
    return {
        type: SAVE_USER_COUNT_REDUX,
        payload
    }
}

export const saveStripeTransactions = payload => {
    return {
        type: SAVE_STRIPE_TRANSACTIONS_REDUX,
        payload
    }
}