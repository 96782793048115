import styled from "styled-components";

export const DetailsFormSection = styled.section`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.bgColor};
  .line{
    display: none;
  }
  .back-btn{
    display: none;
  }
  @media (max-width: 1100px) {
    .line{
            position: relative;
            display: block;
            width: 100%;
            height: 1px;
            background: ${({theme}) => theme.colors.darkTextColor};
            top: 200px;
        }
    padding: 1.4rem 0;
      height: 100%;
      background: ${({ theme }) => theme.colors.bgColor};
      .back-btn {
        display: block;
    position: relative;
    top: 0;
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: "";
      position: absolute;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.whiteColor};
    }

    .icon {
      z-index: 2;
      width: 40px;
      height: 40px;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.bgColor};
    }
    
    
  }
    }
  .wrapper {
    display: flex;
    background: ${({ theme }) => theme.colors.bgColorVariant};
    width: 90%;
    padding: 2rem;
    border-radius: 10px;
    justify-content: space-between;
    margin-top: 3rem;

    @media (max-width: 1100px) {
      height: 100%;
      flex-direction: column;
      background: ${({ theme }) => theme.colors.bgColorVariant};
      border-radius: 10px;
      padding: 1.4rem;
      margin-top: 0;
    }
    .company-event {
      flex: 2;
      @media (max-width: 1100px) {
        .company-header {
          width: 80vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          h2 {
            font-size: 22px;
            margin: 0;
          }
          h4{
            margin: 0;
          }
        }
      }
      .selected-date-time {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @media (max-width: 1100px) {
          gap: 8px;
          padding-top: 1rem;
        }
        .selected-time-range {
          display: flex;
          p{
            margin-left: 12px;
          }
          @media (max-width: 1100px) {
          p{
            margin-left: 12px;
            font-size: 11px;
            font-weight: bold;
          }
        }
        }
        .selected-date {
          display: flex;
          .date-num{
            margin-left: 2px;
          }
         .icon{
          margin-right: 15px;
         }
          @media (max-width: 1100px) {
            p{
            font-size: 11px;
            font-weight: bold;
          }
        }
        }
        .selected-time-zone {
          margin-left: 30px;
          @media (max-width: 1100px) {
            p{
            font-size: 11px;
            font-weight: bold;
           
          }
        }
        }
      }
    }
  }
`;
export const FormContainer = styled.div`
 @media (max-width: 1100px) {
  margin-top: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  h4 {
    font-size: 15px;
    @media (max-width: 1100px) {
      font-size: 18px;
    }
  }
  flex: 3;
  form {
    margin-right: 2rem;
    margin-top: 1rem;
    @media (max-width: 1100px) {
      margin-top: 0;
      margin-right: 0;
    }
    .input-area {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      @media (max-width: 1100px) {
      margin-top: 1rem;
    }
      label {
        color: ${({ theme }) => theme.colors.whiteColor};
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 7px;
        @media (max-width: 1100px) {
        font-size: 12px;
    }
      }
      input {
        background: transparent;
        border: 1px solid ${({ theme }) => theme.colors.darkTextColor};
        padding: 14px;
        border-radius: 5px;
        color: ${({ theme }) => theme.colors.darkTextColor};
        @media (max-width: 1100px) {
        padding: 8px;
    }
      }
    }
    .guests {
      display: flex;
      align-items: center;
      gap: 2rem;
      margin-top: 1rem;
      @media (max-width: 1100px) {
      margin-top: 1rem;
      font-size: 11px;
      gap: 1rem;
    }
      button {
        cursor: pointer;
        border: 1px solid ${({ theme }) => theme.colors.primaryColorVariant};
        padding: 10px 15px;
        background: transparent;
        border-radius: 20px;
        font-weight: bold;
        width: 150px;
        color: ${({ theme }) => theme.colors.primaryColorVariant};
        @media (max-width: 1100px) {
      font-size: 10px;
      padding: 8px 12px;
    }
      }
      p {
        color: ${({ theme }) => theme.colors.primaryColorVariant};
        
      }
    }
    .check-box-area {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
      margin-top: 2rem;
      @media (max-width: 1100px) {
      text-align: center;
      flex-direction: column;
      align-items: center;
      margin-top: 1.5rem;
      gap: 1rem;
    }
      p {
        font-size: 14px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.whiteColor};
        @media (max-width: 1100px) {
      font-size: 12px;
     
    }
      }
      .check-boxx {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    .text-area {
      margin-top: 1rem;
      @media (max-width: 1100px) {
      margin-top: 1.5rem;
      
    }
      label {
        color: ${({ theme }) => theme.colors.whiteColor};
        font-size: 14px;
        font-weight: bold;
        @media (max-width: 1100px) {
      font-size: 12px;
    }
      }
      textarea {
        resize: none;
        margin-top: 10px;
        background: transparent;
        border: 1px solid ${({ theme }) => theme.colors.darkTextColor};
        padding: 14px;
        border-radius: 5px;
        color: ${({ theme }) => theme.colors.darkTextColor};
        width: 100%;
        @media (max-width: 1100px) {
      font-size: 12px;
      padding: 10px;
    }
      }
    }
    .delivery-btn {
      font-size: 14px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.whiteColor};
      background: ${({ theme }) => theme.colors.primaryColor};
      padding: 1rem 1.5rem;
      border: none;
      margin-top: 1rem;
      border-radius: 40px;
      cursor: pointer;
      @media (max-width: 1100px) {
      margin-top: 2rem;
      margin-bottom: 3rem;
      width: 100%;
    }
    }
  }
`;
