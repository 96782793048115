
import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, ErrorMessage, Form as FormF } from "formik";
import {
    Card,
    Row,
    Col,
    Form,
    InputGroup,
    FormControl,
    Button,
    Dropdown,
    Modal,
    Container,
    NavDropdown,
} from "react-bootstrap";

import Union from "../../assets/image/Union.png";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import uploadplusicon from "../../assets/image/plusuploadicon.png";
import closeicon from "../../assets/image/close-icon.png";
import axios from "axios"
import fileDownload from "js-file-download";
import Switch from "react-switch";

const ScheduleDeliveryTab = (props) => {
    const uploadInput = useRef(null);
    let type = props.settingTypes.OPERATIONS;
    let subType = props.settingSubType.SCHEDULE_DELIVERY;
    if (props.userData && props.userData.role === 1) {
        type = props.settingTypes.OPERATIONS
        subType = props.settingSubType.COMMON_SCHEDULE_DELIVERY
    }
    let roleBasedDisabled = false;
    if (props.userData && props.userData.userId) {
        roleBasedDisabled = true
    }

    const handleSlots = (value, index) => {
        let dailySlots = [...props.settingFormData.dailyAvailableSlots]
        dailySlots[index].slots = value;
        props.setInputValue(dailySlots);
        props.setInputSettingValue({ key: [{ "dailyAvailableSlots": dailySlots }], type: type, subType: subType })
    }
    return (<div className="st-oplist">
        <div className="st-optimization">
            <div className="st-opheading">
                <h1>Event name</h1>
            </div>
            <div className="st-eventname">
                <Form>
                    <Form.Group as={Row} className="mb-input">
                        <div style={{ display: "flex" }}>
                            <Col sm={4}>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.settingFormData.eventName}
                                    placeholder="Event name"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskDuration": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "eventName": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                        </div>
                    </Form.Group>
                </Form>
            </div>
            <div className="st-opheading">
                <h1>Description</h1>
            </div>
            <div className="st-eventdescription">
                <Form>
                    <Form.Group as={Row} className="mb-input">
                        <div style={{ display: "flex" }}>
                            <Col sm={4}>
                                <Form.Control
                                    as="textarea"
                                    defaultValue={props.settingFormData.eventDescription}
                                    placeholder="Description"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskDuration": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "eventDescription": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                        </div>
                    </Form.Group>
                </Form>
            </div>
            <div className="st-opheading1">
                <h1>Set your daily available slots</h1>
            </div>
            <div className="st-oplistbox">
                <Form>
                    {props.settingFormData && props.settingFormData.dailyAvailableSlots && props.settingFormData.dailyAvailableSlots.map((i, index) => {
                        return (<Form.Group as={Row} className="mb-input">
                            <Form.Label column sm={2}>
                                {i.name}
                            </Form.Label>
                            <div style={{ display: "flex" }}>
                                <Col sm={1.5}>
                                    <Form.Control
                                        type="number"
                                        defaultValue={i.slots}
                                        placeholder="Unavailable"
                                        disabled={roleBasedDisabled}
                                        // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskDuration": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                        onChange={(e) => { handleSlots(e.target.value, index) }} />
                                </Col>
                                <span style={{ color: "white", padding: "6px", fontSize: "13px", fontWeight: "500" }}>{i.slots || i.slots === 0 ? "Slots" : ""}</span>
                            </div>
                        </Form.Group>)
                    })
                    }
                </Form>
            </div>
        </div>
        <div className="st-TimeRange">
            <div className="st-opheading">
                <h1>Options for your customer</h1>
            </div>
            <div className="st-oplistbox">
                <Form>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Add Notes
                        </Form.Label>
                        <Col sm={10}>
                            <div className="toggle-switch">
                                {/* <Form.Control
                                    type="checkbox"
                                    id="note"
                                    name="note"
                                    value="8"
                                /> */}
                                <Switch
                                    // disabled={roleBasedDisabled}
                                    disabled={true}
                                    onChange={(e) => { props.handleChangeSetting(e, { key: [{ "addNotes": e }], type: type, subType: subType }) }}
                                    checked={props.settingFormData.addNotes}
                                />
                                {/* <label for="note">
                                    <span className="toggle-track"></span>
                                </label> */}
                                <div className="editd">
                                    {/* <Button>Edit Report</Button> */}
                                    <span style={{ color: "#ffffff" }}>
                                        Coming Soon...
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Request assembling or installation
                        </Form.Label>
                        <Col sm={10}>
                            <div className="toggle-switch">
                                {/* <Form.Control
                                    type="checkbox"
                                    id="note"
                                    name="note"
                                    value="8"
                                /> */}
                                <Switch
                                    // disabled={roleBasedDisabled}
                                    disabled={true}
                                    onChange={(e) => { props.handleChangeSetting(e, { key: [{ "requestAsssembleorInstallation": e }], type: type, subType: subType }) }}
                                    checked={props.settingFormData.requestAsssembleorInstallation}
                                />
                                {/* <label for="note">
                                    <span className="toggle-track"></span>
                                </label> */}
                                <div className="editd">
                                    {/* <Button>Edit Report</Button> */}
                                    <span style={{ color: "#ffffff" }}>
                                        Coming Soon...
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Choose time window
                        </Form.Label>
                        <Col sm={10}>
                            <div className="toggle-switch">
                                {/* <Form.Control
                                    type="checkbox"
                                    id="note"
                                    name="note"
                                    value="8"
                                /> */}
                                <Switch
                                    // disabled={roleBasedDisabled}
                                    disabled={true}
                                    onChange={(e) => { props.handleChangeSetting(e, { key: [{ "chooseTimeWindow": e }], type: type, subType: subType }) }}
                                    checked={props.settingFormData.chooseTimeWindow}
                                />
                                {/* <label for="note">
                                    <span className="toggle-track"></span>
                                </label> */}
                                <div className="editd">
                                    {/* <Button>Edit Report</Button> */}
                                    <span style={{ color: "#ffffff" }}>
                                        Coming Soon...
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Collect payment
                        </Form.Label>
                        <Col sm={10}>
                            <div className="toggle-switch">
                                {/* <Form.Control
                                    type="checkbox"
                                    id="note"
                                    name="note"
                                    value="8"
                                /> */}
                                <Switch
                                    // disabled={roleBasedDisabled}
                                    disabled={true}
                                    onChange={(e) => { props.handleChangeSetting(e, { key: [{ "collectPayment": e }], type: type, subType: subType }) }}
                                    checked={props.settingFormData.collectPayment}
                                />
                                {/* <label for="note">
                                    <span className="toggle-track"></span>
                                </label> */}
                                <div className="editd">
                                    {/* <Button>Edit Report</Button> */}
                                    <span style={{ color: "#ffffff" }}>
                                        Coming Soon...
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Form.Group>
                </Form>
            </div>
        </div>
        <div className="st-Documents">
            <div className="st-opheading">
                <h1>Scheduling conditions</h1>
            </div>
            <div className="st-oplistbox">
                <Form>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Customer can't schedule within
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                            <Col sm={1.5}>
                                <Form.Control
                                    type="number"
                                    defaultValue={props.settingFormData.customerCannotScheduleWithin}
                                    placeholder="Default hours"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskDuration": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "customerCannotScheduleWithin": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                            <span style={{ color: "white", padding: "6px", fontSize: "13px", fontWeight: "500" }}>{"hours of their delivery date"}</span>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        </div>
    </div>)
}

export default ScheduleDeliveryTab