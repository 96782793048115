export const AUTH_REGISTER_USER = "AUTH_REGISTER_USER";
export const AUTH_GET_PROFILE = "AUTH_GET_PROFILE";
export const SAVE_PROFILE_DATA = "SAVE_PROFILE_DATA";
export const SAVE_PROFILE_API = "SAVE_PROFILE_API";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const USER_LOGOUT = "USER_LOGOUT";
export const AUTH_SAVE_DATA = "AUTH_SAVE_DATA";
export const HIDE_LOADER = "HIDE_LOADER";
export const SHOW_LOADER = "SHOW_LOADER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const GET_SETTINGS = "GET_SETTINGS";
export const SAVE_SETTINGS = "SAVE_SETTINGS";
export const SAVE_SETTINGS_API = "SAVE_SETTINGS_API";
export const GET_COMMON_SERVICES = "GET_COMMON_SERVICES";
export const SAVE_COMMON_SERVICES = "SAVE_COMMON_SERVICES";
export const SAVE_COMMON_SERVICES_API = "SAVE_COMMON_SERVICES_API";
export const DELETE_COMMON_SERVICES_API = "DELETE_COMMON_SERVICES_API";
export const AUTH_CHECK_EMAIL = "AUTH_CHECK_EMAIL";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const SAVE_CURRENT_COUNTRY = "SAVE_CURRENT_COUNTRY";