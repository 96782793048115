import styled from "styled-components";

export const DateTimeSection = styled.section`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme?.colors?.bgColor};
  .cover-full-screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex;
    justify-content: center;
  }
  .line {
    display: none;
  }
  @media (max-width: 1100px) {
    .line {
      position: relative;
      display: block;
      width: 100%;
      height: 1px;
      background: ${({ theme }) => theme?.colors?.darkTextColor};
      top: 250px;
    }
    height: 100%;
  }
  .wrapper {
    width: 90%;
    display: grid;
    padding: 1.4rem;
    border-radius: 10px;
    grid-template-columns: repeat(3, 1fr);
    background: ${({ theme }) => theme?.colors?.bgColorVariant};
    margin-top: 3rem;

    @media (max-width: 1100px) {
      margin-top: 1.4rem;
      height: 90vh;
    }
    .left {
      @media (max-width: 1100px) {
        margin-top: 1.4rem;
        height: auto;
        width: 90%;
      }
      .line {
        display: none;
      }
    }
    .right {
      position: relative;
      .selected-date {
        font-weight: bold;
        .date-num {
          margin-left: 10px;
        }
        display: flex;
        justify-content: flex-start;
        margin-top: 2rem;
        margin-left: 2rem;
        p {
          font-size: 16px;
          color: ${({ theme }) => theme?.colors?.whiteColor};
        }
      }
    }
    @media (max-width: 1100px) {
      // height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      border-radius: 10px;
      margin-bottom: 3rem;
      .left {
      }
    }

    .middle {
      display: flex;
      flex-direction: column;
      .time-zone-for-pc {
        @media (max-width: 1100px) {
          display: none;
          .select-wrapper {
            display: none;
          }
        }
      }
      .time-zone-for-mobile {
        @media (max-width: 1100px) {
          display: flex;
        }
        display: none;
        .select-wrapper {
          display: none;
          @media (max-width: 1100px) {
            display: flex;
          }
        }
      }
    }
  }
`;

export const EventContainer = styled.div`
  padding: 2rem;
  text-align: left;
  @media (max-width: 1100px) {
    padding: 0;
    margin-top: 2rem;
    position: relative;
  }
  h4 {
    font-size: 14px;
    color: ${({ theme }) => theme?.colors?.darkTextColor};
    margin-bottom: 10px;
  }
  h2 {
    font-size: 24px;
    color: ${({ theme }) => theme?.colors?.whiteColor};
    @media (max-width: 388px) {
      font-size: 20px;
    }
  }
  .duration {
    display: flex;
    gap: 10px;
    margin-top: 1rem;
    .icon {
      color: ${({ theme }) => theme?.colors?.darkTextColor};
      width: 17px;
      height: 17px;
    }
    p {
      color: ${({ theme }) => theme?.colors?.darkTextColor};
      font-size: 12px;
    }
  }
`;

export const CalendarContainer = styled.div`
  padding: 2rem 2rem 0 2rem;
  position: relative;
  text-align: left;
  z-index: 1;
  .text-for-mobile {
    display: none;
  }
  .text-for-pc {
    display: block;
    color: ${({ theme }) => theme?.colors?.whiteColor};
  }
  @media (max-width: 1100px) {
    .text-for-mobile {
      display: block;
      color: ${({ theme }) => theme?.colors?.whiteColor};
    }
    .text-for-pc {
      display: none;
    }
    h4 {
      font-size: 14px;
      color: ${({ theme }) => theme?.colors?.whiteColor};
      margin-bottom: 2rem;
      @media (max-width: 1100px) {
        margin-top: 3rem;
      }
    }
  }
`;

export const TimeZoneContainer = styled.div`
  padding: 2rem;
  z-index: 3;

  @media (max-width: 1100px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h4 {
    margin-bottom: 10px;
    color: ${({ theme }) => theme?.colors?.whiteColor};
  }

  .select-wrapper {
    display: flex;

    .icon {
      color: ${({ theme }) => theme?.colors?.whiteColorVariant};
      margin-top: 10px;
    }
    .time-zone {
      //for time zone zrea width change
      width: 300px;
      @media (max-width: 1100px) {
        width: 200px;
        margin-bottom: 10px;
      }
    }

    .css-13cymwt-control {
      background: transparent !important;
      border: none;
      outline: none;
      .css-1dimb5e-singleValue {
        color: ${({ theme }) => theme?.colors?.whiteColorVariant} !important;
      }
    }
    .css-16xfy0z-control {
      background: transparent !important;
      border: none;
      outline: none;
      .css-olqui2-singleValue {
        color: ${({ theme }) => theme?.colors?.whiteColorVariant} !important;
      }
    }
    .time-zone {
      font-size: 13px;
    }
    .css-1dimb5e-singleValue {
      //display none
    }
  }
`;

export const ShowDate = styled.div`
  position: absolute;
  right: -10rem;
  top: 2rem;
  @media (max-width: 1100px) {
    position: relative;
    top: 2rem;
    left: 5rem;
    display: none;
  }
`;

export const TimePickContainerForDesktop = styled.div`
  display: flex;
  position: relative;
  width: 240px;
  top: 2rem;
  left: 2rem;
  padding: 15px 2rem;
  border: 2px solid ${({ theme }) => theme?.colors?.primaryColorVariant};
  border-radius: 10px;
  gap: 10px;
  justify-content: center;
  .time-container {
    cursor: pointer;
    position: relative;
    .slot {
      color: ${({ theme }) => theme?.colors?.primaryColorVariant};
      margin: 0
    }
  }
`;

export const TimePickContainerForDesktopSelected = styled.div`
  display: flex;
  position: relative;
  width: 240px;
  top: 2rem;
  left: 2rem;
  background: ${({ theme }) => theme?.colors?.primaryColor};
  padding: 15px 2rem;
  border: 2px solid ${({ theme }) => theme?.colors?.whiteColor};
  border-radius: 10px;
  gap: 10px;
  justify-content: center;
  .time-container {
    cursor: pointer;
    position: relative;
    .slot {
      color: ${({ theme }) => theme?.colors?.whiteColor};
      margin: 0
    }
  }
`;

export const ConfirmBtn = styled.button`
  width: 120px;
  padding: 1rem 2rem;
  margin-left: 2rem;
  color: ${({ theme }) => theme?.colors?.whiteColor};
  background: ${({ theme }) => theme?.colors?.primaryColor};
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  @media (max-width: 1100px) {
    padding: 10px;
    width: 200px;
    margin-left: 5rem;
    margin-top: 5rem;
    display: none;
  }
`;
