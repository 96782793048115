import React, { useState } from "react";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { BiTimeFive } from "react-icons/bi";
import {
  CalendarContainer,
  ConfirmBtn,
  DateTimeSection,
  EventContainer,
  ShowDate,
  TimePickContainerForDesktop,
  TimePickContainerForDesktopSelected,
  TimeZoneContainer,
} from "./SelectDateAndTime-styled";
import Calendar from "react-calendar";
import "./calendar-styled.scss";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import CompanyAndEventData from "../../data/CompanyAndEventData";
import { useMediaPredicate } from "react-media-hook";
import momentTz from "moment-timezone";
import moment from "moment-timezone";
import taskService from "../../../../../services/task.service";

const SelectDateAndTimeZone = (props) => {
  const [date, onChangeDate] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [selectedTimezoneforMobile, setSelectedTimezoneforMobile] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [selectedSlot, onSelectedSlot] = useState(null);
  const getTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const getTimeZonelocale = Intl.DateTimeFormat().resolvedOptions().locale;
  /************888888888888FOR-MOBILE************* */
  const lessthan1100 = useMediaPredicate("(max-width: 1100px)");
  /*************************FOR-PC*********************/
  const biggerThan1100 = useMediaPredicate("(min-width: 1100px)");
  const sendDataForMobile = (date) => {
    props.setStep(2)
    props.setPassingData({
      passingDate: date.toDateString(),
      passingTimeName: JSON.stringify(selectedTimezoneforMobile.altName)
        ? JSON.stringify(selectedTimezoneforMobile.altName)
        : getTimeZoneName,
      passingTimeLabal: JSON.stringify(selectedTimezoneforMobile.label)
        ? JSON.stringify(selectedTimezoneforMobile.label)
        : getTimeZonelocale,
    })
  };

  function tileDisabled({ date, view }) {
    // Disable tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of disabled dates

      let proposedDates = props.task?.proposedDates;
      let currentDate = moment(date).format("DD-MM-YYYY")
      if (proposedDates?.some((pd) => {
        let pdate = moment(pd.date)
        let todayDate = moment()
        let diff = pdate.diff(todayDate, "hours");
        console.log("todayDate", todayDate)
        console.log("pdate", pdate)
        console.log("diff", diff)
        if (diff <= props.settings[0]?.customerCannotScheduleWithin) {
          return false
        }
        return pd.formattedDate === currentDate
      })) {
        return false
      }
      else {
        return true
      }
      // return moment(date).startOf('d') < moment().startOf("d");
    }
  }

  function tileClassName({ date, view }) {
    if (
      view === 'month'
    ) {
      let proposedDates = props.task?.proposedDates.map(pd => pd.formattedDate);
      let currentDate = moment(date).format("DD-MM-YYYY")
      if (proposedDates?.some(pd => pd === currentDate)) {
        return "available"
      }
    }
  }

  const sendData = () => {
    let data = {
      selectedSlot: selectedSlot,
      taskIds: [props.task.id],
      orderDate: date
    }
    let response = props.updateScheduleDelivery(data);
    props.setStep(4)
    props.setPassingData({
      passingDate: date.toDateString(),
      passingTimeName: JSON.stringify(selectedTimezone.altName)
        ? JSON.stringify(selectedTimezone.altName)
        : getTimeZoneName,
      passingTimeLabal: JSON.stringify(selectedTimezone.label)
        ? JSON.stringify(selectedTimezone.label)
        : getTimeZonelocale,
      selectedSlot: selectedSlot
    })
  };

  const formatDate = (date) => {
    let momentDate = momentTz(date).format("LLLL");
    let formattedDate = momentDate.split(",")
    return `${formattedDate[0]}, ${formattedDate[1]}`
  }

  const setSelectedSlot = (i) => {
    onSelectedSlot(i);
    let allSlots = JSON.parse(JSON.stringify(props.slots));
    allSlots = allSlots.map(s => {
      if (s.id === i.id)
        s.selected = true
      return s
    })
    props.setSlots(allSlots)
  }

  const CompanyAndEvent = () => {
    console.log("props.settings", props.settings)
    return (
      <EventContainer>
        <h4>{props.task?.userId.companyName}</h4>
        <h2>{props.settings ? props.settings[0]?.eventName : ""}</h2>
        <div className="duration">
          <MdOutlineAccessTimeFilled className="icon" />
          <p>
            Duration <span>{props.task?.durationTime}</span> min
          </p>
        </div>
      </EventContainer>
    );
  };

  const tileContent = ({ date, view }) => (
    <div type="button" className="tile-content" ><span>{moment(date).get("date")}</span>{moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? <span>.</span> : <span style={{ paddingTop: "6px" }}></span>}</div>
  )

  return (
    <DateTimeSection>
      {lessthan1100 && (
        <div
          className="cover-full-screen"
        // onTouchStart={() => sendDataForMobile()}
        // onClick={() => sendDataForMobile()}
        >
          <div className="wrapper">
            <hr className="line" />
            <div className="left">
              <CompanyAndEvent />
            </div>
            <div className="middle">
              <CalendarContainer>
                <h4 className="text-for-pc">Select a Date & Time</h4>
                <h4 className="text-for-mobile">Select a Day</h4>
                <div className="calendar-wrapper">
                  <Calendar
                    onChange={(value) => { onChangeDate(value); sendDataForMobile(value) }}
                    value={date}
                    tileDisabled={tileDisabled}
                    tileClassName={tileClassName}
                    tileContent={tileContent}
                  />
                </div>

                <ShowDate>
                  <h4>{date?.toDateString()}</h4>
                </ShowDate>
              </CalendarContainer>

              <TimeZoneContainer className="time-zone-for-mobile">
                <h4>Time zone</h4>
                <div className="select-wrapper">
                  <BiTimeFive className="icon" />
                  <TimezoneSelect
                    isDisabled
                    className="time-zone"
                    style={{}}
                    value={selectedTimezoneforMobile}
                    onChange={setSelectedTimezoneforMobile}
                    timezones={{
                      ...allTimezones,
                      "America/Lima": "Pittsburgh",
                      "Europe/Berlin": "Frankfurt",
                    }}
                  />
                </div>
              </TimeZoneContainer>

              <ConfirmBtn onClick={() => sendData()}>Confirm</ConfirmBtn>
            </div>
            <div className="right"></div>
          </div>
        </div>
      )}

      {biggerThan1100 && (
        <div className="wrapper">
          <hr className="line" />
          <div className="left">
            <CompanyAndEvent />
          </div>
          <div className="middle">
            <CalendarContainer>
              <h4 className="text-for-pc">Select a Date & Time</h4>
              <h4 className="text-for-mobile">Select a Day</h4>
              <div className="calendar-wrapper">
                <Calendar
                  tileDisabled={tileDisabled}
                  tileClassName={tileClassName}
                  tileContent={tileContent}
                  onChange={onChangeDate}
                  value={date} />
              </div>
            </CalendarContainer>

            <TimeZoneContainer className="time-zone-for-pc">
              <h4>Time zone</h4>
              <div className="select-wrapper">
                <BiTimeFive className="icon" />
                <TimezoneSelect
                  isDisabled
                  className="time-zone"
                  style={{}}
                  value={selectedTimezone}
                  onChange={setSelectedTimezone}
                  timezones={{
                    ...allTimezones,
                    "America/Lima": "Pittsburgh",
                    "Europe/Berlin": "Frankfurt",
                  }}
                />
              </div>
            </TimeZoneContainer>

            <TimeZoneContainer className="time-zone-for-mobile">
              <h4>Time zone</h4>
              <div className="select-wrapper">
                <BiTimeFive className="icon" />
                <TimezoneSelect
                  className="time-zone"
                  style={{}}
                  value={selectedTimezone}
                  onChange={setSelectedTimezone}
                  timezones={{
                    ...allTimezones,
                    "America/Lima": "Pittsburgh",
                    "Europe/Berlin": "Frankfurt",
                  }}
                />
              </div>
            </TimeZoneContainer>

            {selectedSlot && <ConfirmBtn onClick={() => sendData()}>Confirm</ConfirmBtn>}
          </div>
          {date && <div className="right">
            <div className="selected-date">
              <p className="date-num">{formatDate(date)}</p>
            </div>

            {props.slots?.map((i, index) => {
              if (i.selected) {
                return (<TimePickContainerForDesktopSelected key={index} onClick={() => {
                  setSelectedSlot(i)
                }}>
                  <div className="time-container">
                    <p className={`slot`}>{i.after} - {i.before}</p>
                  </div>
                </TimePickContainerForDesktopSelected>)
              }
              else {
                return (<TimePickContainerForDesktop key={index} onClick={() => {
                  setSelectedSlot(i)
                }}>
                  <div className="time-container">
                    <p className={`slot`}>{i.after} - {i.before}</p>
                  </div>
                </TimePickContainerForDesktop>)
              }
            })}
          </div>}
        </div>
      )
      }
    </DateTimeSection >
  );
};

export default SelectDateAndTimeZone;
