import { useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Button
} from "react-bootstrap";
import closeicon from "../../assets/image/close-icon.png";
import mapimg from "../../assets/image/map_image.png";
import profileimg from "../../assets/image/girl.png";
import { MdShareLocation } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";
import landingPageImg from "../../assets/image/Preview_PromoPage.png"
import Switch from "react-switch";
import { SketchPicker } from 'react-color';
import businessImg from "../../assets/image/TrackingPage_Business_icon.png";
import callImg from "../../assets/image/TrackingPage_Call_icon.png";

const ExperienceCommunicationsTab = (props) => {
  // console.log(props)
  let type = props.settingTypes.CUSTOMER_EXPERIENCE;
  let subType = props.settingSubType.COMMUNICATIONS
  if (props.userData && props.userData.role === 1 && !props.userData.subAdmin) {
    type = props.settingTypes.CUSTOMER_EXPERIENCE
    subType = props.settingSubType.COMMON_COMMUNICATIONS
  }
  let roleBasedDisabled = false;
  if (props.userData && props.userData.userId && !props.userData.subAdmin && props.userData.userRole !== "admin") {
    roleBasedDisabled = true
  }
  const [createMapview, setMapview] = useState("false");
  const [createLandingview, setLandingview] = useState("false");
  const createMapviewToggle = () => {
    setMapview(!createMapview);
    props.handleSetSettingForm(null, null, props.userData.role === 1 && !props.userData.subAdmin ? props.commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS : props.commonServicesTypes.CUSTOMER_EXPERIENCE_COMMUNICATIONS)
  };
  const createLandingPageToggle = () => {
    setLandingview(!createLandingview);
    props.handleSetSettingForm(null, null, props.userData.role === 1 && !props.userData.subAdmin ? props.commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS : props.commonServicesTypes.CUSTOMER_EXPERIENCE_COMMUNICATIONS)
  }
  const onSubmitTrackingPage = (data) => {
    console.log("datadata", props)
    props.saveCommonServiceData(
      data,
      props.userData.role === 1 && !props.userData.subAdmin ? props.commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS : props.commonServicesTypes.CUSTOMER_EXPERIENCE_COMMUNICATIONS
    );
  }

  const handleThemeChange = (color, event) => {
    console.log("eeeeeeeeeee", color)
    let newCustomize = Object.assign({}, props.customizeTrackingPage);
    newCustomize.theme = color.hex;
    props.setCustomizeTrackingPage(newCustomize)
  }

  const handleEditUrl = (e, key) => {
    let newLandingPage = Object.assign({}, props.addLinkToLandingPage)
    newLandingPage[key] = e.target.value
    props.setAddLinkToLandingPage(newLandingPage)
  }
  const onChangeTrackingPage = (e, key) => {

    key = key.split(".")
    let newCustomize = Object.assign({}, props.customizeTrackingPage);
    console.log("newCustomize", newCustomize);
    newCustomize[key[0]][key[1]] = e.target.checked;
    props.setCustomizeTrackingPage(newCustomize)
  }

  const handleFeatureClick = (type) => {
    if (props.userData.role !== 1) {
      if ((!props.features || (props.features && !props.features[type])) && !roleBasedDisabled) {
        let pathName = "/Business/"
        if (type) {
          pathName += type
        }
        props.history.push({
          pathname: pathName
        })
      }
    }
  }

  return (<div className="st-oplist  cnnt-field">
    <div className="st-Documents Communications-field">
      <div className="st-opheading">
        <p>
          Send custom communications to your customers based on the triggers below
        </p>
      </div>

      <div className="cmfield">
        <div className="st-opheading">
          <h1>Delivery</h1>
        </div>
        <div className="st-oplistbox">
          <Form>
            <Form.Group as={Row} className="mb-input">
              <Form.Label column sm={3}>
                Delivery tracking page
              </Form.Label>
              <Col sm={9}>
                <div className="legalpd-field">
                  {/* {props.userData && (props.userData.role !== 1 || props.userData.subAdmin) && <div className="toggle-switch" onClick={(e) => { handleFeatureClick("smsNotificationsTriggers") }} >
                    <Switch
                      onChange={(e) =>
                        props.handleChangeSetting(e, { key: [{ "deliverySendTrackingLink": e }], type: type, subType: subType })
                      }
                      disabled={((props.features && props.features.brandedTrackingPage && !roleBasedDisabled) || (props.userData.role === 1)) ? false : true}
                      checked={props.settingFormData.deliverySendTrackingLink}
                    />
                  </div>} */}
                  <div className="editd" style={{ marginLeft: "95px" }} onClick={(e) => { handleFeatureClick("brandedTrackingPage") }}>
                    <Button onClick={createMapviewToggle}
                      disabled={((props.features && props.features.brandedTrackingPage && !roleBasedDisabled) || (props.userData.role === 1)) ? false : true}>
                      Customize Tracking Page
                    </Button>
                  </div>
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-input">
              <Form.Label column sm={3}>
                After-delivery customer experience
              </Form.Label>
              <Col sm={9}>
                <div className="legalpd-field">
                  {props.userData && (props.userData.role !== 1 || props.userData.subAdmin) && <div className="toggle-switch" onClick={(e) => { handleFeatureClick("landingPage") }}>
                    <Switch
                      onChange={(e) =>
                        props.handleChangeSetting(e, { key: [{ "deliveryAddALangingPage": e }], type: type, subType: subType })
                      }
                      disabled={((props.features && props.features.landingPage && !roleBasedDisabled) || (props.userData.role === 1)) ? false : true}
                      checked={props.settingFormData.deliveryAddALangingPage}
                    />
                  </div>}
                  <div className="editd" onClick={(e) => { handleFeatureClick("landingPage") }}>
                    <Button onClick={createLandingPageToggle} disabled={((props.features && props.features.landingPage && !roleBasedDisabled) || (props.userData.role === 1)) ? false : true}>
                      Customize the after-delivery customer experience
                    </Button>
                  </div>
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-input">
              <Form.Label column sm={3}>
                Send proof of delivery
              </Form.Label>
              <Col sm={9}>
                <div className="legalpd-field">
                  {props.userData && (props.userData.role !== 1 || props.userData.subAdmin) && <div className="toggle-switch">
                    <Switch
                      onChange={(e) =>
                        props.handleChangeSetting(e, { key: [{ "deliverySendProofOfDelivery": e }], type: type, subType: subType })
                      }
                      disabled
                      checked={props.settingFormData.deliverySendProofOfDelivery}
                    />
                  </div>}
                  <div className="editd">
                    {/* <Button>Edit Report</Button> */}
                    <span style={{ color: "#ffffff" }}>
                      Coming Soon...
                    </span>
                  </div>
                </div>
              </Col>
            </Form.Group>
          </Form>
        </div>
      </div>

    </div>

    {/* create map view popup start */}
    <div
      className={
        createMapview
          ? "opencustomer-popup"
          : "opencustomer-popup createCustomer"
      }
    >
      <div className="addCustomer-popup">
        <div className="addcustomer-slide map-view-popup">
          <div className="addcustomer-field">
            <div className="customer-import">
              <Button
                className="createcustomer-close"
                onClick={createMapviewToggle}
              >
                <img src={closeicon} alt="icon" />
              </Button>
            </div>
          </div>

          <div className="add-mapviewpopup">
            <div className="map-view-detail">
              <div className="customize-page">
                <div className="cus-color-option">
                  <h1>Customize your tracking page</h1>
                  <div style={{ background: "transparent" }}>
                    <SketchPicker onChange={handleThemeChange} color={props.customizeTrackingPage && props.customizeTrackingPage.theme ? props.customizeTrackingPage.theme : "#1b7bf4"} />
                  </div>
                </div>
                <div className="customize-check-option">
                  <div className="cus-option">
                    <h2>Image</h2>
                    <Form>
                      <Form.Group>
                        <input type="checkbox" id="Organization" checked={props.customizeTrackingPage && props.customizeTrackingPage.image && props.customizeTrackingPage.image.organizationLogo} onChange={(e) => { onChangeTrackingPage(e, "image.organizationLogo") }} />
                        <label htmlFor="Organization">Organization logo</label>
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="cus-option">
                    <h2>Communication</h2>
                    <Form>
                      <Form.Group>
                        <input type="checkbox" id="Call" checked={props.customizeTrackingPage && props.customizeTrackingPage.communication && props.customizeTrackingPage.communication.call} onChange={(e) => { onChangeTrackingPage(e, "communication.call") }} />
                        <label htmlFor="Call">Call</label>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>

              <div className="Map-layout">
                <div className="mapview-heading">
                  <h1>What your customer will see</h1>
                </div>
                <div className="map-detail-exp">
                  <div className="google-map">
                    <div className="notch"></div>
                    <div className="notch_long_area"></div>
                    <div className="notch_dot"></div>
                    <img src={mapimg} alt="img" />
                    <div className="business_container">
                      {props.customizeTrackingPage && props.customizeTrackingPage.image && props.customizeTrackingPage.image.organizationLogo && <div className="img_container" style={{ "backgroundColor": props.customizeTrackingPage && props.customizeTrackingPage.theme ? props.customizeTrackingPage.theme : "" }}>
                        <img src={businessImg} alt="business_icon" />
                      </div>}
                      <div className="text_container">
                        <p className="time">5:30 pm</p>
                        <p className="time_text">Delivery Time</p>
                      </div>
                    </div>
                    {props.customizeTrackingPage && props.customizeTrackingPage.communication && props.customizeTrackingPage.communication.call && <div className="call_img_container" style={{ "backgroundColor": props.customizeTrackingPage && props.customizeTrackingPage.theme ? props.customizeTrackingPage.theme : "" }}>
                      <img src={callImg} alt="business_icon" />
                    </div>}
                  </div>
                </div>
                <Button className="savebtn" onClick={() => { createMapviewToggle(); onSubmitTrackingPage({ deliveryCustomizeTrackingPage: props.customizeTrackingPage }) }}>Save</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* create map view popup end */}


    {/* create Landing page start */}



    <div
      className={
        createLandingview
          ? "opencustomer-popup"
          : "opencustomer-popup createCustomer"
      }
    >
      <div className="addCustomer-popup">
        <div className="addcustomer-slide map-view-popup">
          <div className="addcustomer-field">
            <div className="customer-import">
              <Button
                className="createcustomer-close"
                onClick={createLandingPageToggle}
              >
                <img src={closeicon} alt="icon" />
              </Button>
            </div>
          </div>

          <div className="add-mapviewpopup">
            <div className="map-view-detail">
              <div className="customize-page">
                <h1>Choose a path to follow</h1>
                <span className="subheading_span">When a delivery is completed, transform your tracking page into a webpage of your choice.</span>
                <div className="sms-Detail">
                  <span className="input_heading">
                    Every time a delivery is successful
                  </span>
                  <div className="editsms-heading" style={{
                    border: "2px solid #272a2f",
                    borderRadius: "10px",
                    display: "block",
                    "marginTop": "10px"
                  }}>
                    <Form>
                      <Form.Control
                        as="input"
                        id={`landingPage`}
                        value={props.addLinkToLandingPage && props.addLinkToLandingPage.url ? props.addLinkToLandingPage.url : ""}
                        style={{
                          background: "transparent",
                          border: "none",
                          color: "#ffffff",
                          fontSize: "17px"
                        }}
                        type="text"
                        placeholder="Add a link to: Google review, promotional page, etc."
                        onChange={(e) => {
                          handleEditUrl(
                            e, "url"
                          );
                        }}
                      // onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultTaskDuration": e.target.value }], type: props.settingTypes.OPERATIONS, subType: props.settingSubType.TASK_AND_MISSION }) }}
                      />
                    </Form>
                  </div>
                </div>
                <div className="sms-Detail">
                  <span className="input_heading">
                    Every time a delivery is failed
                  </span>
                  <div className="editsms-heading" style={{
                    border: "2px solid #272a2f",
                    borderRadius: "10px",
                    display: "block",
                    "marginTop": "10px"
                  }}>
                    <Form>
                      <Form.Control
                        as="input"
                        id={`landingPage`}
                        value={props.addLinkToLandingPage && props.addLinkToLandingPage.failureUrl ? props.addLinkToLandingPage.failureUrl : ""}
                        style={{
                          background: "transparent",
                          border: "none",
                          color: "#ffffff",
                          fontSize: "17px"
                        }}
                        type="text"
                        placeholder="Add a link to: Satisfaction survey, Shipping Policy, etc."
                        onChange={(e) => {
                          handleEditUrl(
                            e, "failureUrl"
                          );
                        }}
                      // onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultTaskDuration": e.target.value }], type: props.settingTypes.OPERATIONS, subType: props.settingSubType.TASK_AND_MISSION }) }}
                      />
                    </Form>
                  </div>
                </div>
              </div>

              <div className="Map-layout">
                <div className="mapview-heading">
                  <h1>An example of what your customer will see</h1>
                </div>
                <div className="editsms-detail">
                  <div className="delivery-question-ans">
                    <div className="ques-answer">
                      <div className="question-answer">
                        <div className="qs-landingpage">
                          <img src={landingPageImg} alt="landing page"></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Button className="savebtn" onClick={(e) => { createLandingPageToggle(); onSubmitTrackingPage({ deliveryAddLinkToLandingPage: props.addLinkToLandingPage }) }
                }>Save</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* create Landing page end */}

  </div >)
}

export default ExperienceCommunicationsTab