import { MdOutlineClose } from "react-icons/md";
import "./createInvoice.scss"
import RightSideBar from "./rightsidebar";
import RightSideBarCustomer from "../../InvoicingCustomer/components/rightsidebar";
import BootstrapTable from "react-bootstrap-table-next";
import ReactPaginate from "react-paginate";
import logo from "../../../assets/image/logo.png";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { useDispatch, useSelector } from "react-redux";
import { GET_CUSTOMER_API, GET_PAYMENT_API } from "../../../redux/invoicing/actionTypes";
import momentTz from "moment-timezone"
import { delay, timeZoneHeader } from "../../../helpers/helperFunctions";
import { Field } from "formik";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Services from "./services";
const timezone = { ...timeZoneHeader() }

const CreateInvoice = (props) => {
    const [windowSize, setWindowSize] = useState(null);
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.auth.user);
    const { commonServices } = useSelector((state) => state.authSaga)
    const { pageLimit, taskList, paymentList } = useSelector((state) => state.invoicing)
    const getWindowDimensions = async () => {
        const { innerWidth: width, innerHeight: height } = window;
        setWindowSize({ width, height })
        let rightsidebarWidth = 370;
        let leftSidebarWidth = 70;
        let mapDiv = document.querySelector(".invoice-view-container");
        if (mapDiv) {
            console.log("mapDiv", mapDiv)
            document.querySelector(".invoice-view-container").style.width = `${width - rightsidebarWidth - leftSidebarWidth}px`;
        }
    }

    const getWindowDimensionsCustomer = async () => {
        const { innerWidth: width, innerHeight: height } = window;
        setWindowSize({ width, height })
        let rightSideBarWrapper = document.querySelector(".right-side-bar-wrapper");
        if (rightSideBarWrapper) {
            document.querySelector(".right-side-bar-wrapper").style.display = "flex"
        };
        let rightContainer = document.querySelector(".right-sidebar-customer-container")
        if (rightContainer) {
            document.querySelector(".right-sidebar-customer-container").style.background = "#39394b";
            document.querySelector(".right-sidebar-customer-container").style.width = "370px";

        }
        if (document.querySelector(".customer-form")) {
            document.querySelector(".customer-form").style.maxHeight = `${height - 120}px`;
        }

    }

    const getUserList = async (params) => {
        dispatch({
            type: GET_CUSTOMER_API,
            data: {
                payload: { userId: userData.id, all: true },
                saveInRedux: true,
                saveLimit: pageLimit
            }
        })
    };

    useEffect(() => {
        getUserList()
    }, [])

    useEffect(() => {
        window.addEventListener("resize", getWindowDimensions);
        getWindowDimensions();
        return () => window.removeEventListener("resize", getWindowDimensions);
    }, [props.createInvoice])


    const [rightSidebarOpen, setRightSidebarOpen] = useState(false)
    const rightSidebarOpenRef = useRef(false)
    const [customerButtonText, setCustomerButtonText] = useState("Create")

    useEffect(() => {
        window.addEventListener("resize", getWindowDimensionsCustomer);
        // Call handler right away so state gets updated with initial window size
        getWindowDimensionsCustomer();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", getWindowDimensionsCustomer);
    }, [rightSidebarOpen])

    useEffect(() => {
        let service = []
        if (props.buttonText === "Edit" || props.showPaymentForm) {
            service = [...props.services]
        }
        if (taskList?.length >= 0 && !props.showPaymentForm) {
            for (let task of taskList) {
                let orderType = "Drop"
                if (task.orderType === "DI") orderType = "Drop & Service"
                if (task.orderType === "S") orderType = "Service"
                if (task.orderType === "R") orderType = "Return"
                if (task.orderType === "P") orderType = "Pick Up"
                if (task.orderType === "E") orderType = "Exchange"
                let obj = {
                    id: `dummm-${btoa(
                        Math.random().toString()
                    ).substring(0, 16)}`,
                    orderDate: task.orderDate,
                    recipientName: task.customer.firstName,
                    orderType: orderType,
                    taskId: task.id,
                    quantity: 1,
                    price: task.price ? Number(task.price) : 0,
                    description: task.notes
                }
                obj.amount = obj.quantity * obj.price
                if (!service.some(se => se.taskId === obj.taskId)) {
                    service.push(obj)
                }
            }
        }

        props.setServices(service)
    }, [taskList])


    const calculateValues = () => {
        let subTotal = props.services.reduce((prev, current) => {
            return Number(prev) + Number(current.amount)
        }, 0)
        let subTotalAfterDiscount = subTotal;
        let discountType = props.discount?.type;
        if (discountType === "percent") {
            let value = subTotal * (Number(props.discount?.value) / 100);
            subTotalAfterDiscount = subTotal - value;
        }
        else {
            subTotalAfterDiscount = subTotal - props.discount?.value;
        }
        if (subTotalAfterDiscount < 0) {
            subTotalAfterDiscount = 0;
        }
        let amount = subTotalAfterDiscount;
        let newTaxes = JSON.parse(JSON.stringify(props.invoiceFormProps.invoiceForm.taxes));
        newTaxes = newTaxes.map(tax => {
            tax.amount = subTotalAfterDiscount * (Number(tax.percentage) / 100);
            amount += tax.amount;
            tax.amount = tax.amount.toFixed(2)
            return tax
        })
        let dueAmount = amount
        if (paymentList.length) {
            let totalPayments = paymentList.reduce((prev, curr) => {
                console.log("prev", prev)
                console.log("prev", curr)
                return Number(prev) + Number(curr.amount)
            }, 0)
            dueAmount -= totalPayments
        }
        return {
            subTotal: subTotal.toFixed(2),
            subTotalAfterDiscount: subTotalAfterDiscount.toFixed(2),
            taxes: newTaxes,
            amount: amount.toFixed(2),
            dueAmount: dueAmount.toFixed(2)
        }
    }
    const ListContainer = () => {
        return (<div className="invoice-view-container">
            <div className="top-container">
                <div className="left-container">
                    <h3>Invoice</h3>
                    <div><span>Invoice No. {props.invoiceFormProps.invoiceForm?.invoiceNumber}</span></div>
                    <div><span>Invoice Date. {momentTz(props.invoiceFormProps.invoiceForm?.invoiceDate).format("LL")}</span></div>
                    <div><span>Due date. {momentTz(props.invoiceFormProps.invoiceForm?.dueDate).format("LL")}</span></div>
                </div>
                <div className="right-container">
                    <img className="logo" src={userData?.logo?.original ? userData?.logo?.original : logo} alt="logo" />
                </div>
            </div>
            <div className="address-container">
                <div className="left-container">
                    <h5>{userData.companyName}</h5>
                    <div><span>{userData.addressLine1}</span></div>
                    <div><span>{userData.addressLine2} ,{userData.city} ,{userData.country}</span></div>
                    <div><span>{userData.phoneNumber}</span></div>
                    <div><span>{userData.email}</span></div>
                </div>
                {props.invoiceFormProps.invoiceForm?.customer && <div className="right-container">
                    <h5>{props.invoiceFormProps.invoiceForm.customer?.displayName}</h5>
                    <div><span>{props.invoiceFormProps.invoiceForm.customer?.billingAddress.streetAddress}</span></div>
                    <div><span>{props.invoiceFormProps.invoiceForm.customer?.billingAddress.city}, {props.invoiceFormProps.invoiceForm.customer?.billingAddress?.province}, {props.invoiceFormProps.invoiceForm.customer?.billingAddress?.country}</span></div>
                    <div><span>{props.invoiceFormProps.invoiceForm.customer?.phoneNumber}</span></div>
                    <div><span>{props.invoiceFormProps.invoiceForm.customer?.email}</span></div>
                </div>}
            </div>
            <div className="amount-container">
                <span>${calculateValues().dueAmount} due on {momentTz(props.invoiceFormProps?.invoiceForm?.dueDate).tz(timezone.timezone).format("MMMM Do")}</span>
                {((props.buttonText === "Edit" || props.showPaymentForm) && paymentList.length === 0) && <span className="receive-payment" onClick={() => {
                    props.setShowPaymentForm(true);
                    props.setButtonText("Create");
                    props.setPaymentForm((prev) => {
                        return {
                            ...prev,
                            invoiceDetails: props.invoiceFormProps.invoiceForm,
                            invoiceId: props.invoiceFormProps.invoiceForm.id,
                            invoiceCustomerId: props.invoiceFormProps.invoiceForm.customer.id
                        }
                    })
                }}>Receive payment</span>}
                {((props.buttonText === "Edit" || props.showPaymentForm) && paymentList.length > 0) && <span className="receive-payment" onClick={() => {
                    props.setSelectedTab("PAYMENTS");
                    props.setCreateInvoice(false)
                    props.setPaymentForm(false)
                }}>See payments</span>}
            </div>
            <Services services={props.services} setServices={props.setServices} calculateValues={calculateValues} setDiscount={props.setDiscount} discount={props.discount} showPaymentForm={props.showPaymentForm} />
        </div>
        )
    }


    const initialValues = {
        currency: "CAD",
        companyName: "",
        isMerchant: false,
        displayName: "",
        email: "",
        countryCode: "+1",
        phoneNumber: "",
        website: "",
        nameOnCheque: "",
        businessNumber: "",
        shippingAddress: {
            sameAsBillingAddress: false,
            streetAddress: "",
            city: "",
            province: "",
            postalCode: "",
            country: ""
        },
        billingAddress: {
            streetAddress: "",
            city: "",
            province: "",
            postalCode: "",
            country: ""
        },
        taxId: "",
        tax: null,
        merchantInfo: {
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            userRole: ""
        },
        taxValues: []
    }
    const [customerForm, setCustomerForm] = useState(initialValues)

    const createCustomerToggle = () => {
        setRightSidebarOpen(!rightSidebarOpen);
        rightSidebarOpenRef.current = !rightSidebarOpenRef.current
    }
    // const rightsidebarprops = {
    //     createCustomerToggle,
    // }
    const rightsidebarprops = {
        setRightSidebarOpen,
        rightSidebarOpenRef,
        windowSize,
        buttonText: customerButtonText,
        setButtonText: setCustomerButtonText,
        customerForm, setCustomerForm,
        createCustomerToggle
    }

    return (<div className="create-invoice-container">
        <ListContainer />
        <RightSideBar {...props.invoiceFormProps}
            rightsidebarprops={rightsidebarprops}
            services={props.services}
            discount={props.discount}
            setDiscount={props.setDiscount}
            calculateValues={calculateValues}
            invoicePage={true}
            showPaymentForm={props.showPaymentForm}
            setShowPaymentForm={props.setShowPaymentForm}
            taskValues={props.taskValues}
            setTaskValues={props.setTaskValues}
            invoiceNumber={props.invoiceNumber}
            initialPaymentForm={props.initialPaymentForm}
            setCreateInvoice={props.setCreateInvoice} />
        {rightSidebarOpen && <RightSideBarCustomer {...rightsidebarprops} customerPage={true} />}
    </div>)
}

export default CreateInvoice;