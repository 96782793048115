import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import {
  successAlert,
  errorAlert,
  infoAlert,
} from "../../services/toast.service";
import UserService from "../../services/user.service";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Loader } from "../../components/Loader/loader";
import { useDispatch, useSelector } from "react-redux";
import extensions from "../../slices/extensions"
import { defaultSubscription } from "../../slices/subscription";
import { getProfile } from "../../slices/auth";
import authService from "../../services/auth.service";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

const StripePay = ({ builderId, productId, priceId, planName, amount, handleExtension, interval, subscriptionAction, currency }) => {
  console.log(builderId, productId, priceId, planName, amount, handleExtension, interval, subscriptionAction)
  const [loading, setLoading] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);

  const goHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("currency", currency)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!error) {
      let response
      if (!subscriptionAction) {
        response = await UserService.createUserSubscripton({
          builderId,
          amount,
          productId,
          priceId,
          planName,
          paymentMethod,
          interval,
          currency
        });
      }
      else {
        if (subscriptionAction === "Upgrade") {
          response = await authService.upgradeUserSubscripton({
            builderId,
            amount,
            productId,
            priceId,
            planName,
            paymentMethod,
            interval,
            currency
          });
        }
        else {
          response = await authService.downgradeUserSubscripton({
            builderId,
            amount,
            productId,
            priceId,
            planName,
            paymentMethod,
            interval,
            currency
          });
        }
      }
      if (response && response.data && response.data.success) {
        setLoading(false);
        successAlert(response.data.message);
        handleExtension()
        let userId = userData.user.id;
        if (userData.user.role === 2 && userData.user.userId) {
          userId = userData.user.userId
        }
        dispatch(getProfile({ userId: userData.id }))
        dispatch(extensions({ userId: userId }))
        dispatch(defaultSubscription({ userId: userId }))
      } else {
        setLoading(false);
        errorAlert(
          response.data.message ? response.data.message : "Something went wrong"
        );
      }
    } else {
      setLoading(false);
      errorAlert(error.message ? error.message : "Something went wrong");
    }
  };

  return (
    <div>
      <div className="signupinfo-form">
        <h1 className="heading">Buy Addon</h1>
        <div className="signup-form-box payment-info">
          <h2>Payment info</h2>
          <Form inline="true">
            <fieldset className="FormGroup">
              <div className="FormRow">
                <CardElement options={CARD_OPTIONS} />
              </div>
            </fieldset>

            <div className="payment-information">
              <div className="allpayment">

                <p>
                  All payment information is stored securely. Your card won’t be
                  charged unless you explicitly select a plan and confirm your
                  subscription type.
                </p>
              </div>

            </div>
            <div className="bsbtn">
              <Form.Group
                className="mb-3 privacypolicy"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  id="customCheck"
                  checked={termsAndConditions === true}
                  value={termsAndConditions}
                  onChange={(e) => setTermsAndConditions(!termsAndConditions)}
                  name="example1"
                />
                <label htmlFor="customCheck">
                  I agree to the <a href="https://gomove.ai/terms-conditions/" target="_blank" rel="noreferrer">terms of service </a>
                  and <a href="https://gomove.ai/privacy-policy/" target="_blank" rel="noreferrer">privacy policy</a>
                </label>
              </Form.Group>
              <Row>

                <Col xs={6}>
                  <div className="submitbtn">
                    <Button
                      type="submit"
                      disabled={!termsAndConditions}
                      onClick={goHandler}
                    >
                      Go
                    </Button>

                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        {loading ? <Loader /> : null}
      </div>

    </div>
  );
};

export default StripePay;
