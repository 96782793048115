import taskService from "../../services/task.service"
import authService from "../../services/auth.service"
import { socketConnection, listenToEvent } from '../../services/socket.service'
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom";
import { GoogleMap, useLoadScript, useJsApiLoader, Marker, DirectionsRenderer, InfoWindow, Polyline } from '@react-google-maps/api';
import map_options from "../dashboard/map_options";
import AssignImage from "../../assets/image/started-icon.png"
import { Table, Form, Button } from "react-bootstrap";
import { io } from "socket.io-client";
import profileimg from "../../assets/image/girl.png";
import { MdShareLocation } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";
import unassignedTaskMarker from "../../assets/image/unassignedMarker.png"
import assignedTaskMarker from "../../assets/image/assignedMarker.png"
import delayTaskMarker from "../../assets/image/delayMarker.png"
import completedTaskMarker from "../../assets/image/completedMarker.png"
import failedTaskMarker from "../../assets/image/failedTaskMarker.png"
import startedTaskMarker from "../../assets/image/startedMarker.png"
import startLocationMarker from "../../assets/image/startLocationMarker.png"
import endLocationMarker from "../../assets/image/endLocationMarker.png"
import agentMarker from "../../assets/image/agentLocation.png"
import moment from "moment";
import optimize from "../dashboard/OptimizeMission";
import Swal from "sweetalert2";
import businessImg from "../../assets/image/TrackingPage_Business_icon.png";
import callImg from "../../assets/image/TrackingPage_Call_icon.png";
const libraries = ["places"];
const PublicTracking = (props) => {
    const params = useParams();

    const myStateRef = React.useRef(null);
    const mySettingRef = React.useRef(null);
    const missionRef = React.useRef(null);
    const taskRef = React.useRef(null);
    let [task, setTask] = useState(null)
    let [mission, setMission] = useState(null)
    let [agentCoordinates, setAgentCoordinates] = useState(null)
    let [socket, setSocket] = useState(null)
    let [userSettings, setUserSettings] = useState(null)
    let [landingPage, setLandingPage] = useState(null)
    let [eta, setEta] = useState(moment().format("hh:mm a"))
    const [taskSettingData, setTaskSettingData] = useState(null);
    const [communicationSettingData, setCommunicationSettingData] = useState(null);

    useEffect(() => {
        if (params.id) {
            let userData = {
                user: {
                    role: "public",
                    id: params.id,
                    token: ""
                }
            }
            let userType = "PUBLIC_USER";
            let sockets = io(process.env.REACT_APP_SOCKET_URL, {
                query: {
                    accessToken: userData.user.token,
                    ...(userData.user.id && { id: userData.user.id }),
                    userType
                },
                transports: ['websocket']
            })
            setSocket(sockets)
            getTask(params.id)
            sockets.on("COMMON_EVENT", (data) => {
                if (data) {
                    setAgentCoordinates({ location: data.agentCoordinates, id: data.agentId })
                    getDirections(missionRef.current)
                }
                return data;
            })
            sockets.on("TASK_EVENT", async (data) => {
                if (data.type === "TASK_UPDATED") {
                    if (data.taskId && (data.taskStatus === "completed" || data.taskStatus === "failed")) {
                        if (myStateRef.current) {
                            Swal.fire({
                                title: "Task Completed",
                                text: "See what the store is offering",
                                icon: "info",
                                color: "#ffffff",
                                background: '#151719',
                                showCancelButton: true,
                                confirmButtonColor: "#1A1D1F",
                                cancelButtonColor: "#1A1D1F",
                                confirmButtonText: "OK",
                            }).then(async (result) => {
                                if (result.isConfirmed) {
                                    let url = data.taskStatus === "completed" ? myStateRef.current.url : myStateRef.current.failureUrl
                                    let findhttp = url.includes("http");
                                    if (mySettingRef.current && mySettingRef.current.deliveryAddALangingPage) {
                                        if (url && !findhttp) {
                                            url = `https://${url}`
                                        }
                                        if (url) window.open(url, '_blank');
                                    }

                                }
                            });
                        }
                    }
                }
            })
        }
    }, [params.id])

    const getSetting = async (userData) => {
        let params = {
            userId: userData.id,
            subType: [
                "TASK_AND_MISSION",
                "REGIONAL_SETTINGS",
                "COMMUNICATIONS"
            ]
        }
        // if (userData.role === 1) {
        //   params.subType = [
        //     settingSubType.COMMON_AGENT_APP,
        //     settingSubType.COMMON_API_KEY,
        //     settingSubType.COMMON_COMMUNICATIONS,
        //     settingSubType.COMMON_GROUPS,
        //     settingSubType.COMMON_LEGAL_PROTECTION,
        //     settingSubType.COMMON_NOTIFICATIONS,
        //     settingSubType.COMMON_PERFORMANCE,
        //     settingSubType.COMMON_REGIONAL_SETTINGS,
        //     settingSubType.COMMON_TASK_AND_MISSION,
        //   ]
        // }
        let response = await authService.getSettings(params);
        if (response && response.data && response.data.data && response.data.data.results.length) {
            let findTaskSetting = response.data.data.results.find(i => {
                return i.subType === "TASK_AND_MISSION"
            })
            setTaskSettingData(findTaskSetting)
        }
        if (response && response.data && response.data.data && response.data.data.results.length) {
            let findTaskSetting = response.data.data.results.find(i => {
                return i.subType === "COMMUNICATIONS"
            })
            mySettingRef.current = findTaskSetting
        }
    }

    useEffect(() => {
        if (mission && mission.id) {
            getDirections(mission)
        }
    }, [taskSettingData, mission])

    const getDirections = async (mission) => {

        let tasks = mission.tasks
        let missionData = []
        if (tasks && tasks.length) {
            for (let index in tasks) {
                let t = tasks[index];
                if (t && t.location) {
                    let data = {
                        id: t.id, ...t.location, address: t.address,
                        agentId: mission.agentId, customerId: t.customerId, position: t.position, orderType: t.orderType, teamId: mission.teamId, taskStatus: t.taskStatus,
                        taskLength: tasks ? tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                        capacity: t.capacity, capacityUnit: t.capacityUnit, eta: t.eta, etaSeconds: t.etaSeconds, missioned: t.missioned, updatedAt: t.updatedAt
                    }
                    let eta = await calculateETA(mission, t, index)
                    // data.calculatedeta = eta;
                    data.eta = eta;
                    missionData.push(data)
                }
            }
            if (missionData.length) {
                let findTask = missionData.find(i => {
                    return i.id === taskRef.current.id
                })
                if (findTask) {
                    setEta(findTask.eta)
                }
            }

        }
    };

    const calculateETA = async (mission, task, index) => {
        let currentTime = moment().format("hh:mm a");
        if (mission.missionCategory === "unassigned") {
            currentTime = assignedUnassignedETA(currentTime, mission, task, index)
        }
        else {
            if (mission.missionStatus === "started" || mission.missionStatus === "failed") {
                if (task.taskStatus === "started") {
                    let time = moment();
                    let result
                    if (mission.agentCoordinates && task.location) {
                        result = await optimize.directionsServiceHere({ locations: [] }, false, [],
                            { location: mission.agentCoordinates },
                            { location: task.location })
                    }
                    if (result && result[0]) {
                        let myroute = result[0]
                        let eta = Number(myroute.time);
                        if (eta) {
                            time.add(eta, "seconds")
                        }
                    }
                    currentTime = time.format("hh:mm a");
                    task.calculatedeta = time
                    let durationTime = Math.floor(Number(task.etaSeconds) / 60)
                    if (task.etaSeconds) currentTime = currentTime + `(${durationTime} min)`
                }
                else {
                    if (task.taskStatus !== "completed" && task.taskStatus !== "failed") {
                        if (Number(index) === 0) {
                            let time = moment().add(task.durationTime, "m")
                            currentTime = time.format("hh:mm a");
                            task.calculatedeta = time
                            let durationTime = Math.floor(Number(task.durationTime) / 60)
                            currentTime = currentTime + `(${Number(durationTime)} min)`
                        }
                        else {
                            let addTime = 0;
                            addTime += Number(mission.tasks[Number(index) - 1].durationTime)
                            addTime += Number(mission.tasks[Number(index) - 1].etaSeconds || 0) / 60;
                            addTime += Number(task.timeFromPrevTask)
                            let time = moment(mission.tasks[Number(index) - 1].calculatedeta).add(addTime, "m")
                            console.log("time", time)
                            currentTime = time.format("hh:mm a");
                            task.calculatedeta = time
                            let a = moment();
                            let diff = time.diff(a, 'm')
                            let hours = Math.floor(diff / 60);
                            let minutes = Math.floor(diff % 60);
                            let string = ``;
                            if (hours > 0) {
                                string += ` ${hours} hour`
                            }
                            if (minutes > 0) {
                                string += ` ${minutes} mins`
                            }
                            currentTime = currentTime + `(${string})`
                        }
                    }
                }
            }
            else {
                currentTime = assignedUnassignedETA(currentTime, mission, task, index)
            }
        }

        return currentTime;
    }

    const assignedUnassignedETA = (currentTime, mission, task, index) => {
        // if (mission.missionCategory === "unassigned") {
        //     currentTime = "__"
        // }
        // else {
        if (Number(index) === 0) {
            if (mission.startFrom && mission.endAt) {
                let defaultStartTime = taskSettingData && taskSettingData.defaultAfterTime ? taskSettingData.defaultAfterTime : "8:00"
                if (task.after) {
                    defaultStartTime = task.after;
                }
                let newDate = moment().format("YYYY-MM-DD");
                let time = moment(newDate + " " + defaultStartTime).add(Number(task.timeFromPrevTask), "m");
                currentTime = time.format("hh:mm a");
                task.calculatedeta = time;
            }
            else {
                let defaultStartTime = taskSettingData && taskSettingData.defaultAfterTime ? taskSettingData.defaultAfterTime : "8:00"
                if (task.after) {
                    defaultStartTime = task.after;
                }
                let newDate = moment().format("YYYY-MM-DD");
                let time = moment(newDate + " " + defaultStartTime)
                currentTime = time.format("hh:mm a");
                task.calculatedeta = time;
            }
        }
        else {
            let addTime = 0;
            addTime += Number(mission.tasks[Number(index) - 1].durationTime)
            addTime += Number(mission.tasks[Number(index) - 1].etaSeconds || 0) / 60;
            addTime += Number(task.timeFromPrevTask)
            let time = moment(mission.tasks[Number(index) - 1].calculatedeta)
            time = time.add(addTime, "m")
            currentTime = time.format("hh:mm a");

            let a = moment(mission.tasks[Number(index) - 1].calculatedeta);
            let diff = time.diff(a, 'm')
            task.calculatedeta = time
            let hours = Math.floor(diff / 60);
            let minutes = diff % 60;
            let string = ``;
            if (hours > 0) {
                string += ` ${hours} hour`
            }
            if (minutes > 0) {
                string += ` ${minutes} mins`
            }
            // console.log("currentTimecurrentTime", currentTime)
            currentTime = currentTime + `(${string})`
        }
        // }
        return currentTime
    }


    const getMission = async (id) => {
        const response = await taskService.getMissionListTracking({ _id: id }, true);
        if (response && response.data && response.data.results.length) {
            missionRef.current = response.data.results[0]
            setMission(response.data.results[0])
        }
    }

    const getTask = async (id) => {
        const response = await taskService.getTaskListTracking({ _id: id }, true);
        if (response && response.data && response.data.result && response.data.result.results.length) {
            let task = response.data.result.results[0]
            setTask(task)
            taskRef.current = task
            await getSetting(task.userId)
            if (task.missionId) await getMission(task.missionId.id)
            let params = {
                userId: task.userId.id,
                type: ["CUSTOMER_EXPERIENCE_COMMUNICATIONS"]
            }
            let getUserSettings = await authService.getCommonServices(params);
            if (getUserSettings && getUserSettings.data && getUserSettings.data.data && getUserSettings.data.data.results[0] && getUserSettings.data.data.results[0].deliveryCustomizeTrackingPage) {
                setUserSettings(getUserSettings.data.data.results[0].deliveryCustomizeTrackingPage)
            }
            else {
                let params = {
                    type: ["COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS"]
                }
                let getCommonSettings = await authService.getCommonServices(params);
                if (getCommonSettings && getCommonSettings.data && getCommonSettings.data.data && getCommonSettings.data.data.results[0] && getCommonSettings.data.data.results[0].deliveryCustomizeTrackingPage) {
                    setUserSettings(getCommonSettings.data.data.results[0])
                }
            }
            if (getUserSettings && getUserSettings.data && getUserSettings.data.data && getUserSettings.data.data.results[0] && getUserSettings.data.data.results[0].deliveryAddLinkToLandingPage) {
                myStateRef.current = getUserSettings.data.data.results[0].deliveryAddLinkToLandingPage;
            }
            if (task.agentId) setAgentCoordinates({ location: task.agentId?.coordinates, id: task.agentId.id })
        }
    }
    const [map, setMap] = useState(null)

    const onLoad = map => {
        setMap(map)
    }

    const onUnmount = map => {
        setMap(null)
    }
    let options = map_options;
    const mapContainerStyle = {
        width: '100%',
        height: '100%'
    };


    const MarkerComponent = (props) => {
        let task = props;
        let markers = [];
        if (agentCoordinates && agentCoordinates.location) {
            markers.push({
                location: agentCoordinates.location,
                id: "agent",
                markerImage: agentMarker,
                showMarkerLabel: false,
            })
        }
        let taskMarkerImage = AssignImage;
        if (task.taskStatus === "unassigned") {
            taskMarkerImage = unassignedTaskMarker
        }
        else if (task.taskStatus === "assigned") {
            taskMarkerImage = assignedTaskMarker
        }
        else if (task.taskStatus === "completed") {
            taskMarkerImage = completedTaskMarker
        }
        else if (task.taskStatus === "failed") {
            taskMarkerImage = failedTaskMarker
        }
        else if (task.taskStatus === "delayed") {
            taskMarkerImage = delayTaskMarker
        }
        else if (task.taskStatus === "started") {
            taskMarkerImage = startedTaskMarker
        }
        else if (task.taskStatus === "reached") {
            taskMarkerImage = startedTaskMarker
        }
        markers.push({
            location: task.location,
            id: task.id,
            markerImage: taskMarkerImage,
            showMarkerLabel: true
        })
        return (
            markers &&
            // markers.length && (!missionDirection.length || missionDirection.length === 1) &&
            markers.length &&
            markers.map((marker, i) => {
                if (!!marker) {
                    marker.index = i;
                    return (
                        <Marker
                            key={marker.id}
                            position={{ lat: marker.location.lat, lng: marker.location.lng }}
                            label={{
                                text: marker.showMarkerLabel ? `1` : " ",
                                color: 'white',
                            }}
                            // animation={window.google.maps.Animation.DROP}
                            icon={{
                                url: marker.markerImage ? marker.markerImage : AssignImage,
                                // size: { width: 45, height: 70 },
                                // anchor: { x: 10, y: 35 },
                                ...(!marker.markerImage && { scaledSize: { width: 22, height: 30 } }),
                            }}
                        >
                        </Marker>
                    );
                }
            })
        )
    }

    function MapDirectionsRenderer(props) {
        const [directions, setDirections] = useState(null);
        const [options, setOptions] = useState({
            preserveViewport: true,
        });
        const [error, setError] = useState(null);

        useEffect(() => {
            const { places, travelMode } = props;
            const waypoints = places.map((p) => ({
                location: { lat: p.lat, lng: p.lng },
                stopover: true,
            }));

            const origin = waypoints.shift().location;
            const destination = waypoints.pop().location;

            const directionsService = new window.google.maps.DirectionsService();

            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    // optimizeWaypoints: true,
                    travelMode: travelMode,
                    waypoints: waypoints,
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirections(result);
                    } else {
                        setError(result);
                    }
                }
            );
        });

        if (error) {

            return <></>
        }

        return directions && directions.status == 'OK' && (
            <div>
                <DirectionsRenderer key={"directionrenderer"} directions={directions} options={props.options} />
            </div>
        );
    }

    const RenderPolyline = (props) => {
        let task = props;
        let path = [];
        if (agentCoordinates && agentCoordinates.location) {
            path.push(agentCoordinates.location)
        }
        path.push(task.location)
        return (path.length > 1 ? < MapDirectionsRenderer
            key={"mapdirectionrenderer"}
            props={props}
            options={{
                suppressMarkers: true,
                // markerOptions: {
                //   icon: AssignImage,
                // },
                polylineOptions: { strokeColor: '#0e3cb0', geodesic: true }
            }
            }
            places={path}
            travelMode={window.google.maps.TravelMode.DRIVING}
        /> : <></>)


        // return (
        //     <Polyline
        //         path={path}
        //         options={
        //             {geodesic: true}
        //         }
        //     />
        // )
    }

    const popup = () => {
        if (myStateRef.current) {
            Swal.fire({
                title: "Task Completed",
                text: "See what the store is offering",
                icon: "info",
                color: "#ffffff",
                background: '#151719',
                showCancelButton: true,
                confirmButtonColor: "#1A1D1F",
                cancelButtonColor: "#1A1D1F",
                confirmButtonText: "OK",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let findhttp = myStateRef.current.includes("http");
                    let url = myStateRef.current.toString()
                    if (!findhttp) {
                        url = `https://${url}`
                    }
                    window.open(url, '_blank');
                }
            });
        }
    }
    const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY, libraries: libraries })

    return (<>
        {task && (task.taskStatus !== "completed" && task.taskStatus !== "failed") ? <div className="Map-layout" style={{ padding: "0" }}>
            <div style={{ height: "100vh", width: "100%" }}>
                {isLoaded ? <GoogleMap
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={options}
                    mapContainerStyle={mapContainerStyle}
                    center={task && task.location}
                    clickableIcons={false}
                    zoom={15}
                // defaultOptions={{ styles: mapStyles }}
                >
                    <MarkerComponent {...task} />
                    <RenderPolyline  {...task} />
                </GoogleMap> : <div>Loading</div>}
            </div>
            <div className="wrapper">
                <div className="business_container">
                    {userSettings && userSettings.image && userSettings.image.organizationLogo && <div className="img_container" style={{ "backgroundColor": userSettings && userSettings.theme ? userSettings.theme : "" }}>
                        <img src={task?.userId && task?.userId.logo?.original ? task?.userId.logo?.original : businessImg} alt="business_icon" />
                    </div>}
                    <div className="text_container">
                        <p className="time">{eta}</p>
                        <p className="time_text">Delivery Time</p>
                    </div>
                </div>
                {userSettings && userSettings.communication && userSettings.communication.call &&
                    <div className="call_img_container"
                        style={{ "backgroundColor": userSettings && userSettings.theme ? userSettings.theme : "" }}
                        onClick={() => window.open(`tel:${task.agentId.countryCode}${task.agentId.phoneNumber}`)}
                    >
                        <img src={callImg} alt="business_icon" />
                    </div>}
            </div>
        </div> :
            <div style={{
                color: "#ffffff",
                margin: "auto",
                font: "15px",
                transform: "translateX(40%)"
            }}>
                Feedback page coming soon ...
                {popup()}
            </div>}
    </>)
};


export default PublicTracking