import { useRef, useState } from "react";
import ContentLayout from "../../../components/contentlayout";
import { useDispatch, useSelector } from "react-redux";
import "./main.scss"
import { ToastContainer } from "react-toastify";
import { Loader } from "../../../components/Loader/loader";
import { useEffect } from "react";
import Invoices from "../pages/invoices";
import { USER_ROLES } from "../../../helpers/configDefaults";
import { GET_TEAM_LIST } from "../../../redux/team/actionTypes";
import { GET_USER_API } from "../../../redux/user/actionTypes";
import { GET_TASK_LIST_API } from "../../../redux/invoicing/actionTypes";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { delay } from "../../../helpers/helperFunctions";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Button, Form, FormControl } from "react-bootstrap";
import searchicon from "../../../assets/image/searchicon.png"
import Payments from "../pages/payments";

let invoiceNumber = 1;
const initialInvoiceForm = {
    customerId: "",
    customer: null,
    customerEmail: "",
    invoiceNumber: `DRAFT-00${invoiceNumber}`,
    terms: null,
    termId: "",
    invoiceDate: new Date(),
    dueDate: new Date(),
    serviceStartDate: "",
    serviceEndDate: "",
    teamIds: [],
    agentIds: [],
    taskStatus: [],
    services: [],
    invoiceId: "",
    subTotal: 0,
    amount: 0,
    taxes: [],
    invoiceCounter: invoiceNumber
}

const initialPaymentForm = {
    amount: 0,
    paymentDate: new Date(),
    paymentMethod: null,
    paymentMethodId: "",
    referenceNumber: "",
    depositedTo: null,
    depositedToId: "",
    invoiceId: ""
}

const initialTaskValues = {
    customerId: "",
    customer: null,
    serviceStartDate: "",
    serviceEndDate: "",
    teamIds: [],
    agentIds: [],
    taskStatus: []
}

const MainContainer = () => {
    const ref = useRef();
    const locationHook = useLocation()
    const dispatch = useDispatch();
    const { commonServices, countryCode, settings, loading } = useSelector(
        (state) => state.authSaga
    );
    const [services, setServices] = useState([])
    const [buttonText, setButtonText] = useState("Create and close")
    let [createInvoice, setCreateInvoice] = useState(false);
    let [discount, setDiscount] = useState({
        type: "percent",
        value: 0
    });
    const { teamList } = useSelector((state) => state.team)
    let { agentList } = useSelector((state) => state.user);
    const userData = useSelector(
        (state) => state.auth.user
    );

    const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
    const [invoiceForm, setInvoiceForm] = useState(initialInvoiceForm)
    const [paymentForm, setPaymentForm] = useState(initialPaymentForm)
    const [taskValues, setTaskValues] = useState(initialTaskValues)
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [sortBy, setSortBy] = useState("_id:desc");
    const [showPaymentForm, setShowPaymentForm] = useState(false)
    let [selectedTab, setSelectedTab] = useState("INVOICES")

    const leftSidebarProps = {
        leftSidebarOpen, setLeftSidebarOpen,
        createInvoice, setCreateInvoice
    }

    const invoicesProps = {
        createInvoice, setCreateInvoice,
        buttonText, setButtonText,
        initialValues: initialInvoiceForm,
        discount, setDiscount,
        taskValues, setTaskValues,
        showPaymentForm, setShowPaymentForm,
        paymentForm, setPaymentForm,
        services, setServices,
        initialTaskValues,
        initialPaymentForm, setSelectedTab,
        invoiceNumber
    }
    

    useEffect(() => {
        if (locationHook && locationHook.state?.createInvoice && locationHook.state?.customer) {
            handleLocationHook()
        }
    }, []);

    const handleLocationHook = async () => {
        setInvoiceForm((prev) => {
            return {
                ...prev,
                customer: locationHook.state?.customer,
                customerId: locationHook.state?.customer.id,
                taxes: locationHook.state?.customer?.tax?.taxes
            }
        })
        setTaskValues((prev) => {
            return {
                ...prev,
                customer: locationHook.state?.customer,
                customerId: locationHook.state?.customer.id,
                taxes: locationHook.state?.customer?.tax?.taxes
            }
        })
        await delay(500)
        setCreateInvoice(true)
    }

    const getTeamList = async (params, setFieldValue) => {
        let payload = {
            limit: 100,
        };
        if (
            userData.role === 1 &&
            userData.userId &&
            userData.permittedUsers &&
            userData.permittedUsers.length
        ) {
            payload.userIds = userData.permittedUsers;
        }
        if (userData.teamIds) {
            payload.teamIds = userData.teamIds;
        }
        if (userData.subAdmin) {
            delete payload.teamIds;
            delete payload.userIds;
        }

        const currentUser = userData;
        if (currentUser && currentUser.role !== 1) payload.userId = currentUser.id;

        if (params && params.userId) {
            payload.userId = params.userId;
        }
        dispatch({ type: GET_TEAM_LIST, data: { payload } });
    };

    const getUserList = async (role) => {
        let payload = {
            limit: 1000,
            role,
        };

        const currentUser = userData;
        if (currentUser && currentUser.role !== 1) payload.userId = currentUser.id;

        if (
            userData.role === 1 &&
            userData.userId &&
            userData.permittedUsers &&
            userData.permittedUsers.length
        ) {
            payload.userIds = userData.permittedUsers;
        }

        if (userData.role === 2 && userData.userId) {
            payload.userIds = [userData.userId];
        }
        // if (userData.subAdmin) {
        //     delete payload.userIds;
        // }
        dispatch({
            type: GET_USER_API,
            data: {
                payload,
                saveInRedux: true,
            },
        });
    };
    useEffect(() => {
        getTeamList();
        let role = USER_ROLES.AGENT;
        getUserList(role);
    }, [])

    useEffect(() => {
        if (taskValues && taskValues.serviceStartDate) {
            let data = {};
            if (taskValues.serviceStartDate) {
                data.startDate = taskValues.serviceStartDate
            }
            if (taskValues.serviceEndDate) {
                data.endDate = taskValues.serviceEndDate
            }
            if (taskValues.customer) {
                if (taskValues.customer.isMerchant && taskValues.customer.merchantId) {
                    data.merchantId = taskValues.customer.merchantId
                }
                else {
                    data.customer = {
                        displayName: taskValues.customer.displayName,
                        email: taskValues.customer.email,
                        phoneNumber: taskValues.customer.phoneNumber
                    }
                }
            }
            if (taskValues.agentIds) {
                data.agentIds = taskValues.agentIds
            }
            if (taskValues.teamIds) {
                data.teamIds = taskValues.teamIds
            }
            if (taskValues.taskStatus) {
                data.taskStatus = taskValues.taskStatus
            }
            if (Object.keys(data).length > 0) {
                getTaskList(data)
            }
        }
    }, [taskValues])

    const allStatusList = () => {
        return [
            {
                name: "Unassigned",
                id: "unassigned",
            },
            {
                name: "Assigned",
                id: "assigned",
            },
            {
                name: "OnGoing",
                id: "onGoing",
            },
            {
                name: "Delayed",
                id: "delayed",
            },
            {
                name: "Completed",
                id: "completed",
            },
            {
                name: "Failed",
                id: "failed",
            },
        ];
    }

    useEffect(() => {
        setInvoiceForm((prev) => {
            return {
                ...prev,
                teamIds: teamList?.map(i => i.id),
                agentIds: agentList?.map(i => i.id),
                taskStatus: allStatusList().map(i => i.id)
            }
        })
    }, [teamList, agentList])


    const getTaskList = async (payload) => {
        let data = { limit: 100 };
        if (payload) {
            data = { ...data, ...payload };
        }
        if (
            userData.role === 1 &&
            userData.userId &&
            userData.permittedUsers &&
            userData.permittedUsers.length
        ) {
            data.userIds = userData.permittedUsers;
        }
        const currentUser = userData;
        if (currentUser) data.userId = currentUser.id;
        dispatch({
            type: GET_TASK_LIST_API,
            data: {
                payload: {
                    ...data,
                },
                auth: true,
                saveInRedux: true,
                resCall: (res) => {

                },
            },
        });
    }

    const invoiceFormProps = {
        invoiceForm,
        setInvoiceForm,
        getTaskList,
        currentPage,
        setCurrentPage,
        searchText, setSearchText,
        sortBy, setSortBy,
        buttonText, setButtonText,
        initialValues: initialInvoiceForm,
        paymentForm, setPaymentForm, initialPaymentForm,
        showPaymentForm, setShowPaymentForm,
        services, setServices,
        taskValues, setTaskValues, initialTaskValues,
        invoiceNumber
    }

    return (
        <>
            <ContentLayout {...leftSidebarProps}>
                <div className="main-control-container" ref={ref}>
                    {!createInvoice && <div className="invoice-top-bar-container">
                        <div className="left-container">
                            <div className={`tab-div ${selectedTab === "INVOICES" ? "selected" : ""}`}
                                onClick={() => { setSelectedTab("INVOICES") }}>
                                <span>
                                    INVOICES
                                </span>
                            </div>
                            <div className={`tab-div ${selectedTab === "PAYMENTS" ? "selected" : ""}`}
                                onClick={() => { setSelectedTab("PAYMENTS") }}>
                                <span>
                                    PAYMENTS
                                </span>
                            </div>
                        </div>
                        <div className="right-container">
                            <div className="search-box">
                                <div className="searchbar">
                                    <Form className="d-flex">
                                        <FormControl
                                            type="search"
                                            onChange={(e) => {
                                                // if (!e.target.value) {
                                                //     setSearchData({});
                                                //     setShowSearchComponent(false);
                                                // }
                                                // setSearchText(e.target.value)
                                            }}
                                            placeholder="Search Everything..."
                                            className="me-2"
                                            aria-label="Search"
                                        />
                                        <Button className="searchicon">
                                            <img src={searchicon} alt="searchicon" />
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                            <div className="create-area"
                                // onMouseOver={handleMouseOvercreate}
                                // onMouseOut={handleMouseOutcreate} 
                                onClick={() => {
                                    setCreateInvoice(true);
                                }} >
                                {/* {isHoveringcreate && (
                                    <div className="create-text">
                                        <p>Add tasks or import data</p>
                                    </div>
                                )} */}
                                <AiOutlinePlusCircle className="create-icon" />
                                <p>Create Invoice</p>
                            </div>
                        </div>
                    </div>}
                    {selectedTab === "INVOICES" && <Invoices {...invoicesProps} invoiceFormProps={invoiceFormProps} />}
                    {selectedTab === "PAYMENTS" && <Payments {...invoicesProps} invoiceFormProps={invoiceFormProps} />}
                </div>
                <ToastContainer
                    style={{ color: "black" }}
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    // closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                {loading ? <Loader /> : null}
            </ContentLayout>
        </>
    );
};

export default MainContainer;
