import TopBar from "../../components/Topbar/Topbar";
import MapComponent from "../../components/ResizeMap/MapComponent";
import RightSideBar from "../../components/Rightsidebar/Rightsidebar";
import BottomMenu from "../../components/BottomMenu/BottomMenu";
import { useSelector } from "react-redux";

const Supervise = (props) => {
    const { unAssignedTasksList, missionsList } = useSelector((state) => state.task)
    const mapProps = {
        ...props,
        taskToUpdate: "unAssignedTasksList",
        unassignedTaskList: unAssignedTasksList,
        missionsList

    }
    const rightSidebarProps = {
        ...props
    }
    const topbarProps = {
        ...props.topbarProps
    }
    return (<>
        <div className="supervise-main-container">
            <div className="map-top-container">
                <TopBar {...topbarProps} />
                <div style={mapProps?.mapSize}><MapComponent {...mapProps} /></div>
            </div>
            <RightSideBar {...rightSidebarProps} />
            <BottomMenu {...props.bottomMenuProps} />
        </div>
    </>)
}

export default Supervise