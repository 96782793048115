import appDefaults from "../../../helpers/app-defaults";
import Sections from "./Rightsidebar/Sections";

const TaskContainer = (props) => {
    let allDatesRef = props.allDatesRef;
    return (
        <div className="task-container">
            {allDatesRef && allDatesRef.current && allDatesRef.current.map((items, index) => {
                let newProps = Object.assign({}, props)
                newProps.unAssignedTasksList = items.taskList;
                newProps.missionsList = items.missionList
                // newProps.taskClickProps.datedTasks = true
                newProps.borderColor = items.color
                newProps.sideView = false;
                newProps.dateString = items.date
                return (
                    <div className="weather-task-box" key={index}>
                        <div className="task-box"
                            onDragOver={props.dragOver}
                            onDrop={(e) => { props.onDrop(e, {}, `date_${items.date}`) }}
                        >
                            <Sections {...newProps} sectionType={appDefaults.SECTIONS.RESIZE_CONTAINER} />
                        </div>
                    </div>)
            })}
        </div>)
}
export default TaskContainer