import {
    SAVE_CUSTOMER_LIST, SAVE_DASHBOARD_METRICS, SAVE_INVOICE_LIST, SAVE_INVOICE_TASK_LIST, SAVE_PAYMENT_LIST
} from "./actionTypes";

let initialState = {
    customerList: [],
    taskList: [],
    invoiceCount: 0,
    pageCount: 1,
    pageLimit: 10,
    customerCount: 0,
    invoiceList: [],
    paymentList: [],
    dashboardMetrics: []
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_CUSTOMER_LIST:
            let customerList = JSON.parse(JSON.stringify(state.customerList))
            let customerCount = state.customerCount
            let pageCount = state.pageCount
            if (action.payload.createApi) {
                customerCount += 1
                delete action.payload.createApi;
                if (action.payload.sortBy) {
                    let split = action.payload.sortBy.split(":");
                    if (split[1] === "desc") {
                        if (action.payload.currentPage === 1) {
                            customerList.unshift(action.payload.user)
                            if (customerList.length > action.payload.pageLimit) {
                                customerList.splice(action.payload.pageLimit, 1)
                            }
                        }
                    }
                    else {
                        if (action.payload.currentPage === action.payload.pageCount) {
                            if (customerList.length < action.payload.pageLimit) {
                                customerList.push(action.payload.user)
                            }
                        }
                    }
                }

            }
            else if (action.payload.deleteApi) {
                delete action.payload.deleteApi;
                let userIndex = customerList.findIndex(i => {
                    return i.id === action.payload.user.id
                })
                console.log("userIndexuserIndex", userIndex)
                if (userIndex >= 0) {
                    customerList.splice(userIndex, 1)
                }
            }
            else if (action.payload.editApi) {
                delete action.payload.editApi;
                let userIndex = customerList.findIndex(i => {
                    return i.id === action.payload.user.id
                })
                customerList[userIndex] = action.payload.user
            }
            else {
                customerList = action.payload.result.results || [];
                customerCount = Number(action.payload.result.totalResults) || 0;
                pageCount = Number(action.payload.result.totalPages) || 1;
            }
            return {
                ...state,
                customerList,
                customerCount,
                pageCount
            }
        case SAVE_INVOICE_TASK_LIST:
            return {
                ...state,
                taskList: action.payload.result.results
            }
        case SAVE_INVOICE_LIST:
            return saveInvoiceList(state, action)
        case SAVE_PAYMENT_LIST:
            return savePaymentList(state, action)
        case SAVE_DASHBOARD_METRICS:
            let dashboardMetrics = action.payload.results
            return {
                ...state,
                dashboardMetrics: dashboardMetrics
            }
        default:
            return {
                ...state
            }
    }
}

const saveInvoiceList = (state, action) => {
    let invoiceList = JSON.parse(JSON.stringify(state.invoiceList))
    let invoiceCount = state.invoiceCount;
    let pageCount = state.pageCount;
    if (action.payload.createApi) {
        invoiceCount += 1
        delete action.payload.createApi;
        if (action.payload.sortBy) {
            let split = action.payload.sortBy.split(":");
            if (split[1] === "desc") {
                if (action.payload.currentPage === 1) {
                    invoiceList.unshift(action.payload.user)
                    if (invoiceList.length > action.payload.pageLimit) {
                        invoiceList.splice(action.payload.pageLimit, 1)
                    }
                }
            }
            else {
                if (action.payload.currentPage === action.payload.pageCount) {
                    if (invoiceList.length < action.payload.pageLimit) {
                        invoiceList.push(action.payload.user)
                    }
                }
            }
        }

    }
    else if (action.payload.editApi) {
        delete action.payload.editApi;
        let userIndex = invoiceList.findIndex(i => {
            return i.id === action.payload.user.id
        })
        if (userIndex)
            invoiceList[userIndex] = action.payload.user
    }
    else if (action.payload.deleteApi) {
        delete action.payload.deleteApi;
        let userIndex = invoiceList.findIndex(i => {
            return i.id === action.payload.user.id
        })
        if (userIndex >= 0) {
            invoiceList.splice(userIndex, 1)
        }
    }
    else {
        invoiceList = action.payload.result.results || [];
        invoiceCount = Number(action.payload.result.totalResults) || 0;
        pageCount = Number(action.payload.result.totalPages) || 1;
    }
    return {
        ...state,
        invoiceList,
        invoiceCount,
        pageCount
    }
}

const savePaymentList = (state, action) => {
    let paymentList = JSON.parse(JSON.stringify(state.paymentList))
    let invoiceCount = state.invoiceCount;
    let pageCount = state.pageCount;
    if (action.payload.createApi) {
        invoiceCount += 1
        delete action.payload.createApi;
        if (action.payload.sortBy) {
            let split = action.payload.sortBy.split(":");
            if (split[1] === "desc") {
                if (action.payload.currentPage === 1) {
                    paymentList.unshift(action.payload.user)
                    if (paymentList.length > action.payload.pageLimit) {
                        paymentList.splice(action.payload.pageLimit, 1)
                    }
                }
            }
            else {
                if (action.payload.currentPage === action.payload.pageCount) {
                    if (paymentList.length < action.payload.pageLimit) {
                        paymentList.push(action.payload.user)
                    }
                }
            }
        }

    }
    else if (action.payload.editApi) {
        delete action.payload.editApi;
        let userIndex = paymentList.findIndex(i => {
            return i.id === action.payload.user.id
        })
        if (userIndex)
            paymentList[userIndex] = action.payload.user
    }
    else if (action.payload.deleteApi) {
        delete action.payload.deleteApi;
        let userIndex = paymentList.findIndex(i => {
            return i.id === action.payload.user.id
        })
        if (userIndex >= 0) {
            paymentList.splice(userIndex, 1)
        }
    }
    else {
        paymentList = action.payload.result.results || [];
        invoiceCount = Number(action.payload.result.totalResults) || 0;
        pageCount = Number(action.payload.result.totalPages) || 1;
    }
    return {
        ...state,
        paymentList,
        invoiceCount,
        pageCount
    }
}
export default reducer;