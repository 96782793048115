import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Card,
  Form as BootstrapForm,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
  NavDropdown,
} from "react-bootstrap";
import "./hub.scss";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import logo from "../../assets/image/logo.png";
import Sidebarmenu from "../../components/sidebarmenu";
import Union from "../../assets/image/Union.png";
import downloadicon from "../../assets/image/downloadicon.png";
import searchicon from "../../assets/image/searchicon.png";
import searchF from "../../assets/image/search-f.png";
import ShortIcon from "../../assets/image/filter-icon.png";
import filterremoveicon from "../../assets/image/filter-removeicon.png";
import closeicon from "../../assets/image/close-icon.png";
import nameicon from "../../assets/image/nameicon.png";
import mailicon from "../../assets/image/mailicon.png";
import industryicon from "../../assets/image/industryicon.png";
import countryicon from "../../assets/image/countryicon.png";
import phoneicon from "../../assets/image/phoneicon.png";
import ContentLayout from "../../components/contentlayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import {
  successAlert,
  errorAlert,
  infoAlert,
} from "../../services/toast.service";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import userService from "../../services/user.service";
import hubService from "../../services/hub.service";

const Hub = (props) => {
  const locationHook = useLocation();
  const userData = useSelector((state) => state.auth.user)
  const initialValues = {
    id: "",
    hubName: "",
  };
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const [createCustomer, setcreateCustomer] = useState("false");
  const [formData, setFormData] = useState(initialValues);
  const [users, setUsers] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const params = useParams();
  const createCustomerToggle = () => {
    setcreateCustomer(!createCustomer);
  };

  useEffect(() => {
    if (locationHook && locationHook.state?.createHub) {
      createCustomerToggle()
      if (locationHook.state?.address) {
        setAddress(locationHook.state?.address)
      }
      if (locationHook.state?.location) {
        setLocation(locationHook.state?.location)
      }
    }
  }, [])

  const validationSchema = Yup.object().shape({

    hubName: Yup.string().required("Hub name is required")
    // password: Yup.string().required("Password is required!"),
  });

  const formatStatus = (cell, row) => {
    if (row.blocked) return <span>Blocked</span>;
    return <span>Active</span>;
  };
  function NameFormatter(cell, row) {
    return (
      <span>
        {row.hubName ? row.hubName : ""}
      </span>
    );
  }
  const handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };
  // const [customerActive, setcustomerActive] = useState(false);
  // const customerActiveToggle = () => setcustomerActive(true);
  // const customerActiveToggle = () => {
  //   setcustomerActive(!customerActive);
  // };
  const [isOpened, setIsOpened] = useState(false);
  const [hubTitle, setHubTitle] = useState("Add Hub");

  // function toggle() {
  //   setIsOpened(wasOpened => !wasOpened);
  // }

  const Remove = (cell, row) => {
    return (
      <>
        <span>
          <span className="filter-edit">
            <NavDropdown
              title={<img src={filterremoveicon} />}
              id="nav-dropdown"
            >
              <NavDropdown.Item
                eventKey="4.1"
                onClick={() => {
                  editHandler(row);
                }}
              >
                Edit
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="4.2"
                onClick={() => {
                  deleteHandler(row);
                }}
              >
                Delete
              </NavDropdown.Item>
            </NavDropdown>
          </span>
        </span>
      </>
    );
  };

  const getUserList = async (params) => {
    const response = await userService.getUserList(params);
    if (response.status == 200) {
      if (response.data && response.data.totalPages) {
        setPageCount(response.data.totalPages);
      }
      setUsers(response.data.results || []);
    }
    console.log("user list response  :::", response);
  };

  const getHubList = async (params) => {
    const response = await hubService.getHubList(params);
    if (response.status == 200) {
      if (response.data && response.data.totalPages) {
        setPageCount(response.data.totalPages);
      }
      setHubs(response.data.results || []);
    }
    console.log("user list response  :::", response);
  };
  //handlers
  const editHandler = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You Want to Modify Details !",
      icon: "warning",
      showCancelButton: true,
      color: "#ffffff",
      background: '#151719',
      confirmButtonColor: "#1A1D1F",
      cancelButtonColor: "#1A1D1F",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setHubTitle("Edit Hub");
        if (row.location) {
          setLocation(row.location)
        }
        if (row.address) {
          setAddress(row.address)
        }
        setFormData({
          hubName: row.hubName ? row.hubName : "",
          id: row.id ? row.id : "",
        });
        createCustomerToggle();
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    });
  };
  const deleteHandler = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      text: "Deleted records can't be restored!",
      color: "#ffffff",
      background: '#151719',
      confirmButtonColor: "#1A1D1F",
      cancelButtonColor: "#1A1D1F",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await hubService.deleteHub(row.id);
        if (response) {
          console.log("delete response ::::", response);
          Swal.fire(
            "Deleted!",
            "Hub has been Deleted Successfully !",
            "success"
          );
          getHubList({ page: currentPage, searchText });
        }
      }
    });
  };

  const RowSelectHandler = (row, isSelect, rowIndex, e) => {
    // ...
    if (isSelect) {
      const item = row.id || "";
      setSelectedItems([...selectedItems, item]);
    } else {
      const items = selectedItems.filter((sItem) => sItem != row.id);
      setSelectedItems(items);
    }
  };

  const selectAllRowsHandler = (isSelect, rows, e) => {
    if (isSelect) {
      const items = rows
        .map((rowItem) => rowItem.id)
        .filter((itemId) => {
          if (!selectedItems.includes(itemId)) return itemId;
        });
      setSelectedItems([...selectedItems, ...items]);
    } else {
      const ids = rows.map((rowItem) => rowItem.id);
      const items = selectedItems.filter((sItem) => {
        if (!ids.includes(sItem)) return sItem;
      });
      setSelectedItems(items);
    }
  };

  const deleteAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        text: "Deleted records can't be restored!",
        color: "#ffffff",
        background: '#151719',
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await userService.deleteManyUsers(selectedItems);
          if (data.success) {
            getHubList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Hub Deleted Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const blockAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        text: "You Want to Block selected Users !",
        icon: "warning",
        showCancelButton: true,
        color: "#ffffff",
        background: '#151719',
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await userService.blockManyUsers(selectedItems);
          if (data.success) {
            getHubList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Users Blocked Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: RowSelectHandler,
    onSelectAll: selectAllRowsHandler,
    bgColor: "rgb(238, 193, 213)",
  };

  useEffect(() => {
    getHubList({ page: currentPage, searchText });

  }, []);

  useEffect(() => {
    console.log("search text ::::", searchText);
    const delayDebounceFn = setTimeout(async () => {
      getHubList({ page: 1, searchText });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const handleSubmit = async (formValue) => {
    try {
      if (!location) {
        return errorAlert("Select valid address !");
      }
      console.log("hub form:::", { ...formValue, address, location })
      if (formValue.id) {
        //update here
        const response = await hubService.editHub({ ...formValue, address, location });

        if (response.data) {
          setFormData(initialValues);
          setcreateCustomer("false");
          getHubList({ page: currentPage, searchText });
          successAlert(
            response.data.message
              ? response.data.message
              : "Hub Updated Successfully !"
          );
        }
        if (!response.data) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
      } else {
        if (userData.userId) {
          formValue.userId = userData.userId
        }
        const response = await hubService.createHub({ ...formValue, address, location });
        if (response.data.success) {
          setFormData(initialValues);
          setcreateCustomer("false");
          successAlert(
            response.data.message
              ? response.data.message
              : "Hub Added Successfully !"
          );
          getHubList({ page: currentPage, searchText });
        }
        if (response.data.success === false) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
      }
    } catch (err) {
      console.log("error:::", err);
    }
  };

  const cancelhandler = () => {
    setFormData(initialValues);
    setcreateCustomer("false");
  };

  const handlePageClick = async ({ selected }) => {
    console.log("current page::", selected);
    if (selected + 1 !== currentPage) {
      setCurrentPage(selected + 1);
      await getHubList({ page: selected + 1, searchText });
    }
  };
  const [bulkOpened, setbulkOpened] = useState(false);

  function bulktoggle() {
    setbulkOpened((wasOpened) => !wasOpened);
  }
  useEffect(() => {
    if (props.location && props.location.state && props.location.state.searchText) {
      setSearchText(props.location.state.searchText)
    }

  }, []);


  let allAccess = {
    create: false,
    edit: false,
    delete: false,
    block: false
  }
  if (userData) {
    if (userData.role === 1 || (userData.role === 2 && !userData.userId)) {
      allAccess = {
        create: true,
        edit: true,
        delete: true,
        block: true
      }
    }
    else if (userData.role === 2 && (userData.userRole === "admin" || userData.userRole === "manager")) {
      allAccess = {
        create: true,
        edit: true,
        delete: true,
        block: true
      }
    }
    else if (userData.role === 4 && userData.userRole === "crud") {
      allAccess = {
        create: true,
        edit: true,
        delete: true,
        block: true
      }
    }
  }

  return (

    <>
      {/* <ContentLayout> */}
      <div className="customer-page-wrapper d-flex">
        {/* customer layout start */}
        <div className="customer-form-wrapper">
          <div className="customer-heading-box">
            <Row>
              <Col xs={6}>
                <h1>Hub</h1>
              </Col>
              <Col xs={6}>
                {(allAccess.create) && <div className="createmsbox">
                  <div onClick={() => { createCustomerToggle(); setHubTitle("Add Hub") }}>
                    <Button className="cticon" >
                      <img src={Union} alt="icon" />
                    </Button>
                    <span>Add Hub</span>
                  </div>
                  {/* <div>
                    <Button>
                      <img src={downloadicon} alt="icon" />
                    </Button>
                  </div> */}
                </div>}
              </Col>
            </Row>
          </div>
          <div className="customer-searching-box">
            <Row>
              <Col xs={6}>
                {/* <div className="create-action-option">
                    <BootstrapForm>
                      <BootstrapForm.Group className="mb-3 od-dselect">
                        <Button className="bulk-action" onClick={bulktoggle}>
                          Bulk Action
                        </Button>
                        {bulkOpened && (
                          <div className="bulkoption">
                         
                            <Button onClick={deleteAllHandler}>
                              Delete Selected
                            </Button>
                          </div>
                        )}
                      </BootstrapForm.Group>
                    </BootstrapForm>
                  </div> */}
              </Col>
              <Col xs={6}>
                <div className="searchbar">
                  <BootstrapForm className="d-flex">
                    <FormControl
                      type="search"
                      placeholder="Search or type a name"
                      className="me-2"
                      autoComplete="off"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      aria-label="Search"
                    />
                    <Button className="searchicon">
                      <img src={searchicon} alt="searchicon" />
                    </Button>
                    {/* <span className="searchF">
                        <img src={searchF} alt="icon" />
                      </span> */}
                  </BootstrapForm>
                </div>
              </Col>
            </Row>
          </div>

          {/* customer list start */}
          <div className="customer-list-wrapper">
            <BootstrapTable
              data={hubs}
              striped={true}
              hover={true}
              selectRow={selectRowProp}
              wrapperClasses="table-responsive"
            >
              <TableHeaderColumn
                dataField="id"
                isKey={true}
                dataAlign="left"
                dataSort={true}
                dataFormat={NameFormatter}
                width="200px"
              >
                Hub Name <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="address"
                dataSort={true}
                width="200px"
              >
                Address
                <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>
              {(allAccess.edit && allAccess.delete) && <TableHeaderColumn
                dataField="Action"
                dataAlign="right"
                dataFormat={Remove}
                width="150px"
              >
                Action
              </TableHeaderColumn>}
            </BootstrapTable>
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
              className="pagination"
            />
          </div>

          {/* customer list end */}
        </div>

        {/* customer layout end */}
      </div>
      {/* create customer popup start */}
      <div
        className={
          createCustomer
            ? "opencustomer-popup"
            : "opencustomer-popup createCustomer"
        }
      >
        <div className="addCustomer-popup">
          <div className="addcustomer-slide">
            <div className="addcustomer-field">
              <span className="title">{hubTitle}</span>
              <div className="customer-import">
                <div className="customer-import-field">
                  <Button>
                    <img src={Union} alt="icon" />
                  </Button>
                  <span>import</span>
                  <Button>
                    <img src={downloadicon} alt="icon" />
                  </Button>
                </div>
                <Button
                  className="createcustomer-close"
                  onClick={createCustomerToggle}
                >
                  <img src={closeicon} alt="icon" />
                </Button>
              </div>
            </div>

            <div className="addcustomer-information">
              <h2>Hub Information</h2>
              <div className="customer-form">
                <Formik
                  enableReinitialize
                  initialValues={formData}
                  render={(formProps) => {
                    return (
                      <Form>
                        <Row className="custinfo-form">
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="firstname">
                                <img src={nameicon} alt="nameicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="Hub Name"
                                name="hubName"

                              />
                              <ErrorMessage
                                name="hubName"
                                component="div"
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <BootstrapForm.Group className="mb-3">
                              <Autocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                libraries={["places"]}
                                className="form-control"
                                value={address}
                                onChange={e => { setAddress(e.target.value); setLocation(null) }}
                                options={{ types: ["address"] }}
                                placeholder="Address"
                                onPlaceSelected={(place) => {

                                  setAddress(place.formatted_address)
                                  setLocation({
                                    lat: place.geometry.location.lat(),
                                    lng: place.geometry.location.lng(),
                                  })

                                }} />
                              {/* <Field
                                  className="form-control"
                                  placeholder="Address"
                                  name="address"

                                  aria-label="Address"
                                /> */}
                              {!address && (<ErrorMessage
                                name="address"
                                component="div"
                              />)}
                            </BootstrapForm.Group>
                          </Col>

                        </Row>
                        <div className="ct-btn">
                          <Button
                            className="cncle-btn"
                            onClick={cancelhandler}
                          >
                            Cancel
                          </Button>
                          <Button className="ctaskbtn" type="submit">
                            Let’s Go
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                ></Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* create customer popup end */}
      {/* </ContentLayout> */}
      <ToastContainer
        style={{ color: "black" }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Hub;
