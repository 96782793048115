import momentTz from "moment-timezone";
import appDefaults from "../../helpers/app-defaults";
import { TASK_STATUSES } from "../../helpers/configDefaults";
import {
    CUSTOM_SAVE_ALL_TASKS_REDUX,
    CUSTOM_SAVE_MISSIONS_REDUX,
    CUSTOM_SAVE_TASKS_REDUX,
    SAVE_DELETE_MISSION_REDUX,
    SAVE_DELETE_TASKS_REDUX,
    SAVE_MISSIONS_REDUX,
    SAVE_MULTI_MISSION_REDUX,
    SAVE_REMOVE_TASKS_REDUX,
    SAVE_TASKS_REDUX,
    SAVE_TASK_HISTORY_REDUX
} from "./actionTypes";
import { timeZoneHeader } from "../../helpers/helperFunctions";
const timezone = { ...timeZoneHeader() }
let initialState = {
    tasksList: [],
    unAssignedTasksList: [],
    tasksListWithouDate: [],
    missionsList: [],
    taskCount: 0,
    taskPageCount: 1,
    taskPageLimit: 10000,
    missionPageCount: 1,
    missionPageLimit: 10000,
    taskHistory: [],
    tasksListPendingAction: [],
    datedTaskList: []
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_TASKS_REDUX:
            return saveTasksReducer(state, action)
        case SAVE_MISSIONS_REDUX:
            return saveMissionReducer(state, action)
        case CUSTOM_SAVE_TASKS_REDUX:
            let dataToReturnCustom = {
                ...state,
            }
            dataToReturnCustom[action.key] = action.unAssignedTasksList
            return dataToReturnCustom
        case CUSTOM_SAVE_ALL_TASKS_REDUX:
            let dataToReturnAllCustom = {
                ...state,
                ...action.taskToUpdate
            }
            return dataToReturnAllCustom
        case CUSTOM_SAVE_MISSIONS_REDUX:
            let dataToReturnMission = {
                ...state,
            }
            dataToReturnMission[action.key] = action.missionList
            return dataToReturnMission
        case SAVE_DELETE_TASKS_REDUX:
            return saveDeleteTaskReducer(state, action)
        case SAVE_DELETE_MISSION_REDUX:
            return saveDeleteMissionReducer(state, action)
        case SAVE_REMOVE_TASKS_REDUX:
            return saveRemoveTasksReducer(state, action)
        case SAVE_MULTI_MISSION_REDUX:
            let missionsList = JSON.parse(JSON.stringify(state.missionsList));
            if (action.payload.missions) {
                for (let miss of action.payload.missions) {
                    let findMissionIndex = missionsList.findIndex(i => i.id === miss.missionId);
                    if (findMissionIndex >= 0) {
                        missionsList[findMissionIndex].missionDetails = miss.missionDetails;
                        missionsList[findMissionIndex].missionTaskCapacity = miss.missionTaskCapacity;
                    }
                }
            }
            return {
                ...state,
                missionsList: missionsList
            }
        case SAVE_TASK_HISTORY_REDUX:
            return {
                ...state,
                taskHistory: action.payload?.history?.results || []
            }
        default:
            return {
                ...state
            }
    }
}
const saveDeleteTaskReducer = (state, action) => {
    let tasksList = JSON.parse(JSON.stringify(state.tasksList));
    let missionsList = JSON.parse(JSON.stringify(state.missionsList));
    let unAssignedTasksList = JSON.parse(JSON.stringify(state.unAssignedTasksList));
    let tasksListWithouDate = JSON.parse(JSON.stringify(state.tasksListWithouDate))
    let tasksListPendingAction = JSON.parse(JSON.stringify(state.tasksListPendingAction))
    let datedTaskList = JSON.parse(JSON.stringify(state.datedTaskList))
    const tasksToDeleteSet = new Set(action.payload.taskIds);
    // use filter() method
    // to filter only those elements
    // that need not to be deleted from the array
    const newTaskArr = tasksList.filter((task) => {
        // return those elements not in the tasksToDeleteSet
        return !tasksToDeleteSet.has(task.id);
    });
    const missions = missionsList.map((m) => {
        if (m.tasks && m.tasks.length) {
            m.tasks = m.tasks.filter((t) => {
                return !tasksToDeleteSet.has(t.id)
            });
        }
        return m;
    });
    let findUnassignedTasks = newTaskArr.filter(items => { return !!items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned });
    let findTasksWithoutDate = newTaskArr.filter(items => { return !items.actionPendingCustomer && !items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned });
    let findTasksPendingAction = newTaskArr.filter(items => { return items.actionPendingCustomer && !items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned });
    let findDatedTasks = newTaskArr.filter(items => {
        return (!items.orderDate || items.orderDate !== null)
    })
    unAssignedTasksList = [...findUnassignedTasks]
    tasksListWithouDate = [...findTasksWithoutDate]
    tasksListPendingAction = [...findTasksPendingAction]
    datedTaskList = [...findDatedTasks]
    let finalMission = missions.filter(i => i.tasks.length > 0)
    return {
        ...state,
        tasksList: newTaskArr,
        tasksListWithouDate,
        tasksListPendingAction,
        datedTaskList,
        unAssignedTasksList: unAssignedTasksList,
        missionsList: finalMission
    }
}

const saveDeleteMissionReducer = (state, action) => {
    let tasksList = JSON.parse(JSON.stringify(state.tasksList));
    let missionsList = JSON.parse(JSON.stringify(state.missionsList));
    if (action.payload.mission) {
        let taskIds = action.payload.mission.tasks.map(i => i.id)
        const tasksToDeleteSet = new Set(taskIds);
        // use filter() method
        // to filter only those elements
        // that need not to be deleted from the array
        tasksList = tasksList.filter((task) => {
            // return those elements not in the tasksToDeleteSet
            return !tasksToDeleteSet.has(task.id);
        });
        let findIndex = missionsList.findIndex(i => {
            return i.id === action.payload.mission.id
        })
        if (findIndex >= 0) {
            missionsList.splice(findIndex, 1)
        }
    }
    return {
        ...state,
        tasksList: tasksList,
        missionsList: missionsList
    }
}

const saveRemoveTasksReducer = (state, action) => {
    let tasksList = JSON.parse(JSON.stringify(state.tasksList));
    let missionsList = JSON.parse(JSON.stringify(state.missionsList));
    let unAssignedTasksList = JSON.parse(JSON.stringify(state.unAssignedTasksList));
    let tasksListWithouDate = JSON.parse(JSON.stringify(state.tasksListWithouDate))
    let tasksListPendingAction = JSON.parse(JSON.stringify(state.tasksListPendingAction))
    let datedTaskList = JSON.parse(JSON.stringify(state.datedTaskList))
    const tasksToRemoveSet = new Set(action.payload.taskIds);
    // use filter() method
    // to filter only those elements
    // that need not to be deleted from the array
    let newtasksList = tasksList.map(i => {
        if (action.payload.taskIds.includes(i.id)) {
            i.agentId = null;
            i.teamId = null;
            i.missionId = null;
            i.missioned = false;
            i.taskStatus = TASK_STATUSES.UNASSIGNED;
            i.taskCategory = TASK_STATUSES.UNASSIGNED;
        }
        return i
    })
    let newTaskArr = newtasksList.filter((task) => {
        // return those elements not in the tasksToDeleteSet
        return tasksToRemoveSet.has(task.id);
    });
    // newTaskArr = newTaskArr.map(i => {
    //     i.agentId = null;
    //     i.teamId = null;
    //     i.missionId = null;
    //     i.missioned = false;
    //     i.taskStatus = TASK_STATUSES.UNASSIGNED;
    //     i.taskCategory = TASK_STATUSES.UNASSIGNED;
    //     return i
    // })
    let findUnassignedTasks = newTaskArr.filter(items => { return !!items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned });
    let findTasksWithoutDate = newTaskArr.filter(items => { return !items.actionPendingCustomer && !items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned });
    let findTasksPendingAction = newTaskArr.filter(items => { return items.actionPendingCustomer && !items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned });
    let findDatedTasks = newTaskArr.filter(items => {
        return (!items.orderDate || items.orderDate !== null)
    })
    unAssignedTasksList = [...unAssignedTasksList, ...findUnassignedTasks]
    tasksListWithouDate = [...tasksListWithouDate, ...findTasksWithoutDate]
    tasksListPendingAction = [...tasksListPendingAction, ...findTasksPendingAction]
    datedTaskList = [...findDatedTasks]
    const missions = missionsList.map((m) => {
        if (m.tasks && m.tasks.length) {
            m.tasks = m.tasks.filter((t) => {
                return !tasksToRemoveSet.has(t.id)
            });
        }
        return m;
    });
    let finalMission = missions.filter(m => m.tasks.length > 0)
    return {
        ...state,
        // tasksList: newTaskArr,
        unAssignedTasksList: unAssignedTasksList,
        missionsList: finalMission,
        tasksList: newtasksList,
        tasksListWithouDate: tasksListWithouDate,
        tasksListPendingAction: tasksListPendingAction,
        datedTaskList: datedTaskList
    }
}


const saveMissionReducer = (state, action) => {
    let tasksList = JSON.parse(JSON.stringify(state.tasksList));
    let missionsList = JSON.parse(JSON.stringify(state.missionsList));
    let unAssignedTasksList = JSON.parse(JSON.stringify(state.unAssignedTasksList));
    let tasksListWithouDate = JSON.parse(JSON.stringify(state.tasksListWithouDate))
    let tasksListPendingAction = JSON.parse(JSON.stringify(state.tasksListPendingAction))
    let datedTaskList = JSON.parse(JSON.stringify(state.datedTaskList))
    let newTasksList = []
    if (action.payload.createApi) {
        delete action.payload.createApi;
        if (action.payload.sortBy) {
            let split = action.payload.sortBy.split(":");
            if (split[1] === "desc") {
                if (action.payload.currentPage === 1) {
                    if (action.payload.taskIds && action.payload.taskIds.length) {
                        for (let id of action.payload.taskIds) {
                            let findTaskIndex = unAssignedTasksList.findIndex(item => {
                                return item.id === id
                            })
                            let findDatedTaskIndex = datedTaskList.findIndex(item => {
                                return item.id === id
                            })
                            if (findTaskIndex >= 0 || findDatedTaskIndex >= 0) {
                                if (unAssignedTasksList[findTaskIndex]) unAssignedTasksList[findTaskIndex].deleted = true
                                if (datedTaskList[findTaskIndex]) datedTaskList[findTaskIndex].deleted = true
                            }
                        }
                    }
                    let consolidatedTasks = unAssignedTasksList;
                    if (action.payload?.viewType === appDefaults.SECTIONS.RESIZE_CONTAINER) {
                        consolidatedTasks = datedTaskList
                    }
                    for (let task of consolidatedTasks) {
                        if (!task.deleted) {
                            newTasksList.push(task)
                        }
                    }
                    missionsList.unshift(action.payload.mission)
                    if (missionsList.length > action.payload.pageLimit) {
                        missionsList.splice(action.payload.pageLimit, 1)
                    }
                }
            }
            else {
                if (action.payload.currentPage === action.payload.pageCount) {
                    if (action.payload.taskIds && action.payload.taskIds.length) {
                        for (let id of action.payload.taskIds) {
                            let findTaskIndex = unAssignedTasksList.findIndex(item => {
                                return item.id === id
                            })
                            let findDatedTaskIndex = datedTaskList.findIndex(item => {
                                return item.id === id
                            })
                            if (findTaskIndex >= 0 || findDatedTaskIndex >= 0) {
                                if (unAssignedTasksList[findTaskIndex]) unAssignedTasksList[findTaskIndex].deleted = true
                                if (datedTaskList[findTaskIndex]) datedTaskList[findTaskIndex].deleted = true
                            }
                        }
                    }
                    let consolidatedTasks = unAssignedTasksList;
                    if (action.payload?.viewType === appDefaults.SECTIONS.RESIZE_CONTAINER) {
                        consolidatedTasks = datedTaskList
                    }
                    for (let task of consolidatedTasks) {
                        if (!task.deleted) {
                            newTasksList.push(task)
                        }
                    }
                    if (missionsList.length < action.payload.pageLimit) {
                        missionsList.push(action.payload.mission)
                    }
                }
            }
        }
        tasksList = tasksList.map(t => {
            if (action.payload.taskIds.some(i => i === t.id)) {
                t.missioned = true;
            }
            return t
        })
        newTasksList = JSON.parse(JSON.stringify(tasksList));
    }
    else if (action.payload.addTaskApi) {
        let unAssignedTasksList = JSON.parse(JSON.stringify(state.unAssignedTasksList));

        const tasksToDeleteSet = new Set(action.payload.taskIds);
        // use filter() method
        // to filter only those elements
        // that need not to be deleted from the array
        const newUnassignedTaskArr = unAssignedTasksList.filter((task) => {
            // return those elements not in the tasksToDeleteSet
            return !tasksToDeleteSet.has(task.id);
        });
        delete action.payload.addTaskApi;
        let missionIndex = missionsList.findIndex(i => {
            return i.id === action.payload.mission.id
        })
        if (missionIndex >= 0) {
            missionsList[missionIndex] = action.payload.mission
        }
        newTasksList = newUnassignedTaskArr;
        tasksList = tasksList.map(t => {
            if (action.payload.taskIds.some(i => i === t.id)) {
                t.missioned = true;
            }
            return t
        })
        newTasksList = JSON.parse(JSON.stringify(tasksList));
    }
    else if (action.payload.assignApi) {
        delete action.payload.assignApi;
        if (action.payload.mission) {
            for (let task of action.payload.mission.tasks) {
                let findIndex = tasksList.findIndex(i => i.id === task.id)
                console.log("findIndex", findIndex)
                if (findIndex >= 0) {
                    tasksList[findIndex] = task
                }
            }
            let missionIndex = missionsList.findIndex(i => {
                return i.id === action.payload.mission.id
            })
            if (missionIndex >= 0) {
                missionsList[missionIndex] = action.payload.mission
            }
        }
        newTasksList = JSON.parse(JSON.stringify(tasksList));
    }
    else if (action.payload.optimizeApi) {
        delete action.payload.optimizeApi;
        if (action.payload.mission) {
            let missionIndex = missionsList.findIndex(i => {
                return i.id === action.payload.mission.id
            })
            if (missionIndex >= 0) {
                missionsList[missionIndex] = action.payload.mission
            }
        }
        newTasksList = JSON.parse(JSON.stringify(state.tasksList));
    }
    else if (action.payload.changePositionApi) {
        let findMissionIndex = missionsList.findIndex(i => i.id === action.payload.mission.id);
        if (findMissionIndex >= 0) {
            missionsList[findMissionIndex] = action.payload.mission;
        }
        newTasksList = JSON.parse(JSON.stringify(state.tasksList));
    }
    else {
        missionsList = action.payload.results || [];
        newTasksList = JSON.parse(JSON.stringify(state.tasksList));
    }
    let findUnassignedTasks = newTasksList.filter(items => { return !!items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned });
    let findDatedTasks = newTasksList.filter(items => {
        return (!items.orderDate || items.orderDate !== null)
    })
    let dataToReturnMission = {
        ...state,
        missionsList: missionsList,
        unAssignedTasksList: findUnassignedTasks,
        datedTaskList: findDatedTasks,
        tasksList: tasksList,
        ...(action.payload.totalPages && !action.payload.saveLimit && { missionPageCount: action.payload.totalPages }),
        ...(action.payload.limit && !action.payload.saveLimit && { missionPageLimit: action.payload.limit })
    }
    return dataToReturnMission
}

const saveTasksReducer = (state, action) => {
    let tasksList = JSON.parse(JSON.stringify(state.tasksList))
    let unAssignedTasksList = JSON.parse(JSON.stringify(state.unAssignedTasksList))
    let tasksListWithouDate = JSON.parse(JSON.stringify(state.tasksListWithouDate))
    let tasksListPendingAction = JSON.parse(JSON.stringify(state.tasksListPendingAction))
    let datedTaskList = JSON.parse(JSON.stringify(state.datedTaskList))
    let missionsList = JSON.parse(JSON.stringify(state.missionsList))
    let taskCount = state.taskCount;
    if (action.payload.createApi) {
        delete action.payload.createApi;
        if (action.payload.sortBy) {
            let split = action.payload.sortBy.split(":");
            if (split[1] === "desc") {
                if (action.payload.currentPage === 1) {
                    tasksList.unshift(action.payload.task)
                    if (action.payload.task.orderDate) {
                        unAssignedTasksList.unshift(action.payload.task)
                    }
                    else {
                        tasksListWithouDate.unshift(action.payload.task)
                    }
                    if (tasksList.length > action.payload.pageLimit) {
                        tasksList.splice(action.payload.pageLimit, 1)
                    }
                }
            }
            else {
                if (action.payload.currentPage === action.payload.pageCount) {
                    if (tasksList.length < action.payload.pageLimit) {
                        tasksList.push(action.payload.task)
                        if (action.payload.task.orderDate) {
                            unAssignedTasksList.push(action.payload.task)
                        }
                        else {
                            tasksListWithouDate.push(action.payload.task)
                        }
                    }
                }
            }
        }
        taskCount = taskCount + 1;
    }
    else if (action.payload.createMultiApi) {
        delete action.payload.createMultiApi;
        if (action.payload.sortBy) {
            let split = action.payload.sortBy.split(":");
            if (split[1] === "desc") {
                if (action.payload.currentPage === 1) {
                    tasksList.unshift(...action.payload.tasks)
                    if (action.payload.task.orderDate) {
                        unAssignedTasksList.unshift(...action.payload.task)
                    }
                    else {
                        tasksListWithouDate.unshift(...action.payload.task)
                    }
                    if (tasksList.length > action.payload.pageLimit) {
                        tasksList.splice(action.payload.pageLimit, 1)
                    }
                }
            }
            else {
                if (action.payload.currentPage === action.payload.pageCount) {
                    if (tasksList.length < action.payload.pageLimit) {
                        tasksList.push(...action.payload.tasks)
                        if (action.payload.task.orderDate) {
                            unAssignedTasksList.push(...action.payload.task)
                        }
                        else {
                            tasksListWithouDate.push(...action.payload.task)
                        }
                    }
                }
            }
        }
        taskCount = taskCount + Number(action.payload.tasks.length);
    }
    else if (action.payload.editApi) {
        delete action.payload.editApi;
        let taskIndex = tasksList.findIndex(i => {
            return i.id === action.payload.task.id
        })
        if (action.payload.task.missionId) {
            let findMissionIndex = missionsList.findIndex(i => i.id === action.payload.task.missionId.id)
            if (findMissionIndex >= 0) {
                let tasks = missionsList[findMissionIndex].tasks;
                let findTaskIndex = tasks.findIndex(i => i.id === action.payload.task.id);
                if (findTaskIndex >= 0) {
                    tasks[findTaskIndex] = action.payload.task
                }
                missionsList[findMissionIndex].tasks = tasks;
                if(action.payload.totalTime && Number(action.payload.totalTime)>0){
                    if(missionsList[findMissionIndex].missionDetails)
                        missionsList[findMissionIndex].missionDetails.totalTime = action.payload.totalTime
                }
            }
        }
        else if (!action.payload.task.missionId && action.payload.missionId) {
            let findMissionIndex = missionsList.findIndex(i => {return i.id === action.payload.missionId })
            if (findMissionIndex >= 0) {
                let tasks = missionsList[findMissionIndex].tasks;
                let findTaskIndex = tasks.findIndex(i => i.id === action.payload.task.id);
                if (findTaskIndex >= 0) {
                    tasks.splice(findTaskIndex, 1)
                }
                missionsList[findMissionIndex].tasks = tasks
                if(action.payload.totalTime && Number(action.payload.totalTime)>0){
                    if(missionsList[findMissionIndex].missionDetails)
                        missionsList[findMissionIndex].missionDetails.totalTime = action.payload.totalTime
                }
            }
        }
        if (taskIndex >= 0) {
            tasksList[taskIndex] = action.payload.task
        }
    }
    else {
        tasksList = action.payload.result.results || [];
        taskCount = action.payload.taskCountresult || 0;
    }

    let unassignedList = (items) => {
        let currentDate = true
        if (action.payload?.currentDate) {
            let orderDate = momentTz(items.orderDate)._a;
            if (momentTz(orderDate).tz(timezone.timezone).format("YYYY-MM-DD") !== momentTz(action.payload?.currentDate).tz(timezone.timezone).format("YYYY-MM-DD")) {
                currentDate = false
            }
        }
        return !!items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned && currentDate
    }
    let findUnassignedTasks = tasksList.filter(items => { return unassignedList(items) });
    let findTasksWithoutDate = tasksList.filter(items => { return !items.actionPendingCustomer && !items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned });
    let findTasksPendingAction = tasksList.filter(items => { return items.actionPendingCustomer && !items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned });
    let findDatedTasks = tasksList.filter(items => {

        return (items.orderDate && items.orderDate !== null)
    })
    unAssignedTasksList = findUnassignedTasks
    tasksListWithouDate = findTasksWithoutDate
    tasksListPendingAction = findTasksPendingAction
    datedTaskList = findDatedTasks
    let finalMission = missionsList.filter(m => m.tasks.length > 0)
    let dataToReturn = {
        ...state,
        tasksList: tasksList,
        missionsList: finalMission,
        unAssignedTasksList: unAssignedTasksList,
        tasksListWithouDate: tasksListWithouDate,
        tasksListPendingAction: tasksListPendingAction,
        taskCount: taskCount,
        datedTaskList: datedTaskList,
        ...(action.payload.totalPages && !action.payload.saveLimit && { taskPageCount: action.payload.totalPages }),
        ...(action.payload.limit && !action.payload.saveLimit && { taskPageLimit: action.payload.limit })
    }
    return dataToReturn
}

export default reducer;