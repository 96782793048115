import React, { useState, useEffect } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import userService from "../../services/user.service";
import "./Settings.scss";
import moment from "moment";


const Transactions = () => {
  const [transactions, setTransactions] = useState([])

  useEffect(() => {
    getBuilder();
  }, []);


  const getBuilder = async () => {
    const response = await userService.getStripeTransactions({});
    if (response.status == 200) {
      console.log(response.data.charges.data, "response-data")
      setTransactions(response.data.charges.data || []);
    }
  }
  function invoiceUrl(cell, row) {
    const data = row.receipt_url ?
      <span><a href={row.receipt_url} target="_blank">Download Invoice</a></span>
      : <span>No Data</span>

    return data ? data : <span>No Date</span>;
  }

  function transactionId(cell, row) {
    const data = row.id ?
      <span>{row.id}</span>
      : <span>No Data</span>

    return data ? data : <span>No Date</span>;
  }
  function Subscriptionprice(cell, row) {
    const data = row.amount ?
      <div className="Subscriptionprice"><span>${row.amount / 100}</span></div>
      :
      <div className="Subscriptionprice">No Data</div>

    return data ? data : <div className="Subscriptionprice">No Data</div>
  }
  function dateissued(cell, row) {
    const data = row.created && row.created ?
      <span>{moment(row.created * 1000).format("MMM DD, YYYY")}</span>
      : <span>No Data</span>

    return data ? data : <span>No Data</span>
  }
  function paymentmethod(cell, row) {
    const data = row.payment_method_details && row.payment_method_details.card && row.payment_method_details.card.brand ?
      <span>{row.payment_method_details.card.brand}</span>
      : <span>No Data</span>

    return data ? data : <span>No Data</span>
  }
  function last4(cell, row) {
    const data = row.payment_method_details && row.payment_method_details.card && row.payment_method_details.card.last4 ?
      <span>{row.payment_method_details.card.last4}</span>
      : <span>No Data</span>

    return data ? data : <span>No Data</span>
  }
  function expMonth(cell, row) {
    const data = row.payment_method_details && row.payment_method_details.card && row.payment_method_details.card.exp_month ?
      <span>{row.payment_method_details.card.exp_month}</span>
      : <span>No Data</span>

    return data ? data : <span>No Data</span>
  }
  function expYear(cell, row) {
    const data = row.payment_method_details && row.payment_method_details.card && row.payment_method_details.card.exp_year ?
      <span>{row.payment_method_details.card.exp_year}</span>
      : <span>No Data</span>

    return data ? data : <span>No Data</span>
  }



  return (
    <div className="subscription-field">
      <div className="sb-heading-invoices">
        <h1>Invoices</h1>
      </div>
      {/* <div className="sp-heading">
        <h2>Professional operations Plan</h2>
        <p>Billed quarterly, 1 year subscription</p>
      </div> */}
      <div className="sb-content">
        <div className="customer-list-wrapper">
          <BootstrapTable
            data={transactions}
            striped={true}
            hover={true}
            wrapperClasses="table-responsive"
          >
            <TableHeaderColumn
              dataField="id"
              isKey={true}
              dataAlign="left"
              dataSort={true}
              dataFormat={transactionId}
              width="250px"
            >
              Id
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="left"
              dataSort={true}
              dataFormat={dateissued}
              width="150px"
            >
              Date
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="paymentmethod"
              dataSort={true}
              dataFormat={paymentmethod}
              width="250px"
            >
              Payment method
            </TableHeaderColumn>
            {/* <TableHeaderColumn
                dataField="expMonth"
                dataSort={true}
                dataFormat={expMonth}
                width="250px"
              > 
                Expiry Month
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="expYear"
                dataSort={true}
                dataFormat={expYear}
                width="250px"
              > 
                Expiray Year
              </TableHeaderColumn> */}
            <TableHeaderColumn
              dataField="last4"
              dataSort={true}
              dataFormat={last4}
              width="250px"
            >
              Card Last 4 digit
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="Subscriptionprice"
              dataSort={true}
              dataFormat={Subscriptionprice}
              width="150px"
            >
              Amount
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="invoiceUrl"
              dataSort={true}
              dataFormat={invoiceUrl}
              width="150px"
            >
              Download Invoice
            </TableHeaderColumn>

          </BootstrapTable>
        </div>


      </div>

    </div>
  );
}

export default Transactions