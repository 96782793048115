
export const apiConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 180000,
    headers: {
        "Content-Type": "application/json",
    },
    validateStatus: function (status) {
        return status >= 200 && status < 600;
    },
};

