import { MdOutlineAllInbox, MdOutlineClose } from "react-icons/md"
import { delay, escapeRegex, formatAMPM, renderCustomerName, timeZoneHeader } from "../../../helpers/helperFunctions"
import TaskRightSideDetails from "../../SupervisePlanner/components/Rightsidebar/RightSideTaskDetails"
import "./createCustomer.scss"
import moment from "moment"
import { IoMdTime } from "react-icons/io"
import { Formik, Form as FormF, Field, ErrorMessage, FieldArray } from "formik"
import { Button, Form } from "react-bootstrap"
import Select, { components } from 'react-select';
import { useRef } from "react"
import { useState } from "react"
import { useEffect } from "react"
import plus from "../../../assets/image/plus.png";
import bin from "../../../assets/image/bin-white.png";
import authService from "../../../services/auth.service"
import { useDispatch, useSelector } from "react-redux"
import { DELETE_COMMON_SERVICES_API, GET_COMMON_SERVICES, SAVE_COMMON_SERVICES_API } from "../../../redux/auth/actionTypes"
import * as Yup from "yup"
import { CREATE_CUSTOMER_API, EDIT_CUSTOMER_API } from "../../../redux/invoicing/actionTypes"
import { errorAlert, successAlert } from "../../../services/toast.service"
import { commonServicesTypes } from "../../../helpers/configDefaults"
const timezone = { ...timeZoneHeader() }

const initialValuesTaxForm = {
    name: "",
    taxes: [{
        id: `dummm-${btoa(
            Math.random().toString()
        ).substring(0, 16)}`,
        taxName: "",
        percentage: 0
    }]
}


const CustomerForm = (props) => {
    let { rightsidebarprops } = props
    let { customerForm, setCustomerForm, initialValues } = rightsidebarprops;
    let currencies = [{
        id: "CAD",
        name: "CAD Canadian Dollar"
    }, {
        id: "USD",
        name: "USD United States Dollar"
    },
    {
        id: "EUR",
        name: "EUR Euro"
    }]
    const [addNewTax, setAddNewTax] = useState(false)
    const [topTaxForm, setTopTaxForm] = useState(0)
    const [allTaxes, setAllTaxes] = useState([])
    const [taxForm, setTaxForm] = useState(initialValuesTaxForm)
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.auth.user);
    const { commonServices } = useSelector((state) => state.authSaga)
    const { customerList, pageCount, pageLimit } = useSelector((state) => state.invoicing)
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [sortBy, setSortBy] = useState("_id:desc")

    const taxSchema = Yup.object().shape({
        taxes: Yup.array()
            .of(
                Yup.object().shape({
                    taxName: Yup.string().required('Tax Name Required'), // these constraints take precedence
                    percentage: Yup.string().required('Percentage Required'), // these constraints take precedence
                })
            )
            .required('Must have taxes') // these constraints are shown if and only if inner constraints are satisfied
            .min(1, 'Minimum of 1 taxes'),
        name: Yup.string().required("Group Name Required")
    });

    const customerValidationSchema = Yup.object().shape({
        currency: Yup.string().required("Currency is required"),
        companyName: Yup.string().required("Please enter company name"),
        displayName: Yup.string().required("Please enter display name"),
        phoneNumber: Yup.number().required("Please enter phone number"),
        nameOnCheque: Yup.string().required("Please enter name on cheque"),
        billingAddress: Yup.object().shape({
            streetAddress: Yup.string().required('Enter street address'),
            city: Yup.string().required("Enter City"),
            country: Yup.string().required("Enter Country"),
            province: Yup.string().required("Enter Province"),
            postalCode: Yup.string().required("Enter Postal code")
        }),
        shippingAddress: Yup.object().shape({
            streetAddress: Yup.string().required('Enter street address'),
            city: Yup.string().required("Enter City"),
            country: Yup.string().required("Enter Country"),
            province: Yup.string().required("Enter Province"),
            postalCode: Yup.string().required("Enter Postal code")
        })
    });

    const handleSubmitTaxForm = (data) => {
        let dataForApi = {
            type: "TAX_GROUP",
            ...data
        };
        let dataApi = {
            payload: dataForApi,
            resCall: (res) => {
                setTaxForm(initialValuesTaxForm)
                setAddNewTax(false)
            }
        }
        dispatch({ type: SAVE_COMMON_SERVICES_API, data: dataApi })
    }

    const handleSubmitCustomerForm = (formValue, { resetForm }) => {
        delete formValue?.taxValues
        delete formValue?.tax?.userId
        delete formValue?.userRoleValue
        if (formValue.phoneNumber) {
            let phone = escapeRegex((formValue.phoneNumber.toString()));
            formValue.phoneNumber = phone;
        }
        let dataForApi = {
            ...formValue
        };
        let dataApi = {
            payload: dataForApi,
            resCall: (res) => {
                if (!res.success) {
                    errorAlert(res.message || "Something went wrong")
                }
                else {
                    // successAlert("Customer created successfully.")
                    handleCancel(resetForm)
                }
            },
            sortBy: sortBy,
            pageLimit: pageLimit,
            pageCount: pageCount,
            currentPage

        }
        let api = dataForApi.id ? EDIT_CUSTOMER_API : CREATE_CUSTOMER_API
        dispatch({ type: api, data: dataApi })
    }


    const handleDeleteCommonService = (id) => {
        let dataApi = {
            payload: {
                commonServiceId: id
            }
        }
        dispatch({ type: DELETE_COMMON_SERVICES_API, data: dataApi })
    }


    const ValueContainer = ({ children, ...props }) => {
        return (
            components.ValueContainer && (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            )
        );
    };

    let taxFormRef = useRef();


    useEffect(() => {
        let taxes = [];
        taxes = commonServices?.filter(cm => cm.type === "TAX_GROUP")
        setAllTaxes(taxes)
        let newFormData = { ...customerForm }
        newFormData.taxValues = taxes;
        newFormData.taxId = taxes[0]?.id;
        setCustomerForm(newFormData)
    }, [commonServices])

    const CustomMenuList = ({ selectProps, ...props }) => {
        const { onTeamInputChange, addTeamInputValue, onMenuInputFocus, addInput } = selectProps;
        // Copied from source
        const ariaAttributes = {
            "aria-autocomplete": "list",
            "aria-label": selectProps["aria-label"],
            "aria-labelledby": selectProps["aria-labelledby"]
        };

        return (
            <div>
                <div
                    className="MenuButton"
                    onClick={(e) => {
                        setTopTaxForm(e.clientY)
                        setAddNewTax(true)
                    }}
                >
                    {/* <img style={{ width: "18px" }} src={"Union"} alt="icon" /> */}
                    <span className={"AddTeamSpan"}>+ Add new tax group</span>
                </div>
                <components.MenuList {...props} selectProps={selectProps} />
            </div >
        );
    };

    const handleCancel = async (resetForm) => {
        rightsidebarprops.createCustomerToggle()
        // rightsidebarprops.setRightSidebarOpen(false);
        // rightsidebarprops.rightSidebarOpenRef.current = false;
        await delay(1000)
        resetForm()
        setCustomerForm(initialValues)
        props.setButtonText("Create")
    }

    const CustomSelectField = ({ field, colors, form, ...props }) => {
        const containerRef = useRef(null);
        const [isFocused, setIsFocused] = useState(false);
        const [inputValue, setInputValue] = useState("");

        const onDomClick = (e) => {
            let menu = containerRef.current.querySelector(".select__menu");
            if (
                !containerRef.current.contains(e.target) ||
                !menu ||
                !menu.contains(e.target)
            ) {
                setIsFocused(false);
                setInputValue("");
            }
        };

        useEffect(() => {
            document.addEventListener("mousedown", onDomClick);
            return () => {
                document.removeEventListener("mousedown", onDomClick);
            };
        }, []);

        return (
            <div ref={containerRef}>
                <Select
                    className="react-tax-select-container"
                    classNamePrefix="react-tax-select"
                    closeMenuOnSelect={true}
                    formProps={{ form }}
                    defaultValue={allTaxes[0]}
                    value={form.values["tax"]}
                    placeholder="Select a tax code"
                    menuIsOpen
                    autoFocus={false}
                    isSearchable={false}
                    options={getOptions()}
                    getOptionLabel={(option) => { return `${option.name}` }}
                    getOptionValue={(option) => option.id}
                    onChange={(newValue, actionMeta) => {
                        form.setFieldValue("taxId", newValue.id)
                        form.setFieldValue("tax", JSON.parse(JSON.stringify(newValue)))
                        // form.setFieldValue("teamIds", newValue.map(i => i.id));?
                    }}
                    components={{
                        MenuList: CustomMenuList,
                        ValueContainer: ValueContainer,
                        Option: CustomSelectOption
                    }}
                    {...{
                        menuIsOpen: isFocused || undefined,
                        isFocused: isFocused || undefined
                    }}
                />
            </div>
        );
    };

    const CustomSelectOption = props => {
        return (
            <components.Option {...props}>
                <div>
                    {props.data.name}{props.data.id !== "all" && <img onClick={(e) => { e.stopPropagation(); handleDeleteCommonService(props.data.id) }} src={bin} style={{ width: "18px", float: "right", cursor: "pointer" }} alt="icon" />}
                </div>
            </components.Option>
        )
    }


    const getOptions = () => { console.log("allTaxes", allTaxes); return [...allTaxes] };

    const validate = (values, props /* only available when using withFormik */) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Required';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        else if (!values.taxId) {
            errors.taxId = 'Select valid tax';
        }
        else if (values.isMerchant && !values.merchantInfo.email) {
            errors.merchantInfo = {
                email: 'Merchant email is required'
            };
        }
        else if (values.isMerchant && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.merchantInfo?.email)) {
            errors.merchantInfo = {
                email: 'Invalid email address'
            };
        }
        else if (values.isMerchant && !values.merchantInfo.password) {
            errors.merchantInfo = {
                password: 'Merchant password is required'
            };
        }
        //...

        return errors;
    };

    const taxValidate = (values, props /* only available when using withFormik */) => {
        const errors = {};
        if (!values.taxes || values.taxes?.length === 0) {
            errors.taxes = 'Required';
        }

        //...

        return errors;
    };

    const CreateTaxForm = () => {
        return (
            <Formik
                initialValues={initialValuesTaxForm}
                validationSchema={taxSchema}
                validate={taxValidate}
                onSubmit={handleSubmitTaxForm}
                enableReinitialize={true}
            >{({
                setFieldValue,
                setFieldTouched,
                values,
                resetForm,
                errors,
                touched,
            }) => (
                <div className="tax-form" style={{ top: `${topTaxForm - 270}px` }} ref={taxFormRef}>
                    <FormF>
                        <div className="sub-heading">
                            <span className="text">New taxes group</span>
                        </div>
                        <Form.Group className="input-invoice-container">
                            <label>Group name</label>
                            <Field
                                className="input-area"
                                placeholder=""
                                name={"name"}
                                maxLength="35"
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                aria-label="Group Name"
                            />
                            {errors.name && touched.name ? (
                                <div>{errors.name}</div>
                            ) : null}
                        </Form.Group>
                        <FieldArray
                            name="taxes"
                            render={arrayHelpers => (
                                <div>
                                    {values.taxes && values.taxes.length > 0 ? (
                                        values.taxes.map((tax, index) => (
                                            <div key={index}>
                                                <Form.Group key={index} className="input-invoice-container">
                                                    <label>Tax name</label>
                                                    <div className="tax-input">
                                                        <Field
                                                            className="input-area"
                                                            placeholder=""
                                                            name={`taxes[${index}].taxName`}
                                                            maxLength="35"
                                                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                                            aria-label="Tax Name"
                                                        />
                                                        <div className="delete-button" onClick={(e) => {
                                                            // let newTaxForm = JSON.parse(JSON.stringify(taxForm));
                                                            // let taxIndex = newTaxForm.taxes.findIndex(tax => tax.id = t.id)
                                                            // if (taxIndex >= 0) {
                                                            //     newTaxForm.taxes.splice(taxIndex, 1)
                                                            // }
                                                            // setTaxForm(newTaxForm)
                                                            arrayHelpers.remove(index)
                                                        }}><img src={bin} alt={"delete"} /></div>
                                                    </div>
                                                    <ErrorMessage className="error-message-invoice"
                                                        name={`taxes[${index}].taxName`}
                                                        component="div"
                                                    />
                                                </Form.Group>
                                                <Form.Group className="input-percentage-container">
                                                    <Field
                                                        className="input-area"
                                                        placeholder=""
                                                        name={`taxes[${index}].percentage`}
                                                        maxLength="35"
                                                        type="number"
                                                        min="0"
                                                        step="any"
                                                        presicion={2}
                                                        onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                                        aria-label="Percentage"
                                                    />
                                                    <label>%</label>
                                                    <ErrorMessage className="error-message-invoice"
                                                        name={`taxes[${index}].percentage`}
                                                        component="div"
                                                    />
                                                </Form.Group>
                                            </div>
                                        ))
                                    ) : null}
                                    <div className="add-button" >
                                        <img src={plus} alt={"plus"} onClick={(e) => {
                                            arrayHelpers.push({
                                                taxName: "",
                                                percentage: 0,
                                                id: `dummm-${btoa(
                                                    Math.random().toString()
                                                ).substring(0, 16)}`
                                            })
                                        }} />
                                    </div>
                                </div>
                            )}
                        />
                        <div className="buttons">
                            <Button onClick={() => {
                                setTaxForm(initialValuesTaxForm)
                                setAddNewTax(false)
                            }}>
                                Cancel
                            </Button>
                            <Button type="submit" onClick={(e) => { }}>
                                {props.buttonText}
                            </Button>
                        </div>
                    </FormF>
                </div>
            )}
            </Formik >
        );
    }

    let roleOptions = [{ id: "crud", name: "Can create tasks", description: "" },
    { id: "view", name: "Can only view", description: "" }]


    const CustomRoleSelectOption = props => (
        <components.Option {...props}>
            <div>
                {props.data.name}
            </div>
            <div style={{ fontSize: "10px" }}>
                {props.data.description}
            </div>
        </components.Option>
    )

    const CustomRoleSelectField = ({ field, colors, form, ...props }) => {

        const containerRef = useRef(null);
        return (
            <div ref={containerRef}>
                <Select
                    className="react-tax-select-container"
                    classNamePrefix="react-tax-select"
                    closeMenuOnSelect={true}
                    fieldProp={"merchantInfo.userRole"}
                    defaultValue={form.values.userRoleValue}
                    value={form.values.userRoleValue}
                    placeholder="Assign Role"
                    autoFocus={false}
                    isSearchable={false}
                    options={roleOptions}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => option.id}
                    onChange={(newValue, actionMeta) => {
                        const { action, option, removedValue } = actionMeta;
                        form.setFieldValue("userRoleValue", newValue)
                        form.setFieldValue("merchantInfo.userRole", newValue.id);
                    }}
                    components={{
                        ValueContainer: ValueContainer,
                        Option: CustomRoleSelectOption
                    }}
                />
            </div>
        );
    }


    const CustomPasswordField = ({ field, colors, form, ...props }) => {
        const [showHidePassword, changeShowHidePassword] = useState(false);

        return (
            <input
                type={showHidePassword ? "text" : "password"}
                {...field}
                className="customer-password"
                name="merchantInfo.password"
                placeholder="Password"
                autoComplete="new-password"
            />
            // <div className="input-invoice-container">

            //     <img className="eye-icon" onClick={(e) => { changeShowHidePassword(!showHidePassword) }} src={showHidePassword ? hide : view} alt="passwordicon" />
            // </div>
        );
    }
    console.log("customerForm", customerForm)
    return (
        <div className="create-customer-container"><Formik
            initialValues={customerForm}
            validationSchema={customerValidationSchema}
            validate={validate}
            onSubmit={handleSubmitCustomerForm}
            enableReinitialize={true}
        >{({
            setFieldValue,
            setFieldTouched,
            values,
            resetForm,
            errors,
            touched,
        }) => (
            <> <div className="customer-form"> <FormF>
                <div className="sub-heading">
                    <span className="text">Customer</span>
                </div>
                <div className="input-invoice-container">
                    <label>Currency</label>
                    <Field
                        // className="form-control"
                        name="currency"
                        as="select"
                        onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                        defaultValue={values["currency"]}
                        onChange={(e) => {
                            setFieldValue("currency", e.target.value)
                        }}
                    >
                        {currencies.map((curr, index) => {
                            return <option key={index} value={curr.id}>{curr.name}</option>
                        })}
                    </Field>
                </div>
                <div className="input-invoice-container">
                    <Form.Group>
                        <Form.Check
                            className="input-checkbox"
                            type="checkbox"
                            value={values["isMerchant"]}
                            checked={values["isMerchant"]}
                            onChange={(e) => {
                                setFieldValue("isMerchant", e.target.checked)
                            }}
                            label="This customer is a merchant"
                        />
                    </Form.Group>
                </div>
                <div className="sub-heading">
                    <span className="text">Name and Contact</span>
                </div>
                <div className="two-inputs">
                    <Form.Group className="input-invoice-container">
                        <label>Company name</label>
                        <Field
                            className="input-area"
                            placeholder="John Doe"
                            name="companyName"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="Company Name"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="companyName"
                            component="div"
                        />
                    </Form.Group>
                    <Form.Group className="input-invoice-container">
                        <label>Display name</label>
                        <Field
                            className="input-area"
                            placeholder="John Doe"
                            name="displayName"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="Display Name"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="displayName"
                            component="div"
                        />
                    </Form.Group>
                </div>
                <div className="two-inputs">
                    <Form.Group className="input-invoice-container">
                        <label>Email</label>
                        <Field
                            className="input-area"
                            placeholder="JohnDoe@example.com"
                            name="email"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="Email"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="email"
                            component="div"
                        />
                    </Form.Group>
                    <Form.Group className="input-invoice-container">
                        <label>Phone Number</label>
                        <div className="input-container-phone">
                            <Field className="country-code" aria-label="Phone" name="countryCode" as="select"
                                defaultValue={"+1"}
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}>
                                <option value="+1" >+1</option>
                                <option value="+44" >+44</option>
                                <option value="+91">+91</option>
                            </Field>
                            <Field
                                className="input-area input-area-phone"
                                placeholder="Phone"
                                name="phoneNumber"
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                aria-label="Phone"
                            />
                        </div>
                        <ErrorMessage className="error-message-invoice"
                            name="phoneNumber"
                            component="div"
                        />
                        <ErrorMessage className="error-message-invoice" name="countryCode" component="div" />
                    </Form.Group>
                </div>
                <Form.Group className="input-invoice-container">
                    <label>Website</label>
                    <Field
                        className="input-area"
                        placeholder="JohnDoe.com"
                        name="website"
                        maxLength="35"
                        onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                        aria-label="Website"
                    />
                    <ErrorMessage className="error-message-invoice"
                        name="website"
                        component="div"
                    />
                </Form.Group>
                <Form.Group className="input-invoice-container">
                    <label>Name to print on cheques</label>
                    <Field
                        className="input-area"
                        placeholder="John Doe"
                        name="nameOnCheque"
                        maxLength="35"
                        onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                        aria-label="Name to print on cheques"
                    />
                    <ErrorMessage className="error-message-invoice"
                        name="nameOnCheque"
                        component="div"
                    />
                </Form.Group>
                <div className="sub-heading">
                    <span className="text">Addresses</span>
                </div>
                <div className="small-sub-heading">
                    <span className="text">Billing address</span>
                </div>
                <Form.Group className="input-invoice-container">
                    <label>Street address</label>
                    <Field
                        className="input-area"
                        placeholder=""
                        name="billingAddress.streetAddress"
                        // defaultValue={values.billingAddress["streetAddress"]}
                        onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                        aria-label="Street Address"
                    // onChange={e=>setFieldValue("billingAddress.streetAddress", e.target.value)}
                    />
                    <ErrorMessage className="error-message-invoice"
                        name="billingAddress.streetAddress"
                        component="div"
                    />
                </Form.Group>
                <div className="two-inputs">
                    <Form.Group className="input-invoice-container">
                        <label>City</label>
                        <Field
                            className="input-area"
                            placeholder=""
                            name="billingAddress.city"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="City"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="billingAddress.city"
                            component="div"
                        />
                    </Form.Group>
                    <Form.Group className="input-invoice-container">
                        <label>Province</label>
                        <Field
                            className="input-area"
                            placeholder=""
                            name="billingAddress.province"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="Province"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="billingAddress.province"
                            component="div"
                        />
                    </Form.Group>
                </div>
                <div className="two-inputs">
                    <Form.Group className="input-invoice-container">
                        <label>Postal code</label>
                        <Field
                            className="input-area"
                            placeholder=""
                            name="billingAddress.postalCode"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="Postal code"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="billingAddress.postalCode"
                            component="div"
                        />
                    </Form.Group>
                    <Form.Group className="input-invoice-container">
                        <label>Country</label>
                        <Field
                            className="input-area"
                            placeholder=""
                            name="billingAddress.country"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="Country"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="billingAddress.country"
                            component="div"
                        />
                    </Form.Group>
                </div>
                <div className="small-sub-heading">
                    <span className="text">Shipping address</span>
                </div>
                <div className="input-invoice-container">
                    <Form.Group>
                        <Form.Check
                            className="input-checkbox"
                            type="checkbox"
                            value={values.shippingAddress?.sameAsBillingAddress}
                            checked={values.shippingAddress?.sameAsBillingAddress}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setFieldValue("shippingAddress", { ...values.billingAddress, sameAsBillingAddress: e.target.checked })
                                }
                                setFieldValue("shippingAddress.sameAsBillingAddress", e.target.checked)
                            }}
                            label="Same as billing address"
                        />
                    </Form.Group>
                </div>
                <Form.Group className="input-invoice-container">
                    <label>Street address</label>
                    <Field
                        className="input-area"
                        placeholder=""
                        name="shippingAddress.streetAddress"
                        // defaultValue={values.billingAddress["streetAddress"]}
                        onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                        aria-label="Street Address"
                    // onChange={e=>setFieldValue("billingAddress.streetAddress", e.target.value)}
                    />
                    <ErrorMessage className="error-message-invoice"
                        name="shippingAddress.streetAddress"
                        component="div"
                    />
                </Form.Group>
                <div className="two-inputs">
                    <Form.Group className="input-invoice-container">
                        <label>City</label>
                        <Field
                            className="input-area"
                            placeholder=""
                            name="shippingAddress.city"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="City"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="shippingAddress.city"
                            component="div"
                        />
                    </Form.Group>
                    <Form.Group className="input-invoice-container">
                        <label>Province</label>
                        <Field
                            className="input-area"
                            placeholder=""
                            name="shippingAddress.province"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="Province"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="shippingAddress.province"
                            component="div"
                        />
                    </Form.Group>
                </div>
                <div className="two-inputs">
                    <Form.Group className="input-invoice-container">
                        <label>Postal code</label>
                        <Field
                            className="input-area"
                            placeholder=""
                            name="shippingAddress.postalCode"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="Postal code"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="shippingAddress.postalCode"
                            component="div"
                        />
                    </Form.Group>
                    <Form.Group className="input-invoice-container">
                        <label>Country</label>
                        <Field
                            className="input-area"
                            placeholder=""
                            name="shippingAddress.country"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="Country"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="shippingAddress.country"
                            component="div"
                        />
                    </Form.Group>
                </div>
                <div className="sub-heading">
                    <span className="text">Additional information</span>
                </div>
                <div className="small-sub-heading">
                    <span className="text">Taxes</span>
                </div>
                <Form.Group className="input-invoice-container">
                    <label>Business number</label>
                    <Field
                        className="input-area"
                        placeholder=""
                        name="businessNumber"
                        maxLength="35"
                        onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                        aria-label="Business number"
                    />
                    <ErrorMessage className="error-message-invoice"
                        name="businessNumber"
                        component="div"
                    />
                </Form.Group>
                <div className="input-invoice-container">
                    <label>Tax code</label>
                    <Field
                        className="form-control"
                        name="taxId"
                        // as="select"
                        component={CustomSelectField}
                    >
                    </Field>
                    <ErrorMessage className="error-message-invoice"
                        name="taxId"
                        component="div"
                    />
                </div>
                {values["isMerchant"] && <>
                    <div className="sub-heading">
                        <span className="text">Merchant Access</span>
                    </div>
                    <div className="two-inputs">
                        <Form.Group className="input-invoice-container">
                            <label>First name</label>
                            <Field
                                className="input-area"
                                placeholder="John Doe"
                                name="merchantInfo.firstName"
                                maxLength="35"
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                aria-label="First Name"
                            />
                            <ErrorMessage className="error-message-invoice"
                                name="merchantInfo.firstName"
                                component="div"
                            />
                        </Form.Group>
                        <Form.Group className="input-invoice-container">
                            <label>Last name</label>
                            <Field
                                className="input-area"
                                placeholder="John Doe"
                                name="merchantInfo.lastName"
                                maxLength="35"
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                aria-label="Last Name"
                            />
                            <ErrorMessage className="error-message-invoice"
                                name="merchantInfo.lastName"
                                component="div"
                            />
                        </Form.Group>
                    </div>
                    <div className="two-inputs">
                        <Form.Group className="input-invoice-container">
                            <label>Email</label>
                            <Field
                                className="input-area"
                                placeholder=""
                                name="merchantInfo.email"
                                maxLength="35"
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                aria-label="Email"
                            />
                            <ErrorMessage className="error-message-invoice"
                                name="merchantInfo.email"
                                component="div"
                            />
                        </Form.Group>
                        <Form.Group className="input-invoice-container">
                            <label>Password</label>
                            <Field
                                className="input-area"
                                placeholder="John Doe"
                                name="merchantInfo.password"
                                maxLength="35"
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                aria-label="First Name"
                                component={CustomPasswordField}
                            />
                            <ErrorMessage className="error-message-invoice"
                                name="merchantInfo.password"
                                component="div"
                            />
                        </Form.Group>
                    </div>
                    <div className="input-invoice-container">
                        <label>Role</label>
                        <Field
                            className="form-control"
                            name="merchantInfo.userRole"
                            // as="select"
                            component={CustomRoleSelectField}
                        >
                        </Field>
                        <ErrorMessage className="error-message-invoice" name="userRole" component="div" />
                    </div></>}
                <div className="fixed-div">
                    <div className="fixed-buttons">
                        <Button onClick={() => {
                            handleCancel(resetForm)
                        }}>
                            Cancel
                        </Button>
                        <Button type="submit"
                            onClick={(e) => { }}>
                            {props.buttonText}
                        </Button>
                    </div>
                </div>
            </FormF>
            </div>
            </>
        )}
        </Formik>
            {
                addNewTax && <CreateTaxForm />
            }</div>
    );
}

export default CustomerForm