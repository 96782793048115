import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import filterremoveicon from "../../assets/image/filter-removeicon.png";
import mastercard from "../../assets/image/Credit_asset/mastercard.png";
import amex from "../../assets/image/Credit_asset/amex.png";
import dinersclub from "../../assets/image/Credit_asset/dinersclub.png";
import discover from "../../assets/image/Credit_asset/discover.png";
import jcb from "../../assets/image/Credit_asset/jcb.png";
import visa from "../../assets/image/Credit_asset/visa.png";
import closeicon from "../../assets/image/close-icon.png";
import Swal from "sweetalert2";
import userService from "../../services/user.service";
import {
    Button,
    NavDropdown,
} from "react-bootstrap";
import "./Settings.scss";
import moment from "moment";
import { getProfile } from "../../slices/auth";
import authService from "../../services/auth.service";
import StripePay from "./StripePay";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

const stripeTestPromise = loadStripe(PUBLIC_KEY);
const PaymentMethod = () => {
    const [paymentMethods, setPaymentMehods] = useState([])
    const [apiData, setApiData] = useState(null)
    const [createCustomer, setcreateCustomer] = useState(false)
    const createCustomerToggle = () => {
        setcreateCustomer(!createCustomer);
    };

    useEffect(() => {
        getPaymentMethods();
    }, []);

    const getPaymentMethods = async () => {
        const response = await authService.getPaymentMethods({});
        if (response.status == 200) {
            console.log(response.data.paymentMethods, "response-data")
            setPaymentMehods(response.data.paymentMethods || []);
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    let cards = {
        "amex": amex,
        "diners": dinersclub,
        "discover": discover,
        "mastercard": mastercard,
        "visa": visa,
        "jcb": jcb
    }
    return (
        <div className="subscription-field">
            <div className="sb-heading">
                <h1>Payment Method</h1>
            </div>
            <div className="payment-methods">
                {paymentMethods && paymentMethods.length ? <div className="card-details">
                    <span className="details">{paymentMethods[0].card ? capitalizeFirstLetter(paymentMethods[0].card.funding) : ""} {paymentMethods[0].type ? capitalizeFirstLetter(paymentMethods[0].type) : "Card"}</span>
                    <span className="details"><img className="card-image" src={cards[paymentMethods[0].card.brand] ? cards[paymentMethods[0].card.brand] : mastercard} alt="card" />{paymentMethods[0].card ? paymentMethods[0].card.last4.padStart(8, "*") : ""}</span>
                    <button className="change-payment-method" onClick={() => {
                        setApiData({
                            type: "CHANGE_PAYMENT_METHOD",
                            detachPaymentMethod: paymentMethods[0].id
                        })
                        createCustomerToggle()
                    }}>Change Payment Method</button>
                </div> : <div>
                    <button className="add-payment-method" onClick={() => {
                        setApiData({
                            type: "CHANGE_PAYMENT_METHOD"
                        })
                        createCustomerToggle()
                    }}>Add Payment Method</button>
                </div>}
            </div>
            <div
                className={
                    createCustomer
                        ? "opencustomer-popup createCustomer"
                        : "opencustomer-popup"
                }
            >
                <div className="addCustomer-popup">
                    <div className="addcustomer-slide">
                        <div className="addcustomer-field">
                            <div className="customer-import">
                                <Button
                                    className="createcustomer-close"
                                    onClick={createCustomerToggle}
                                >
                                    <img src={closeicon} alt="icon" />
                                </Button>
                            </div>
                        </div>

                        <div className="addcustomer-information Business-cus-popup">
                            <div className="customer-form">
                                <Elements stripe={stripeTestPromise}>
                                    <StripePay apiData={apiData} getPaymentMethods={getPaymentMethods} createCustomerToggle={createCustomerToggle}
                                    />
                                </Elements>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentMethod