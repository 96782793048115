// TODO: Resolve lint issues
/* eslint-disable */
import {
    put,
    takeLatest,
    all,
    call,
} from 'redux-saga/effects'

/********************* ACTION TYPE ********************* */
import { GET_USER_BUILDER_LIST } from './actionTypes';

/*************************** ACTIONS *******************/
import { saveUserBuilderList } from './actions';
import { apiSagaHandler } from '../network/saga';
import { errorReducerHandler } from '../alert/actions'
import { showWarning } from '../alert/actions';
import { clean } from '../../helpers/helperFunctions';
const API_URL = "builder/"
/************************ USER SAGA *******************************/
function* saga() {
    yield all([
        call(getUserBuilderListApi),
    ]);
}

function* getUserBuilderListApi() {
    yield takeLatest(GET_USER_BUILDER_LIST, getUserBuilderListHandler)
}

function* getUserBuilderListHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}user-builders`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield put(saveUserBuilderList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

export default saga;
