import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import { CALL_API } from './actionTypes';
import { callApi } from '../../helpers/apiHandler';
import { HIDE_LOADER, SHOW_LOADER } from '../auth/actionTypes';
import { errorReducerHandler } from '../alert/actions';
// import { HIDE_LOADER, SHOW_LOADER } from '../loaderReducer/actionType';
// import { HIDE_LOADER, SHOW_LOADER } from '../userReducer/actionType';

/******************************* MAIN SAGA ********************************* */
function* amslSaga() {
    yield all([
        call(apiSaga),
    ]);
}

/******************************* SEARCH JOB ********************************* */

function* apiSaga() {
    yield takeLatest(CALL_API, apiSagaHandler);
}


export function* apiSagaHandler(config) {
    // const userInfo = yield select(state => state.auth);
    /*** extract props ***/
    // const { onSuccess, onError, config } = data;
    /*** missing config props ***/
    if (!config) return;
    /*** initialize config for api ***/
    const apiConfig = { ...config };
    /*** show loader ****/
    if (config.loading) yield put({ type: SHOW_LOADER });
    /*** check for base url origin to make api call if its in props (default is the a1024 api) ***/
    /*** if auth is set to true in props it will get the token and set it to config ***/
    if (config.auth) {
        apiConfig.auth = yield call(getToken);
    }
    const res = yield call(callApi, { ...apiConfig });

    const finalRes = yield call(handleRes, res, config);
    if (config.loading) yield put({ type: HIDE_LOADER });
    return finalRes;
}

/********************** HANDLE API RESPONSE ******************/
function* handleRes(res, data) {

    if (res) {
        if (Number(res.status) === 200 || Number(res.status) === 201) {
            return res.data;
        } else {
            yield put(errorReducerHandler(res));
            // res.errors
            // const res = yield call(sessionExpiredSagaHandler);
            // yield call(apiSagaHandler, data);
            return;
        }
    }
    // yield put(errorReducerHandler({}));
}


/******************************* GET TOKEN ********************************* */
function* getToken() {
    const token = yield select(state => state.auth?.user?.token);
    return token;
}

export default amslSaga;
