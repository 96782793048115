import React, { useState, useEffect } from "react";
import {
  Form as BootstrapForm,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  NavDropdown,
} from "react-bootstrap";
import "./user.scss";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Union from "../../assets/image/Union.png";
import downloadicon from "../../assets/image/downloadicon.png";
import searchicon from "../../assets/image/searchicon.png";
import ShortIcon from "../../assets/image/filter-icon.png";
import filterremoveicon from "../../assets/image/filter-removeicon.png";
import closeicon from "../../assets/image/close-icon.png";
import nameicon from "../../assets/image/nameicon.png";
import mailicon from "../../assets/image/mailicon.png";
import phoneicon from "../../assets/image/phoneicon.png";
import roleicon from "../../assets/image/roleicon.png";
import teamicon from "../../assets/image/teamicon.png";
import passwordicon from "../../assets/image/password.png";
import view from "../../assets/image/view.png";
import hide from "../../assets/image/hide.png";
import companyicon from "../../assets/image/companyicon.png";
import ContentLayout from "../../components/contentlayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select, { components } from 'react-select';
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { ExportToCsv } from "export-to-csv";
import {
  successAlert,
  errorAlert
} from "../../services/toast.service";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import userService from "../../services/user.service";
import teamService from "../../services/team.service";
import { useSelector } from "react-redux";

const Users = (props) => {
  const userData = useSelector((state) => state.auth.user);
  const initialValues = {
    id: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    companyName: "",
    teamId: "",
    userRole: "",
    teamValues: [],
    values: [],
    permittedUsers: [],
    teamIds: [],
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [createCustomer, setcreateCustomer] = useState("false");
  const [formData, setFormData] = useState(initialValues);
  const [users, setUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const [teamList, setTeamList] = React.useState([]);
  const [values, setValues] = useState([])
  const [teamValues, setTeamValues] = useState([])
  const createCustomerToggle = () => {
    setcreateCustomer(!createCustomer);
  };

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("last name is required"),
    companyName: Yup.string().required("Company name is required"),
    email: Yup.string()
      .required("Email is required!")
      .email("Please fill valid email address "),
    password: Yup.string().required("Password is required"),
    userRole: Yup.string().required("Please choose a role"),
    // teamId: Yup.string().required("Please choose a team"),
    // password: Yup.string().required("Password is required!"),
  });
  const escapeRegex = (str) => {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "").replace(/ /g, "");
  };

  const getTeamlist = async (params) => {
    const response = await teamService.getTeamList(params);
    if (response.status == 200) {
      setTeamList(response.data.results || []);
    }
  };

  const formatStatus = (cell, row) => {
    if (row.blocked) return <span>Blocked</span>;
    return <span>Active</span>;
  };
  function NameFormatter(cell, row) {
    return (
      <span>
        {row.firstName ? row.firstName : ""} {row.lastName ? row.lastName : ""}
      </span>
    );
  }
  function TeamNameFormatter(cell, row) {
    // return <span>{row.teamId ? row.teamId.teamName : ""}</span>;
    return <span>{row.teamIds ? row.teamIds.map(i => i.teamName).join(", ") : ""}</span>;
  }
  function UserRoleFormatter(cell, row) {
    return <span>{row.userRole ? row.userRole : ""}</span>;
  }


  const Remove = (cell, row) => {
    return (
      <>
        <span>
          <span className="filter-edit">
            <NavDropdown
              title={<img src={filterremoveicon} />}
              id="nav-dropdown"
            >
              <NavDropdown.Item
                eventKey="4.1"
                onClick={() => {
                  editHandler(row);
                }}
              >
                Edit
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="4.2"
                onClick={() => {
                  deleteHandler(row);
                }}
              >
                Delete
              </NavDropdown.Item>
            </NavDropdown>
          </span>
        </span>
      </>
    );
  };

  const getUserList = async (params) => {
    params.settingPage = props.settingPage;
    params.role = 2;
    if (props.settingPage) {
      params.role = userData.role
    }
    const response = await userService.getSubAdminList(params);
    if (response.status == 200) {
      if (response.data && response.data.totalPages) {
        setPageCount(response.data.totalPages);
      }
      setUsers(response.data.results || []);
    }
  };

  const getAllUserList = async (params) => {
    // params.settingPage = props.settingPage;
    params.role = 2;
    // if (props.settingPage) {
    //   params.role = userData.role
    // }
    const response = await userService.getSubAdminList(params);
    if (response.status == 200) {
      setUserList(response.data.results || []);
    }
  };

  const exportToCsv = async () => {
    userService
      .getUsers({ role: 2 })
      .then((result) => {
        if (result.status === 200) {
          let data = [];

          result.data.map((item, index) => {
            let d = {
              firstName: item.firstName || "N/A",
              lastName: item.lastName || "N/A",
              email: item.email || "N/A",
              company: item.companyName || "N/A",
              phoneNumber: item.phoneNumber || "N/A",
              status: item.blocked ? "UnActive" : "Active",
            };
            data.push(d);
          });

          const options = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: true,
            title: "Users List",
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: "users",
          };

          const csvExporter = new ExportToCsv(options);
          if (data.length > 0) {
            csvExporter.generateCsv(data);
          } else {
            errorAlert("No data found");
          }
        } else {
          errorAlert(result.message ? result.message : "Something went wronng");
        }
      })
      .catch(console.log);
  };

  //handlers
  const editHandler = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You Want to Modify Details !",
      icon: "warning",
      showCancelButton: true,
      color: "#ffffff",
      background: "#151719",
      confirmButtonColor: "#1A1D1F",
      cancelButtonColor: "#1A1D1F",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setFormData({
          id: row.id ? row.id : "",
          firstName: row.firstName ? row.firstName : "",
          lastName: row.lastName ? row.lastName : "",
          phoneNumber: row.phoneNumber ? row.phoneNumber : "",
          email: row.email ? row.email : "",
          password: "test@123",
          companyName: row.companyName ? row.companyName : "",
          userRole: row.userRole ? row.userRole : "",
          teamId: row.teamId ? row.teamId.id : "",
          teamIds: row.teamIds ? row.teamIds.map(i => i.id) : [],
          teamValues: row.teamIds ? row.teamIds : [],
          values: row.permittedUsers ? row.permittedUsers : [],
          userId: row.userId ? row.userId.id : "",
          permittedUsers: row.permittedUsers ? row.permittedUsers.map(i => i.id) : [],
        });
        // setValues(row.permittedUsers ? row.permittedUsers : [])
        // setTeamValues(row.teamIds ? row.teamIds : [])
        createCustomerToggle();
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    });
  };
  const ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <img src={teamicon} style={{ position: 'absolute', left: 6, width: "25px" }} alt="teamImg"></img>
            // <i
            //   className="fa fa-search"
            //   aria-hidden="true"
            //   style={{ position: 'absolute', left: 6 }}
            // />
          )}
          {children}
        </components.ValueContainer>
      )
    );
  };

  const selectAllOption = { id: "all", teamName: "All Teams", firstName: "All Users", lastName: "" }

  const CustomSelectField = ({ field, colors, form, ...props }) => {
    return (
      <Select
        className="react-team-select-container"
        classNamePrefix="react-team-select"
        closeMenuOnSelect={false}
        defaultValue={field.name === "permittedUsers" ? form.values.values : form.values.teamValues}
        value={field.name === "permittedUsers" ? form.values.values : form.values.teamValues}
        placeholder={field.name === "permittedUsers" ? "Select User List" : "Select Team List"}
        isMulti
        autoFocus={false}
        isSearchable={false}
        options={getOptions(field.name)}
        getOptionLabel={(option) => field.name === "permittedUsers" ? `${option.firstName} ${option.lastName}` : `${option.teamName}`}
        getOptionValue={(option) => option.id}
        onChange={(newValue, actionMeta) => {
          const { action, option, removedValue } = actionMeta;
          if (action === "select-option" && option.id === selectAllOption.id) {
            if (field.name === "permittedUsers") {
              form.setFieldValue("values", userList);
              form.setFieldValue("permittedUsers", userList.map(i => i.id));
            }
            else {
              form.setFieldValue("teamValues", teamList);
              form.setFieldValue("teamIds", teamList.map(i => i.id));
            }
          }
          else if (action === "clear") {
            if (field.name === "permittedUsers") {
              form.setFieldValue("values", []);
              form.setFieldValue("permittedUsers", []);
            }
            else {
              form.setFieldValue("teamValues", []);
              form.setFieldValue("teamIds", []);
            }
          }
          else {
            if (field.name === "permittedUsers") {
              form.setFieldValue("values", newValue);
              form.setFieldValue("permittedUsers", newValue.map(i => i.id));
            }
            else {
              form.setFieldValue("teamValues", newValue);
              form.setFieldValue("teamIds", newValue.map(i => i.id));
            }
          }
        }
        }
        components={{ ValueContainer }}
      />
    )
  };

  const getOptions = (fieldName) => {
    if (fieldName === "permittedUsers") {
      return [selectAllOption, ...userList];
    }
    else {
      return [selectAllOption, ...teamList];
    }
  }

  // const CustomSelectField = ({ field, colors, form, ...props }) => {
  //   console.log("field", field)
  //   return (
  //     <Select
  //       className="react-select-container"
  //       classNamePrefix="react-select"
  //       closeMenuOnSelect={false}
  //       defaultValue={values}
  //       placeholder="Select User List"
  //       isMulti
  //       // values={values}
  //       autoFocus={false}
  //       isSearchable={false}
  //       options={userList}
  //       getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
  //       getOptionValue={(option) => option.id}
  //       onChange={(data) => { form.setFieldValue('permittedUsers', data.map(i => i.id)) }}
  //       components={{ ValueContainer }}
  //     />
  //   )
  // };

  const deleteHandler = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      text: "Deleted records can't be restored!",
      color: "#ffffff",
      background: "#151719",
      confirmButtonColor: "#1A1D1F",
      cancelButtonColor: "#1A1D1F",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await userService.deleteUser(row.id);
        if (response) {
          console.log("delete response ::::", response);
          Swal.fire(
            "Deleted!",
            "User has been Deleted Successfully !",
            "success"
          );
          getUserList({ page: currentPage, searchText });
        }
      }
    });
  };

  const RowSelectHandler = (row, isSelect, rowIndex, e) => {
    // ...
    if (isSelect) {
      const item = row.id || "";
      setSelectedItems([...selectedItems, item]);
    } else {
      const items = selectedItems.filter((sItem) => sItem != row.id);
      setSelectedItems(items);
    }
  };

  const selectAllRowsHandler = (isSelect, rows, e) => {
    if (isSelect) {
      const items = rows
        .map((rowItem) => rowItem.id)
        .filter((itemId) => {
          if (!selectedItems.includes(itemId)) return itemId;
        });
      setSelectedItems([...selectedItems, ...items]);
    } else {
      const ids = rows.map((rowItem) => rowItem.id);
      const items = selectedItems.filter((sItem) => {
        if (!ids.includes(sItem)) return sItem;
      });
      setSelectedItems(items);
    }
  };

  const deleteAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        text: "Deleted records can't be restored!",
        color: "#ffffff",
        background: "#151719",
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await userService.deleteManyUsers(selectedItems);
          if (data.success) {
            getUserList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Users Deleted Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const blockAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        text: "You Want to Block selected Users !",
        icon: "warning",
        showCancelButton: true,
        color: "#ffffff",
        background: "#151719",
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await userService.blockManyUsers(selectedItems);
          if (data.success) {
            getUserList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Users Blocked Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: RowSelectHandler,
    onSelectAll: selectAllRowsHandler,
    bgColor: "rgb(238, 193, 213)",
  };

  useEffect(() => {
    getUserList({ page: currentPage, searchText });
    getTeamlist({ limit: 100 });
    getAllUserList({ limit: 100 })
  }, []);

  useEffect(() => {
    console.log("users::::", users);
  }, [users]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      getUserList({ page: 1, searchText });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const handleSubmit = async (formValue) => {
    try {
      delete formValue.teamValues;
      delete formValue.values;
      let date = new Date();
      date.setHours(8);
      date.setMinutes(0);
      let timeSplit = date.toLocaleTimeString().split(":")
      let afterTime = `${timeSplit[0]}:${timeSplit[1]}`
      let afterSeconds = Number((date.getHours() * 3600) + (date.getMinutes() * 60))
      formValue.defaultAfterTime = afterTime
      formValue.defaultAfterTimeSeconds = afterSeconds
      formValue.defaultAfterTimePicker = date;
      let beforedate = new Date();
      beforedate.setHours(17);
      beforedate.setMinutes(0);
      let beforetimeSplit = beforedate.toLocaleTimeString().split(":")
      let beforeTime = `${beforetimeSplit[0]}:${beforetimeSplit[1]}`
      let beforeSeconds = Number((beforedate.getHours() * 3600) + (beforedate.getMinutes() * 60))
      formValue.defaultBeforeTime = beforeTime
      formValue.defaultBeforeTimeSeconds = beforeSeconds
      formValue.defaultBeforeTimePicker = beforedate;

      if (formValue.phoneNumber) {
        let phone = escapeRegex((formValue.phoneNumber.toString()));
        formValue.phoneNumber = phone;
      }
      if (formValue.id) {
        //update here
        const response = await userService.editUser(formValue);
        console.log("response edit merchant:::", response);

        if (response.data.success) {
          setFormData(initialValues);
          setcreateCustomer("false");
          getUserList({ page: currentPage, searchText });
          successAlert(
            response.data.message
              ? response.data.message
              : "User Updated Successfully !"
          );
        }
        if (!response.data.success) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
      } else {
        if (userData.userId) {
          formValue.userId = userData.userId
        }
        formValue.role = 2;
        if (props.settingPage) {
          formValue.role = userData.role;
          formValue.userId = userData.id
          if (formValue.role === 1) formValue.subAdmin = true
        }

        const response = await userService.createSubAdmin(formValue);
        if (response.data.success) {
          setFormData(initialValues);
          setcreateCustomer("false");
          successAlert(
            response.data.message
              ? response.data.message
              : "User Added Successfully !"
          );
          getUserList({ page: currentPage, searchText });
        }
        if (response.data.success === false) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
      }
    } catch (err) {
      console.log("error:::", err);
    }
  };

  const cancelhandler = () => {
    setFormData(initialValues);
    setcreateCustomer("false");
  };

  const handlePageClick = async ({ selected }) => {
    if (selected + 1 !== currentPage) {
      setCurrentPage(selected + 1);
      await getUserList({ page: selected + 1, searchText });
    }
  };
  const [bulkOpened, setbulkOpened] = useState(false);

  function bulktoggle() {
    setbulkOpened((wasOpened) => !wasOpened);
  }

  const CustomPasswordField = ({ field, colors, form, ...props }) => {
    const [showHidePassword, changeShowHidePassword] = useState(false);

    return (
      <div className="input-container">
        <img className="password-icon" src={passwordicon} alt="passwordicon" />
        <input
          type={showHidePassword ? "text" : "password"}
          {...field}
          className="custom-password"
          name="password"
          placeholder="Password"
        />
        <img className="eye-icon" onClick={(e) => { changeShowHidePassword(!showHidePassword) }} src={showHidePassword ? hide : view} alt="passwordicon" />
      </div>
    );
  }

  const UserComponent = () => {
    return (<>
      <div className="customer-page-wrapper d-flex">
        {/* customer layout start */}
        <div className="customer-form-wrapper">
          <div className="customer-heading-box">
            <Row>
              <Col xs={6}>
                <h1>Users</h1>
              </Col>
              <Col xs={6}>
                <div className="createmsbox">
                  <div onClick={createCustomerToggle}>
                    <Button className="cticon">
                      <img src={Union} alt="icon" />
                    </Button>
                    <span>Add User</span>
                  </div>
                  <div>
                    <Button onClick={() => exportToCsv()}>
                      <img src={downloadicon} alt="icon" />
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="customer-searching-box">
            <Row>
              <Col xs={6}>
                <div className="create-action-option mb-3">
                  <Dropdown>
                    <Dropdown.Toggle className="bulk-action">
                      Bulk Action
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={blockAllHandler}>
                        Block All
                      </Dropdown.Item>
                      <Dropdown.Item onClick={deleteAllHandler}>
                        Delete Selected
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
              <Col xs={6}>
                <div className="searchbar">
                  <BootstrapForm className="d-flex">
                    <FormControl
                      type="search"
                      placeholder="Search or type a name"
                      className="me-2"
                      autoComplete="off"
                      onChange={(e) => setSearchText(e.target.value)}
                      aria-label="Search"
                    />
                    <Button className="searchicon">
                      <img src={searchicon} alt="searchicon" />
                    </Button>
                    {/* <span className="searchF">
                  <img src={searchF} alt="icon" />
                </span> */}
                  </BootstrapForm>
                </div>
              </Col>
            </Row>
          </div>

          {/* customer list start */}
          <div className="customer-list-wrapper">
            {/* <MyMapComponent key="map" /> */}
            <BootstrapTable
              data={users}
              striped={true}
              hover={true}
              selectRow={selectRowProp}
            >
              <TableHeaderColumn
                dataField="teamId"
                dataSort={true}
                width="200px"
                dataFormat={TeamNameFormatter}
              >
                Team
                <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                isKey={true}
                dataAlign="left"
                dataSort={true}
                dataFormat={NameFormatter}
                width="200px"
              >
                User Name <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="phoneNumber"
                dataSort={true}
                width="200px"
              >
                Phone
                <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="email"
                dataSort={true}
                width="250px"
              >
                Email
                <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>
              {props && props.settingPage && <TableHeaderColumn
                dataField="userRole"
                dataSort={true}
                width="200px"
                dataFormat={UserRoleFormatter}
              >
                Role
                <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>}
              <TableHeaderColumn
                dataField="block"
                dataFormat={formatStatus}
                width="150px"
              >
                Status
                <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>

              {/* <TableHeaderColumn
            dataField="companyName"
            dataSort={true}
            width="200px"
          >
            Number of Ask
            <img className="ms-1" src={ShortIcon} alt="" />
          </TableHeaderColumn> */}
              <TableHeaderColumn
                dataField="Action"
                dataAlign="right"
                dataFormat={Remove}
                width="150px"
              >
                Action
              </TableHeaderColumn>
            </BootstrapTable>
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
              className="pagination"
            />
          </div>

          {/* customer list end */}
        </div>

        {/* customer layout end */}
      </div>
      {/* create customer popup start */}
      <div
        className={
          createCustomer
            ? "opencustomer-popup"
            : "opencustomer-popup createCustomer"
        }
      >
        <div className="addCustomer-popup">
          <div className="addcustomer-slide">
            <div className="addcustomer-field">
              <span className="title">add user</span>
              <div className="customer-import">
                <div className="customer-import-field">
                  <Button>
                    <img src={Union} alt="icon" />
                  </Button>
                  <span>import</span>
                  <Button>
                    <img src={downloadicon} alt="icon" />
                  </Button>
                </div>
                <Button
                  className="createcustomer-close"
                  onClick={createCustomerToggle}
                >
                  <img src={closeicon} alt="icon" />
                </Button>
              </div>
            </div>

            <div className="addcustomer-information">
              <h2>Users Information</h2>
              <div className="customer-form">
                <Formik
                  enableReinitialize
                  initialValues={formData}
                  render={(formProps) => {
                    return (
                      <Form>
                        <Row className="custinfo-form">
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="firstname">
                                <img src={nameicon} alt="nameicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="First Name"
                                maxLength="25"
                                name="firstName"
                                aria-label="First Name"
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="lastname">
                                <img src={nameicon} alt="lasticon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="Last Name"
                                maxLength="25"
                                name="lastName"
                                aria-label="Last Name"
                              />
                              <ErrorMessage name="lastName" component="div" />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="phonenumber">
                                <img src={phoneicon} alt="phoneicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="Phone Number"
                                name="phoneNumber"
                                type="text"
                                aria-label="Phone Number"
                              />
                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="yourworkemail">
                                <img src={mailicon} alt="emailicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="Your work email"
                                name="email"
                                aria-label="Your email"
                              />
                              <ErrorMessage name="email" component="div" />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="companyname">
                                <img src={companyicon} alt="companyicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="Company name"
                                maxLength="25"
                                name="companyName"
                                aria-label="Company Name"
                              />
                              <ErrorMessage
                                name="companyName"
                                component="div"
                              />
                            </InputGroup>
                          </Col>
                          {(!formData.id || props.settingPage) && (
                            // <Col xs={6}>
                            //   <InputGroup className="mb-3">
                            //     <InputGroup.Text id="createpassword">
                            //       <img
                            //         src={passwordicon}
                            //         alt="passwordicon"
                            //       />
                            //     </InputGroup.Text>
                            //     <Field
                            //       className="form-control"
                            //       type="password"
                            //       placeholder="Create Password"
                            //       name="password"
                            //       aria-label="Create Password"
                            //     />
                            //     <ErrorMessage
                            //       name="password"
                            //       component="div"
                            //     />
                            //   </InputGroup>
                            // </Col>
                            <Col xs={6}>
                              <Field
                                  className="form-control"
                                  type="password"
                                  placeholder="Create Password"
                                  name="password"
                                  aria-label="Create Password"
                                  component={CustomPasswordField}
                                />
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                />
                            </Col>
                          )}
                          {userData.role === 1 && props.settingPage && <Col xs={12}>
                            {/* <InputGroup className="mb-3"> */}
                            {/* <InputGroup.Text id="teamicon">
                                <img src={teamicon} alt="teamicon" />
                              </InputGroup.Text> */}
                            <Field
                              className="form-control"
                              name="permittedUsers"
                              component={CustomSelectField}
                            >
                              <option selected>Select User List</option>
                              {userList &&
                                userList.length > 0 &&
                                userList.map((t) => (
                                  <option value={t.id}>{t.firsName} {t.lastName}</option>
                                ))}
                            </Field>
                            <ErrorMessage name="permittedUsers" component="div" />
                            {/* </InputGroup> */}
                          </Col>}
                          {/* {!props.settingPage && <Col xs={6}>
                            <InputGroup className="mb-3 teamsl">
                              <InputGroup.Text id="teamicon">
                                <img src={teamicon} alt="teamicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                name="teamId"
                                as="select"
                              >
                                <option selected>Assign Team</option>
                                {teamList &&
                                  teamList.length > 0 &&
                                  teamList.map((t) => (
                                    <option value={t.id}>{t.teamName}</option>
                                  ))}
                              </Field>
                              <ErrorMessage name="teamId" component="div" />
                            </InputGroup>
                          </Col>} */}
                          <Col xs={6}>
                            <Field
                              className="form-control"
                              name="teamIds"
                              component={CustomSelectField}
                            >
                            </Field>
                            <ErrorMessage name="teamIds" component="div" />
                          </Col>
                          <Col xs={6}>
                            <InputGroup className="mb-3 teamsl">
                              <InputGroup.Text id="roleicon">
                                <img src={roleicon} alt="roleicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                name="userRole"
                                as="select"
                              >
                                <option selected>Assign Role</option>
                                <option value="view">View Only</option>
                                <option value="crud">CRUD</option>
                                {/* <option value="super">Super Merchant</option> */}
                              </Field>
                              <ErrorMessage name="userRole" component="div" />
                            </InputGroup>
                          </Col>
                        </Row>
                        <div className="ct-btn">
                          <Button
                            className="cncle-btn"
                            onClick={cancelhandler}
                          >
                            Cancel
                          </Button>
                          <Button className="ctaskbtn" type="submit">
                            Let’s Go
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                ></Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* create customer popup end */}
    </>
    )
  }
  return (
    <>
      {props && !props.settingPage ? <ContentLayout>
        {UserComponent()}
        <ToastContainer
          style={{ color: "black" }}
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ContentLayout> : <UserComponent />}
    </>
  );
};

export default Users;
