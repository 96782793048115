import React from "react";
import Sidebarmenu from "./sidebarmenu.js";
import Rightsidebar from './rightsidebar.js';

function ContentLayout(props) {
  let sidebarProps = {
    menuCollapse: true,
    ...props
  }
  return (
    <>
      <div className="dashboard-page-wrapper page-content-wrap">
        <Sidebarmenu {...sidebarProps} />
        <div className="main-content-wrapper">
          {props.children}
        </div>
      </div>
    </>
  );
}

export default ContentLayout;
