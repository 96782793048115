import React, { useState, useEffect } from "react";
import {
  Card,
  Form as BootstrapForm,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
  NavDropdown,
} from "react-bootstrap";
import "./builderCategory.scss";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Union from "../../assets/image/Union.png";
import downloadicon from "../../assets/image/downloadicon.png";
import searchicon from "../../assets/image/searchicon.png";
import searchF from "../../assets/image/search-f.png";
import ShortIcon from "../../assets/image/filter-icon.png";
import filterremoveicon from "../../assets/image/filter-removeicon.png";
import closeicon from "../../assets/image/close-icon.png";
import nameicon from "../../assets/image/nameicon.png";
import ContentLayout from "../../components/contentlayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { ExportToCsv } from "export-to-csv";
import {
  successAlert,
  errorAlert,
  infoAlert,
} from "../../services/toast.service";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import builderService from "../../services/builder.service";

const BuilderCategory = (props) => {
  const initialValues = {
    id:"",
    category: ""
  };
  
  const [createLibraryCategory, setCreateLibraryCategory] = useState("false");
  const [formData, setFormData] = useState(initialValues);
  const [category, setCategory] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const createLibraryCategoryToggle = () => {  console.log("hiii")
    setCreateLibraryCategory(!createLibraryCategory);
  };

  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Category is required"),
  });

  const formatStatus = (cell, row) => {
    if (row.blocked) return <span>Blocked</span>;
    return <span>Active</span>;
  };
  function NameFormatter(cell, row) {
    return (
      <span>
        {row.category ? row.category : ""} 
      </span>
    );
  }
  const handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };
  // const [customerActive, setcustomerActive] = useState(false);
  // const customerActiveToggle = () => setcustomerActive(true);
  // const customerActiveToggle = () => {
  //   setcustomerActive(!customerActive);
  // };
  const [isOpened, setIsOpened] = useState(false);

  // function toggle() {
  //   setIsOpened(wasOpened => !wasOpened);
  // }

  const Remove = (cell, row) => {
    return (
      <>
        <span>
          <span className="filter-edit">
            <NavDropdown
              title={<img src={filterremoveicon} />}
              id="nav-dropdown"
            >
              <NavDropdown.Item
                eventKey="4.1"
                onClick={() => {
                  editHandler(row);
                }}
              >
                Edit
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="4.2"
                onClick={() => {
                  deleteHandler(row);
                }}
              >
                Delete
              </NavDropdown.Item>
            </NavDropdown>
          </span>
        </span>
      </>
    );
  };

  const getCategoryList = async (params) => {
    const response = await builderService.getCategoryList(params);
    if (response.status == 200) {
      if (response.data && response.data.totalPages) {
        setPageCount(response.data.totalPages);
      }
      setCategory(response.data.results || []);
    }
    console.log("category list response  :::", response);
  };

  const exportToCsv = async ()=>{
    builderService.getAll({role: 4})
     .then((result) => {
       if (result.status === 200) {
         let data = [];

         result.data.map((item, index) => {
           let d = {
             category: item.category || "N/A",
             status: item.blocked ? "UnActive": "Active",
           };
           data.push(d);
         });

         const options = {
           fieldSeparator: ",",
           quoteStrings: '"',
           decimalSeparator: ".",
           showLabels: true,
           showTitle: true,
           title: "Builder List",
           useTextFile: false,
           useBom: true,
           useKeysAsHeaders: true,
           filename: "builder-category"
         };

         const csvExporter = new ExportToCsv(options);
         if (data.length > 0) {
           csvExporter.generateCsv(data);
         } else {
           errorAlert("No data found");
         }
       } else {
         errorAlert(result.message ? result.message:  "Something went wronng");
       }
     })
     .catch(console.log);

}

  //handlers
  const editHandler = (row) => {
    
    setFormData({
      id: row.id ? row.id : "",
      category: row.category 
    });
    createLibraryCategoryToggle();
  };
  const deleteHandler = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      text: "Deleted records can't be restored!",
      color: "#ffffff",
      background: '#151719',
      confirmButtonColor: "#1A1D1F",
      cancelButtonColor: "#1A1D1F",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await builderService.deleteCategory(row.id);
        if (response) {
          console.log("delete response ::::", response);
          Swal.fire(
            "Deleted!",
            "Category has been Deleted Successfully !",
            "success"
          );
          getCategoryList({ page: currentPage, searchText });
        }
      }
    });
  };

  const RowSelectHandler = (row, isSelect, rowIndex, e) => {
    // ...
    if (isSelect) {
      const item = row.id || "";
      setSelectedItems([...selectedItems, item]);
    } else {
      const items = selectedItems.filter((sItem) => sItem != row.id);
      setSelectedItems(items);
    }
  };

  const selectAllRowsHandler = (isSelect, rows, e) => {
    if (isSelect) {
      const items = rows
        .map((rowItem) => rowItem.id)
        .filter((itemId) => {
          if (!selectedItems.includes(itemId)) return itemId;
        });
      setSelectedItems([...selectedItems, ...items]);
    } else {
      const ids = rows.map((rowItem) => rowItem.id);
      const items = selectedItems.filter((sItem) => {
        if (!ids.includes(sItem)) return sItem;
      });
      setSelectedItems(items);
    }
  };

  const deleteAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        text: "Deleted records can't be restored!",
        color: "#ffffff",
        background: '#151719',
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await builderService.deleteManyCategory(selectedItems);
          if (data.success) {
            getCategoryList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Categories Deleted Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const blockAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        text: "You Want to Block selected Categories !",
        icon: "warning",
        showCancelButton: true,
        color: "#ffffff",
        background: '#151719',
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await builderService.blockManyCategory(selectedItems);
          if (data.success) {
            getCategoryList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Category Blocked Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: RowSelectHandler,
    onSelectAll: selectAllRowsHandler,
    bgColor: "rgb(238, 193, 213)",
  };

  useEffect(() => {
    getCategoryList({ page: currentPage, searchText });
  }, []);

  useEffect(() => {
    console.log("category::::", category);
  }, [category]);

  useEffect(() => {
    console.log("search text ::::", searchText);
    const delayDebounceFn = setTimeout(async () => {
      getCategoryList({ page: 1, searchText });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const handleSubmit = async (formValue) => {
    try {
      if (formValue.id) {
        //update here
        const response = await builderService.editCategory(formValue);

        if (response.data) {
          setFormData(initialValues);
          setCreateLibraryCategory("false");
          getCategoryList({ page: currentPage, searchText });
          successAlert(
            response.data.message
              ? response.data.message
              : "Category Updated Successfully !"
          );
        }
        if (!response.data) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
      } else {
        const response = await builderService.createCategory(formValue);
        if (response.data.success) {
          setFormData(initialValues);
          setCreateLibraryCategory("false");
          successAlert(
            response.data.message
              ? response.data.message
              : "Category Added Successfully !"
          );
          getCategoryList({ page: currentPage, searchText });
        }
        if (response.data.success === false) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
      }
    } catch (err) {
      console.log("error:::", err);
    }
  };

  const cancelhandler = () => {
    setFormData(initialValues);
    setCreateLibraryCategory("false");
  };

  const handlePageClick = async ({ selected }) => {
    console.log("current page::", selected);
    if (selected + 1 !== currentPage) {
      setCurrentPage(selected + 1);
      await getCategoryList({ page: selected + 1, searchText });
    }
  };
  const [bulkOpened, setbulkOpened] = useState(false);

  function bulktoggle() {
    setbulkOpened((wasOpened) => !wasOpened);
  }
  return (
    
    <>
      <ContentLayout>
        <div className="customer-page-wrapper d-flex">
          {/* customer layout start */}
          <div className="customer-form-wrapper">
            <div className="customer-heading-box">
              <Row>
                <Col xs={6}>
                  <h1>Builder Category</h1>
                </Col>
                <Col xs={6}>
                  <div className="createmsbox">
                    <div onClick={createLibraryCategoryToggle}>
                      <Button className="cticon" >
                        <img src={Union} alt="icon" />
                      </Button>
                      <span>Add Builder Category</span>
                    </div>
                    <div>
                      <Button onClick={()=>exportToCsv()}>
                        <img src={downloadicon} alt="icon" />
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="customer-searching-box">
              <Row>
                <Col xs={6}>
                  <div className="create-action-option">
                    <BootstrapForm>
                      <BootstrapForm.Group className="mb-3 od-dselect">
                        <Button className="bulk-action" onClick={bulktoggle}>
                          Bulk Action
                        </Button>
                        {bulkOpened && (
                          <div className="bulkoption">
                            <Button onClick={blockAllHandler}>Block All</Button>
                            <Button onClick={deleteAllHandler}>
                              Delete Selected
                            </Button>
                          </div>
                        )}
                      </BootstrapForm.Group>
                    </BootstrapForm>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="searchbar">
                    <BootstrapForm className="d-flex">
                      <FormControl
                        type="search"
                        placeholder="Search or type a name"
                        className="me-2"
                        autoComplete="off"
                        onChange={(e) => setSearchText(e.target.value)}
                        aria-label="Search"
                      />
                      <Button className="searchicon">
                        <img src={searchicon} alt="searchicon" />
                      </Button>
                      {/* <span className="searchF">
                        <img src={searchF} alt="icon" />
                      </span> */}
                    </BootstrapForm>
                  </div>
                </Col>
              </Row>
            </div>

            {/* customer list start */}
            <div className="customer-list-wrapper">
              <BootstrapTable
                data={category}
                striped={true}
                hover={true}
                selectRow={selectRowProp}
                wrapperClasses="table-responsive"
              >
                <TableHeaderColumn
                  dataField="id"
                  isKey={true}
                  dataAlign="left"
                  dataSort={true}
                  dataFormat={NameFormatter}
                  width="200px"
                >
                  Category <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                
                <TableHeaderColumn
                  dataField="block"
                  dataFormat={formatStatus}
                  width="150px"
                >
                  Status
                  <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="Action"
                  dataAlign="right"
                  dataFormat={Remove}
                  width="150px"
                >
                  Action
                </TableHeaderColumn>
              </BootstrapTable>
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="Prev"
                renderOnZeroPageCount={null}
                className="pagination"
              />
            </div>

            {/* customer list end */}
          </div>

          {/* customer layout end */}
        </div>
        {/* create customer popup start */}
        <div
          className={
            createLibraryCategory
              ? "opencustomer-popup"
              : "opencustomer-popup createCustomer"
          }
        >
          <div className="addCustomer-popup">
            <div className="addcustomer-slide">
              <div className="addcustomer-field">
                <span className="title">Builder category</span>
                <div className="customer-import">
                  <div className="customer-import-field">
                    <Button>
                      <img src={Union} alt="icon" />
                    </Button>
                    <span>import</span>
                    <Button>
                      <img src={downloadicon} alt="icon" />
                    </Button>
                  </div>
                  <Button
                    className="createLibraryCategory-close"
                    onClick={createLibraryCategoryToggle}
                  >
                    <img src={closeicon} alt="icon" />
                  </Button>
                </div>
              </div>

              <div className="addcustomer-information">
                <h2>Category Information</h2>
                <div className="customer-form">
                  <Formik
                    enableReinitialize
                    initialValues={formData}
                    render={(formProps) => {
                      return (
                        <Form>
                          <Row className="custinfo-form">
                            <Col xs={12}>
                              <InputGroup>
                                <InputGroup.Text id="firstname">
                                  <img src={nameicon} alt="nameicon" />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  placeholder="Category"
                                  name="category"
                                  maxLength="25"
                                  aria-label="Category"
                                />
                                <ErrorMessage
                                  name="category"
                                  component="div"
                                />
                              </InputGroup>
                            </Col>
                            
                          </Row>
                          <div className="ct-btn">
                            <Button
                              className="cncle-btn"
                              onClick={cancelhandler}
                            >
                              Cancel
                            </Button>
                            <Button className="ctaskbtn" type="submit">
                              Let’s Go
                            </Button>
                          </div>
                        </Form>
                      );
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  ></Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* create customer popup end */}
      </ContentLayout>
      <ToastContainer
        style={{ color: "black" }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default BuilderCategory;
