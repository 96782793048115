import { InfoWindow, Marker } from "@react-google-maps/api";
import InfoWindowComponent from "./InfoWindowComponent";
import AssignImage from "../../assets/image/started-icon.png"
import { Tooltip } from "react-bootstrap";

const MarkerComponent = (props) => {
    let marker = props.marker;
    const renderMarkerImage = (marker) => {
        let markerImage = AssignImage;
        if (marker.markerImage) {
            markerImage = marker.markerImage
        }
        if (marker.selected && marker.selectedMarkerImage) {
            markerImage = marker.selectedMarkerImage
        }
        return markerImage
    }

    const markerClickHandler = (e, props, marker) => {
        if (!marker.notClickable) {
            if (e && (!e.domEvent.ctrlKey && !e.domEvent.metaKey)) {
                // props.setDefaultCenter(marker);
                props.map.panTo({ lat: marker.lat, lng: marker.lng })
                props.setSelectedCenter({ ...marker, from: "marker" });
                // props.taskDetailHandler(marker);
            }
            if (marker.missioned) props.missionTaskSingleClickHandler(e.domEvent, marker, null, true)
            else props.taskSingleClickHandler(e.domEvent, marker, null)
        }
    }

    const OnMouseoverMarker = () => {
        if (props.hoverHub) {
            return (<InfoWindow key={props.hoverHub.id}
                position={{
                    lat: props.hoverHub.lat,
                    lng: props.hoverHub.lng
                }}>
                <div className="infowindow-hub">
                    <span className="hubname">{props.hoverHub.hubName}</span>
                </div>
            </InfoWindow>)
        }
        else {
            return null
        }

    }

    if (marker.lat && marker.lng) {
        return (<Marker
            key={marker.id}
            position={{ lat: marker.lat, lng: marker.lng }}
            label={{
                // text: alphabets(marker.index),
                text: marker.showMarkerLabel && marker.orderType && marker.orderType !== "SL" && marker.orderType !== "EL" ? (marker.markerIndex).toString() : " ",
                color: 'white',
            }}
            onMouseOver={(e) => { marker.hover && props.setHoverHub(marker) }}
            onMouseOut={() => { setTimeout(() => { props.setHoverHub(null) }, 1000) }}
            // animation={window.google.maps.Animation.DROP}
            onClick={(e) => {
                markerClickHandler(e, props, marker)
            }}
            icon={{
                url: renderMarkerImage(marker),
                // size: { width: 45, height: 70 },
                // anchor: { x: 10, y: 35 },
                ...(!marker.markerImage && { scaledSize: { width: 22, height: 30 } }),
            }}
        >
            {props.selectedCenter && ((props.selectedCenter.from === "marker" && marker.id === props.selectedCenter.id) || (props.selectedCenter.from === "task" && marker.id === props.selectedCenter.id)) && (
                <InfoWindowComponent {...props} />
            )}
            {props.hoverHub && props.hoverHub.id === marker.id && <OnMouseoverMarker {...props} />}
        </Marker>)
    }
    else {
        return null;
    }
}

const RenderMarkers = (props) => {
    let markers = [];

    if (props.otherMarkers) {
        markers = [...markers, ...props.otherMarkers]
    }
    if (props.bookMarks) {
        markers = [...markers, ...props.bookMarks]
    }
    if (props.agentBookMarks) {
        markers = [...markers, ...props.agentBookMarks]
    }

    return (
        markers &&
        // markers.length && (!missionDirection.length || missionDirection.length === 1) &&
        markers.length &&
        markers.map((marker, i) => {
            if (!!marker) {
                let markerProps = { ...props }
                markerProps.marker = marker;
                return (
                    <MarkerComponent key={markerProps.marker.index} {...markerProps} />
                );
            }
            return null
        })
    )
}


export default RenderMarkers