import styled from "styled-components";

export const SelectTimeContainer = styled.section`
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1.4rem 0;
  .line {
    position: absolute;
    display: block;
    width: 90%;
    height: 1px;
    background: ${({ theme }) => theme.colors.darkTextColor};
    top: 230px;
  }
  .back-btn {
    position: relative;
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: "";
      position: absolute;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.whiteColor};
    }
    a {
      z-index: 2;
      .icon {
        z-index: 2;
        width: 40px;
        height: 40px;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.bgColor};
      }
    }
  }
  .wrapper {
    background: ${({ theme }) => theme.colors.bgColorVariant};
    width: 90%;
    padding: 1.4rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
   
    .date {
      margin-top: 10px;
      h2 {
      color: ${({ theme }) => theme.colors.whiteColor};
      font-size: 25px;
      margin-bottom: 2px;
    }
      display: flex;
      flex-direction: column;
      color: ${({ theme }) => theme.colors.darkTextColor};
      font-weight: bold;
      padding: 0;
      .sub-date{
        display: flex;
        gap: 6px;
        justify-content: center;
        p{
        font-size: 12px;
      }
      }
    
     
    }
    .time-zone-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3.2rem;
      h5 {
        color: ${({ theme }) => theme.colors.whiteColor};
        font-size: 12px;
      }
      .icon {
        width: 15px;
        height: 15px;
        margin: 0 8px;
        color: ${({ theme }) => theme.colors.darkTextColor};
      }
      p {
        color: ${({ theme }) => theme.colors.whiteColorVariant};
        font-size: 10px;
      }
    }
    .line {
      width: 100%;
      margin-top: 6px;
      color: ${({ theme }) => theme.colors.darkTextColor};
      height: 1px;
    }
    h3 {
      color: ${({ theme }) => theme.colors.whiteColor};
      font-size: 20px;
      margin-top: 3rem;
      margin-bottom: 5px;
    }
    .button-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 1rem;
      gap: 1rem;
      .confirm-btn {
        width: 160px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 12px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.whiteColor};
        background: ${({ theme }) => theme.colors.primaryColor};
        border: none;
        border-radius: 10px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
`;

export const TimePickContainerForMobile = styled.div`
  display: flex;
  padding: 12px;
  border: 2px solid ${({ theme }) => theme.colors.primaryColorVariant};
  border-radius: 10px;
  gap: 10px;
  width: 150px;
  .time-container{
    cursor: pointer;
    position: relative;
    .slot {
      color: ${({ theme }) => theme?.colors?.primaryColorVariant};
      margin: 0
    }
  }
`;
export const TimePickContainerForMobileSelected = styled.div`
  display: flex;
  padding: 12px;
  border: 2px solid ${({ theme }) => theme.colors.whiteColor};
  background: ${({ theme }) => theme?.colors?.primaryColor};
  border-radius: 10px;
  gap: 10px;
  width: 150px;
  .time-container{
    cursor: pointer;
    position: relative;
    .slot {
      color: ${({ theme }) => theme?.colors?.whiteColor};
      margin: 0
    }
  }
`;
