import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "libraryCategory";
const API_URL_B = process.env.REACT_APP_API_URL + "library";


const getCategoryList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    if (!params.searchText) delete params.searchText
    return axios.get(API_URL, { headers: authHeader(), params: { limit: 10, ...params } });
};

const getAllCategoryList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    if (!params.searchText) delete params.searchText
    return axios.get(API_URL, { headers: authHeader(), params: {  ...params } });
};

const getAll = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    return axios.get(`${API_URL}/all`, { headers: authHeader(), params  });
};

const createCategory = (data) => {
    data = clean(data)
    return axios.post(API_URL, { ...data }, { headers: authHeader() });
};

const editCategory = (data) => {
    data = clean(data)
    return axios.patch(`${API_URL}/${data.id}`, data, { headers: authHeader() });
};
const deleteCategory = (id) => {
    return axios.delete(`${API_URL}/${id}`, { headers: authHeader() });
};
const blockManyCategory = (categoryIds) => {
    return axios.patch(API_URL + '/multipleBlock', { categoryIds }, { headers: authHeader() });

};

const deleteManyCategory = (categoryIds) => {
    const headerObj = { data: { categoryIds }, headers: authHeader() }
    return axios.delete(API_URL + '/multipleDelete', headerObj);

};

const getLibraryList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    if (!params.searchText) delete params.searchText
    return axios.get(API_URL_B, { headers: authHeader(), params: { limit: 10, ...params } });
};

const getAllList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    return axios.get(`${API_URL_B}/all-list`, { headers: authHeader(), params  });
};

const getLibraryByCategory = (categoryId) => {
    return axios.get(`${API_URL_B}/byCategory/${categoryId}`, { headers: authHeader()});
};


const createLibrary = async(data) => { 
    
    const res= await axios.post(API_URL_B, data, { headers: authHeader() });
    return res
};

const editLibrary = (data,id) => {
    
    return axios.patch(`${API_URL_B}/${id}`, data, { headers: authHeader() });
};
const deleteLibrary = (id) => {
    return axios.delete(`${API_URL_B}/${id}`, { headers: authHeader() });
};
const blockManyLibrary = (libraryIds) => {
    return axios.patch(API_URL_B + '/multipleBlock', { libraryIds }, { headers: authHeader() });

};

const deleteManyLibrary = (libraryIds) => {
    const headerObj = { data: { libraryIds }, headers: authHeader() }
    return axios.delete(API_URL_B + '/multipleDelete', headerObj);

};

function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
    }
    return obj
}

const libraryService = {
    getCategoryList,
    createCategory,
    editCategory,
    deleteCategory,
    deleteManyCategory,
    blockManyCategory,
    getLibraryList,
    createLibrary,
    editLibrary,
    deleteLibrary,
    deleteManyLibrary,
    blockManyLibrary,
    getLibraryByCategory,
    getAllCategoryList,
    getAll,
    getAllList
};

export default libraryService