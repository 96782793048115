import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import "./dashboard.scss";
import axios from 'axios';
import Bottommenu from "../../components/Bottommenu.js";
import ModalPopUp from "../../components/UI/Modal/Modal";
import ImportPopup from "../Importpopup/Importpopup";
import Layout from "../../components/layout.js";
import { ToastContainer } from "react-toastify";
import { listenToEvent, socketConnection } from '../../services/socket.service'
import agentMarker from "../../assets/image/agentLocation.png"
import unassignedTaskMarker from "../../assets/image/unassignedMarker.png"
import assignedTaskMarker from "../../assets/image/assignedMarker.png"
import delayTaskMarker from "../../assets/image/delayMarker.png"
import completedTaskMarker from "../../assets/image/completedMarker.png"
import failedTaskMarker from "../../assets/image/failedTaskMarker.png"
import startedTaskMarker from "../../assets/image/startedMarker.png"
import selectedUnassignedTaskMarker from "../../assets/image/selectedUnassignedTask.png"
import selectedAssignedMarker from "../../assets/image/selectedAssignedMarker.png"
import selectedDelayMarker from "../../assets/image/selectedDelayMarker.png"
import selectedCompletedMarker from "../../assets/image/selectedCompletedMarker.png"
import selectedFailedTaskMarker from "../../assets/image/selectedFailedTaskMarker.png"
import selectedStartedMarker from "../../assets/image/selectedStartedMarker.png"
import startLocationMarker from "../../assets/image/startLocationMarker.png"
import endLocationMarker from "../../assets/image/endLocationMarker.png"
import polygon from "../../assets/image/polygon.png"
import layer from "../../assets/image/layer.png"
import { Flatfile, PartialRejection, RecordError } from "@flatfile/sdk";

import moment from 'moment'
import {
    Row,
    Col,
    InputGroup,
    Button,
    Form as FormB
} from "react-bootstrap";

import closeicon from "../../assets/image/close-icon.png";
import teamicon from "../../assets/image/teamicon.png";

import { Formik, Form, Field, ErrorMessage } from "formik";
import {
    successAlert,
    errorAlert,
    infoAlert,
} from "../../services/toast.service";
import Swal from "sweetalert2";
import libraryService from "../../services/library.service";
import userService from "../../services/user.service";
import taskService from "../../services/task.service";
import teamService from "../../services/team.service";
import hubService from "../../services/hub.service";
import routes from "./routes3.json";
// import MapComponent from "./Map";
import MapComponent from "./MapComponent";
import ListViewComponent from "./ListView";
import OptimizeMission from "./OptimizeMission"
import { useSelector } from "react-redux";
import authService from "../../services/auth.service";
import { Loader } from "../../components/Loader/loader";
import { MapDirectionsRenderer } from "./DirectionService";
import { delay, selectTasksPolygon } from "../../helpers/helperFunctions";
import timeZoneHeader from "../../services/timezone-header";
import momentTz from "moment-timezone"
import { Calendar } from "react-calendar";
import Select from "react-select";
import DatePicker from "react-date-picker";

const defaultDurationTime = 60;
const defaultMaxMissionDuration = 20;
const defaultMaxMissionTasks = 8;
const defaultMissionCapacity = 1000;

const Dashboard = (props) => {
    const history = useHistory()
    let localCurrentDate = localStorage.getItem("currentDate");
    let currentFormattedDate = moment().format("YYYY-MM-DD");
    let lastDateChanged = localStorage.getItem("lastDateChanged");
    if (!localCurrentDate) {
        localStorage.setItem("currentDate", new Date());
        localStorage.setItem("lastDateChanged", moment().format("YYYY-MM-DD"));
        localCurrentDate = localStorage.getItem("currentDate")
        lastDateChanged = localStorage.getItem("lastDateChanged")
    }
    if (lastDateChanged !== currentFormattedDate) {
        localStorage.setItem("currentDate", new Date());
        localStorage.setItem("lastDateChanged", moment().format("YYYY-MM-DD"));
    }
    const initialValues = {
        agentId: "",
        locationType: "",
        address: "",
    };

    const [agentBookMarks, setAgentBookMarks] = useState([]);
    const [otherMarkers, setOtherMarkers] = useState([]);
    const [missionData, setMissionData] = useState(null);
    const [mapControls, setMapControls] = useState({
        drawPolygon: false,
        sateliteView: false
    });

    const initialValues2 = {
        missionId: "",
        startFrom: "",
        endAt: "",
        maxMissionTasks: defaultMaxMissionTasks,
        maxMissionDuration: defaultMaxMissionDuration,
        maxMissionCapacity: defaultMissionCapacity,
        multiMission: false,
        optimiseMode: "time",
        missionDate: moment(new Date(localCurrentDate)).toDate(),
        teamId: "",
        agentId: "",
    };
    const [loading, setLoading] = useState(false);
    const [socket, setSocket] = useState(null);
    const userData = useSelector((state) => state.auth.user)
    const superAdminRole = userData && userData.role && userData.role === 1 ? true : false
    const [view, setView] = useState("MAP");
    const [selectedBottomMenuTeam, setSelectedBottomMenuTeam] = useState("all");
    const [selectedBottomMenuAgent, setSelectedBottomMenuAgent] = useState([]);
    const [selectedBottomMenuMerchant, setSelectedBottomMenuMerchant] = useState([]);
    const [selectedBottomMenuUser, setSelectedBottomMenuUser] = useState([]);
    const [selectedBottomMenuStatus, setSelectedBottomMenuStatus] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [hubList, setHubList] = useState([]);
    const [merchantList, setMerchantList] = useState([]);
    const [assignToggle, setAssignToggle] = useState(false);
    const [printToggle, setPrintToggle] = useState(false);
    const [importToggle, setImportToggle] = useState(false);
    const [updateMissionToggle, setUpdateMissionToggle] = useState(false);
    const [customOccurencesToggle, setCustomOccurencesToggle] = useState(false);
    const [duplicateMissionToggle, setDuplicateMissionToggle] = useState(false);
    const [multiMissionToggle, setMultiMissionToggle] = useState(false);
    const [multiMissionOption, setMultiMissionOption] = useState("")
    const [addDocToggle, setAddDocToggle] = useState(false);
    const [PDFData, setPDFData] = useState("");
    const [selectedMissions, setSelectedMissions] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date(localCurrentDate));
    const currentDateRef = useRef(new Date(localCurrentDate))
    const [taskList, setTaskList] = useState([]);
    const [unassignedTaskList, setUnassignedTaskList] = useState([]);
    const [bookMarks, setBookMarks] = useState([]);
    const [libraryList, setLibraryList] = useState([]);
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [missionList, setMissionList] = useState([]);
    const [currentMission, setcurrentMission] = useState("");
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [optimiseFormData, setOptimiseFormData] = useState(initialValues2);
    const [showOptimiseSection, setShowOptimiseSection] = useState(false);
    const [taskCount, setTaskCount] = useState(0);
    const myStateRef = React.useRef(agentBookMarks);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [taskDetail, setTaskDetail] = useState(null);
    const [toggleState, setToggleState] = useState({});
    const [lastSelectedTaskIndex, setSelectedTaskIndex] = useState(null);
    const [lastSelectedPostion, setSelectedTaskPosition] = useState(null);
    const [selectedMission, setSelectedMission] = useState(null)
    const [defaultCenter, setDefaultCenter] = useState({
        lat: routes[0].lat,
        lng: routes[0].lng,
    });
    const [teams, setTeams] = useState([])
    const [features, setFeatures] = useState({})
    const [pageTaskCount, setTaskPageCount] = useState(1);
    const [map, setMap] = useState(/** @type google.maps.Map */(null))
    const [exportTasks, setExportTasks] = useState(false);
    const [selectedItems, setSelectedItems] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const defaultSubscriptionData = useSelector((state) => state.defaultSubscription);
    const extensionData = useSelector((state) => state.extensions);
    const [taskSettingData, setTaskSettingData] = useState(null);
    const [regionalSettingData, setRegionalSettingData] = useState(null);
    const [directions, setDirections] = useState(null);
    const [dummyDirections, setDummyDirections] = useState([]);
    const [hoverHub, setHoverHub] = useState(null)
    const [createTask, setcreateTask] = useState(true);
    const [location, setLocation] = useState("");
    const [address, setAddress] = useState("");
    const [buttonText, setButtonText] = useState("Create Task");

    const initialValuesTask = {
        id: "",
        orderType: "D",
        merchantId: "",
        createdFor: "",
        customerId: "",
        userId: "",
        notes: "",
        orderDate: currentDate,
        after: taskSettingData && (taskSettingData.defaultAfterTime || taskSettingData.defaultAfterTime === "") ? taskSettingData.defaultAfterTime : "",
        afterTimePicker: taskSettingData && (taskSettingData.defaultAfterTimePicker || taskSettingData.defaultAfterTimePicker === "") ? taskSettingData.defaultAfterTimePicker : null,
        before: taskSettingData && (taskSettingData.defaultBeforeTime || taskSettingData.defaultBeforeTime === "") ? taskSettingData.defaultBeforeTime : "",
        beforeTimePicker: taskSettingData && (taskSettingData.defaultBeforeTimePicker || taskSettingData.defaultBeforeTimePicker === "") ? taskSettingData.defaultBeforeTimePicker : null,
        scheduledTimePicker: taskSettingData && (taskSettingData.defaultScheduleTimePicker || taskSettingData.defaultScheduleTimePicker === "") ? taskSettingData.defaultScheduleTimePicker : null,
        scheduledTime: taskSettingData && (taskSettingData.defaultScheduleTime || taskSettingData.defaultScheduleTime === "") ? taskSettingData.defaultScheduleTime : "",
        afterSeconds: taskSettingData && (taskSettingData.defaultAfterTimeSeconds || taskSettingData.defaultAfterTimeSeconds === 0) ? taskSettingData.defaultAfterTimeSeconds : 0,
        beforeSeconds: taskSettingData && (taskSettingData.defaultBeforeTimeSeconds || taskSettingData.defaultBeforeTimeSeconds === 0) ? taskSettingData.defaultBeforeTimeSeconds : 0,
        scheduledTimeSeconds: taskSettingData && (taskSettingData.defaultScheduleTimeSeconds || taskSettingData.defaultScheduleTimeSeconds === 0) ? taskSettingData.defaultScheduleTimeSeconds : 0,
        durationTime: taskSettingData && (taskSettingData.defaultTaskDuration || taskSettingData.defaultTaskDuration === 0) ? taskSettingData.defaultTaskDuration : defaultDurationTime,
        capacity: taskSettingData && (taskSettingData.defaultTaskCapacity || taskSettingData.defaultTaskCapacity === 0) ? taskSettingData.defaultTaskCapacity : defaultMissionCapacity,
        capacityUnit: regionalSettingData && (regionalSettingData.volumeUnit) ? regionalSettingData.volumeUnit : "m3",
        firstName: "",
        lastName: "",
        ISO: "ca",
        countryCode: "+1",
        phoneNumber: "",
        email: "",
        address: "",
        apartment: "",
        afterOpen: false,
        beforeOpen: false,
        scheduleTimeOpen: false,
        taskStatus: "",
        documents: [],
        price: "",
        priceUnitSymbol: "$",
        priceUnit: "USD",
        libraryFiles: [],
        teamIds: [],
        dateChanged: false,
        recurringId: null
        // beforeTime: "",
        // afterTime: "",
        // scheduleTime: "",
    };
    const [formData, setFormData] = useState(initialValuesTask);
    const [showRecurringMissionPopup, setShowRecurringMissionPopup] = useState(null)
    const timezone = { ...timeZoneHeader() }

    useEffect(() => {
        extensionData && extensionData.extension && extensionData.extension.length && extensionData.extension.map((data) => {
            // if (data.isEnabled) {
            data.userExtensions.map((extension) => {
                setFeatures((prev) => {
                    return {
                        ...prev,
                        ...extension.features,
                        additionalTaskNumber: Number(extension.features.additionalTaskNumber)
                    }
                })
                return {}
            })
            // }
        })
    }, [extensionData])

    useEffect(() => {
        // getTaskList();
        // getMissionList()
        getAgentList();
        getTeamList();
        getUserList();
        getHubList();
        // getLibraryList();
        getMerchantList();
        getTaskCount();
        getSetting();
    }, []);

    useEffect(() => {
        if (defaultSubscriptionData && defaultSubscriptionData.extension && defaultSubscriptionData.extension.data && defaultSubscriptionData.extension.data.results.length) {
            setFeatures((prev) => {
                return { ...prev, ...defaultSubscriptionData.extension.data.results[0].features }
            })
        }
    }, [defaultSubscriptionData])

    useEffect(() => {
        window.navigator.geolocation.getCurrentPosition((position) => {
            setDefaultCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
        })
        const listener = e => {
            if (e.key === "Escape") {
                setSelectedCenter(null);
            }
        };

        window.addEventListener("keydown", listener);
        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, [])

    useEffect(() => {
        let socketData
        if (userData) {
            socketData = socketConnection({ user: userData })
            setSocket(socketData)
        }
        return () => {
            if (socketData) {
                socketData.socket.close()
            }
        };
    }, []);

    useEffect(() => {
        if (dummyDirections.length) {
            let directionsResultsReturned = 0
            let combinedResults
            for (let k = 0; k < dummyDirections.length; k++) {
                if (directionsResultsReturned === 0) { // first bunch of results in. new up the combinedResults object
                    combinedResults = dummyDirections[k];
                    directionsResultsReturned++;
                }
                else {
                    // only building up legs, overview_path, and bounds in my consolidated object. This is not a complete
                    // directionResults object, but enough to draw a path on the map, which is all I need
                    combinedResults.routes[0].legs = combinedResults.routes[0].legs.concat(dummyDirections[k].routes[0].legs);
                    combinedResults.routes[0].overview_path = combinedResults.routes[0].overview_path.concat(dummyDirections[k].routes[0].overview_path);

                    combinedResults.routes[0].bounds = combinedResults.routes[0].bounds.extend(dummyDirections[k].routes[0].bounds.getNorthEast());
                    combinedResults.routes[0].bounds = combinedResults.routes[0].bounds.extend(dummyDirections[k].routes[0].bounds.getSouthWest());
                    directionsResultsReturned++;
                }
                if (directionsResultsReturned === dummyDirections.length) // we've received all the results. put to map
                    setDirections(combinedResults);
            }
        }
    }, [dummyDirections])

    useEffect(() => {
        listenToEvent("COMMON_EVENT", async (socketData) => {
            if (socketData.type === "DRIVER_LOCATION") {
                let newBook = [...myStateRef.current];
                let findIndex = newBook.findIndex(i => i.id === socketData.agentId);
                if (findIndex > -1) {
                    let data = {
                        ...newBook[findIndex],
                        ...socketData.agentCoordinates,
                        id: socketData.agentId,
                        markerImage: agentMarker,
                        notClickable: false,
                        showMarkerLabel: false,
                        ...(socketData.taskId && { taskId: socketData.taskId }),
                        ...(socketData.missionId && { missionId: socketData.missionId }),
                        markerType: "AGENT"
                    }
                    newBook[findIndex] = data;
                }
                else {
                    let data = {
                        firstName: socketData.firstName,
                        lastName: socketData.lastName,
                        teamId: socketData.teamId,
                        ...socketData.agentCoordinates,
                        id: socketData.agentId,
                        markerImage: agentMarker,
                        notClickable: false,
                        showMarkerLabel: false,
                        ...(socketData.taskId && { taskId: socketData.taskId }),
                        ...(socketData.missionId && { missionId: socketData.missionId }),
                        markerType: "AGENT"
                    }
                    newBook.push(data);
                }
                myStateRef.current = newBook;

                setAgentBookMarks(newBook)
                // if (socketData.missionId) {
                //     let missionLists = await getMissionList(true, true, currentDateRef.current)
                //     let findMission = missionLists.find(item => { return item.id === socketData.missionId })
                //     if (findMission) {
                //         getDirections(findMission)
                //     }
                // }
            }
        })


        listenToEvent("TASK_EVENT", async (data) => {
            if (data.type === "TASK_UPDATED") {
                if (data.taskId) {
                    let missionLists = await getMissionList(true, true, currentDateRef.current)
                    let mission = missionLists.find(item => { return item.id === data.missionId })
                    getDirections(mission);
                    getTaskList(data.taskId, false, null, currentDateRef.current)
                }
            }
            else if (data.type === "MISSION_UPDATED") {
                let missionLists = await getMissionList(true, true, currentDateRef.current)
                let mission = missionLists.find(item => { return item.id === data.missionId })
                getDirections(mission);
                getTaskList(data.taskId, false, null, currentDateRef.current)
            }
            else if (data.type === "MISSION_UPDATED_ADMIN") {
                await getMissionList(true, true, currentDateRef.current)
                getTaskList(false, false, null, currentDateRef.current)
                getUnassignedTaskList()
            }
            else if (data.type === "TASK_UPDATED_ADMIN") {
                await getMissionList(true, true, currentDateRef.current)
                getTaskList(false, false, null, currentDateRef.current)
                getUnassignedTaskList()
            }
        })
    }, [])

    // useEffect(() => {
    //     getLibraryList()
    // }, [selectedDocs])

    useEffect(() => {
        getTaskList();
        getMissionList()
        currentDateRef.current = currentDate;
        setOtherMarkers([])

    }, [currentDate]);

    useEffect(() => {
        let apiData = {

        }
        if (selectedBottomMenuMerchant.length) {
            apiData.merchantIds = selectedBottomMenuMerchant
        }
        if (selectedBottomMenuAgent.length) {
            apiData.agentIds = selectedBottomMenuAgent
        }
        if (selectedBottomMenuTeam.length) {
            apiData.teamIds = selectedBottomMenuTeam
        }
        if (selectedBottomMenuUser.length) {
            apiData.userIds = selectedBottomMenuUser
        }
        if (selectedBottomMenuStatus.length) {
            apiData.taskStatuses = selectedBottomMenuStatus
        }
        let apiKeys = Object.keys(apiData)
        if (apiKeys.length) {
            getTaskList(false, false, apiData)
            if (apiData.teamIds && apiData.teamIds.length) {
                let api = {
                    teamIds: apiData.teamIds
                }
                getUnassignedTaskList(api)
            }
        }
        setDirections(null)
    }, [selectedBottomMenuMerchant, selectedBottomMenuAgent, selectedBottomMenuStatus, selectedBottomMenuTeam, selectedBottomMenuUser])

    const getSetting = async () => {
        let params = {
            userId: userData.id,
            subType: [
                "TASK_AND_MISSION",
                "REGIONAL_SETTINGS"
            ]
        }
        // if (userData.role === 1) {
        //   params.subType = [
        //     settingSubType.COMMON_AGENT_APP,
        //     settingSubType.COMMON_API_KEY,
        //     settingSubType.COMMON_COMMUNICATIONS,
        //     settingSubType.COMMON_GROUPS,
        //     settingSubType.COMMON_LEGAL_PROTECTION,
        //     settingSubType.COMMON_NOTIFICATIONS,
        //     settingSubType.COMMON_PERFORMANCE,
        //     settingSubType.COMMON_REGIONAL_SETTINGS,
        //     settingSubType.COMMON_TASK_AND_MISSION,
        //   ]
        // }
        let response = await authService.getSettings(params);
        if (response && response.data && response.data.data && response.data.data.results.length) {
            let findTaskSetting = response.data.data.results.find(i => {
                return i.subType === "TASK_AND_MISSION"
            })
            setTaskSettingData(findTaskSetting)
            let findRegionalSetting = response.data.data.results.find(i => {
                return i.subType === "REGIONAL_SETTINGS"
            })
            setRegionalSettingData(findRegionalSetting)
        }
    }

    const getAgentList = async (payload) => {
        let params = { limit: 1000 }
        // if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
        //     params.userIds = userData.permittedUsers
        // }
        if (userData && userData.teamIds) {
            params.teamIds = [...userData.teamIds]
        }
        if (payload && payload.teamId) {
            params.teamIds = [payload.teamId]
        }
        const response = await userService.getAgentList(params);
        if (response.status == 200) {
            if (response.data.results.length) {
                let agentCoordinates = [];
                for (let item of response.data.results) {
                    if (item.coordinates) {
                        agentCoordinates.push({
                            firstName: item.firstName,
                            lastName: item.lastName,
                            teamId: item.teamId,
                            id: item.id,
                            ...item.coordinates,
                            markerImage: agentMarker,
                            notClickable: false,
                            showMarkerLabel: false,
                            markerType: "AGENT"
                        })

                    }
                }

                if (agentCoordinates.length) {
                    myStateRef.current = agentCoordinates;
                    setAgentBookMarks(agentCoordinates)
                }
            }
            setAgentList(response.data.results || []);
        }
    };

    const deleteTaskFunction = async (result, option) => {
        if (result.isConfirmed) {
            let data = {
                taskIds: selectedTasks
            }
            if (option === "ALL_FOLLOWING_TASKS") {
                data.taskStrings = showRecurringMissionPopup.taskStrings
                data.recurringIds = showRecurringMissionPopup.recurringIds
                data.currentDate = currentDate
            }
            const response = await taskService.deleteTasks(data);
            let missionIds = [];
            for (let task of response.data) {
                if (task.missionId) {
                    let checkMission = missionIds.includes(task.missionId);
                    if (!checkMission) {
                        missionIds.push(task.missionId._id)
                    }
                }
            }
            if (missionIds.length) {
                await taskService.updateMissionDirections({ missionIds })
            }
            // for (let miss of missionIds) {
            //     let findMission = missionLists.find(m => m.id === miss);
            //     if (findMission) {
            //         if (findMission.tasks && findMission.tasks.length) {
            //             let maxMissionCapacity = 0, maxMissionDuration = 0, startFrom, endAt, tasks = { locations: [] }, missionDetails = {
            //                 totalTime: 0,
            //                 totalDist: 0
            //             };
            //             let tasksDistTime = [];
            //             if (findMission.startFrom) {
            //                 startFrom = findMission.startFrom
            //             }
            //             if (findMission.endAt) {
            //                 endAt = findMission.endAt
            //             }
            //             for (let task in findMission.tasks) {
            //                 maxMissionCapacity += findMission.tasks[task].capacity ? Number(findMission.tasks[task].capacity) : 0;
            //                 if (findMission.tasks[task].durationTime) maxMissionDuration += Number(findMission.tasks[task].durationTime);
            //                 if (Number(task) === 0 && !findMission.startFrom) {
            //                     startFrom = {
            //                         location: findMission.tasks[task].location
            //                     }
            //                 }
            //                 else if (Number(task) === findMission.tasks.length - 1 && !findMission.endAt) {
            //                     endAt = {
            //                         location: findMission.tasks[task].location
            //                     }
            //                 }
            //                 else {
            //                     tasks.locations.push({ location: findMission.tasks[task].location, id: findMission.tasks[task].id })
            //                 }
            //             }
            //             if (startFrom && endAt) {
            //                 let result = await OptimizeMission.directionsServiceHere(tasks, false, [], startFrom, endAt);
            //                 if (result) {
            //                     let myroute = result[0];
            //                     missionDetails.totalDist += Number(myroute.distance);
            //                     missionDetails.totalTime += Number(myroute.time);
            //                     if (findMission.startFrom && findMission.endAt) {
            //                         for (let i = 0; i < myroute.interconnections.length - 1; i++) {
            //                             tasksDistTime.push({
            //                                 id: findMission.tasks[i].id,
            //                                 distanceFromPrevTask: myroute.interconnections[i].distance / 1000,
            //                                 timeFromPrevTask: Number(myroute.interconnections[i].time) / 60
            //                             })
            //                         }
            //                     }
            //                     else {
            //                         for (let i = 0; i < myroute.interconnections.length; i++) {
            //                             tasksDistTime.push({
            //                                 id: findMission.tasks[i + 1].id,
            //                                 distanceFromPrevTask: myroute.interconnections[i].distance / 1000,
            //                                 timeFromPrevTask: Number(myroute.interconnections[i].time) / 60
            //                             })
            //                         }
            //                     }
            //                     missionDetails.totalTime += (maxMissionDuration * 60);
            //                     if (missionDetails.totalTime) {
            //                         missionDetails.totalTime = missionDetails.totalTime / 3600
            //                         missionDetails.totalTime = missionDetails.totalTime.toFixed(1)
            //                     }
            //                     if (missionDetails.totalDist) {
            //                         missionDetails.totalDist = missionDetails.totalDist / 1000
            //                         missionDetails.totalDist = missionDetails.totalDist.toFixed(1)
            //                     }


            //                     let data = {
            //                         missionId: findMission.id,
            //                         missionDetails: missionDetails,
            //                         missionTaskCapacity: maxMissionCapacity,
            //                         tasks: tasksDistTime
            //                     }
            //                     apiData.push(data)
            //                 }
            //             }
            //             else {
            //                 let data = {
            //                     missionId: findMission.id,
            //                     missionDetails: {
            //                         totalDist: 0,
            //                         totalTime: 0
            //                     },
            //                     missionTaskCapacity: maxMissionCapacity,
            //                     tasks: tasksDistTime
            //                 }
            //                 apiData.push(data)
            //             }
            //         }
            //         else {
            //             let data = {
            //                 missionId: findMission.id,
            //                 missionDetails: {
            //                     totalDist: 0,
            //                     totalTime: 0
            //                 },
            //                 missionTaskCapacity: 0,
            //                 tasks: []
            //             }
            //             apiData.push(data)
            //         }
            //     }
            //     if (apiData.length)
            //         await taskService.multipleMissionUpdate({ missions: apiData })

            // }
        }
        getMissionList(true, true);
        getUnassignedTaskList();
        getTaskList();
        setLoading(false)
    }


    const removeTaskHandler = async () => {
        let recurring = false;
        let taskStrings = [];
        let recurringIds = [];
        if (selectedTasks && selectedTasks.length) {
            if (taskList && taskList.length) {
                for (let task of selectedTasks) {
                    let findTasks = taskList.find((t) => t.id === task);
                    if (findTasks && findTasks.recurring && findTasks.missionId) {
                        if (!recurring && findTasks.recurring) {
                            recurring = true
                        }
                        taskStrings.push(findTasks.taskString)
                        recurringIds.push(findTasks.recurringId)
                    }
                }
            }
        }
        if (recurring) {
            setShowRecurringMissionPopup({ show: true, type: "REMOVE_TASK", text: "Remove recurring task", taskStrings, recurringIds })
        }
        else {
            await removeMenu()
        }
    };

    const removeMenu = async (option) => {
        setLoading(true)
        setShowRecurringMissionPopup(false)
        if (selectedTasks && selectedTasks.length) {
            let data = { taskIds: selectedTasks };
            if (option === "ALL_FOLLOWING_TASKS") {
                data.taskStrings = showRecurringMissionPopup.taskStrings
                data.recurringIds = showRecurringMissionPopup.recurringIds
                data.currentDate = currentDate
            }
            const response = await taskService.removeTasksFromMission(data);
            if (response.data && response.data.taskList) {
                let missionIds = [];
                for (let task of response.data.taskList) {
                    if (task.missionId) {
                        let checkMission = missionIds.includes(task.missionId);
                        if (!checkMission) {
                            missionIds.push(task.missionId._id)
                        }
                    }
                }
                if (missionIds.length) {
                    await taskService.updateMissionDirections({ missionIds })
                }
                getMissionList(true, true);
                getUnassignedTaskList();
                getTaskList();
                setLoading(false)
            }
            else {
                errorAlert("Something went wrong")
            }
        }
    };

    const deleteMenuHandler = async () => {
        let recurring = false;
        let taskStrings = [];
        let recurringIds = [];
        if (selectedTasks && selectedTasks.length) {
            if (taskList && taskList.length) {
                for (let task of selectedTasks) {
                    let findTasks = taskList.find((t) => t.id === task);
                    if (findTasks && findTasks.recurring && findTasks.missionId) {
                        if (!recurring && findTasks.recurring) {
                            recurring = true
                        }
                        taskStrings.push(findTasks.taskString)
                        recurringIds.push(findTasks.recurringId)
                    }
                }
            }
        }
        if (recurring) {
            setShowRecurringMissionPopup({ show: true, type: "DELETE_TASK", text: "Delete recurring task", taskStrings, recurringIds })
        }
        else {
            await deleteMenu()
        }

    };

    const deleteMenu = async (option) => {
        setLoading(true)
        setShowRecurringMissionPopup(false)
        if (selectedTasks && selectedTasks.length) {
            Swal.fire({
                title: "Are you sure?",
                text: "You want to delete task(s)!",
                icon: "warning",
                showCancelButton: true,
                color: "#ffffff",
                background: "#151719",
                confirmButtonColor: "#1A1D1F",
                cancelButtonColor: "#1A1D1F",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => deleteTaskFunction(result, option))
        }
    }
    const getTeamList = async () => {
        let params = { limit: 100 }
        if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
            params.userIds = userData.permittedUsers
        }
        if (userData.teamIds) {
            params.teamIds = userData.teamIds
        }
        if (userData.subAdmin) {
            delete params.teamIds
            delete params.userIds
        }
        const response = await teamService.getTeamList(params);
        if (response.status == 200) {
            setTeams(response.data.results || []);
        }
        console.log("team list response  :::", response);
    };

    const getUserList = async () => {
        let params = { limit: 100, role: 2 }
        let user = localStorage.getItem("user")
        if (user) {
            user = JSON.parse(user);
        }
        if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
            params.userIds = userData.permittedUsers
        }
        const response = await userService.getSubAdminList(params);
        if (response.status == 200) {

            if (response.data.results && response.data.results.length) {
                let found = response.data.results.find(r => r.id == user.id)
                if (!found) {
                    setUserList([...response.data.results, user]);
                } else {
                    setUserList(response.data.results);
                }
            } else {
                setUserList([user]);
            }
        }
        console.log("team list response  :::", response);
    };

    const getHubList = async () => {
        const response = await hubService.getHubList({ limit: 100 });
        if (response.status == 200) {
            setHubList(response.data.results || []);
            if (response && response.data && response.data.results) {
                let otherMarkers = response.data.results.map((i) => {
                    return {
                        id: i.id,
                        orderType: "SL",
                        markerImage: startLocationMarker,
                        hover: true,
                        hubName: i.hubName,
                        notClickable: true,
                        ...i.location
                    }
                })
                setOtherMarkers(otherMarkers)
            }
        }
    };

    const getUnassignedTaskList = async (apiData) => {
        let data = {
            limit: 100,
            date: currentDate,
        }
        if (apiData) {
            data = { ...data, ...apiData }
        }
        if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
            data.userIds = userData.permittedUsers
        }

        // if (teams.length) {
        //     data.teamIds = teams.map(i => i.id)
        // }
        const response = await taskService.getUnassignedTaskList(data);

        if (response.status == 200) {
            setUnassignedTaskList(response.data.results || []);
        }
    };

    const getTaskList = async (changeFocus = false, setMissionDirections = true, apiData, date, setSelectedTask) => {
        let data = { date: date ? date : currentDate };
        if (apiData) {
            data = { ...data, ...apiData }
        }
        if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
            data.userIds = userData.permittedUsers
        }
        // if (teams.length) {
        //     data.teamIds = teams.map(i => i.id)
        // }
        const response = await taskService.getTaskList(data);
        if (setMissionDirections) { setDirections(null) }
        if (response.status == 200) {
            if (setSelectedTask) {
                selectTasksPolygon({
                    tasks: response.data.results,
                    unassignedTaskList,
                    setBookMarks,
                    setSelectedTasks,
                    setUnassignedTaskList,
                    bookMarks
                })
            }
            else {
                if (response.data.results && response.data.results.length) {
                    const data = response.data.results.map((t) => {
                        if (t && t.location) {
                            let data = {
                                id: t.id, ...t.location, address: t.address, position: t.position, orderType: t.orderType,
                                customerId: t.customerId, agentId: t.agentId, teamId: t.teamId, taskStatus: t.taskStatus,
                                optimised: t.missionId && t.missionId.optimised ? t.missionId.optimised : false,
                                missionDetails: t.missionId && t.missionId.missionDetails ? t.missionId.missionDetails : {},
                                capacity: t.capacity, capacityUnit: t.capacityUnit, eta: "__", etaSeconds: t.etaSeconds,
                                markerType: "TASK", missioned: t.missioned, updatedAt: t.updatedAt, orderCompletedDate: t.orderCompletedDate, customer: t.customer
                            }
                            data.distanceUnit = regionalSettingData && regionalSettingData.distanceUnit ? regionalSettingData.distanceUnit : "km"
                            if (t.taskStatus === "unassigned") {
                                data.markerImage = unassignedTaskMarker;
                                data.selectedMarkerImage = selectedUnassignedTaskMarker;
                            }
                            else if (t.taskStatus === "assigned") {
                                data.markerImage = assignedTaskMarker;
                                data.selectedMarkerImage = selectedAssignedMarker;
                            }
                            else if (t.taskStatus === "completed") {
                                data.markerImage = completedTaskMarker;
                                data.selectedMarkerImage = selectedCompletedMarker;
                            }
                            else if (t.taskStatus === "failed") {
                                data.markerImage = failedTaskMarker;
                                data.selectedMarkerImage = selectedFailedTaskMarker;
                            }
                            else if (t.taskStatus === "delayed") {
                                data.markerImage = delayTaskMarker;
                                data.selectedMarkerImage = selectedDelayMarker;
                            }
                            else if (t.taskStatus === "started") {
                                data.markerImage = startedTaskMarker;
                                data.selectedMarkerImage = selectedStartedMarker;
                            }
                            else if (t.taskStatus === "reached") {
                                data.markerImage = startedTaskMarker;
                                data.selectedMarkerImage = selectedStartedMarker;
                            }
                            return data;
                        }
                    });

                    setBookMarks(data);
                    if (changeFocus && data && data.length) {
                        let found = data.find(d => d.id === changeFocus)
                        if (found) {
                            if (setMissionDirections) {
                                setDirections(null)
                                setSelectedCenter(null)
                            }
                            // map.panTo({lat: found.lat, lng: found.lng})
                            setDefaultCenter(found)
                        }
                        else {
                            // map.panTo(defaultCenter)
                            setDefaultCenter(defaultCenter)
                        }
                    }
                    else {
                        // map.panTo(defaultCenter)
                        setDefaultCenter(defaultCenter)
                    }
                }
                else {
                    setBookMarks([]);
                }
                setTaskList(response.data.results || []);
                setTaskPageCount(response.data.totalPages);
            }
        }

    };

    const getMerchantList = async () => {
        const response = await userService.getMerchantList({ limit: 100 });
        if (response.status == 200) {
            let user = localStorage.getItem("user")
            if (user) {
                user = JSON.parse(user);
            }
            if (response.data.results && response.data.results.length) {
                let found = response.data.results.find(r => r.id == user.id)
                if (!found) {
                    setMerchantList([...response.data.results, user]);
                } else {
                    setMerchantList(response.data.results);
                }
            } else {
                setMerchantList([user]);
            }
        }
    };


    const getMissionList = async (updateState = true, preventToggleUpdate = false, date) => {
        let data = {
            date: date ? date : currentDate,
        }
        if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
            data.userIds = userData.permittedUsers
        }
        const response = await taskService.getMissionList(data);
        let result = []
        if (response.status === 200) {
            // let missionCords = []
            if (response.data.results && response.data.results.length && !preventToggleUpdate) {
                let mappedObject = {};
                response.data.results.map((m) => {
                    let obj = {};
                    obj[m.id] = false;
                    mappedObject = { ...obj, ...mappedObject };
                    return obj;
                });
                console.log("mappedObject", mappedObject)
                setToggleState(mappedObject);
            }
            result = response.data.results || []
            updateState && setMissionList(result);
            return result
        }
    };

    const getLibraryList = async () => {
        const response = await libraryService.getLibraryList({ limit: 1000 });
        if (response.status == 200) {
            if (selectedDocs && selectedDocs.length) {
                if (response.data.results && response.data.results.length) {
                    const docs = response.data.results.map(item => {
                        if (selectedDocs.includes(item.id)) {
                            item.check = true
                        }
                        return item
                    })
                    setLibraryList([...docs])
                } else {
                    setLibraryList(response.data.results || []);
                }
            } else {
                setLibraryList(response.data.results || []);
            }

        }
        console.log("library list response  :::", response);
    };

    async function importPopup() {
        Flatfile.requestDataFromUser({
            // theme: {
            //     hideConfetti: true,
            //     loadingText: "Custom loading text ...",
            //     submitCompleteText: "Custom submit text ...",
            //     displayName: "Display Name",
            // },
            // Development mode = embedId, user, org.
            token: async () => {
                // customize this to match your response payload
                const res = await authService.flatFileAuth();
                return res.data;
            },
            // handle submitted data
            async onData(chunk, next) {
                // do something with the data
                const records = chunk.records
                let importData = records.map((i) => {
                    i.data.recordId = i.recordId
                    return i.data
                })
                let { errors } = await checkForErrorRows([...importData])
                if (!errors || (errors && !errors.length)) {
                    next()
                }
                else {
                    let allErrors = []
                    for (let err of errors) {
                        allErrors.push(
                            // A RecordError should be created with an record (or record id)
                            // and a list of validation errors.
                            new RecordError(err.recordId, err.error),
                        );
                    }
                    console.log("allErrors", allErrors)
                    next(new PartialRejection(allErrors))
                }
            },
            // onInit({ session }) {
            // },
            async onComplete(data) {
                let validData = await data.data();
                await importData(validData)
            },
            onError(error) {
                return errorAlert(error)
            },
        });
    }

    let checkForErrorRows = async (importedTasks) => {
        let invalidTasks = [], validTasks = [];
        for (let task of importedTasks) {
            let validMerchant = false, validAddress = false;
            if (!task["address"] /*|| !task["merchantEmail"]*/ || !task["orderType"] /*|| !task["deliveryDate"]*/) {
                if (!task["address"]) {
                    invalidTasks.push({
                        recordId: task.recordId,
                        error: [{ field: "address", message: "Address is missing" }]
                    })
                }
                // if (!task["merchantEmail"]) {
                //     invalidTasks.push({
                //         recordId: task.recordId,
                //         error: [{ field: "merchantEmail", message: "Merchant email is missing" }]
                //     })
                // }
                if (!task["orderType"]) {
                    invalidTasks.push({
                        recordId: task.recordId,
                        error: [{ field: "orderType", message: "Order Type is missing" }]
                    })
                }
            }
            if (task["merchantEmail"] && task["merchantEmail"] !== userData.email) {
                let findMerchant = merchantList.find(i => { return i.email === task["merchantEmail"] });
                if (userData && userData.role === 1) {
                    findMerchant = userList.find(i => { return i.email === task["merchantEmail"] })
                }
                if (!findMerchant) {
                    invalidTasks.push({
                        recordId: task.recordId,
                        error: [{ field: "merchantEmail", message: "Invalid merchant email" }]
                    })
                    validMerchant = false
                }
                else {
                    validMerchant = true
                }
            }
            if (task["address"]) {
                task["address"] = task["address"].replace(/[\-\[\]\/\{\}\(\)\*\/\#\+\?\.\\\^\$\|]/g, "")
                let response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${task["address"]}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
                if (!response || (response && !response.data) || (response && response.data && !response.data.results[0])) {
                    invalidTasks.push({
                        recordId: task.recordId,
                        error: [{ field: "address", message: "Invalid address" }]
                    })
                    validAddress = false
                }
                else {
                    validAddress = true
                }
            }
            // if (validAddress && validMerchant && task["address"] && task["merchantEmail"] && task["orderType"]) {
            //     validTasks.push(task)
            // }
        }
        return { errors: invalidTasks, validTasks }
    }

    const escapeRegex = (str) => {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "").replace(/ /g, "");
    };


    const importData = async (validData) => {
        setLoading(true)
        let tasks = [], invalidTasks = [];
        let taskData = validData.rows.map((i) => {
            return i.data
        })

        for (let task of taskData) {
            let findMerchant
            if (task["merchantEmail"] === userData.email) {
                findMerchant = userData
            }
            else if (!task["merchantEmail"]) {
                findMerchant = userData
            }
            else {
                findMerchant = merchantList.find(i => { return i.email === task["merchantEmail"] });
                if (userData && userData.role === 1) {
                    findMerchant = userList.find(i => { return i.email === task["merchantEmail"] })
                }
            }
            if (findMerchant || !task["merchantEmail"]) {
                let defaultTaskDuration = defaultDurationTime;
                let orderType = "D";
                if (taskSettingData && (taskSettingData.defaultTaskDuration || taskSettingData.defaultTaskDuration === 0)) {
                    defaultTaskDuration = taskSettingData.defaultTaskDuration
                }
                if (task["orderType"]) {
                    if (task["orderType"] === "Drop") {
                        orderType = "D";
                        if (taskSettingData && (taskSettingData.defaultTaskDuration || taskSettingData.defaultTaskDuration === 0)) {
                            defaultTaskDuration = taskSettingData.defaultTaskDuration
                        }
                    }
                    else if (task["orderType"] === "Drop and Install" || task["orderType"] === "Drop & Install") {
                        orderType = "DI";
                        if (taskSettingData && (taskSettingData.defaultInstallationTime || taskSettingData.defaultInstallationTime === 0)) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime
                        }
                    }
                    else if (task["orderType"] === "Drop and Service" || task["orderType"] === "Drop & Service") {
                        orderType = "DI";
                        if (taskSettingData && (taskSettingData.defaultInstallationTime || taskSettingData.defaultInstallationTime === 0)) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime
                        }
                    }
                    else if (task["orderType"] === "Return") {
                        orderType = "R";
                        if (taskSettingData && (taskSettingData.defaultInstallationTime || taskSettingData.defaultInstallationTime === 0)) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime
                        }
                    }
                    else if (task["orderType"] === "Service") {
                        orderType = "S";
                        if (taskSettingData && (taskSettingData.defaultInstallationTime || taskSettingData.defaultInstallationTime === 0)) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime
                        }
                    }
                    else if (task["orderType"] === "Pick Up" || task["orderType"] === "Pick up") {
                        orderType = "P";
                        if (taskSettingData && (taskSettingData.defaultTaskDuration || taskSettingData.defaultTaskDuration === 0)) {
                            defaultTaskDuration = taskSettingData.defaultTaskDuration
                        }
                    }
                    else if (task["orderType"] === "Exchange") {
                        orderType = "E";
                        if (taskSettingData && (taskSettingData.defaultInstallationTime || taskSettingData.defaultInstallationTime === 0)) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime
                        }
                    }
                };
                let location;
                if (task["longitude"] && task["latitude"]) {
                    location = {
                        lat: Number(task["latitude"]),
                        lng: Number(task["longitude"])
                    }
                }
                else {
                    task["address"] = task["address"].replace(/[\-\[\]\/\{\}\(\)\*\/\#\+\?\.\\\^\$\|]/g, "")
                    let response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${task["address"]}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
                    // console.log(`https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${task["address"]}&inputtype=textquery&fields=formatted_address,name,geometry&key=${process.env.REACT_APP_GOOGLE_API_KEY}`)
                    // let response = await axios.get(`https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${task["address"]}&inputtype=textquery&fields=formatted_address,name,geometry&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);

                    if (response && response.data && response.data.results[0]) {
                        location = {
                            lat: response.data.results[0].geometry.location.lat,
                            lng: response.data.results[0].geometry.location.lng
                        }
                    }
                }
                let timezone = { ...timeZoneHeader() }
                if (location) {
                    let data = {
                        orderType: orderType,
                        merchantId: findMerchant ? findMerchant.id : userData.id,
                        notes: task["notes"],
                        orderDate: task["orderDate"] ? `${momentTz(task["orderDate"]).tz(timezone.timezone).startOf("d")._d}` : null,
                        after: task["after"],
                        afterTimePicker: null,
                        before: task["before"],
                        beforeTimePicker: null,
                        scheduledTimePicker: null,
                        scheduledTime: task["scheduledTime"],
                        afterSeconds: 0,
                        beforeSeconds: 0,
                        scheduledTimeSeconds: 0,
                        durationTime: task["durationTime"],
                        firstName: task["firstName"],
                        lastName: task["lastName"],
                        ISO: "ca",
                        countryCode: task["countryCode"] ? task["countryCode"] : "+1",
                        phoneNumber: task["phoneNumber"] && escapeRegex(task["phoneNumber"].toString()),
                        email: task["email"],
                        address: task["address"],
                        apartment: task["apartment"],
                        location: location,
                        capacity: task["capacity"],
                        price: Number(task["price"]) || 0
                    }
                    if (findMerchant.role === 4) {
                        data.userId = findMerchant.userId
                    }
                    else if (findMerchant.role === 2 && findMerchant.userId) {
                        data.userId = findMerchant.userId
                    }
                    else {
                        data.userId = findMerchant.id
                    }
                    if (data.scheduledTime) {
                        let time = data.scheduledTime.split(":");
                        data.scheduledTimeSeconds = Number((time[0] * 3600) + (time[1] * 60))
                    }
                    if (data.before) {
                        let time = data.before.split(":");
                        data.beforeSeconds = Number((time[0] * 3600) + (time[1] * 60))
                    }
                    if (data.after) {
                        let time = data.after.split(":");
                        data.afterSeconds = Number((time[0] * 3600) + (time[1] * 60))
                    }
                    if (!data.durationTime) {
                        data.durationTime = defaultTaskDuration;
                    }
                    if (!data.capacity) {
                        data.capacity = taskSettingData && (taskSettingData.defaultTaskCapacity || taskSettingData.defaultTaskCapacity === 0) ? taskSettingData.defaultTaskCapacity : defaultMissionCapacity;
                        data.capacityUnit = regionalSettingData && (regionalSettingData.volumeUnit) ? regionalSettingData.volumeUnit : "m3";
                    }
                    tasks.push(data)
                }
            }
        }
        if (!tasks.length) {
            setLoading(false)
            return errorAlert("There are some rows with invalid values please check all values!!!")
        }
        let response = await taskService.createMultipleTasks({ tasks });
        let successMsg = "Task imported successfully"
        if (response && response.data && response.data.success) {
            if (!invalidTasks.length) {
                successMsg = "Task imported successfully"
                getTaskList();
                getUnassignedTaskList();
                setLoading(false)
            }
            else {
                setLoading(false)
                errorAlert("Some tasks were not imported due to invalid values!!!")
                return
            }
        }
        else {
            setLoading(false)
            errorAlert("Some tasks were not imported due to invalid values!!!")
            return
        }
        setLoading(false)
        return successAlert(successMsg)
    }

    // const ImportPopUp = () => {
    //     let props = {
    //         // setImportData,
    //         taskService,
    //         setImportToggle,
    //         errorAlert,
    //         successAlert,
    //         merchantList,
    //         userList,
    //         getTaskList,
    //         defaultMissionCapacity,
    //         taskSettingData,
    //         regionalSettingData
    //     }
    //     importData();
    // }


    //save optimised mission 
    const saveOptimisedMision = async (data) => {

        if (data.missionDate) {
            let timezone = { ...timeZoneHeader() }
            data.missionDate = momentTz(data.missionDate).tz(timezone.timezone)._d
        }
        const response = await taskService.optimiseMission(data)

        if (response) {
            setOptimiseFormData(initialValues2)
            setShowOptimiseSection(false)
            successAlert("Mission optimised successfully")
            getMissionList()
            let mission = response && response.data && response.data.mission ? response.data.mission : {}
            if (mission) {
                setSelectedMissions([mission.id]);
                setcurrentMission(mission.id);
                getDirections(mission)
            }
        }
    }

    const getDirections = async (mission, missionClicked) => {
        let cordinates = [], allowedCoordinates = [], bookMarksToUpdate = [], newMarkers = [];
        let markers = [...bookMarks];
        markers = markers.map((i) => {
            delete i.markerIndex;
            delete i.showMarkerLabel;
            return i
        })
        setBookMarks(markers)
        let allowedTasks = mission.tasks.map(i => i.id)
        if (mission && mission.missionCategory === "unassigned") {
            if (mission.allTasks && mission.allTasks.length) {
                for (let index in mission.allTasks) {
                    let t = mission.allTasks[index];
                    if (t && t.location) {
                        let data = {
                            id: t.id, ...t.location, address: t.address,
                            agentId: mission.agentId, customerId: t.customerId, position: t.position, orderType: t.orderType, teamId: mission.teamId, taskStatus: t.taskStatus,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            capacity: t.capacity, capacityUnit: t.capacityUnit, eta: t.eta, etaSeconds: t.etaSeconds, missioned: t.missioned, updatedAt: t.updatedAt, customer: t.customer
                        }
                        data.distanceUnit = regionalSettingData && regionalSettingData.distanceUnit ? regionalSettingData.distanceUnit : "km"
                        let eta = await calculateETA(mission, t, index)
                        // data.calculatedeta = eta;
                        data.eta = `${eta.currentTime}${eta.string}`;

                        if (eta.currentTime && allowedTasks.includes(t.id)) {
                            let findTaskIndex = mission.tasks.findIndex(i => i.id === t.id);
                            if (findTaskIndex >= 0) {
                                mission.tasks[findTaskIndex].eta = eta.currentTime
                            }
                        }
                        if (t.taskStatus === "unassigned") {
                            data.markerImage = unassignedTaskMarker;
                            data.selectedMarkerImage = selectedUnassignedTaskMarker;
                        }
                        else if (t.taskStatus === "assigned") {
                            data.markerImage = assignedTaskMarker;
                            data.selectedMarkerImage = selectedAssignedMarker;
                        }
                        else if (t.taskStatus === "completed") {
                            data.markerImage = completedTaskMarker;
                            data.selectedMarkerImage = selectedCompletedMarker;
                        }
                        else if (t.taskStatus === "failed") {
                            data.markerImage = failedTaskMarker;
                            data.selectedMarkerImage = selectedFailedTaskMarker;
                        }
                        else if (t.taskStatus === "delayed") {
                            data.markerImage = delayTaskMarker;
                            data.selectedMarkerImage = selectedDelayMarker;
                        }
                        else if (t.taskStatus === "started") {
                            data.markerImage = startedTaskMarker;
                            data.selectedMarkerImage = selectedStartedMarker;
                        }
                        else if (t.taskStatus === "reached") {
                            data.markerImage = startedTaskMarker;
                            data.selectedMarkerImage = selectedStartedMarker;
                        }
                        if (data.eta) {
                            let newBookmarks = [...bookMarks]
                            let findBookmark = newBookmarks.findIndex(i => { return i.id === t.id });
                            if (findBookmark >= 0) {
                                newBookmarks[findBookmark].eta = data.eta
                                newBookmarks[findBookmark].showMarkerLabel = true;
                                newBookmarks[findBookmark].markerIndex = Number(index) + 1;
                                bookMarksToUpdate.push(newBookmarks[findBookmark])
                            }
                            else {
                                if (allowedTasks.includes(t.id)) {
                                    data.showMarkerLabel = true;
                                    data.markerIndex = Number(index) + 1;
                                    newMarkers.push(data)
                                }
                            }
                        }
                        cordinates.push(data);
                    }
                }
                if (bookMarksToUpdate.length) {
                    let newBookmarks = [...bookMarks];
                    for (let marker of bookMarksToUpdate) {
                        let findBookmark = newBookmarks.findIndex(i => { return i.id === marker.id });
                        if (findBookmark >= 0) {
                            newBookmarks[findBookmark] = marker
                        }
                    }
                    setBookMarks(newBookmarks)
                }
                if (newMarkers.length) {
                    setBookMarks((prev) => {
                        return [...prev, ...newMarkers]
                    })
                }
                if (mission.optimised) {
                    cordinates.unshift({
                        id: mission.id, ...mission.startFrom.location, address: mission.startFrom.address,
                        agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                        taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                        markerImage: startLocationMarker
                    })
                    cordinates.push({
                        id: mission.id + 1, ...mission.endAt.location, address: mission.endAt.address,
                        agentId: mission.agentId, customerId: "", position: "", orderType: "EL", teamId: mission.teamId,
                        taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                        markerImage: endLocationMarker
                    })
                    setOtherMarkers([
                        {
                            id: mission.id, ...mission.startFrom.location, address: mission.startFrom.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: startLocationMarker
                        },
                        {
                            id: mission.id + 1, ...mission.endAt.location, address: mission.endAt.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "EL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: endLocationMarker
                        }
                    ])
                }
                for (let coor of cordinates) {
                    let ifExists = allowedTasks.includes(coor.id)
                    if (coor.orderType === "SL" || coor.orderType === "EL" || ifExists) {
                        allowedCoordinates.push(coor)
                    }
                }
                if (allowedCoordinates.length && allowedCoordinates.length > 1) {
                    let directions = []
                    let parts = [], max = 25 - 1;
                    for (let i = 0; i < allowedCoordinates.length; i = i + max)
                        parts.push(allowedCoordinates.slice(i, i + max + 1));

                    for (let i = 0; i < parts.length; i++) {
                        let { result, props, status } = await MapDirectionsRenderer({ places: parts[i], travelMode: window.google.maps.TravelMode.DRIVING });
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            directions.push(result)
                        }
                        else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                            await delay(2000);
                            i--;
                        }
                        else {
                            errorAlert("No Route Found")
                        }
                    }
                    if (directions.length) {
                        setDummyDirections(directions)
                    }
                    else {
                        errorAlert("No Route Found")
                    }
                }
                else {
                    setDirections(null)
                }
            }
        }
        if (mission && mission.missionCategory === "assigned") {
            let agent = {}
            if (mission.allTasks && mission.allTasks.length) {
                for (let index in mission.allTasks) {
                    let t = mission.allTasks[index];
                    if (t && t.location) {
                        let data = {
                            id: t.id, ...t.location, address: t.address,
                            agentId: mission.agentId, customerId: t.customerId, position: t.position, orderType: t.orderType, teamId: mission.teamId, taskStatus: t.taskStatus,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            capacity: t.capacity, capacityUnit: t.capacityUnit, eta: t.eta, etaSeconds: t.etaSeconds, missioned: t.missioned,
                            updatedAt: t.updatedAt, customer: t.customer
                        }
                        data.distanceUnit = regionalSettingData && regionalSettingData.distanceUnit ? regionalSettingData.distanceUnit : "km"
                        let eta = await calculateETA(mission, t, index)
                        // data.calculatedeta = eta;
                        data.eta = `${eta.currentTime}${eta.string}`;

                        if (eta.currentTime && allowedTasks.includes(t.id)) {
                            let findTaskIndex = mission.tasks.findIndex(i => i.id === t.id);
                            if (findTaskIndex >= 0) {
                                mission.tasks[findTaskIndex].eta = eta.currentTime
                            }
                        }
                        if (eta.currentTime) {

                        }
                        if (t.taskStatus === "unassigned") {
                            data.markerImage = unassignedTaskMarker;
                            data.selectedMarkerImage = selectedUnassignedTaskMarker;
                        }
                        else if (t.taskStatus === "assigned") {
                            data.markerImage = assignedTaskMarker;
                            data.selectedMarkerImage = selectedAssignedMarker;
                        }
                        else if (t.taskStatus === "completed") {
                            data.markerImage = completedTaskMarker;
                            data.selectedMarkerImage = selectedCompletedMarker;
                        }
                        else if (t.taskStatus === "failed") {
                            data.markerImage = failedTaskMarker;
                            data.selectedMarkerImage = selectedFailedTaskMarker;
                        }
                        else if (t.taskStatus === "delayed") {
                            data.markerImage = delayTaskMarker;
                            data.selectedMarkerImage = selectedDelayMarker;
                        }
                        else if (t.taskStatus === "started") {
                            data.markerImage = startedTaskMarker;
                            data.selectedMarkerImage = selectedStartedMarker;
                        }
                        else if (t.taskStatus === "reached") {
                            data.markerImage = startedTaskMarker;
                            data.selectedMarkerImage = selectedStartedMarker;
                        }
                        if (data.eta) {
                            let newBookmarks = [...bookMarks]
                            let findBookmark = newBookmarks.findIndex(i => { return i.id === t.id });
                            if (findBookmark >= 0) {
                                newBookmarks[findBookmark].eta = data.eta
                                newBookmarks[findBookmark].showMarkerLabel = true;
                                newBookmarks[findBookmark].markerIndex = Number(index) + 1;
                                bookMarksToUpdate.push(newBookmarks[findBookmark])
                            }
                            else {
                                if (allowedTasks.includes(t.id)) {
                                    data.showMarkerLabel = true;
                                    data.markerIndex = Number(index) + 1;
                                    newMarkers.push(data)
                                }
                            }
                        }
                        cordinates.push(data);
                    }
                }

                let findAgentCoordinate = agentBookMarks.find(item => {
                    return item.id === mission.agentId.id
                })

                if (findAgentCoordinate) {
                    if (!findAgentCoordinate.taskId) {
                        if (mission.tasks.length) {
                            let task = mission.tasks.find(i => {
                                return i.taskStatus !== "completed" && i.taskStatus !== "assigned" && i.taskStatus !== "failed"
                            })
                            if (task) {
                                findAgentCoordinate.taskId = task.id;
                                findAgentCoordinate.missionId = mission.id;
                            }
                            // else {
                            //     findAgentCoordinate.taskId = mission.tasks[0].id;
                            //     findAgentCoordinate.missionId = mission.id;
                            // }
                        }
                    }
                    agent = findAgentCoordinate
                    myStateRef.current = [findAgentCoordinate]
                    setAgentBookMarks([findAgentCoordinate])
                }
                else {
                    agent = {
                        id: mission.agentId.id,
                        ...(mission.agentCoordinates && { ...mission.agentCoordinates }),
                        markerImage: agentMarker,
                        showMarkerLabel: false,
                        notClickable: false,
                        markerType: "AGENT",
                        firstName: mission.agentId.firstName,
                        lastName: mission.agentId.lastName,
                        teamId: mission.teamId,
                    }
                    if (mission.agentId.coordinates) {
                        agent = {
                            ...agent,
                            ...mission.agentId.coordinates
                        }
                    }
                    if (mission.tasks.length) {
                        let task = mission.tasks.find(i => {
                            return i.taskStatus !== "completed" && i.taskStatus !== "assigned" && i.taskStatus !== "failed"
                        })
                        // console.log("tasktasktask", task)
                        if (task) {
                            agent.taskId = task.id;
                            agent.missionId = mission.id;
                        }
                        // else {
                        //     agent.taskId = mission.tasks[0].id;
                        //     agent.missionId = mission.id;
                        // }
                    }
                    if (agent.taskId && (agent.agentCoordinates || (agent.lat && agent.lng))) {
                        myStateRef.current = [agent]
                        setAgentBookMarks([agent])
                    }
                }
                if (bookMarksToUpdate.length) {
                    let newBookmarks = [...bookMarks];
                    for (let marker of bookMarksToUpdate) {
                        let findBookmark = newBookmarks.findIndex(i => { return i.id === marker.id });
                        if (findBookmark >= 0) {
                            newBookmarks[findBookmark] = marker
                        }
                    }
                    setBookMarks(newBookmarks)
                }
                if (newMarkers.length) {
                    setBookMarks((prev) => {
                        return [...prev, ...newMarkers]
                    })
                }
                if (mission.optimised) {
                    cordinates.unshift({
                        id: mission.id, ...mission.startFrom.location, address: mission.startFrom.address,
                        agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                        taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                        markerImage: startLocationMarker
                    })
                    cordinates.push({
                        id: mission.id + 1, ...mission.endAt.location, address: mission.endAt.address,
                        agentId: mission.agentId, customerId: "", position: "", orderType: "EL", teamId: mission.teamId,
                        taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                        markerImage: endLocationMarker
                    })
                    setOtherMarkers([
                        {
                            id: mission.id, ...mission.startFrom.location, address: mission.startFrom.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: startLocationMarker
                        },
                        {
                            id: mission.id + 1, ...mission.endAt.location, address: mission.endAt.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "EL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: endLocationMarker
                        }
                    ])
                } else {
                    if (mission.startLocation) {
                        cordinates.unshift({
                            id: mission.id, ...mission.startLocation, address: mission.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: startLocationMarker
                        })
                        setOtherMarkers([
                            {
                                id: mission.id, ...mission.startLocation, address: mission.address,
                                agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                                taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                                markerImage: startLocationMarker
                            },
                        ])
                    }
                }
                for (let coor of cordinates) {
                    let ifExists = allowedTasks.includes(coor.id)
                    if (coor.orderType === "SL" || coor.orderType === "EL" || ifExists) {
                        allowedCoordinates.push(coor)
                    }
                }
                if (allowedCoordinates.length && allowedCoordinates.length > 1) {
                    let directions = []
                    let parts = [], max = 25 - 1;
                    for (let i = 0; i < allowedCoordinates.length; i = i + max)
                        parts.push(allowedCoordinates.slice(i, i + max + 1));

                    for (let i = 0; i < parts.length; i++) {
                        let { result, props, status } = await MapDirectionsRenderer({ places: parts[i], travelMode: window.google.maps.TravelMode.DRIVING });
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            directions.push(result)
                        }
                        else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                            await delay(2000);
                            i--;
                        }
                        else {
                            errorAlert("No Route Found")
                        }
                    }
                    if (agent && agent.taskId && (agent.agentCoordinates || (agent.lat && agent.lng))) {
                        let findTaskBookMark = cordinates.find(i => i.id === agent.taskId);
                        if (findTaskBookMark) {
                            directions.push(await agentDirections({ places: [agent, findTaskBookMark], travelMode: window.google.maps.TravelMode.DRIVING }))
                        }

                    }
                    if (directions.length) {
                        setDummyDirections(directions)
                    }
                    else {
                        errorAlert("No Route Found")
                    }
                }
                else {
                    setDirections(null)
                }
            }
        }
        if (mission && mission.missionCategory === "completed") {
            if (mission.tasks && mission.tasks.length) {
                for (let index in mission.tasks) {
                    let t = mission.tasks[index];
                    if (t && t.location) {
                        let data = {
                            id: t.id, ...t.location, address: t.address,
                            agentId: mission.agentId, customerId: t.customerId, position: t.position, orderType: t.orderType, teamId: mission.teamId, taskStatus: t.taskStatus,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            capacity: t.capacity, capacityUnit: t.capacityUnit, eta: t.eta, etaSeconds: t.etaSeconds, missioned: t.missioned,
                            orderCompletedTimeStamp: t.orderCompletedTimeStamp, updatedAt: t.updatedAt, customer: t.customer
                        }
                        data.distanceUnit = regionalSettingData && regionalSettingData.distanceUnit ? regionalSettingData.distanceUnit : "km"
                        if (t.taskStatus === "unassigned") {
                            data.markerImage = unassignedTaskMarker;
                            data.selectedMarkerImage = selectedUnassignedTaskMarker;
                        }
                        else if (t.taskStatus === "assigned") {
                            data.markerImage = assignedTaskMarker;
                            data.selectedMarkerImage = selectedAssignedMarker;
                        }
                        else if (t.taskStatus === "completed") {
                            data.markerImage = completedTaskMarker;
                            data.selectedMarkerImage = selectedCompletedMarker;
                        }
                        else if (t.taskStatus === "failed") {
                            data.markerImage = failedTaskMarker;
                            data.selectedMarkerImage = selectedFailedTaskMarker;
                        }
                        else if (t.taskStatus === "delayed") {
                            data.markerImage = delayTaskMarker;
                            data.selectedMarkerImage = selectedDelayMarker;
                        }
                        else if (t.taskStatus === "started") {
                            data.markerImage = startedTaskMarker;
                            data.selectedMarkerImage = selectedStartedMarker;
                        }
                        else if (t.taskStatus === "reached") {
                            data.markerImage = startedTaskMarker;
                            data.selectedMarkerImage = selectedStartedMarker;
                        }
                        let newBookmarks = [...bookMarks]
                        let findBookmark = newBookmarks.findIndex(i => { return i.id === t.id });
                        if (findBookmark >= 0) {
                            // newBookmarks[findBookmark].eta = data.eta
                            newBookmarks[findBookmark].showMarkerLabel = true;
                            newBookmarks[findBookmark].markerIndex = Number(index) + 1;
                            bookMarksToUpdate.push(newBookmarks[findBookmark])
                        }
                        else {
                            data.showMarkerLabel = true;
                            data.markerIndex = Number(index) + 1;
                            newMarkers.push(data)
                        }
                        cordinates.push(data);
                    }
                }
                if (bookMarksToUpdate.length) {
                    let newBookmarks = [...bookMarks];
                    for (let marker of bookMarksToUpdate) {
                        let findBookmark = newBookmarks.findIndex(i => { return i.id === marker.id });
                        if (findBookmark >= 0) {
                            newBookmarks[findBookmark] = marker
                        }
                    }
                    setBookMarks(newBookmarks)
                }
                if (newMarkers.length) {
                    setBookMarks((prev) => {
                        return [...prev, ...newMarkers]
                    })
                }
                if (mission.optimised) {
                    cordinates.unshift({
                        id: mission.id, ...mission.startFrom.location, address: mission.startFrom.address,
                        agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                        taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                        markerImage: startLocationMarker
                    })
                    cordinates.push({
                        id: mission.id + 1, ...mission.endAt.location, address: mission.endAt.address,
                        agentId: mission.agentId, customerId: "", position: "", orderType: "EL", teamId: mission.teamId,
                        taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                        markerImage: endLocationMarker
                    })
                    setOtherMarkers([
                        {
                            id: mission.id, ...mission.startFrom.location, address: mission.startFrom.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: startLocationMarker
                        },
                        {
                            id: mission.id + 1, ...mission.endAt.location, address: mission.endAt.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "EL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: endLocationMarker
                        }
                    ])
                } else {
                    if (mission.startLocation) {
                        cordinates.unshift({
                            id: mission.id, ...mission.startLocation, address: mission.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: startLocationMarker
                        })
                        setOtherMarkers([
                            {
                                id: mission.id, ...mission.startLocation, address: mission.address,
                                agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                                taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                                markerImage: startLocationMarker
                            }
                        ])
                    }
                }

                for (let coor of cordinates) {
                    let ifExists = allowedTasks.includes(coor.id)
                    if (coor.orderType === "SL" || coor.orderType === "EL" || ifExists) {
                        allowedCoordinates.push(coor)
                    }
                }
                if (allowedCoordinates.length && allowedCoordinates.length > 1) {
                    let directions = []
                    let parts = [], max = 25 - 1;
                    for (let i = 0; i < allowedCoordinates.length; i = i + max)
                        parts.push(allowedCoordinates.slice(i, i + max + 1));

                    for (let i = 0; i < parts.length; i++) {
                        let { result, props, status } = await MapDirectionsRenderer({ places: parts[i], travelMode: window.google.maps.TravelMode.DRIVING });
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            directions.push(result)
                        }
                        else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                            await delay(2000);
                            i--;
                        }
                        else {
                            errorAlert("No Route Found")
                        }
                    }
                    if (directions.length) {
                        setDummyDirections(directions)
                    }
                    else {
                        errorAlert("No Route Found")
                    }
                }
                else {
                    setDirections(null)
                }
            }
        }
        if (missionClicked) {
            let missions = [...missionList];
            let missionIndex = missions.findIndex(i => i.id === mission.id);
            if (missionIndex >= 0) {
                missions[missionIndex] = mission
            }
            setMissionList(missions)
        }
    };

    const agentDirections = async ({ places, travelMode }) => {
        let { result, props, status } = await MapDirectionsRenderer({ places, travelMode });
        if (status === window.google.maps.DirectionsStatus.OK) {
            return result
        }
        else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
            await delay(3000);
            return await agentDirections({ places, travelMode })
        }
        else {
            return errorAlert("No Route Found")
        }
    }

    const calculateETA = async (mission, task, index) => {
        let currentTime = moment().format("hh:mm a");
        let string = ""
        if (mission.missionCategory === "unassigned") {
            let result = assignedUnassignedETA(currentTime, mission, task, index, string)
            currentTime = result.currentTime;
            string = result.string;
        }
        else {
            if (mission.missionStatus === "started" || mission.missionStatus === "failed") {
                if (task.taskStatus === "started") {
                    let time = moment();
                    let result
                    if (mission.agentCoordinates && task.location) {
                        result = await OptimizeMission.directionsServiceHere({ locations: [] }, false, [],
                            { location: mission.agentCoordinates },
                            { location: task.location })
                    }
                    if (result[0]) {
                        console.log("result", result)
                        let myroute = result[0]
                        let eta = Number(myroute.time);
                        if (eta) {
                            time.add(eta, "s")
                        }
                    }
                    currentTime = time.format("hh:mm a");
                    task.calculatedeta = time
                    if (task.etaSeconds) string = `(${Number(task.etaSeconds) / 60} min)`
                }
                else {
                    if (task.taskStatus !== "completed" && task.taskStatus !== "failed") {
                        if (Number(index) === 0) {
                            let time = moment().add(task.durationTime, "m")
                            currentTime = time.format("hh:mm a");
                            task.calculatedeta = time
                            string = `(${Number(task.durationTime)} min)`
                        }
                        else {
                            let addTime = 0;
                            addTime += Number(mission.allTasks[Number(index) - 1].durationTime)
                            addTime += Number(mission.allTasks[Number(index) - 1].etaSeconds || 0) / 60;
                            addTime += Number(task.timeFromPrevTask)
                            let time = moment(mission.allTasks[Number(index) - 1].calculatedeta).add(addTime, "m")
                            currentTime = time.format("hh:mm a");
                            task.calculatedeta = time
                            let a = moment();
                            let diff = time.diff(a, 'm')
                            let hours = Math.floor(diff / 60);
                            let minutes = diff % 60;
                            if (hours > 0) {
                                string += ` ${hours} hour`
                            }
                            if (minutes > 0) {
                                string += ` ${minutes} mins`
                            }
                            string = `(${string})`
                        }
                    }
                }
            }
            else {
                let result = assignedUnassignedETA(currentTime, mission, task, index, string)
                currentTime = result.currentTime;
                string = result.string;
            }
        }

        return { currentTime, string };
    }


    const assignedUnassignedETA = (currentTime, mission, task, index, string) => {
        // if (mission.missionCategory === "unassigned") {
        //     currentTime = "__"
        // }
        // else {
        if (Number(index) === 0) {
            if (mission.startFrom && mission.endAt) {
                let defaultStartTime = taskSettingData && taskSettingData.defaultAfterTimeSeconds ? taskSettingData.defaultAfterTimeSeconds : "8:00"
                if (task.after) {
                    defaultStartTime = task.afterSeconds;
                }
                let newDate = moment().format("YYYY-MM-DD");
                let time = moment(newDate).add(defaultStartTime, "s").add(Number(task.timeFromPrevTask), "m");
                currentTime = time.format("hh:mm a");
                task.calculatedeta = time;
            }
            else {
                let defaultStartTime = taskSettingData && taskSettingData.defaultAfterTimeSeconds ? taskSettingData.defaultAfterTimeSeconds : "8:00"
                if (task.after) {
                    defaultStartTime = task.afterSeconds;
                }
                let newDate = moment().format("YYYY-MM-DD");
                let time = moment(newDate).add(defaultStartTime, "s")
                currentTime = time.format("hh:mm a");
                task.calculatedeta = time;
            }
        }
        else {
            let addTime = 0;
            addTime += Number(mission.allTasks[Number(index) - 1].durationTime)
            addTime += Number(mission.allTasks[Number(index) - 1].etaSeconds || 0) / 60;
            addTime += Number(task.timeFromPrevTask)
            let time = moment(mission.allTasks[Number(index) - 1].calculatedeta)
            time = time.add(addTime, "m")
            currentTime = time.format("hh:mm a");

            let a = moment(mission.allTasks[Number(index) - 1].calculatedeta);
            let diff = time.diff(a, 'm')
            task.calculatedeta = time
            let hours = Math.floor(diff / 60);
            let minutes = diff % 60;
            if (hours > 0) {
                string += ` ${hours} hour`
            }
            if (minutes > 0) {
                string += ` ${minutes} mins`
            }
            string = `(${string})`
        }
        // }
        return { currentTime, string }
    }


    const handleOptimiseSubmit = async (formValue, optimiseFormValue) => {
        try {
            setLoading(true)
            let formData;
            if (optimiseFormValue) formData = optimiseFormValue;
            else formData = optimiseFormData
            if (formValue && formValue.multiMissionOption === "cancel") {
                setLoading(false)
                return;
            }
            else {
                if ((formValue && formValue.multiMissionOption === "proceedMultiMission") || formData.multiMission) {
                    if ((features && features.multiMission) || superAdminRole) {
                        let { allMissionTasks, totalDist, totalTime, tasks } = await OptimizeMission.handleMultiMission(formData, defaultDurationTime, [selectedMission], hubList, moment)
                        let missionData = [selectedMission].find(i => i.id === formData.missionId)
                        let findEmptyArray = allMissionTasks.some(i => Number(i.length) === 0);
                        console.log("allMissionTasks, totalDist, totalTime, tasks", allMissionTasks)
                        if (findEmptyArray) {
                            return Swal.fire({
                                title: 'Error!',
                                text: 'Some tasks do not fit the criteria, Please try with new criteria.',
                                icon: 'error',
                                color: "#ffffff",
                                background: '#151719',
                                confirmButtonColor: "#1A1D1F",
                                cancelButtonColor: "#1A1D1F",
                                confirmButtonText: 'Okay'
                            })
                        }
                        for (let task in allMissionTasks) {
                            if (Number(task) === 0) {
                                let maxMissionCapacity = 0;
                                maxMissionCapacity = allMissionTasks[task].reduce((partialSum, a) => partialSum + a.capacity, 0);
                                let data = {
                                    ...formData,
                                    taskIds: allMissionTasks[task].map(i => i.id),
                                    missionDetails: {
                                        totalDist, totalTime
                                    },
                                    tasks: tasks,
                                    missionTaskCapacity: maxMissionCapacity
                                }
                                if (data.missionDate) {
                                    let timezone = { ...timeZoneHeader() }
                                    data.missionDate = momentTz(data.missionDate).tz(timezone.timezone)._d
                                }
                                const response = await taskService.optimiseMission(data)
                                if (response && response.data && response.data.mission) await getDirections(response.data.mission)
                                continue;
                            }
                            else {
                                const waypointss = allMissionTasks[task].map(p => {
                                    return { location: { lat: p.location.lat, lng: p.location.lng }, id: p.id }
                                })
                                const origin = waypointss[0];
                                const destination = waypointss[waypointss.length - 1];
                                let waypoints = [];
                                for (let i = 1; i < waypointss.length; i++) {
                                    waypoints.push(waypointss[i])
                                }
                                let directionService
                                if (origin && destination) {
                                    directionService = await OptimizeMission.directionsServiceHere({ locations: waypoints }, false, [], { location: origin.location }, { location: destination.location })
                                }
                                let missionDetails = {
                                    totalDist: 0,
                                    totalTime: 0
                                }, tasksDistTime = [], maxMissionDuration = 0, maxMissionCapacity = 0;

                                if (directionService) {
                                    let myroute = directionService[0];

                                    missionDetails.totalDist += Number(myroute.distance);
                                    missionDetails.totalTime += Number(myroute.time);
                                    for (let i = 0; i < myroute.interconnections.length - 1; i++) {
                                        tasksDistTime.push({
                                            id: allMissionTasks[task][i + 1].id,
                                            distanceFromPrevTask: myroute.interconnections[i].distance / 1000,
                                            timeFromPrevTask: Number(myroute.interconnections[i].time) / 60
                                        })
                                    }


                                    missionDetails.totalTime += (maxMissionDuration * 60);
                                    if (missionDetails.totalTime) {
                                        missionDetails.totalTime = missionDetails.totalTime / 3600
                                        missionDetails.totalTime = Number(missionDetails.totalTime.toFixed(1))
                                    }
                                    if (missionDetails.totalDist) {
                                        missionDetails.totalDist = missionDetails.totalDist / 1000
                                        missionDetails.totalDist = Number(missionDetails.totalDist.toFixed(1))
                                    }
                                }

                                let data = {
                                    taskIds: allMissionTasks[task].map(i => i.id),
                                    date: missionData.missionDate,
                                    missionDetails,
                                    tasks: tasksDistTime
                                };
                                maxMissionCapacity = allMissionTasks[task].reduce((partialSum, a) => partialSum + a.capacity, 0);
                                data.missionTaskCapacity = maxMissionCapacity;
                                let createMission = await taskService.createMession(data)
                            }
                        }
                        setShowOptimiseSection(false);
                        getMissionList();
                        setLoading(false)
                        return Swal.fire(
                            "Mission optimized!",
                            `${allMissionTasks.length} missions were created for optimization.`,
                            "success"
                        );
                    }
                    else {
                        history.push({
                            pathname: "/Business/multiMission"
                        })
                    }
                }
                else {
                    let returnedValues;
                    if (formData.optimiseMode === "distance") {
                        returnedValues = await OptimizeMission.calculateTimeDistace(formData, defaultDurationTime, [selectedMission], hubList)
                        console.log("returnedValues", returnedValues)
                    }
                    else {
                        returnedValues = await OptimizeMission.optimizeDataTimeMode(formData, defaultDurationTime, [selectedMission], hubList, moment)
                    }
                    if (!returnedValues) {
                        return infoAlert("No routes found")
                    }
                    setLoading(false)
                    return saveOptimisedMision(returnedValues)
                }
            }
        } catch (err) {
            console.log("error:::", err);
            setLoading(false)
            return errorAlert(err ? err : "Something went wrong");
        }
    };

    const dayOfWeekOfMonth = () => {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        const today = moment();
        const dayOfWeek = today.day();
        const dateOfMonth = today.date(); // get date of month (1-31)
        if (dateOfMonth < 8) { // check if the first Friday of the month has not passed yet
            return { number: 1, string: "first", dayString: days[dayOfWeek] };
        } else if (dateOfMonth < 15) { // check if the second Friday of the month has not passed yet
            return { number: 2, string: "second", dayString: days[dayOfWeek] };
        } else if (dateOfMonth < 22) { // check if the third Friday of the month has not passed yet
            return { number: 3, string: "third", dayString: days[dayOfWeek] };
        } else if (dateOfMonth < 29) { // check if the fourth Friday of the month has not passed yet
            return { number: 4, string: "fourth", dayString: days[dayOfWeek] };
        } else {
            return { number: 5, string: "fifth", dayString: days[dayOfWeek] };
        }
    }

    const updateDuplicateMissionPopup = () => {
        return (<ModalPopUp
            top="15%"
            show={updateMissionToggle}
            updateState={setUpdateMissionToggle}
        >
            <div className="update-mission-pop-up">
                <h6>{missionData && missionData.type === "DUPLICATE" ? 'Duplicate Mission' : 'Change Mission Date'}</h6>
                <span className="mission-span">{missionData && missionData.name}</span>
                <div className="mission-calendar">
                    <Calendar
                        onChange={(e) => {
                            setMissionData((prev) => {
                                return {
                                    ...prev,
                                    missionDate: e
                                }
                            });
                        }}
                        value={missionData && new Date(missionData.missionDate)}></Calendar>
                </div>
                {missionData && missionData.type === "DUPLICATE" && <div><div className="dropdown">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        defaultValue={missionData.selectedRepeatValue ? missionData.selectedRepeatValue : { id: "notRepeat", name: "Does not repeat" }}
                        value={missionData.selectedRepeatValue ? missionData.selectedRepeatValue : { id: "notRepeat", name: "Does not repeat" }}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        name="color"
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.id}
                        onChange={(newValue, actionMeta) => {
                            if (newValue.id === "repeat") {
                                setCustomOccurencesToggle(true);
                                setMissionData((prev) => {
                                    return {
                                        ...prev,
                                        repeat: true,
                                        selectedRepeatValue: newValue
                                    }
                                })
                            }
                            else {
                                setMissionData((prev) => {
                                    return {
                                        ...prev,
                                        repeat: false,
                                        selectedRepeatValue: newValue
                                    }
                                })
                            }
                        }}
                        options={[{ id: "notRepeat", name: "Does not repeat" }, { id: "repeat", name: "Custom" }]}
                    />
                </div></div>}
                {missionData && missionData.repeat && RecurrencePopup()}
                <div className="mission-buttons">
                    <Button className="cncle-btn" onClick={() => setUpdateMissionToggle(false)}>
                        Cancel
                    </Button>
                    <Button type="submit" className="ctaskbtn" onClick={() => {
                        updateMission({ ...missionData }, missionData.type)
                    }}>
                        Lets go
                    </Button>
                </div>
            </div>
        </ModalPopUp>)
    }

    const RecurrencePopup = () => {
        return (<ModalPopUp
            show={customOccurencesToggle}
            updateState={setCustomOccurencesToggle}
            top="-5%"
            hideCloseButton={true}
            zIndex="1350"
            width="500px"
        >
            <div className="recurrence-container">
                <span>Custom recurrence</span>
                <div className="repeat-container">
                    <span className="repeat-span">Repeat every</span>
                    <input className="input-custom" type="number" min="1" defaultValue={1} onChange={(e) => {
                        setMissionData((prev) => { return { ...prev, repeatEvery: e.target.value } })
                    }} />
                    <div className="repeat-dropdown">
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            defaultValue={{ id: "DAY", name: "Day" }}
                            value={missionData && missionData.repeatType ? missionData.repeatType : { id: "DAY", name: "Day" }}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={false}
                            name="color"
                            onChange={(newValue, actionMeta) => {
                                setMissionData((prev) => { return { ...prev, repeatType: newValue } })
                            }}
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => option.id}
                            options={[{ id: "DAY", name: "Day" }, { id: "WEEK", name: "Week" }, { id: "MONTH", name: "Month" }]}
                        />
                    </div>
                </div>
                {missionData && missionData.repeatType && (missionData.repeatType.id === "WEEK" || missionData.repeatType.id === "MONTH") &&
                    <div className="repeat-on">
                        {missionData.repeatType.id === "WEEK" ? (<>
                            <span>Repeats On</span>
                            <div className="week-repeat">
                                {[{ day: 0, dayText: "S" }, { day: 1, dayText: "M" }, { day: 2, dayText: "T" },
                                { day: 3, dayText: "W" }, { day: 4, dayText: "T" }, { day: 5, dayText: "F" },
                                { day: 6, dayText: "S" }].map((i) => {
                                    return (<div className={`weeks${missionData.repeatOnDay && missionData.repeatOnDay.includes(i.day) ? " selected" : ""}`} onClick={() => {
                                        let prevSelectedDays = missionData.repeatOnDay;
                                        if (prevSelectedDays && prevSelectedDays.includes(i.day)) {
                                            let findIndex = prevSelectedDays.findIndex((d) => d === i.day);
                                            if (findIndex >= 0) {
                                                prevSelectedDays.splice(findIndex, 1)
                                            }
                                        }
                                        else {
                                            prevSelectedDays.push(i.day)
                                        }
                                        setMissionData((prev) => { return { ...prev, repeatOnDay: prevSelectedDays } })
                                    }}>{i.dayText}</div>)
                                })}
                            </div></>) :
                            <div className="month-repeat">
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    defaultValue={{ id: "DAY", value: momentTz().tz(timezone.timezone).date(), name: `Monthly on day ${momentTz().tz(timezone.timezone).date()}` }}
                                    value={missionData && missionData.repeatOnDays ? missionData.repeatOnDays : { id: "DAY", value: momentTz().tz(timezone.timezone).date(), name: `Monthly on day ${momentTz().tz(timezone.timezone).date()}` }}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={false}
                                    name="color"
                                    onChange={(newValue, actionMeta) => {
                                        setMissionData((prev) => { return { ...prev, repeatOnDays: newValue } })
                                    }}
                                    getOptionLabel={(option) => `${option.name}`}
                                    getOptionValue={(option) => option.id}
                                    options={[{ id: "DAY", value: momentTz().tz(timezone.timezone).date(), name: `Monthly on day ${momentTz().tz(timezone.timezone).date()}` }, { id: "WEEKDAY_MONTH", value: dayOfWeekOfMonth().number, name: `Monthly on ${dayOfWeekOfMonth().string} ${dayOfWeekOfMonth().dayString}` }]}
                                />
                            </div>}
                    </div>}
                <div className="end-text"><span>Ends</span></div>
                <div className="ends-on-container">
                    <div className="menu-list">
                        <FormB.Check
                            defaultChecked
                            type="radio"
                            id="Never"
                            name="view"
                            className="checkbox-custom"
                            onChange={(e) => { setMissionData((prev) => { return { ...prev, endsOn: "NEVER" } }) }}
                        />
                        <label htmlFor="Never" className="checkbox-custom-label">
                            Never
                        </label>
                    </div>
                    <div className="menu-list">
                        <FormB.Check
                            type="radio"
                            id="One"
                            name="view"
                            className="checkbox-custom"
                            onChange={(e) => { setMissionData((prev) => { return { ...prev, endsOn: "ON" } }) }}
                        />
                        <label htmlFor="On" className="checkbox-custom-label">
                            On
                        </label>
                        <DatePicker
                            dateFormat="MM/dd/yyyy"
                            name={"orderDate"}
                            value={missionData && missionData.endsOnDate ? missionData.endsOnDate : new Date()}
                            disabled={!missionData || (missionData && missionData.endsOn !== "ON")}
                            onChange={(e) => {
                                setMissionData((prev) => { return { ...prev, endsOnDate: e } })
                            }}
                            selectsEnd
                        />
                    </div>
                    <div className="menu-list">
                        <FormB.Check
                            type="radio"
                            id="After"
                            name="view"
                            className="checkbox-custom"
                            onChange={(e) => { setMissionData((prev) => { return { ...prev, endsOn: "AFTER" } }) }}
                        />
                        <label htmlFor="After" className="checkbox-custom-label">
                            After
                        </label>
                        <input className="input-custom" type="number" min="1" defaultValue={1}
                            onChange={(e) => { setMissionData((prev) => { return { ...prev, endAfterOccurence: e.target.value } }) }}
                        />
                        <div className="occuerence-text"><span>occurences</span></div>
                    </div>
                </div>
                <div className="recurrence-buttons">
                    <Button className="cncle-btn" onClick={() => setCustomOccurencesToggle(false)}>
                        Cancel
                    </Button>
                    <Button type="submit" className="ctaskbtn" onClick={() => {
                        setCustomOccurencesToggle(false)
                    }}>
                        Done
                    </Button>
                </div>
            </div>
        </ModalPopUp >)
    }

    const MultiMissionPopup = () => {
        return (
            <ModalPopUp
                show={multiMissionToggle}
                updateState={setMultiMissionToggle}
            >
                <div className="multi-mission-pop-up">
                    <span>The optimization can't respect your max mission duration, max tasks per mission and deivery time range</span>
                </div>
                <div className="multi-mission-form">
                    <Formik
                        initialValues={{
                            multiMissionOption: 'proceedMultiMission',
                        }}
                        onSubmit={async (values) => {
                            setMultiMissionOption(values.multiMissionOption)
                            setMultiMissionToggle(false)
                            handleOptimiseSubmit(values)
                        }}
                    >
                        {({ values }) => (
                            <Form>
                                <div role="group" aria-labelledby="my-radio-group" style={{ color: "#ffffff" }}>
                                    <label style={{ marginBottom: "8px" }}>
                                        <Field type="radio" name="multiMissionOption" value="proceedMultiMission" />
                                        Proceed with multi-mission.
                                    </label>
                                    <label style={{ marginBottom: "8px" }}>
                                        <Field type="radio" name="multiMissionOption" value="proceedOptimization" />
                                        Proceed with optimization anyway and ignore the warning.
                                    </label>
                                    <label style={{ marginBottom: "8px" }}>
                                        <Field type="radio" name="multiMissionOption" value="cancel" />
                                        Cancel the optimization, the user will change manually the criteria.
                                    </label>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button type="submit" >Let's go</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

            </ModalPopUp>
        )
    }

    const editDocs = (docs) => {
        setSelectedDocs([...docs])
    }
    const getTaskCount = async () => {
        const response = await taskService.getTaskCount({});
        if (response.status == 200) {
            setTaskCount(response.data.result || 0);
        }
    }

    const taskSingleClickHandler = (e, task, i) => {
        //unselecting mission
        setSelectedMissions([]);
        setToggleState({})
        setDirections(null)
        let selectedCenter = {
            ...task.location, address: task.address, from: "task", id: task.id
        };
        if (task.lat) {
            selectedCenter.lat = task.lat
        }
        if (task.lng) {
            selectedCenter.lng = task.lng
        }
        if (e && (!e.ctrlKey && !e.metaKey)) { setSelectedCenter(selectedCenter) };
        let defaultcenter = {
            id: task.id,
            ...task.location,
            address: task.address,
        }
        if (task.lat) {
            defaultcenter.lat = task.lat
        }
        if (task.lng) {
            defaultcenter.lng = task.lng
        }
        if (e && (!e.ctrlKey && !e.metaKey)) {
            setDefaultCenter(defaultcenter)
            // map.panTo({lat: defaultcenter.lat, lng: defaultCenter.lng})

        }
        //  console.log("task:::",task)
        if (e && (e.ctrlKey || e.metaKey)) {
            if (!selectedTasks.includes(task.id)) {
                setSelectedTasks((prev) => {
                    //  console.log("selected items:::",[...selectedTasks,task.id])
                    return [...selectedTasks, task.id];
                });
                if (selectedTasks.length) {
                    taskSelectHandler(task);
                } else {
                    taskSelectHandler(task, true);
                }
            } else {
                //unselect the current task
                const tasks = unassignedTaskList.map((t) => {
                    if (task.id === t.id) return { ...t, active: false };
                    return t;
                });
                setUnassignedTaskList(tasks);
                //remove for selected array list
                const filteredArray = selectedTasks.filter((st) => st !== task.id);
                setSelectedTasks(filteredArray);
            }
        }
        else if (e && e.shiftKey) {
            let newTaskList = [...unassignedTaskList]
            if (!selectedTasks.includes(task.id)) {

                let select = []
                if (selectedTasks.length) {
                    let newTasks = selectMultiTaskShiftKey(i, newTaskList, select)
                    removeTaskAfterIndex(i, newTaskList.length, newTasks)
                }
            }
            else {
                removeTaskAfterIndex(i, newTaskList.length, newTaskList)
            }
        }
        else if (e.detail === 2) {
            setTaskDetail(task);
            let selectedCenter = {
                ...task.location, address: task.address, from: "task", id: task.id
            };
            if (task.lat) {
                selectedCenter.lat = task.lat
            }
            if (task.lng) {
                selectedCenter.lng = task.lng
            }
            setSelectedCenter(selectedCenter)
        }
        else {
            if (e.button === 2) {
                if (!selectedTasks.length || selectedTasks.length === 1) {
                    setSelectedTasks([task.id]);
                    taskSelectHandler(task, true);
                }
            }
            else {
                setSelectedTasks([task.id]);
                taskSelectHandler(task, true);
            }
        }

        if (i || i === 0) { setSelectedTaskIndex(i); console.log("iiiii", i) }
        let newBookmarks = JSON.parse(JSON.stringify(bookMarks));
        let findBookmarkIndex = newBookmarks.findIndex(item => item.id === task.id);
        if (findBookmarkIndex > -1) {
            newBookmarks[findBookmarkIndex].selected = true;
        }
        if (findBookmarkIndex > -1 && e && (e.ctrlKey || e.metaKey)) {
            if (bookMarks[findBookmarkIndex].selected === true) {
                newBookmarks[findBookmarkIndex].selected = false;
            }
        }
        else {
            newBookmarks = newBookmarks.map(i => {
                if (i.id !== task.id) {
                    i.selected = false;
                }
                return i
            })
        }
        setBookMarks(newBookmarks)
    };

    const removeTaskAfterIndex = (i, taskListLength, newTaskList) => {
        let unselect = [];
        // console.log("i, taskListLength, newTaskList", i, taskListLength, newTaskList)
        for (let index = i + 1; index <= taskListLength - 1; index++) {
            newTaskList[index].active = false;
            unselect.push(newTaskList[index])
            setSelectedTasks((prev) => {
                let indexOfTask = prev.indexOf(newTaskList[index].id)
                if (indexOfTask > -1) {
                    prev.splice(indexOfTask, 1)
                }
                return [...prev];
            });
        }
        newTaskList.splice(i + 1, (taskListLength - (i + 1)), ...unselect)
        setTaskList(newTaskList)
        return newTaskList
    }

    const selectMultiTaskShiftKey = (i, newTaskList, select) => {
        // console.log("i, newTaskList, select", i, newTaskList, select, lastSelectedTaskIndex)
        let findTasksBWIndexes = newTaskList.slice(lastSelectedTaskIndex, i + 1)
        console.log("findTasksBWIndexes", findTasksBWIndexes)
        for (let task of findTasksBWIndexes) {
            if (!selectedTasks.includes(task.id)) {
                setSelectedTasks((prev) => {
                    return [...prev, task.id];
                });
            }
            let findFromTasks = newTaskList.find(item => {
                return item.id === task.id
            })
            findFromTasks.active = true;
            select.push(findFromTasks)
        }
        newTaskList.splice(lastSelectedTaskIndex, (i - lastSelectedTaskIndex) + 1, ...select)
        setTaskList(newTaskList)
        return newTaskList
    }

    const taskSelectHandler = (task, removeSelected = false) => {
        if (removeSelected) {
            const tasks = unassignedTaskList.map((t) => {
                if (task.id === t.id) return { ...t, active: true };
                return { ...t, active: false };
            });
            setUnassignedTaskList(tasks);
        } else {
            const tasks = unassignedTaskList.map((t) => {
                if (task.id == t.id) return { ...t, active: true };
                return t;
            });
            setUnassignedTaskList(tasks);
        }
    };

    const missionClickhandler = async (mission, preventDirection) => {
        setSelectedMissions([mission.id]);
        if (mission.tasks[0]) setDefaultCenter({ ...mission.tasks[0].location, address: mission.tasks[0].address, id: mission.tasks[0].id })
        if (!preventDirection) getDirections(mission, true);
        setcurrentMission(mission.id);
        setSelectedCenter(null)
    };


    const missionTaskSingleClickHandler = (e, task, i, markerClick = false) => {
        let newTaskList = unassignedTaskList.map(item => {
            item.active = false;
            return item
        })
        setUnassignedTaskList(newTaskList)
        setSelectedTaskPosition(task.position)
        if (e.ctrlKey || e.metaKey) {
            if (!selectedTasks.includes(task.id)) {
                setSelectedTasks((prev) => {
                    //  console.log("selected items:::",[...selectedTasks,task.id])
                    return [...selectedTasks, task.id];
                });
                if (selectedTasks.length) {
                    missionTaskSelectHandler(task);
                } else {
                    missionTaskSelectHandler(task, true);
                }
            } else {
                //unselect the current task
                const missions = missionList.map((m) => {
                    if (m.tasks && m.tasks.length) {
                        m.tasks = m.tasks.map((t) => {
                            if (task.id == t.id) return { ...t, active: false, selected: false };
                            return t;
                        });
                    }
                    return m;
                });
                setMissionList(missions);
                //remove for selected array list
                const filteredArray = selectedTasks.filter((st) => st !== task.id);
                setSelectedTasks(filteredArray);
            }
        }
        else if (e.shiftKey) {

            let currentMissionData = missionList.find(item => {
                return item.id === currentMission
            })
            let indexOfMission = missionList.map(i => i.id).indexOf(props.currentMission)
            let missionData = setMissionTaskStatus(task, currentMissionData)
            currentMissionData = missionData;
            if (indexOfMission > -1) {
                missionList.splice(indexOfMission, 1, currentMissionData)
            }
        }
        else if (e.detail === 2) {
            setTaskDetail(task);
            let selectedCenter = {
                ...task.location, address: task.address, from: "task", id: task.id
            };
            if (task.lat) {
                selectedCenter.lat = task.lat
            }
            if (task.lng) {
                selectedCenter.lng = task.lng
            }
            setSelectedCenter(selectedCenter)
        }
        else {
            if (e.button === 2) {
                if (selectedTasks.length <= 1) {
                    if (!selectedTasks.includes(task.id)) {
                        let selectedCenter = {
                            ...task.location, address: task.address, from: "task", id: task.id
                        };

                        if (task.lat) {
                            selectedCenter.lat = task.lat
                        }
                        if (task.lng) {
                            selectedCenter.lng = task.lng
                        }
                        setSelectedTasks([task.id]);
                        missionTaskSelectHandler(task, true);
                        if (e && (!e.ctrlKey && !e.metaKey)) { setSelectedCenter(selectedCenter) }
                    }
                    else {
                        const index = selectedTasks.indexOf(task.id);
                        if (index > -1) {
                            selectedTasks.splice(index, 1); // 2nd parameter means remove one item only
                        }
                        setSelectedTasks([task.id]);
                        missionTaskSelectHandler(task, true);
                        if (!markerClick) setSelectedCenter(null)
                    }
                }
            }
            else {
                if (!selectedTasks.includes(task.id)) {
                    let selectedCenter = {
                        ...task.location, address: task.address, from: "task", id: task.id
                    };

                    if (task.lat) {
                        selectedCenter.lat = task.lat
                    }
                    if (task.lng) {
                        selectedCenter.lng = task.lng
                    }
                    setSelectedTasks([task.id]);
                    missionTaskSelectHandler(task, true);
                    if (e && (!e.ctrlKey && !e.metaKey)) { setSelectedCenter(selectedCenter) }
                }
                else {
                    const index = selectedTasks.indexOf(task.id);
                    if (index > -1) {
                        selectedTasks.splice(index, 1); // 2nd parameter means remove one item only
                    }
                    setSelectedTasks([task.id]);
                    missionTaskSelectHandler(task, true);
                    if (!markerClick) setSelectedCenter(null)
                }
            }
        }
        let newBookmarks = JSON.parse(JSON.stringify(bookMarks));
        let findBookmarkIndex = newBookmarks.findIndex(item => item.id === task.id);
        if (findBookmarkIndex > -1) {
            newBookmarks[findBookmarkIndex].selected = true;
        }
        if (findBookmarkIndex > -1 && e && (e.ctrlKey || e.metaKey)) {
            if (newBookmarks[findBookmarkIndex].selected === true) {
                newBookmarks[findBookmarkIndex].selected = false;
            }
        }
        else {
            newBookmarks = newBookmarks.map(i => {
                if (i.id !== task.id) {
                    i.selected = false;
                }
                return i
            })
        }
        setBookMarks(newBookmarks);
    };

    const missionTaskSelectHandler = (task, removeSelected = false) => {
        if (removeSelected) {
            const missions = missionList.map((m) => {
                if (m.tasks && m.tasks.length) {
                    m.tasks = m.tasks.map((t) => {
                        if (task.id == t.id) {
                            return { ...t, active: true, selected: true };
                        }
                        return { ...t, active: false, selected: false };
                    });
                }
                return m;
            });
            setMissionList(missions);
        } else {
            const missions = missionList.map((m) => {
                if (m.tasks && m.tasks.length) {
                    m.tasks = m.tasks.map((t) => {
                        if (task.id == t.id) {
                            return { ...t, active: true, selected: true };
                        }
                        return t;
                    });
                }
                return m;
            });
            setMissionList(missions);
        }
    };

    const setMissionTaskStatus = (task, mission) => {
        let select = [];
        if (!selectedTasks.includes(task.id)) {
            let findTasksBWIndexes = mission.tasks.slice(lastSelectedPostion, task.position + 1)

            for (let task of findTasksBWIndexes) {
                if (!selectedTasks.includes(task.id)) {
                    setSelectedTasks((prev) => {
                        return [...prev, task.id];
                    });
                }
                let findFromTasks = mission.tasks.find(item => {
                    return item.id === task.id
                })
                findFromTasks.active = true;
                select.push(findFromTasks)

            }
            mission.tasks.splice(lastSelectedPostion, (task.position - lastSelectedPostion) + 1, ...select)
            // setTaskList(newTaskList)
            return mission
        }
        else {
            let unselect = [];
            for (let index = task.position + 1; index <= mission.tasks.length - 1; index++) {
                mission.tasks[index].active = false;
                unselect.push(mission.tasks[index])
                setSelectedTasks((prev) => {
                    let indexOfTask = prev.indexOf(mission.tasks[index].id)
                    if (indexOfTask > -1) {
                        prev.splice(indexOfTask, 1)
                    }
                    return [...prev];
                });
            }
            mission.tasks.splice(task.position + 1, (mission.tasks.length - (task.position + 1)), ...unselect)
            return mission
        }
    }
    const updateMission = async (apiData, type) => {
        let response;
        if (type === "UPDATE") {
            response = await taskService.updateMission(apiData);
        }
        else {
            if (apiData.repeatType) apiData.repeatType = apiData.repeatType.id;
            if (apiData.repeatType === "MONTH" && apiData.repeatOnDays) {
                if (apiData.repeatOnDays.id === "DAY")
                    apiData.repeatOnDay = [apiData.repeatOnDays.value];
                else apiData.repeatForDay = [apiData.repeatOnDays.value]
            }
            apiData.startDate = momentTz(apiData.missionDate).tz(timezone.timezone).add(32, "d").startOf("D")
            response = await taskService.duplicateMission({ ...apiData, timezone: timezone.timezone });
        }
        if (response && response.data && response.data.success) {
            await getMissionList()
        }
        setUpdateMissionToggle(false)
    }

    const deleteMission = async (option) => {
        let data = { missionId: selectedMissions[0], currentDate }
        if (option && option === "ALL_FOLLOWING_MISSIONS") {
            data.recurringId = showRecurringMissionPopup?.missionDetail?.recurringId
        }
        const response = await taskService.deleteMission(data);
        // successAlert("Mission Deleted Successfully")
        getUnassignedTaskList();
        getMissionList();
        setDirections(null);
        getTaskList();
        setShowRecurringMissionPopup(false)
    }

    const deleteMissionClickHandler = async () => {
        if (selectedMissions && selectedMissions.length) {
            let missionDetail = missionList.find(m => m.id === selectedMissions[0])
            if (missionDetail && missionDetail.recurring) {
                setShowRecurringMissionPopup({ show: true, type: "DELETE", text: "Delete recurring mission", missionDetail })
            }
            else {
                if (window.confirm(`Are you sure ?`)) {
                    await deleteMission()
                }
            }
        }
        return {}
    };

    const editMenuHandler = async () => {
        let currentTask = "";
        if (selectedTasks && selectedTasks.length) {
            if (unassignedTaskList && unassignedTaskList.length) {
                currentTask = unassignedTaskList.find((t) => t.id === selectedTasks[0]);
            }
            if (!currentTask) {
                if (missionList && missionList.length) {
                    for (let i = 0; i < missionList.length; i++) {
                        if (missionList[i].tasks && missionList[i].tasks.length) {
                            currentTask = missionList[i].tasks.find(
                                (t) => {
                                    return t.id === selectedTasks[0]
                                }
                            );

                            if (currentTask) {
                                break;
                            }
                        }
                    }
                }
            }
        }
        if (currentTask && currentTask.recurring) {
            setShowRecurringMissionPopup({ show: true, type: "EDIT_TASK", text: "Edit recurring task" })
        }
        else {
            await editMenu()
        }
    };

    const editMenu = async (option) => {
        setButtonText("Edit Task")
        let currentTask = "";
        if (selectedTasks && selectedTasks.length) {
            if (unassignedTaskList && unassignedTaskList.length) {
                currentTask = unassignedTaskList.find((t) => t.id === selectedTasks[0]);
            }
            if (!currentTask) {
                if (missionList && missionList.length) {
                    for (let i = 0; i < missionList.length; i++) {
                        if (missionList[i].tasks && missionList[i].tasks.length) {
                            currentTask = missionList[i].tasks.find(
                                (t) => {
                                    return t.id === selectedTasks[0]
                                }
                            );

                            if (currentTask) {
                                break;
                            }
                        }
                    }
                    // missionList.forEach(m=>{
                    //   if(m.tasks && m.tasks)
                    // })
                }
            }
        }
        if (currentTask) {
            setcreateTask(false);
            if (currentTask.location) {
                setLocation(currentTask.location);
            }
            if (currentTask.address) {
                setAddress(currentTask.address);
            }
            if (currentTask && currentTask.docs && currentTask.docs.length) {
                editDocs(currentTask.docs)

            } else {
                setSelectedDocs([])
                // props.getLibraryList()
            }
            let createdFor = currentTask.merchantId.id;
            let formData = {
                id: currentTask.id,
                orderType: currentTask.orderType,
                merchantId: currentTask.merchantId ? currentTask.merchantId.id : userData.id,
                userId: currentTask.userId ? currentTask.userId.id : userData.id,
                createdFor: currentTask.createdFor ? currentTask.createdFor.id : (createdFor ? createdFor : userData.id),
                customerId: currentTask.customerId.id,
                notes: currentTask.notes,
                orderDate: currentDate,
                after: currentTask.after,
                before: currentTask.before,
                beforeTimePicker: currentTask.beforeTimePicker || null,
                afterTimePicker: currentTask.afterTimePicker || null,
                scheduledTime: currentTask.scheduledTime,
                scheduledTimePicker: currentTask.scheduledTimePicker || null,
                durationTime: currentTask.durationTime,
                capacity: currentTask.capacity,
                price: currentTask.price,
                priceUnit: currentTask.priceUnit,
                priceUnitSymbol: currentTask.priceUnitSymbol,
                firstName: currentTask.customer && currentTask.customer.firstName ? currentTask.customer.firstName : currentTask.customerId.firstName,
                lastName: currentTask.customer && currentTask.customer.lastName ? currentTask.customer.lastName : currentTask.customerId.lastName,
                phoneNumber: currentTask.customerId.phoneNumber,
                countryCode: currentTask.customerId.countryCode || "",
                email: currentTask.email && currentTask.customer.email ? currentTask.customer.email : currentTask.customerId.email,
                address: currentTask.address
                    ? currentTask.address
                    : "",
                apartment: currentTask.apartment,
                afterOpen: false,
                beforeOpen: false,
                scheduleTimeOpen: false,
                documents: currentTask.documents,
                libraryFiles: currentTask.libraryFiles,
                values: currentTask.teamIds,
                teamIds: currentTask.teamIds.map(i => i.id),

                // beforeTime: currentTask.beforeTime,
                // afterTime: currentTask.afterTime,
                // scheduleTime: currentTask.scheduleTime
            }
            if (option === "ALL_FOLLOWING_TASKS") {
                formData.recurringId = currentTask.recurringId;
                formData.taskString = currentTask.taskString;
            };
            if (formData.userId !== userData.id) {
                formData.values = []
            }
            setShowRecurringMissionPopup(false)
            setFormData(formData);
            // setTeamList(currentTask.teamIds.length > 1 ? [selectAllOption, ...teams] : [selectAllOption, ...currentTask.teamIds])
        }
    };
    const recurringMissionPopup = () => {
        return (
            <ModalPopUp
                show={showRecurringMissionPopup && showRecurringMissionPopup.show}
                updateState={setShowRecurringMissionPopup}
                left={"38%"}
                width={"20%"}
            >
                <div className="multi-mission-pop-up">
                    <span>{showRecurringMissionPopup && showRecurringMissionPopup.text}</span>
                </div>
                <div className="multi-mission-form">
                    <Formik
                        initialValues={{
                            selectedOption: showRecurringMissionPopup && showRecurringMissionPopup.type === "DELETE" ? "THIS_MISSION" : "THIS_TASK",
                        }}
                        onSubmit={async (values) => {
                            if (showRecurringMissionPopup && showRecurringMissionPopup.type === "DELETE")
                                deleteMission(values.selectedOption)
                            else if (showRecurringMissionPopup && showRecurringMissionPopup.type === "EDIT_TASK") {
                                editMenu(values.selectedOption)
                            }
                            else if (showRecurringMissionPopup && showRecurringMissionPopup.type === "DELETE_TASK") {
                                deleteMenu(values.selectedOption)
                            }
                            else if (showRecurringMissionPopup && showRecurringMissionPopup.type === "REMOVE_TASK") {
                                removeMenu(values.selectedOption)
                            }
                        }}
                    >
                        {({ values }) => (
                            <Form>
                                <div role="group" aria-labelledby="my-radio-group" style={{ color: "#ffffff", display: "flex", flexDirection: "column" }}>
                                    {showRecurringMissionPopup && showRecurringMissionPopup.type === "DELETE" && <label style={{ marginBottom: "8px" }}>
                                        <Field type="radio" name="selectedOption" value="THIS_MISSION" />
                                        This mission only.
                                    </label>}
                                    {showRecurringMissionPopup && (showRecurringMissionPopup.type === "EDIT_TASK" || showRecurringMissionPopup.type === "REMOVE_TASK" || showRecurringMissionPopup.type === "DELETE_TASK") && <label style={{ marginBottom: "8px" }}>
                                        <Field type="radio" name="selectedOption" value="THIS_TASK" />
                                        This mission only.
                                    </label>}
                                    {showRecurringMissionPopup && showRecurringMissionPopup.type === "DELETE" && <label style={{ marginBottom: "8px" }}>
                                        <Field type="radio" name="selectedOption" value="ALL_FOLLOWING_MISSIONS" />
                                        This and following missions.
                                    </label>}
                                    {showRecurringMissionPopup && (showRecurringMissionPopup.type === "EDIT_TASK" || showRecurringMissionPopup.type === "REMOVE_TASK" || showRecurringMissionPopup.type === "DELETE_TASK") && <label style={{ marginBottom: "8px" }}>
                                        <Field type="radio" name="selectedOption" value="ALL_FOLLOWING_TASKS" />
                                        This and following missions.
                                    </label>}
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button className="cncle-btn" onClick={() => { setShowRecurringMissionPopup(false) }}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" style={{ width: "105px" }} className="ctaskbtn">
                                        {"Submit"}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

            </ModalPopUp >
        )
    }

    const rytSideBarProps = {
        importPopup, editMenuHandler, removeTaskHandler,
        selectedMission, setSelectedMission,
        createTask, setcreateTask,
        socket: props.socket,
        setSocket: props.setSocket,
        setAssignToggle,
        setPrintToggle,
        selectedMissions, setSelectedMissions,
        currentDate, setCurrentDate,
        setDirections,
        setcurrentMission,
        updateMission,
        setUpdateMissionToggle,
        setDuplicateMissionToggle,
        setMissionData,
        currentMission,
        getTaskList,
        setAddDocToggle,
        setSelectedDocs,
        selectedDocs,
        getLibraryList,
        libraryList,
        editDocs,
        setSelectedCenter,
        setDefaultCenter,
        setPDFData,
        setImportToggle,
        setMultiMissionToggle,
        multiMissionOption,
        optimiseFormData, setOptimiseFormData, initialValues2,
        showOptimiseSection,
        setShowOptimiseSection,
        handleOptimiseSubmit, setShowRecurringMissionPopup,
        taskCount,
        features,
        getDirections,
        importToggle,
        setTaskCount,
        missionList, setMissionList, getMissionList,
        unassignedTaskList, setUnassignedTaskList, getUnassignedTaskList,
        taskSingleClickHandler, missionTaskSingleClickHandler, missionClickhandler,
        toggleState, setToggleState,
        taskDetail, setTaskDetail,
        selectedTasks, setSelectedTasks,
        taskList,
        setLoading,
        deleteMenuHandler,
        deleteMissionClickHandler,
        initialValues: initialValuesTask,
        setFormData, formData,
        address, setAddress,
        location, setLocation,
        setButtonText, buttonText
    };

    const mapProps = {
        key: "map",
        isMarkerShown: "true",
        defaultCenter,
        bookMarks,
        agentBookMarks,
        selectedCenter,
        setDefaultCenter,
        setSelectedCenter,
        taskSingleClickHandler,
        missionTaskSingleClickHandler,
        map, setMap,
        directions, otherMarkers,
        hoverHub, setHoverHub,
        getTaskList,
        drawPolygon: mapControls.drawPolygon
    }

    const taskStatus = [
        {
            name: "Unassigned",
            value: "unassigned"
        },
        {
            name: "Assigned",
            value: "assigned"
        },
        {
            name: "OnGoing",
            value: "onGoing"
        },
        {
            name: "Delayed",
            value: "delayed"
        },
        {
            name: "Completed",
            value: "completed"
        },
        {
            name: "Failed",
            value: "failed"
        }
    ]

    const handleSubmit = async (formValue) => {
        try {
            if (formValue.hubId) delete formValue.hubId;
            const response = await taskService.assignMission({
                ...formValue,
                // startLocation: location,
                // address,
                missionId: selectedMissions[0],
            });
            setAssignToggle(false);
            getMissionList();
            getTaskList();
            let mission = response && response.data && response.data.mission ? response.data.mission : {}
            if (mission) {
                // setSelectedMissions([mission.id]);
                // setcurrentMission(mission.id);
                getDirections(mission)
            }
        } catch (err) {
            console.log("error:::", err);
        }
    };

    const saveLibraryList = (e) => {
        e.preventDefault()
        if (libraryList && libraryList.length) {
            const docs = libraryList.filter(item => item.check == true).map(item => item.id)
            setSelectedDocs(docs)
            setAddDocToggle(false)
            getLibraryList()
        }
    }

    const AddDocSection = () => {
        return (
            <div className="addcustomer-information selectDoc">

                <div className="customer-form">
                    {libraryList &&
                        libraryList.length > 0 &&
                        libraryList.map((item, index) => {
                            return (
                                <div className="inputdocs" key={item.id}  >
                                    <input
                                        type="checkbox"
                                        color="green"
                                        style={{ marginRight: 20 }}
                                        checked={libraryList[index].check}
                                        onChange={() => {
                                            const list = libraryList;
                                            list[index].check = !list[index].check;
                                            setLibraryList([...list]);
                                            console.log("list:::", list)
                                        }}
                                    />
                                    <span>{item.name ? item.name : ""}</span>
                                </div>

                            );
                        })}
                    <div className="ct-btn">
                        {libraryList &&
                            libraryList.length > 0 && <Button type="button" onClick={saveLibraryList} >Save</Button>}
                    </div>
                </div>
            </div>
        )
    }

    let bottomMenuProps = {
        userData,
        setView,
        teams,
        agentList,
        userList,
        merchantList,
        selectedBottomMenuTeam, setSelectedBottomMenuTeam,
        selectedBottomMenuAgent, setSelectedBottomMenuAgent,
        selectedBottomMenuMerchant, setSelectedBottomMenuMerchant,
        selectedBottomMenuUser, setSelectedBottomMenuUser,
        selectedBottomMenuStatus, setSelectedBottomMenuStatus,
        taskStatus, views: true, dashboard: true
    }


    let listViewPros = {
        taskList,
        infoAlert,
        errorAlert,
        pageTaskCount, setTaskPageCount,
        exportTasks, setExportTasks,
        selectedTasks, setSelectedTasks,
        currentPage, setCurrentPage, deleteTaskFunction
    }

    const MapControls = () => {
        return (
            <>
                <div className="dashboard-map-controls">
                    <div className="bottom-map-control">
                        <div className="dbt-menu">
                            <div
                                className={"menu-btn"}
                            >
                                <span className={mapControls && mapControls.drawPolygon ? "menuicon selected" : "menuicon"} onClick={() => {
                                    setMapControls((prev) => {
                                        return {
                                            ...prev,
                                            drawPolygon: !mapControls.drawPolygon
                                        }
                                    })
                                }}>
                                    <img src={polygon} alt={"polygon"}></img>
                                </span>
                                <span className="menuiconright">
                                    <img src={layer} alt={"layer"}></img>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }

    return (
        <>
            <Layout {...rytSideBarProps}>
                <div className="dashboard-content-wrapper">
                    {/* <img src={mapimg} alt="map-img" /> */}
                    {/* <MyMapComponent key="map" />  */}
                    <div style={{ height: "100vh", width: "100%" }}>
                        {/* {((features && features.mapAndListView) || (userData && userData.role === 1)) && view === "MAP" && <MapComponent {...mapProps} />} */}
                        {/* {view === "MAP" && <MapComponent {...mapProps} />} */}
                        {view === "MAP" && MapComponent({ ...mapProps })}
                        {/* {view === "LIST" && <ListViewComponent {...listViewPros} />} */}
                        {view === "LIST" && ListViewComponent({ ...listViewPros })}
                    </div>
                </div>
                {Bottommenu({ ...bottomMenuProps })}
                {MapControls()}
                {/* print mission popup start */}
                {/* <PrintManifestPopup /> */}
                {/* {importToggle && ImportPopUp()} */}
                {MultiMissionPopup()}
                {recurringMissionPopup()}
                {updateDuplicateMissionPopup()}
                {/* create agent popup start */}
                <div className="opencustomer-popup" open={assignToggle}>
                    <div className="addCustomer-popup">
                        <div className="addcustomer-slide">
                            <div className="addcustomer-field">
                                <span className="title">Assign Agent</span>
                                <div className="customer-import">
                                    <Button
                                        className="createcustomer-close"
                                        onClick={() => {
                                            setAssignToggle(false);
                                        }}
                                    >
                                        <img src={closeicon} alt="icon" />
                                    </Button>
                                </div>
                            </div>

                            <div className="addcustomer-information">
                                <h2>Agent Information</h2>
                                <div className="customer-form">
                                    <Formik
                                        enableReinitialize
                                        initialValues={initialValues}
                                        render={(formProps) => {
                                            return (
                                                <Form>
                                                    <Row className="custinfo-form">
                                                        <Col xs={6}>
                                                            <InputGroup className="mb-3 teamsl">
                                                                <InputGroup.Text id="teamicon">
                                                                    <img src={teamicon} alt="teamicon" />
                                                                </InputGroup.Text>
                                                                <Field
                                                                    className="form-control"
                                                                    name="teamId"
                                                                    as="select"
                                                                    value={formProps.values["teamId"]}
                                                                    onChange={(e) => {
                                                                        formProps.setFieldValue("teamId", e.target.value)
                                                                        getAgentList({ teamId: e.target.value })
                                                                    }}
                                                                >
                                                                    <option selected>Select Team</option>
                                                                    {teams &&
                                                                        teams.length > 0 &&
                                                                        teams.map((t) => (
                                                                            <option value={t.id}>
                                                                                {t.teamName ? t.teamName : ""}
                                                                            </option>
                                                                        ))}
                                                                </Field>
                                                                <ErrorMessage name="teamId" component="div" />
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="custinfo-form">
                                                        <Col xs={6}>
                                                            <InputGroup className="mb-3 teamsl">
                                                                <InputGroup.Text id="teamicon">
                                                                    <img src={teamicon} alt="teamicon" />
                                                                </InputGroup.Text>
                                                                <Field
                                                                    className="form-control"
                                                                    name="agentId"
                                                                    as="select"
                                                                >
                                                                    <option selected>Select Agent</option>
                                                                    {agentList &&
                                                                        agentList.length > 0 && formProps.values["teamId"] &&
                                                                        agentList.map((t) => (
                                                                            <option value={t.id}>
                                                                                {t.firstName ? t.firstName : ""} {t.lastName ? t.lastName : ""}
                                                                            </option>
                                                                        ))}
                                                                </Field>
                                                                <ErrorMessage name="agentId" component="div" />
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <div className="ct-btn">
                                                        <Button
                                                            className="cncle-btn"
                                                            onClick={() => {
                                                                setAssignToggle(false);
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button className="ctaskbtn" type="submit">
                                                            Let’s Go
                                                        </Button>
                                                    </div>
                                                </Form>
                                            );
                                        }}
                                        onSubmit={handleSubmit}
                                    ></Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* create agent popup end */}

                {/* add  docs popup start */}
                <div className="opencustomer-popup" open={addDocToggle}>
                    <div className="addCustomer-popup">
                        <div className="addcustomer-slide">
                            <div className="addcustomer-field">
                                <span className="title">Select Docs</span>
                                <div className="customer-import">
                                    <Button
                                        className="createcustomer-close"
                                        onClick={() => {
                                            setAddDocToggle(false);
                                        }}
                                    >
                                        <img src={closeicon} alt="icon" />
                                    </Button>
                                </div>
                            </div>


                            <AddDocSection />
                        </div>
                    </div>
                </div>
                {/* create agent popup end */}
            </Layout>
            <ToastContainer
                style={{ color: "black" }}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {loading ? <Loader /> : null}
        </>
    );
};

export default Dashboard;
