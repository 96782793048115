import {
    SAVE_DEFAULT_SUBSCRIPTION
} from './actionTypes';

export const defaultSubscription = payload => {
    return {
        type: SAVE_DEFAULT_SUBSCRIPTION,
        payload
    }
}
