import React, { useState, useEffect } from "react";
import "./Export.scss";
import ExportFile from "./Exportfile";
import { Loader } from "../../components/Loader/loader";
const ExportPopup = (props) => {
  const [step, setStep] = useState(1)
  const [rows, setRows] = useState(0);
  const [loading, setLoading] = useState(false);

  const getTaskListDashboard = props.getTaskList
  let exportProps = {
    ...props,
    getTaskListDashboard,
    step: step,
    setStep: setStep,
    setRows,
    rows,
    setLoading
  }
  return (
    <>
      <div className="import-form">
        {step === 1 && <ExportFile {...exportProps} />}
        {loading ? <Loader popup={true} /> : null}
      </div>
    </>
  );
};

export default ExportPopup;
