import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Form as BootstrapForm,
  Row,
  Col,
  FormControl,
  Button,
  Dropdown,
  NavDropdown,
} from "react-bootstrap";
import "./customer.scss";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Union from "../../../assets/image/Union.png";
import downloadicon from "../../../assets/image/downloadicon.png";
import searchicon from "../../../assets/image/searchicon.png";
import ShortIcon from "../../../assets/image/filter-icon.png";
import filterremoveicon from "../../../assets/image/down-icon-white.png";
import * as Yup from "yup";
import { ExportToCsv } from "export-to-csv";
import {
  successAlert,
  errorAlert,
  infoAlert,
} from "../../../services/toast.service";
import Swal from "sweetalert2";
import swalIcon from "../../../assets/image/swalIcon.png"
import ReactPaginate from "react-paginate";
import userService from "../../../services/user.service";
import RightSideBar from "../components/rightsidebar";
import { useHistory } from "react-router-dom";
import { DELETE_CUSTOMER_API, GET_CUSTOMER_API } from "../../../redux/invoicing/actionTypes";
import { delay } from "../../../helpers/helperFunctions";

const Customer = (props) => {
  const { countryCode } = useSelector(
    (state) => state.authSaga
  );
  const dispatch = useDispatch();
  const initialValues = {
    id: "",
    password: "test@123",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    apartment: "",
    user: "",
    countryCode: countryCode && countryCode === "IN" ? "+91" : "+1",
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [formData, setFormData] = useState(initialValues);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [subAdmin, setSubAdmin] = React.useState([]);
  const { customerList, pageCount, pageLimit } = useSelector((state) => state.invoicing)

  const userData = useSelector((state) => state.auth.user);

  let validation = {
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number is required"),
    countryCode: Yup.string().required("Please choose a countryCode"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("last name is required"),
    address: Yup.string().required("Address  is required"),
    // apartment: Yup.string().required("Apartment  is required"),
    // email: Yup.string().required("Email is required!").email("Please fill valid email address "),
    // password: Yup.string().required("Password is required!"),
  };

  if (userData && userData.role === 1) {
    validation.user = Yup.string().required("Please select a user");
  }

  const validationSchema = Yup.object().shape(validation);

  const formatStatus = (cell, row) => {
    if (row.blocked) return <span>Blocked</span>;
    return <span>Active</span>;
  };
  function TypeFormatter(cell, row) {
    return (
      <span>
        {row.isMerchant ? "Merchant" : "Individual"}
      </span>
    );
  }
  const UserNameFormatter = (cell, row) => {
    return (
      <span>
        {row.userId && row.userId.firstName ? row.userId.firstName : ""}{" "}
        {row.userId && row.userId.lastName ? row.userId.lastName : ""}
      </span>
    );
  };

  const PhoneFormatter = (cell, row) => {
    return (
      <span>
        {row.countryCode ? row.countryCode : ""}{" "}
        {row.phoneNumber ? row.phoneNumber : ""}
      </span>
    );
  };
  // function toggle() {
  //   setIsOpened(wasOpened => !wasOpened);
  // }
  const handleActionClick = async (action, row) => {
    if (action === "Edit") {
      let customer = JSON.parse(JSON.stringify(row))
      delete customer.userId;
      delete customer.createdBy;
      delete customer.status;
      props.setButtonText("Edit")
      props.setCustomerForm(customer)
      await delay(500)
      props.createCustomerToggle();
    }
    else {
      handleDelete(row)
    }
  }

  const handleDelete = (row) => {
    Swal.fire({
      // iconHtml: `<img src="${IoMdTime}">`,
      imageUrl: swalIcon,
      title: "Are you sure?",
      text: "Deleted records can't be restored!",
      showCancelButton: true,
      color: "#000000",
      background: "#ffffff",
      confirmButtonColor: "#7066E0",
      cancelButtonColor: "#7066E0",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    }).then((result) => {
      console.log("result", result)
      if (result.isConfirmed) {
        dispatch({
          type: DELETE_CUSTOMER_API,
          data: {
            payload: {
              customerId: row.id
            }
          }
        })
      }
    })

  }

  const Remove = (cell, row) => {
    return (
      <>
        <div className="action-items">
          <span className="filter-edit" style={{ cursor: "pointer" }} onClick={() => {
            handleCreateInvoice(row)
          }}>
            Create invoice
          </span>
          <span className="filter-edit">
            <div className="action">
              <NavDropdown
                title={<img src={filterremoveicon} alt={"down-arrow"} />}
                id="nav-dropdown"
              >
                <NavDropdown.Item
                  eventKey="4.1"
                  onClick={() => {
                    handleActionClick("Edit", row);
                  }}
                >
                  Edit
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="4.2"
                  onClick={() => {
                    handleActionClick("Delete", row);
                  }}
                >
                  Delete
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </span>
        </div>
      </>
    );
  };

  const getUserList = async (params) => {
    dispatch({
      type: GET_CUSTOMER_API,
      data: {
        payload: { userId: userData.id },
        saveInRedux: true,
        saveLimit: pageLimit
      }
    })
  };

  const getUserByRole = async (obj) => {
    const response = await userService.getUsers(obj);
    if (response.status == 200) {
      setSubAdmin(response.data || []);
    }
  };

  const exportToCsv = async () => {
    console.log("i export");
    userService
      .getUsers({ role: 3 })
      .then((result) => {
        if (result.status === 200) {
          let data = [];

          result.data.map((item, index) => {
            let d = {
              firstName: item.firstName || "N/A",
              lastName: item.lastName || "N/A",
              email: item.email || "N/A",
              apartment: item.apartment || "N/A",
              address: item.address || "N/A",
              Latitude:
                item.location && item.location.lat ? item.location.lat : "N/A",
              Longitude:
                item.location && item.location.lng ? item.location.lng : "N/A",
              countryCode: item.countryCode || "N/A",
              phoneNumber: item.phoneNumber || "N/A",
              status: item.blocked ? "UnActive" : "Active",
            };
            data.push(d);
          });

          const options = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: true,
            title: "Customer List",
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: "customers",
          };

          const csvExporter = new ExportToCsv(options);
          if (data.length > 0) {
            csvExporter.generateCsv(data);
          } else {
            errorAlert("No data found");
          }
        } else {
          errorAlert(result.message ? result.message : "Something went wronng");
        }
      })
      .catch(console.log);
  };

  //handlers
  const editHandler = (row) => {
    setFormData({
      id: row.id ? row.id : "",
      firstName: row.firstName ? row.firstName : "",
      lastName: row.lastName ? row.lastName : "",
      phoneNumber: row.phoneNumber ? row.phoneNumber : "",
      email: row.email ? row.email : "",
      address: row.address ? row.address : "",
      apartment: row.apartment ? row.apartment : "",
      countryCode: row.countryCode ? row.countryCode : "",
      user: row.userId ? row.userId.id : "",
    });
    props.createCustomerToggle();
  };
  const deleteHandler = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      text: "Deleted records can't be restored!",
      color: "#ffffff",
      background: "#151719",
      confirmButtonColor: "#1A1D1F",
      cancelButtonColor: "#1A1D1F",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await userService.deleteUser(row.id);
        if (response) {
          console.log("delete response ::::", response);
          Swal.fire(
            "Deleted!",
            "User has been Deleted Successfully !",
            "success"
          );
          getUserList({ page: currentPage, searchText });
        }
      }
    });
  };
  const RowSelectHandler = (row, isSelect, rowIndex, e) => {
    // ...
    if (isSelect) {
      const item = row.id || "";
      setSelectedItems([...selectedItems, item]);
    } else {
      const items = selectedItems.filter((sItem) => sItem != row.id);
      setSelectedItems(items);
    }
  };

  const selectAllRowsHandler = (isSelect, rows, e) => {
    if (isSelect) {
      const items = rows
        .map((rowItem) => rowItem.id)
        .filter((itemId) => {
          if (!selectedItems.includes(itemId)) return itemId;
        });
      setSelectedItems([...selectedItems, ...items]);
    } else {
      const ids = rows.map((rowItem) => rowItem.id);
      const items = selectedItems.filter((sItem) => {
        if (!ids.includes(sItem)) return sItem;
      });
      setSelectedItems(items);
    }
  };

  const deleteAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        text: "Deleted records can't be restored!",
        color: "#ffffff",
        background: "#151719",
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await userService.deleteManyUsers(selectedItems);
          if (data.success) {
            getUserList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Users Deleted Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const blockAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        text: "You Want to Block selected Users !",
        icon: "warning",
        showCancelButton: true,
        color: "#ffffff",
        background: "#151719",
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await userService.blockManyUsers(selectedItems);
          if (data.success) {
            getUserList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Users Blocked Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: RowSelectHandler,
    onSelectAll: selectAllRowsHandler,
    bgColor: "rgb(238, 193, 213) !important",
    // classes: (row, rowIndex) => {
    //   console.log("rrrrrrrrrrrrrrrrowwwwwww", row)
    //   return ""
    // }
  };

  useEffect(() => {
    getUserList({ page: currentPage, searchText });
    if (userData.role === 1) {
      getUserByRole({ role: 2, roleBy: userData.role });
    }
  }, [userData]);

  useEffect(() => {
    console.log("search text ::::", searchText);
    const delayDebounceFn = setTimeout(async () => {
      getUserList({ page: 1, searchText });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const escapeRegex = (str) => {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "").replace(/ /g, "");
  };

  const history = useHistory();
  useEffect(() => {
    history.replace()
    // reload and pass empty object to clear state
    // we can also use replace option: ..., {replace: true}
  }, []);

  const handlePageClick = async ({ selected }) => {
    console.log("current page::", selected);
    if (selected + 1 !== currentPage) {
      setCurrentPage(selected + 1);
      await getUserList({ page: selected + 1, searchText });
    }
  };

  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.searchText
    ) {
      setSearchText(props.location.state.searchText);
    }
  }, []);

  const tableRowEvents = {
    onRowClick: (row) => {
      setSelectedCustomer(row)
    }
  }

  const handleCreateInvoice = (row) => {
    history.push({
      pathname: "/Invoice",
      state: {
        createInvoice: true,
        customer: row
      },
    });
  }
  return (
    <>
      <div className="main-wrapper">
        <div className="customer-page-wrapper d-flex">
          {/* customer layout start */}
          <div className="customer-form-wrapper">
            <div className="customer-heading-box">
              <Row>
                <Col xs={6}>
                  <h1>Customers</h1>
                </Col>
                <Col xs={6}>
                  {((userData && userData.role === 1) || ((userData && userData.role === 2 && !userData.userId) || (userData.userId && userData.userRole === "manager")) || (userData && userData.role === 4 && userData.userRole === "manager")) && <div className="createmsbox">
                    <div onClick={props.createCustomerToggle}>
                      <Button className="cticon">
                        <img src={Union} alt="icon" />
                      </Button>
                      <span>Add Customer</span>
                    </div>
                    <div>
                      <Button onClick={() => exportToCsv()}>
                        <img src={downloadicon} alt="icon" />
                      </Button>
                    </div>
                  </div>}
                </Col>
              </Row>
            </div>
            <div className="customer-searching-box">
              {((userData && userData.role === 1) || ((userData && userData.role === 2 && !userData.userId) || (userData.userId && userData.userRole === "manager")) || (userData && userData.role === 4 && userData.userRole === "manager")) && <Row>
                <Col xs={6}>
                  <div className="create-action-option mb-3">
                    <Dropdown>
                      <Dropdown.Toggle className="bulk-action">
                        Bulk Action
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={blockAllHandler}>
                          Block All
                        </Dropdown.Item>
                        <Dropdown.Item onClick={deleteAllHandler}>
                          Delete Selected
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="searchbar">
                    <BootstrapForm className="d-flex">
                      <FormControl
                        type="search"
                        placeholder="Search or type a name"
                        className="me-2"
                        autoComplete="off"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        aria-label="Search"
                      />
                      <Button className="searchicon">
                        <img src={searchicon} alt="searchicon" />
                      </Button>
                      {/* <span className="searchF">
                        <img src={searchF} alt="icon" />
                      </span> */}
                    </BootstrapForm>
                  </div>
                </Col>
              </Row>}
            </div>

            {/* customer list start */}
            <div className="customer-list-wrapper">
              <BootstrapTable
                options={tableRowEvents}
                data={customerList}
                striped={true}
                hover={true}
                selectRow={selectRowProp}
                wrapperClasses="table-responsive"
                keyField="id"
              >
                <TableHeaderColumn
                  dataField="isMerchant"
                  dataAlign="left"
                  dataSort={true}
                  dataFormat={TypeFormatter}
                  width="200px"
                >
                  Type <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="displayName"
                  dataSort={true}
                  width="250px"
                >
                  Customer display name
                  <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="companyName"
                  dataSort={true}
                  width="250px"
                >
                  Company name
                  <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="phoneNumber"
                  dataSort={true}
                  width="200px"
                  dataFormat={PhoneFormatter}
                >
                  Phone number
                  <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="currency"
                  dataSort={true}
                  width="200px"
                >
                  Currency
                  <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="openBalance"
                  dataSort={true}
                  width="200px"
                >
                  Open balance
                  <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                {((userData && userData.role === 1) || ((userData && userData.role === 2 && !userData.userId) || (userData.userId && userData.userRole === "manager")) || (userData && userData.role === 4 && userData.userRole === "manager")) && <TableHeaderColumn
                  dataField="Action"
                  dataAlign="left"
                  dataFormat={Remove}
                  width="200px"
                >
                  Action
                </TableHeaderColumn>}
              </BootstrapTable>
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="Prev"
                renderOnZeroPageCount={null}
                className="pagination"
              />
            </div>

            {/* customer list end */}
          </div>

          {/* customer layout end */}
        </div>
        <RightSideBar {...props.rightsidebarprops} customerPage={true} />
      </div>
    </>
  );
};

export default Customer;
