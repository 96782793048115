import { DirectionsRenderer, GoogleMap, useLoadScript, DrawingManager } from "@react-google-maps/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import map_options from "./map_options";
import RenderMarkers from "./MarkerComponent";
import AssignImage from "../../assets/image/started-icon.png"

const MapComponent = (props) => {
    // const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY })
    const mapContainerStyle = {
        width: '100%',
        height: '100%'
    };

    const onLoad = map => {
        props.setMap(map)
    }

    const onUnmount = map => {
        props.setMap(null)
    }
    
    const markerProps = {
        bookMarks: props.bookMarks,
        agentBookMarks: props.agentBookMarks,
        map: props.map,
        setSelectedCenter: props.setSelectedCenter,
        taskDetailHandler: props.taskDetailHandler,
        taskSingleClickHandler: props.taskSingleClickHandler,
        selectedCenter: props.selectedCenter,
        missionTaskSingleClickHandler: props.missionTaskSingleClickHandler,
        hoverHub: props.hoverHub, setHoverHub: props.setHoverHub
    }

    const otherMarkerProps = {
        ...markerProps,
        bookMarks: [],
        agentBookMarks: [],
        otherMarkers: props.otherMarkers,
    }
    const onPolygonLoad = drawingManager => {
        // console.log(drawingManager)
    }

    const onPolygonComplete = polygon => {
        // console.log(polygon)
        let polygonBounds = polygon.getPath();
        let bounds = [];
        for (let i = 0; i < polygonBounds.length; i++) {
            let point = {
                lng: polygonBounds.getAt(i).lng(),
                lat: polygonBounds.getAt(i).lat()
            };
            bounds.push(point);
        }
        props.getTaskList(false, false, {
            coordinates: JSON.stringify(bounds)
        }, null, true)
        polygon.setPaths([]);
    }

    const symbolOne = {
        path: window.google.maps.SymbolPath.CIRCLE,
        strokeColor: "#ffffff",
        strokeWeight: 1,
        scale: 10,
        fillColor: "rgb(38, 129, 196)",
        fillOpacity: 1,
    };

    return (<><GoogleMap
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={map_options}
        mapContainerStyle={mapContainerStyle}
        center={props.defaultCenter}
        clickableIcons={false}
        zoom={15}
    >
        {props.directions && <DirectionsRenderer directions={props.directions} options={{
            suppressMarkers: true,
            polylineOptions: { strokeColor: '#0e3cb0', geodesic: true }
        }} />}
        {(props.bookMarks.length || props.agentBookMarks.length || props.otherMarkers.length) && <RenderMarkers {...markerProps} />}
        {(props.otherMarkers.length) && <RenderMarkers {...otherMarkerProps} />}
        {props.drawPolygon ? <DrawingManager
            onLoad={onPolygonLoad}
            onPolygonComplete={onPolygonComplete}
            drawingMode={"polygon"}
            options={{
                drawingControl: false,
                markerOptions: {
                    icon: AssignImage
                },
                polygonOptions: {
                    fillColor: "rgb(38, 129, 196)",
                    strokeColor: "rgb(38, 129, 196)",
                    strokeWeight: 5,
                    icons: [{
                        icon: symbolOne,
                        offset: "0%",
                        repeat: "100%"
                    }],
                }

            }}
        /> : null}
    </GoogleMap>
    </>)
}

export default MapComponent;