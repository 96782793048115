import { InfoWindow } from "@react-google-maps/api";
import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import personalicon from "../../../../assets/image/personalicon.png"
import moment from "moment";
import { KmToMiles, formatAMPM, renderCustomerName } from "../../../../helpers/helperFunctions";
import "../../pages/container/main.scss";
import { MdOutlineAllInbox } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";

const InfoWindowComponent = (props) => {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const distance = (totalDist, distanceUnit) => {
        let value = totalDist;
        let distanceMetric = "KM"
        if (distanceUnit && distanceUnit === "m") {
            value = KmToMiles(value)
            distanceMetric = "Miles"
        }
        return `${value.toFixed(2)} ${distanceMetric}`
    }

    const InfoWindowDivV2 = ({ marker }) => {
        let data = marker;
        if (data.markerType && data.markerType === "AGENT") {
            return (<div className="infowindow-agent">
                <div className="agent-details">
                    <div className="detail-call-chat">
                        <div className="detail-address-pic">
                            <div>
                                <img src={data.profilePic && data.profilePic.original ? data.profilePic.original : personalicon} alt="profile pic" />
                            </div>
                            <div className="detail-address">
                                <div className="title">
                                    {data
                                        ? data.firstName
                                        : "Sebastien Godbout"}{" "}
                                    {data ? data.lastName : ""}
                                </div>
                                <div className="btn">
                                    <BsFillPersonFill className="icon" /><p>
                                        {data && data.teamId ? data.teamId.teamName : ""}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
        }
        else {
            return (<div className="infowindow">
                <div className="task-details">
                    <div className="pic">
                        <p>{data?.orderType}</p>
                    </div>
                    <div className="text">
                        <div className="mission-task-top">
                            <div className="name-and-address">
                                <h4>{`${data.position || data.position === 0 ? `${data.position + 1}.` : ``} ${renderCustomerName(data)}`}</h4>
                                <p>{data?.address}</p>
                            </div>
                            {data?.missioned && <p className="time">{data?.eta ? data?.eta : ""}</p>}
                        </div>
                        <div className="mission-task-bottom">
                            {data?.merchantId && data?.merchantId.role === 4 && data?.merchantId.companyName && <div className="btn">
                                <MdOutlineAllInbox className='icon' />
                                <p>{`${data?.merchantId.companyName}`}</p>
                            </div>}
                            {data?.merchantId && data?.merchantId.role === 2 && data?.merchantId.companyName && <div className="btn">
                                <MdOutlineAllInbox className='icon' />
                                <p>{`${data?.merchantId.companyName}`}</p>
                            </div>}
                            {data?.scheduledTime && <div className="btn">
                                <IoMdTime className='icon' />
                                <p>{formatAMPM(data?.scheduledTimeSeconds)}</p>
                            </div>}
                            {(data?.after || data?.before) && <div className="btn">
                                <IoMdTime className='icon' />
                                <p>{`${data?.after ? formatAMPM(data?.afterSeconds) : ""}-${data?.before ? formatAMPM(data?.beforeSeconds) : ""}`}</p>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
    
    return (
        <InfoWindow
            key={props.marker.id}
            onCloseClick={() => {
                props.setSelectedCenter(null);
            }}
            position={{
                lat: props.selectedCenter.lat,
                lng: props.selectedCenter.lng
            }}
        >
            <InfoWindowDivV2 {...props} />
        </InfoWindow>
    )

}

export default InfoWindowComponent