import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute(props) {
    const { component: Component, ...rest } = props;

    const render = props => {
        const user = JSON.parse(localStorage.getItem("user"))
        if (!user) {
            return <Redirect to={'/login'} />;
        }

        return <Component {...props} />;
    };

    return <Route {...rest} render={render} />;
}

export default PrivateRoute;

