import {
    SAVE_HUB_LIST
} from "./actionTypes";

let initialState = {
    hubList: [],
    pageCount: 1,
    pageLimit: 10
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_HUB_LIST:
            let hubList = JSON.parse(JSON.stringify(state.hubList))
            if (action.payload.createApi) {
                delete action.payload.createApi;
                if (action.payload.sortBy) {
                    let split = action.payload.sortBy.split(":");
                    if (split[1] === "desc") {
                        if (action.payload.currentPage === 1) {
                            hubList.unshift(action.payload.hub)
                            if (hubList.length > action.payload.pageLimit) {
                                hubList.splice(10, 1)
                            }
                        }
                    }
                    else {
                        if (action.payload.currentPage === action.payload.pageCount) {
                            if (hubList.length < action.payload.pageLimit) {
                                hubList.push(action.payload.hub)
                            }
                        }
                    }
                }

            }
            else if (action.payload.editApi) {
                delete action.payload.editApi;
                let hubIndex = hubList.findIndex(i => {
                    return i.id === action.payload.hub.id
                })
                hubList[hubIndex] = action.payload.hub
            }
            else {
                hubList = action.payload.results || []
            }
            return {
                ...state,
                hubList: hubList,
                ...(action.payload.totalPages && { pageCount: action.payload.totalPages }),
                ...(action.payload.limit && { pageLimit: action.payload.limit })
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;