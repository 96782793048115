const MapDirectionsRenderer = async (props) => {
    const { places, travelMode } = props;
    const waypointss = places.map((p) => ({
        location: { lat: p.lat, lng: p.lng },
        stopover: true,
    }));
    const origin = waypointss[0].location;
    const destination = waypointss[waypointss.length - 1].location;
    let waypoints = [];
    for (let key = 1; key < waypointss.length - 1; key++) {
        waypoints.push(waypointss[key])
    }
    const directionsService = new window.google.maps.DirectionsService();
    return new Promise((resolve, reject) => {
        directionsService.route(
            {
                origin: origin,
                destination: destination,
                optimizeWaypoints: false,
                travelMode: travelMode,
                waypoints: waypoints
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    console.log("statusstatus", status)
                    console.log("result", result)
                    resolve({
                        status,
                        result
                    })
                }
                else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                    resolve({
                        status,
                        result,
                        props
                    })
                }
            }
        );
    })
}

export { MapDirectionsRenderer }