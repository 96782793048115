import React, { useState } from "react";
import {
    Row,
    Col,
    Form,
    Button,
} from "react-bootstrap";
import {
    successAlert,
    errorAlert,
    infoAlert,
} from "../../services/toast.service";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Loader } from "../../components/Loader/loader";
import { useDispatch, useSelector } from "react-redux";
import authService from "../../services/auth.service";

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "#fff",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#fce883" },
            "::placeholder": { color: "#87bbfd" },
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee",
        },
    },
};

const StripePay = ({ apiData, getPaymentMethods, createCustomerToggle, setShowAddCard, showBack, setFormData }) => {
    const [loading, setLoading] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState(false)
    const stripe = useStripe();
    const elements = useElements();

    const goHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        });
        if (!error) {
            let response = await authService.addChangePaymentMethods({ ...apiData, paymentMethod });
            if (response && response.data && response.data.success) {
                setLoading(false);
                successAlert(response.data.message);
                getPaymentMethods();
                setShowAddCard(false);
                createCustomerToggle()
            } else {
                setLoading(false);
                errorAlert(
                    response.data.message ? response.data.message : "Something went wrong"
                );
            }
        } else {
            setLoading(false);
            errorAlert(error.message ? error.message : "Something went wrong");
        }
    };

    return (
        <div>
            <div className="signupinfo-form">
                <div className="signup-form-box payment-info">
                    <h2>Payment info</h2>
                    <Form inline="true">
                        <fieldset className="FormGroup">
                            <div className="FormRow">
                                <CardElement options={CARD_OPTIONS} />
                            </div>
                        </fieldset>

                        <div className="payment-information">
                            <div className="allpayment">

                                <p>
                                    All payment information is stored securely. Your card won’t be
                                    charged unless you explicitly select a plan and confirm your
                                    subscription type.
                                </p>
                            </div>

                        </div>
                        <div className="bsbtn">
                            <Row>
                                {showBack && <Col xs={2}>
                                    <div className="submitbtn">
                                        <Button
                                            type="submit"
                                            onClick={() => {
                                                setFormData((prev) => {
                                                    return {
                                                        ...prev,
                                                        step: prev.step - 1
                                                    }
                                                })
                                                setShowAddCard(false)
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </Col>}
                                <Col xs={6}>
                                    <div className="submitbtn">
                                        <Button
                                            type="submit"
                                            onClick={goHandler}
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
                {loading ? <Loader /> : null}
            </div>

        </div>
    );
};

export default StripePay;
