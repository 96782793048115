import {
    Card,
    Row,
    Col,
    Form,
    InputGroup,
    FormControl,
    Button,
    Dropdown,
    Modal,
    Container,
    NavDropdown,
} from "react-bootstrap";
import signatureicon from "../../assets/image/signatureicon.png";
import legalicon from "../../assets/image/legalicon.png";
import dischargeicon from "../../assets/image/discharge-icon.png";
import cameraicon from "../../assets/image/cameraicon.png";
import Switch from "react-switch";
import Union from "../../assets/image/Union.png";
import closeicon from "../../assets/image/close-icon.png";
import moment from "moment";
import { MdEdit, MdClear } from "react-icons/md";
import { useState } from "react";
import SignaturePad from 'react-signature-canvas'
import { useSelector } from "react-redux";
const LegalProtectionTab = (props) => {
    let type = props.settingTypes.OPERATIONS;
    let subType = props.settingSubType.LEGAL_PROTECTION
    if (props.userData && props.userData.role === 1) {
        type = props.settingTypes.OPERATIONS
        subType = props.settingSubType.COMMON_LEGAL_PROTECTION
    }
    let roleBasedDisabled = false;
    if (props.userData && props.userData.userId && props.userData.userRole !== "admin") {
        roleBasedDisabled = true
    }
    const userData = useSelector((state) => state.auth.user)
    const [createResponsibility, setResponsibility] = useState("false");
    const [focusedTextArea, setFocusedTextArea] = useState("");
    const [focusedDescriptionIndex, setFocusedDescriptionIndex] = useState(0);
    const [focusedTitleIndex, setFocusedTitleIndex] = useState(0);

    const createResponsibilityToggle = () => {
        setResponsibility(!createResponsibility);
        props.handleSetSettingForm(null, null, userData.role === 1 ? props.commonServicesTypes.COMMON_RESPONSIBILITY_DISCHARGE : props.commonServicesTypes.RESPONSIBILITY_DISCHARGE)
    };

    const [createDelivery, setDelivery] = useState("false");
    const createDeliveryToggle = () => {
        setDelivery(!createDelivery);
        props.handleSetSettingForm(null, null, userData.role === 1 ? props.commonServicesTypes.COMMON_CONFIRMATION_DELIVERY : props.commonServicesTypes.CONFIRMATION_DELIVERY)
    };

    const getInputSelection = (el) => {
        var start = 0,
            end = 0,
            normalizedValue,
            range,
            textInputRange,
            len,
            endRange;

        if (
            typeof el.selectionStart == "number" &&
            typeof el.selectionEnd == "number"
        ) {
            start = el.selectionStart;
            end = el.selectionEnd;
        } else {
            range = document.selection.createRange();

            if (range && range.parentElement() == el) {
                len = el.value.length;
                normalizedValue = el.value.replace(/\r\n/g, "\n");

                // Create a working TextRange that lives only in the input
                textInputRange = el.createTextRange();
                textInputRange.moveToBookmark(range.getBookmark());

                // Check if the start and end of the selection are at the very end
                // of the input, since moveStart/moveEnd doesn't return what we want
                // in those cases
                endRange = el.createTextRange();
                endRange.collapse(false);

                if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                    start = end = len;
                } else {
                    start = -textInputRange.moveStart("character", -len);
                    start += normalizedValue.slice(0, start).split("\n").length - 1;

                    if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                        end = len;
                    } else {
                        end = -textInputRange.moveEnd("character", -len);
                        end += normalizedValue.slice(0, end).split("\n").length - 1;
                    }
                }
            }
        }

        return {
            start: start,
            end: end,
        };
    };

    const offsetToRangeCharacterMove = (el, offset) => {
        return offset - (el.value.slice(0, offset).split("\r\n").length - 1);
    };

    const setSelection = (el, start, end) => {
        if (
            typeof el.selectionStart == "number" &&
            typeof el.selectionEnd == "number"
        ) {
            el.selectionStart = start;
            el.selectionEnd = end;
        } else if (typeof el.createTextRange != "undefined") {
            var range = el.createTextRange();
            var startCharMove = offsetToRangeCharacterMove(el, start);
            range.collapse(true);
            if (start == end) {
                range.move("character", startCharMove);
            } else {
                range.moveEnd("character", offsetToRangeCharacterMove(el, end));
                range.moveStart("character", startCharMove);
            }
            range.select();
        }
    };
    const insertTextAtCaret = (el, text) => {
        var pos = getInputSelection(el).end;
        var newPos = pos + text.length;
        var val = el.value;
        el.value = val.slice(0, pos) + text + val.slice(pos);
        setSelection(el, newPos, newPos);
    };

    const showText = (text) => {
        let regexRecipientName = /{{recipientName}}/g; // Johny
        text = text.replace(regexRecipientName, 'Johny');
        let regexDriverName = /{{driverName}}/g; // Sebastien Godbout
        text = text.replace(regexDriverName, 'Sebastien Godbout');
        let regexEta = /{{eta}}/g; // 20 mins
        text = text.replace(regexEta, '20 mins');
        let regexVehicleDescription = /{{vehicleDescription}}/g; // Black Color
        text = text.replace(regexVehicleDescription, '20 mins');
        let regexWorkerPhone = /{{workerPhone}}/g; // +12244673
        text = text.replace(regexWorkerPhone, '+12244673');
        let regexDestinationAddressShort = /{{destinationAddressShort}}/g; //
        text = text.replace(regexDestinationAddressShort, '');
        let regexDestinationAddressLong = /{{destinationAddressLong}}/g; //
        text = text.replace(regexDestinationAddressLong, '');
        let regexTrackLink = /{{trackLink}}/g; // track here
        text = text.replace(regexTrackLink, 'track here');
        let regexCreatorName = /{{creatorName}}/g; // Boutique Furniture Expert.
        text = text.replace(regexCreatorName, 'Boutique Furniture Expert');
        let regexMerchantName = /{{merchantName}}/g; // Boutique Furniture Expert.
        text = text.replace(regexMerchantName, 'Boutique Furniture Expert');
        let regexOrganizationName = /{{organizationName}}/g; // Boutique Furniture Expert.
        text = text.replace(regexOrganizationName, 'Boutique Furniture Expert');
        let regexEarliestTime = /{{earliestTime}}/g; // 6:35PM
        text = text.replace(regexEarliestTime, '6:35PM');
        let regexLatestTime = /{{latestTime}}/g; // 6:35PM
        text = text.replace(regexLatestTime, '6:35PM');
        let regexCompletedTime = /{{completedTime}}/g; //  6:35PM
        text = text.replace(regexCompletedTime, '6:35PM');

        let regexTodayDate = /{{todayDate}}/g; //  6:35PM
        text = text.replace(regexTodayDate, `${moment().format("ll")}`);
        return text;
    };

    // document.getElementById("insertButton").onclick = function () {
    //   var textarea = document.getElementById("ta");
    //   textarea.focus();
    //   insertTextAtCaret(textarea, "[INSERTED]");
    //   return false;
    // };

    const removeSection = (index) => {
        let newSections = JSON.parse(JSON.stringify(props.sections));
        newSections.splice(index, 1);
        props.setSections(newSections)
    }
    const enableTitle = (index) => {
        let newSections = JSON.parse(JSON.stringify(props.sections));
        newSections[index].titleDisabled = false;
        props.setSections(newSections)
    }

    const handleInsertTextButton = (text) => {
        let textarea = document.getElementById(focusedTextArea);
        textarea.focus();
        insertTextAtCaret(textarea, text);
        // textarea.onchange((e)=>{onChangeEditSmsHandler(e, focusedTextArea==="smsText"?"smsText": "emailText", editSmsData.key)})

        let data = document.getElementById(focusedTextArea).value;
        if (focusedTextArea.includes("description")) {
            handleEditDescription(data, focusedDescriptionIndex, true)
        }
        else {
            handleEditTitle(data, focusedTitleIndex, true)
        }
        return false;
    };

    // const onChangeEditSection = (e, subKey, key, forced) => {
    //     let title = "";
    //     let desctiption = "";

    //     let smsData = JSON.parse(JSON.stringify(editSmsData));
    //     if (subKey === "sms" || subKey === "email") {
    //       if (subKey === "sms") smsData.sms = e;
    //       if (subKey === "email") smsData.email = e;
    //       setEditSmsData(smsData);
    //     } else {
    //       if (forced) {
    //         if (subKey === "smsText") smsData.smsText = e;
    //         if (subKey === "emailText") smsData.emailText = e;
    //       } else {
    //         if (subKey === "smsText") smsData.smsText = e.target.value;
    //         if (subKey === "emailText") smsData.emailText = e.target.value;
    //       }
    //       setEditSmsData(smsData);
    //     }
    //   };

    const addSection = () => {
        let section = {
            title: "",
            description: "",
            titleDisabled: false,
            checked: false
        }
        props.setSections(prev => {
            return [...prev, section]
        })
    }

    const addSignature = () => {
        props.setSignature(true)
    }

    const handleEditDescription = (e, index, forced) => {
        let newSections = JSON.parse(JSON.stringify(props.sections))
        newSections[index].description = forced ? e : e.target.value;
        props.setSections(newSections)
    };

    const handleEditTitle = (e, index, forced) => {
        let newSections = JSON.parse(JSON.stringify(props.sections))
        newSections[index].title = forced ? e : e.target.value;
        props.setSections(newSections)
    };

    const onSubmitEditSmsHandler = (e, type) => {
        let sections = props.sections.map(i => {
            i.titleDisabled = true;
            return i
        })
        props.saveCommonServiceData(
            { sections: sections, signature: props.signature },
            type
        );
    };

    const [trimmedDataURL, setTrimmedDataURL] = useState()

    let sigPad = {}
    const clear = () => {
        sigPad.clear()
    }
    const trim = () => {
        setTrimmedDataURL(sigPad.getTrimmedCanvas()
            .toDataURL('image/png'))
    }

    const handleFeatureClick = (type) => {
        if (props.userData.role !== 1) {
            if ((!props.features || (props.features && !props.features[type])) && !roleBasedDisabled) {
                let pathName = "/Business/"
                if (type) {
                    pathName += type
                }
                props.history.push({
                    pathname: pathName
                })
            }
        }
    }


    return (<div className="st-oplist">
        <div className="st-Documents legal-protection-field">
            <div className="st-opheading">
                <h1>
                    Legal & POD <img src={legalicon} alt="Legal icon" />
                </h1>
            </div>
            <div className="st-oplistbox">
                <Form>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={3}>
                            <img src={dischargeicon} alt="Discharge icon" />
                            Responsibility discharge
                        </Form.Label>
                        <Col sm={9}>
                            <div className="legalpd-field" onClick={(e) => { handleFeatureClick("responsibiltyDischargeForm") }}>
                                {props.userData && props.userData.role !== 1 && <div className="toggle-switch">
                                    <Switch
                                        onChange={(e) => { props.handleChangeSetting(e, { key: [{ "responsibilityDischarge": e }], type: type, subType: subType }) }}
                                        checked={props.settingFormData.responsibilityDischarge}
                                        disabled={props.features && props.features.responsibiltyDischargeForm && !roleBasedDisabled ? false : true}
                                    />
                                </div>}
                                <div className="editd" onClick={(e) => { handleFeatureClick("responsibiltyDischargeForm") }}>
                                    <Button onClick={createResponsibilityToggle}
                                        disabled={((props.features && props.features.responsibiltyDischargeForm && !roleBasedDisabled) || (props.userData.role === 1)) ? false : true}>Edit document</Button>
                                </div>
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={3}>
                            <img src={signatureicon} alt="Signature icon" />
                            Confirmation delivery form
                        </Form.Label>
                        <Col sm={9}>
                            <div className="legalpd-field" onClick={(e) => { handleFeatureClick("deliveryConfirmationForm") }}>
                                {props.userData && props.userData.role !== 1 && <div className="toggle-switch">
                                    <Switch
                                        onChange={(e) => { props.handleChangeSetting(e, { key: [{ "confirmationDeliveryForm": e }], type: type, subType: subType }) }}
                                        checked={props.settingFormData.confirmationDeliveryForm}
                                        disabled={props.features && props.features.deliveryConfirmationForm && !roleBasedDisabled ? false : true}
                                    />
                                </div>}
                                <div className="editd" onClick={(e) => { handleFeatureClick("deliveryConfirmationForm") }}>
                                    <Button onClick={createDeliveryToggle}
                                        disabled={((props.features && props.features.deliveryConfirmationForm && !roleBasedDisabled) || (props.userData.role === 1)) ? false : true}>Edit document</Button>
                                </div>
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={3}>
                            <img src={cameraicon} alt="Camera icon" /> Photos Before delivery
                        </Form.Label>
                        <Col sm={9}>
                            <div className="legalpd-field" onClick={(e) => { handleFeatureClick("pictureAssessment") }}>
                                {props.userData && props.userData.role !== 1 && <div className="toggle-switch">
                                    <Switch
                                        onChange={(e) => { props.handleChangeSetting(e, { key: [{ "photosBeforeAndAfter": e }], type: type, subType: subType }) }}
                                        checked={props.settingFormData.photosBeforeAndAfter}
                                        disabled={props.features && props.features.pictureAssessment && !roleBasedDisabled ? false : true}
                                    />
                                </div>}
                                <div className="editd">
                                    {/* <Button>Edit document</Button> */}
                                </div>
                            </div>
                        </Col>
                    </Form.Group>
                </Form>
            </div>
            {/* Responsability popup start */}
            <div
                className={
                    createResponsibility
                        ? "opencustomer-popup"
                        : "opencustomer-popup createCustomer"
                }
            >
                <div className="addCustomer-popup">
                    <div className="addcustomer-slide map-view-popup">
                        <div className="addcustomer-field">
                            <div className="customer-import">
                                <Button
                                    className="createcustomer-close"
                                    onClick={createResponsibilityToggle}
                                >
                                    <img src={closeicon} alt="icon" />
                                </Button>
                            </div>
                        </div>

                        <div className="add-mapviewpopup">
                            <div className="map-view-detail">
                                <div className="customize-page">
                                    <h1>Responsability Discharge</h1>
                                    {props.sections.map((item, index) => {
                                        return (
                                            <div className="sms-Detail">
                                                <div className="editsms-heading">
                                                    <h1>
                                                        <Form>
                                                            <Form.Control
                                                                as="input"
                                                                id={`title${index}`}
                                                                value={item.title}
                                                                disabled={item.titleDisabled}
                                                                style={{
                                                                    background: "transparent",
                                                                    border: "none",
                                                                    color: "#ffffff",
                                                                    fontSize: "20px"
                                                                }}
                                                                type="text"
                                                                placeholder="Add Section Title"
                                                                onFocus={() => {
                                                                    setFocusedTextArea(`title${index}`);
                                                                    setFocusedTitleIndex(index)
                                                                }}
                                                                onChange={(e) => {
                                                                    handleEditTitle(
                                                                        e,
                                                                        index
                                                                    );
                                                                }}
                                                            // onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultTaskDuration": e.target.value }], type: type, subType: props.settingSubType.TASK_AND_MISSION }) }}
                                                            />
                                                        </Form>

                                                        <span className="editicon" onClick={() => enableTitle(index)}>
                                                            <MdEdit />
                                                        </span>
                                                        <span className="clearicon" onClick={() => removeSection(index)}>
                                                            <MdClear />
                                                        </span>
                                                    </h1>
                                                </div>
                                                <div className="detail-description p-15">
                                                    <Form>
                                                        <Form.Control
                                                            as="textarea"
                                                            id={`description${index}`}
                                                            value={item.description}
                                                            style={{
                                                                background: "transparent",
                                                                border: "none",
                                                                color: "#ffffff",
                                                                height: "100px",
                                                            }}
                                                            type="text"
                                                            placeholder="Add Section Desctiption"
                                                            onFocus={() => {
                                                                setFocusedTextArea(`description${index}`);
                                                                setFocusedDescriptionIndex(index)
                                                            }}
                                                            onChange={(e) => {
                                                                handleEditDescription(
                                                                    e,
                                                                    index
                                                                );
                                                            }}
                                                        // onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultTaskDuration": e.target.value }], type: type, subType: props.settingSubType.TASK_AND_MISSION }) }}
                                                        />
                                                    </Form>
                                                </div>
                                                <span className="count">60/500</span>
                                            </div>
                                        )
                                    })}

                                    <div className="add-section">
                                        <div className="add-signature" onClick={addSection}>
                                            <h3>Add section</h3>
                                            <div className="image-thumbnail">
                                                <Form.Control />
                                                <span>
                                                    <img src={Union} alt="icon" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="add-signature" onClick={addSignature}>
                                            <h3>Add signature</h3>
                                            <div className="image-thumbnail">
                                                <Form.Control />
                                                <span>
                                                    <img src={Union} alt="icon" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="editsms-tag">
                                        <span className="btn" onClick={() =>
                                            handleInsertTextButton("{{recipientName}}")
                                        }>Recipient name</span>
                                        <span className="btn" onClick={() =>
                                            handleInsertTextButton("{{driverName}}")
                                        }>Driver name</span>
                                        <span className="btn" onClick={() =>
                                            handleInsertTextButton("{{organizationName}}")
                                        }>Organization name</span>
                                        <span className="btn" onClick={() =>
                                            handleInsertTextButton("{{merchantName}}")
                                        }>Merchant Name</span>
                                        <span className="btn" onClick={() =>
                                            handleInsertTextButton("{{destinationAddressLong}}")
                                        }>Address(Long)</span>
                                    </div>
                                </div>

                                <div className="Map-layout">
                                    <div className="mapview-heading">
                                        <h1>What your customer will see</h1>
                                    </div>
                                    {props.sections.map((i => {
                                        return (<div className="editsms-detail">
                                            <div className="delivery-question-ans">
                                                <div className="ques-answer">
                                                    <h1>{showText(i.title)}</h1>
                                                    <div className="question-answer">
                                                        <div className="qs">
                                                            <div className="form-group">
                                                                <input type="checkbox" id="Acknowledgment" disabled={true} checked={i.checked} />
                                                                <label htmlFor="Acknowledgment" className="answer">
                                                                    <p>{showText(i.description)}</p>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    }))}
                                    {props.signature && <div className="signature-main-container">
                                        <div className="sig-container">
                                            <SignaturePad canvasProps={{ className: "sig-pad" }}
                                                ref={(ref) => { sigPad = ref }} />
                                        </div>
                                        {/* <div>
                                            <button onClick={clear}>
                                                Clear
                                            </button>
                                            <button onClick={trim}>
                                                Trim
                                            </button>
                                        </div> */}
                                        {/* {trimmedDataURL
                                            ? <img className="sig-image"
                                                src={trimmedDataURL} alt="signature" />
                                            : null} */}
                                    </div>}
                                    <Button className="savebtn" onClick={(e) => { createResponsibilityToggle(); onSubmitEditSmsHandler(e, userData.role === 1 ? props.commonServicesTypes.COMMON_RESPONSIBILITY_DISCHARGE : props.commonServicesTypes.RESPONSIBILITY_DISCHARGE) }
                                    }>Save</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Responsability popup end */}

            {/* delivery confirmation popup start */}
            <div
                className={
                    createDelivery
                        ? "opencustomer-popup"
                        : "opencustomer-popup createCustomer"
                }
            >
                <div className="addCustomer-popup">
                    <div className="addcustomer-slide map-view-popup">
                        <div className="addcustomer-field">
                            <div className="customer-import">
                                <Button
                                    className="createcustomer-close"
                                    onClick={createDeliveryToggle}
                                >
                                    <img src={closeicon} alt="icon" />
                                </Button>
                            </div>
                        </div>

                        <div className="add-mapviewpopup">
                            <div className="map-view-detail">
                                <div className="customize-page">
                                    <h1>Delivery Confirmation</h1>
                                    {props.sections.map((item, index) => {
                                        return (
                                            <div className="sms-Detail">
                                                <div className="editsms-heading">
                                                    <h1>
                                                        <Form>
                                                            <Form.Control
                                                                as="input"
                                                                id={`title${index}`}
                                                                value={item.title}
                                                                disabled={item.titleDisabled}
                                                                style={{
                                                                    background: "transparent",
                                                                    border: "none",
                                                                    color: "#ffffff",
                                                                    fontSize: "20px"
                                                                }}
                                                                type="text"
                                                                placeholder="Add Section Title"
                                                                onFocus={() => {
                                                                    setFocusedTextArea(`title${index}`);
                                                                    setFocusedTitleIndex(index)
                                                                }}
                                                                onChange={(e) => {
                                                                    handleEditTitle(
                                                                        e,
                                                                        index
                                                                    );
                                                                }}
                                                            // onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultTaskDuration": e.target.value }], type: type, subType: props.settingSubType.TASK_AND_MISSION }) }}
                                                            />
                                                        </Form>

                                                        <span className="editicon" onClick={() => enableTitle(index)}>
                                                            <MdEdit />
                                                        </span>
                                                        <span className="clearicon" onClick={() => removeSection(index)}>
                                                            <MdClear />
                                                        </span>
                                                    </h1>
                                                </div>
                                                <div className="detail-description p-15">
                                                    <Form>
                                                        <Form.Control
                                                            as="textarea"
                                                            id={`description${index}`}
                                                            value={item.description}
                                                            style={{
                                                                background: "transparent",
                                                                border: "none",
                                                                color: "#ffffff",
                                                                height: "100px",
                                                            }}
                                                            type="text"
                                                            placeholder="Add Section Desctiption"
                                                            onFocus={() => {
                                                                setFocusedTextArea(`description${index}`);
                                                                setFocusedDescriptionIndex(index)
                                                            }}
                                                            onChange={(e) => {
                                                                handleEditDescription(
                                                                    e,
                                                                    index
                                                                );
                                                            }}
                                                        // onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultTaskDuration": e.target.value }], type: type, subType: props.settingSubType.TASK_AND_MISSION }) }}
                                                        />
                                                    </Form>
                                                </div>
                                                <span className="count">60/500</span>
                                            </div>
                                        )
                                    })}

                                    <div className="add-section">
                                        <div className="add-signature" onClick={addSection}>
                                            <h3>Add section</h3>
                                            <div className="image-thumbnail">
                                                <Form.Control />
                                                <span>
                                                    <img src={Union} alt="icon" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="add-signature" onClick={addSignature}>
                                            <h3>Add signature</h3>
                                            <div className="image-thumbnail">
                                                <Form.Control />
                                                <span>
                                                    <img src={Union} alt="icon" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="editsms-tag">
                                        <span className="btn" onClick={() =>
                                            handleInsertTextButton("{{recipientName}}")
                                        }>Recipient name</span>
                                        <span className="btn" onClick={() =>
                                            handleInsertTextButton("{{driverName}}")
                                        }>Driver name</span>
                                        <span className="btn" onClick={() =>
                                            handleInsertTextButton("{{organizationName}}")
                                        }>Organization name</span>
                                        <span className="btn" onClick={() =>
                                            handleInsertTextButton("{{merchantName}}")
                                        }>Merchant Name</span>
                                        <span className="btn" onClick={() =>
                                            handleInsertTextButton("{{destinationAddressLong}}")
                                        }>Address(Long)</span>
                                    </div>
                                </div>

                                <div className="Map-layout">
                                    <div className="mapview-heading">
                                        <h1>What your customer will see</h1>
                                    </div>
                                    {props.sections.map((i => {
                                        return (<div className="editsms-detail">
                                            <div className="delivery-question-ans">
                                                <div className="ques-answer">
                                                    <h1>{showText(i.title)}</h1>
                                                    <div className="question-answer">
                                                        <div className="qs">
                                                            <span className="ans  active">Y</span>
                                                            <p className="answer">{showText(i.description)}</p>
                                                        </div>
                                                        <div className="qs">
                                                            <span className="ans">N</span>
                                                            <div className="answer">
                                                                <p></p>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    placeholder="add your comment"
                                                                    style={{ height: "100px" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    }))}
                                    {props.signature && <div className="signature-main-container">
                                        <div className="sig-container">
                                            <SignaturePad canvasProps={{ className: "sig-pad" }}
                                                ref={(ref) => { sigPad = ref }} />
                                        </div>
                                        {/* <div>
                                            <button onClick={clear}>
                                                Clear
                                            </button>
                                            <button onClick={trim}>
                                                Trim
                                            </button>
                                        </div> */}
                                        {/* {trimmedDataURL
                                            ? <img className="sig-image"
                                                src={trimmedDataURL} alt="signature" />
                                            : null} */}
                                    </div>}
                                    <Button
                                        className="savebtn"
                                        onClick={(e) => { createDeliveryToggle(); onSubmitEditSmsHandler(e, userData.role === 1 ? props.commonServicesTypes.COMMON_CONFIRMATION_DELIVERY : props.commonServicesTypes.CONFIRMATION_DELIVERY) }}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* delivery confirmation popup end */}

        </div>
    </div >)
}

export default LegalProtectionTab