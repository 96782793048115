const PerformanceTab = (props) => {
    let type = props.settingTypes.OPERATIONS;
    let subType = props.settingSubType.REGIONAL_SETTINGS
    if (props.userData && props.userData.role === 1) {
        type = props.settingTypes.OPERATIONS
        subType = props.settingSubType.COMMON_REGIONAL_SETTINGS
    }
    return (<></>)
}

export default PerformanceTab;