import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function SuperPrivateRoute(props) {
    const { component: Component, ...rest } = props;

    const render = props => {
        const user = JSON.parse(localStorage.getItem("user"))
        if (!user) {
            return <Redirect to={'/login'} />;
        }else if(user.role !== 1)
        {
            return <Redirect to={'/login'} />;
        }else{
            return <Component {...props} />;
        }

        
    };

    return <Route {...rest} render={render} />;
}

export default SuperPrivateRoute;

