import { MdOutlineAllInbox, MdOutlineClose } from "react-icons/md"
import { formatAMPM, renderCustomerName, timeZoneHeader } from "../../../helpers/helperFunctions"
import TaskRightSideDetails from "../../SupervisePlanner/components/Rightsidebar/RightSideTaskDetails"
import "./createInvoice.scss"
import moment from "moment"
import { IoMdTime } from "react-icons/io"
import bin from "../../../assets/image/bin-white.png";
import downicon from "../../../assets/image/down-icon-white.png";
import { Formik, Form as FormF, Field, ErrorMessage, FieldArray } from "formik"
import { Button, Form } from "react-bootstrap"
import Select, { components } from 'react-select';
import { useRef } from "react"
import { useState } from "react"
import { useEffect } from "react"
import DatePicker from "react-date-picker"
import swalIcon from "../../../assets/image/swalIcon.png"
import momentTz from "moment-timezone"
import { useDispatch, useSelector } from "react-redux"
import { DELETE_COMMON_SERVICES_API, SAVE_COMMON_SERVICES_API } from "../../../redux/auth/actionTypes"
import * as Yup from "yup"
import { CREATE_INVOICE_API, EDIT_INVOICE_API } from "../../../redux/invoicing/actionTypes"
import { errorAlert, successAlert } from "../../../services/toast.service"
import Swal from "sweetalert2";
const timezone = { ...timeZoneHeader() }

const taxSchema = Yup.object().shape({
    name: Yup.string().required("Group Name Required")
});

const initialValuesTermForm = {
    name: "",
    days: "0"
}

const InvoiceForm = (props) => {
    const dispatch = useDispatch();
    const { setInvoiceForm, rightsidebarprops, invoiceFormProps, setTaskValues } = props;
    const { customerList, pageCount, pageLimit } = useSelector((state) => state.invoicing)

    const { commonServices } = useSelector((state) => state.authSaga)
    const [allTerms, setAllTerms] = useState([])
    const [buttonOptions, setButtonOptions] = useState(false)
    const [addNewTerm, setAddNewTerm] = useState(false)
    const [topTermForm, setTopTermForm] = useState(0)
    const [TermForm, setTermForm] = useState(0)

    const { teamList } = useSelector((state) => state.team)
    let { agentList } = useSelector((state) => state.user);

    const ValueContainer = ({ children, ...props }) => {
        return (
            components.ValueContainer && (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            )
        );
    };

    // useEffect(() => {
    //     return () => {
    //         // handleCancel()
    //         window.addEventListener('beforeunload', function (e) {
    //             // Cancel the event
    //             e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    //             // Chrome requires returnValue to be set
    //             e.returnValue = '';
    //             handleCancel()
    //         });
    //     }
    // }, [])

    const CustomMenuList = ({ selectProps, ...props }) => {
        const { onTeamInputChange, addTeamInputValue, onMenuInputFocus, addInput, setAddInput } = selectProps;
        // Copied from source
        const ariaAttributes = {
            "aria-autocomplete": "list",
            "aria-label": selectProps["aria-label"],
            "aria-labelledby": selectProps["aria-labelledby"]
        };

        return (
            <div>
                <div
                    className="MenuButton"
                    onClick={(e) => {
                        rightsidebarprops.setRightSidebarOpen(true);
                        rightsidebarprops.rightSidebarOpenRef.current = true;
                    }}
                >
                    {/* <img style={{ width: "18px" }} src={"Union"} alt="icon" /> */}
                    <span className={"AddTeamSpan"}>+ Add new</span>
                </div>
                <components.MenuList {...props} selectProps={selectProps} />
            </div >
        );
    };


    const CustomTermMenuList = ({ selectProps, ...props }) => {
        const { onTeamInputChange, addTeamInputValue, onMenuInputFocus, addInput, setAddInput } = selectProps;
        // Copied from source
        const ariaAttributes = {
            "aria-autocomplete": "list",
            "aria-label": selectProps["aria-label"],
            "aria-labelledby": selectProps["aria-labelledby"]
        };

        return (
            <div>
                <div
                    className="MenuButton"
                    onClick={(e) => {
                        setTopTermForm(e.clientY)
                        setAddNewTerm(true)
                    }}
                >
                    {/* <img style={{ width: "18px" }} src={"Union"} alt="icon" /> */}
                    <span className={"AddTeamSpan"}>+ Add new</span>
                </div>
                <components.MenuList {...props} selectProps={selectProps} />
            </div >
        );
    };

    const validate = (values, props /* only available when using withFormik */) => {
        const errors = {};
        if (!values.customerId) {
            errors.customerId = 'Please select valid customer';
        }
        else if (!values.customerEmail) {
            errors.customerEmail = 'Must fill customer email';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.customerEmail)) {
            errors.email = 'Invalid email address';
        }
        else if (!values.termId) {
            errors.termId = 'Select valid term';
        }
        //...
        console.log("errors", errors, values)
        return errors;
    };
    const handleSubmitTaxForm = (data) => {
        let dataForApi = {
            type: "TERMS",
            ...data
        };
        let dataApi = {
            payload: dataForApi,
            // resCall: (res) => { handleSaveCommonServiceResponse(res) }
        }
        dispatch({ type: SAVE_COMMON_SERVICES_API, data: dataApi })
    }

    const CustomSelectField = ({ field, colors, form, ...props }) => {
        const containerRef = useRef(null);
        const [isFocused, setIsFocused] = useState(false);
        const [inputValue, setInputValue] = useState("");
        const [addInput, setAddInput] = useState(false);

        const onDomClick = (e) => {
            let menu = containerRef.current.querySelector(".select__menu");
            if (
                !containerRef.current.contains(e.target) ||
                !menu ||
                !menu.contains(e.target)
            ) {
                setIsFocused(false);
                setInputValue("");
            }
        };

        useEffect(() => {
            document.addEventListener("mousedown", onDomClick);
            return () => {
                document.removeEventListener("mousedown", onDomClick);
            };
        }, []);

        return (
            <div ref={containerRef}>
                <Select
                    className="react-tax-select-container"
                    classNamePrefix="react-tax-select"
                    closeMenuOnSelect={true}
                    formProps={{ form }}
                    defaultValue={customerList[0]}
                    value={form.values["customer"]}
                    placeholder="Select customer"
                    // isMulti
                    menuIsOpen
                    autoFocus={false}
                    isSearchable={false}
                    options={getOptions()}
                    getOptionLabel={(option) => `${option.displayName}`}
                    getOptionValue={(option) => option.id}
                    onChange={(newValue, actionMeta) => {
                        const { action, option, removedValue } = actionMeta;
                        form.setFieldValue("customerId", newValue.id)
                        form.setFieldValue("customerEmail", newValue.email)
                        form.setFieldValue("customer", newValue)
                        form.setFieldValue("taxes", newValue.tax?.taxes)
                        setInvoiceForm((prev) => {
                            return {
                                ...prev,
                                customer: newValue,
                                taxes: newValue.tax?.taxes
                            }
                        })
                        setTaskValues((prev) => {
                            return {
                                ...prev,
                                customer: newValue
                            }
                        })
                    }}
                    components={{
                        MenuList: CustomMenuList,
                        ValueContainer: ValueContainer,
                        Option: CustomSelectOption
                    }}
                    {...{
                        menuIsOpen: isFocused || undefined,
                        isFocused: isFocused || undefined
                    }}
                />
            </div>
        );
    };


    const CustomTermSelectField = ({ field, colors, form, ...props }) => {
        const containerRef = useRef(null);
        const [isFocused, setIsFocused] = useState(false);
        const [inputValue, setInputValue] = useState("");
        const [addInput, setAddInput] = useState(false);

        const onDomClick = (e) => {
            let menu = containerRef.current.querySelector(".select__menu");
            if (
                !containerRef.current.contains(e.target) ||
                !menu ||
                !menu.contains(e.target)
            ) {
                setIsFocused(false);
                setInputValue("");
            }
        };

        useEffect(() => {
            document.addEventListener("mousedown", onDomClick);
            return () => {
                document.removeEventListener("mousedown", onDomClick);
            };
        }, []);

        return (
            <div ref={containerRef}>
                <Select
                    className="react-tax-select-container"
                    classNamePrefix="react-tax-select"
                    closeMenuOnSelect={true}
                    formProps={{ form }}
                    // defaultValue={allTerms[0]}
                    value={form.values["terms"]}
                    placeholder="Select term"
                    // isMulti
                    menuIsOpen
                    autoFocus={false}
                    isSearchable={false}
                    options={getTermOptions()}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => option.id}
                    onChange={(newValue, actionMeta) => {
                        const { action, option, removedValue } = actionMeta;
                        form.setFieldValue("termId", newValue.id);
                        form.setFieldValue("terms", newValue);
                        let date = moment(form.values.invoiceDate).add(Number(newValue.days), "day")
                        form.setFieldValue("dueDate", date);
                        setInvoiceForm((prev) => {
                            return {
                                ...prev,
                                dueDate: date
                            }
                        })
                    }}
                    components={{
                        MenuList: CustomTermMenuList,
                        ValueContainer: ValueContainer,
                        Option: CustomTermSelectOption
                    }}
                    {...{
                        menuIsOpen: isFocused || undefined,
                        isFocused: isFocused || undefined
                    }}
                />
            </div>
        );
    };

    const handleDeleteCommonService = (id) => {
        let dataApi = {
            payload: {
                commonServiceId: id
            }
        }
        dispatch({ type: DELETE_COMMON_SERVICES_API, data: dataApi })
    }

    const CustomSelectOption = props => (
        <components.Option {...props}>
            <div>
                {/* {props.data.displayName}{props.data.id !== "all" && <img onClick={(e) => { e.stopPropagation(); }} src={bin} style={{ width: "18px", float: "right", cursor: "pointer" }} alt="icon" />} */}
                {props.data.displayName}{props.data.id !== "all" && <span style={{ float: "right", color: "#6f767e" }}>
                    {props.data.isMerchant ? "Merchant" : "Individual"}
                </span>}
            </div>
        </components.Option>
    )


    const CustomTermSelectOption = props => (
        <components.Option {...props}>
            <div>
                {props.data.name}{props.data.id !== "all" && <img onClick={(e) => { e.stopPropagation(); handleDeleteCommonService(props.data.id) }} src={bin} style={{ width: "18px", float: "right", cursor: "pointer" }} alt="icon" />}
            </div>
        </components.Option>
    )
    useEffect(() => {
        let taxes = [];
        taxes = commonServices?.filter(cm => cm.type === "TERMS")
        setAllTerms(taxes)
        // let newFormData = { ...props.invoiceForm }
        // newFormData.termId = taxes[0]?.id;
        // newFormData.terms = taxes[0];
        // let date = moment(newFormData.invoiceDate).add(Number(taxes[0].days), "day")
        // newFormData.dueDate = date;
        // props.setInvoiceForm(newFormData)
    }, [commonServices])

    const getOptions = () => [...customerList];
    const getTermOptions = () => [...allTerms];

    let taxFormRef = useRef();

    const CreateTaxForm = () => {
        return (
            <Formik
                initialValues={initialValuesTermForm}
                validationSchema={taxSchema}
                // validate={taxValidate}
                onSubmit={handleSubmitTaxForm}
                enableReinitialize={true}
            >{({
                setFieldValue,
                setFieldTouched,
                values,
                resetForm,
                errors,
                touched,
            }) => (
                <div className="tax-form" style={{ top: `${topTermForm}px` }} ref={taxFormRef}>
                    <FormF>
                        <div className="sub-heading">
                            <span className="text">New term</span>
                        </div>
                        <Form.Group className="input-invoice-container">
                            <label>Name</label>
                            <Field
                                className="input-area"
                                placeholder=""
                                name={"name"}
                                maxLength="35"
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                aria-label="Group Name"
                            />
                            {errors.name && touched.name ? (
                                <div>{errors.name}</div>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="input-percentage-container">
                            <Field
                                className="input-area"
                                placeholder=""
                                name={`days`}
                                maxLength="35"
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                aria-label="Days"
                            />
                            <label>days</label>
                            <ErrorMessage className="error-message-invoice"
                                name={`days`}
                                component="div"
                            />
                        </Form.Group>
                        <div className="buttons">
                            <Button onClick={() => {
                                setTermForm(initialValuesTermForm)
                                setAddNewTerm(false)
                            }}>
                                Cancel
                            </Button>
                            <Button type="submit" onClick={(e) => { }}>
                                {"Create"}
                            </Button>
                        </div>
                    </FormF>
                </div>
            )}
            </Formik >
        );
    }

    const getAllValue = (fieldName) => {
        let name = "All Teams";
        if (fieldName === "agent") {
            name = "All Agents"
        }
        else if (fieldName === "status") {
            name = "All Status"
        }
        return { id: "all", name: name }
    }

    const MultiCustomSelectField = ({ field, colors, form, ...propss }) => {
        let fieldToUpdate = {
            ids: "teamIds",
            values: "teamValues"
        }
        let values = form.values.teamValues;
        if (field.name === "agent") {
            values = form.values.agentValues;
            fieldToUpdate = {
                values: "agentValues",
                ids: "agentIds"
            }
        }
        else if (field.name === "status") {
            values = form.values.taskStatusValues
            fieldToUpdate = {
                ids: "taskStatus",
                values: "taskStatusValues"
            }
        }
        return (
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                closeMenuOnSelect={false}
                defaultValue={values?.length ? values : getAllValue(field.name)}
                // value={form.values.values && form.values.values.length ? form.values.values : selectAllOption}
                placeholder="Select Team List"
                isMulti
                isClearable={false}
                autoFocus={false}
                isSearchable={false}
                options={getMultiOptions(field.name)}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option.id}
                onChange={(newValue, actionMeta) => { onChangeValue(newValue, actionMeta, form, field.name, fieldToUpdate) }}
            // components={{ ValueContainer }}
            />
        )
    };


    const onChangeValue = (newValue, actionMeta, form, fieldName, fieldToUpdate) => {
        const { action, option, removedValue } = actionMeta;
        let list = teamList.map(i => {
            return {
                id: i.id,
                name: i.teamName
            }
        })
        if (fieldName === "agent") {
            list = agentList.map(i => {
                return {
                    id: i.id,
                    name: `${i.firstName} ${i.lastName ? i.lastName : ""}`
                }
            })
        }
        else if (fieldName === "status") {
            list = allStatusList()
        }
        if (action === "select-option" && option.id === getAllValue(fieldName).id) {
            form.setFieldValue(fieldToUpdate.values, list);
            form.setFieldValue(fieldToUpdate.ids, list.map(i => i.id));
            setTaskValues((prev) => {
                return {
                    ...prev,
                    [fieldToUpdate.ids]: newValue
                }
            })
        }
        else if (action === "clear") {
            form.setFieldValue(fieldToUpdate.values, []);
            form.setFieldValue(fieldToUpdate.ids, []);
            setTaskValues((prev) => {
                return {
                    ...prev,
                    [fieldToUpdate.ids]: []
                }
            })
        }
        else {
            let findAllSelectedValue = newValue.findIndex(i => i.id === getAllValue(fieldName).id)
            if (findAllSelectedValue > -1) {
                newValue.splice(findAllSelectedValue, 1)
            }
            if (newValue.length) {
                form.setFieldValue(fieldToUpdate.values, newValue);
                form.setFieldValue(fieldToUpdate.ids, newValue.map(i => i.id));
                setTaskValues((prev) => {
                    return {
                        ...prev,
                        [fieldToUpdate.ids]: newValue.map(i => i.id)
                    }
                })
            }
            else {
                form.setFieldValue(fieldToUpdate.values, []);
                form.setFieldValue(fieldToUpdate.ids, list.map(i => i.id));
                setTaskValues((prev) => {
                    return {
                        ...prev,
                        [fieldToUpdate.ids]: list.map(i => i.id)
                    }
                })
            }
        }
    }

    const allStatusList = () => {
        return [
            {
                name: "Unassigned",
                id: "unassigned",
            },
            {
                name: "Assigned",
                id: "assigned",
            },
            {
                name: "OnGoing",
                id: "onGoing",
            },
            {
                name: "Delayed",
                id: "delayed",
            },
            {
                name: "Completed",
                id: "completed",
            },
            {
                name: "Failed",
                id: "failed",
            },
        ];
    }

    const getMultiOptions = (fieldName) => {
        let values = teamList.map(i => {
            return {
                id: i.id,
                name: i.teamName
            }
        });
        if (fieldName === "agent") {
            values = agentList.map(i => {
                return {
                    id: i.id,
                    name: `${i.firstName} ${i.lastName ? i.lastName : ""}`
                }
            })
        }
        else if (fieldName === "status") {
            values = allStatusList()
        }
        return [getAllValue(fieldName), ...values]
    };

    const handleSubmit = (formValues, { resetForm }) => {
        formValues.terms = {
            days: formValues.terms.days,
            status: formValues.terms.status,
            type: formValues.terms.type,
            name: formValues.terms.name,
            id: formValues.terms.id
        }
        let apiData = {
            services: props.services,
            invoiceNumber: formValues.invoiceNumber,
            invoiceCustomerId: formValues.customerId,
            customerEmail: formValues.customerEmail,
            teamIds: formValues.teamIds,
            agentIds: formValues.agentIds,
            taskStatus: formValues.taskStatus,
            termId: formValues.termId,
            terms: formValues.terms,
            invoiceDate: momentTz(formValues.invoiceDate).tz(timezone.timezone).startOf("D")._d,
            dueDate: momentTz(formValues.dueDate).tz(timezone.timezone).startOf("D")._d,
            serviceStartDate: momentTz(formValues.serviceStartDate).tz(timezone.timezone).startOf("D")._d,
            serviceEndDate: momentTz(formValues.serviceEndDate).tz(timezone.timezone).startOf("D")._d,
        }
        if (props.invoiceForm.invoiceId) {
            apiData.invoiceId = props.invoiceForm.invoiceId
        }
        let apiType = CREATE_INVOICE_API;
        if (props.buttonText === "Edit") {
            apiType = EDIT_INVOICE_API
        }
        apiData.discount = props.discount;
        apiData.amount = props.calculateValues().amount;
        apiData.subTotal = props.calculateValues().subTotal;
        apiData.taxes = props.calculateValues().taxes;
        apiData.subTotalAfterDiscount = props.calculateValues().subTotalAfterDiscount;
        if (props.buttonText === "Create and send") {
            apiData.sendEmail = true
        }
        dispatch({
            type: apiType,
            data: {
                payload: apiData,
                resCall: (res) => {
                    if (!res.success) {
                        errorAlert(res.message || "Something went wrong")
                    }
                    else {
                        props.setButtonText("Create and close");
                        if (props.buttonText !== "Create and new") props.setCreateInvoice(false);
                        if (props.buttonText === "Create and send") {
                            successAlert(`${formValues.invoiceNumber} sent successfully`)
                        }
                        else if(props.buttonText==="Create and close" || props.buttonText==="Edit"){
                            successAlert(`${formValues.invoiceNumber} saved successfully`)
                        }
                        props.initialValues.invoiceNumber = `DRAFT-00${props.invoiceForm.invoiceCounter + 1}`;
                        props.initialValues.invoiceCounter = props.invoiceForm.invoiceCounter + 1;
                        props.setInvoiceForm(props.initialValues);
                        resetForm()
                    }
                },
                sortBy: props.sortBy,
                pageLimit: pageLimit,
                pageCount: pageCount,
                currentPage: props.currentPage
            }
        })
    }

    const handleCancel = (resetForm) => {
        Swal.fire({
            title: "Do you want to leave without saving?",
            text: "If you leave without saving, this invoice will be lost",
            // iconHtml: `<img src="${IoMdTime}">`,
            imageUrl: swalIcon,
            showCancelButton: true,
            color: "#000000",
            background: "#ffffff",
            confirmButtonColor: "#7066E0",
            cancelButtonColor: "#7066E0",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                props.setCreateInvoice(false);
                props.setButtonText("Create");
                resetForm()
                props.setInvoiceForm(props?.invoiceForm)
            }
        })

    }
    return (
        <div className="invoice-form-container">
            <Formik
                initialValues={props?.invoiceForm}
                // validationSchema={validationSchema}
                validate={validate}
                onSubmit={handleSubmit}
                enableReinitialize={false}
            >{({
                setFieldValue,
                setFieldTouched,
                values,
                resetForm,
                errors,
                touched,
            }) => (
                <FormF>
                    <div className="input-container">
                        <label>Customer</label>
                        <Field
                            className="form-control"
                            name="customer"
                            // as="select"
                            component={CustomSelectField}
                        >
                        </Field>
                        <ErrorMessage className="error-message-invoice"
                            name="customerId"
                            component="div"
                        />
                    </div>
                    <Form.Group className="input-container">
                        <label>Customer email</label>
                        <Field
                            className="input-area"
                            placeholder=""
                            name="customerEmail"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="Customer email"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="customerEmail"
                            component="div"
                        />
                    </Form.Group>
                    <Form.Group className="input-container">
                        <label>Invoice number</label>
                        <Field
                            className="input-area"
                            placeholder=""
                            name="invoiceNumber"
                            maxLength="35"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="Invoice Number"
                            onBlur={e => {
                                setInvoiceForm((prev) => {
                                    return {
                                        ...prev,
                                        invoiceNumber: e.target.value
                                    }
                                })
                            }}
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="invoiceNumber"
                            component="div"
                        />
                    </Form.Group>
                    <div className="input-container">
                        <label>Terms</label>
                        <Field
                            className="form-control"
                            name="terms"
                            // as="select"
                            component={CustomTermSelectField}
                        >
                        </Field>
                        <>{errors.termId ? (
                            <div className="error-message-invoice">{errors.termId}</div>
                        ) : null}</>
                    </div>
                    <div className="two-inputs">
                        <div className="input-container">
                            <label>Invoice date</label>
                            {/* <div className="input-area"> */}
                            <DatePicker
                                dateFormat="MM/dd/yyyy"
                                name={"invoiceDate"}
                                value={values["invoiceDate"] ? new Date(values["invoiceDate"]) : ""}
                                onChange={(e) => {
                                    setFieldValue("invoiceDate", e);
                                }}
                                selectsEnd
                            />
                        </div>
                        <div className="input-container">
                            <label>Due date</label>
                            {/* <div className="input-area"> */}
                            <DatePicker
                                dateFormat="MM/dd/yyyy"
                                name={"dueDate"}
                                value={values["dueDate"] ? new Date(values["dueDate"]) : ""}
                                onChange={(e) => {
                                    setFieldValue("dueDate", e);
                                }}
                                selectsEnd
                            />
                        </div>
                    </div>
                    <div className="two-inputs" style={{ marginTop: "50px" }}>
                        <div className="input-container">
                            <label>Service date between</label>
                            {/* <div className="input-area"> */}
                            <DatePicker
                                dateFormat="MM/dd/yyyy"
                                name={"serviceStartDate"}
                                value={values["serviceStartDate"] ? new Date(values["serviceStartDate"]) : ""}
                                onChange={(e) => {
                                    setFieldValue("serviceStartDate", e);
                                    setTaskValues((prev) => {
                                        return {
                                            ...prev,
                                            serviceStartDate: e
                                        }
                                    })
                                }}
                                selectsEnd
                            />
                        </div>
                        <div className="input-container">
                            <label>And</label>
                            {/* <div className="input-area"> */}
                            <DatePicker
                                dateFormat="MM/dd/yyyy"
                                name={"serviceEndDate"}
                                value={values["serviceEndDate"] ? new Date(values["serviceEndDate"]) : ""}
                                onChange={(e) => {
                                    setFieldValue("serviceEndDate", e);
                                    setTaskValues((prev) => {
                                        return {
                                            ...prev,
                                            serviceEndDate: e
                                        }
                                    })
                                }}
                                selectsEnd
                            />
                        </div>
                    </div>
                    <div className="two-inputs">
                        <div className="input-container">
                            <label>Team</label>
                            <Field
                                // className="form-control"
                                name="team"
                                component={MultiCustomSelectField}
                            >
                            </Field>
                        </div>
                        <div className="input-container">
                            <label>Agent</label>
                            <Field
                                // className="form-control"
                                name="agent"
                                component={MultiCustomSelectField}
                            >
                            </Field>
                        </div>
                    </div>
                    <div className="input-container single-input-container">
                        <label>Task status</label>
                        <Field
                            // className="form-control"
                            name="status"
                            component={MultiCustomSelectField}
                        >
                        </Field>
                    </div>
                    <div className="buttons">
                        <Button onClick={() => {
                            handleCancel(resetForm)
                        }}>
                            Cancel
                        </Button>
                        <div className="submit-dd-button">
                            <Button type='submit'>
                                {props.buttonText}
                            </Button>
                            {props.buttonText !=="Edit" && <img onClick={() => {
                                setButtonOptions(true)
                            }} src={downicon} alt={"downicon"} />}
                        </div>
                    </div>
                    {buttonOptions && <div className="button-option-container">
                        <div className="button-options">
                            <div className="option-text" onClick={() => {
                                setButtonOptions(false)
                                props.setButtonText("Create and close")
                            }}>
                                <span>Create and close</span>
                            </div>
                            <div className="option-text" onClick={() => {
                                setButtonOptions(false)
                                props.setButtonText("Create and new")
                            }}>
                                <span>Create and new</span>
                            </div>
                            <div className="option-text" onClick={() => {
                                setButtonOptions(false)
                                props.setButtonText("Create and send")
                            }}>
                                <span>Create and send</span>
                            </div>
                        </div></div>}
                </FormF>
            )}
            </Formik>
            {addNewTerm && <CreateTaxForm />}
        </div>
    );
}


export default InvoiceForm