import React from "react";
import {
  Card,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import Switch from "react-switch";

const OperationCommunicationsTab = (props) => {
  let type = props.settingTypes.OPERATIONS;
  let subType = props.settingSubType.COMMUNICATIONS
  if (props.userData && props.userData.role === 1) {
    type = props.settingTypes.OPERATIONS
    subType = props.settingSubType.COMMON_COMMUNICATIONS
  }
  let roleBasedDisabled = false;
  if (props.userData && props.userData.userId && props.userData.userRole !== "admin") {
    roleBasedDisabled = true
  }
  const [isShown, setIsShown] = React.useState(true);
  return (<div className="st-oplist">
    <div className="st-Documents Communications-field">
      <div className="st-opheading">
        <h1>Notifications</h1>
        <p>Communication with Users</p>
      </div>

      <div className="cmfield">
        <div className="st-opheading">
          <h1>Delivery</h1>
        </div>
        <div className="st-oplistbox">
          <Form>
            <Form.Group as={Row} className="mb-input">
              <Form.Label column sm={4}>
                Allow Agents to initiate message with
                users
              </Form.Label>
              <Col sm={8}>
                <div className="legalpd-field">
                  <div className="toggle-switch">
                    <Switch
                      disabled
                      onChange={(e) => { props.handleChangeSetting(e, { key: [{ "deliveryAgentToInitiateMessage": e }], type: type, subType: subType }) }}
                      checked={props.settingFormData.deliveryAgentToInitiateMessage}
                    />
                  </div>
                  {isShown && (
                    <div className="editd">
                      <span style={{ color: "#ffffff" }}>
                        Coming Soon...
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-input">
              <Form.Label column sm={4}>
                Anonymize Agent phone number
              </Form.Label>
              <Col sm={8}>
                <div className="legalpd-field">
                  <div className="toggle-switch">
                    <Switch
                      disabled
                      onChange={(e) => { props.handleChangeSetting(e, { key: [{ "deliveryAnonymizeAgentPhoneNumber": e }], type: type, subType: subType }) }}
                      checked={props.settingFormData.deliveryAnonymizeAgentPhoneNumber}
                    />
                  </div>
                  {isShown && (
                    <div className="editd">
                      <span style={{ color: "#ffffff" }}>
                        Coming Soon...
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            </Form.Group>
          </Form>
        </div>
      </div>
      {/* <div className="cmfield">
    <div className="st-opheading">
      <h1>Installation</h1>
    </div>
    <div className="st-oplistbox">
      <Form>
        <Form.Group as={Row} className="mb-input">
          <Form.Label column sm={4}>
            Allow Agents to initiate message with
            users
          </Form.Label>
          <Col sm={8}>
            <div className="toggle-switch">
              <Form.Control
                type="checkbox"
                id="Responsibility"
                name="Responsibility"
                value="8"
              />
              <label for="Responsibility">
                <span className="toggle-track"></span>
              </label>
            </div>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-input">
          <Form.Label column sm={4}>
            Anonymize Agent phone number
          </Form.Label>
          <Col sm={8}>
            <div className="toggle-switch">
              <Form.Control
                type="checkbox"
                id="Confirmation"
                name="Confirmation"
                value="8"
              />
              <label for="Confirmation">
                <span className="toggle-track"></span>
              </label>
            </div>
          </Col>
        </Form.Group>
      </Form>
    </div>
  </div> */}
    </div>
  </div>)
}

export default OperationCommunicationsTab
