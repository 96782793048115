import { MdOutlineAllInbox, MdOutlineClose } from "react-icons/md"
import { formatAMPM, renderCustomerName, timeZoneHeader } from "../../../helpers/helperFunctions"
import TaskRightSideDetails from "../../SupervisePlanner/components/Rightsidebar/RightSideTaskDetails"
import "./rightsidebar.scss"
import moment from "moment"
import { IoMdTime } from "react-icons/io"
import CreateCustomer from "./createCustomer"
const timezone = { ...timeZoneHeader() }

const RightSideBar = (props) => {
    return (<div className="right-sidebar-customer-container">
        {!props.createTask && <div className="right-side-bar-wrapper">
            {<>
                <div className="top">
                    <div className="close-btn">
                        <MdOutlineClose className="icon" onClick={() => { props.setRightSidebarOpen(false); props.rightSidebarOpenRef.current = false }} />
                    </div>
                </div>
                {props.customerPage && <div className="bottom">
                    <CreateCustomer windowSize={props.windowSize} setButtonText={props.setButtonText} buttonText={props.buttonText} rightsidebarprops={{...props}} />
                </div>}
            </>}
        </div>}
    </div>)
}

export default RightSideBar