import React from "react";
import righticon from "../../assets/image/righticon.png";
import lefticon from "../../assets/image/lefticon.png";
import searchicon from "../../assets/image/searchicon.png";
import momentTz from "moment-timezone"
import { Button, Form, FormControl } from "react-bootstrap";
import { useState } from "react";

const TopBar = (props) => {
    let [searchText, setSearchText] = useState("");
    let dateText = () => (<span>
        {`${momentTz(props.date.startDate).format("LL")} - ${momentTz(props.date.endDate).format("LL")}`}
    </span>)
    let leftClickHandler = () => {
        props.setDate({
            startDate: momentTz(props.date.startDate).subtract(1, "W").startOf("W").format("LL"),
            endDate: momentTz(props.date.startDate).subtract(1, "W").endOf("W").format("LL")
        })
    }
    let rightClickHandler = () => {
        props.setDate({
            startDate: momentTz(props.date.startDate).add(1, "W").startOf("W").format("LL"),
            endDate: momentTz(props.date.startDate).add(1, "W").endOf("W").format("LL")
        })
    }
    return (
        <div className="topbar-container">
            <div className="button-container">
                <div className="buttons" onClick={leftClickHandler}>
                    <img src={lefticon} alt={"left"} />
                </div>
                <div className="buttons" onClick={rightClickHandler}>
                    <img src={righticon} alt={"right"} />
                </div>
            </div>
            <div className="main-container">
                <div className="date-container">
                    <span>
                        {dateText()}
                    </span>
                </div>
                {/* <div className="search-box">
                    <div className="searchbar">
                        <Form className="d-flex">
                            <FormControl
                                type="search"
                                onChange={(e) => setSearchText(e.target.value)}
                                placeholder="Search or type a name"
                                className="me-2"
                                aria-label="Search"
                            />
                            <Button className="searchicon">
                                <img src={searchicon} alt="searchicon" />
                            </Button>
                        </Form>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default TopBar