import React from 'react';
import { Route, Redirect } from 'react-router-dom';




function PublicRoute(props) {
    const { component: Component, restricted = false, ...rest } = props;

    const render = props => {
        const user = JSON.parse(localStorage.getItem("user"))
        if (user && user.token && restricted) {
            return <Redirect to={'/Dashboard'} />;
        }

        return <Component {...props} />;
    };

    return <Route {...rest} render={render} />;
}

export default PublicRoute;

