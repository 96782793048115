import "./MapContextMenu.scss";

const MapContextMenu = (props) => {
    return (<div className="context-menu-container" ref={props.contextMenuDivRef}>
        {props.contextMenu.menu?.map((item, index) => {
            if (!item.hideContextMenu) {
                return (<div className="menu-item-container" key={index}><div className="menu-item">
                    <p onClick={() => { item.clickMethod({ selectedTask: props.selectedTasks, selectedM: props.selectedMissions, selectedMissionsRef: props.selectedMissionsRef?.current }) }}>{item.menuItem.en}</p>
                </div></div>)
            }
            return null
        })}
    </div>)
}

export default MapContextMenu