export const CREATE_USER_API = "CREATE_USER_API";
export const EDIT_USER_API = "EDIT_USER_API";
export const GET_USER_API = "GET_USER_API";
export const GET_MULTI_TYPE_USER_API = "GET_MULTI_TYPE_USER_API";
export const GET_USER_COUNT_API = "GET_USER_COUNT_API";
export const DELETE_USER_API = "DELETE_USER_API";
export const DELETE_MANY_USER_API = "DELETE_MANY_USER_API";
export const BLOCK_MANY_USER_API = "BLOCK_MANY_USER_API";
export const CREATE_USER_SUBSCRIPTION_API = "CREATE_USER_SUBSCRIPTION_API";
export const DELETE_USER_SUBSCRIPTION_API = "DELETE_USER_SUBSCRIPTION_API";
export const GET_STRIPE_PLANS = "GET_STRIPE_PLANS";
export const SAVE_STRIPE_PLANS_REDUX = "SAVE_STRIPE_PLANS_REDUX";
export const GET_STRIPE_TRANSACTIONS = "GET_STRIPE_TRANSACTIONS";
export const SAVE_STRIPE_TRANSACTIONS_REDUX = "SAVE_STRIPE_TRANSACTIONS_REDUX";
export const SAVE_USER_COUNT_REDUX = "SAVE_USER_COUNT_REDUX";
export const SAVE_USER_REDUX = "SAVE_USER_REDUX";
export const CUSTOM_SAVE_USER_REDUX = "CUSTOM_SAVE_USER_REDUX";
export const MULTI_TYPE_SAVE_USER_REDUX = "MULTI_TYPE_SAVE_USER_REDUX";