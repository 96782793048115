import React, { useState, useEffect } from "react";
import closeicon from "../../assets/image/close-icon.png";
import {
    Button,
    NavDropdown,
} from "react-bootstrap";
import "./Settings.scss";
import { useDispatch, useSelector } from "react-redux";
import authService from "../../services/auth.service";
import { getProfile } from "../../slices/auth";

const BillingContacts = () => {
    const userData = useSelector((state) => state.auth.user);
    const [billingContacts, setBillingContacts] = useState([])
    const [input, setInput] = useState("")
    const dispatch = useDispatch();
    const [createCustomer, setcreateCustomer] = useState(false)
    const createCustomerToggle = () => {
        setcreateCustomer(!createCustomer);
    };

    // useEffect(() => {
    //     setBillingContacts(userData?.billingContacts)
    // }, [])

    const handleSubmit = async (newBillingContacts) => {
        let billingContact = [];
        if (newBillingContacts) {
            billingContact = [...newBillingContacts];
        }
        else {
            billingContact = [...billingContacts];
        }

        if (input && input.length > 0) {
            billingContact = [...billingContact, input]
        }
        let res = await authService.updateProfile({
            billingContacts: billingContact
        })
        if (res && res.data) {
            dispatch(getProfile({ userId: userData.id }));
        }
    }

    return (
        <div className="subscription-field">
            <div className="sb-heading">
                <h1>Manage billing contacts</h1>
            </div>
            <div className="sb-sub-heading">
                <span>Billing contacts will be notified by email regarding billing changes in your account</span>
            </div>
            <div className="billing-contacts">
                {userData.billingContacts?.map((b, i) => {
                    return (<div key={i} className="billing-email">
                        <span>{b}</span><span className="cross" onClick={() => {
                            let newBillingContacts = [...userData.billingContacts];
                            newBillingContacts.splice(i, 1);
                            handleSubmit(newBillingContacts)
                        }}>X</span>
                    </div>)
                })}
            </div>
            <div className="manage-email">
                <span>
                    Add a billing contact by email
                </span>
                <div>
                    <input className="email-input" type={"email"} onChange={(e) => {
                        setInput(e.target.value)
                    }} />
                    <button className="email-button" disabled={input && input.length > 0 ? false : true} onClick={() => {
                        handleSubmit(userData.billingContacts)
                    }}>Add</button>
                </div>
            </div>
        </div>
    );
}

export default BillingContacts