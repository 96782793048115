import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Card, Row, Col, Button, FormControl, InputGroup, Dropdown, Modal, Container } from 'react-bootstrap';
import './Login.scss';
import logo from '../../assets/image/logo.png';
import passwordicon from '../../assets/image/password.png';
import mailicons from '../../assets/image/mailicons.png';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import AuthService from "../../services/auth.service";
import { successAlert, errorAlert, infoAlert } from '../../services/toast.service';



const ForgetPassword = (props) => {

  const initialValues = {
    email:""
  };
  
  const validationSchema = Yup.object().shape({
      email: Yup.string().required().email("Please fill valid email address ")
  });

  const handleSubmit = async (formValue) => {
      console.log("formValue", formValue)
      // check email is already taken or not 
      const response = await AuthService.forgetPassword(formValue)
      console.log(response,"response");
      if (response && response.data && response.data.success) {
        successAlert(
          response.data.message
            ? response.data.message
            : "Reset password link sent successfully !"
        );
        
      }else{
        errorAlert(
          response.data.message
            ? response.data.message
            : "Something Went Wrong !"
        );
      }

    
  }

 



  return (
    <>
      <div>
        <div className="signin-form">
          <div className="logo-bar">
            <img src={logo} alt="logo" />
            <h1 className="headingpassword">Password assistance</h1>
          </div>
          <div className="signup-form-box password-formbox">
            <p>Enter the administrator or dispatcher email associated with your account</p>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            >
              <Form inline="true" >
                <Row>
                  <Col xs={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="email"><img src={mailicons} alt="emailicon" /></InputGroup.Text>
                      <Field className="form-control" placeholder="Email" type="email" name="email" aria-label="Email" />
                      <ErrorMessage name="email" component="div" />
                    </InputGroup>

                  </Col>
                  <Col xs={12}>
                    <div className="continue-btn">
                      <Button variant="primary" type="submit">
                       Continue
                      </Button>
                    </div>
                    <div className="recapcta-box">
                      <p>This site is protected by reCAPTCHA and the Google Privacy Policy.</p>
                      <p className="dha-text">Don’t have an account?  <Link to={'/signup'}>Sign up</Link></p>
                      
                    </div>
                  </Col>
                </Row>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer
        style={{ color: "black" }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
  
    </>
  );





};

export default ForgetPassword;