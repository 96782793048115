import React from "react";
import { BrowserRouter } from 'react-router-dom';

import Routes from "./Routes";
import './assets/scss/main.scss';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-NPWHVGT'
}

TagManager.initialize(tagManagerArgs)

function App() {
  window.dataLayer.push({
    event: 'pageview'
  });
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
