import { useDispatch, useSelector } from "react-redux";
import "./invoices.scss";
import { useState } from "react";
import CreateInvoice from "../components/createInvoice";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Button, Form, FormControl, NavDropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import BootstrapTable from "react-bootstrap-table-next";
import { TableHeaderColumn } from "react-bootstrap-table";
import ShortIcon from "../../../assets/image/filter-icon.png";
import downFilledArrow from "../../../assets/image/down-filled-arrow.png";
import { useEffect } from "react";
import { DELETE_INVOICE_API, GET_DASHBOARD_METRICS_API, GET_INVOICE_LIST_API, GET_PAYMENT_API, PRINT_INVOICE_PDF, SEND_INVOICE } from "../../../redux/invoicing/actionTypes";
import momentTz from "moment-timezone";
import { timeZoneHeader } from "../../../helpers/helperFunctions";

import filterremoveicon from "../../../assets/image/filter-removeicon.png";
import Chart from "./chart";
import fileDownload from "js-file-download";
import { successAlert } from "../../../services/toast.service";

import Swal from "sweetalert2";
import swalIcon from "../../../assets/image/swalIcon.png"
const timezone = { ...timeZoneHeader() }
const Invoices = (props) => {
    const userData = useSelector((state) => state.auth.user);
    const { pageLimit, invoiceList, pageCount, dashboardMetrics } = useSelector((state) => state.invoicing)
    const metricsTabsDefault = [{
        name: "overdue",
        amount: 0
    },
    {
        name: "Not due yet",
        amount: 0
    },
    {
        name: "not deposited",
        amount: 0
    },
    {
        name: "deposited",
        amount: 0
    }]
    let dispatch = useDispatch();
    let [metricsTab, setMetricsTab] = useState(metricsTabsDefault);
    const { invoiceFormProps } = props;
    const createInvoiceProps = {
        createInvoice: props.createInvoice,
        setCreateInvoice: props.setCreateInvoice,
        createCustomerToggle: props.createCustomerToggle,
        sortBy: props.sortBy,
        currentPage: props.currentPage,
        initialValues: props.initialValues,
        discount: props.discount,
        setDiscount: props.setDiscount,
        setTaskValues: props.setTaskValues,
        setShowPaymentForm: props.setShowPaymentForm,
        showPaymentForm: props.showPaymentForm,
        paymentForm: props.paymentForm,
        setPaymentForm: props.setPaymentForm,
        services: props.services,
        setServices: props.setServices,
        buttonText: props.buttonText,
        setButtonText: props.setButtonText,
        initialPaymentForm: props.initialPaymentForm,
        setSelectedTab: props.setSelectedTab
    }
    const getInvoiceList = ({ page }) => {
        let currentpage = page ? page : props.currentPage
        dispatch({
            type: GET_INVOICE_LIST_API,
            data: {
                payload: {
                    pageLimit,
                    currentPage: currentpage,
                },
                saveInRedux: true
            }
        })
    }

    const getPaymentList = async (params) => {
        let query = {
            ...params
        }
        query.userId = userData.id;
        dispatch({
            type: GET_PAYMENT_API,
            data: {
                payload: { ...query },
                saveInRedux: true,
            }
        })
    };


    const getDashboardMetrics = async (params) => {
        let query = {
            ...params
        }
        query.userId = userData.id;
        dispatch({
            type: GET_DASHBOARD_METRICS_API,
            data: {
                payload: { ...query },
                saveInRedux: true,
            }
        })
    };

    useEffect(() => {
        getInvoiceList({ page: props.currentPage })
        getDashboardMetrics()
    }, [props.createInvoice])

    useEffect(() => {
        let metricTab = metricsTabsDefault;
        if (dashboardMetrics) {
            metricTab[0].amount = Number(dashboardMetrics.overdue)?.toFixed(2)
            metricTab[1].amount = Number(dashboardMetrics.notDue)?.toFixed(2)
            metricTab[2].amount = (Number(dashboardMetrics.overdue) + Number(dashboardMetrics.notDue) - Number(dashboardMetrics.deposited))?.toFixed(2)
            metricTab[3].amount = Number(dashboardMetrics.deposited)?.toFixed(2)
        }
        setMetricsTab(metricTab)
    }, [dashboardMetrics])


    const tableRowEvents = {
        onRowClick: (row) => {
            //   setSelectedCustomer(row)
        }
    }
    const selectRowProp = {
        mode: "checkbox",
        clickToSelect: false,
        // onSelect: RowSelectHandler,
        // onSelectAll: selectAllRowsHandler,
        bgColor: "rgb(238, 193, 213) !important",
        // classes: (row, rowIndex) => {
        //   console.log("rrrrrrrrrrrrrrrrowwwwwww", row)
        //   return ""
        // }
    };

    const handlePageClick = async ({ selected }) => {
        console.log("current page::", selected);
        if (selected + 1 !== props.currentPage) {
            props.invoiceFormProps.setCurrentPage(selected + 1);
            await getInvoiceList({ page: selected + 1 });
        }
    };


    const handleActionClick = (type, row) => {
        if (type === "Edit") {
            props.setButtonText("Edit")
            props.setCreateInvoice(true)
            props.setDiscount(row.discount)
            getPaymentList({
                invoiceId: row._id
            })
            row.invoiceId = row._id
            row.termId = row.termId?.id
            row.customerId = row.invoiceCustomerId?.id
            row.customer = row.invoiceCustomerId
            delete row.invoiceCustomerId
            delete row._id
            props.setServices(row.services)
            let taskValues = { ...props.initialTaskValues };
            if (row.serviceStartDate) {
                taskValues.serviceStartDate = row.serviceStartDate
            }
            if (row.serviceEndDate) {
                taskValues.serviceEndDate = row.serviceEndDate
            }
            if (row.teamIds) {
                taskValues.teamIds = row.teamIds
            }
            if (row.agentIds) {
                taskValues.agentIds = row.agentIds
            }
            if (row.taskStatus) {
                taskValues.taskStatus = row.taskStatus
            }
            if (row.customer) {
                taskValues.customer = row.customer
            }
            if (row.customerId) {
                taskValues.customerId = row.customerId
            }
            props.setTaskValues(taskValues)
            invoiceFormProps.setInvoiceForm(row)
        }
        else if (type === "Duplicate") {
            props.setButtonText("Create")
            props.setCreateInvoice(true)
            row.termId = row.termId?.id
            row.customerId = row.invoiceCustomerId?.id
            row.customer = row.invoiceCustomerId
            delete row.invoiceCustomerId
            invoiceFormProps.setInvoiceForm(row)
        }
        else if (type === "Delete") {
            Swal.fire({
                title: "Are you sure?",
                text: "Deleted records can't be restored!",
                // iconHtml: `<img src="${IoMdTime}">`,
                imageUrl: swalIcon,
                showCancelButton: true,
                color: "#000000",
                background: "#ffffff",
                confirmButtonColor: "#7066E0",
                cancelButtonColor: "#7066E0",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch({
                        type: DELETE_INVOICE_API, data: {
                            payload: {
                                invoiceId: row._id
                            }
                        }
                    })
                }
            })

        }
        else if (type === "Receive Payment") {
            props.setPaymentForm((prev) => {
                return {
                    ...prev,
                    invoiceId: row.id,
                    invoiceDetails: row,
                    invoiceCustomerId: row.invoiceCustomerId.id
                }
            })
            getPaymentList({
                invoiceId: row.id
            })
            props.setServices(row.services)
            props.setButtonText("Save")
            props.setShowPaymentForm(true)
            props.setCreateInvoice(true)
            invoiceFormProps.setInvoiceForm(row)
        }
        else if (type === "Send") {
            dispatch({
                type: SEND_INVOICE,
                data: {
                    payload: {
                        invoiceId: row._id,
                    },
                    resCall: (res) => {
                        if (res.success) {
                            successAlert(`${row.invoiceNumber} sent succesfully`)
                        }
                    }
                }
            })
        }
        else if (type === "Print") {
            dispatch({
                type: PRINT_INVOICE_PDF,
                data: {
                    payload: {
                        invoiceId: row._id
                    },
                    resCall: (res) => {
                        fileDownload(res, `${row.invoiceNumber}.pdf`)
                    }
                }
            })
        }
    }

    const Remove = (cell, row) => {
        return (
            <>
                <span>
                    <span className="filter-edit">
                        <div className="action">
                            <NavDropdown
                                title={<img src={filterremoveicon} alt={"down-arrow"} />}
                                id="nav-dropdown"
                            >
                                <NavDropdown.Item
                                    eventKey="4.1"
                                    onClick={() => {
                                        handleActionClick("Edit", row);
                                    }}
                                >
                                    Edit
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    eventKey="4.2"
                                    onClick={() => {
                                        handleActionClick("Duplicate", row);
                                    }}
                                >
                                    Duplicate
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    eventKey="4.3"
                                    onClick={() => {
                                        handleActionClick("Receive Payment", row);
                                    }}
                                >
                                    Receive Payment
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    eventKey="4.4"
                                    onClick={() => {
                                        handleActionClick("Send", row);
                                    }}
                                >
                                    Send
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    eventKey="4.5"
                                    onClick={() => {
                                        handleActionClick("Print", row);
                                    }}
                                >
                                    Print
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    eventKey="4.6"
                                    onClick={() => {
                                        handleActionClick("Delete", row);
                                    }}
                                >
                                    Delete
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </span>
                </span>
            </>
        );
    };
    const StatusFormatter = (cell, row) => {
        let today = momentTz();
        let invoiceDueDate = momentTz(row.dueDate)
        let dayDiff = invoiceDueDate.diff(today, "day")
        let status = `Due in ${dayDiff} days`
        if (dayDiff <= 0) {
            status = 'Overdue'
        }
        if (row.payments && row.payments.length) {
            let paymentAmount = row.payments.reduce((prev, curr) => {
                return prev + curr.amount
            }, 0);
            console.log("paymentAmount", paymentAmount)
            if (paymentAmount < Number(row.amount)) {
                status = 'Partially Paid'
            }
            else if (paymentAmount >= Number(row.amount)) {
                status = 'Deposited'
            }
        }
        return status;
    };

    const columns = [{
        dataField: 'invoiceDate',
        text: 'DATE',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: (cell, row) => {
            let momentdate = momentTz(row.invoiceDate)
            return row.invoiceDate ? momentTz(momentdate._a).format("MM/DD/YYYY") : momentTz().format("MM/DD/YYYY")
        },
        headerClasses: 'table-header',
        classes: 'custom-td-class'
    }, {
        dataField: 'invoiceNumber',
        text: 'NUMBER',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        headerClasses: 'table-header',
        classes: 'custom-td-class'
    }, {
        dataField: 'invoiceCustomerId',
        text: 'CUSTOMER',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        formatter: (cell, row) => {
            return row.invoiceCustomerId?.displayName
        },
        headerClasses: 'table-header',
        classes: 'custom-td-class'
    }, {
        dataField: 'amount',
        text: 'AMOUNT',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        formatter: (cell, row) => {
            return row.amount ? `$${row.amount}` : "$0"
        },
        headerClasses: 'table-header',
        classes: 'custom-td-class'
    }, {
        dataField: 'status',
        text: 'STATUS',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        headerClasses: 'table-header',
        formatter: StatusFormatter,
        classes: 'custom-td-class'
    }, {
        datafield: "selectedOption",
        text: 'ACTION',
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'custom-td-class',
        formatter: Remove,
        // editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
        //     <ActionButton {...editorProps} value={value} />
        // )
    }];
    const rowClasses = (row, rowIndex) => {
        return 'custom-row-class';
    };
    return (
        <div className="main-invoice-container">
            {props.createInvoice ? <CreateInvoice {...createInvoiceProps} rightsidebarprops={props.rightsidebarprops} background={"#272735"} invoiceFormProps={props.invoiceFormProps} /> :
                <div className="invoice-dashboard-container">
                    <div className="dashboard-metrics-container">
                        {metricsTab && metricsTab.length && metricsTab.map((tab, index) => {
                            return (<div key={index} className="metrics-div">
                                <div className="chart-container">
                                    <Chart />
                                </div>
                                <div className="right-text-container">
                                    <h5>{tab.name}</h5>
                                    <h1>${tab.amount}</h1>
                                </div>
                            </div>)
                        })}
                    </div>
                    <div className="list-container">
                        <BootstrapTable
                            keyField='id'
                            options={tableRowEvents}
                            data={JSON.parse(JSON.stringify(invoiceList))}
                            striped={true}
                            hover={true}
                            rowClasses={rowClasses}
                            selectRow={selectRowProp}
                            wrapperClasses="table-responsive"
                            columns={columns}
                        >
                        </BootstrapTable>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="Prev"
                            renderOnZeroPageCount={null}
                            className="pagination"
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default Invoices;