import axios from "axios";
import moment from "moment";

const directionsServiceCalculate = async (formValue, optimizeWaypoints, hubs, startFromLoc, endAtLoc) => {
    console.log("formValue, optimizeWaypoints, hubs, startFromLoc, endAtLoc", formValue, optimizeWaypoints, hubs, startFromLoc, endAtLoc)
    const startFrom = startFromLoc ? startFromLoc : hubs.find(h => h.id == formValue.startFrom)
    const endAt = endAtLoc ? endAtLoc : hubs.find(h => h.id == formValue.endAt)
    const waypoints = formValue.locations.map((p) => ({
        location: p.location,
        stopover: true,
    }));

    const origin = startFrom.location;
    const destination = endAt.location;

    const directionsService = new window.google.maps.DirectionsService();
    return new Promise((resolve, reject) => {
        directionsService.route(
            {
                origin: origin,
                destination: destination,
                optimizeWaypoints: optimizeWaypoints,
                travelMode: window.google.maps.TravelMode.DRIVING,
                waypoints: waypoints,
            },
            async (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    resolve(result)
                }
                else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                    setTimeout(() => {
                        let resultss = directionsServiceCalculate(formValue, optimizeWaypoints, hubs, startFromLoc, endAtLoc);
                        resolve(resultss)
                    }, 5000)
                }
                else {
                    resolve()
                }
            })
    })

}

const directionsServiceHere = async (formValue, optimizeWaypoints, hubs, startFromLoc, endAtLoc, optimize = false) => {
    const startFrom = startFromLoc ? startFromLoc : hubs.find(h => h.id === formValue.startFrom)
    const endAt = endAtLoc ? endAtLoc : hubs.find(h => h.id === formValue.endAt)
    const waypoints = formValue.locations.map((p) => ({
        ...p,
        stopover: true,
    }));

    const origin = startFrom.location;
    const destination = endAt.location;

    let hereUrl = `${process.env.REACT_APP_HERE_URL}v8/findsequence2?apiKey=${process.env.REACT_APP_HERE_API_KEY}`
    if (origin) {
        hereUrl = `${hereUrl}&start=${origin.lat},${origin.lng}`
    }
    if (waypoints.length) {
        for (let w in waypoints) {
            hereUrl = `${hereUrl}&destination${Number(w) + 1}=${waypoints[w].id};${waypoints[w].location.lat},${waypoints[w].location.lng};${Number(w) < waypoints.length - 1 && !optimize ? `before:destination${Number(w) + 2}` : ""}`
        }
    }
    if (destination) {
        hereUrl = `${hereUrl}&end=${destination.lat},${destination.lng}`
    }
    let optimizeUrl = `&improveFor=distance`
    let restUrl = `&departure=${encodeURIComponent(moment().format())}&mode=fastest;car;traffic:disabled;`
    hereUrl = `${hereUrl}${optimize ? `${optimizeUrl}${restUrl}` : `${restUrl}`}`
    let result = await axios.get(hereUrl)
    if (result.status === 200 && Number(result.data.responseCode) === 200 && result.data.results.length) {
        return result.data.results
    }
    else {
        return
    }
}

const calculateTimeDistace = async (formValue, defaultDurationTime, missionList, hubs) => {
    const result = await directionsServiceHere(formValue, true, hubs, null, null, true);
    if (result) {
        let totalDist = 0;
        let totalTime = 0;
        let tasksDistTime = [];
        let { taskIds, totalDuration, allTasks } = await rearrangeMissionTasksV2(formValue, result, "distance", missionList, defaultDurationTime);
        formValue.locations = allTasks.map(i => { return { location: i.location, id: i.id } });
        let resultDirections = await directionsServiceHere(formValue, false, hubs, null, null, true);
        if (resultDirections && resultDirections.length) {
            let myroute = resultDirections[0];
            totalDist = Number(myroute.distance)
            totalTime = Number(myroute.time)

            for (let i = 0; i < myroute.interconnections.length - 1; i++) {
                tasksDistTime.push({
                    id: allTasks[i].id,
                    distanceFromPrevTask: myroute.interconnections[i].distance / 1000,
                    timeFromPrevTask: Number(myroute.interconnections[i].time) / 60
                })
            }
        }
        console.log("totalDuration", totalDuration)
        totalTime += (totalDuration * 60);
        if (totalTime) {
            totalTime = totalTime / 3600
            totalTime = totalTime.toFixed(1)
        }
        if (totalDist) {
            totalDist = totalDist / 1000
            totalDist = totalDist.toFixed(1)
        }
        //   saveOptimisedMision({ ...formValue, taskIds, missionDetails: { totalTime, totalDist } })
        return { ...formValue, taskIds, missionDetails: { totalTime, totalDist }, tasks: tasksDistTime }
    }
    else {
        return
    }
}

const rearrangeMissionTasksV2 = async (formValue, result, optimiseMode, missionList, defaultDurationTime) => {
    let missionData = missionList.find(item => item.id === formValue.missionId)
    let totalDuration = 0;
    if (missionData.tasks.length) {
        totalDuration = missionData.tasks.reduce((partialSum, task) => partialSum + Number(task.durationTime), 0)
    }

    let taskIds = []
    let taskWithOrderType = []
    if (optimiseMode === "distance") {
        let myRoute = result
        if (myRoute[0].waypoints.length) {
            let position = 0;
            for (let item in myRoute[0].waypoints) {
                let index = missionData.tasks.findIndex(i => { return i.id === myRoute[0].waypoints[item].id })
                if (index >= 0) {
                    taskWithOrderType.push({
                        id: missionData.tasks[index].id,
                        orderType: missionData.tasks[index].orderType,
                        position: Number(position),
                        linked: missionData.tasks[index].linked ? missionData.tasks[index].linked : false,
                        location: missionData.tasks[index].location,
                        durationTime: missionData.tasks[index].durationTime ? missionData.tasks[index].durationTime : defaultDurationTime,
                        scheduledTime: missionData.tasks[index].scheduledTime,
                        scheduledTimeSeconds: missionData.tasks[index].scheduledTimeSeconds,
                        after: missionData.tasks[index].after,
                        afterSeconds: missionData.tasks[index].afterSeconds,
                        before: missionData.tasks[index].before,
                        beforeSeconds: missionData.tasks[index].beforeSeconds
                    })
                    position += 1
                }

            }
        }
    }
    else {
        taskWithOrderType = result.taskWithOrderType
    }
    if (!missionData.linkedTasks || (missionData.linkedTasks && !missionData.linkedTasks.length)) {
        taskIds = taskWithOrderType.map(i => i.id)
        return { taskIds, totalDuration, allTasks: taskWithOrderType };
    }
    else {
        let linkedTasks = [], notLinkedTasks = [], otherTask = [], pickupTask = [];
        let availablePositions = [];
        linkedTasks = taskWithOrderType.filter(item => item.linked === true)
        notLinkedTasks = taskWithOrderType.filter(item => item.linked === false)
        let notLinkedTaskPositions = notLinkedTasks.map(i => i.position);
        for (let i = 0; i < taskWithOrderType.length; i++) {
            if (notLinkedTaskPositions.indexOf(i) < 0) {
                availablePositions.push(i)
            }
        }
        for (let key of linkedTasks) {
            if (key.orderType === "P") {
                pickupTask.push(key)
            }
            else if (key.orderType !== "P") {
                otherTask.push(key)
            }
        }

        linkedTasks = [...pickupTask, ...otherTask]
        linkedTasks.map((t, i) => {
            t.position = availablePositions[i]
            return t
        })
        let allTasks = [...linkedTasks, ...notLinkedTasks];
        allTasks = allTasks.sort((a, b) => a.position - b.position)
        taskIds = allTasks.map(i => i.id)
        return { taskIds, totalDuration, allTasks };
    }
}

const rearrangeMissionTasks = async (formValue, result, optimiseMode, missionList, defaultDurationTime) => {
    let missionData = missionList.find(item => item.id === formValue.missionId)
    let totalDuration = 0;
    if (missionData.tasks.length) {
        totalDuration = missionData.tasks.reduce((partialSum, task) => partialSum + Number(task.durationTime), 0)
    }

    let taskIds = []
    let taskWithOrderType = []
    if (optimiseMode === "distance") {
        if (result.routes[0].waypoint_order.length) {
            for (let item in result.routes[0].waypoint_order) {
                let index = result.routes[0].waypoint_order[item]
                taskWithOrderType.push({
                    id: missionData.tasks[index].id,
                    orderType: missionData.tasks[index].orderType,
                    position: Number(item),
                    linked: missionData.tasks[index].linked ? missionData.tasks[index].linked : false,
                    location: missionData.tasks[index].location,
                    durationTime: missionData.tasks[index].durationTime ? missionData.tasks[index].durationTime : defaultDurationTime,
                    scheduledTime: missionData.tasks[index].scheduledTime,
                    scheduledTimeSeconds: missionData.tasks[index].scheduledTimeSeconds,
                    after: missionData.tasks[index].after,
                    afterSeconds: missionData.tasks[index].afterSeconds,
                    before: missionData.tasks[index].before,
                    beforeSeconds: missionData.tasks[index].beforeSeconds
                })
            }
        }
    }
    else {
        taskWithOrderType = result.taskWithOrderType
    }
    if (!missionData.linkedTasks || (missionData.linkedTasks && !missionData.linkedTasks.length)) {
        taskIds = taskWithOrderType.map(i => i.id)
        return { taskIds, totalDuration, allTasks: taskWithOrderType };
    }
    else {
        let linkedTasks = [], notLinkedTasks = [], otherTask = [], pickupTask = [];
        let availablePositions = [];
        linkedTasks = taskWithOrderType.filter(item => item.linked === true)
        notLinkedTasks = taskWithOrderType.filter(item => item.linked === false)
        let notLinkedTaskPositions = notLinkedTasks.map(i => i.position);
        for (let i = 0; i < taskWithOrderType.length; i++) {
            if (notLinkedTaskPositions.indexOf(i) < 0) {
                availablePositions.push(i)
            }
        }
        for (let key of linkedTasks) {
            if (key.orderType === "P") {
                pickupTask.push(key)
            }
            else if (key.orderType !== "P") {
                otherTask.push(key)
            }
        }

        linkedTasks = [...pickupTask, ...otherTask]
        linkedTasks.map((t, i) => {
            t.position = availablePositions[i]
            return t
        })
        let allTasks = [...linkedTasks, ...notLinkedTasks];
        allTasks = allTasks.sort((a, b) => a.position - b.position)
        taskIds = allTasks.map(i => i.id)
        return { taskIds, totalDuration, allTasks };
    }
}


const optimizeDataTimeMode = async (formValue, defaultDurationTime, missionList, hubs, moment) => {
    const distanceresult = await directionsServiceHere(formValue, true, hubs, null, null, true);

    if (!distanceresult) {
        return
    }
    console.log("missionList", missionList)
    let rearrangeResults = await rearrangeMissionTasksV2(formValue, distanceresult, "distance", missionList, defaultDurationTime);

    formValue.tasks = rearrangeResults.allTasks;
    let finalPositionTasks = [];
    let date = moment(formValue.missionDate);
    let startHour = 9;
    let startMinute = 0;
    let startSecond = 0;
    let startShiftTime = startHour * 3600 + startMinute + 60 + startSecond;
    let endHour = 23;
    let endMinute = 0;
    let endSecond = 0;
    let endShiftTime = endHour * 3600 + endMinute + 60 + endSecond;

    let { freeTimeInADay, sortedTasks, tasksWithoutTime } = await sortTaskAndCalcualteTime(formValue,
        date, startShiftTime, defaultDurationTime, endShiftTime, moment
    );
    let position = 0;
    if (freeTimeInADay.length) {
        for (let i = 0; i < freeTimeInADay.length; i++) {
            if (freeTimeInADay[i].time > 0) {
                let taskForLoop = [...tasksWithoutTime];
                let index = 0;
                for (let task of taskForLoop) {
                    let duration = task.durationTime ? Number(task.durationTime) : defaultDurationTime
                    if (freeTimeInADay[i].time > duration * 60) {
                        task.position = position;
                        task.afterSeconds = freeTimeInADay[i].end + freeTimeInADay[i].duration
                        finalPositionTasks.push(task)
                        position += 1;
                        freeTimeInADay[i].time -= duration * 60;
                        tasksWithoutTime.splice(index, 1)
                        index += 1
                    }
                }
                if (sortedTasks[i]) {
                    sortedTasks[i].position = position;
                    finalPositionTasks.push(sortedTasks[i])
                    position += 1;
                }
            }
            else {
                if (sortedTasks[i]) {
                    sortedTasks[i].position = position;
                    finalPositionTasks.push(sortedTasks[i])
                    position += 1;
                }
            }
        }
    }
    else {
        let taskForLoop = [...tasksWithoutTime];
        let index = 0;
        let lastTaskAfterSeconds = startShiftTime
        let lastTaskDuration = 0;
        for (let task in taskForLoop) {
            taskForLoop[task].position = position;
            taskForLoop[task].afterSeconds = lastTaskAfterSeconds + lastTaskDuration * 60
            lastTaskDuration = taskForLoop[task].durationTime ? Number(taskForLoop[task].durationTime) : defaultDurationTime
            finalPositionTasks.push(taskForLoop[task])
            position += 1;
            lastTaskAfterSeconds = taskForLoop[task].afterSeconds;
            tasksWithoutTime.splice(index, 1)
            index += 1
        }
    }

    let firstTask = finalPositionTasks[0]
    let lastTask = finalPositionTasks[finalPositionTasks.length - 1]
    console.log("lastTask.afterSeconds", lastTask.afterSeconds)
    console.log("lastTask.durationTime", lastTask.durationTime)
    console.log("firstTask.afterSeconds", firstTask.afterSeconds)
    // let totalTime = (lastTask.afterSeconds + (lastTask.durationTime ? Number(lastTask.durationTime) : defaultDurationTime) * 60) - firstTask.afterSeconds
    let totalTime = 0;
    console.log("totalTimetotalTime", totalTime)
    let taskWithOrderType = finalPositionTasks.map((item, i) => {
        return {
            id: item.id,
            orderType: item.orderType,
            position: i,
            linked: item.linked ? item.linked : false,
            location: item.location,
            durationTime: item.durationTime ? item.durationTime : defaultDurationTime,
            scheduledTime: item.scheduledTime,
            scheduledTimeSeconds: item.scheduledTimeSeconds,
            after: item.after,
            afterSeconds: item.afterSeconds,
            before: item.before,
            beforeSeconds: item.beforeSeconds
        }
    })

    let result = {
        taskWithOrderType
    }
    let { taskIds, totalDuration, allTasks } = await rearrangeMissionTasksV2(formValue, result, "time", missionList, defaultDurationTime)
    formValue.locations = allTasks.map(i => { return { location: i.location, id: i.id } });
    let resultDirections = await directionsServiceHere(formValue, false, hubs, null, null, true);
    let totalDist = 0;
    let totalDurationHere = 0;
    let tasksDistTime = [];
    if (resultDirections && resultDirections.length) {
        let myroute = resultDirections[0];
        totalDist = Number(myroute.distance)
        totalDurationHere = Number(myroute.time)

        for (let i = 0; i < myroute.interconnections.length - 1; i++) {
            tasksDistTime.push({
                id: allTasks[i].id,
                distanceFromPrevTask: myroute.interconnections[i].distance / 1000,
                timeFromPrevTask: Number(myroute.interconnections[i].time) / 60
            })
        }
        console.log("totalDurationHere", totalDurationHere)
        if (totalDist) {
            totalDist = totalDist / 1000
            totalDist = totalDist.toFixed(1)
        }

        totalTime = totalTime + totalDurationHere + (Number(totalDuration) * 60);
    }
    // saveOptimisedMision({ ...formValue, taskIds, missionDetails: { totalTime: (totalTime / 3600).toFixed(1), totalDist: totalDist } })
    return { ...formValue, taskIds, missionDetails: { totalTime: (totalTime / 3600).toFixed(1), totalDist: totalDist }, allTasks, tasks: tasksDistTime }
}

const sortTaskAndCalcualteTime = async (formValue, date, startShiftTime, defaultDurationTime, endShiftTime, moment) => {
    let newTasks = JSON.parse(JSON.stringify(formValue.tasks));
    let tasksWithTime = [], tasksWithoutTime = [], tasksWithScheduleTime = [];
    for (let item of newTasks) {
        if ((item.scheduledTime && item.scheduledTime !== "")) {
            tasksWithScheduleTime.push(item)
        }
        else if ((item.after && item.after !== "") || (item.before && item.before !== "")) {
            tasksWithTime.push(item)
        }
        else {
            tasksWithoutTime.push(item)
        }
    }
    let newTasksWithScheduleTime = tasksWithScheduleTime.map(item => {
        let split = item.scheduledTime.split(":")
        let [hour, minute] = split.map(Number)
        let newDate = moment(date._d).format("YYYY-MM-DD")
        let start = item.scheduledTimeSeconds;
        let duration = item.durationTime ? Number(item.durationTime) : defaultDurationTime;
        let endDate = moment(newDate + " " + item.scheduledTime).add(duration, "minute");
        // endDate.setHours(hour)
        // endDate.setMinutes(minute + duration)
        console.log("endDate", endDate)
        let endHour = endDate.get("hour");
        let endMinute = endDate.get("minute");
        let time = endHour * 3600 + endMinute * 60
        let end = parseInt(time);
        item.afterSeconds = start
        item.beforeSeconds = end
        return item;
    })
    let allTasksWithTime = [...newTasksWithScheduleTime, ...tasksWithTime]
    let sortedTasks = allTasksWithTime.sort((a, b) => a.afterSeconds - b.afterSeconds)
    let newsortedTasks = sortedTasks.sort((a, b) => a.beforeSeconds - b.beforeSeconds)
    let freeTimeInADay = [];
    if (sortedTasks && sortedTasks.length) {
        let timeBWStartShiftAndFirstTask = sortedTasks[0].afterSeconds - startShiftTime
        freeTimeInADay.push({
            start: startShiftTime,
            end: sortedTasks[0].afterSeconds,
            duration: sortedTasks[0].durationTime ? Number(sortedTasks[0].durationTime) : defaultDurationTime,
            time: timeBWStartShiftAndFirstTask
        });

        for (let task = 1; task < sortedTasks.length; task++) {
            let prevDuration = sortedTasks[task - 1].durationTime ? Number(sortedTasks[task - 1].durationTime) : defaultDurationTime
            let start = sortedTasks[task - 1].afterSeconds + prevDuration * 60;
            let end = sortedTasks[task].afterSeconds
            let timeBWTwoTasks = end - start
            freeTimeInADay.push({
                start: start,
                end: end,
                duration: sortedTasks[task].durationTime ? Number(sortedTasks[task].durationTime) : defaultDurationTime,
                time: timeBWTwoTasks
            })
        }
        let lastDurationTime = sortedTasks[Number(sortedTasks.length) - 1].durationTime ? sortedTasks[Number(sortedTasks.length) - 1].durationTime : defaultDurationTime
        let timeBWLastTaskAndEndShift = endShiftTime - (sortedTasks[Number(sortedTasks.length) - 1].afterSeconds + lastDurationTime * 60);
        freeTimeInADay.push({
            start: sortedTasks[Number(sortedTasks.length) - 1].afterSeconds,
            end: endShiftTime,
            duration: sortedTasks[Number(sortedTasks.length) - 1].durationTime ? Number(sortedTasks[Number(sortedTasks.length) - 1].durationTime) : defaultDurationTime,
            time: timeBWLastTaskAndEndShift
        });


    }

    return { freeTimeInADay, sortedTasks, tasksWithoutTime }
}

const checkMMRequirement = (formValue, missionList) => {
    if (!formValue || (!formValue.maxMissionTasks && !formValue.maxMissionDuration)) {
        return false
    }
    else {
        let missionData = missionList.find(item => item.id === formValue.missionId)
        if (missionData && Number(missionData.tasks.length) > Number(formValue.maxMissionTasks)) {
            return true
        }
        let taskDuration = missionData.tasks.reduce((partialSum, task) => partialSum + Number(task.durationTime), 0)
        if (taskDuration / 60 > Number(formValue.maxMissionDuration)) {
            return true
        }
        return false
    }
}

const handleMultiMission = async (formValue, defaultDurationTime, missionList, hubs, moment) => {
    let allMissionTasks = [], tasksOptimised = formValue.tasks, remainingTasksIn1Mission = Number(formValue.maxMissionTasks),
        remainingDurationIn1Mission = Number(formValue.maxMissionDuration) * 60;

    let totalDist = 0, totalTime = 0;
    if (formValue.optimiseMode === "distance") {
        let { allTasks } = await calculateTimeDistace(formValue, defaultDurationTime, missionList, hubs)
        tasksOptimised = allTasks;
    }
    else {
        let { allTasks } = await optimizeDataTimeMode(formValue, defaultDurationTime, missionList, hubs, moment);
        tasksOptimised = allTasks;
    }

    allMissionTasks = await optimiseMultiMissionTasks(formValue, allMissionTasks, tasksOptimised, remainingTasksIn1Mission, remainingDurationIn1Mission)
    let tasksDistTime = [];
    if (allMissionTasks.length && allMissionTasks[0].length) {
        let newFormValue = { ...formValue };
        console.log("allMissionTasks[0]", allMissionTasks[0])
        newFormValue.locations = allMissionTasks[0].map(i => { return { location: i.location, id: i.id } })

        let directionService = await directionsServiceHere(newFormValue, false, hubs, null, null, true)
        if (directionService) {
            let myroute = directionService[0];
            totalDist = Number(directionService[0].distance);
            totalTime = Number(directionService[0].time);
            for (let i = 1; i < myroute.interconnections.length - 1; i++) {
                tasksDistTime.push({
                    id: allMissionTasks[0][i].id,
                    distanceFromPrevTask: myroute.interconnections[i].distance / 1000,
                    timeFromPrevTask: Number(myroute.interconnections[i].time) / 60
                })
            }
            let totalDuration = allMissionTasks[0].reduce((partialSum, task) => partialSum + Number(task.durationTime), 0);
            totalTime += (totalDuration * 60);
            if (totalTime) {
                totalTime = totalTime / 3600
                totalTime = totalTime.toFixed(1)
            }
            if (totalDist) {
                totalDist = totalDist / 1000
                totalDist = totalDist.toFixed(1)
            }
        }

    }

    return { allMissionTasks, totalDist, totalTime, tasks: tasksDistTime }
}

let optimiseMultiMissionTasks = async (formValue, allMissionTasks, tasksOptimised, remainingTasksIn1Mission, remainingDurationIn1Mission) => {
    let taskIn1Mission = [];

    let newtasksOptimised = JSON.parse(JSON.stringify(tasksOptimised))
    for (let i = 0; i < newtasksOptimised.length; i++) {
        if (remainingTasksIn1Mission > 0) {
            // eslint-disable-next-line no-loop-func
            let findTaskIndexForDuration = tasksOptimised.findIndex(i => {
                return (remainingDurationIn1Mission - Number(i.durationTime) >= 0);
            })
            if (findTaskIndexForDuration > -1) {
                taskIn1Mission.push({ id: tasksOptimised[findTaskIndexForDuration].id, location: tasksOptimised[findTaskIndexForDuration].location, durationTime: tasksOptimised[findTaskIndexForDuration].durationTime, capacity: tasksOptimised[findTaskIndexForDuration].capacity })
                remainingTasksIn1Mission -= 1;
                remainingDurationIn1Mission = remainingDurationIn1Mission - Number(tasksOptimised[findTaskIndexForDuration].durationTime);
                tasksOptimised.splice(findTaskIndexForDuration, 1)
            }
            if (i === newtasksOptimised.length - 1) {
                allMissionTasks.push(taskIn1Mission);
            }
            continue;
        }
        else {
            if (taskIn1Mission.length) allMissionTasks.push(taskIn1Mission)
            return optimiseMultiMissionTasks(formValue, allMissionTasks, tasksOptimised, Number(formValue.maxMissionTasks), (Number(formValue.maxMissionDuration) * 60))
        }
    }

    return allMissionTasks
}

let optimize = {
    calculateTimeDistace,
    optimizeDataTimeMode,
    checkMMRequirement,
    handleMultiMission,
    directionsServiceCalculate,
    directionsServiceHere
}

export default optimize;