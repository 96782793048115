import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function SuperAndSubadminRoutes(props) {
    const { component: Component, ...rest } = props;

    const render = props => {
        const user = JSON.parse(localStorage.getItem("user"))
        if (!user) {
            return <Redirect to={'/login'} />;
        }else if(user.role === 1 || user.role === 2 || user.role === 3)
        {
            return <Component {...props} />;
        }else{
            return <Redirect to={'/login'} />;
        }

        
    };

    return <Route {...rest} render={render} />;
}

export default SuperAndSubadminRoutes;

