import { DirectionsRenderer, GoogleMap, useLoadScript, DrawingManager, Polyline } from "@react-google-maps/api";
import React from "react";
import map_options from "./map_options";
import RenderMarkers from "./MarkerComponent";
import AssignImage from "../../../../assets/image/started-icon.png"
import appDefaults from "../../../../helpers/app-defaults";
import { delay } from "../../../../helpers/helperFunctions";
const libraries = ["places", "drawing"]
const MapComponent = (props) => {
    const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY, libraries: libraries })
    const mapContainerStyle = {
        width: '100%',
        height: '100%'
    };

    const onLoad = map => {
        props.setMap(map)
    }

    const onUnmount = map => {
        props.setMap(null)
    }

    const markerProps = {
        bookMarks: props.bookMarks,
        agentBookMarks: props.agentBookMarks,
        map: props.map,
        setSelectedCenter: props.setSelectedCenter,
        taskDetailHandler: props.taskDetailHandler,
        taskSingleClickHandler: props.taskSingleClickHandler,
        selectedCenter: props.selectedCenter,
        missionTaskSingleClickHandler: props.missionTaskSingleClickHandler,
        hoverHub: props.hoverHub, setHoverHub: props.setHoverHub
    }

    const otherMarkerProps = {
        ...markerProps,
        bookMarks: [],
        agentBookMarks: [],
        otherMarkers: props.otherMarkers,
    }
    const onPolygonLoad = drawingManager => {
        // console.log(drawingManager)
    }

    const onPolygonComplete = polygon => {
        // console.log(polygon)
        let polygonBounds = polygon.getPath();
        let bounds = [];
        for (let i = 0; i < polygonBounds.length; i++) {
            let point = {
                lng: polygonBounds.getAt(i).lng(),
                lat: polygonBounds.getAt(i).lat()
            };
            bounds.push(point);
        }
        props.getTaskList(false, {
            coordinates: JSON.stringify(bounds)
        }, null, null, true)
        polygon.setPaths([]);
    }

    const symbolOne = {
        path: window.google?.maps && window.google.maps.SymbolPath.CIRCLE,
        strokeColor: "#ffffff",
        strokeWeight: 1,
        scale: 10,
        fillColor: "rgb(38, 129, 196)",
        fillOpacity: 1,
    };

    return (<>{isLoaded ? <GoogleMap
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={map_options}
        mapContainerStyle={mapContainerStyle}
        center={props.defaultCenter}
        clickableIcons={false}
        zoom={15}
        onClick={() => {
            let state = {
                show: false
            }
            props.setContextMenu(state)
            if (props.contextMenuRef) {
                props.contextMenuRef.current = state
            }
        }}
        onRightClick={async (e) => {
            await delay(10)
            let state = {
                show: true,
                position: {
                    clientX: e.domEvent.clientX,
                    clientY: e.domEvent.clientY
                },
                type: appDefaults.CONTEXT_MENU_TYPE.MAP,
                data: {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                },
                menu: [{ clickMethod: props.handleTaskClick, menuItem: appDefaults.CONTEXT_MENU_ITEMS.CREATE_TASK }, { clickMethod: props.handleHubClick, menuItem: appDefaults.CONTEXT_MENU_ITEMS.CREATE_HUB }]
            }
            props.setContextMenu(state)
            if (props.contextMenuRef) {
                props.contextMenuRef.current = state
            }
        }}
    >
        {/* {props.directions && <DirectionsRenderer directions={props.directions} options={{
            suppressMarkers: true,
            polylineOptions: { strokeColor: '#0e3cb0', geodesic: true }
        }} />} */}
        {props.directions && <Polyline path={props.directions} options={{
            suppressMarkers: true,
            strokeWeight: 1.5,
            strokeColor: '#fff', geodesic: true
        }} />}
        {props.agentDirections && <Polyline path={props.agentDirections} options={{
            suppressMarkers: true,
            strokeWeight: 1.5,
            strokeColor: '#fff', geodesic: true
        }} />}
        {((props.bookMarks && props.bookMarks.length) || (props.agentBookMarks && props.agentBookMarks.length) || (props.otherMarkers && props.otherMarkers.length)) && <RenderMarkers {...props} />}
        {(props.datedBookMarks && props.datedBookMarks.length) && <RenderMarkers {...props} datedBookMarks={props.datedBookMarks} />}
        {(props.pendingBookMarks && props.pendingBookMarks.length) && <RenderMarkers {...props} pendingBookMarks={props.pendingBookMarks} />}
        {props.drawPolygon ? <DrawingManager
            onLoad={onPolygonLoad}
            onPolygonComplete={onPolygonComplete}
            drawingMode={"polygon"}
            options={{
                drawingControl: false,
                markerOptions: {
                    icon: AssignImage
                },
                polygonOptions: {
                    fillColor: "rgb(38, 129, 196)",
                    strokeColor: "rgb(38, 129, 196)",
                    strokeWeight: 5,
                    icons: [{
                        icon: symbolOne,
                        offset: "0%",
                        repeat: "100%"
                    }],
                }

            }}
        /> : null}
    </GoogleMap> : <div>Loading</div>}
    </>)
}

export default MapComponent;