import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Row,
  Col,
  Form as BootstrapForm,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
  NavDropdown,
} from "react-bootstrap";
import "./Team.scss";
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import logo from "../../assets/image/logo.png";
import Union from "../../assets/image/Union.png";
import downloadicon from "../../assets/image/downloadicon.png";
import searchicon from "../../assets/image/searchicon.png";
import view from "../../assets/image/view.png";
import hide from "../../assets/image/hide.png";
import passwordicon from "../../assets/image/password.png";
import searchF from "../../assets/image/search-f.png";
import bin from "../../assets/image/bin.png";
import ShortIcon from "../../assets/image/filter-icon.png";
import filterremoveicon from "../../assets/image/filter-removeicon.png";
import closeicon from "../../assets/image/close-icon.png";
import nameicon from "../../assets/image/nameicon.png";
import mailicon from "../../assets/image/mailicon.png";
import phoneicon from "../../assets/image/phoneicon.png";
import roleicon from "../../assets/image/roleicon.png";
import teamicon from "../../assets/image/teamicon.png";
import Layout from "../../components/layout";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { ExportToCsv } from "export-to-csv";
import {
  successAlert,
  errorAlert,
  infoAlert,
} from "../../services/toast.service";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import userService from "../../services/user.service";
import teamService from "../../services/team.service";
import "react-tabs/style/react-tabs.css";

const User = (props) => {
  console.log("props.countryCode", props.countryCode)
  const history = useHistory();
  const initialValues = {
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    teamId: "",
    userRole: "",
    userRoleValue: "",
    user: "",
    countryCode: props.countryCode && props.countryCode === "IN" ? "+91" : "+1",
    password: "",
    teamIds: [],
    values: [],
    role: 2,
    addInput: false,
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [createCustomer, setcreateCustomer] = useState("false");
  const [formData, setFormData] = useState(initialValues);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const [open, setOpen] = React.useState(false);
  const [teamList, setTeamList] = React.useState([]);
  const [subAdmin, setSubAdmin] = React.useState([]);
  const [agentTitle, setAgentTitle] = React.useState("");
  const [agentCount, setAgentCount] = React.useState(0);
  const [features, setFeatures] = React.useState(null);
  const userData = useSelector((state) => state.auth.user);

  const extensionData = useSelector((state) => state.extensions);
  useEffect(() => {
    extensionData &&
      extensionData.extension &&
      extensionData.extension.length &&
      extensionData.extension.map((data) => {
        if (data.isEnabled) {
          data.userExtensions.map((extension) => {
            setFeatures((prev) => {
              return {
                ...prev,
                ...props.features,
                ...extension.features,
                additionalTaskNumber: Number(
                  extension.features.additionalTaskNumber
                ),
              };
            });
          });
        }
      });
  }, [extensionData]);
  //open and close agent
  const selectAllOption = { id: "all", teamName: "All Teams" }
  const getTeamlist = async (params) => {
    if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
      params.userIds = userData.permittedUsers
    }
    if (userData.subAdmin) {
      delete params.userIds
    }
    const response = await teamService.getTeamList(params);
    if (response.status == 200) {
      setTeamList(response.data.results || []);
    }
  };
  const getAgentCount = async () => {
    const response = await userService.getUserCount({ role: 5 });
    if (response.status == 200) {
      setAgentCount(response.data.result || 0);
    }
    else {
      setAgentCount(0)
    }
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const createCustomerToggle = () => {
    setcreateCustomer(!createCustomer);
  };

  let validation = {
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number is required"),
    countryCode: Yup.string().required("Please choose a countryCode"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    userRole: Yup.string().required("User role is required"),
    // email: Yup.string().required("Email is required!").email("Please fill valid email address "),
    // userRole: Yup.string().required("Please choose a role")
  };

  if (userData && userData.role === 1 && !userData.subAdmin) {
    validation.user = Yup.string().required("Please select a user");
  }

  const validationSchema = Yup.object().shape(validation);

  const formatStatus = (cell, row) => {
    if (row.blocked) return <span>Blocked</span>;
    return <span>Active</span>;
  };
  function NameFormatter(cell, row) {
    return (
      <span>
        {row.firstName ? row.firstName : ""} {row.lastName ? row.lastName : ""}
      </span>
    );
  }
  const UserNameFormatter = (cell, row) => {
    return (
      <span>
        {row.userId && row.userId.firstName ? row.userId.firstName : ""}{" "}
        {row.userId && row.userId.lastName ? row.userId.lastName : ""}
      </span>
    );
  };
  const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1)
  }
  const RoleFormatter = (cell, row) => {
    return (
      <span>
        {row.userRole ? (row.userRole === "crud" ? "Account owner" : capitalizeFirstLetter(row.userRole)) : ""}
      </span>
    );
  };

  const PhoneFormatter = (cell, row) => {
    return (
      <span>
        {row.countryCode ? row.countryCode : ""}{" "}
        {row.phoneNumber ? row.phoneNumber : ""}
      </span>
    );
  };

  function TeamNameFormatter(cell, row) {
    return <span>{row.teamIds ? row.teamIds.map(i => i.teamName).join(", ") : ""}</span>;
  }

  const Remove = (cell, row) => {
    return (
      <>
        <span>
          <span className="filter-edit">
            <NavDropdown
              title={<img src={filterremoveicon} />}
              id="nav-dropdown"
            >
              <NavDropdown.Item
                eventKey="4.1"
                onClick={() => {
                  setAgentTitle("Edit Member");
                  editHandler(row);
                }}
              >
                Edit
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="4.2"
                onClick={() => {
                  deleteHandler(row);
                }}
              >
                Delete
              </NavDropdown.Item>
            </NavDropdown>
          </span>
        </span>
      </>
    );
  };

  const getUserList = async (params) => {
    if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
      params.userIds = userData.permittedUsers
    }
    if (userData.subAdmin) {
      delete params.userIds;
    }
    params.roles = [2, 5]
    const response = await userService.getMemberList(params);
    if (response.status == 200) {
      if (response.data && response.data.totalPages) {
        setPageCount(response.data.totalPages);
      }
      setUsers(response.data.results || []);
    }
  };

  const exportToCsv = async () => {
    userService
      .getUsers({ role: 5 })
      .then((result) => {
        if (result.status === 200) {
          let data = [];

          result.data.map((item, index) => {
            let d = {
              firstName: item.firstName || "N/A",
              lastName: item.lastName || "N/A",
              email: item.email || "N/A",
              userRole: item.userRole || "N/A",
              countryCode: item.countryCode || "N/A",
              phoneNumber: item.phoneNumber || "N/A",
              status: item.blocked ? "UnActive" : "Active",
            };
            data.push(d);
          });

          const options = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: true,
            title: "Agent List",
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: "agents",
          };

          const csvExporter = new ExportToCsv(options);
          if (data.length > 0) {
            csvExporter.generateCsv(data);
          } else {
            errorAlert("No data found");
          }
        } else {
          errorAlert(result.message ? result.message : "Something went wronng");
        }
      })
      .catch(console.log);
  };


  let roleOptions = [{ id: "admin", name: "Admin", description: "Manage company-level setup" },
  { id: "manager", name: "Manager", description: "Views and/or manages drivers and tasks" },
  { id: "viewer", name: "Viewer", description: "consulting access, very limited actions" },
  { id: "agent", name: "Agent", description: "Execute tasks in the fielt using mobile app" }]

  //handlers
  const editHandler = (row) => {
    setAgentTitle("Edit Member");
    setFormData({
      id: row.id ? row.id : "",
      firstName: row.firstName ? row.firstName : "",
      lastName: row.lastName ? row.lastName : "",
      phoneNumber: row.phoneNumber ? row.phoneNumber : "",
      email: row.email ? row.email : "",
      userRole: row.userRole ? row.userRole : "",
      userRoleValue: row.userRole ? roleOptions.find(i => i.id === row.userRole) : "",
      countryCode: row.countryCode ? row.countryCode : "",
      teamId: row.teamId ? row.teamId.id : "",
      values: row.teamIds,
      teamIds: row.teamIds ? row.teamIds.map(i => i.id) : [],
      user: row.userId ? row.userId.id : "",
      role: row.role ? row.role : 2
    });
    // setValues(row.teamIds ? row.teamIds : [])
    handleDrawerOpen();
  };
  const deleteHandler = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      text: "Deleted records can't be restored!",
      color: "#ffffff",
      background: "#151719",
      confirmButtonColor: "#1A1D1F",
      cancelButtonColor: "#1A1D1F",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await userService.deleteUser(row.id);
        if (response) {
          console.log("delete response ::::", response);
          Swal.fire(
            "Deleted!",
            "User has been Deleted Successfully !",
            "success"
          );
          getUserList({ page: currentPage, searchText });
        }
      }
    });
  };

  const RowSelectHandler = (row, isSelect, rowIndex, e) => {
    // ...
    if (isSelect) {
      const item = row.id || "";
      setSelectedItems([...selectedItems, item]);
    } else {
      const items = selectedItems.filter((sItem) => sItem != row.id);
      setSelectedItems(items);
    }
  };

  const escapeRegex = (str) => {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "").replace(/ /g, "");
  };

  const ValueContainer = ({ children, ...props }) => {
    console.log("props", props)
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (<>{
            props.selectProps.fieldProp ? <img src={roleicon} style={{ position: 'absolute', left: 6, width: "25px" }} alt="teamImg"></img> : <img src={teamicon} style={{ position: 'absolute', left: 6, width: "25px" }} alt="teamImg"></img>
          }</>
          )}
          {children}
        </components.ValueContainer>
      )
    );
  };

  const CustomMenuList = ({ selectProps, ...props }) => {
    const { onTeamInputChange, addTeamInputValue, onMenuInputFocus, addInput, setAddInput } = selectProps;
    // Copied from source
    const ariaAttributes = {
      "aria-autocomplete": "list",
      "aria-label": selectProps["aria-label"],
      "aria-labelledby": selectProps["aria-labelledby"]
    };

    return (
      <div>
        <components.MenuList {...props} selectProps={selectProps} />
        {addInput ? <input
          id="menulistinput"
          className="MenuInput"
          autoCorrect="off"
          autoComplete="off"
          spellCheck="false"
          type="text"
          value={addTeamInputValue}
          // onKeyPress={e => { if (e.key === "Enter") { e.preventDefault(); console.log("input") } }}
          onChange={(e) =>
            onTeamInputChange(e.currentTarget.value, {
              action: "input-change"
            })
          }
          onMouseDown={(e) => {
            e.stopPropagation();
            e.target.focus();
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
            e.target.focus();
          }}
          onFocus={onMenuInputFocus}
          placeholder="Add New Team"
          {...ariaAttributes}
        /> : <div
          className="MenuButton"
          onClick={(e) => {
            setAddInput()
          }}
        >
          <img style={{ width: "18px" }} src={Union} alt="icon" />
          <span className={"AddTeamSpan"}>Add</span>
        </div>}
      </div >
    );
  };

  const CustomRoleSelectField = ({ field, colors, form, ...props }) => {

    const containerRef = useRef(null);
    return (
      <div ref={containerRef}>
        <Select
          className="react-team-select-container"
          classNamePrefix="react-team-select"
          closeMenuOnSelect={true}
          fieldProp={"userRole"}
          defaultValue={form.values.userRoleValue}
          value={form.values.userRoleValue}
          placeholder="Assign Role"
          autoFocus={false}
          isSearchable={false}
          options={roleOptions}
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => option.id}
          onChange={(newValue, actionMeta) => {
            const { action, option, removedValue } = actionMeta;
            form.setFieldValue("userRoleValue", newValue)
            form.setFieldValue("userRole", newValue.id);
            if (newValue.id === "agent") {
              form.setFieldValue("role", 5)
            }
            else {
              form.setFieldValue("role", 2)
            }
          }}
          components={{
            ValueContainer: ValueContainer,
            Option: CustomRoleSelectOption
          }}
        />
      </div>
    );
  }

  const CustomSelectField = ({ field, colors, form, ...props }) => {
    const containerRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [addInput, setAddInput] = useState(false);

    const handleKeyDown = async (event) => {
      console.log("event.key", event.key)
      if (!inputValue) return;
      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          const response = await teamService.createTeam({
            teamName: inputValue
          });
          await getTeamlist({ limit: 1000 })
          return;
        case ' ':
          event.preventDefault();
          setInputValue((prev) => {
            return `${prev} `
          })
          return;
        case 'Backspace':
          event.preventDefault();
          let textbox = document.getElementById('menulistinput');
          let ss = textbox.selectionStart;
          let se = textbox.selectionEnd;
          let ln = textbox.value.length;

          let textbefore = textbox.value.substring(0, ss);    //text in front of selected text
          let textselected = textbox.value.substring(ss, se); //selected text
          let textafter = textbox.value.substring(se, ln);    //text following selected text

          if (ss === se) // if no text is selected
          {
            textbox.value = textbox.value.substring(0, ss - 1) + textbox.value.substring(se, ln);
            textbox.focus();
            textbox.selectionStart = ss - 1;
            textbox.selectionEnd = ss - 1;
          }
          else // if some text is selected
          {
            textbox.value = textbefore + textafter;
            textbox.focus();
            textbox.selectionStart = ss;
            textbox.selectionEnd = ss;
          }
          setInputValue(textbox.value)
          return;
        case 'Tab':
          event.preventDefault();
          setInputValue((prev) => {
            return `${prev}     `
          })
          return;
        default:
          return;
      }
    };
    const onDomClick = (e) => {
      let menu = containerRef.current.querySelector(".select__menu");
      console.log("menu", menu)
      if (
        !containerRef.current.contains(e.target) ||
        !menu ||
        !menu.contains(e.target)
      ) {
        setIsFocused(false);
        setInputValue("");
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", onDomClick);
      return () => {
        document.removeEventListener("mousedown", onDomClick);
      };
    }, []);

    return (
      <div ref={containerRef}>
        <Select
          className="react-team-select-container"
          classNamePrefix="react-team-select"
          closeMenuOnSelect={false}
          formProps={{ form }}
          defaultValue={form.values.values}
          value={form.values.values}
          placeholder="Select Team List"
          isMulti
          menuIsOpen
          autoFocus={false}
          isSearchable={false}
          options={getOptions()}
          getOptionLabel={(option) => `${option.teamName}`}
          getOptionValue={(option) => option.id}
          onKeyDown={handleKeyDown}
          onChange={(newValue, actionMeta) => {
            const { action, option, removedValue } = actionMeta;
            if (action === "select-option" && option.id === selectAllOption.id) {
              form.setFieldValue("values", teamList)
              form.setFieldValue("teamIds", teamList.map(i => i.id));
            }
            else if (action === "clear") {
              form.setFieldValue("values", [])
              form.setFieldValue("teamIds", []);
            }
            else {
              form.setFieldValue("values", newValue)
              form.setFieldValue("teamIds", newValue.map(i => i.id));
            }
            // else if (
            //   (action === "deselect-option" &&
            //     option.value === selectAllOption.value) ||
            //   (action === "remove-value" &&
            //     removedValue.value === selectAllOption.value)
            // ) {
            //   props.onChange([], actionMeta);
            // } else if (
            //   actionMeta.action === "deselect-option" &&
            //   isSelectAllSelected()
            // ) {
            //   props.onChange(
            //     props.options.filter(({ value }) => value !== option.value),
            //     actionMeta
            //   );
            // } else {
            //   props.onChange(newValue || [], actionMeta);
            // }
          }}
          components={{
            MenuList: CustomMenuList,
            ValueContainer: ValueContainer,
            Option: CustomSelectOption
          }}
          addInput={addInput}
          setAddInput={(e) => { setAddInput(true) }}
          addTeamInputValue={inputValue}
          onMenuInputFocus={() => setIsFocused(true)}
          onTeamInputChange={(val) => { setInputValue(val) }}
          {...{
            menuIsOpen: isFocused || undefined,
            isFocused: isFocused || undefined
          }}
        />
      </div>
    );
  };

  const deleteTeamHandler = async (row) => {
    const response = await teamService.deleteTeam(row.id);
    if (response) {
      getTeamlist({ limit: 1000 });
    }
  };

  const CustomSelectOption = props => (
    <components.Option {...props}>
      <div>
        {props.data.teamName}{props.data.id !== "all" && <img onClick={(e) => { e.stopPropagation(); deleteTeamHandler(props.data) }} src={bin} style={{ width: "18px", float: "right", cursor: "pointer" }} alt="icon" />}
      </div>
    </components.Option>
  )

  const CustomRoleSelectOption = props => (
    <components.Option {...props}>
      <div>
        {props.data.name}
      </div>
      <div style={{ fontSize: "10px" }}>
        {props.data.description}
      </div>
    </components.Option>
  )

  const getOptions = () => [selectAllOption, ...teamList];

  const selectAllRowsHandler = (isSelect, rows, e) => {
    if (isSelect) {
      const items = rows
        .map((rowItem) => rowItem.id)
        .filter((itemId) => {
          if (!selectedItems.includes(itemId)) return itemId;
        });
      setSelectedItems([...selectedItems, ...items]);
    } else {
      const ids = rows.map((rowItem) => rowItem.id);
      const items = selectedItems.filter((sItem) => {
        if (!ids.includes(sItem)) return sItem;
      });
      setSelectedItems(items);
    }
  };

  const deleteAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        text: "Deleted records can't be restored!",
        color: "#ffffff",
        background: "#151719",
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await userService.deleteManyUsers(selectedItems);
          if (data.success) {
            getUserList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Users Deleted Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const blockAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        text: "You Want to Block selected Users !",
        icon: "warning",
        showCancelButton: true,
        color: "#ffffff",
        background: "#151719",
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await userService.blockManyUsers(selectedItems);
          if (data.success) {
            getUserList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Users Blocked Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: RowSelectHandler,
    onSelectAll: selectAllRowsHandler,
    bgColor: "rgb(238, 193, 213)",
  };

  const getUserByRole = async (obj) => {
    const response = await userService.getUsers(obj);
    if (response.status == 200) {
      setSubAdmin(response.data || []);
    }
  };

  useEffect(() => {
    getUserList({ page: currentPage, searchText });
    getTeamlist({ limit: 100 });
    if (userData.role === 1) {
      getUserByRole({ role: 2, roleBy: userData.role });
    }
  }, [userData]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      getUserList({ page: 1, searchText });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const handleSubmit = async (formValue) => {
    try {
      delete formValue.addInput;
      delete formValue.values;
      delete formValue.userRoleValue;
      if (formValue.phoneNumber) {
        let phone = escapeRegex((formValue.phoneNumber.toString()));
        formValue.phoneNumber = phone;
      }
      let numberOfDriver = features && features.numberOfDrivers ? Number(features.numberOfDrivers) : 0;
      if (!numberOfDriver) {
        numberOfDriver = 1
      }
      if (userData.perAgentSub) {
        numberOfDriver = userData.teamSize
      }
      if (formValue.id) {
        const response = await userService.editUser(formValue);
        console.log("response", response)
        if (!response.data.success) {
          return errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
        if (response.data.success) {
          setFormData(initialValues);
          handleDrawerClose();
          getUserList({ page: currentPage, searchText });
          successAlert(
            response.data.message
              ? response.data.message
              : "User Updated Successfully !"
          );
        }
      }
      else {
        if (formValue.userRole && formValue.userRole === "agent") {
          if (
            userData.role === 1 ||
            (((features && features.unlimitedAgents && !userData.perAgentSub) || agentCount < numberOfDriver))
          ) {
            if (userData.role !== 1 && userData.userId) {
              formValue.userId = userData.userId
            }
            const response = await userService.createMember({ ...formValue, role: 5 });

            if (response.data.success) {
              setFormData(initialValues);
              getAgentCount()
              handleDrawerClose();
              successAlert(
                response.data.message
                  ? response.data.message
                  : "User Added Successfully !"
              );
              getUserList({ page: currentPage, searchText });
            }
            if (response.data.success === false) {
              errorAlert(
                response.data.message
                  ? response.data.message
                  : "Something Went Wrong !"
              );
            }

          } else {
            if (userData.perAgentSub) {
              history.push({
                pathname: "/Settings",
                state: {
                  billingTab: true
              },
              })
            }
            else {
              history.push({
                pathname: "/Business/unlimitedAgents"
              })
            }
          }
        }
        else {
          if (userData.role !== 1 && userData.userId) {
            formValue.userId = userData.userId
          }
          const response = await userService.createMember({ ...formValue, role: 2 });

          if (response.data.success) {
            setFormData(initialValues);
            getAgentCount()
            handleDrawerClose();
            successAlert(
              response.data.message
                ? response.data.message
                : "User Added Successfully !"
            );
            getUserList({ page: currentPage, searchText });
          }
          if (response.data.success === false) {
            errorAlert(
              response.data.message
                ? response.data.message
                : "Something Went Wrong !"
            );
          }
        }
      }
    } catch (err) {
      console.log("error:::", err);
    }
  };

  const cancelhandler = () => {
    setFormData(initialValues);
    handleDrawerClose();
  };

  const handlePageClick = async ({ selected }) => {
    if (selected + 1 !== currentPage) {
      setCurrentPage(selected + 1);
      await getUserList({ page: selected + 1, searchText });
    }
  };

  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.searchText
    ) {
      setSearchText(props.location.state.searchText);
    }
    getAgentCount();
  }, []);

  let allAccess = {
    create: false,
    edit: false,
    delete: false,
    block: false
  }
  if (userData) {
    if (userData.role === 1 || (userData.role === 2 && !userData.userId)) {
      allAccess = {
        create: true,
        edit: true,
        delete: true,
        block: true
      }
    }
    else if (userData.role === 2 && (userData.userRole === "admin" || userData.userRole === "manager")) {
      allAccess = {
        create: true,
        edit: true,
        delete: true,
        block: true
      }
    }
    else if (userData.role === 4 && userData.userRole === "crud") {
      allAccess = {
        create: true,
        edit: true,
        delete: true,
        block: true
      }
    }
  }

  const CustomPasswordField = ({ field, colors, form, ...props }) => {
    const [showHidePassword, changeShowHidePassword] = useState(false);

    return (
      <div className="input-container">
        <img className="password-icon" src={passwordicon} alt="passwordicon" />
        <input
          type={showHidePassword ? "text" : "password"}
          {...field}
          className="custom-password"
          name="password"
          placeholder="Password"
          autocomplete="new-password"
        />
        <img className="eye-icon" onClick={(e) => { changeShowHidePassword(!showHidePassword) }} src={showHidePassword ? hide : view} alt="passwordicon" />
      </div>
    );
  }

  return (
    <>
      <div className="agent-layout">
        <div className="customer-heading-box">
          <Row>
            <Col xs={6}>{/* <h1>Merchants</h1> */}</Col>
            <Col xs={6}>
              {allAccess.create && (
                <div className="createmsbox">
                  <div
                    onClick={(e) => {
                      setAgentTitle("Add Member");
                      handleDrawerOpen();
                    }}
                  >
                    <Button className="cticon">
                      <img src={Union} alt="icon" />
                    </Button>
                    <span>Add</span>
                  </div>
                  {/* <div>
                    <Button onClick={() => exportToCsv()}>
                      <img src={downloadicon} alt="icon" />
                    </Button>
                  </div> */}
                </div>
              )}
            </Col>
          </Row>
        </div>
        <div className="customer-searching-box">
          {(allAccess.delete && allAccess.block) && <Row>
            <Col xs={6}>
              <div className="create-action-option mb-3">
                <Dropdown>
                  <Dropdown.Toggle className="bulk-action">
                    Bulk Action
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={blockAllHandler}>
                      Block All
                    </Dropdown.Item>
                    <Dropdown.Item onClick={deleteAllHandler}>
                      Delete Selected
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
            <Col xs={6}>
              <div className="searchbar">
                <BootstrapForm className="d-flex">
                  <FormControl
                    type="search"
                    placeholder="Search or type a name"
                    className="me-2"
                    autoComplete="off"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    aria-label="Search"
                  />
                  <Button className="searchicon">
                    <img src={searchicon} alt="searchicon" />
                  </Button>
                  {/* <span className="searchF">
                                    <img src={searchF} alt="icon" />
                                </span> */}
                </BootstrapForm>
              </div>
            </Col>
          </Row>}
        </div>

        {/* customer list start */}
        <div className="customer-list-wrapper">
          <BootstrapTable
            data={users}
            striped={true}
            hover={true}
            selectRow={selectRowProp}
          >
            <TableHeaderColumn
              dataField="id"
              isKey={true}
              dataAlign="left"
              dataSort={true}
              dataFormat={NameFormatter}
              width="200px"
            >
              Name <img className="ms-1" src={ShortIcon} alt="" />
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="teamId"
              dataSort={true}
              width="200px"
              dataFormat={TeamNameFormatter}
            >
              Team
              <img className="ms-1" src={ShortIcon} alt="" />
            </TableHeaderColumn>
            {userData && userData.role === 1 && (
              <TableHeaderColumn
                dataField="userId"
                // isKey={true}
                dataAlign="left"
                // dataSort={true}
                dataFormat={UserNameFormatter}
                width="200px"
              >
                User Name <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>
            )}
            {/* <TableHeaderColumn
                        dataField="countryCode"
                        dataSort={true}
                        width="200px"
                    >
                        Country code
                        <img className="ms-1" src={ShortIcon} alt="" />
                    </TableHeaderColumn> */}
            <TableHeaderColumn
              dataField="userRole"
              dataSort={true}
              width="200px"
              dataFormat={RoleFormatter}
            >
              Role
              <img className="ms-1" src={ShortIcon} alt="" />
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="phoneNumber"
              dataSort={true}
              width="200px"
              dataFormat={PhoneFormatter}
            >
              Phone
              <img className="ms-1" src={ShortIcon} alt="" />
            </TableHeaderColumn>
            <TableHeaderColumn dataField="email" dataSort={true} width="250px">
              Email
              <img className="ms-1" src={ShortIcon} alt="" />
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="block"
              dataFormat={formatStatus}
              width="150px"
            >
              Status
              <img className="ms-1" src={ShortIcon} alt="" />
            </TableHeaderColumn>

            {/* <TableHeaderColumn
        dataField="companyName"
        dataSort={true}
        width="200px"
      >
        Number of Ask
        <img className="ms-1" src={ShortIcon} alt="" />
      </TableHeaderColumn> */}
            {(allAccess.edit && allAccess.delete) && <TableHeaderColumn
              dataField="Action"
              dataAlign="right"
              dataFormat={Remove}
              width="150px"
            >
              Action
            </TableHeaderColumn>}
          </BootstrapTable>
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>

        {/* customer list end */}
      </div>
      {/* create agent popup start */}
      <div className="opencustomer-popup" open={open}>
        <div className="addCustomer-popup">
          <div className="addcustomer-slide">
            <div className="addcustomer-field">
              <span className="title">{agentTitle}</span>
              <div className="customer-import">
                <div className="customer-import-field">
                  {/* <Button>
                                    <img src={Union} alt="icon" />
                                </Button>
                                <span>import</span>
                                <Button>
                                    <img src={downloadicon} alt="icon" />
                                </Button> */}
                </div>
                <Button
                  className="createcustomer-close"
                  onClick={handleDrawerClose}
                >
                  <img src={closeicon} alt="icon" />
                </Button>
              </div>
            </div>

            <div className="addcustomer-information">
              <h2>Member Information</h2>
              <div className="customer-form">
                <Formik
                  enableReinitialize
                  initialValues={formData}
                  render={(formProps) => {
                    return (
                      <Form>
                        <Row className="custinfo-form">
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="firstname">
                                <img src={nameicon} alt="nameicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="First Name"
                                name="firstName"
                                aria-label="First Name"
                                maxLength="25"
                              />
                              <ErrorMessage name="firstName" component="div" />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="lastname">
                                <img src={nameicon} alt="lasticon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="Last Name"
                                name="lastName"
                                maxLength="25"
                                aria-label="Last Name"
                              />
                              <ErrorMessage name="lastName" component="div" />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup className="mb-3 teamsl">
                              <InputGroup.Text id="roleicon">
                                <img src={roleicon} alt="roleicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                name="countryCode"
                                as="select"
                              >
                                <option selected>Select country code</option>
                                <option value="+91">+91</option>
                                <option value="+1">+1</option>
                                <option value="+44">+44</option>
                              </Field>
                              <ErrorMessage
                                name="countryCode"
                                component="div"
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="phonenumber">
                                <img src={phoneicon} alt="phoneicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="Phone Number"
                                name="phoneNumber"
                                aria-label="Phone Number"
                              />
                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="yourworkemail">
                                <img src={mailicon} alt="emailicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="Email"
                                name="email"
                                aria-label="Your email"
                              />
                              {/* <ErrorMessage name="email" component="div" /> */}
                            </InputGroup>
                          </Col>

                          {/* <Col xs={6}>
                            <InputGroup className="mb-3 teamsl">
                              <InputGroup.Text id="teamicon">
                                <img src={teamicon} alt="teamicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                name="teamId"
                                as="select"
                              >
                                <option selected>Assign Team</option>
                                {teamList &&
                                  teamList.length > 0 &&
                                  teamList.map((t) => (
                                    <option value={t.id}>{t.teamName}</option>
                                  ))}
                              </Field>
                              <ErrorMessage name="teamId" component="div" />
                            </InputGroup>
                          </Col> */}
                          <Col xs={6}>
                            {/* <InputGroup className="mb-3 teamsl"> */}
                            {/* <InputGroup.Text id="teamicon"> */}
                            {/* <img src={teamicon} alt="teamicon" /> */}
                            {/* </InputGroup.Text> */}
                            <Field
                              className="form-control"
                              name="teamIds"
                              // as="select"
                              component={CustomSelectField}
                            >
                            </Field>
                            <ErrorMessage name="teamIds" component="div" />
                            {/* </InputGroup> */}
                          </Col>
                          <Col xs={6}>
                            <Field
                              className="form-control"
                              placeholder="Password"
                              name="password"
                              maxLength="25"
                              aria-label="Password"
                              component={CustomPasswordField}
                            />
                            <ErrorMessage name="password" component="div" />
                          </Col>
                          {/* <Col xs={6}>
                                                    <InputGroup className="mb-3 teamsl">
                                                        <InputGroup.Text id="roleicon">
                                                            <img src={roleicon} alt="roleicon" />
                                                        </InputGroup.Text>
                                                        <Field className="form-control" name="userRole" as="select">
                                                            <option selected >Assign Role</option>
                                                            <option value="view">View Only</option>
                                                            <option value="crud">CRUD</option>
                                                            <option value="super">Super Merchant</option>
                                                        </Field>
                                                        <ErrorMessage name="userRole" component="div" />
                                                    </InputGroup>
                                                </Col> */}
                          <Col xs={6}>
                            {/* <InputGroup className="mb-3 teamsl"> */}
                            {/* <InputGroup.Text id="teamicon"> */}
                            {/* <img src={teamicon} alt="teamicon" /> */}
                            {/* </InputGroup.Text> */}
                            <Field
                              className="form-control"
                              name="userRole"
                              // as="select"
                              component={CustomRoleSelectField}
                            >
                            </Field>
                            <ErrorMessage name="userRole" component="div" />
                            {/* </InputGroup> */}
                          </Col>
                          {userData && userData.role === 1 && !userData.subAdmin && (
                            <Col xs={6}>
                              <InputGroup className="mb-3 teamsl">
                                <InputGroup.Text id="roleicon">
                                  <img src={roleicon} alt="roleicon" />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  name="user"
                                  as="select"
                                >
                                  <option selected>Select User</option>
                                  {subAdmin &&
                                    subAdmin.map((data) => {
                                      return (
                                        <option value={data.id}>
                                          {data.firstName + " " + data.lastName}
                                        </option>
                                      )
                                    })}
                                </Field>
                                <ErrorMessage name="user" component="div" />
                              </InputGroup>
                            </Col>
                          )}
                        </Row>
                        <div className="ct-btn">
                          <Button className="cncle-btn" onClick={cancelhandler}>
                            Cancel
                          </Button>
                          <Button className="ctaskbtn" type="submit">
                            Let’s Go
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                ></Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* create agent popup end */}
    </>
  );
};

export default User;
