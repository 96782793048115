import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import authService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

export const defaultSubscription = createAsyncThunk(
    "users/defaultSubscription",
    async ({ userId }, thunkAPI) => {
        try {
            console.log(userId, "userId")
            const data = await authService.getCommonServices({ type: ["DEFAULT_SUBSCRIPTION"] });
            console.log(data, "--default subscription data")
            if (data.status === 200) {

                return { extension: data.data, message: data.message, type: 'success' };
            } else {

                console.log("--default subscription data")
                thunkAPI.rejectWithValue();
                return { message: data.message, type: 'danger' }
            }

        } catch (error) {
            console.log("error::", error)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);


const initialState = { defaultSubscription: null, message: "", type: "" }


const subscriptionSlice = createSlice({
    name: "defaultSubscription",
    initialState,
    extraReducers: {
        [defaultSubscription.fulfilled]: (state, action) => {
            state.message = action.payload.message;
            state.type = action.payload.type;
            state.extension = action.payload.extension;
        },
        [defaultSubscription.rejected]: (state, action) => {
            state.extension = null;
            state.message = action.payload.message;
            state.type = action.payload.type;
        }
    },
});

const { reducer } = subscriptionSlice;
export default reducer;