export const CREATE_CUSTOMER_API = "CREATE_CUSTOMER_API";
export const EDIT_CUSTOMER_API = "EDIT_CUSTOMER_API";
export const GET_CUSTOMER_API = "GET_CUSTOMER_API";
export const DELETE_CUSTOMER_API = "DELETE_CUSTOMER_API";
export const GET_TASK_LIST_API = "GET_TASK_LIST_API";
export const SAVE_CUSTOMER_LIST = "SAVE_CUSTOMER_LIST";
export const SAVE_INVOICE_TASK_LIST = "SAVE_INVOICE_TASK_LIST";
export const GET_INVOICE_LIST_API = "GET_INVOICE_LIST_API";
export const CREATE_INVOICE_API = "CREATE_INVOICE_API";
export const EDIT_INVOICE_API = "EDIT_INVOICE_API";
export const DELETE_INVOICE_API = "DELETE_INVOICE_API";
export const SAVE_INVOICE_LIST = "SAVE_INVOICE_LIST";
export const CREATE_PAYMENT_API = "CREATE_PAYMENT_API";
export const EDIT_PAYMENT_API = "EDIT_PAYMENT_API";
export const SAVE_PAYMENT_LIST = "SAVE_PAYMENT_LIST";
export const GET_PAYMENT_API = "GET_PAYMENT_API";
export const GET_DASHBOARD_METRICS_API = "GET_DASHBOARD_METRICS_API";
export const SAVE_DASHBOARD_METRICS = "SAVE_DASHBOARD_METRICS";
export const SEND_INVOICE = "SEND_INVOICE";
export const PRINT_INVOICE_PDF = "PRINT_INVOICE_PDF";