// TODO: Resolve lint issues
/* eslint-disable */
import {
    put,
    takeLatest,
    all,
    call,
    takeEvery,
} from 'redux-saga/effects'

/********************* ACTION TYPE ********************* */
import {
    GET_CUSTOMER_API,
    CREATE_CUSTOMER_API,
    EDIT_CUSTOMER_API,
    GET_TASK_LIST_API,
    GET_INVOICE_LIST_API,
    CREATE_INVOICE_API,
    EDIT_INVOICE_API,
    DELETE_INVOICE_API,
    DELETE_CUSTOMER_API,
    CREATE_PAYMENT_API,
    GET_PAYMENT_API,
    EDIT_PAYMENT_API,
    GET_DASHBOARD_METRICS_API,
    SEND_INVOICE,
    PRINT_INVOICE_PDF
} from './actionTypes';

/*************************** ACTIONS *******************/
import { saveCustomerList, saveDashbaordMetrics, saveInvoiceList, savePaymentList, saveTaskList } from './actions';
import { apiSagaHandler } from '../network/saga';
import { errorReducerHandler } from '../alert/actions'
import { showWarning } from '../alert/actions';
import { clean } from '../../helpers/helperFunctions';
import { USER_ROLES } from '../../helpers/configDefaults';
const API_URL = "invoicing/"
/************************ USER SAGA *******************************/
function* saga() {
    yield all([
        call(getCustomerListApi),
        call(createCustomerApi),
        call(editCustomerApi),
        call(getTaskListApi),
        call(getInvoiceListApi),
        call(createInvoiceApi),
        call(editInvoiceApi),
        call(deleteInvoiceApi),
        call(deleteCustomerApi),
        call(createPaymentApi),
        call(getPaymnetApi),
        call(editPaymentApi),
        call(getDashboardMetricsApi),
        call(sendInvoiceApi),
        call(printInvoicePdfApi),
    ]);
}

function* getCustomerListApi() {
    yield takeEvery(GET_CUSTOMER_API, getCustomerListHandler)
}

function* createCustomerApi() {
    yield takeLatest(CREATE_CUSTOMER_API, createCustomerHandler)
}

function* editCustomerApi() {
    yield takeLatest(EDIT_CUSTOMER_API, editCustomerHandler)
}

function* deleteCustomerApi() {
    yield takeLatest(DELETE_CUSTOMER_API, deleteCustomerHandler)
}

function* getTaskListApi() {
    yield takeLatest(GET_TASK_LIST_API, getTasksListHandler)
}

function* getInvoiceListApi() {
    yield takeLatest(GET_INVOICE_LIST_API, getInvoiceListHandler)
}

function* createInvoiceApi() {
    yield takeLatest(CREATE_INVOICE_API, createInvoiceHandler)
}

function* createPaymentApi() {
    yield takeLatest(CREATE_PAYMENT_API, createPaymentHandler)
}

function* getPaymnetApi() {
    yield takeLatest(GET_PAYMENT_API, getPaymentHandler)
}

function* editPaymentApi() {
    yield takeLatest(EDIT_PAYMENT_API, editPaymentHandler)
}

function* editInvoiceApi() {
    yield takeLatest(EDIT_INVOICE_API, editInvoiceHandler)
}

function* deleteInvoiceApi() {
    yield takeLatest(DELETE_INVOICE_API, deleteInvoiceHandler)
}

function* getDashboardMetricsApi() {
    yield takeLatest(GET_DASHBOARD_METRICS_API, getDashboardMetrics)
}

function* sendInvoiceApi() {
    yield takeLatest(SEND_INVOICE, sendInvoice)
}

function* printInvoicePdfApi() {
    yield takeLatest(PRINT_INVOICE_PDF, printInvoicePDF)
}

function* getCustomerListHandler(data) {
    let { payload, resCall, saveLimit, saveInRedux } = data?.data;
    let config = {
        "endpoint": `${API_URL}customers`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.saveLimit = saveLimit
        if (saveInRedux) yield put(saveCustomerList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}
function* createCustomerHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall, role } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}customers`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.createApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage;
        apiRes.pageLimit = pageLimit;
        apiRes.pageCount = pageCount;
        yield put(saveCustomerList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* deleteCustomerHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}customers/delete`,
        "auth": true,
        "method": "DELETE",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.deleteApi = true;
        yield put(saveCustomerList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}


function* deleteManyCustomerHandler(data) {
    let { payload, resCall, listPayload, role } = data?.data;
    let config = {
        "endpoint": `${API_URL}multipleDelete`,
        "auth": true,
        "method": "DELETE",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield getUserListHandler({ data: { payload: listPayload, role: role } })
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* editCustomerHandler(data) {
    let { payload, resCall, role } = data?.data;
    let config = {
        "endpoint": `${API_URL}customers`,
        "auth": true,
        "method": "PUT",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.editApi = true;
        yield put(saveCustomerList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}


function* blockCustomerHandler(data) {
    let { payload, resCall, role } = data?.data;
    let config = {
        "endpoint": `${API_URL}multipleBlock`,
        "auth": true,
        "method": "PATCH",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* getTasksListHandler(data) {
    let { payload, resCall, saveLimit, auth, saveInRedux } = data?.data;
    if (payload.date) {
        const dateQuery = getdateQuery(payload.date, "orderDate")
        delete payload.date
        payload = { ...payload, ...dateQuery }
    }
    let config = {
        "endpoint": `${API_URL}allTasks`,
        "auth": auth,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.saveLimit = saveLimit
        if (saveInRedux) yield put(saveTaskList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}


function* getInvoiceListHandler(data) {
    let { payload, resCall, saveLimit, saveInRedux } = data?.data;
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.saveLimit = saveLimit
        if (saveInRedux) yield put(saveInvoiceList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}
function* createInvoiceHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall, role } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.createApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage;
        apiRes.pageLimit = pageLimit;
        apiRes.pageCount = pageCount;
        yield put(saveInvoiceList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* editInvoiceHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall, role } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "PUT",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.editApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage;
        apiRes.pageLimit = pageLimit;
        apiRes.pageCount = pageCount;
        yield put(saveInvoiceList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* deleteInvoiceHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall, role } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "DELETE",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.deleteApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage;
        apiRes.pageLimit = pageLimit;
        apiRes.pageCount = pageCount;
        yield put(saveInvoiceList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* createPaymentHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall, role } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}/payments`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.createApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage;
        apiRes.pageLimit = pageLimit;
        apiRes.pageCount = pageCount;
        yield put(savePaymentList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* getPaymentHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall, role } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}/payments`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.getApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage;
        apiRes.pageLimit = pageLimit;
        apiRes.pageCount = pageCount;
        yield put(savePaymentList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* editPaymentHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall, role } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}/payments`,
        "auth": true,
        "method": "PUT",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.editApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage;
        apiRes.pageLimit = pageLimit;
        apiRes.pageCount = pageCount;
        yield put(saveInvoiceList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* getDashboardMetrics(data) {
    let { payload,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}/dashboardMetrics`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        yield put(saveDashbaordMetrics(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* sendInvoice(data) {
    let { payload,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}/sendInvoice`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* printInvoicePDF(data) {
    let { payload,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}/printInvoice`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: "blob"
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

export default saga;
