import {
    SAVE_TEAM_LIST
} from "./actionTypes";

let initialState = {
    teamList: [],
    pageCount: 1,
    pageLimit: 10
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_TEAM_LIST:
            let teamList = JSON.parse(JSON.stringify(state.teamList))
            if (action.payload.createApi) {
                delete action.payload.createApi;
                if (action.payload.sortBy) {
                    let split = action.payload.sortBy.split(":");
                    if (split[1] === "desc") {
                        if (action.payload.currentPage === 1) {
                            teamList.unshift(action.payload.team)
                            if (teamList.length > action.payload.pageLimit) {
                                teamList.splice(action.payload.pageLimit, 1)
                            }
                        }
                    }
                    else {
                        if (action.payload.currentPage === action.payload.pageCount) {
                            if (teamList.length < action.payload.pageLimit) {
                                teamList.push(action.payload.team)
                            }
                        }
                    }
                }

            }
            else if (action.payload.editApi) {
                delete action.payload.editApi;
                let teamIndex = teamList.findIndex(i => {
                    return i.id === action.payload.team.id
                })
                teamList[teamIndex] = action.payload.team
            }
            else {
                teamList = action.payload.results || [];
            }
            return {
                ...state,
                teamList: teamList,
                ...(action.payload.totalPages && { pageCount: action.payload.totalPages }),
                ...(action.payload.limit && { pageLimit: action.payload.limit })
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;