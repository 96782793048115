// TODO: Resolve lint issues
/* eslint-disable */
import {
    put,
    takeLatest,
    all,
    call,
    takeEvery,
} from 'redux-saga/effects'

/********************* ACTION TYPE ********************* */
import {
    GET_USER_API,
    GET_STRIPE_PLANS,
    GET_STRIPE_TRANSACTIONS,
    DELETE_MANY_USER_API,
    DELETE_USER_API,
    DELETE_USER_SUBSCRIPTION_API,
    BLOCK_MANY_USER_API,
    EDIT_USER_API,
    CREATE_USER_API,
    CREATE_USER_SUBSCRIPTION_API,
    GET_USER_COUNT_API,
    GET_MULTI_TYPE_USER_API,
} from './actionTypes';

/*************************** ACTIONS *******************/
import { saveMultiTypeUserList, saveStripePlans, saveStripeTransactions, saveUserCount, saveUserList } from './actions';
import { apiSagaHandler } from '../network/saga';
import { errorReducerHandler } from '../alert/actions'
import { showWarning } from '../alert/actions';
import { clean } from '../../helpers/helperFunctions';
import { USER_ROLES } from '../../helpers/configDefaults';
const API_URL = "users/"
/************************ USER SAGA *******************************/
function* saga() {
    yield all([
        call(getUserListApi),
        call(getMultiTypeUserListApi),
        call(createUserApi),
        call(editUserApi),
        call(deleteUserApi),
        call(blockUserApi),
        call(deleteManyUserApi),
        call(getStripePlansApi),
        call(getUserCountApi),
        call(getStripeTransactionsApi),
        call(createUserSubscriptionApi),
        call(deleteUserSubscriptionApi),
        // call(getAllUserApi),
    ]);
}

function* getUserListApi() {
    yield takeEvery(GET_USER_API, getUserListHandler)
}

function* createUserApi() {
    yield takeLatest(CREATE_USER_API, createUserHandler)
}

function* editUserApi() {
    yield takeLatest(EDIT_USER_API, editUserHandler)
}

function* deleteUserApi() {
    yield takeLatest(DELETE_USER_API, deleteUserHandler)
}

function* blockUserApi() {
    yield takeLatest(BLOCK_MANY_USER_API, blockUserHandler)
}

function* deleteManyUserApi() {
    yield takeLatest(DELETE_MANY_USER_API, deleteManyUserHandler)
}

function* getStripePlansApi() {
    yield takeLatest(GET_STRIPE_PLANS, getStripePlansHandler)
}

function* getUserCountApi() {
    yield takeLatest(GET_USER_COUNT_API, getUserCountHandler)
}

function* getStripeTransactionsApi() {
    yield takeLatest(GET_STRIPE_TRANSACTIONS, getStripeTransactionsHandler)
}

function* createUserSubscriptionApi() {
    yield takeLatest(CREATE_USER_SUBSCRIPTION_API, createUserSubscriptionHandler)
}

function* deleteUserSubscriptionApi() {
    yield takeLatest(CREATE_USER_SUBSCRIPTION_API, deleteUserSubscriptionHandler)
}

function* getMultiTypeUserListApi() {
    yield takeLatest(GET_MULTI_TYPE_USER_API, getMultiTypeUserListHandler)
}

// function* getAllUserApi() {
//     yield takeLatest(GET_ALL_USER_LIST, getAllUserListHandler)
// }

// function* getAllUserListHandler(data) {
//     let { payload, resCall } = data?.data;
//     let config = {
//         "endpoint": `${API_URL}all`,
//         "auth": true,
//         "method": "GET",
//         "localApi": false,
//         params: {
//             ...payload
//         },
//         headers: {
//             'Content-Type': 'application/json'
//         },
//     }

//     const apiRes = yield apiSagaHandler(config)
//     if (apiRes) {
//         if (resCall) resCall(apiRes)
//     }
//     else {
//         yield put(errorReducerHandler(apiRes));
//     }
// }

function* getUserCountHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}user/count`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }
    console.log("configconfigconfig", config)
    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.role = payload.role;
        yield put(saveUserCount(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* getUserListHandler(data) {
    let { payload, resCall, saveLimit, saveInRedux } = data?.data;
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.role = payload.role;
        apiRes.saveLimit = saveLimit
        if (saveInRedux) yield put(saveUserList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}
function* getMultiTypeUserListHandler(data) {
    let { payload, resCall, saveLimit, saveInRedux } = data?.data;
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.role = payload.role;
        apiRes.multiUserType = true
        apiRes.saveLimit = saveLimit
        if (saveInRedux) yield put(saveMultiTypeUserList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* createUserHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall, role } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.createApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage;
        apiRes.pageLimit = pageLimit;
        apiRes.pageCount = pageCount;
        apiRes.role = role;
        yield put(saveUserList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* deleteUserHandler(data) {
    let { payload, resCall, listPayload, role } = data?.data;
    let config = {
        "endpoint": `${API_URL}/${payload.id}`,
        "auth": true,
        "method": "DELETE",
        "localApi": false,
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield getUserListHandler({ data: { payload: listPayload, role: role } })
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}


function* deleteManyUserHandler(data) {
    let { payload, resCall, listPayload, role } = data?.data;
    let config = {
        "endpoint": `${API_URL}multipleDelete`,
        "auth": true,
        "method": "DELETE",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield getUserListHandler({ data: { payload: listPayload, role: role } })
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}

function* editUserHandler(data) {
    let { payload, resCall, role } = data?.data;
    let config = {
        "endpoint": `${API_URL}/${payload.id}`,
        "auth": true,
        "method": "PATCH",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.editApi = true;
        apiRes.role = role;
        yield put(saveUserList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}


function* blockUserHandler(data) {
    let { payload, resCall, role } = data?.data;
    let config = {
        "endpoint": `${API_URL}multipleBlock`,
        "auth": true,
        "method": "PATCH",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        if (resCall) resCall(apiRes)
    }
}


function* getStripePlansHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}stripe-plans-final`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield put(saveStripePlans(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* getStripeTransactionsHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}stripe-transactions`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield put(saveStripeTransactions(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* createUserSubscriptionHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}extension/subscription`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield put(saveStripePlans(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* deleteUserSubscriptionHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}delete-subscription`,
        "auth": true,
        "method": "PATCH",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield put(saveStripePlans(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

export default saga;
