import { MdOutlineAllInbox, MdOutlineClose } from "react-icons/md"
import { formatAMPM, renderCustomerName, timeZoneHeader } from "../../../helpers/helperFunctions"
import TaskRightSideDetails from "../../SupervisePlanner/components/Rightsidebar/RightSideTaskDetails"
import "./rightsidebar.scss"
import moment from "moment"
import { IoMdTime } from "react-icons/io"
import InvoiceForm from "./invoiceForm"
import PaymentForm from "./paymentForm"
import Swal from "sweetalert2";
import swalIcon from "../../../assets/image/swalIcon.png"
const timezone = { ...timeZoneHeader() }

const RightSideBar = (props) => {
    const handleCancel = () => {
        Swal.fire({
            title: "Do you want to leave without saving?",
            text: "If you leave without saving, this invoice will be lost",
            // iconHtml: `<img src="${IoMdTime}">`,
            imageUrl: swalIcon,
            showCancelButton: true,
            color: "#000000",
            background: "#ffffff",
            confirmButtonColor: "#7066E0",
            cancelButtonColor: "#7066E0",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                props.setCreateInvoice(false);
                props.setButtonText("Create");
                props.setShowPaymentForm(false)
                props.setPaymentForm(props.initialPaymentForm)
                props.setInvoiceForm(props.initialValues)
            }
        })

    }

    return (<div className="invoice-right-sidebar-container">
        {!props.createTask && <div className="invoice-right-side-bar-wrapper">
            {<>
                <div className="top">
                    <div className="close-btn">
                        <MdOutlineClose className="icon" onClick={() => { handleCancel() }} />
                    </div>
                </div>
                {props.invoicePage && !props.showPaymentForm && <div className="bottom">
                    <InvoiceForm {...props} handleCancel={handleCancel}/>
                </div>}
                {props.showPaymentForm && <div className="bottom"><PaymentForm {...props} handleCancel={handleCancel} /></div>}
            </>}
        </div>}
    </div >)
}

export default RightSideBar