import moment from "moment";
import TopBar from "../../components/Topbar/Topbar";
import RightSideBar from "../../components/Rightsidebar/Rightsidebar";
import BottomMenu from "../../components/BottomMenu/BottomMenu";
import { Rnd } from "react-rnd";
import TaskContainer from "../../components/TaskContainer";
import MapComponent from "../../components/ResizeMap/MapComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pending_pin from "../../../../assets/image/Planner_map_pins/Pending_pin.png"
import UnPending_pin from "../../../../assets/image/Planner_map_pins/UnPending_pin.png"
import NewOrder_pin from "../../../../assets/image/Planner_map_pins/NewOrder_pin.png"
import UnNewOrder_pin from "../../../../assets/image/Planner_map_pins/UnNewOrder_pin.png"
import { timeZoneHeader } from "../../../../helpers/helperFunctions";
import momentTz from "moment-timezone"
import { UPDATE_MULTIPLE_TASKS_API } from "../../../../redux/task/actionTypes";
import authService from "../../../../services/auth.service";

const Planner = (props) => {
  let { datedTaskList, tasksList, missionsList, tasksListWithouDate, tasksListPendingAction } = useSelector((state) => state.task)
  const [datedBookMarks, setDatedBookMarks] = useState([]);
  const [pendingBookMarks, setPendingBookMarks] = useState([]);
  const [bookMarks, setBookMarks] = useState([]);
  const [addLabel, setAddLabel] = useState(null);
  const userData = useSelector((state) => state.auth.user)
  const dispatch = useDispatch();

  const getBookmarks = (tasks, type) => {
    const data = tasks.map((t) => {
      let checkIfMarkerAvailable = props.datedBookMarks.find(i => i && i.id === t.id);
      if (checkIfMarkerAvailable) {
        if (t.taskStatus === "unassigned") {
          if (t.actionPendingCustomer) {
            checkIfMarkerAvailable.markerImage = Pending_pin;
            checkIfMarkerAvailable.selectedMarkerImage = UnPending_pin;
          }
          else {
            checkIfMarkerAvailable.markerImage = NewOrder_pin;
            checkIfMarkerAvailable.selectedMarkerImage = UnNewOrder_pin;
          }
        }
        if (t.markerImage && !!t.orderDate) {
          checkIfMarkerAvailable.markerImage = t.markerImage;
        }
        if (t.selectedMarkerImage && !!t.orderDate) {
          checkIfMarkerAvailable.selectedMarkerImage = t.selectedMarkerImage;
        }
        return checkIfMarkerAvailable
      }
      if (t && t.location && !checkIfMarkerAvailable) {
        let data = {
          id: t.id, ...t.location, address: t.address, position: t.position, orderType: t.orderType,
          customerId: t.customerId, agentId: t.agentId, teamId: t.teamId, taskStatus: t.taskStatus,
          optimised: t.missionId && t.missionId.optimised ? t.missionId.optimised : false,
          missionDetails: t.missionId && t.missionId.missionDetails ? t.missionId.missionDetails : {},
          merchantId: t.merchantId, createdFor: t.createdFor, after: t.after, before: t.before, scheduledTime: t.scheduledTime,
          capacity: t.capacity, capacityUnit: t.capacityUnit, eta: "__", etaSeconds: t.etaSeconds,
          markerType: "TASK", missioned: t.missioned, updatedAt: t.updatedAt, orderCompletedDate: t.orderCompletedDate,
          selected: t.active ? t.active : false,
          customer: t.customer
        }
        if (t.taskStatus === "unassigned") {
          if (t.actionPendingCustomer) {
            data.markerImage = Pending_pin;
            data.selectedMarkerImage = UnPending_pin;
          }
          else {
            data.markerImage = NewOrder_pin;
            data.selectedMarkerImage = UnNewOrder_pin;
          }
        }
        if (t.markerImage && !!t.orderDate) {
          data.markerImage = t.markerImage;
        }
        if (t.selectedMarkerImage && !!t.orderDate) {
          data.selectedMarkerImage = t.selectedMarkerImage;
        }
        return data;
      }
      return null
    });
    if (type === "UNASSIGNED") {
      setBookMarks(data)
    }
    else if (type === "PENDING") {
      setPendingBookMarks(data)
    }
    else {
      setDatedBookMarks(data)
      props.setDatedBookMarks(data)
    }
  }


  useEffect(() => {
    if (tasksListWithouDate.length) {
      getBookmarks(tasksListWithouDate, "UNASSIGNED")
    }
    else {
      setBookMarks([])
    }
  }, [tasksListWithouDate])

  useEffect(() => {
    if (tasksListPendingAction.length) {
      getBookmarks(tasksListPendingAction, "PENDING")
    }
    else {
      setPendingBookMarks([])
    }
  }, [tasksListPendingAction])

  useEffect(() => {
    if (tasksList.length || missionsList.length) {
      let newDatedTaskList = JSON.parse(JSON.stringify(tasksList))
      let newMissionsList = JSON.parse(JSON.stringify(missionsList))
      let allDatedTaskList = [];
      let allDates = props.getDates(props.date.startDate, props.date.endDate, newDatedTaskList, newMissionsList)
      props.setAllDates(allDates)
      props.allDatesRef.current = allDates
      for (let dates of allDates) {
        dates.bookmarkTaskList = dates.bookmarkTaskList.map(i => {
          i.markerImage = dates.markerImage
          i.selectedMarkerImage = dates.selectedMarkerImage
          return i
        })
        allDatedTaskList = [...allDatedTaskList, ...dates.bookmarkTaskList]
      }
      getBookmarks(allDatedTaskList, "DATED")
    }
    else {
      setDatedBookMarks([])
      props.setDatedBookMarks([])
    }
  }, [tasksList, missionsList])

  const onDrop = async (ev, data, dropId) => {
    ev.preventDefault();
    let apiData
    let timezone = { ...timeZoneHeader() };
    let orderDate = moment().tz(timezone.timezone).startOf("d");
    let draggedTask = ev.dataTransfer.getData("drag-item")
    if (props.selectedTasks.length > 1) {
      if (dropId === "unassignedTaskList") {
        orderDate = null
      }
      else if (dropId.split("_")[0] === "date") {
        // orderDate = momentTz(new Date(dropId.split("_")[1])).tz(timezone.timezone).startOf("d")._d
        // orderDate = new Date(dropId.split("_")[1])
        orderDate = moment(dropId.split("_")[1]).tz(timezone.timezone).startOf("d")
      }
      apiData = {
        taskIds: props.selectedTasks,
        orderDate: orderDate
      }
    }
    else if (draggedTask) {
      if (dropId === "unassignedTaskList") {
        orderDate = null
      }
      else if (dropId.split("_")[0] === "date") {
        // orderDate = new Date(dropId.split("_")[1])
        orderDate = moment(dropId.split("_")[1]).tz(timezone.timezone).startOf("d")
      }
      apiData = {
        taskIds: [draggedTask],
        orderDate: orderDate
      }
    }

    if (apiData) {
      if (apiData.orderDate) {
        apiData.orderDate = momentTz(orderDate).tz(timezone.timezone).startOf("d")._d
      }
      let startDate = props.date.startDate;
      let endDate = props.date.endDate;
      let taskApiData = {
        orderDate: props.selectedDateRange(startDate, endDate),
        withoutDate: true,
        pageLimit: 100,
      }
      if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
        taskApiData.userIds = userData.permittedUsers
      }
      const currentUser = userData;
      if (currentUser) taskApiData.userId = currentUser.id
      dispatch({
        type: UPDATE_MULTIPLE_TASKS_API, data: {
          payload: apiData,
          ...taskApiData,
          resCall: (res) => {
            props.setSelectedTasks([])
          }
        }
      })
    }
  }

  const mapProps = {
    ...props,
    datedBookMarks,
    pendingBookMarks,
    bookMarks,
    missionsList,
    unassignedTaskList: tasksList,
    taskToUpdate: "tasksList"
  }
  const rightSidebarProps = {
    ...props, onDrop,
    minHeight: "300px"
  }
  const topbarProps = {
    ...props.topbarProps
  }
  const taskContainerProps = {
    ...props,
    onDrop
  }

  const [values, setValues] = useState([])
  const [inputValue, setInputValue] = useState("");
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(inputValue, 500);
  // Effect for API call
  useEffect(
    () => {
      console.log("debouncedSearchTerm", debouncedSearchTerm)
      if (debouncedSearchTerm || debouncedSearchTerm === "") {
        submitPlannerLabel(debouncedSearchTerm)
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
  const submitPlannerLabel = async (label) => {
    if (addLabel) {
      let data = {
        dayOfWeek: addLabel.dayOfWeek,
        label: label,
        type: "PLANNER_LABELS"
      }
      if (addLabel.id) {
        data.id = addLabel.id
      }
      let response = await authService.saveCommonServices(data);
      if (response && response.data) {
        let allDates = props.getDates(props.date.startDate, props.date.endDate, [], []);
        let dayOfWeeks = allDates.map(i => i.dayOfWeek);
        props.getPlannerLabels({ dayOfWeeks })
      }
    }
  }
  return (<>
    <div className="planner-main-container">
      <div className="map-top-container">
        <TopBar {...topbarProps} />
        <div className="weather-fixed-div">
          {props.allDatesRef && props.allDatesRef.current && props.allDatesRef.current.map((items) => {
            return (<div className="weather-details">
              <div className="day-of-week">
                <span>{moment(items.date).format('dddd').substring(0, 3)}</span>
              </div>
              <div className="date-of-week">
                <span className={`date-span ${items.today ? "today" : ""}`}>{moment(items.date).format('DD')}</span>
              </div>
            </div>)
          })}
        </div>
        <div className="weather-fixed-div">
          {props.allDatesRef && props.allDatesRef.current && props.allDatesRef.current.map((items, index) => (<div key={index} className="task-line" style={{ borderColor: items.color }}></div>))}
        </div>
        <div className="weather-fixed-div">
          {props.allDatesRef && props.allDatesRef.current && props.allDatesRef.current.map((items) => {
            let findLabel = props.plannerLabels?.find(i => Number(i.dayOfWeek) === Number(items.dayOfWeek));
            return (
              <input
                key={items.dayOfWeek}
                id={items.dayOfWeek}
                className={`planner-labels`}
                style={{ color: items.color }}
                defaultValue={findLabel && findLabel.label ? findLabel.label : ""}
                onChange={(e) => {
                  setAddLabel({ dayOfWeek: Number(items.dayOfWeek), id: findLabel && findLabel.id })
                  setInputValue(e.target.value)
                }}
                placeholder="+ Add Label"
                type={"text"} />
            )
          })}
        </div>
        <div>
          <Rnd
            className={"resize-container"}
            disableDragging={true}
            enableResizing={{ top: false, right: false, bottom: true, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
            size={{ width: props?.size?.width, height: props?.size?.height }}
            // position={{ x: this.state.x, y: this.state.y }}
            // onDragStop={(e, d) => { this.setState({ x: d.x, y: d.y }) }}
            onResizeStop={(e, direction, ref, delta, position) => {
              props.setSize({
                width: ref.style.width,
                height: ref.style.height,
                ...position,
              });
            }}
            onResize={(e, direction, ref, delta, position) => {
              props.setSize({
                width: ref.style.width,
                height: ref.style.height,
                ...position,
              });
            }}
          >
            <TaskContainer {...taskContainerProps} />
          </Rnd>
        </div>
        <div style={{ borderBottom: "4px solid grey", margin: "auto", marginBottom: "5px", marginTop: "5px", borderRadius: "2px", width: "90px", position: "relative" }}></div>
        <div style={props.mapSize}><MapComponent {...mapProps} /></div>
      </div>
      <RightSideBar {...rightSidebarProps} />
      <BottomMenu {...props.bottomMenuProps} />
    </div >
  </>)
}

export default Planner