import taskService from "../../services/task.service"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom";
import { Table, Form, Button } from "react-bootstrap";
import moment from 'moment'
import SignaturePad from 'react-signature-canvas'
import authService from "../../services/auth.service";
import { Loader } from "../../components/Loader/loader";
import {
    successAlert,
    errorAlert,
    infoAlert,
} from "../../services/toast.service";

const PublicResponsibilityDischarge = (props) => {
    const [loading, setLoading] = useState(false)
    const params = useParams();
    let [task, setTask] = useState(null)
    let [userSettings, setUserSettings] = useState(null)
    let [apiData, setApiData] = useState({
        type: "RESPONSIBILITY_DISCHARGE",
        taskId: "",
        responsibilityDischargeFormSigned: false,
        responsibilityDischargeFormSubmitted: []
    })

    useEffect(() => {
        if (params.id) {
            getTask(params.id)
        }
    }, [params.id])

    const getTask = async (id) => {
        const response = await taskService.getTaskList({ _id: id });
        if (response && response.data && response.data.results.length) {
            setTask(response.data.results[0])
            let params = {
                userId: response.data.results[0].userId.id,
                type: ["RESPONSIBILITY_DISCHARGE"]
            }
            let getUserSettings = await authService.getCommonServices(params);
            if (getUserSettings && getUserSettings.data && getUserSettings.data.data && getUserSettings.data.data.results[0]) {
                if (getUserSettings.data.data.results[0].sections.length) {
                    let index = 0;
                    let responsibilityDischargeFormSubmitted = []
                    for (let i of getUserSettings.data.data.results[0].sections) {
                        i.index = index;
                        responsibilityDischargeFormSubmitted.push(i)
                        index += 1
                    }
                    setApiData({
                        ...apiData,
                        taskId: response.data.results[0].id,
                        responsibilityDischargeFormSubmitted: responsibilityDischargeFormSubmitted
                    })
                }
                setUserSettings(getUserSettings.data.data.results[0])
            }
            else {
                let params = {
                    type: ["COMMON_RESPONSIBILITY_DISCHARGE"]
                }
                let getCommonSettings = await authService.getCommonServices(params);
                if (getCommonSettings && getCommonSettings.data && getCommonSettings.data.data && getCommonSettings.data.data.results[0]) {
                    if (getCommonSettings.data.data.results[0].sections.length) {
                        let index = 0;
                        let responsibilityDischargeFormSubmitted = []
                        for (let i of getCommonSettings.data.data.results[0].sections) {
                            i.index = index;
                            responsibilityDischargeFormSubmitted.push(i)
                            index += 1
                        }
                        setApiData({
                            ...apiData,
                            taskId: response.data.results[0].id,
                            responsibilityDischargeFormSubmitted: responsibilityDischargeFormSubmitted
                        })
                    }
                    setUserSettings(getCommonSettings.data.data.results[0])
                }
            }
        }
    }

    const handleChange = (e, index) => {
        let newApiData = Object.assign({}, apiData)
        let findIndex = newApiData.responsibilityDischargeFormSubmitted.findIndex(i => i.index === index)
        if (findIndex > -1) {
            newApiData.responsibilityDischargeFormSubmitted[findIndex].comment = e.target.value
        }
        setApiData({
            ...apiData,
            responsibilityDischargeFormSubmitted: newApiData.responsibilityDischargeFormSubmitted
        })
    }
    const renderName = (obj) => {
        if (obj.customer && obj.customer.firstName) {
            return `${obj.customer.firstName ? obj.customer.firstName : ''} ${obj.customer.lastName ?
                obj.customer.lastName : ''}`
        }
        else if (obj.customerId) {
            return `${obj.customerId.firstName ? obj.customerId.firstName : ''} ${obj.customerId.lastName ?
                obj.customerId.lastName : ''}`
        }
        else {
            return ``
        }
    }
    const showText = (text) => {
        let regexRecipientName = /{{recipientName}}/g; // Johny
        text = text.replace(regexRecipientName, renderName(task));
        let regexDriverName = /{{driverName}}/g; // Sebastien Godbout
        text = text.replace(regexDriverName, `${task.agentId && task.agentId.firstName ? task.agentId.firstName : ""} ${task.agentId && task.agentId.lastName ? task.agentId.lastName : ""}`);
        let regexEta = /{{eta}}/g; // 20 mins
        text = text.replace(regexEta, task.eta);
        let regexVehicleDescription = /{{vehicleDescription}}/g; // Black Color
        text = text.replace(regexVehicleDescription, '20 mins');
        let regexWorkerPhone = /{{workerPhone}}/g; // +12244673
        text = text.replace(regexWorkerPhone, `${task.agentId && task.agentId.countryCode ? task.agentId.countryCode : ""}${task.agentId && task.agentId.phoneNumber ? task.agentId.phoneNumber : ""}`);
        let regexDestinationAddressShort = /{{destinationAddressShort}}/g; //
        text = text.replace(regexDestinationAddressShort, '');
        let regexDestinationAddressLong = /{{destinationAddressLong}}/g; //
        text = text.replace(regexDestinationAddressLong, '');
        let regexTrackLink = /{{trackLink}}/g; // track here
        text = text.replace(regexTrackLink, 'track here');
        let regexCreatorName = /{{creatorName}}/g; // Boutique Furniture Expert.
        text = text.replace(regexCreatorName, `${task.userId && task.userId.firstName ? task.userId.firstName : ""} ${task.userId && task.userId.lastName ? task.userId.lastName : ""}`);
        let regexMerchantName = /{{merchantName}}/g; // Boutique Furniture Expert.
        text = text.replace(regexMerchantName, `${task.merchantId && task.merchantId.companyName ? task.merchantId.companyName : ""}`);
        let regexOrganizationName = /{{organizationName}}/g; // Boutique Furniture Expert.
        text = text.replace(regexOrganizationName, `${task.userId && task.userId.companyName ? task.userId.companyName : ""}`);
        let regexEarliestTime = /{{earliestTime}}/g; // 6:35PM
        text = text.replace(regexEarliestTime, '6:35PM');
        let regexLatestTime = /{{latestTime}}/g; // 6:35PM
        text = text.replace(regexLatestTime, '6:35PM');
        let regexCompletedTime = /{{completedTime}}/g; //  6:35PM
        text = text.replace(regexCompletedTime, '6:35PM');
        let regexTodayDate = /{{todayDate}}/g; //  6:35PM
        text = text.replace(regexTodayDate, `${moment().format("ll")}`);
        return text;
    };

    const handleSubmit = async () => {
        trim();
        setLoading(true)
        let api = {
            ...apiData,
            responsibilityDischargeFormSigned: true,
        }
        await taskService.submitLegalSignedDoc(api)
        setLoading(false)
    }

    const [trimmedDataURL, setTrimmedDataURL] = useState()

    let sigPad = {}
    const clear = () => {
        sigPad.clear()
    }
    const trim = () => {
        setTrimmedDataURL(sigPad.getTrimmedCanvas()
            .toDataURL('image/png'))
    }
    return (<>
        {userSettings &&
            <div className="add-mapviewpopup" style={{ height: "100%" }}>
                <div className="map-view-detail" style={{ justifyContent: "center" }}>
                    <div className="Map-layout" style={{
                        position: "absolute"
                    }}>
                        <div className="mapview-heading">
                            <h1>Responsibility Discharge Form</h1>
                        </div>
                        {userSettings.sections.map((i, index) => {
                            return (
                                <div className="editsms-detail">
                                    <div className="delivery-question-ans">
                                        <div className="ques-answer">
                                            <h1>{showText(i.title)}</h1>
                                            <div className="question-answer">
                                                <div className="qs">
                                                    <span className="ans  active">Y</span>
                                                    <p className="answer">
                                                        {showText(i.description)}
                                                    </p>
                                                </div>
                                                <div className="qs">
                                                    <span className="ans">N</span>
                                                    <div className="answer">
                                                        <p></p>
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder="add your comment"
                                                            style={{ height: "100px" }}
                                                            onChange={(e) => {
                                                                handleChange(e, index)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {userSettings.signature && <div className="signature-main-container">
                            <div className="sig-container">
                                <SignaturePad canvasProps={{ className: "sig-pad" }}
                                    ref={(ref) => { sigPad = ref }} />
                            </div>
                            {/* <div>
                                <button onClick={clear}>
                                    Clear
                                </button>
                                <button onClick={trim}>
                                    Save signature
                                </button>
                            </div> */}
                            {/* {trimmedDataURL
                                ? <img className="sig-image"
                                    src={trimmedDataURL} alt="signature" />
                                : null} */}
                        </div>}
                        <Button
                            className="savebtn" onClick={handleSubmit}
                        // onClick={(e) => { createDeliveryToggle(); onSubmitEditSmsHandler(e, userData.role === 1 ? props.commonServicesTypes.COMMON_CONFIRMATION_DELIVERY : props.commonServicesTypes.CONFIRMATION_DELIVERY) }}
                        >
                            Save
                        </Button>
                    </div>
                </div>

                {loading ? <Loader /> : null}
            </div>}
    </>)
};


export default PublicResponsibilityDischarge