let appDefaults = {
    VIEWS: {
        SUPERVISE_CONTROL: "SUPERVISE_CONTROL",
        PLANNER_CONTROL: "PLANNER_CONTROL",
        LIST_VIEW: "LIST_VIEW"
    },
    SECTIONS: {
        SUPERVISE_CONTROL: "SUPERVISE_CONTROL",
        PLANNER_CONTROL: "PLANNER_CONTROL",
        RESIZE_CONTAINER: "RESIZE_CONTAINER"
    },
    LISTING_TYPE: {
        MISSION: "MISSION",
        NON_MISSION: "NON_MISSION"
    },
    CONTEXT_MENU_TYPE: {
        MAP: "MAP",
        MISSION: "MISSION",
        MISSION_TASKS: "MISSION_TASKS",
        TASKS: "TASKS"
    },
    POPUP_TYPE: {
        ASSIGN: "ASSIGN"
    },
    CONTEXT_MENU_ITEMS: {
        CREATE_TASK: {
            en: "Create task here"
        },
        CREATE_HUB: {
            en: "Create hub here"
        },
        EDIT_TASK: {
            en: "Edit"
        },
        DUPLICATE_TASK: {
            en: "Duplicate"
        },
        DELETE_TASK: {
            en: "Delete"
        },
        CREATE_MISSION: {
            en: "Create Mission"
        },
        ASSIGN_MISSION: {
            en: "Assign/Reassign"
        },
        UNASSIGN_MISSION: {
            en: "Unassign"
        },
        OPTIMIZE_MISSION: {
            en: "Optimize"
        },
        DELETE_MISSION: {
            en: "Delete Mission"
        },
        PRINT_MISSION: {
            en: "Print Manifest"
        },
        UPDATE_MISSION_DATE: {
            en: "Update Mission Date"
        },
        DUPLICATE_MISSION: {
            en: "Duplicate Mission"
        },
        REMOVE_TASK: {
            en: "Remove Task"
        },
        CHANGE_STATUS: {
            en: "Change Status"
        },
        LINK_TASKS: {
            en: "Link Selected Tasks"
        },
        SCHEDULE_DELIVERY: {
            en: "Schedule Delivery"
        }
    }
}

export default appDefaults