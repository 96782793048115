import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Card,
  Form as BootstrapForm,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
  NavDropdown,
} from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import closeicon from "../../assets/image/close-icon.png";
import taskPdfIcon from "../../assets/image/download-task-pdf.png";
import taskService from "../../services/task.service";
import moment from "moment"


const TaskRightSideDetails = (props) => {
  const [taskHistory, setTaskHistory] = useState([])
  const userData = useSelector((state) => state.auth.user)
  let task = props.taskDetail;
  console.log("tasktasktasktask", task)
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  useEffect(() => {
    getTaskHistory()
  }, [])
  let getTaskHistory = async () => {
    let response = await taskService.getTaskHistory({ taskId: task.id });
    console.log("response", response)
    if (response && response.data && response.data.results) {
      setTaskHistory(response.data.results)
    }
  }
  const renderName = (obj) => {
    if (obj.customer && obj.customer.firstName) {
      return `${obj.customer.firstName ? obj.customer.firstName : ''} ${obj.customer.lastName ?
        obj.customer.lastName : ''}`
    }
    else if (obj.customerId) {
      return `${obj.customerId.firstName ? obj.customerId.firstName : ''} ${obj.customerId.lastName ?
        obj.customerId.lastName : ''}`
    }
    else {
      return ``
    }
  }
  const renderEmail = (obj) => {
    if (obj.customer) {
      return `${obj.customer.email ? obj.customer.email : ''}`
    }
    else if (obj.customerId) {
      return `${obj.customerId.email ? obj.customerId.email : ''}`
    }
    else {
      return ``
    }
  }
  const printManifestHandler = async () => {
    if ((props.features && props.features.exportTaskPdf) || props.userData.role === 1) {
      // props.setPrintToggle(true)
      let data = {
        taskId: task.id
      }
      let response = await props.taskService.createTaskPDF(data)
      if (response.data) {
        props.fileDownload(response.data, "Task Report.pdf")
        // props.setPDFData(response.data)
      }
      else {
        return props.errorAlert("Something went wrong!!!")
      }
    }
    else {
      props.history.push({
        pathname: "/Business/exportTaskPdf"
      })
    }
  }

  let orderType = "";
  if (task.orderType === "D") orderType = "Drop"
  else if (task.orderType === "DI") orderType = "Drop & Service"
  else if (task.orderType === "S") orderType = "Service"
  else if (task.orderType === "R") orderType = "Return"
  else if (task.orderType === "P") orderType = "Pick Up"
  else if (task.orderType === "E") orderType = "Exchange"

  const renderDocs = () => {
    let docs = []
    // if (task.docs && task.docs.length) {
    //   for (let item of task.docs) {
    //     let librarydoc = props.libraryList.find(i => i.id === item);
    //     if (librarydoc) {
    //       let name = librarydoc.attachments[0].split("/")
    //       docs.push(
    //         <li><span className="pdfvalue">PDF</span><span className="pdfname">{name[3]}</span></li>
    //       )
    //     }
    //   }
    // } 
    if (task.libraryFiles && task.libraryFiles.length) {
      for (let item of task.libraryFiles) {
        let name = item.original.split("/")
        let ext = item.original.substring(item.original.lastIndexOf('.') + 1, item.original.length).toLowerCase()
        docs.push(
          <div className="upload__image-wrapper">

            <div className="selected-docs" onClick={() => {
              if (item && item.original) {
                window.open(item.original, "_blank")
              }
            }}>
              {ext === "pdf" ? <div><span className="pdfvalue">PDF</span></div> : <div><img class="original-image" src={item.original} alt="icon" /></div>}
            </div>
            <div className="filename"><span>{name[4]}</span></div>
          </div>
        )
      }
    }
    else if (task.documents && task.documents.length) {
      for (let item of task.documents) {
        docs.push(
          <li><span className="pdfvalue">PDF</span><span className="pdfname" onClick={() => {
            if (item && item.original) {
              window.open(item.original, "_blank")
            }
          }}>{item.fileName}</span></li>
        )
      }

    }
    else {
      docs.push(<li></li>)
    }
    return docs
  }

  let taskStatus = capitalizeFirstLetter(task.taskStatus);
  return (<div>
    {/* create detail history start */}
    <div className="createtask-slide Detailhistory">
      <div className="create-task-field ">
        <div><span>Task</span><span className="taskid">{task.taskNumber}</span></div>

        <div className="task-title">

          <span className={`title-${task.taskStatus}`}>{taskStatus}</span>
          <Button
            className="createtask-print"
            onClick={() => { printManifestHandler() }}
          >
            <img src={taskPdfIcon} alt="icon" />
          </Button>
          <Button
            className="createtask-close"
            onClick={() => { props.setTaskDetail(null) }}
          >
            <img src={closeicon} alt="icon" />
          </Button>
        </div>
      </div>
      {/* Customer Details */}


      <div className="create-customer-detail">
        {<h2>For: {task.createdFor && task.createdFor.companyName ? task.createdFor.companyName :  `${task.createdFor.firstName}${task.createdFor.lastName}`}</h2>}
        <h2>Team: {task.teamIds && task.teamIds.map(t=>t.teamName).toString()}</h2>
        <h2>Customer Details</h2>
        <div className="Detail-customers-form">
          <ul>
            <li><span className="csname">Name</span><span className="csvalue">{renderName(task)}</span></li>
            <li><span className="csname">Phone</span><span className="csvalue">{task.customerId && task.customerId.phoneNumber}</span></li>
            <li><span className="csname">Email</span><span className="csvalue">{renderEmail(task)}</span></li>
            <li><span className="csname">Address</span><span className="csvalue">{task.address}</span></li>
            <li><span className="csname">After</span><span className="csvalue">{task.after}</span></li>
            <li><span className="csname">Before</span><span className="csvalue">{task.before}</span></li>
            <li><span className="csname">Scheduled At</span><span className="csvalue">{task.scheduledTime}</span></li>
          </ul>
        </div>
      </div>
      {/* Order Details */}
      <div className="create-order-detail">
        <h2>Order Details</h2>
        <div className="create-order-form">

          <div className="Detail-customers-form">
            <ul>
              <li><span className="csname">Type</span><span className="csvalue">{orderType}</span></li>
              <li><span className="csname">Duration</span><span className="csvalue">{task.durationTime} min</span></li>
              <li><span className="csname">Capacity</span><span className="csvalue">{task.capacity} {task.capacityUnit}</span></li>
              {task.notes ? <li><span className="csname">Note</span><span className="csvaluenotes">{task.notes}</span></li> : <></>}
              <li><span className="csname">Doc</span></li>
              <div className="pdfdetail">
                <ul>
                  {renderDocs()}
                </ul>
              </div>
            </ul>
          </div>

        </div>
      </div>

      <div className="create-settime-duration">
        <h2 className="history">History</h2>
        <div className="history-detail-form">
          <ul>
            {taskHistory.map(i => {
              return (<li>
                <div className="hstime">
                  <span className="datetime">{moment(i.createdDate).format("DD MMM YYYY HH:mm")}</span>
                  <span className="hsdetail">
                    <span className="hsvalue">{capitalizeFirstLetter(i.status)}</span>
                    {i.updatedBy && (i.status === "started" || i.status === "created" || i.status === "updated") && <span className="hsby">{` By ${i.updatedBy.firstName} ${i.updatedBy.lastName}`}</span>}
                    {(i.status === "Picture Before" && i.picturesBefore && i.picturesBefore.length) && <span className="hslink">{i.picturesBefore.map((item, i) => { return item.original ? (<a href={item.original} target="_blank" rel="noreferrer">{`Picture ${i + 1}`}</a>) : null })}</span>}
                    {(i.status === "Picture After" && i.picturesAfter && i.picturesAfter.length) && <span className="hslink">{i.picturesAfter.map((item, i) => { return item.original ? (<a href={item.original} target="_blank" rel="noreferrer">{`Picture ${i + 1}`}</a>) : null })}</span>}
                    {i.status === "reached" && i.reachedLocation && i.reachedLocation.location && <span className="hslink"><a href={"https://maps.google.com?q=" + i.reachedLocation.location.lat + "," + i.reachedLocation.location.lng} target="_blank" rel="noreferrer">{`Location`}</a></span>}
                    {i.status === "completed" && i.signature && <span className="hslink">{i.signature.original ? (<a href={i.signature.original} target="_blank" rel="noreferrer">{`Signature`}</a>) : null}</span>}
                    {(i.status === "Notes") && <span className="hsby">{i.agentNotes}</span>}
                    {i.status === "failed" && i.signature && <span className="hslink">{i.signature.original ? (<a href={i.signature.original} target="_blank" rel="noreferrer">{`Signature`}</a>) : null}</span>}
                    {i.status === "failed" && i.reason && <span className="hsby">{`Reason: ${i.reason}`}</span>}
                    {(i.status === "Reason" && i.failedReason && i.failedReason.length) && <span className="hsby">{i.failedReason.map(i => { return ` ${i}` })}</span>}
                    {/* {i.status === "failed" && i.failedReason && i.failedReason.length && <span className="hsby">{`Reason:${i.failedReason.map(i => { return ` ${i}` })}`}</span>} */}
                  </span>
                </div>
              </li>)
            })}
          </ul>
        </div>
      </div>
    </div>

    {/* create detail history end */}
  </div>
  )
}

export default TaskRightSideDetails;
