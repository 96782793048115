import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import CreateTaskComponent from "../../components/RightSideBarComponents/CreateTaskComponent";
import authService from "../../services/auth.service";
import Union from "../../assets/image/Union.png";
import downloadicon from "../../assets/image/downloadicon.png";
import { errorAlert, successAlert } from "../../services/toast.service";
import taskService from "../../services/task.service";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import userService from "../../services/user.service";
import teamService from "../../services/team.service";
import TaskCategory from "../../components/RightSideBarComponents/UnassignedCategory";
import ImportPopup from "../Importpopup/Importpopup";
import { Flatfile, PartialRejection, RecordError } from "@flatfile/sdk";
import axios from "axios";
import moment from "moment";
import TaskRightSideDetails from "../TaskDetails/RightSideTaskDetails";
import fileDownload from 'js-file-download';
import ScheduleDelivery from "./ScheduleDelivery";
import timeZoneHeader from "../../services/timezone-header";
import momentTz from "moment-timezone"

const RightSideBar = (props) => {
    const history = useHistory();
    const userData = useSelector((state) => state.auth.user)
    const superAdminRole = userData && userData.role && userData.role === 1 ? true : false
    const [calendarView, setCalendarView] = useState(false);
    const [taskSettingData, setTaskSettingData] = useState(null);
    const [regionalSettingData, setRegionalSettingData] = useState(null);
    const [scheduleDeliverySettingData, setScheduleDeliverySettingData] = useState(null);
    const [usersList, setUsersList] = useState([])

    const [allAccess, setAllAccess] = useState({
        task: {
            create: false,
            edit: false,
            delete: false,
            duplicate: false
        },
    })

    const [countryCode, setCountryCode] = useState("")
    const [features, setFeatures] = useState({})
    const [unassignTask, setunassignTask] = useState(true);
    const [pendingTask, setpendingTask] = useState(true);
    const unassignTaskToggle = () => {
        setunassignTask(!unassignTask);
    };
    const pendingTaskToggle = () => {
        setpendingTask(!pendingTask);
    };

    const selectAllOption = { id: "all", teamName: "All Teams", firstName: "All Users", lastName: "" }
    const defaultSubscriptionData = useSelector((state) => state.defaultSubscription);
    const extensionData = useSelector((state) => state.extensions);

    useEffect(() => {
        extensionData && extensionData.extension && extensionData.extension.length && extensionData.extension.map((data) => {
            // if (data.isEnabled) {
            data.userExtensions.map((extension) => {
                setFeatures((prev) => {
                    return {
                        ...prev,
                        ...extension.features,
                        additionalTaskNumber: Number(extension.features.additionalTaskNumber)
                    }
                })
                return {}
            })
            // }
        })
    }, [extensionData])


    useEffect(() => {
        let newAccess = Object.assign({}, allAccess);
        if (userData.role === 1 || (userData.role === 2 && userData.userId && (userData.userRole === "admin" || userData.userRole === "manager"))) {
            newAccess.task = {
                create: true,
                edit: true,
                delete: true,
                duplicate: true
            }
            newAccess.mission = {
                create: true,
                optimize: true,
                delete: true,
                assign: true,
                unAssign: true
            }
        }
        else if (userData.role === 2 && !userData.userId) {
            newAccess.task = {
                create: true,
                edit: true,
                delete: true,
                duplicate: true
            }
            newAccess.mission = {
                create: true,
                optimize: true,
                delete: true,
                assign: true,
                unAssign: true
            }
        }
        else if (userData.role === 4) {
            if (userData.userRole === "crud") {
                newAccess.task = {
                    create: true,
                    edit: true,
                    delete: true,
                    duplicate: true
                }
                newAccess.mission = {
                    create: true,
                    optimize: true,
                    delete: true,
                    assign: true,
                    unAssign: true
                }
            }
        }
        setAllAccess(newAccess)
    }, [userData])


    useEffect(() => {
        getUsersList();
        // getTeamList();
        // getMerchantList();
        // getTaskCount();
        getSetting();
    }, []);

    useEffect(() => {
        if (defaultSubscriptionData && defaultSubscriptionData.extension && defaultSubscriptionData.extension.data && defaultSubscriptionData.extension.data.results.length) {
            setFeatures((prev) => {
                return { ...prev, ...defaultSubscriptionData.extension.data.results[0].features }
            })
        }
    }, [defaultSubscriptionData])

    useEffect(() => {
        let user = localStorage.getItem("user")
        if (user) {
            user = JSON.parse(user);
        }
        if (user.role === 2 && user.userId) {
            props.setFormData((prev) => {
                return {
                    ...prev,
                    merchantId: user.id,
                    createdFor: usersList[0] ? usersList[0].id : user.id,
                    userId: usersList[0] ? usersList[0].id : user.id
                  }
            })
        }
        else {
            props.setFormData((prev) => {
                return {
                    ...prev,
                    merchantId: user.id,
                    createdFor: user.id,
                    userId: (user.role === 2 || user.role === 4) && user.userId ? user.userId : user.id
                  }
            })
        }
    }, [usersList]);

    const createTaskToggle = () => {
        getSetting();
        props.setcreateTask(!props.createTask);
        props.setButtonText("Create Task")
    };

    const cancelTask = () => {
        props.setButtonText("Create Task")
        props.setcreateTask(false);
        delete props.initialValues.merchantId
        delete props.initialValues.userId
        props.setAddress("")
        props.setFormData((prev) => {
            return {
                ...prev,
                ...props.initialValues
            }
        });
    };

    useEffect(() => {
        if (!countryCode) {
            window.navigator.geolocation.getCurrentPosition((position) => {
                displayLocation(position.coords.latitude, position.coords.longitude);
            });
        }
    }, []);

    function displayLocation(latitude, longitude) {
        var geocoder;
        geocoder = new window.google.maps.Geocoder();
        var latlng = new window.google.maps.LatLng(latitude, longitude);

        geocoder.geocode({ latLng: latlng }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                    let findCountry = results[0].address_components.find((items) => {
                        return items.types[0] === "country";
                    });
                    if (findCountry) {
                        console.log("findCountry.short_name", findCountry.short_name)
                        setCountryCode(findCountry.short_name);
                    } else {
                        setCountryCode("CA");
                    }
                } else {
                    setCountryCode("CA");
                }
            } else {
                setCountryCode("CA");
            }
        });
    }

    const getUsersList = async () => {
        let roles = [];
        if (userData.role === 1) {
            if (userData.subAdmin) {
                roles = [2, 4]
            }
            else {
                roles = [2]
            }
        }
        else if (userData.role === 2 && userData.userId) {
            roles = [2]
        }
        else {
            roles = [4]
        }
        let params = { limit: 100, roles }
        let user = localStorage.getItem("user")
        if (user) {
            user = JSON.parse(user);
        }
        if (user.role === 1 && user.userId && user.permittedUsers && user.permittedUsers.length) {
            params.userIds = user.permittedUsers
        }
        if (user.role === 2 && user.userId) {
            params.userIds = [user.userId]
        }
        const response = await userService.getSubAdminList(params);
        if (Number(response.status) === 200) {
            if (userData.role === 2 && userData.userId) {
                if (response.data.results && response.data.results.length) {
                    setUsersList(response.data.results);
                    // props.setFormData({ ...props.formData, userId: response.data.results[0].id, merchantId: response.data.results[0].id })
                } else {
                    setUsersList([user]);
                    // props.setFormData({ ...props.formData, userId: user.id, merchantId: user.id })
                }
            }
            else {
                if (response.data.results && response.data.results.length) {
                    let found = response.data.results.find(r => r.id === user.id)
                    if (!found) {
                        setUsersList([...response.data.results, user]);
                    } else {
                        setUsersList(response.data.results);
                    }
                } else {
                    setUsersList([user]);
                }
                // props.setFormData({ ...props.formData, userId: user.id, merchantId: user.id })
            }
        }
        console.log("team list response  :::", response);
    };

    const getSetting = async () => {
        let params = {
            userId: userData.id,
            subType: [
                "TASK_AND_MISSION",
                "REGIONAL_SETTINGS",
                "SCHEDULE_DELIVERY"
            ]
        }
        if (userData.role === 1 && !userData.subAdmin) {
            params.subType = [
                "COMMON_TASK_AND_MISSION",
                "COMMON_REGIONAL_SETTINGS",
                "COMMON_SCHEDULE_DELIVERY"
            ]
        }
        let response = await authService.getSettings(params);
        let taskAndMission = userData.role === 1 && !userData.subAdmin ? "COMMON_TASK_AND_MISSION" : "TASK_AND_MISSION"
        let regional = userData.role === 1 && !userData.subAdmin ? "COMMON_REGIONAL_SETTINGS" : "REGIONAL_SETTINGS"
        let scheduleDeliverySubType = userData.role === 1 && !userData.subAdmin ? "COMMON_SCHEDULE_DELIVERY" : "SCHEDULE_DELIVERY"
        if (response && response.data && response.data.data && response.data.data.results.length) {
            let findTaskSetting = response.data.data.results.find(i => {
                return i.subType === taskAndMission
            })
            setTaskSettingData(findTaskSetting)
            if (findTaskSetting) {
                let taskSettingData = findTaskSetting;
                props.setFormData((prev) => {
                    let data = {
                        ...prev,
                        after: taskSettingData && (taskSettingData.defaultAfterTime || taskSettingData.defaultAfterTime === "") ? taskSettingData.defaultAfterTime : "",
                        afterTimePicker: taskSettingData && (taskSettingData.defaultAfterTimePicker || taskSettingData.defaultAfterTimePicker === "") ? taskSettingData.defaultAfterTimePicker : null,
                        before: taskSettingData && (taskSettingData.defaultBeforeTime || taskSettingData.defaultBeforeTime === "") ? taskSettingData.defaultBeforeTime : "",
                        beforeTimePicker: taskSettingData && (taskSettingData.defaultBeforeTimePicker || taskSettingData.defaultBeforeTimePicker === "") ? taskSettingData.defaultBeforeTimePicker : null,
                        scheduledTimePicker: taskSettingData && (taskSettingData.defaultScheduleTimePicker || taskSettingData.defaultScheduleTimePicker === "") ? taskSettingData.defaultScheduleTimePicker : null,
                        scheduledTime: taskSettingData && (taskSettingData.defaultScheduleTime || taskSettingData.defaultScheduleTime === "") ? taskSettingData.defaultScheduleTime : "",
                        afterSeconds: taskSettingData && (taskSettingData.defaultAfterTimeSeconds || taskSettingData.defaultAfterTimeSeconds === 0) ? taskSettingData.defaultAfterTimeSeconds : 0,
                        beforeSeconds: taskSettingData && (taskSettingData.defaultBeforeTimeSeconds || taskSettingData.defaultBeforeTimeSeconds === 0) ? taskSettingData.defaultBeforeTimeSeconds : 0,
                        scheduledTimeSeconds: taskSettingData && (taskSettingData.defaultScheduleTimeSeconds || taskSettingData.defaultScheduleTimeSeconds === 0) ? taskSettingData.defaultScheduleTimeSeconds : 0,
                        durationTime: taskSettingData && (taskSettingData.defaultTaskDuration || taskSettingData.defaultTaskDuration === 0) ? taskSettingData.defaultTaskDuration : props.defaultDurationTime,
                        capacity: taskSettingData && (taskSettingData.defaultTaskCapacity || taskSettingData.defaultTaskCapacity === 0) ? taskSettingData.defaultTaskCapacity : props.defaultMissionCapacity,
                        documents: taskSettingData && (taskSettingData.documents || taskSettingData.documents.length === 0) ? taskSettingData.documents : [],
                        beforeTime: "",
                        afterTime: "",
                        scheduleTime: ""
                    }
                    return data
                })
            }
            let findRegionalSetting = response.data.data.results.find(i => {
                return i.subType === regional
            })
            if (findRegionalSetting) {
                props.setFormData((prev) => {
                    return {
                        ...prev,
                        capacityUnit: findRegionalSetting && (findRegionalSetting.volumeUnit) ? findRegionalSetting.volumeUnit : "m3",
                    }
                })

            }
            setRegionalSettingData(findRegionalSetting)

            let scheduleDelivery = response.data.data.results.find(i => {
                return i.subType === scheduleDeliverySubType
            })
            setScheduleDeliverySettingData(scheduleDelivery)
        }
    }

    const handleImageUpload = async (image) => {
        let formDataFile = new FormData();
        formDataFile.append("image", image)
        let response = await authService.uploadFile(formDataFile)
        if (response && response.data) {
            let libraryFiles = props.formData.libraryFiles;
            libraryFiles.push(response.data)
            props.setFormData((prev => {
                return {
                    ...prev,
                    libraryFiles: libraryFiles
                }
            }))
        }
    }

    const uploadLibraryDocument = async (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                handleImageUpload(event.target.files[0])
            };
            reader.readAsDataURL(event.target.files[0]);

        }
    }

    let taskProps = {
        ...props,
        cancelTask,
        features: features,
        uploadLibraryDocument,
        setImportToggle: props.setImportToggle,
        createTaskToggle,
        usersList,
        countryCode,
        taskSettingData,
        editMenuHandler: props.editMenuHandler,
        selectAllOption,
        importPopup,
        date: props.date, getUnassignedTaskList: props.getUnassignedTaskList
    }

    async function importPopup() {
        Flatfile.requestDataFromUser({
            // theme: {
            //     hideConfetti: true,
            //     loadingText: "Custom loading text ...",
            //     submitCompleteText: "Custom submit text ...",
            //     displayName: "Display Name",
            // },
            // Development mode = embedId, user, org.
            token: async () => {
                // customize this to match your response payload
                const res = await authService.flatFileAuth();
                return res.data;
            },
            // handle submitted data
            async onData(chunk, next) {
                // do something with the data
                const records = chunk.records
                let importData = records.map((i) => {
                    i.data.recordId = i.recordId
                    return i.data
                })
                let { errors } = await checkForErrorRows([...importData])
                if (!errors || (errors && !errors.length)) {
                    next()
                }
                else {
                    let allErrors = []
                    for (let err of errors) {
                        allErrors.push(
                            // A RecordError should be created with an record (or record id)
                            // and a list of validation errors.
                            new RecordError(err.recordId, err.error),
                        );
                    }
                    console.log("allErrors", allErrors)
                    next(new PartialRejection(allErrors))
                }
            },
            // onInit({ session }) {
            // },
            async onComplete(data) {
                let validData = await data.data();
                await importData(validData)
            },
            onError(error) {
                return errorAlert(error)
            },
        });
    }

    let checkForErrorRows = async (importedTasks) => {
        let invalidTasks = [], validTasks = [];
        for (let task of importedTasks) {
            let validMerchant = false, validAddress = false;
            if (!task["address"] /*|| !task["merchantEmail"]*/ || !task["orderType"] /*|| !task["deliveryDate"]*/) {
                if (!task["address"]) {
                    invalidTasks.push({
                        recordId: task.recordId,
                        error: [{ field: "address", message: "Address is missing" }]
                    })
                }
                // if (!task["merchantEmail"]) {
                //     invalidTasks.push({
                //         recordId: task.recordId,
                //         error: [{ field: "merchantEmail", message: "Merchant email is missing" }]
                //     })
                // }
                if (!task["orderType"]) {
                    invalidTasks.push({
                        recordId: task.recordId,
                        error: [{ field: "orderType", message: "Order Type is missing" }]
                    })
                }
            }
            if (task["merchantEmail"] && task["merchantEmail"] !== userData.email) {
                let findMerchant = usersList.find(i => { return i.email === task["merchantEmail"] });
                if (userData && userData.role === 1) {
                    findMerchant = usersList.find(i => { return i.email === task["merchantEmail"] })
                }
                if (!findMerchant) {
                    invalidTasks.push({
                        recordId: task.recordId,
                        error: [{ field: "merchantEmail", message: "Invalid merchant email" }]
                    })
                    validMerchant = false
                }
                else {
                    validMerchant = true
                }
            }
            if (task["address"]) {
                task["address"] = task["address"].replace(/[\-\[\]\/\{\}\(\)\*\/\#\+\?\.\\\^\$\|]/g, "")
                let response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${task["address"]}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
                if (!response || (response && !response.data) || (response && response.data && !response.data.results[0])) {
                    invalidTasks.push({
                        recordId: task.recordId,
                        error: [{ field: "address", message: "Invalid address" }]
                    })
                    validAddress = false
                }
                else {
                    validAddress = true
                }
            }
            // if (validAddress && validMerchant && task["address"] && task["merchantEmail"] && task["orderType"]) {
            //     validTasks.push(task)
            // }
        }
        return { errors: invalidTasks, validTasks }
    }


    const escapeRegex = (str) => {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "").replace(/ /g, "");
    };

    const importData = async (validData) => {
        props.setLoading(true)
        let tasks = [], invalidTasks = [];
        let taskData = validData.rows.map((i) => {
            return i.data
        })

        for (let task of taskData) {
            let findMerchant
            if (task["merchantEmail"] === userData.email) {
                findMerchant = userData
            }
            else if (!task["merchantEmail"]) {
                findMerchant = userData
            }
            else {
                findMerchant = usersList.find(i => { return i.email === task["merchantEmail"] });
                if (userData && userData.role === 1) {
                    findMerchant = usersList.find(i => { return i.email === task["merchantEmail"] })
                }
            }
            if (findMerchant) {
                let defaultTaskDuration = props.defaultDurationTime;
                let orderType = "D";
                if (taskSettingData && (taskSettingData.defaultTaskDuration || taskSettingData.defaultTaskDuration === 0)) {
                    defaultTaskDuration = taskSettingData.defaultTaskDuration
                }
                if (task["orderType"]) {
                    if (task["orderType"] === "Drop") {
                        orderType = "D";
                        if (taskSettingData && (taskSettingData.defaultTaskDuration || taskSettingData.defaultTaskDuration === 0)) {
                            defaultTaskDuration = taskSettingData.defaultTaskDuration
                        }
                    }
                    else if (task["orderType"] === "Drop and Install" || task["orderType"] === "Drop & Install") {
                        orderType = "DI";
                        if (taskSettingData && (taskSettingData.defaultInstallationTime || taskSettingData.defaultInstallationTime === 0)) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime
                        }
                    }
                    else if (task["orderType"] === "Drop and Service" || task["orderType"] === "Drop & Service") {
                        orderType = "DI";
                        if (taskSettingData && (taskSettingData.defaultInstallationTime || taskSettingData.defaultInstallationTime === 0)) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime
                        }
                    }
                    else if (task["orderType"] === "Return") {
                        orderType = "R";
                        if (taskSettingData && (taskSettingData.defaultInstallationTime || taskSettingData.defaultInstallationTime === 0)) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime
                        }
                    }
                    else if (task["orderType"] === "Service") {
                        orderType = "S";
                        if (taskSettingData && (taskSettingData.defaultInstallationTime || taskSettingData.defaultInstallationTime === 0)) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime
                        }
                    }
                    else if (task["orderType"] === "Pick Up" || task["orderType"] === "Pick up") {
                        orderType = "P";
                        if (taskSettingData && (taskSettingData.defaultTaskDuration || taskSettingData.defaultTaskDuration === 0)) {
                            defaultTaskDuration = taskSettingData.defaultTaskDuration
                        }
                    }
                    else if (task["orderType"] === "Exchange") {
                        orderType = "E";
                        if (taskSettingData && (taskSettingData.defaultInstallationTime || taskSettingData.defaultInstallationTime === 0)) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime
                        }
                    }
                };
                let location;
                if (task["longitude"] && task["latitude"]) {
                    location = {
                        lat: Number(task["latitude"]),
                        lng: Number(task["longitude"])
                    }
                }
                else {
                    task["address"] = task["address"].replace(/[\-\[\]\/\{\}\(\)\*\/\#\+\?\.\\\^\$\|]/g, "")
                    let response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${task["address"]}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
                    // let response = await axios.get(`https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${task["address"]}&inputtype=textquery&fields=formatted_address%2Cname%2Cgeometry&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);

                    if (response && response.data && response.data.results[0]) {
                        location = {
                            lat: response.data.results[0].geometry.location.lat,
                            lng: response.data.results[0].geometry.location.lng
                        }
                    }
                }

                let timezone = { ...timeZoneHeader() }
                if (location) {
                    let data = {
                        orderType: orderType,
                        merchantId: findMerchant.id,
                        notes: task["notes"],
                        orderDate: task["orderDate"] ? `${momentTz(task["orderDate"]).tz(timezone.timezone).startOf("d")._d}` : null,
                        after: task["after"],
                        afterTimePicker: null,
                        before: task["before"],
                        beforeTimePicker: null,
                        scheduledTimePicker: null,
                        scheduledTime: task["scheduledTime"],
                        afterSeconds: 0,
                        beforeSeconds: 0,
                        scheduledTimeSeconds: 0,
                        durationTime: task["durationTime"],
                        firstName: task["firstName"],
                        lastName: task["lastName"],
                        ISO: "ca",
                        countryCode: task["countryCode"] ? task["countryCode"] : "+1",
                        phoneNumber: task["phoneNumber"] && escapeRegex(task["phoneNumber"].toString()),
                        email: task["email"],
                        address: task["address"],
                        apartment: task["apartment"],
                        location: location,
                        capacity: task["capacity"],
                        price: Number(task["price"]) || 0
                    }
                    if (userData && userData.role === 1) {
                        data.userId = findMerchant.id
                    }
                    if (data.scheduledTime) {
                        let time = data.scheduledTime.split(":");
                        data.scheduledTimeSeconds = Number((time[0] * 3600) + (time[1] * 60))
                    }
                    if (data.before) {
                        let time = data.before.split(":");
                        data.beforeSeconds = Number((time[0] * 3600) + (time[1] * 60))
                    }
                    if (data.after) {
                        let time = data.after.split(":");
                        data.afterSeconds = Number((time[0] * 3600) + (time[1] * 60))
                    }
                    if (!data.durationTime) {
                        data.durationTime = defaultTaskDuration;
                    }
                    if (!data.capacity) {
                        data.capacity = taskSettingData && (taskSettingData.defaultTaskCapacity || taskSettingData.defaultTaskCapacity === 0) ? taskSettingData.defaultTaskCapacity : props.defaultMissionCapacity;
                        data.capacityUnit = regionalSettingData && (regionalSettingData.volumeUnit) ? regionalSettingData.volumeUnit : "m3";
                    }
                    tasks.push(data)
                }
            }
        }
        if (!tasks.length) {
            props.setLoading(false)
            return errorAlert("There are some rows with invalid values please check all values!!!")
        }
        let response = await taskService.createMultipleTasks({ tasks });
        let successMsg = "Task imported successfully"
        if (response && response.data && response.data.success) {
            if (!invalidTasks.length) {
                successMsg = "Task imported successfully"
                props.getUnassignedTaskList();
                props.setLoading(false)
            }
            else {
                props.setLoading(false)
                errorAlert("Some tasks were not imported due to invalid values!!!")
                return
            }
        }
        else {
            props.setLoading(false)
            errorAlert("Some tasks were not imported due to invalid values!!!")
            return
        }
        props.setLoading(false)
        return successAlert(successMsg)
    }

    const renderCreateTaskButton = () => {
        if (allAccess.task.create) {
            return (
                <div
                    className={
                        props.createTask ? "createtaskbox " : "createtaskbox createTask"
                    } onClick={createTaskToggle}
                >

                    <div>
                        <Button className="cticon" onClick={createTaskToggle}>
                            <img src={Union} alt="icon" />
                        </Button>
                        <span>Create</span>
                    </div>

                    <div>
                        <Button>
                            <img src={downloadicon} onClick={event => {
                                event.stopPropagation(); // <-- this stops the click going through to the parent div
                                // props.setImportToggle(true);
                                importPopup()
                            }} alt="icon" />
                        </Button>
                    </div>
                </div>)
        }
        else {
            return null
        }
    }


    let unassignedCategoryProps = {
        unassignedTaskList: props.unassignedTaskList,
        unassignTask,
        unassignTaskToggle,
        taskClickProps: { ...props.taskClickProps, setTaskList: props.setUnassignedTaskList },
        sideView: true,
        onDrop: props.onDrop,
        dragStart: props.dragStart,
        setTaskDetail: props.setTaskDetail,
        editMenuHandler: props.editMenuHandler,
        deleteMenuHandler: props.deleteMenuHandler,
        duplicateTaskHandler: props.duplicateTaskHandler,
        UnassignedCategoryTitle: "New Orders",
        setCalendarView, dragAndDropRestricted: false,
        fieldBoxClass: "unassigned-field-box"
    }

    let pendingCategoryProps = {
        ...unassignedCategoryProps,
        unassignedTaskList: props.pendingTaskList,
        UnassignedCategoryTitle: "Pending reply",
        unassignTaskToggle: pendingTaskToggle,
        unassignTask: pendingTask,
        dragAndDropRestricted: true,
        fieldBoxClass: "pending-field-box",
        borderColor: "#BB2727ff",
        taskClickProps: {
            ...props.taskClickProps,
            setTaskList: props.setPendingTaskList,
            unassignedTaskList: props.pendingTaskList,
            setUnassignedTaskList: props.setPendingTaskList
        },
    }

    let taskDetailProps = {
        taskDetail: props.taskDetail,
        setTaskDetail: props.setTaskDetail,
        errorAlert,
        taskService,
        fileDownload,
        features: props.features,
        userData,
        history,
        libraryList: props.libraryList
    }

    let scheduleDeliveryProps = {
        scheduleDeliverySettingData,
        setCalendarView,
        taskCount: props.taskCount,
        selectedTasks: props.selectedTasks,
        getUnassignedTaskList: props.getUnassignedTaskList,
        date: props.date,
        errorAlert
    }
    return (<>
        <div className="create-task-container">
            {renderCreateTaskButton()}
        </div>
        <div className="sidebar-container">
            <CreateTaskComponent {...taskProps} />
            {props.taskDetail ? <TaskRightSideDetails {...taskDetailProps} /> : <>
                {calendarView && <ScheduleDelivery {...scheduleDeliveryProps} />}
                <div className="task-list">
                    <TaskCategory {...unassignedCategoryProps} />
                    <TaskCategory {...pendingCategoryProps} />
                    {/* {props.importToggle && <ImportPopUp />} */}
                </div>
            </>}

        </div>

    </>)
}

export default RightSideBar;