import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Card,
  Form as BootstrapForm,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
  NavDropdown,
} from "react-bootstrap";
import "./merchantNew.scss";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import logo from "../../assets/image/logo.png";
import Sidebarmenu from "../../components/sidebarmenu";
import Union from "../../assets/image/Union.png";
import downloadicon from "../../assets/image/downloadicon.png";
import searchicon from "../../assets/image/searchicon.png";
import searchF from "../../assets/image/search-f.png";
import ShortIcon from "../../assets/image/filter-icon.png";
import filterremoveicon from "../../assets/image/filter-removeicon.png";
import closeicon from "../../assets/image/close-icon.png";
import nameicon from "../../assets/image/nameicon.png";
import mailicon from "../../assets/image/mailicon.png";
import industryicon from "../../assets/image/industryicon.png";
import countryicon from "../../assets/image/countryicon.png";
import phoneicon from "../../assets/image/phoneicon.png";
import roleicon from "../../assets/image/roleicon.png";
import teamicon from "../../assets/image/teamicon.png";
import passwordicon from "../../assets/image/password.png";
import companyicon from "../../assets/image/companyicon.png";
import Layout from "../../components/layout";
import ContentLayout from "../../components/contentlayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { ExportToCsv } from "export-to-csv";
import {
  successAlert,
  errorAlert,
  infoAlert,
} from "../../services/toast.service";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import userService from "../../services/user.service";
import teamService from "../../services/team.service";
import Select, { components } from 'react-select';

const Merchant = (props) => {
  const randomString1 = `dummm-${btoa(Math.random().toString()).substring(
    0,
    16
  )}`;
  let initialValues = {
    id: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    companyName: "",
    teamId: "",
    userRole: "",
    user: "",
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [createCustomer, setcreateCustomer] = useState("false");
  const [formData, setFormData] = useState(initialValues);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const [teamList, setTeamList] = React.useState([]);
  const [subAdmin, setSubAdmin] = React.useState([]);
  const [values, setValues] = useState([])
  const [teamValues, setTeamValues] = useState([])
  const createCustomerToggle = () => {
    setcreateCustomer(!createCustomer);
  };

  const userData = useSelector((state) => state.auth.user);

  let validation = {
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("last name is required"),
    companyName: Yup.string().required("Company name is required"),
    email: Yup.string()
      .required("Email is required!")
      .email("Please fill valid email address "),
    userRole: Yup.string().required("Please choose a role"),
  };
  if (userData && userData.role === 1 && !userData.subAdmin) {
    validation.user = Yup.string().required("Please select a user");
  }
  if (!formData.id) {
    validation.password = Yup.string().required("Password is required");
  }
  const validationSchema = Yup.object().shape(validation);
  const getTeamlist = async (params) => {
    const response = await teamService.getTeamList(params);
    if (response.status == 200) {
      setTeamList(response.data.results || []);
    }
  };

  const formatStatus = (cell, row) => {
    if (row.blocked) return <span>Blocked</span>;
    return <span>Active</span>;
  };
  function NameFormatter(cell, row) {
    return (
      <span>
        {row.firstName ? row.firstName : ""} {row.lastName ? row.lastName : ""}
      </span>
    );
  }
  function TeamNameFormatter(cell, row) {
    // return <span>{row.teamId ? row.teamId.teamName : ""}</span>;
    return <span>{row.teamIds ? row.teamIds.map(i => i.teamName).join(", ") : ""}</span>;
  }

  const Remove = (cell, row) => {
    return (
      <>
        <span>
          <span className="filter-edit">
            <NavDropdown
              title={<img src={filterremoveicon} />}
              id="nav-dropdown"
            >
              <NavDropdown.Item
                eventKey="4.1"
                onClick={() => {
                  editHandler(row);
                }}
              >
                Edit
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="4.2"
                onClick={() => {
                  deleteHandler(row);
                }}
              >
                Delete
              </NavDropdown.Item>
            </NavDropdown>
          </span>
        </span>
      </>
    );
  };

  const getUserList = async (params) => {
    const response = await userService.getMerchantList(params);
    if (response.status == 200) {
      if (response.data && response.data.totalPages) {
        setPageCount(response.data.totalPages);
      }
      setUsers(response.data.results || []);
    }
  };

  const getUserByRole = async (obj) => {
    const response = await userService.getUsers(obj);
    if (response.status == 200) {
      setSubAdmin(response.data || []);
    }
  };

  const exportToCsv = async () => {
    userService
      .getUsers({ role: 4 })
      .then((result) => {
        if (result.status === 200) {
          let data = [];

          result.data.map((item, index) => {
            let d = {
              firstName: item.firstName || "N/A",
              lastName: item.lastName || "N/A",
              email: item.email || "N/A",
              company: item.companyName || "N/A",
              phoneNumber: item.phoneNumber || "N/A",
              status: item.blocked ? "UnActive" : "Active",
            };
            data.push(d);
          });

          const options = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: true,
            title: "Merchant List",
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: "merchants",
          };

          const csvExporter = new ExportToCsv(options);
          if (data.length > 0) {
            csvExporter.generateCsv(data);
          } else {
            errorAlert("No data found");
          }
        } else {
          errorAlert(result.message ? result.message : "Something went wronng");
        }
      })
      .catch(console.log);
  };

  //handlers
  const editHandler = (row) => {
    setFormData({
      id: row.id ? row.id : "",
      firstName: row.firstName ? row.firstName : "",
      lastName: row.lastName ? row.lastName : "",
      phoneNumber: row.phoneNumber ? row.phoneNumber : "",
      email: row.email ? row.email : "",
      // password: "test@123",
      companyName: row.companyName ? row.companyName : "",
      userRole: row.userRole ? row.userRole : "",
      teamId: row.teamId ? row.teamId.id : "",
      user: row.userId ? row.userId.id : "",
    });
    createCustomerToggle();
  };
  const deleteHandler = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      text: "Deleted records can't be restored!",
      color: "#ffffff",
      background: "#151719",
      confirmButtonColor: "#1A1D1F",
      cancelButtonColor: "#1A1D1F",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await userService.deleteUser(row.id);
        if (response) {
          console.log("delete response ::::", response);
          Swal.fire(
            "Deleted!",
            "User has been Deleted Successfully !",
            "success"
          );
          getUserList({ page: currentPage, searchText });
        }
      }
    });
  };

  const RowSelectHandler = (row, isSelect, rowIndex, e) => {
    // ...
    if (isSelect) {
      const item = row.id || "";
      setSelectedItems([...selectedItems, item]);
    } else {
      const items = selectedItems.filter((sItem) => sItem != row.id);
      setSelectedItems(items);
    }
  };

  const selectAllRowsHandler = (isSelect, rows, e) => {
    if (isSelect) {
      const items = rows
        .map((rowItem) => rowItem.id)
        .filter((itemId) => {
          if (!selectedItems.includes(itemId)) return itemId;
        });
      setSelectedItems([...selectedItems, ...items]);
    } else {
      const ids = rows.map((rowItem) => rowItem.id);
      const items = selectedItems.filter((sItem) => {
        if (!ids.includes(sItem)) return sItem;
      });
      setSelectedItems(items);
    }
  };

  const deleteAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        text: "Deleted records can't be restored!",
        color: "#ffffff",
        background: "#151719",
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await userService.deleteManyUsers(selectedItems);
          if (data.success) {
            getUserList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Users Deleted Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const blockAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        text: "You Want to Block selected Users !",
        icon: "warning",
        showCancelButton: true,
        color: "#ffffff",
        background: "#151719",
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await userService.blockManyUsers(selectedItems);
          if (data.success) {
            getUserList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Users Blocked Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: RowSelectHandler,
    onSelectAll: selectAllRowsHandler,
    bgColor: "rgb(238, 193, 213)",
  };

  useEffect(() => {
    getUserList({ page: currentPage, searchText });
    getTeamlist({ limit: 100 });
    if (userData.role === 1) {
      getUserByRole({ role: 2, roleBy: userData.role });
    }
  }, [userData]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      getUserList({ page: 1, searchText });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const handleSubmit = async (formValue) => {
    try {
      if (formValue.id) {
        //update here
        const response = await userService.editUser(formValue);

        if (response.data.success) {
          setFormData(initialValues);
          setcreateCustomer("false");
          getUserList({ page: currentPage, searchText });
          successAlert(
            response.data.message
              ? response.data.message
              : "User Updated Successfully !"
          );
        }
        if (!response.data.success) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
      } else {
        const response = await userService.createMerchant(formValue);
        if (response.data.success) {
          setFormData(initialValues);
          setcreateCustomer("false");
          successAlert(
            response.data.message
              ? response.data.message
              : "User Added Successfully !"
          );
          getUserList({ page: currentPage, searchText });
        }
        if (response.data.success === false) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
      }
    } catch (err) {
      console.log("error:::", err);
    }
  };

  const cancelhandler = () => {
    setFormData(initialValues);
    setcreateCustomer("false");
  };
  const nodeRef = React.useRef(null);

  const handlePageClick = async ({ selected }) => {
    if (selected + 1 !== currentPage) {
      setCurrentPage(selected + 1);
      await getUserList({ page: selected + 1, searchText });
    }
  };
  const [bulkOpened, setbulkOpened] = useState(false);

  function bulktoggle() {
    setbulkOpened((wasOpened) => !wasOpened);
  }
  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.searchText
    ) {
      setSearchText(props.location.state.searchText);
    }
  }, []);

  const ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <img src={teamicon} style={{ position: 'absolute', left: 6, width: "25px" }} alt="teamImg"></img>
            // <i
            //   className="fa fa-search"
            //   aria-hidden="true"
            //   style={{ position: 'absolute', left: 6 }}
            // />
          )}
          {children}
        </components.ValueContainer>
      )
    );
  };

  const selectAllOption = { id: "all", teamName: "All Teams", firstName: "All Users", lastName: "" }

  const CustomSelectField = ({ field, colors, form, ...props }) => {
    return (
      <Select
        className="react-team-select-container"
        classNamePrefix="react-team-select"
        closeMenuOnSelect={false}
        defaultValue={field.name === "permittedUsers" ? values : teamValues}
        placeholder={field.name === "permittedUsers" ? "Select User List" : "Select Team List"}
        isMulti
        autoFocus={false}
        isSearchable={false}
        options={getOptions(field.name)}
        getOptionLabel={(option) => field.name === "permittedUsers" ? `${option.firstName} ${option.lastName}` : `${option.teamName}`}
        getOptionValue={(option) => option.id}
        onChange={(newValue, actionMeta) => {
          const { action, option, removedValue } = actionMeta;
          if (action === "select-option" && option.id === selectAllOption.id) {
            // if (field.name === "permittedUsers") {
            //   setValues(userList)
            //   form.setFieldValue("permittedUsers", userList.map(i => i.id));
            // }
            // else {
            setTeamValues(teamList)
            form.setFieldValue("teamIds", teamList.map(i => i.id));
            // }
          }
          else if (action === "clear") {
            // if (field.name === "permittedUsers") {
            //   setValues([])
            //   form.setFieldValue("permittedUsers", []);
            // }
            // else {
            setTeamValues([])
            form.setFieldValue("teamIds", []);
            // }
          }
          else {
            // if (field.name === "permittedUsers") {
            //   setValues(newValue)
            //   form.setFieldValue("permittedUsers", newValue.map(i => i.id));
            // }
            // else {
            setTeamValues(newValue)
            form.setFieldValue("teamIds", newValue.map(i => i.id));
            // }
          }
        }
        }
        components={{ ValueContainer }}
      />
    )
  };

  const getOptions = (fieldName) => {
    // if (fieldName === "permittedUsers") {
    //   return [selectAllOption, ...userList];
    // }
    // else {
    return [selectAllOption, ...teamList];
    // }
  }


  return (
    <ContentLayout>
      <div className="customer-page-wrapper d-flex">
        {/* customer layout start */}
        <div className="customer-form-wrapper">
          <div className="customer-heading-box">
            <Row>
              <Col xs={6}>
                <h1>Merchants</h1>
              </Col>

              <Col xs={6}>
                <div className="createmsbox">
                  <div onClick={createCustomerToggle}>
                    <Button className="cticon">
                      <img src={Union} alt="icon" />
                    </Button>
                    <span>Add Merchant</span>
                  </div>
                  <div>
                    <Button onClick={() => exportToCsv()}>
                      <img src={downloadicon} alt="icon" />
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="customer-searching-box">
            <Row>
              <Col xs={6}>
                <div className="create-action-option mb-3">
                  <Dropdown>
                    <Dropdown.Toggle className="bulk-action">
                      Bulk Action
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={blockAllHandler}>
                        Block All
                      </Dropdown.Item>
                      <Dropdown.Item onClick={deleteAllHandler}>
                        Delete Selected
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
              <Col xs={6}>
                <div className="searchbar">
                  <BootstrapForm className="d-flex">
                    <FormControl
                      type="search"
                      placeholder="Search or type a name"
                      className="me-2"
                      autoComplete="off"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      aria-label="Search"
                    />
                    <Button className="searchicon">
                      <img src={searchicon} alt="searchicon" />
                    </Button>
                    {/* <span className="searchF">
                        <img src={searchF} alt="icon" />
                      </span> */}
                  </BootstrapForm>
                </div>
              </Col>
            </Row>
          </div>

          {/* customer list start */}
          <div className="customer-list-wrapper" ref={nodeRef}>
            {/* <MyMapComponent key="map" /> */}
            <BootstrapTable
              data={users}
              striped={true}
              hover={true}
              selectRow={selectRowProp}
            >
              <TableHeaderColumn
                dataField="teamId"
                dataSort={true}
                width="200px"
                dataFormat={TeamNameFormatter}
              >
                Team
                <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                isKey={true}
                dataAlign="left"
                dataSort={true}
                dataFormat={NameFormatter}
                width="200px"
              >
                Merchant Name <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="phoneNumber"
                dataSort={true}
                width="200px"
              >
                Phone
                <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="email"
                dataSort={true}
                width="250px"
              >
                Email
                <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="block"
                dataFormat={formatStatus}
                width="150px"
              >
                Status
                <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>

              {/* <TableHeaderColumn
                  dataField="companyName"
                  dataSort={true}
                  width="200px"
                >
                  Number of Ask
                  <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn> */}
              <TableHeaderColumn
                dataField="Action"
                dataAlign="right"
                dataFormat={Remove}
                width="150px"
              >
                Action
              </TableHeaderColumn>
            </BootstrapTable>
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
              className="pagination"
            />
          </div>

          {/* customer list end */}
        </div>

        {/* customer layout end */}
      </div>
      {/* create customer popup start */}
      <div
        className={
          createCustomer
            ? "opencustomer-popup"
            : "opencustomer-popup createCustomer"
        }
      >
        <div className="addCustomer-popup">
          <div className="addcustomer-slide">
            <div className="addcustomer-field">
              <span className="title">Merchant</span>
              <div className="customer-import">
                <div className="customer-import-field">
                  <Button>
                    <img src={Union} alt="icon" />
                  </Button>
                  <span>import</span>
                  <Button>
                    <img src={downloadicon} alt="icon" />
                  </Button>
                </div>
                <Button
                  className="createcustomer-close"
                  onClick={createCustomerToggle}
                >
                  <img src={closeicon} alt="icon" />
                </Button>
              </div>
            </div>

            <div className="addcustomer-information">
              <h2>Merchant Information</h2>
              <div className="customer-form">
                <Formik
                  enableReinitialize
                  initialValues={formData}
                  render={(formProps) => {
                    return (
                      <Form>
                        <Row className="custinfo-form">
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="firstname">
                                <img src={nameicon} alt="nameicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="First Name"
                                maxLength="25"
                                name="firstName"
                                aria-label="First Name"
                              />
                              <ErrorMessage name="firstName" component="div" />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="lastname">
                                <img src={nameicon} alt="lasticon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="Last Name"
                                maxLength="25"
                                name="lastName"
                                aria-label="Last Name"
                              />
                              <ErrorMessage name="lastName" component="div" />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="phonenumber">
                                <img src={phoneicon} alt="phoneicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="Phone Number"
                                name="phoneNumber"
                                type="text"
                                aria-label="Phone Number"
                              />
                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup>
                              <InputGroup.Text id="yourworkemail">
                                <img src={mailicon} alt="emailicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="Your work email"
                                name="email"
                                aria-label="Your email"
                              />
                              <ErrorMessage name="email" component="div" />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="companyname">
                                <img src={companyicon} alt="companyicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder="Company name"
                                maxLength="25"
                                name="companyName"
                                aria-label="Company Name"
                              />
                              <ErrorMessage
                                name="companyName"
                                component="div"
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="createpassword">
                                <img src={passwordicon} alt="passwordicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                type="password"
                                placeholder="Create Password"
                                name="password"
                                aria-label="Create Password"
                              />
                              <ErrorMessage name="password" component="div" />
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <Field
                              className="form-control"
                              name="teamIds"
                              component={CustomSelectField}
                            >
                            </Field>
                            <ErrorMessage name="teamIds" component="div" />
                          </Col>
                          <Col xs={6}>
                            <InputGroup className="mb-3 teamsl">
                              <InputGroup.Text id="roleicon">
                                <img src={roleicon} alt="roleicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                name="userRole"
                                as="select"
                              >
                                <option selected>Assign Role</option>
                                {/* <option value="admin">Admin</option> */}
                                <option value="crud">CRUD</option>
                                <option value="view">View</option>
                                {/* <option value="super">Super Merchant</option> */}
                              </Field>
                              <ErrorMessage name="userRole" component="div" />
                            </InputGroup>
                          </Col>
                          {userData.role === 1 && !userData.subAdmin && <Col xs={6}>
                            <InputGroup className="mb-3 teamsl">
                              <InputGroup.Text id="roleicon">
                                <img src={roleicon} alt="roleicon" />
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                name="user"
                                as="select"
                              >
                                <option selected>Select User</option>
                                {subAdmin &&
                                  subAdmin.map((data) => (
                                    <option value={data.id}>
                                      {data.firstName + " " + data.lastName}
                                    </option>
                                  ))}
                              </Field>
                              <ErrorMessage name="user" component="div" />
                            </InputGroup>
                          </Col>}
                        </Row>
                        <div className="ct-btn">
                          <Button className="cncle-btn" onClick={cancelhandler}>
                            Cancel
                          </Button>
                          <Button className="ctaskbtn" type="submit">
                            Let’s Go
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                ></Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* create customer popup end */}

      <ToastContainer
        style={{ color: "black" }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ContentLayout>
  );
};

export default Merchant;
