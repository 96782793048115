import customers_icon from "../../../../assets/image/customer_icon.png";
import merchant_icon from "../../../../assets/image/merchant_icon_white.png";
import performance_icon from "../../../../assets/image/graph.png";
import team_icon from "../../../../assets/image/group.png";
import right from "../../../../assets/image/right-arrow.png";
import time from "../../../../assets/image/time-left.png";
import loading from "../../../../assets/image/loading.png";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";

const MenuList = (props) => {
  const [subMenuList, setSubMenuList] = useState([]);
  let { userList, merchantList } = useSelector((state) => state.user);
  const { teamList } = useSelector((state) => state.team);
  let statusList = [
    {
      name: "New",
      id: "new",
    },
    {
      name: "Pending",
      id: "pending",
    },
    {
      name: "Unassigned",
      id: "unassigned",
    },
    {
      name: "Assigned",
      id: "assigned",
    },
    {
      name: "OnGoing",
      id: "onGoing",
    },
    {
      name: "Delayed",
      id: "delayed",
    },
    {
      name: "Completed",
      id: "completed",
    },
    {
      name: "Failed",
      id: "failed",
    },
  ];
  let dayList = [
    {
      name: "Monday",
      id: 0,
    },
    {
      name: "Tuesday",
      id: 1,
    },
    {
      name: "Wednesday",
      id: 2,
    },
    {
      name: "Thursday",
      id: 3,
    },
    {
      name: "Friday",
      id: 4,
    },
    {
      name: "Saturday",
      id: 5,
    },
    {
      name: "Sunday",
      id: 6,
    },
  ];

  const handleMenuClick = (filter) => {
    switch (filter) {
      case "merchant":
        let merchants = merchantList?.map((m) => {
          return {
            name: m.companyName
              ? m.companyName
              : `${m.firstName} ${m.lastName}`,
            value: m.id,
            listType: "merchantList",
            checked: props.selectedBottomMenuMerchant.some((i) => i === m.id),
          };
        });
        setSubMenuList(merchants);
        break;
      case "user":
        let users = userList?.map((m) => {
          return {
            name: m.companyName
              ? m.companyName
              : `${m.firstName} ${m.lastName}`,
            value: m.id,
            listType: "userList",
            checked: props.selectedBottomMenuUser.some((i) => i === m.id),
          };
        });
        setSubMenuList(users);
        break;
      case "team":
        let teams = teamList?.map((m) => {
          return {
            name: m.teamName ? m.teamName : `${m.firstName} ${m.lastName}`,
            value: m.id,
            listType: "teamList",
            checked: props.selectedBottomMenuTeams.some((i) => i === m.id),
          };
        });
        setSubMenuList(teams);
        break;
      case "status":
        let status = statusList?.map((m) => {
          return {
            name: m.name ? m.name : `${m.firstName} ${m.lastName}`,
            value: m.id,
            listType: "statusList",
            checked: props.selectedBottomMenuStatus.some((i) => i === m.id),
          };
        });
        setSubMenuList(status);
        break;
      case "day":
        let days = dayList?.map((m) => {
          return {
            name: m.name ? m.name : `${m.firstName} ${m.lastName}`,
            value: m.id,
            listType: "dayList",
            checked: props.selectedBottomMenuDay.some((i) => i === m.id),
          };
        });
        setSubMenuList(days);
        break;
      default:
        break;
    }
  };
  const handleFilterCheck = (e, type) => {
    let subMenus = JSON.parse(JSON.stringify(subMenuList));
    let subMenuLists = subMenus.map((i) => {
      if (i.value === e.target.id) {
        i.checked = e.target.checked;
      }
      return i;
    });
    setSubMenuList(subMenuLists);
    if (type === "merchantList") {
      let resultArray = [];
      if (e.target.checked) {
        //if checked (true), then add this id into checkedList
        resultArray = props.selectedBottomMenuMerchant.filter(
          (CheckedId) => CheckedId !== e.target.id
        );
        resultArray.push(e.target.id);
      } //if not checked (false), then remove this id from checkedList
      else {
        resultArray = props.selectedBottomMenuMerchant.filter(
          (CheckedId) => CheckedId !== e.target.id
        );
      }
      props.setSelectedBottomMenuMerchant(resultArray);
    } else if (type === "userList") {
      let resultArray = [];
      if (e.target.checked) {
        //if checked (true), then add this id into checkedList
        resultArray = props.selectedBottomMenuUser.filter(
          (CheckedId) => CheckedId !== e.target.id
        );
        resultArray.push(e.target.id);
      } //if not checked (false), then remove this id from checkedList
      else {
        resultArray = props.selectedBottomMenuUser.filter(
          (CheckedId) => CheckedId !== e.target.id
        );
      }
      props.setSelectedBottomMenuUser(resultArray);
    } else if (type === "teamList") {
      let resultArray = [];
      if (e.target.checked) {
        //if checked (true), then add this id into checkedList
        resultArray = props.selectedBottomMenuTeams.filter(
          (CheckedId) => CheckedId !== e.target.id
        );
        resultArray.push(e.target.id);
      } //if not checked (false), then remove this id from checkedList
      else {
        resultArray = props.selectedBottomMenuTeams.filter(
          (CheckedId) => CheckedId !== e.target.id
        );
      }
      props.setSelectedBottomMenuTeams(resultArray);
    } else if (type === "statusList") {
      let resultArray = [];
      let id = e.target.id;
      if (!id) id = 0;
      if (e.target.checked) {
        //if checked (true), then add this id into checkedList
        resultArray = props.selectedBottomMenuStatus.filter(
          (CheckedId) => CheckedId !== id
        );
        resultArray.push(id);
      } //if not checked (false), then remove this id from checkedList
      else {
        resultArray = props.selectedBottomMenuStatus.filter(
          (CheckedId) => CheckedId !== id
        );
      }
      props.setSelectedBottomMenuStatus(resultArray);
    } else if (type === "dayList") {
      let resultArray = [];
      let id = e.target.id;
      if (!id) id = 0;
      if (e.target.checked) {
        //if checked (true), then add this id into checkedList
        resultArray = props.selectedBottomMenuDay.filter(
          (CheckedId) => CheckedId !== id
        );
        resultArray.push(id);
      } //if not checked (false), then remove this id from checkedList
      else {
        resultArray = props.selectedBottomMenuDay.filter(
          (CheckedId) => CheckedId !== id
        );
      }
      props.setSelectedBottomMenuDay(resultArray);
    } else if (e.target.name === "agentList") {
      let resultArray = [];
      if (e.target.checked) {
        //if checked (true), then add this id into checkedList
        resultArray = props.selectedBottomMenuAgent.filter(
          (CheckedId) => CheckedId !== e.target.id
        );
        resultArray.push(e.target.id);
      } //if not checked (false), then remove this id from checkedList
      else {
        resultArray = props.selectedBottomMenuAgent.filter(
          (CheckedId) => CheckedId !== e.target.id
        );
      }
      props.setSelectedBottomMenuAgent(resultArray);
    }
  };
  const subMenu = () => {
    return (
      <div className="sub-menu-wrapper">
        <ul className="no-bullets">
          {subMenuList.map((sm, i) => {
            return (
              <div className="list-wrapper">
                <label htmlFor={sm.name} className="checkbox-custom-label">
                  {sm.name}
                </label>
                <Form.Check
                  onChange={(e) => {
                    handleFilterCheck(e, sm.listType);
                  }}
                  type="checkbox"
                  name={sm.listType}
                  id={sm.value}
                  checked={sm.checked}
                  className="checkbox-custom"
                />
              </div>
            );
          })}
        </ul>
      </div>
    );
  };
  return (
    <div className="menu-list-wrapper">
      <ul className="no-bullets">
        <div
          className="list-wrapper"
          onMouseEnter={() => {
            handleMenuClick("merchant");
          }}
        >
          <li
            onClick={() => {
              handleMenuClick("merchant");
            }}
          >
            <img src={merchant_icon} alt={"merchant_icon"} />
            <span>Merchant</span>
          </li>
          <img className="img" src={right} alt={"right-icon"} />
        </div>
        <div
          className="list-wrapper"
          onMouseEnter={() => {
            handleMenuClick("user");
          }}
        >
          <li
            onClick={() => {
              handleMenuClick("user");
            }}
          >
            <img src={customers_icon} alt={"merchant_icon"} />
            <span>User</span>
          </li>
          <img className="img" src={right} alt={"right-icon"} />
        </div>
        <div
          className="list-wrapper"
          onMouseEnter={() => {
            handleMenuClick("team");
          }}
        >
          <li
            onClick={() => {
              handleMenuClick("team");
            }}
          >
            <img src={team_icon} alt={"merchant_icon"} />
            <span>Team</span>
          </li>
          <img className="img" src={right} alt={"right-icon"} />
        </div>
        <div
          className="list-wrapper"
          onMouseEnter={() => {
            handleMenuClick("status");
          }}
        >
          <li
            onClick={() => {
              handleMenuClick("status");
            }}
          >
            <img src={loading} alt={"merchant_icon"} />
            <span>Status</span>
          </li>
          <img className="img" src={right} alt={"right-icon"} />
        </div>
        <div
          className="list-wrapper"
          onMouseEnter={() => {
            handleMenuClick("day");
          }}
        >
          <li
            onClick={() => {
              handleMenuClick("day");
            }}
          >
            <img src={time} alt={"merchant_icon"} />
            <span>Day</span>
          </li>
          <img className="img" src={right} alt={"right-icon"} />
        </div>
      </ul>
      {subMenu()}
    </div>
  );
};

export default MenuList;
