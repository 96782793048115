import React, { useState, useEffect, useRef } from "react";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FormF, Field, ErrorMessage } from "formik";
import DraggableList from "react-draggable-list";
import Calendar from "react-calendar";
// import TimePicker from 'rc-time-picker';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import fileDownload from "js-file-download";
import Switch from "react-switch";
import "react-phone-input-2/lib/style.css";
import "./react-contextmenu.css";
import OptimizeMission from "../pages/dashboard/OptimizeMission";
import recurringIcon from "../assets/image/recurring.png";
import { Row, Col, Form, FormControl, Button } from "react-bootstrap";
import { FaAngleLeft, FaSearch } from "react-icons/fa";
import searchicon from "../assets/image/searchicon.png";
import Iconpuzzle from "../assets/image/icon-puzzle.png";
import MerchantIcon from "../assets/image/merchant_icon_white.png";
import ClockIcon from "../assets/image/clock.png";
import Clicon from "../assets/image/calendar.png";
import Union from "../assets/image/Union.png";
import downloadicon from "../assets/image/downloadicon.png";
import closeicon from "../assets/image/close-icon.png";
import upicon from "../assets/image/upicon.png";
import righticon from "../assets/image/righticon.png";
import lefticon from "../assets/image/lefticon.png";
import linkicon from "../assets/image/link-icon.png";
import profileicon from "../assets/image/personalicon.png";
import profilecirclicon from "../assets/image/profilecirclicon.png";
import moment from "moment";
import DatePicker from "react-date-picker";
import sidebartpicon from "../assets/image/sidebartpicon.png";
import RightSideTaskDetails from "../pages/TaskDetails/RightSideTaskDetails";
import * as Yup from "yup";
import {
  ContextMenu,
  MenuItem,
  ContextMenuTrigger,
  showMenu,
} from "react-contextmenu";

import userService from "../services/user.service";
import taskService from "../services/task.service";
import hubService from "../services/hub.service";
import teamService from "../services/team.service";

import { successAlert, errorAlert } from "../services/toast.service";
import authService from "../services/auth.service";
import Select from "react-select";
import momentTz from "moment-timezone";
import timeZoneHeader from "../services/timezone-header";
import CreateTaskComponent from "./RightSideBarComponents/CreateTaskComponent";
import { KmToMiles } from "../helpers/helperFunctions";
const timezone = { ...timeZoneHeader() };

const Rightsidebar = (props) => {
  const history = useHistory();
  const userData = useSelector((state) => state.auth.user);
  const superAdminRole =
    userData && userData.role && userData.role === 1 ? true : false;
  const [taskSettingData, setTaskSettingData] = useState(null);
  const [regionalSettingData, setRegionalSettingData] = useState(null);

  const [inputValue, setInputValue] = useState("");
  const [changeTaskStatus, setChangeStatus] = useState(false);

  const unAssignedTaskString = `dummm-${btoa(
    Math.random().toString()
  ).substring(0, 16)}`;

  const assignedMissionTaskString = `dummm-${btoa(
    Math.random().toString()
  ).substring(0, 16)}`;

  const unAssignedMissionTaskString = `dummm-${btoa(
    Math.random().toString()
  ).substring(0, 16)}`;

  const completedMissionTaskString = `dummm-${btoa(
    Math.random().toString()
  ).substring(0, 16)}`;
  const unAssignedMissionString = `dummm-${btoa(
    Math.random().toString()
  ).substring(0, 16)}`;

  const assignedMissionString = `dummm-${btoa(
    Math.random().toString()
  ).substring(0, 16)}`;

  const completedMissionString = `dummm-${btoa(
    Math.random().toString()
  ).substring(0, 16)}`;

  const defaultDurationTime = 60;
  const defaultMissionCapacity = 1000;

  const [allAccess, setAllAccess] = useState({
    task: {
      create: false,
      edit: false,
      delete: false,
      duplicate: false,
    },
    mission: {
      create: false,
      delete: false,
      optimize: false,
      assign: false,
      unAssign: false,
    },
  });

  let selectAllOption = {
    id: "all",
    teamName: "All Teams",
    firstName: "All Users",
    lastName: "",
  };

  //   const CalendarContainer = styled.div`
  // .react-calendar__tile--now {
  //   background: #006edc;
  // }
  // .react-calendar__tile--active {
  //   /* background: #006edc; */
  //   border: #e6e6e6 solid;
  //   color: white;
  //   background-color: transparent !important;
  // }
  // `;

  const [images, setImages] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [teams, setTeams] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [calenderToggle, setCalenderToggle] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [linkedTaskList, setLinkedTaskList] = useState([]);
  const [unassignTask, setunassignTask] = useState("false");
  const [assignTask, setassignTask] = useState("false");
  const [Slideopen, setSlideopen] = useState("false");
  const [fullScreen, setFullScreen] = useState(false);
  const [completedTask, setcompletedTask] = useState("false");
  const [isActive, setActive] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [collapseState, setCollapseState] = useState({
    assignTo: "",
    startDate: moment(new Date()).toDate(),
    dueDate: moment(new Date()).toDate(),
    priority: "none",
    watcher: "",
    estimate: "",
    workedOn: moment(new Date()).toDate(),
    progress: "",
    riskStatus: "",
  });

  const containerRef = useRef();

  useEffect(() => {
    getTeamList();
    getAgentList();
    getUsersList();
    getHubList();
    getSetting();
  }, []);
  useEffect(() => {
    if (!countryCode) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        displayLocation(position.coords.latitude, position.coords.longitude);
      });
    }
  }, []);

  function displayLocation(latitude, longitude) {
    var geocoder;
    geocoder = new window.google.maps.Geocoder();
    var latlng = new window.google.maps.LatLng(latitude, longitude);

    geocoder.geocode({ latLng: latlng }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          let findCountry = results[0].address_components.find((items) => {
            return items.types[0] === "country";
          });
          if (findCountry) {
            console.log("findCountry.short_name", findCountry.short_name);
            setCountryCode(findCountry.short_name);
          } else {
            setCountryCode("CA");
          }
        } else {
          setCountryCode("CA");
        }
      } else {
        setCountryCode("CA");
      }
    });
  }

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
    }
    if (user.role === 2 && user.userId) {
      props.setFormData((prev) => {
        return {
          ...prev,
          merchantId: user.id,
          createdFor: usersList[0] ? usersList[0].id : user.id,
          userId: usersList[0] ? usersList[0].id : user.id,
        };
      });
    } else {
      props.setFormData((prev) => {
        return {
          ...prev,
          merchantId: user.id,
          createdFor: user.id,
          userId:
            (user.role === 2 || user.role === 4) && user.userId
              ? user.userId
              : user.id,
        };
      });
    }
  }, [usersList]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchText !== "") mainSearch();
      // Send Axios request here
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    let newAccess = Object.assign({}, allAccess);
    if (
      userData.role === 1 ||
      (userData.role === 2 &&
        userData.userId &&
        (userData.userRole === "admin" || userData.userRole === "manager"))
    ) {
      newAccess.task = {
        create: true,
        edit: true,
        delete: true,
        duplicate: true,
      };
      newAccess.mission = {
        create: true,
        optimize: true,
        delete: true,
        assign: true,
        unAssign: true,
      };
    } else if (userData.role === 2 && !userData.userId) {
      newAccess.task = {
        create: true,
        edit: true,
        delete: true,
        duplicate: true,
      };
      newAccess.mission = {
        create: true,
        optimize: true,
        delete: true,
        assign: true,
        unAssign: true,
      };
    } else if (userData.role === 4) {
      if (userData.userRole === "crud") {
        newAccess.task = {
          create: true,
          edit: true,
          delete: true,
          duplicate: true,
        };
        newAccess.mission = {
          create: true,
          optimize: true,
          delete: true,
          assign: true,
          unAssign: true,
        };
      }
    }
    setAllAccess(newAccess);
  }, [userData]);

  useEffect(() => {
    props.getUnassignedTaskList();
    // props.getMissionList();
    // getAssignedMissionList();

    props.setFormData((prev) => {
      return {
        ...prev,
        orderDate: props.currentDate,
      };
    });
    if (moment(props.currentDate) > moment(collapseState.startDate)) {
      props.setOptimiseFormData((prev) => {
        console.log("prev", prev);
        return {
          ...prev,
          missionDate: moment(props.currentDate).toDate(),
        };
      });
      setCollapseState((prev) => {
        return { ...prev, dueDate: moment(props.currentDate).toDate() };
      });
    } else {
      props.setOptimiseFormData((prev) => {
        return {
          ...prev,
          missionDate: moment(collapseState.startDate).toDate(),
        };
      });
      setCollapseState((prev) => {
        return { ...prev, dueDate: moment(collapseState.startDate).toDate() };
      });
    }
  }, [props.currentDate]);

  useEffect(() => {
    props.getUnassignedTaskList();
  }, [props.importToggle]);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const createTaskToggle = () => {
    getSetting();
    if (teams.length) {
      props.setFormData((prev) => {
        return {
          ...prev,
          teamIds: teams.map((i) => i.id),
        };
      });
    }
    props.setcreateTask(!props.createTask);
    props.setButtonText("Create Task");
  };

  const unassignTaskToggle = () => {
    setunassignTask(!unassignTask);
  };

  const assignTaskToggle = () => {
    setassignTask(!assignTask);
  };

  const SlideopenToggle = () => {
    setSlideopen(!Slideopen);
  };

  const FullScreenToggle = () => {
    setFullScreen(!fullScreen);
    if (!fullScreen) go_full_screen();
    else {
      handleExitFullScreenClick();
    }
  };

  function go_full_screen() {
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }

  const handleExitFullScreenClick = () => {
    document.webkitExitFullscreen();
  };

  const completedTaskToggle = () => {
    setcompletedTask(!completedTask);
  };

  const getUsersList = async () => {
    let roles = [];
    if (userData.role === 1) {
      if (userData.subAdmin) {
        roles = [2, 4];
      } else {
        roles = [2];
      }
    } else if (userData.role === 2 && userData.userId) {
      roles = [2];
    } else {
      roles = [4];
    }
    let params = { limit: 100, roles };
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
    }
    if (
      user.role === 1 &&
      user.userId &&
      user.permittedUsers &&
      user.permittedUsers.length
    ) {
      params.userIds = user.permittedUsers;
    }
    if (user.role === 2 && user.userId) {
      params.userIds = [user.userId];
    }
    const response = await userService.getSubAdminList(params);
    if (Number(response.status) === 200) {
      if (userData.role === 2 && userData.userId) {
        if (response.data.results && response.data.results.length) {
          setUsersList(response.data.results);
          // props.setFormData({ ...props.formData, userId: response.data.results[0].id, merchantId: response.data.results[0].id })
        } else {
          setUsersList([user]);
          // props.setFormData({ ...props.formData, userId: user.id, merchantId: user.id })
        }
      } else {
        if (response.data.results && response.data.results.length) {
          let found = response.data.results.find((r) => r.id === user.id);
          if (!found) {
            setUsersList([...response.data.results, user]);
          } else {
            setUsersList(response.data.results);
          }
        } else {
          setUsersList([user]);
        }
        // props.setFormData({ ...props.formData, userId: user.id, merchantId: user.id })
      }
    }
    console.log("team list response  :::", response);
  };

  // useEffect(() => {
  //   getSetting();
  //   // getCommonServices();
  // }, [userData])
  const getSetting = async () => {
    let params = {
      userId: userData.id,
      subType: ["TASK_AND_MISSION", "REGIONAL_SETTINGS"],
    };
    // if (userData.role === 1) {
    //   params.subType = [
    //     settingSubType.COMMON_AGENT_APP,
    //     settingSubType.COMMON_API_KEY,
    //     settingSubType.COMMON_COMMUNICATIONS,
    //     settingSubType.COMMON_GROUPS,
    //     settingSubType.COMMON_LEGAL_PROTECTION,
    //     settingSubType.COMMON_NOTIFICATIONS,
    //     settingSubType.COMMON_PERFORMANCE,
    //     settingSubType.COMMON_REGIONAL_SETTINGS,
    //     settingSubType.COMMON_TASK_AND_MISSION,
    //   ]
    // }
    let response = await authService.getSettings(params);
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.results.length
    ) {
      let findTaskSetting = response.data.data.results.find((i) => {
        return i.subType === "TASK_AND_MISSION";
      });
      setTaskSettingData(findTaskSetting);
      if (findTaskSetting) {
        let taskSettingData = findTaskSetting;
        props.setFormData((prev) => {
          let data = {
            ...prev,
            after:
              taskSettingData &&
                (taskSettingData.defaultAfterTime ||
                  taskSettingData.defaultAfterTime === "")
                ? taskSettingData.defaultAfterTime
                : "",
            afterTimePicker:
              taskSettingData &&
                (taskSettingData.defaultAfterTimePicker ||
                  taskSettingData.defaultAfterTimePicker === "")
                ? taskSettingData.defaultAfterTimePicker
                : null,
            before:
              taskSettingData &&
                (taskSettingData.defaultBeforeTime ||
                  taskSettingData.defaultBeforeTime === "")
                ? taskSettingData.defaultBeforeTime
                : "",
            beforeTimePicker:
              taskSettingData &&
                (taskSettingData.defaultBeforeTimePicker ||
                  taskSettingData.defaultBeforeTimePicker === "")
                ? taskSettingData.defaultBeforeTimePicker
                : null,
            scheduledTimePicker:
              taskSettingData &&
                (taskSettingData.defaultScheduleTimePicker ||
                  taskSettingData.defaultScheduleTimePicker === "")
                ? taskSettingData.defaultScheduleTimePicker
                : null,
            scheduledTime:
              taskSettingData &&
                (taskSettingData.defaultScheduleTime ||
                  taskSettingData.defaultScheduleTime === "")
                ? taskSettingData.defaultScheduleTime
                : "",
            afterSeconds:
              taskSettingData &&
                (taskSettingData.defaultAfterTimeSeconds ||
                  taskSettingData.defaultAfterTimeSeconds === 0)
                ? taskSettingData.defaultAfterTimeSeconds
                : 0,
            beforeSeconds:
              taskSettingData &&
                (taskSettingData.defaultBeforeTimeSeconds ||
                  taskSettingData.defaultBeforeTimeSeconds === 0)
                ? taskSettingData.defaultBeforeTimeSeconds
                : 0,
            scheduledTimeSeconds:
              taskSettingData &&
                (taskSettingData.defaultScheduleTimeSeconds ||
                  taskSettingData.defaultScheduleTimeSeconds === 0)
                ? taskSettingData.defaultScheduleTimeSeconds
                : 0,
            durationTime:
              taskSettingData &&
                (taskSettingData.defaultTaskDuration ||
                  taskSettingData.defaultTaskDuration === 0)
                ? taskSettingData.defaultTaskDuration
                : defaultDurationTime,
            capacity:
              taskSettingData &&
                (taskSettingData.defaultTaskCapacity ||
                  taskSettingData.defaultTaskCapacity === 0)
                ? taskSettingData.defaultTaskCapacity
                : defaultMissionCapacity,
            documents:
              taskSettingData &&
                (taskSettingData.documents ||
                  taskSettingData.documents.length === 0)
                ? taskSettingData.documents
                : [],
            beforeTime: "",
            afterTime: "",
            scheduleTime: "",
          };
          // if (taskSettingData && taskSettingData.defaultAfterTimeSeconds) {
          //   let hours = taskSettingData.defaultAfterTimeSeconds / 3600;
          //   let timeSplit = data.after;
          //   data.afterTime = `${timeSplit[0]}:${timeSplit[1]} ${hours > 12 ? "pm" : "am"}`
          // }
          // if (taskSettingData && taskSettingData.defaultBeforeTimeSeconds) {
          //   let hours = taskSettingData.defaultBeforeTimeSeconds / 3600;
          //   let timeSplit = data.before;
          //   data.beforeTime = `${timeSplit[0]}:${timeSplit[1]} ${hours > 12 ? "pm" : "am"}`
          // }
          // if (taskSettingData && taskSettingData.scheduledTimeSeconds) {
          //   let hours = taskSettingData.scheduledTimeSeconds / 3600;
          //   let timeSplit = data.scheduledTime;
          //   data.scheduleTime = `${timeSplit[0]}:${timeSplit[1]} ${hours > 12 ? "pm" : "am"}`
          // }
          return data;
        });
      }
      let findRegionalSetting = response.data.data.results.find((i) => {
        return i.subType === "REGIONAL_SETTINGS";
      });
      if (findRegionalSetting) {
        props.setFormData((prev) => {
          return {
            ...prev,
            capacityUnit:
              findRegionalSetting && findRegionalSetting.volumeUnit
                ? findRegionalSetting.volumeUnit
                : "m3",
            distanceUnit:
              findRegionalSetting && findRegionalSetting.distanceUnit
                ? findRegionalSetting.distanceUnit
                : "km",
          };
        });
      }
      setRegionalSettingData(findRegionalSetting);
    }
  };

  const getHubList = async () => {
    const response = await hubService.getHubList({ limit: 100 });
    if (response.status == 200) {
      setHubs(response.data.results || []);
    }
  };

  const getAgentList = async (payload) => {
    let params = { limit: 100 };
    // if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
    //   params.userIds = userData.permittedUsers
    // }

    if (userData && userData.teamIds) {
      params.teamIds = [...userData.teamIds];
    }
    if (payload && payload.teamId) {
      params.teamIds = [payload.teamId];
    }
    const response = await userService.getAgentList(params);
    if (response.status == 200) {
      setAgentList(response.data.results || []);
      console.log("agent list :::", response.data.results);
    }
  };
  const createMissionApiHandler = async () => {
    props.setLoading(true);
    if (props.selectedTasks && props.selectedTasks.length) {
      let maxMissionDuration = 0;
      let maxMissionCapacity = 0;
      let missionTaskCapacityUnit =
        taskSettingData && taskSettingData.defaultMaxMissionCapacityUnit
          ? taskSettingData.defaultMaxMissionCapacityUnit
          : "Cap";
      let startFrom,
        endAt,
        missionDetails = {
          totalTime: 0,
          totalDist: 0,
        },
        tasks = {
          locations: [],
        };
      for (let task in props.selectedTasks) {
        let findTask = props.unassignedTaskList.find((item) => {
          return item.id === props.selectedTasks[task];
        });
        if (findTask) {
          maxMissionCapacity += findTask.capacity
            ? Number(findTask.capacity)
            : 0;
          if (findTask.durationTime)
            maxMissionDuration += Number(findTask.durationTime);
          if (Number(task) === 0) {
            startFrom = {
              location: findTask.location,
            };
          } else if (Number(task) === Number(props.selectedTasks.length) - 1) {
            endAt = {
              location: findTask.location,
            };
          } else {
            tasks.locations.push({
              location: findTask.location,
              id: findTask.id,
            });
          }
        }
      }
      let tasksDistTime = [];
      if (startFrom && endAt) {
        let result = await OptimizeMission.directionsServiceHere(
          tasks,
          false,
          [],
          startFrom,
          endAt
        );
        if (result) {
          let myroute = result[0];
          missionDetails.totalDist += Number(myroute.distance);
          missionDetails.totalTime += Number(myroute.time);
          for (let i = 0; i < myroute.interconnections.length; i++) {
            tasksDistTime.push({
              id: props.selectedTasks[i + 1],
              distanceFromPrevTask: myroute.interconnections[i].distance / 1000,
              timeFromPrevTask: Number(myroute.interconnections[i].time) / 60,
            });
          }
        }
      }
      missionDetails.totalTime += Number(maxMissionDuration * 60);
      if (missionDetails.totalTime) {
        missionDetails.totalTime = missionDetails.totalTime / 3600;
        missionDetails.totalTime = missionDetails.totalTime.toFixed(1);
      }
      if (missionDetails.totalDist) {
        missionDetails.totalDist = missionDetails.totalDist / 1000;
        missionDetails.totalDist = missionDetails.totalDist.toFixed(1);
      }

      const response = await taskService.createMession({
        taskIds: props.selectedTasks,
        date: props.currentDate,
        tasks: tasksDistTime,
        missionTaskCapacity: maxMissionCapacity,
        maxMissionDuration: maxMissionDuration,
        missionTaskCapacityUnit: missionTaskCapacityUnit,
        missionDetails: missionDetails,
      });
      props.setLoading(false);
      if (response.status === 200) {
        props.getMissionList();
        props.getUnassignedTaskList();
      }
    }
  };

  const getTeamList = async (payload, setFieldValue) => {
    let params = { limit: 100 };
    if (
      userData.role === 1 &&
      userData.userId &&
      userData.permittedUsers &&
      userData.permittedUsers.length
    ) {
      params.userIds = userData.permittedUsers;
    }
    if (userData.teamIds) {
      params.teamIds = userData.teamIds;
    }
    if (userData.subAdmin) {
      delete params.userIds;
      delete params.teamIds;
    }
    if (payload && payload.userId) {
      params.userId = payload.userId;
    }
    const response = await teamService.getTeamList(params);
    if (Number(response.status) === 200) {
      setTeams(response.data.results || []);
      if (
        payload &&
        payload.userId &&
        response.data &&
        response.data.results.length &&
        setFieldValue
      ) {
        setFieldValue(
          "teamIds",
          response.data.results.map((i) => i.id)
        );
        setFieldValue("values", []);
      }
    }
  };

  const mainSearch = async () => {
    const response = await taskService.mainSearch({
      searchText,
      date: props.currentDate,
    });
    if (response.status == 200) {
      setSearchData(response.data || {});
    }
  };

  const validateCustomTaskStatus = (values, props) => {
    let error = {};
    if (!values.taskStatus || values.taskStatus === "0") {
      error.taskStatus = "Select task status";
    } else {
      delete error.taskStatus;
    }
    return error;
  };

  const validationSchemaChangeStatus = Yup.object().shape({
    taskStatus: Yup.string().required("Select task status!"),
    // password: Yup.string().required("Password is required!"),
  });

  const optimiseValidationSchema = Yup.object().shape({
    startFrom: Yup.string().required("Start Location is required!"),
    endAt: Yup.string().required("End Location is required!"),
    // password: Yup.string().required("Password is required!"),
    maxMissionTasks: Yup.number().max(
      98,
      "Max mission tasks must be less than or equal to 98"
    ),
  });

  const handleSubmitChangeStatus = async (formValue, { resetForm }) => {
    const response = await taskService.changeStatus({
      taskId: formValue.id,
      taskStatus: formValue.taskStatus,
    });
    if (response.data.success) {
      props.setButtonText("Create Task");
      delete props.initialValues.merchantId;
      delete props.initialValues.userId;
      delete props.initialValues.createdFor;
      props.setcreateTask(true);
      props.setFormData((prev) => {
        return {
          ...prev,
          ...props.initialValues,
        };
      });
      props.setAddress("");
      resetForm();

      props.getUnassignedTaskList();
      // getAssignedMissionList(true);
      props.getMissionList(true, true);
      //docs
      props.setSelectedDocs([]);
      // props.getLibraryList()
      // refresh the bookmark list in dashboard
      if (response.data.task.id) {
        props.getTaskList(response.data.task.id, false);
      }
    } else {
      errorAlert(
        response.data.message
          ? response.data.message
          : "Something went Wrong  !"
      );
    }
  };

  const handleOptimiseSubmit = async (formValue, { resetForm }) => {
    try {
      props.setOptimiseFormData(formValue);
      if (formValue.multiMission) {
        return props.handleOptimiseSubmit(null, formValue);
      } else {
        let checkMMRequirements = await OptimizeMission.checkMMRequirement(
          formValue,
          [props.selectedMission]
        );
        if (checkMMRequirements) {
          props.setMultiMissionToggle(true);
        } else {
          console.log("formValue", formValue);
          return props.handleOptimiseSubmit(null, formValue);
        }
      }
    } catch (err) {
      console.log("error:::", err);
    }
  };

  const cancelTask = () => {
    props.setButtonText("Create Task");
    props.setcreateTask(true);

    setChangeStatus(false);
    delete props.initialValues.merchantId;
    delete props.initialValues.userId;
    delete props.initialValues.createdFor;
    props.setAddress("");
    props.setFormData((prev) => {
      return {
        ...prev,
        ...props.initialValues,
      };
    });
  };
  const toggleflip = () => {
    setActive(!isActive);
  };

  const cancelOptimiseMission = () => {
    props.setOptimiseFormData(props.props.initialValues2);
    props.setShowOptimiseSection(false);
  };

  const OptimiseSection = () => {
    return (
      <div className="optimize-mission-box">
        <div className="optimize-heading">
          <h2>Optimize Mission</h2>
          <Button
            onClick={() => {
              props.setShowOptimiseSection(false);
            }}
          >
            <img src={closeicon} alt="closeIcon" />
          </Button>
        </div>
        <Formik
          key="optimisesection"
          enableReinitialize
          initialValues={props.optimiseFormData}
          render={({ values, handleSubmit, handleChange, setFieldValue }) => {
            return (
              <FormF>
                <div className="optimize-date-field">
                  <div className="opt-heading">
                    <h2>Date</h2>
                  </div>
                  <div className="opt-description">
                    <label>Mission date</label>
                    <div className="dateopt">
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        // ClassName="react-date-picker"
                        onChange={(date) => {
                          setCollapseState({
                            ...collapseState,
                            dueDate: date,
                          });
                          setFieldValue("missionDate", date);
                        }}
                        selectsEnd
                        value={collapseState.dueDate}
                        minDate={collapseState.startDate}
                      />
                    </div>
                  </div>
                </div>

                <div className="optimize-sc-field">
                  <div className="opt-heading">
                    <h2>Start and end locations</h2>
                  </div>
                  <div className="opt-description">
                    <div className="optd-option">
                      <label>Start from</label>
                      <div className="optd-sop">
                        <Field
                          className="form-control"
                          name="startFrom"
                          as="select"
                        >
                          <option value="" selected>
                            Select Hubs
                          </option>
                          {hubs &&
                            hubs.length > 0 &&
                            hubs.map((t) => (
                              <option value={t.id}>
                                {t.hubName ? t.hubName : ""}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          style={{
                            fontSize: "10px",
                            color: "#ea4335",
                            marginTop: "3px",
                            marginLeft: "10px",
                          }}
                          name="startFrom"
                          component="div"
                        />

                        {/* <Form.Select>
                  <option>Furniture mtl warehouse</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select> */}
                      </div>
                    </div>
                    <div className="optd-option">
                      <label>End at</label>
                      <div className="optd-sop">
                        <Field
                          className="form-control"
                          name="endAt"
                          as="select"
                        >
                          <option value="" selected>
                            Select Hubs
                          </option>
                          {hubs &&
                            hubs.length > 0 &&
                            hubs.map((t) => (
                              <option value={t.id}>
                                {t.hubName ? t.hubName : ""}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          style={{
                            fontSize: "10px",
                            color: "#ea4335",
                            marginTop: "3px",
                            marginLeft: "10px",
                          }}
                          name="endAt"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="optimize-opm-field">
                  <div className="opt-heading">
                    <h2>Optimization mode</h2>
                  </div>
                  <div className="opt-description">
                    <div className="opt-mode-btn">
                      <Button
                        type="button"
                        className={
                          "" +
                          (values["optimiseMode"] == "time" ? "active" : "")
                        }
                        onClick={() => {
                          setFieldValue("optimiseMode", "time");
                        }}
                      >
                        Time
                      </Button>
                      <Button
                        type="button"
                        className={
                          "" +
                          (values["optimiseMode"] == "distance" ? "active" : "")
                        }
                        onClick={() => {
                          setFieldValue("optimiseMode", "distance");
                        }}
                      >
                        Distance
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="optimize-option-field">
                  <div className="opt-heading">
                    <h2>Optimization options</h2>
                  </div>
                  <div className="opt-description">
                    <div className="optd-option">
                      <label>Max mission duration</label>
                      <div className="mission-duration">
                        {/* <Form.Control type="text" name="maxMissionDuration" defaultValue={`${defaultMaxMissionDuration}`} /> */}
                        <Form.Group>
                          <Field
                            type="number"
                            min="0"
                            step="any"
                            className="form-control"
                            placeholder="Max mission duration"
                            name="maxMissionDuration"
                            aria-label="Max mission duration"
                            value={values.maxMissionDuration}
                          />
                          <ErrorMessage
                            name="maxMissionDuration"
                            component="div"
                          />
                        </Form.Group>
                        <span>hour(s)</span>
                      </div>
                    </div>
                    <div className="optd-option">
                      <label>Max tasks per mission</label>
                      <div className="optd-sop">
                        {/* <Form.Control type="text" name="maxMissionTasks" defaultValue={defaultMaxMissionTasks} /> */}
                        <Col xs={12}>
                          <Form.Group>
                            <Field
                              type="number"
                              min="0"
                              max="98"
                              className="form-control"
                              placeholder="Max tasks per mission"
                              name="maxMissionTasks"
                              aria-label="Max tasks per mission"
                              value={values.maxMissionTasks}
                            />
                            <ErrorMessage
                              name="maxMissionTasks"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                      </div>
                    </div>
                    <div className="optd-option">
                      <label>Max mission capacity</label>
                      <div className="optd-sop">
                        {/* <Form.Control type="text" name="maxMissionTasks" defaultValue={defaultMaxMissionTasks} /> */}
                        <Col xs={12}>
                          <Form.Group
                            onClick={(e) =>
                              props.features &&
                                !props.features.vehicleCapacityManagement
                                ? history.push({
                                  pathname:
                                    "/Business/vehicleCapacityManagement",
                                })
                                : null
                            }
                          >
                            <Field
                              type="number"
                              min="0"
                              step="any"
                              className="form-control"
                              placeholder="Max mission capacity"
                              name="maxMissionCapacity"
                              aria-label="Max mission capacity"
                              value={values.maxMissionCapacity}
                              disabled={
                                (props.features &&
                                  props.features.vehicleCapacityManagement) ||
                                  superAdminRole
                                  ? false
                                  : true
                              }
                            />
                            <ErrorMessage
                              name="maxMissionCapacity"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                      </div>
                    </div>
                    {
                      <div className="optd-option">
                        <label>Multi-Missions</label>
                        <div className="optd-sop">
                          <div
                            className="toggle-switch"
                            onClick={(e) => {
                              if (
                                (props.features &&
                                  props.features.multiMission) ||
                                superAdminRole
                              ) {
                              } else {
                                history.push({
                                  pathname: "/Business/multiMission",
                                });
                              }
                            }}
                          >
                            {/* <Form.Control
                            type="checkbox"
                            id="multiMission"
                            name="multiMission"
                            defaultValue={values.multiMission}
                            onChange={(e) => { setFieldValue("multiMission", e.target.checked) }}
                          />
                          <label htmlFor="multiMission">
                            <span className="toggle-track"></span>
                          </label> */}
                            <Switch
                              className="toggle-switch-mission"
                              onColor={"#2A85FF"}
                              offColor="#696969"
                              height={25}
                              // width={25}
                              disabled={
                                (props.features &&
                                  props.features.multiMission) ||
                                  superAdminRole
                                  ? false
                                  : true
                              }
                              uncheckedIcon={false}
                              checkedIcon={false}
                              onChange={(e) => {
                                if (
                                  (props.features &&
                                    props.features.multiMission) ||
                                  superAdminRole
                                ) {
                                  setFieldValue("multiMission", e);
                                } else
                                  history.push({
                                    pathname: "/Business/multiMission",
                                  });
                              }}
                              checked={values.multiMission}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="optimize-agent-field">
                  <div className="opt-heading">
                    <h2>Agent assignation</h2>
                  </div>
                  <div className="opt-description">
                    <div className="optd-option">
                      <label>Team</label>
                      <div className="optd-sop">
                        <Field
                          className="form-control"
                          name="teamId"
                          as="select"
                          value={values["teamId"]}
                          onChange={(e) => {
                            setFieldValue("teamId", e.target.value);
                            getAgentList({ teamId: e.target.value });
                          }}
                        >
                          <option value="" selected>
                            Select Team
                          </option>
                          {teams &&
                            teams.length > 0 &&
                            teams.map((t) => (
                              <option value={t.id}>
                                {t.teamName ? t.teamName : ""}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          style={{
                            fontSize: "10px",
                            color: "#ea4335",
                            marginTop: "3px",
                            marginLeft: "10px",
                          }}
                          name="teamId"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="optd-option">
                      <label>Agent</label>
                      <div className="optd-sop">
                        <Field
                          className="form-control"
                          name="agentId"
                          as="select"
                        >
                          <option value="" selected>
                            Select Agent
                          </option>
                          {agentList &&
                            agentList.length > 0 &&
                            values["teamId"] &&
                            agentList.map((t) => (
                              <option value={t.id}>
                                {t.firstName ? t.firstName : ""}{" "}
                                {t.lastName ? t.lastName : ""}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          style={{
                            fontSize: "10px",
                            color: "#ea4335",
                            marginTop: "3px",
                            marginLeft: "10px",
                          }}
                          name="agentId"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="ct-btn">
                  <Button
                    type="button"
                    className="cncle-btn"
                    onClick={cancelOptimiseMission}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="ctaskbtn">
                    Optimize
                  </Button>
                </div>
              </FormF>
            );
          }}
          validationSchema={optimiseValidationSchema}
          // validate={validateCustom}
          onSubmit={handleOptimiseSubmit}
        ></Formik>
      </div>
    );
  };

  const linkClickHandler = async () => {
    if ((props.features && props.features.linkedTask) || superAdminRole) {
      if (props.selectedTasks.length > 4) {
        return errorAlert("You can only select upto 4 tasks to link");
      }
      let findMissionFromList = props.missionList.find(
        (item) => item.id === props.currentMission
      );
      let missionTasks = findMissionFromList.tasks;
      let taskForLink = [];
      for (let key of props.selectedTasks) {
        let filteredTask = missionTasks.find((i) => i.id === key);
        if (filteredTask) {
          taskForLink.push({
            id: filteredTask.id,
            orderType: filteredTask.orderType,
          });
        }
      }

      let data = {
        missionId: props.currentMission,
        linkedTasks: taskForLink,
      };

      const response = await taskService.setLinkedTasks(data);
      if (response) {
        successAlert("Tasks linked successfully");
        setLinkedTaskList([]);
        props.setSelectedTasks([]);
        props.getTaskList();
        props.getMissionList();
      } else {
        return errorAlert(
          response.data.message
            ? response.data.message
            : "Something went Wrong  !"
        );
      }
    } else {
      history.push({
        pathname: "/Business/linkedTask",
      });
    }
  };
  const renderCreateTaskButton = () => {
    if (allAccess && allAccess.task && allAccess.task.create) {
      return (
        <div
          className={
            props.createTask ? "createtaskbox " : "createtaskbox createTask"
          }
          onClick={createTaskToggle}
        >
          <div>
            <Button className="cticon" onClick={createTaskToggle}>
              <img src={Union} alt="icon" />
            </Button>
            <span>Create</span>
          </div>

          <div>
            <Button>
              <img
                src={downloadicon}
                onClick={(event) => {
                  event.stopPropagation(); // <-- this stops the click going through to the parent div
                  // props.setImportToggle(true);
                  props.importPopup();
                }}
                alt="icon"
              />
            </Button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderName = (obj) => {
    if (obj.customer && obj.customer.firstName) {
      return `${obj.customer.firstName ? obj.customer.firstName : ""} ${obj.customer.lastName ? obj.customer.lastName : ""
        }`;
    } else if (obj.customerId) {
      return `${obj.customerId.firstName ? obj.customerId.firstName : ""} ${obj.customerId.lastName ? obj.customerId.lastName : ""
        }`;
    } else {
      return ``;
    }
  };

  const formatAMPM = (seconds) => {
    return momentTz()
      .tz(timezone.timezone)
      .startOf("d")
      .add(seconds, "seconds")
      .format("hh:mm A");
  };

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const Item = (props) => {
    const { onMouseDown } = props.dragHandleProps;
    return (
      <div className="disable-select" draggable>
        <ContextMenuTrigger holdToDisplay={-1} id={props.item.id}>
          <div
            className="disable-select dragHandle"
            // style={{
            //   fontWeight: "600",
            //   transform: "rotate(90deg)",
            //   width: "20px",
            //   height: "20px",
            //   backgroundColor: "black"
            // }}
            onMouseDown={(e) => {
              // console.log("mouseDown");
              // document.body.style.overflow = "hidden";
              // console.log("eeeeeeeee", e)
              onMouseDown(e);
            }}
            onTouchEnd={(e) => {
              // e.target.style.backgroundColor = "black";
              // document.body.style.overflow = "visible";
            }}
            onMouseUp={() => {
              // document.body.style.overflow = "visible";
            }}
          >
            <div
              id={"mission_tasks"}
              // onClick={(e) => missionTaskSingleClickHandler(e, t, i)}
              className={`uns-field-ck ${props.item.active && props.item.active === true ? "active" : ""
                } completed-bg ${props.item.taskStatus}`}
              onClick={(e) => {
                e.stopPropagation();
                props.commonProps.missionTaskSingleClickHandler(
                  e,
                  props.item,
                  0
                );
              }}
              onContextMenu={async (e) => {
                // e.preventDefault();
                // e.stopPropagation();
                // props.commonProps.missionTaskSingleClickHandler(e, props.item, 0)
                // await timeout(1000)
                // handleContextClick(e, props.item.id, "mission_tasks")
              }}
            >
              <div className="unassign-field-box">
                <div className="unassign-detail">
                  <div className="unassign-title">
                    <span className="unls">{props.item.orderType}</span>
                    <span className="lnum">{props.item.position + 1}.</span>
                    <span className="title">{renderName(props.item)}</span>
                  </div>
                  {(props.item.taskStatus === "completed" ||
                    props.item.taskStatus === "failed") && (
                      <span className="eta">
                        {moment(props.item.orderCompletedDate).format("hh:mm a")}
                      </span>
                    )}
                  {props.item.taskStatus !== "reached" &&
                    props.item.taskStatus !== "completed" &&
                    props.item.taskStatus !== "failed" &&
                    props.item.eta && (
                      <span className="eta"> {props.item.eta}</span>
                    )}
                  {props.item.linked && (
                    <div className="unassign-link">
                      <img src={linkicon} alt="icon" />
                    </div>
                  )}
                  {/* <Button className="install-btn">Install</Button> */}
                </div>
                <div className="unassign-description">
                  <p>
                    {props.item && props.item.address
                      ? props.item.address
                      : `124 Verdun St, Montreal, H4G2Y5, Qc, Canada`}
                  </p>
                </div>
                <div className="task-info">
                  {props.item.merchantId &&
                    props.item.merchantId.role === 4 &&
                    props.item.merchantId.companyName && (
                      <span className="info-container">
                        <img
                          className="info-icon"
                          src={MerchantIcon}
                          alt="MerchantIcon"
                        />
                        {`${props.item.merchantId.companyName}`}
                      </span>
                    )}
                  {props.item.merchantId &&
                    props.item.merchantId.role === 2 &&
                    props.item.merchantId.companyName && (
                      <span className="info-container">
                        <img
                          className="info-icon"
                          src={MerchantIcon}
                          alt="MerchantIcon"
                        />
                        {`${props.item.merchantId.companyName}`}
                      </span>
                    )}
                  {props.item.scheduledTime && (
                    <span className="info-container">
                      <img
                        className="info-icon"
                        src={ClockIcon}
                        alt="ClockIcon"
                      />
                      {formatAMPM(props.item.scheduledTimeSeconds)}
                    </span>
                  )}
                  {(props.item.after || props.item.before) && (
                    <span className="info-container">
                      <img
                        className="info-icon"
                        src={ClockIcon}
                        alt="ClockIcon"
                      />
                      {`${props.item.after
                          ? formatAMPM(props.item.afterSeconds)
                          : ""
                        }-${props.item.before
                          ? formatAMPM(props.item.beforeSeconds)
                          : ""
                        }`}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ContextMenuTrigger>
        <ContextMenuContainer
          string={
            props.item.taskCategory === "assigned"
              ? assignedMissionTaskString
              : props.item.taskCategory === "completed"
                ? completedMissionTaskString
                : unAssignedMissionTaskString
          }
          id={props.item.id}
        />
      </div>
    );
  };

  const NonDraggableItem = (props) => {
    return (
      <div
        style={{ marginBottom: "10px" }}
        // onClick={(e) => missionTaskSingleClickHandler(e, t, i)}
        className={`start-end-location-ck`}
        onClick={(e) => {
          e.stopPropagation();
          missionTaskSingleNonDraggableClickHandler(e, props.item, 0);
        }}
      >
        <div className="start-end-field-box">
          <div className="start-end-detail">
            <div className="start-end-title">
              <span className="icon">
                <img
                  style={{ width: "7px" }}
                  src={props.item.icon}
                  alt="icon"
                />
              </span>
              <span className="title">
                {props.item.title} -{" "}
                {props.item.hubName ? props.item.hubName : ""}
              </span>
            </div>
            {/* <Button className="install-btn">Install</Button> */}
          </div>
          {/* <div className="start-end-description">
            <p>
              {props.item && props.item.address
                ? props.item.address
                : `124 Verdun St, Montreal, H4G2Y5, Qc, Canada`}
            </p>
          </div> */}
        </div>
      </div>
    );
  };

  const printManifestHandler = async () => {
    if ((props.features && props.features.exportMissionPdf) || superAdminRole) {
      // props.setPrintToggle(true)
      let data = {
        missionId: props.currentMission,
      };
      let response = await taskService.createMissionPDF(data);
      if (response.data) {
        fileDownload(response.data, "Mission Manifest.pdf");
        // props.setPDFData(response.data)
      } else {
        return errorAlert("Something went wrong!!!");
      }
    } else {
      history.push({
        pathname: "/Business/exportMissionPdf",
      });
    }
  };

  const _onListChange = async (newList) => {
    let found = false,
      missionId;
    let taskIds = newList.map((t) => t.id);
    // if (assignedMissionList && assignedMissionList.length) {
    //   let missions = assignedMissionList.map(m => {
    //     if (m.id === newList[0].missionId) {
    //       m.tasks = [...newList]
    //       found = true
    //       missionId = m.id
    //       props.missionClickhandler(m)
    //     }
    //     return m
    //   })
    //   if (found) {
    //     setAssignedMissionList(missions)
    //   }

    // }

    if (props.missionList && props.missionList.length) {
      let missions = props.missionList.map((m) => {
        if (m.id == newList[0].missionId) {
          found = true;
          missionId = m.id;
          m.tasks = [...newList];
          props.missionClickhandler(m, true);
        }
        return m;
      });
      if (found) props.setMissionList(missions);
    }

    let maxMissionDuration = 0;
    let maxMissionCapacity = 0;
    let startFrom,
      endAt,
      missionDetails = {
        totalTime: 0,
        totalDist: 0,
      },
      tasks = {
        locations: [],
      };
    let missionData = null;
    let tasksDistTime = [];
    if (taskIds.length) {
      missionData = props.missionList.find((items) => {
        return items.id === newList[0].missionId;
      });
      if (missionData) {
        if (missionData.startFrom) {
          startFrom = missionData.startFrom;
        }
        if (missionData.endAt) {
          endAt = missionData.endAt;
        }
        for (let task in taskIds) {
          let findTask = missionData.tasks.find((item) => {
            return item.id === taskIds[task];
          });

          if (findTask) {
            maxMissionCapacity += findTask.capacity
              ? Number(findTask.capacity)
              : 0;
            if (findTask.durationTime)
              maxMissionDuration += Number(findTask.durationTime);
            if (!startFrom && Number(task) === 0) {
              startFrom = {
                location: findTask.location,
              };
            } else if (!endAt && Number(task) === Number(taskIds.length) - 1) {
              endAt = {
                location: findTask.location,
              };
            } else {
              tasks.locations.push({
                location: findTask.location,
                id: findTask.id,
              });
            }
          }
        }
      }
      if (startFrom && endAt) {
        let result = await OptimizeMission.directionsServiceHere(
          tasks,
          false,
          [],
          startFrom,
          endAt
        );
        if (result) {
          let myroute = result[0];
          missionDetails.totalDist += Number(myroute.distance);
          missionDetails.totalTime += Number(myroute.time);

          if (missionData.startFrom && missionData.endAt) {
            for (let i = 0; i < myroute.interconnections.length - 1; i++) {
              tasksDistTime.push({
                id: taskIds[i],
                distanceFromPrevTask:
                  myroute.interconnections[i].distance / 1000,
                timeFromPrevTask: Number(myroute.interconnections[i].time) / 60,
              });
            }
          } else {
            for (let i = 0; i < myroute.interconnections.length; i++) {
              tasksDistTime.push({
                id: taskIds[i + 1],
                distanceFromPrevTask:
                  myroute.interconnections[i].distance / 1000,
                timeFromPrevTask: Number(myroute.interconnections[i].time) / 60,
              });
            }
          }
        }
      }
      missionDetails.totalTime += Number(maxMissionDuration * 60);
      if (missionDetails.totalTime) {
        missionDetails.totalTime = missionDetails.totalTime / 3600;
        missionDetails.totalTime = missionDetails.totalTime.toFixed(1);
      }
      if (missionDetails.totalDist) {
        missionDetails.totalDist = missionDetails.totalDist / 1000;
        missionDetails.totalDist = missionDetails.totalDist.toFixed(1);
      }
    }
    let apiData = {
      taskIds,
      missionId,
      tasks: tasksDistTime,
      missionTaskCapacity: maxMissionCapacity,
      maxMissionDuration: maxMissionDuration,
      missionDetails: missionDetails,
    };
    await taskService.saveTaskPosition(apiData);
    let results = await props.getMissionList(true, true);
    if (results && results.length) {
      let mission = results.find((i) => i.id === missionId);
      if (mission) {
        props.getDirections(mission);
      }
    }
    // setList(newList);
  };

  const duplicateTaskHandler = () => {
    props.setButtonText("Create Task");
    let currentTask = "";

    if (props.selectedTasks && props.selectedTasks.length) {
      if (props.unassignedTaskList && props.unassignedTaskList.length) {
        currentTask = props.unassignedTaskList.find(
          (t) => t.id == props.selectedTasks[0]
        );
      }
      if (!currentTask) {
        if (props.missionList && props.missionList.length) {
          for (let i = 0; i < props.missionList.length; i++) {
            if (
              props.missionList[i].tasks &&
              props.missionList[i].tasks.length
            ) {
              currentTask = props.missionList[i].tasks.find((t) => {
                return t.id === props.selectedTasks[0];
              });

              if (currentTask) {
                break;
              }
            }
          }
          // missionList.forEach(m=>{
          //   if(m.tasks && m.tasks)
          // })
        }
      }
    }
    if (currentTask) {
      props.setcreateTask(false);
      if (currentTask.location) {
        props.setLocation(currentTask.location);
      }
      if (currentTask.address) {
        props.setAddress(currentTask.address);
      }
      if (currentTask && currentTask.docs && currentTask.docs.length) {
        props.editDocs(currentTask.docs);
      } else {
        props.setSelectedDocs([]);
        // props.getLibraryList()
      }
      let form = {
        orderType: currentTask.orderType,
        merchantId: currentTask.merchantId
          ? currentTask.merchantId.id
          : userData.id,
        userId: currentTask.userId ? currentTask.userId.id : userData.id,
        createdFor: currentTask.createdFor
          ? currentTask.createdFor.id
          : userData.id,
        customerId: currentTask.customerId.id,
        notes: currentTask.notes,
        orderDate: props.currentDate,
        after: currentTask.after,
        before: currentTask.before,
        beforeTimePicker: currentTask.beforeTimePicker || null,
        afterTimePicker: currentTask.afterTimePicker || null,
        scheduledTime: currentTask.scheduledTime,
        scheduledTimePicker: currentTask.scheduledTimePicker || null,
        durationTime: currentTask.durationTime,
        capacity: currentTask.capacity,
        firstName:
          currentTask.customer && currentTask.customer.firstName
            ? currentTask.customer.firstName
            : currentTask.customerId.firstName,
        lastName:
          currentTask.customer && currentTask.customer.lastName
            ? currentTask.customer.lastName
            : currentTask.customerId.lastName,
        phoneNumber: currentTask.customerId.phoneNumber,
        countryCode: currentTask.customerId.countryCode || "",
        email:
          currentTask.customer && currentTask.customer.email
            ? currentTask.customer.email
            : currentTask.customerId.email,
        address: currentTask.address ? currentTask.address : "",
        apartment: currentTask.apartment,
        afterOpen: false,
        beforeOpen: false,
        scheduleTimeOpen: false,
        values: currentTask.teamIds,
        teamIds: currentTask.teamIds.map((i) => i.id),
      };
      if (form.userId !== userData.id) {
        form.values = [];
      }
      props.setFormData(form);
    }
  };
  const handleContextClick = (event, id, div_id) => {
    event.persist();
    event.preventDefault();
    const ele = document.getElementById(div_id);
    const positionInfo = ele.getBoundingClientRect();
    const x = positionInfo.right - positionInfo.left;
    showMenu({
      position: { x: x, y: event.pageY },
      target: event,
      id: id,
    });
  };

  const distance = (totalDist) => {
    let value = totalDist;
    let distanceMetric = "KM";
    if (
      regionalSettingData &&
      regionalSettingData.distanceUnit &&
      regionalSettingData.distanceUnit === "m"
    ) {
      value = KmToMiles(value);
      distanceMetric = "Miles";
    }
    return `${value.toFixed(2)} ${distanceMetric}`;
  };
  const MissionAccordian = ({ data, category, string }) => {
    return (
      <>
        {/* <ContextMenuTrigger key={data.id} id={data.id} holdToDisplay={-1}> */}
        <div
          id={"missions"}
          onDragOver={dragOver}
          onDrop={(e) => {
            if (category !== "completed") onDrop(e, data);
          }}
          onClick={(e) => {
            props.setSelectedMission(data);
            props.missionClickhandler(data);
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            props.setSelectedMission(data);
            props.missionClickhandler(data, true);
            setTimeout(() => {
              handleContextClick(e, data.id, "missions");
            }, 300);
          }}
          className={
            props.toggleState[data.id]
              ? "as-mission-fieldbox showMissionField active "
              : "as-mission-fieldbox HideMissionField"
          }
        >
          <div className={"assigned-bg  completed-bg " + data.missionStatus}>
            <div
              className="assigned-field-box-list"
              onClick={() => {
                let obj = {};
                obj[data.id] = !props.toggleState[data.id];
                props.setToggleState({ ...props.toggleState, ...obj });
              }}
            >
              <div className="assigned-detail">
                {/* <span className="title">
                {data.name ? data.name : "mission "}
              </span> */}
                <div className="title">
                  <input
                    key={data.name}
                    id={data.id}
                    className="mission-labels"
                    defaultValue={data.name ? data.name : "mission"}
                    // value={inputValue}
                    onChange={(e) => {
                      // setMissionData({ id: data.id })
                      // setInputValue(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        props.updateMission(
                          { id: data.id, name: e.target.value },
                          "UPDATE"
                        );
                      }
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    placeholder="+ Add Label"
                    type={"text"}
                  />
                </div>
                {category &&
                  (category === "assigned" || category === "completed") && (
                    <div className="astm-title">
                      <div className="astm-box">
                        <img src={profileicon} alt="icon" />
                        <span>
                          {data && data.agentId ? data.agentId.firstName : ""}{" "}
                          {data && data.agentId ? data.agentId.lastName : ""}
                        </span>
                      </div>
                      <div className="subtitle">
                        {data && data.teamId ? data.teamId.teamName : ""}
                      </div>
                    </div>
                  )}
                {data.recurring && (
                  <div className="recurring-icon">
                    <img src={recurringIcon} alt="recurring" />
                  </div>
                )}
              </div>
              <div className="assign-task-list">
                <span>
                  {data.tasks && data.tasks.length ? data.tasks.length : 0}{" "}
                  Tasks
                </span>
                <span>
                  {
                    /*data.optimised && */ data.missionDetails &&
                      data.missionDetails.totalTime
                      ? `${data.missionDetails.totalTime} Hours`
                      : "__ Hours"
                  }
                </span>
                <span>
                  {data.missionTaskCapacity
                    ? `${data.missionTaskCapacity} ${data.missionTaskCapacityUnit}`
                    : "__ Cap"}
                </span>
                <span>
                  {
                    /*data.optimised && */ data.missionDetails &&
                      (data.missionDetails.totalDist ||
                        data.missionDetails.totalDist === 0)
                      ? `${distance(data.missionDetails.totalDist)}`
                      : "__ Km"
                  }
                </span>
              </div>
            </div>
          </div>

          {/* mission under filed list start */}

          <div className="as-mission-under-field">
            <div className="unassigned-field-list" ref={containerRef}>
              {data.optimised && data.startFrom && data.startFrom.location && (
                <NonDraggableItem
                  item={{
                    title: "START",
                    ...data.startFrom,
                    orderType: "SL",
                    ...data,
                    icon: lefticon,
                  }}
                />
              )}
              {!data.optimised && data.startLocation && data.address && (
                <NonDraggableItem
                  item={{
                    title: "START",
                    ...data.startLocation,
                    address: data.address,
                    orderType: "SL",
                    ...data,
                    icon: lefticon,
                  }}
                />
              )}
              <DraggableList
                itemKey="id"
                category={category}
                template={Item}
                commonProps={props}
                list={data.tasks}
                onMoveEnd={(newList) => _onListChange(newList)}
                container={() => containerRef.current}
              />
              {data.optimised && data.startFrom && data.endAt.location && (
                <NonDraggableItem
                  item={{
                    title: "END",
                    ...data.endAt,
                    orderType: "EL",
                    ...data,
                    icon: righticon,
                  }}
                />
              )}
            </div>
          </div>

          {/* mission under filed list end */}
        </div>
        {/* </ContextMenuTrigger> */}
        <ContextMenuContainer
          string={string}
          id={data.id}
          createdBy={data.createdBy}
          userId={data.userId}
        />
      </>
    );
  };

  const changeStatusHandler = () => {
    let currentTask = "";

    if (props.selectedTasks && props.selectedTasks.length) {
      if (props.unassignedTaskList && props.unassignedTaskList.length) {
        currentTask = props.unassignedTaskList.find(
          (t) => t.id == props.selectedTasks[0]
        );
      }
      if (!currentTask) {
        if (props.missionList && props.missionList.length) {
          for (let i = 0; i < props.missionList.length; i++) {
            if (
              props.missionList[i].tasks &&
              props.missionList[i].tasks.length
            ) {
              currentTask = props.missionList[i].tasks.find((t) => {
                return t.id === props.selectedTasks[0];
              });

              if (currentTask) {
                break;
              }
            }
          }
          // missionList.forEach(m=>{
          //   if(m.tasks && m.tasks)
          // })
        }
      }
    }
    if (currentTask) {
      if (currentTask.location) {
        props.setLocation(currentTask.location);
      }
      if (currentTask.address) {
        props.setAddress(currentTask.address);
      }
      props.setFormData({
        id: currentTask.id,
        customerId: currentTask.customerId.id,
        firstName:
          currentTask.customer && currentTask.customer.firstName
            ? currentTask.customer.firstName
            : currentTask.customerId.firstName,
        lastName:
          currentTask.customer && currentTask.customer.lastName
            ? currentTask.customer.lastName
            : currentTask.customerId.lastName,
        phoneNumber: currentTask.customerId.phoneNumber,
        countryCode: currentTask.customerId.countryCode || "",
        email:
          currentTask.customer && currentTask.customer.email
            ? currentTask.customer.email
            : currentTask.customerId.email,
        address: currentTask.address ? currentTask.address : "",
        apartment: currentTask.apartment,
        afterOpen: false,
        beforeOpen: false,
        scheduleTimeOpen: false,
        // taskStatus: currentTask.taskStatus,
        orderDate: currentTask.orderDate,
      });
    }
    setChangeStatus(true);
    createTaskToggle();
  };

  const updateMissionDateHandler = () => {
    let currentMissionData = props.missionList.find((item) => {
      return item.id === props.currentMission;
    });
    props.setMissionData({
      id: props.selectedMissions[0],
      userId: currentMissionData.userId.id,
      missionDate: currentMissionData.missionDate,
      name: currentMissionData.name,
      type: "UPDATE",
    });
    props.setUpdateMissionToggle(true);
  };
  const duplicateMissionHandler = () => {
    let currentMissionData = props.missionList.find((item) => {
      return item.id === props.currentMission;
    });
    props.setMissionData({
      id: props.selectedMissions[0],
      userId: currentMissionData.userId.id,
      missionDate: currentMissionData.missionDate,
      name: currentMissionData.name,
      type: "DUPLICATE",
      repeat: false,
      endsOn: "NEVER",
      repeatEvery: 1,
      endAfterOccurence: 1,
      repeatOnDays: {
        id: "DAY",
        value: momentTz().tz(timezone.timezone).date(),
        name: `Monthly on day ${momentTz().tz(timezone.timezone).date()}`,
      },
      repeatOnDay: [],
      repeatType: { id: "DAY", name: "Day" },
      selectedRepeatValue: { id: "notRepeat", name: "Does not repeat" },
    });
    props.setUpdateMissionToggle(true);
  };

  const ContextMenuContainer = ({ string, id, createdBy, userId }) => {
    let allContextMenuAccess = {
      createTask: false,
      createMission: false,
      editTask: false,
      deleteTask: false,
      duplicateTask: false,
      deleteMission: false,
      optimizeMission: false,
      assignMission: false,
      changeStatus: false,
      unAssignMission: false,
      printManifest: false,
      removeSelectedTask: false,
      linkTasks: false,
      changeMissionDate: false,
      duplicateMission: false,
    };
    if (string && userData) {
      if (superAdminRole) {
        if (
          string === unAssignedTaskString ||
          string === assignedMissionTaskString ||
          string === unAssignedMissionTaskString
        ) {
          allContextMenuAccess.editTask = true;
          allContextMenuAccess.deleteTask = true;
          allContextMenuAccess.duplicateTask = true;
          if (string === unAssignedTaskString) {
            allContextMenuAccess.createMission = true;
          }
          if (
            string === assignedMissionTaskString ||
            string === unAssignedMissionTaskString
          ) {
            allContextMenuAccess.changeStatus = true;
            allContextMenuAccess.removeSelectedTask = true;
            allContextMenuAccess.linkTasks = true;
          }
        }
        if (string === completedMissionTaskString) {
          allContextMenuAccess.duplicateTask = true;
        }
        if (
          string === assignedMissionString ||
          string === unAssignedMissionString
        ) {
          allContextMenuAccess.assignMission = true;
          allContextMenuAccess.unAssignMission = true;
          allContextMenuAccess.optimizeMission = true;
          allContextMenuAccess.deleteMission = true;
          allContextMenuAccess.printManifest = true;
          allContextMenuAccess.changeMissionDate = true;
          allContextMenuAccess.duplicateMission = true;
        }
        if (string === completedMissionString) {
          allContextMenuAccess.printManifest = true;
        }
      }
      if (
        string === unAssignedTaskString ||
        string === assignedMissionTaskString ||
        string === unAssignedMissionTaskString
      ) {
        if (
          (userData.role === 2 && !userData.userId) ||
          (userData.role === 2 &&
            (userData.userRole === "manager" ||
              userData.userRole === "admin")) ||
          (userData.role === 4 && userData.userRole === "crud")
        ) {
          allContextMenuAccess.editTask = true;
          allContextMenuAccess.deleteTask = true;
          allContextMenuAccess.duplicateTask = true;
          if (string === unAssignedTaskString) {
            allContextMenuAccess.createMission = true;
          }
          if (
            string === assignedMissionTaskString ||
            string === unAssignedMissionTaskString
          ) {
            allContextMenuAccess.changeStatus = true;
            allContextMenuAccess.removeSelectedTask = true;
            allContextMenuAccess.linkTasks = true;
          }
        }
      }

      if (string === completedMissionTaskString) {
        allContextMenuAccess.duplicateTask = true;
      }
      if (
        string === assignedMissionString ||
        string === unAssignedMissionString ||
        string === completedMissionString
      ) {
        if (
          string === assignedMissionString ||
          string === unAssignedMissionString
        ) {
          if (
            (userData.role === 2 && !userData.userId) ||
            (userData.role === 2 &&
              (userData.userRole === "manager" ||
                userData.userRole === "admin")) ||
            (userData.role === 4 && userData.userRole === "crud")
          ) {
            if (
              createdBy === userData.id ||
              createdBy === userData.userId ||
              userId.id === userData.id ||
              userId.id === userData.userId
            ) {
              allContextMenuAccess.assignMission = true;
              allContextMenuAccess.unAssignMission = true;
              allContextMenuAccess.optimizeMission = true;
              allContextMenuAccess.deleteMission = true;
              allContextMenuAccess.changeMissionDate = true;
              allContextMenuAccess.duplicateMission = true;
            }
          }
        }
        allContextMenuAccess.printManifest = true;
      }
    }
    return (
      <ContextMenu id={id}>
        {allContextMenuAccess.editTask && (
          <MenuItem onClick={props.editMenuHandler}>Edit</MenuItem>
        )}
        {allContextMenuAccess.changeStatus && (
          <MenuItem
            onClick={() => {
              changeStatusHandler();
            }}
          >
            Change status
          </MenuItem>
        )}
        {allContextMenuAccess.removeSelectedTask && (
          <MenuItem
            onClick={() => {
              props.removeTaskHandler();
            }}
          >
            Remove Task
          </MenuItem>
        )}
        <MenuItem divider />
        {allContextMenuAccess.assignMission && (
          <MenuItem onClick={assignmenuClickHandler}>Assign/Reassign</MenuItem>
        )}
        {allContextMenuAccess.unAssignMission && (
          <MenuItem onClick={unassignmenuClickHandler}>Unassign</MenuItem>
        )}
        {allContextMenuAccess.optimizeMission && (
          <MenuItem onClick={optimiseMenuClickHandler}>Optimise</MenuItem>
        )}
        {allContextMenuAccess.deleteMission && (
          <MenuItem onClick={props.deleteMissionClickHandler}>
            Delete Mission
          </MenuItem>
        )}
        {allContextMenuAccess.deleteTask && (
          <MenuItem onClick={props.deleteMenuHandler}>Delete</MenuItem>
        )}
        {allContextMenuAccess.createMission && (
          <MenuItem onClick={createMissionApiHandler}>Create Mission</MenuItem>
        )}
        {allContextMenuAccess.linkTasks && (
          <MenuItem onClick={linkClickHandler}>Link Selected Tasks</MenuItem>
        )}
        {allContextMenuAccess.printManifest && (
          <MenuItem onClick={printManifestHandler}>Print Manifest</MenuItem>
        )}
        {allContextMenuAccess.duplicateTask && (
          <MenuItem onClick={duplicateTaskHandler}>Duplicate</MenuItem>
        )}
        {allContextMenuAccess.changeMissionDate && (
          <MenuItem onClick={updateMissionDateHandler}>
            Update Mission Date
          </MenuItem>
        )}
        {allContextMenuAccess.duplicateMission && (
          <MenuItem onClick={duplicateMissionHandler}>
            Duplicate Mission
          </MenuItem>
        )}
      </ContextMenu>
    );
  };

  const navigateBuilder = () => {
    history.push({
      // pathname: userData && userData.role === 1 ? "/ManageBusiness" : "Business",
      pathname: "Business",
    });
  };

  const searchItemClick = (type) => {
    if (type === "customer") {
      history.push({
        pathname: "/Customer",
        state: { searchText },
      });
    }
    if (type === "merchant") {
      history.push({
        pathname: "/Merchant",
        state: { searchText },
      });
    }
    if (type === "team") {
      history.push({
        pathname: "/Team",
        state: { searchText },
      });
    }
    //  return(
    //     <Redirect
    //     to={{
    //     pathname: "/Customer",
    //     state: { text: searchText }
    //   }}
    // />
    //   )
  };

  const dragOver = async (ev) => {
    ev.preventDefault();
    // ev.dataTransfer.dropEffect = props.dropEffect;
  };

  const onDrop = async (ev, data) => {
    let allTasks = JSON.parse(JSON.stringify(data.tasks));
    let missionTaskIds = data.tasks.map((item) => item.id);
    ev.preventDefault();
    let draggedTask = ev.dataTransfer.getData("drag-item");
    let apiData = {};
    let maxMissionDuration = 0;
    let maxMissionCapacity = 0;
    let startFrom,
      endAt,
      missionDetails = {
        totalTime: 0,
        totalDist: 0,
      },
      tasks = {
        locations: [],
      };
    let taskIds = [];
    if (props.selectedTasks.length && props.selectedTasks.length > 1) {
      for (let task in props.selectedTasks) {
        let findTasks = await props.unassignedTaskList.find(
          (t) => t.id === props.selectedTasks[task]
        );
        if (findTasks) {
          allTasks.push(findTasks);
        }
      }
      taskIds = [...missionTaskIds, ...props.selectedTasks];
      apiData = {
        missionId: data.id,
        taskIds: taskIds,
        taskStatus: data.missionStatus,
        newTasks: props.selectedTasks,
      };
    } else if (draggedTask) {
      let findTasks = await props.unassignedTaskList.find(
        (t) => t.id === draggedTask
      );
      if (findTasks) {
        allTasks.push(findTasks);
      }
      taskIds = [...missionTaskIds, draggedTask];
      apiData = {
        missionId: data.id,
        taskIds: taskIds,
        taskStatus: data.missionStatus,
        newTasks: [draggedTask],
      };
    }
    if (apiData) {
      if (allTasks.length) {
        if (data.startFrom) {
          startFrom = data.startFrom;
        }
        if (data.endAt) {
          endAt = data.endAt;
        }
        for (let task in allTasks) {
          maxMissionCapacity += allTasks[task].capacity
            ? Number(allTasks[task].capacity)
            : 0;
          if (allTasks[task].durationTime)
            maxMissionDuration += Number(allTasks[task].durationTime);
          if (Number(task) === 0 && !data.startFrom) {
            startFrom = {
              location: allTasks[task].location,
            };
          } else if (Number(task) === allTasks.length - 1 && !data.endAt) {
            endAt = {
              location: allTasks[task].location,
            };
          } else {
            tasks.locations.push({
              location: allTasks[task].location,
              id: allTasks[task].id,
            });
          }
        }

        let tasksDistTime = [];
        if (startFrom && endAt) {
          let result = await OptimizeMission.directionsServiceHere(
            tasks,
            false,
            [],
            startFrom,
            endAt
          );
          if (result) {
            let myroute = result[0];
            console.log("myroute", myroute);

            missionDetails.totalDist += Number(myroute.distance);
            missionDetails.totalTime += Number(myroute.time);

            if (data.startFrom && data.endAt) {
              for (let i = 0; i < myroute.interconnections.length - 1; i++) {
                tasksDistTime.push({
                  id: taskIds[i],
                  distanceFromPrevTask:
                    myroute.interconnections[i].distance / 1000,
                  timeFromPrevTask:
                    Number(myroute.interconnections[i].time) / 60,
                });
              }
            } else {
              for (let i = 0; i < myroute.interconnections.length; i++) {
                tasksDistTime.push({
                  id: taskIds[i + 1],
                  distanceFromPrevTask:
                    myroute.interconnections[i].distance / 1000,
                  timeFromPrevTask:
                    Number(myroute.interconnections[i].time) / 60,
                });
              }
            }

            missionDetails.totalTime += maxMissionDuration * 60;
            if (missionDetails.totalTime) {
              missionDetails.totalTime = missionDetails.totalTime / 3600;
              missionDetails.totalTime = missionDetails.totalTime.toFixed(1);
            }
            if (missionDetails.totalDist) {
              missionDetails.totalDist = missionDetails.totalDist / 1000;
              missionDetails.totalDist = missionDetails.totalDist.toFixed(1);
            }
          }
          apiData.tasks = tasksDistTime;
        }
      }
      apiData.missionDetails = missionDetails;
      apiData.missionTaskCapacity = maxMissionCapacity;
      let response = await taskService.addTaskToMission(apiData);
      if (response) {
        props.getMissionList(true, true);
        props.getUnassignedTaskList();
        props.getTaskList();
      }
    }
  };

  function dragStart(e, task) {
    e.dataTransfer.setData("drag-item", task.id);
  }

  const autoCompleteProps = {
    style: {
      background: "#272B30",
      borderRadius: "12px",
      height: "27px",
      border: "none",
      color: "#6F767E",
      fontSize: "12px",
      width: "200px",
      padding: "0.375rem 0.75rem",
    },
    setLocation: props.setLocation,
    address: props.address,
    setAddress: props.setAddress,
  };

  const UnassignedCategory = () => {
    return (
      <>
        <div className="Unassigned-field ">
          <div
            className={
              unassignTask
                ? "unassigned-field-box  "
                : "unassigned-field-box unassignTask "
            }
            onClick={unassignTaskToggle}
          >
            <span className="title">Unassigned</span>
            <span className="downicon">
              <img src={upicon} alt="icon" />
            </span>
          </div>

          <div className="unassigned-field-list">
            <UnAssignedTaskList key={"unassignedTaskList"} />
            {/* { console.log("ml list ::::", missionList)} */}

            {props.missionList &&
              props.missionList.length > 0 &&
              props.missionList
                .filter((ml) => ml.missionCategory === "unassigned")
                .map((ml) => {
                  return (
                    <MissionAccordian
                      key={ml.id ? ml.id : ""}
                      data={ml}
                      category={"unassigned"}
                      string={unAssignedMissionString}
                    />
                  );
                })}
          </div>
        </div>
      </>
    );
  };

  const UnAssignedTaskList = () => {
    return (
      <>
        {props.unassignedTaskList &&
          props.unassignedTaskList.length > 0 &&
          props.unassignedTaskList.map((task, i) => {
            return (
              <>
                {/* <ContextMenuTrigger id={task.id} holdToDisplay={-1} key={task.id}> */}
                <div
                  id={"unassigned_tasks"}
                  draggable
                  onDragStart={(e) => {
                    dragStart(e, task);
                  }}
                  key={task.id}
                  className={`uns-field-ck ${task.active && task.active == true ? "active" : ""
                    }`}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    props.taskSingleClickHandler(e, task, i);
                    setTimeout(() => {
                      handleContextClick(e, task.id, "unassigned_tasks");
                    }, 200);
                  }}
                  onClick={(e) => props.taskSingleClickHandler(e, task, i)}
                >
                  <div className="unassign-field-box completed-bg unassigned">
                    <div className="unassign-detail">
                      <div className="unassign-title">
                        <span className="unls">
                          {task.orderType ? task.orderType : "P"}
                        </span>
                        <span className="title">
                          {i + 1}. {renderName(task)}
                        </span>
                      </div>
                      {/* <div className="unassign-link">
                            <img src={linkicon} alt="icon" />
                          </div> */}
                      {/* <Button className="install-btn">Install</Button> */}
                    </div>
                    <div className="unassign-description">
                      <p>
                        {" "}
                        {task && task.address
                          ? task.address
                          : `124 Verdun St, Montreal, H4G2Y5, Qc, Canada`}
                      </p>
                      {/* <p>124 Verdun St, Montreal, H4G2Y5, Qc, Canada</p> */}
                    </div>
                  </div>
                </div>
                {/* </ContextMenuTrigger> */}
                <ContextMenuContainer
                  string={unAssignedTaskString}
                  id={task.id}
                />
              </>
            );
          })}
      </>
    );
  };

  const AssignedCategory = () => {
    return (
      <>
        <div className="assigned-field-wrap">
          <div
            className={
              assignTask
                ? "assigned-field-box "
                : "assigned-field-box assignTask"
            }
            onClick={assignTaskToggle}
          >
            <span className="title">Assigned</span>
            <span className="downicon">
              <img src={upicon} alt="icon" />
            </span>
          </div>

          <div className="assigned-field-list">
            {/* { console.log("ml list ::::", missionList)} */}

            {props.missionList &&
              props.missionList.length > 0 &&
              props.missionList
                .filter((ml) => ml.missionCategory === "assigned")
                .map((ml) => {
                  return (
                    <MissionAccordian
                      key={ml.id ? ml.id : ""}
                      data={ml}
                      category={"assigned"}
                      string={assignedMissionString}
                    />
                  );
                })}
          </div>
        </div>
      </>
    );
  };

  const CompletedCategory = () => {
    return (
      <>
        <div className="assigned-field-wrap">
          <div
            className={
              completedTask
                ? "completed-field-box completedTask "
                : "completed-field-box"
            }
            onClick={completedTaskToggle}
          >
            <span className="title">Completed</span>
            <span className="downicon">
              <img src={upicon} alt="icon" />
            </span>
          </div>

          <div className="assigned-field-list">
            {/* { console.log("ml list ::::", missionList)} */}

            {props.missionList &&
              props.missionList.length > 0 &&
              props.missionList
                .filter((ml) => ml.missionCategory === "completed")
                .map((ml) => {
                  return (
                    <MissionAccordian
                      key={ml.id ? ml.id : ""}
                      data={ml}
                      category={"completed"}
                      string={completedMissionString}
                    />
                  );
                })}
          </div>
        </div>
      </>
    );
  };

  const searchTaskClickHandler = (e, task, i) => {
    if (e.detail === 2) {
      props.setTaskDetail(task);
      let selectedCenter = {
        ...task.location,
        address: task.address,
        from: "task",
        id: task.id,
      };
      props.setSelectedCenter(selectedCenter);
      setSearchText("");
    }
  };

  const SearchComponent = () => {
    return (
      <div className="searchbar-box-field">
        {searchData.tasks && searchData.tasks.length > 0 && (
          <div className="task-search">
            <h2>Task</h2>
            <div className="ts-box">
              <div className="unassigned-field-list">
                {searchData.tasks.map((task, i) => {
                  return (
                    <div
                      className="unassign-field-box"
                      onClick={(e) => {
                        searchTaskClickHandler(e, task, i);
                      }}
                    >
                      <div className="unassign-detail">
                        <div className="unassign-title">
                          <span className="unls">
                            {task.orderType ? task.orderType : "P"}
                          </span>
                          <span className="title">
                            {i + 1}. {renderName(task)}
                          </span>
                        </div>
                        <div className="unassign-link">
                          <img src={linkicon} alt="icon" />
                        </div>
                        <Button className="install-btn">Install</Button>
                      </div>
                      <div className="unassign-description">
                        <p>
                          {" "}
                          {task && task.address
                            ? task.address
                            : `124 Verdun St, Montreal, H4G2Y5, Qc, Canada`}
                        </p>
                        {/* <p>124 Verdun St, Montreal, H4G2Y5, Qc, Canada</p> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {searchData.customers && searchData.customers.length > 0 && (
          <div className="customer-search">
            <h2>Customers</h2>
            <div className="task-cs">
              {searchData.customers.map((u, i) => {
                return (
                  <div
                    key={u.id}
                    className="task-cs-box"
                    onClick={() => {
                      searchItemClick("customer");
                    }}
                  >
                    <div className="task-cs-icon">
                      <img src={profilecirclicon} alt="icon" />
                    </div>
                    <div className="task-cs-data">
                      <h2>
                        {u.firstName ? u.firstName : ""}{" "}
                        {u.lastName ? u.lastName : ""}
                      </h2>
                      <h3>{u.address ? u.address : ""}</h3>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {searchData.agent && searchData.agent.length > 0 && (
          <div className="customer-search">
            <h2>Agent</h2>
            <div className="task-cs">
              {searchData.agent.map((u, i) => {
                return (
                  <div
                    key={u.id}
                    className="task-cs-box"
                    onClick={() => {
                      searchItemClick("team");
                    }}
                  >
                    <div className="task-cs-icon">
                      <img src={profilecirclicon} alt="icon" />
                    </div>
                    <div className="task-cs-data">
                      <h2>
                        {u.firstName ? u.firstName : ""}{" "}
                        {u.lastName ? u.lastName : ""}
                      </h2>
                      <h3>{u.address ? u.address : ""}</h3>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {searchData.merchant && searchData.merchant.length > 0 && (
          <div className="customer-search">
            <h2>Merchant</h2>
            <div className="task-cs">
              {searchData.merchant.map((u, i) => {
                return (
                  <div
                    key={u.id}
                    className="task-cs-box"
                    onClick={() => {
                      searchItemClick("merchant");
                    }}
                  >
                    <div className="task-cs-icon">
                      <img src={profilecirclicon} alt="icon" />
                    </div>
                    <div className="task-cs-data">
                      <h2>
                        {u.firstName ? u.firstName : ""}{" "}
                        {u.lastName ? u.lastName : ""}
                      </h2>
                      <h3>{u.address ? u.address : ""}</h3>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  const missionTaskSingleNonDraggableClickHandler = (e, mission, i) => {
    let selectedCenter = {
      ...mission.location,
      address: mission.address,
      from: "task",
      id: mission.orderType === "SL" ? mission.id : mission.id + 1,
    };
    props.setSelectedCenter(selectedCenter);
  };

  const assignmenuClickHandler = () => {
    if (props.selectedMissions && props.selectedMissions.length) {
      props.setAssignToggle(true);
      props.setSelectedMissions(props.selectedMissions);
    }
  };

  const unassignmenuClickHandler = async () => {
    if (props.selectedMissions && props.selectedMissions.length) {
      let data = {
        missionId: props.selectedMissions[0],
      };
      await taskService.unAssignMission(data);
      props.getMissionList();
      props.getTaskList();
    }
  };

  const optimiseMenuClickHandler = () => {
    if (
      (props.features && props.features.routeOptimization) ||
      superAdminRole
    ) {
      if (props.selectedMissions && props.selectedMissions.length) {
        props.setShowOptimiseSection(true);
        // props.setMissionList(props.missionList)
        let selectedMission = props.missionList.find(
          (m) => m.id === props.selectedMissions[0]
        );
        if (selectedMission) {
          let locations = [];
          if (selectedMission.tasks && selectedMission.tasks.length) {
            locations = selectedMission.tasks.map((t) => {
              return { location: t.location, id: t.id };
            });
          }
          props.setOptimiseFormData((prev) => {
            return {
              ...prev,
              missionId: selectedMission.id,
              locations,
              agentId: selectedMission.agentId
                ? selectedMission.agentId.id
                : "",
              teamId: selectedMission.agentId
                ? selectedMission.agentId.teamId
                : "",
              tasks: selectedMission.tasks,
            };
          });
        }
      }
    } else {
      history.push({
        // pathname: userData && userData.role === 1 ? "/ManageBusiness" : "Business",
        pathname: "Business/routeOptimization",
      });
    }
  };

  //Context menu methods starts here
  let taskDetailProps = {
    taskDetail: props.taskDetail,
    setTaskDetail: props.setTaskDetail,
    errorAlert,
    taskService,
    fileDownload,
    features: props.features,
    userData,
    history,
    libraryList: props.libraryList,
  };

  let taskProps = {
    ...props,
    setcreateTask: props.setcreateTask,
    buttonText: props.buttonText,
    setButtonText: props.setButtonText,
    createTask: props.createTask,
    formData: props.formData,
    setFormData: props.setFormData,
    cancelTask,
    features: props.features,
    setImportToggle: props.setImportToggle,
    createTaskToggle,
    usersList,
    countryCode,
    taskSettingData,
    selectAllOption,
    importPopup: props.importPopup,
    getUnassignedTaskList: props.getUnassignedTaskList,
    taskList: props.taskList,
    address: props.address,
    setAddress: props.setAddress,
    location: props.location,
    setLocation: props.setLocation,
    initialValues: props.initialValues,
    getMissionList: props.getMissionList,
  };

  return (
    <>
      {/* right sidebar menu start */}
      <Button onClick={FullScreenToggle} className="tprighticon">
        <img src={sidebartpicon} />
      </Button>
      <div
        className={
          Slideopen
            ? "Rightsidebarmenu Slideopen"
            : "Rightsidebarmenu Slidehide"
        }
      >
        <div className="sidebarmenubar">
          <div className="dash-rightsb-box">
            {/* search bar right sidebar start */}
            <div className="dash-tr-box">
              <div className="searchbar">
                <Form className="d-flex">
                  <FormControl
                    type="search"
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search or type a name"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button className="searchicon">
                    <img src={searchicon} alt="searchicon" />
                  </Button>
                  {/* <span className="searchF">
                    <img src={searchF} alt="icon" />
                  </span> */}
                </Form>
              </div>
              <Button
                onClick={() => {
                  setCalenderToggle(!calenderToggle);
                }}
              >
                <div class="container-calendar">
                  <img src={Clicon} alt="Clicon" style={{ width: "100%" }} />
                  <div class="centered">{props.currentDate.getDate()}</div>
                </div>
              </Button>
              <Button onClick={navigateBuilder}>
                <img src={Iconpuzzle} alt="Iconpuzzle" />
              </Button>
            </div>
            {/* search bar right sidebar end */}
            {/* search bar right sidebar start search task */}
            {calenderToggle && (
              // <CalendarContainer>
              <Calendar
                onChange={(e) => {
                  // setCurrentDate(e);
                  //date chhange in dashboard also
                  props.setCurrentDate(e);
                  localStorage.setItem("currentDate", e);
                  setCalenderToggle(false);
                }}
                value={props.currentDate}
              />
              // </CalendarContainer>
            )}

            {searchText && SearchComponent()}
            {props.taskDetail ? (
              <RightSideTaskDetails {...taskDetailProps} />
            ) : (
              <div>
                {/* search bar right sidebar end search task */}

                {/* search bar right sidebar end Optimize Mission */}
                {props.showOptimiseSection && OptimiseSection()}

                {/* search bar right sidebar end Optimize Mission */}
                {/* createTask */}
                {renderCreateTaskButton()}

                {changeTaskStatus && (
                  <div className="createtask-slide">
                    <Formik
                      enableReinitialize
                      initialValues={props.formData}
                      render={({
                        values,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                      }) => {
                        return (
                          <FormF>
                            <div className="create-task-field">
                              <span>{"Change task status"}</span>
                              <div className="task-import">
                                <div className="task-import-field"></div>
                                <Button
                                  className="createtask-close"
                                  onClick={() => {
                                    createTaskToggle();
                                    cancelTask();
                                  }}
                                >
                                  <img src={closeicon} alt="icon" />
                                </Button>
                              </div>
                            </div>
                            {/* Customer Details */}

                            <div className="create-customer-detail">
                              <h2>Customer Details</h2>
                              <div className="create-customers-form">
                                <Row>
                                  <Col xs={6}>
                                    <Form.Group className="mb-3">
                                      {/* <Form.Control type="text" placeholder="First Name" /> */}
                                      <Field
                                        className="form-control"
                                        placeholder="First Name"
                                        name="firstName"
                                        maxLength="15"
                                        aria-label="First Name"
                                        disabled
                                      />
                                      <ErrorMessage
                                        name="firstName"
                                        component="div"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={6}>
                                    <Form.Group className="mb-3">
                                      <Field
                                        className="form-control"
                                        placeholder="Last Name"
                                        name="lastName"
                                        maxLength="15"
                                        aria-label="Last Name"
                                        disabled
                                      />
                                      <ErrorMessage
                                        name="lastName"
                                        component="div"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={6} style={{ display: "flex" }}>
                                    <Form.Group className="mb-3 od-dselect">
                                      <Field
                                        className="form-control"
                                        aria-label="Phone"
                                        name="countryCode"
                                        as="select"
                                        disabled
                                      >
                                        <option value="+1" selected>
                                          +1
                                        </option>
                                        <option value="+91">+91</option>
                                      </Field>
                                      <ErrorMessage
                                        name="countryCode"
                                        component="div"
                                      />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                      {/* <Form.Control type="Number" placeholder="Phone" /> */}
                                      {/* <div>
                                    <PhoneInput country={values.ISO}
                                      enableSearch={true}
                                      onlyCountries={["ca", "in"]}
                                      value={values.countryCode}
                                      onChange={(countryCode) => {console.log(countryCode); setFieldValue(countryCode)}} />
                                    <Field
                                      className="form-control"
                                      placeholder="Phone"
                                      name="phoneNumber"
                                      aria-label="Phone"
                                    />
                                  </div> */}

                                      <Field
                                        className="form-control"
                                        placeholder="Phone"
                                        name="phoneNumber"
                                        aria-label="Phone"
                                        disabled
                                      />
                                      <ErrorMessage
                                        name="phoneNumber"
                                        component="div"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={6}>
                                    <Form.Group className="mb-3">
                                      {/* <Form.Control type="email" placeholder="Email" /> */}
                                      <Field
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        aria-label="Email"
                                        disabled
                                      />
                                      <ErrorMessage
                                        name="email"
                                        component="div"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={8}>
                                    <Form.Group className="mb-3">
                                      {/* <MyAutoCompleteComponent {...autoCompleteProps} /> */}
                                      <Autocomplete
                                        className="form-control"
                                        value={props.address}
                                        onChange={(e) =>
                                          props.setAddress(e.target.value)
                                        }
                                        placeholder="Address"
                                        options={{
                                          types: ["establishment", "geocode"],
                                          ...(countryCode && {
                                            componentRestrictions: {
                                              country: countryCode,
                                            },
                                          }),
                                        }}
                                        disabled
                                        onPlaceSelected={(place) => {
                                          props.setAddress(
                                            place.formatted_address
                                          );
                                          props.setLocation({
                                            lat: place.geometry.location.lat(),
                                            lng: place.geometry.location.lng(),
                                          });
                                        }}
                                      />

                                      {!props.address && (
                                        <ErrorMessage
                                          name="address"
                                          component="div"
                                        />
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col xs={4}>
                                    <Form.Group className="mb-3">
                                      <Field
                                        className="form-control"
                                        placeholder="Apt, Bld"
                                        name="apartment"
                                        maxLength="15"
                                        aria-label="Apt, Bld"
                                        disabled
                                      />
                                      <ErrorMessage
                                        name="apartment"
                                        component="div"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            {/* Order Details */}
                            <div className="create-order-detail">
                              <h2>Change Status</h2>
                              <div className="create-order-form">
                                <Form.Group className="mb-3 od-dselect">
                                  <Field
                                    className="form-control"
                                    name="taskStatus"
                                    as="select"
                                    // defaultValue={"started"}
                                    defaultValue=""
                                  >
                                    <option value="" selected>
                                      Select status
                                    </option>
                                    <option value="started">Started</option>
                                    <option value="reached">Reached</option>
                                    <option value="completed">Completed</option>
                                    <option value="failed">Failed</option>
                                  </Field>
                                  <ErrorMessage
                                    style={{
                                      fontSize: "10px",
                                      color: "#ea4335",
                                      marginTop: "3px",
                                      marginLeft: "10px",
                                    }}
                                    name="taskStatus"
                                    component="div"
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <div className="ct-btn">
                              <Button
                                className="cncle-btn"
                                onClick={cancelTask}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="submit"
                                style={{ width: "105px" }}
                                className="ctaskbtn"
                              >
                                {"Change status"}
                              </Button>
                            </div>
                          </FormF>
                        );
                      }}
                      validationSchema={validationSchemaChangeStatus}
                      validate={validateCustomTaskStatus}
                      onSubmit={handleSubmitChangeStatus}
                    ></Formik>
                  </div>
                )}
                <CreateTaskComponent {...taskProps} />

                {/* create Unassigned box */}
                {props.features && props.features.mapAndListView && (
                  <UnassignedCategory />
                )}
                {/* Assigned field start */}

                {props.features && props.features.mapAndListView && (
                  <AssignedCategory />
                )}
                {/* Assigned field end */}
                {/* asssgn popup starts */}

                {/* completed field start */}

                {props.features && props.features.mapAndListView && (
                  <CompletedCategory />
                )}
              </div>
            )}
            {/* completed field end */}
          </div>
          <button className="sidebarright-icon" onClick={SlideopenToggle}>
            <span className={isActive ? "flip" : "inflip"} onClick={toggleflip}>
              {" "}
              <FaAngleLeft />
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default React.memo(Rightsidebar);
