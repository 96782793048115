
import UnAssignedTaskList from "../../components/RightSideBarComponents/UnassignedTaskList";
import moment from "moment";

const TaskContainer = (props) => {
    let allDates = props.allDates;
    return (
        <div className="task-container">
            {allDates.map((items) => {
                let newProps = Object.assign({}, props)
                newProps.unassignedTaskList = items.taskList;
                // newProps.unassignedTaskList = newProps.unassignedTaskList.map(i => {
                //     i.markerImage = items.markerImage
                //     i.selectedMarkerImage = items.selectedMarkerImage
                //     return i
                // })
                // newProps.taskClickProps.datedTaskList = newProps.unassignedTaskList
                newProps.taskClickProps.datedTasks = true
                newProps.borderColor = items.color
                newProps.sideView = false;

                return (
                <div className="weather-task-box">
                    {/* <div className="weather-details">
                        <div className="day-of-week">
                            <span>{moment(items.date).format('dddd').substring(0, 3)}</span>
                        </div>
                        <div className="date-of-week">
                            <span>{moment(items.date).format('DD')}</span>
                        </div>
                    </div> */}
                    {/* <div className="task-line" style={{ borderColor: items.color }}></div> */}
                    <div className="task-box"
                        onDragOver={props.dragOver}
                        onDrop={(e) => { props.onDrop(e, {}, `date_${items.date}`) }}
                    >
                        <UnAssignedTaskList {...newProps} />
                    </div>
                </div>)
            })}
        </div>)
}
export default TaskContainer