import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
  NavDropdown,
} from "react-bootstrap";
import "../hub/hub.scss";

import closeicon from "../../assets/image/close-icon.png";
import Union from "../../assets/image/Union.png";
import { Formik, Field, ErrorMessage, Form as FormF } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import Switch from "react-switch";
import { MdEdit } from "react-icons/md";
import moment from "moment";
const Notifications = (props) => {

  const history = useHistory()
  let type = props.settingTypes.CUSTOMER_EXPERIENCE;
  let subType = props.settingSubType.NOTIFICATIONS
  if (props.userData && props.userData.role === 1 && !props.userData.subAdmin) {
    type = props.settingTypes.CUSTOMER_EXPERIENCE
    subType = props.settingSubType.COMMON_NOTIFICATIONS
  }
  let roleBasedDisabled = false;
  if (props.userData && props.userData.userId && !props.userData.subAdmin && props.userData.userRole !== "admin") {
    roleBasedDisabled = true
  }
  const userData = useSelector((state) => state.auth.user)
  const [createEditsms, setEditsms] = useState("false");
  const createEditsmsToggle = () => {
    setEditsms(!createEditsms);
  };
  const [createDelivery, setDelivery] = useState("false");
  const createDeliveryToggle = () => {
    setDelivery(!createDelivery);
  };

  const [focusedTextArea, setFocusedTextArea] = useState("");

  const [editSmsData, setEditSmsData] = useState(null);

  const handleEditSmsButton = (data) => {
    console.log("datatatata", data)
    setEditSmsData(data);
  };

  const onChangeEditSmsHandler = (e, subKey, key, forced) => {
    let smsData = JSON.parse(JSON.stringify(editSmsData));
    if (subKey === "sms" || subKey === "email") {
      if (subKey === "sms") smsData.sms = e;
      if (subKey === "email") smsData.email = e;
      setEditSmsData(smsData);
    } else {
      if (forced) {
        if (subKey === "smsText") smsData.smsText = e;
        if (subKey === "emailText") smsData.emailText = e;
      } else {
        if (subKey === "smsText") smsData.smsText = e.target.value;
        if (subKey === "emailText") smsData.emailText = e.target.value;
      }
      setEditSmsData(smsData);
    }
  };

  const onSubmitEditSmsHandler = (e, key) => {
    let smsData = Object.assign({}, editSmsData);
    delete smsData.key;
    props.saveCommonServiceData(
      { [key]: smsData },
      userData.role === 1 && !userData.subAdmin ? props.commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_NOTIFICATIONS : props.commonServicesTypes.CUSTOMER_EXPERIENCE_NOTIFICATIONS
    );
  };

  const getInputSelection = (el) => {
    var start = 0,
      end = 0,
      normalizedValue,
      range,
      textInputRange,
      len,
      endRange;

    if (
      typeof el.selectionStart == "number" &&
      typeof el.selectionEnd == "number"
    ) {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");

        // Create a working TextRange that lives only in the input
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());

        // Check if the start and end of the selection are at the very end
        // of the input, since moveStart/moveEnd doesn't return what we want
        // in those cases
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end,
    };
  };

  const offsetToRangeCharacterMove = (el, offset) => {
    return offset - (el.value.slice(0, offset).split("\r\n").length - 1);
  };

  const setSelection = (el, start, end) => {
    if (
      typeof el.selectionStart == "number" &&
      typeof el.selectionEnd == "number"
    ) {
      el.selectionStart = start;
      el.selectionEnd = end;
    } else if (typeof el.createTextRange != "undefined") {
      var range = el.createTextRange();
      var startCharMove = offsetToRangeCharacterMove(el, start);
      range.collapse(true);
      if (start == end) {
        range.move("character", startCharMove);
      } else {
        range.moveEnd("character", offsetToRangeCharacterMove(el, end));
        range.moveStart("character", startCharMove);
      }
      range.select();
    }
  };
  const insertTextAtCaret = (el, text) => {
    var pos = getInputSelection(el).end;
    var newPos = pos + text.length;
    var val = el.value;
    el.value = val.slice(0, pos) + text + val.slice(pos);
    setSelection(el, newPos, newPos);
  };

  // document.getElementById("insertButton").onclick = function () {
  //   var textarea = document.getElementById("ta");
  //   textarea.focus();
  //   insertTextAtCaret(textarea, "[INSERTED]");
  //   return false;
  // };

  const handleInsertTextButton = (text) => {
    let textarea = document.getElementById(focusedTextArea);
    textarea.focus();
    insertTextAtCaret(textarea, text);
    // textarea.onchange((e)=>{onChangeEditSmsHandler(e, focusedTextArea==="smsText"?"smsText": "emailText", editSmsData.key)})

    let data = document.getElementById(focusedTextArea).value;
    onChangeEditSmsHandler(
      data,
      focusedTextArea === "smsText" ? "smsText" : "emailText",
      editSmsData.key,
      true
    );
    return false;
  };

  const showText = (text) => {
    let regexRecipientName = /{{recipientName}}/g; // Johny
    text = text.replace(regexRecipientName, 'Johny');
    let regexDriverName = /{{driverName}}/g; // Sebastien Godbout
    text = text.replace(regexDriverName, 'Sebastien Godbout');
    let regexEta = /{{eta}}/g; // 20 mins
    text = text.replace(regexEta, '20 mins');
    let regexVehicleDescription = /{{vehicleDescription}}/g; // Black Color
    text = text.replace(regexVehicleDescription, '20 mins');
    let regexWorkerPhone = /{{workerPhone}}/g; // +12244673
    text = text.replace(regexWorkerPhone, '+12244673');
    let regexDestinationAddressShort = /{{destinationAddressShort}}/g; //
    text = text.replace(regexDestinationAddressShort, '');
    let regexDestinationAddressLong = /{{destinationAddressLong}}/g; //
    text = text.replace(regexDestinationAddressLong, '');
    let regexTrackLink = /{{trackingLink}}/g; // track here
    text = text.replace(regexTrackLink, 'track here');
    let regexCreatorName = /{{creatorName}}/g; // Boutique Furniture Expert.
    text = text.replace(regexCreatorName, 'Boutique Furniture Expert');
    let regexMerchantName = /{{merchantName}}/g; // Boutique Furniture Expert.
    text = text.replace(regexMerchantName, 'Boutique Furniture Expert');
    let regexEarliestTime = /{{earliestTime}}/g; // 6:35PM
    text = text.replace(regexEarliestTime, '6:35PM');
    let regexLatestTime = /{{latestTime}}/g; // 6:35PM
    text = text.replace(regexLatestTime, '6:35PM');
    let regexCompletedTime = /{{completedTime}}/g; //  6:35PM
    text = text.replace(regexCompletedTime, '6:35PM');
    let regexTodayDate = /{{todayDate}}/g; //  6:35PM
    text = text.replace(regexTodayDate, `${moment().format("ll")}`);
    let regexOrganizationName = /{{organizationName}}/g; // Boutique Furniture Expert.
    text = text.replace(regexOrganizationName, `Boutique Furniture Expert`);
    return text;
  };

  const handleFeatureClick = (type) => {
    if (props.userData.role !== 1) {

      console.log(props.userData)
      if ((!props.features || (props.features && !props.features[type])) && !roleBasedDisabled) {
        let pathName = "/Business/"
        if (type) {
          pathName += type
        }
        console.log("pathNamepathName", pathName)
        props.history.push({
          pathname: pathName
        })
      }
    }
  }

  return (
    <>
      {/* <ContentLayout> */}
      <div className="customer-page-wrapper d-flex">
        {/* customer layout start */}
        <div className="customer-form-wrapper">
          <div className="st-oplist  cnnt-field">
            <div className="st-Documents Communications-field">
              <div className="st-opheading">
                <p>Send custom SMS and emails to your customers based on the triggers below</p>
              </div>

              <div className="cmfield">
                <div className="st-opheading">
                  <h1>Delivery</h1>
                </div>
                <div className="st-oplistbox">
                  <Form>
                    <Form.Group as={Row} className="mb-input">
                      <Form.Label column sm={3}>
                        Agent Started Mission
                      </Form.Label>
                      <Col sm={9}>
                        <div className="legalpd-field">
                          {userData && (props.userData.role !== 1 || props.userData.subAdmin) && <div className="toggle-switch" onClick={(e) => { handleFeatureClick("smsNotificationsTriggers") }}>
                            <Switch
                              onChange={(e) =>
                                props.handleChangeSetting(e, {
                                  key: [{ deliveryAgentStartedMission: e }],
                                  type: type,
                                  subType: subType,
                                })
                              }
                              checked={
                                props.settingFormData
                                  .deliveryAgentStartedMission
                              }
                              disabled={((props.features && props.features.smsNotificationsTriggers && !roleBasedDisabled) || props.userData.role === 1) ? false : true}
                            />
                          </div>}
                          <div className="editd" onClick={(e) => { handleFeatureClick("customizeRecipientSms") }}>
                            <Button
                              onClick={(e) => {
                                createEditsmsToggle();
                                handleEditSmsButton({
                                  title: "Agent Started Mission",
                                  ...props.commonServicesFormData
                                    .deliveryAgentStartedMission,
                                  key: "deliveryAgentStartedMission",
                                });
                              }}
                              disabled={((props.features && props.features.customizeRecipientSms && !roleBasedDisabled) || (props.userData.role === 1)) ? false : true}
                            >
                              Edit
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                      <Form.Label column sm={3}>
                        Agent Started Task
                      </Form.Label>
                      <Col sm={9}>
                        <div className="legalpd-field">
                          {userData && (props.userData.role !== 1 || props.userData.subAdmin) && <div className="toggle-switch" onClick={(e) => { handleFeatureClick("smsNotificationsTriggers") }}>
                            <Switch
                              onChange={(e) =>
                                props.handleChangeSetting(e, {
                                  key: [{ deliveryAgentStartedTask: e }],
                                  type: type,
                                  subType: subType,
                                })
                              }
                              checked={
                                props.settingFormData.deliveryAgentStartedTask
                              }
                              disabled={((props.features && props.features.smsNotificationsTriggers && !roleBasedDisabled) || props.userData.role === 1) ? false : true}
                            />
                          </div>}
                          <div className="editd" onClick={(e) => { handleFeatureClick("customizeRecipientSms") }}>
                            <Button
                              onClick={(e) => {
                                createEditsmsToggle();
                                handleEditSmsButton({
                                  title: "Agent Started Task",
                                  ...props.commonServicesFormData
                                    .deliveryAgentStartedTask,
                                  key: "deliveryAgentStartedTask",
                                });
                              }}
                              disabled={((props.features && props.features.customizeRecipientSms && !roleBasedDisabled) || (props.userData.role === 1)) ? false : true}
                            >
                              Edit
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                      <Form.Label column sm={3}>
                        Agent Arrived
                      </Form.Label>
                      <Col sm={9}>
                        <div className="legalpd-field">
                          {userData && (props.userData.role !== 1 || props.userData.subAdmin) && <div className="toggle-switch" onClick={(e) => { handleFeatureClick("smsNotificationsTriggers") }}>
                            <Switch
                              onChange={(e) =>
                                props.handleChangeSetting(e, {
                                  key: [{ deliveryAgentArrived: e }],
                                  type: type,
                                  subType: subType,
                                })
                              }
                              checked={
                                props.settingFormData.deliveryAgentArrived
                              }
                              disabled={((props.features && props.features.smsNotificationsTriggers && !roleBasedDisabled) || props.userData.role === 1) ? false : true}
                            />
                          </div>}
                          <div className="editd" onClick={(e) => { handleFeatureClick("customizeRecipientSms") }}>
                            <Button
                              onClick={(e) => {
                                createEditsmsToggle();
                                handleEditSmsButton({
                                  title: "Agent Arrived",
                                  ...props.commonServicesFormData
                                    .deliveryAgentArrived,
                                  key: "deliveryAgentArrived",
                                });
                              }}
                              disabled={((props.features && props.features.customizeRecipientSms && !roleBasedDisabled) || (props.userData.role === 1)) ? false : true}
                            >
                              Edit
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                      <Form.Label column sm={3}>
                        Successful Task
                      </Form.Label>
                      <Col sm={9}>
                        <div className="legalpd-field">
                          {userData && (props.userData.role !== 1 || props.userData.subAdmin) && <div className="toggle-switch" onClick={(e) => { handleFeatureClick("smsNotificationsTriggers") }}>
                            <Switch
                              onChange={(e) =>
                                props.handleChangeSetting(e, {
                                  key: [{ deliverySuccessFulTask: e }],
                                  type: type,
                                  subType: subType,
                                })
                              }
                              checked={
                                props.settingFormData.deliverySuccessFulTask
                              }
                              disabled={((props.features && props.features.smsNotificationsTriggers && !roleBasedDisabled) || props.userData.role === 1) ? false : true}
                            />
                          </div>}
                          <div className="editd" onClick={(e) => { handleFeatureClick("customizeRecipientSms") }}>
                            <Button
                              onClick={(e) => {
                                createEditsmsToggle();
                                handleEditSmsButton({
                                  title: "Successful Task",
                                  ...props.commonServicesFormData
                                    .deliverySuccessFulTask,
                                  key: "deliverySuccessFulTask",
                                });
                              }}
                              disabled={((props.features && props.features.customizeRecipientSms && !roleBasedDisabled) || (props.userData.role === 1)) ? false : true}
                            >
                              Edit
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                      <Form.Label column sm={3}>
                        Failed Task
                      </Form.Label>
                      <Col sm={9}>
                        <div className="legalpd-field">
                          {userData && (props.userData.role !== 1 || props.userData.subAdmin) && <div className="toggle-switch" onClick={(e) => { handleFeatureClick("smsNotificationsTriggers") }}>
                            <Switch
                              onChange={(e) =>
                                props.handleChangeSetting(e, {
                                  key: [{ deliveryFailedTask: e }],
                                  type: type,
                                  subType: subType,
                                })
                              }
                              checked={props.settingFormData.deliveryFailedTask}
                              disabled={((props.features && props.features.smsNotificationsTriggers && !roleBasedDisabled) || props.userData.role === 1) ? false : true}
                            />
                          </div>}
                          <div className="editd" onClick={(e) => { handleFeatureClick("customizeRecipientSms") }}>
                            <Button
                              onClick={(e) => {
                                createEditsmsToggle();
                                handleEditSmsButton({
                                  title: "Failed Task",
                                  ...props.commonServicesFormData
                                    .deliveryFailedTask,
                                  key: "deliveryFailedTask",
                                });
                              }}
                              disabled={((props.features && props.features.customizeRecipientSms && !roleBasedDisabled) || (props.userData.role === 1)) ? false : true}
                            >
                              Edit
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
              </div>
              {/* <div className="cmfield">
                <div className="st-opheading">
                  <h1>Installation</h1>
                </div>
                <div className="st-oplistbox">
                  <Form>
                    <Form.Group as={Row} className="mb-input">
                      <Form.Label column sm={3}>
                        Agent Started Mission
                      </Form.Label>
                      <Col sm={9}>
                        <div className="legalpd-field">
                          <div className="toggle-switch">
                            <Switch
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  "agentStartedMissionInstallation"
                                )
                              }
                              checked={checked.agentStartedMissionInstallation}
                            />
                          </div>
                          <div className="editd">
                            <Button onClick={createEditsmsToggle}>Edit SMS</Button>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                      <Form.Label column sm={3}>
                        Agent Started Task
                      </Form.Label>
                      <Col sm={9}>
                        <div className="legalpd-field">
                          <div className="toggle-switch">
                            <Switch
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  "agentStartedTaskInstallation"
                                )
                              }
                              checked={checked.agentStartedTaskInstallation}
                            />
                          </div>
                          <div className="editd">
                            <Button onClick={createEditsmsToggle}>Edit SMS</Button>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                      <Form.Label column sm={3}>
                        Agent Arrived
                      </Form.Label>
                      <Col sm={9}>
                        <div className="legalpd-field">
                          <div className="toggle-switch">
                            <Switch
                              onChange={(e) =>
                                handleOnChange(e, "agentArrivedInstallation")
                              }
                              checked={checked.agentArrivedInstallation}
                            />
                          </div>
                          <div className="editd">
                            <Button onClick={createEditsmsToggle}>Edit SMS</Button>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                      <Form.Label column sm={3}>
                        Successful Task
                      </Form.Label>
                      <Col sm={9}>
                        <div className="legalpd-field">
                          <div className="toggle-switch">
                            <Switch
                              onChange={(e) =>
                                handleOnChange(e, "successTaskInstallation")
                              }
                              checked={checked.successTaskInstallation}
                            />
                          </div>
                          <div className="editd">
                            <Button onClick={createEditsmsToggle}>Edit SMS</Button>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                      <Form.Label column sm={3}>
                        Failed Task
                      </Form.Label>
                      <Col sm={9}>
                        <div className="legalpd-field">
                          <div className="toggle-switch">
                            <Switch
                              onChange={(e) =>
                                handleOnChange(e, "failedTaskInstallation")
                              }
                              checked={checked.failedTaskInstallation}
                            />
                          </div>
                          <div className="editd">
                            <Button onClick={createEditsmsToggle}>Edit SMS</Button>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* customer layout end */}

        {/* edit sms popup start */}
        <div
          className={
            createEditsms
              ? "opencustomer-popup"
              : "opencustomer-popup createCustomer"
          }
        >
          <div className="addCustomer-popup">
            <div className="addcustomer-slide map-view-popup">
              <div className="addcustomer-field">
                <div className="customer-import">
                  <Button
                    className="createcustomer-close"
                    onClick={createEditsmsToggle}
                  >
                    <img src={closeicon} alt="icon" />
                  </Button>
                </div>
              </div>

              {editSmsData && (
                <div className="add-mapviewpopup">
                  <div className="map-view-detail">
                    <div className="customize-page">
                      <h1>
                        {editSmsData && editSmsData.title
                          ? editSmsData.title
                          : ""}
                      </h1>

                      <div className="sms-Detail">
                        <div className="editsms-heading">
                          <h1>SMS</h1>
                          <div className="toggle-switch">
                            <Switch
                              onChange={
                                (e) =>
                                  onChangeEditSmsHandler(
                                    e,
                                    "sms",
                                    editSmsData.key
                                  )
                                // props.handleChangeSetting(e, { key: [{ "deliveryAgentStartedMission": e }], type: type, subType: subType })
                              }
                              checked={editSmsData.sms}
                            />
                          </div>
                        </div>
                        <div className="detail-description">
                          <Form>
                            <Form.Control
                              as="textarea"
                              id="smsText"
                              value={editSmsData.smsText}
                              style={{
                                background: "transparent",
                                border: "none",
                                color: "#ffffff",
                                height: "100px",
                              }}
                              type="text"
                              placeholder="Enter SMS Text"
                              onFocus={() => {
                                setFocusedTextArea("smsText");
                              }}
                              onChange={(e) => {
                                onChangeEditSmsHandler(
                                  e,
                                  "smsText",
                                  editSmsData.key
                                );
                              }}
                            // onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultTaskDuration": e.target.value }], type: props.settingTypes.OPERATIONS, subType: props.settingSubType.TASK_AND_MISSION }) }}
                            />
                          </Form>
                        </div>
                        <span className="count">60/500</span>
                      </div>
                      <div className="sms-Detail">
                        <div className="editsms-heading">
                          <h1>Email</h1>
                          <div className="toggle-switch">
                            <Switch
                              onChange={
                                (e) =>
                                  onChangeEditSmsHandler(
                                    e,
                                    "email",
                                    editSmsData.key
                                  )
                                // props.handleChangeSetting(e, { key: [{ "deliveryAgentStartedMission": e }], type: type, subType: subType })
                              }
                              checked={editSmsData.email}
                            />
                          </div>
                        </div>
                        <div className="detail-description">
                          <Form>
                            <Form.Control
                              id="emailText"
                              as="textarea"
                              value={editSmsData.emailText}
                              style={{
                                background: "transparent",
                                border: "none",
                                color: "#ffffff",
                                height: "100px",
                              }}
                              type="text"
                              placeholder="Enter Email Text"
                              onFocus={() => {
                                setFocusedTextArea("emailText");
                              }}
                              onChange={(e) => {
                                onChangeEditSmsHandler(
                                  e,
                                  "emailText",
                                  editSmsData.key
                                );
                              }}
                            // onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultTaskDuration": e.target.value }], type: props.settingTypes.OPERATIONS, subType: props.settingSubType.TASK_AND_MISSION }) }}
                            />
                          </Form>
                        </div>
                        <span className="count">60/500</span>
                      </div>

                      <div className="editsms-tag">
                        <Button
                          className="btn"
                          onClick={() =>
                            handleInsertTextButton("{{recipientName}}")
                          }
                        >
                          Recipient name
                        </Button>
                        <Button
                          className="btn"
                          onClick={() =>
                            handleInsertTextButton("{{driverName}}")
                          }
                        >
                          Driver name
                        </Button>
                        <Button
                          className="btn"
                          onClick={() =>
                            handleInsertTextButton("{{organizationName}}")
                          }
                        >
                          Organization name
                        </Button>
                        {/* <Button
                          className="btn"
                          onClick={() =>
                            handleInsertTextButton("{{timeOfArrival}}")
                          }
                        >
                          Time of arrival
                        </Button> */}
                        <Button
                          className="btn"
                          onClick={() =>
                            handleInsertTextButton("{{trackingLink}}")
                          }
                        >
                          Tracking Link
                        </Button>
                        {/* <Button
                          className="btn"
                          onClick={() =>
                            handleInsertTextButton("{{destinationAddressShort}}")
                          }
                        >
                          Address(Short)
                        </Button> */}
                        <Button
                          className="btn"
                          onClick={() =>
                            handleInsertTextButton("{{destinationAddressLong}}")
                          }
                        >
                          Address(Long)
                        </Button>
                        <Button
                          className="btn"
                          onClick={() =>
                            handleInsertTextButton("{{merchantName}}")
                          }
                        >
                          Merchant Name
                        </Button>
                        {/* <Button
                          className="btn"
                          onClick={() =>
                            handleInsertTextButton("{{vehicleDescrption}}")
                          }
                        >
                          Vehicle descrption
                        </Button> */}
                        <Button
                          className="btn"
                          onClick={() =>
                            handleInsertTextButton("{{completedTime}}")
                          }
                        >
                          Completed time
                        </Button>
                        <Button
                          className="btn"
                          onClick={() =>
                            handleInsertTextButton("{{earliestTime}}")
                          }
                        >
                          Earliest time
                        </Button>
                        <Button
                          className="btn"
                          onClick={() =>
                            handleInsertTextButton("{{latestTime}}")
                          }
                        >
                          Latest time
                        </Button>
                      </div>
                    </div>

                    <div className="Map-layout">
                      <div className="mapview-heading">
                        <h1>What your customer will see</h1>
                      </div>
                      <div className="editsms-detail">
                        <div className="sms-detail">
                          <h1>SMS</h1>
                          <p>{showText(editSmsData.smsText)}</p>
                        </div>
                        <div className="sms-detail">
                          <h1>Email</h1>
                          <p>{showText(editSmsData.emailText)}</p>
                        </div>
                      </div>
                      {/* <Button onClick={() => createDeliveryToggle()}>
                        Next
                      </Button> */}
                      <Button
                        className="savebtn"
                        onClick={(e) => {
                          onSubmitEditSmsHandler(e, editSmsData.key)
                          createEditsmsToggle()
                        }
                        }
                      >
                        Save
                      </Button>

                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* edit sms popup end */}


      </div>
      {/* </ContentLayout> */}
      <ToastContainer
        style={{ color: "black" }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Notifications;
