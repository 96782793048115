import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Card,
  Form as BootstrapForm,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
  NavDropdown,
} from "react-bootstrap";
import "./customer.scss";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import logo from "../../assets/image/logo.png";
import Sidebarmenu from "../../components/sidebarmenu";
import Union from "../../assets/image/Union.png";
import downloadicon from "../../assets/image/downloadicon.png";
import searchicon from "../../assets/image/searchicon.png";
import searchF from "../../assets/image/search-f.png";
import ShortIcon from "../../assets/image/filter-icon.png";
import filterremoveicon from "../../assets/image/filter-removeicon.png";
import closeicon from "../../assets/image/close-icon.png";
import nameicon from "../../assets/image/nameicon.png";
import mailicon from "../../assets/image/mailicon.png";
import industryicon from "../../assets/image/industryicon.png";
import countryicon from "../../assets/image/countryicon.png";
import roleicon from "../../assets/image/roleicon.png";
import phoneicon from "../../assets/image/phoneicon.png";
import ContentLayout from "../../components/contentlayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { ExportToCsv } from "export-to-csv";
import {
  successAlert,
  errorAlert,
  infoAlert,
} from "../../services/toast.service";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import userService from "../../services/user.service";
import RightSideBar from "./rightsidebar";
import { useRef } from "react";
import taskService from "../../services/task.service";
import { useHistory } from "react-router-dom";

const Customer = (props) => {
  const [countryCode, setCountryCode] = useState("");
  useEffect(() => {
    if (!countryCode) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        displayLocation(position.coords.latitude, position.coords.longitude);
      });
    }
  }, []);

  function displayLocation(latitude, longitude) {
    var geocoder;
    geocoder = new window.google.maps.Geocoder();
    var latlng = new window.google.maps.LatLng(latitude, longitude);

    geocoder.geocode({ latLng: latlng }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          console.log("reararaererar", results[0]);
          let findCountry = results[0].address_components.find((items) => {
            return items.types[0] === "country";
          });
          if (findCountry) {
            setCountryCode(findCountry.short_name);
          } else {
            setCountryCode("CA");
          }
        } else {
          setCountryCode("CA");
        }
      } else {
        setCountryCode("CA");
      }
    });
  }

  console.log("props:::::", props);
  const initialValues = {
    id: "",
    password: "test@123",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    apartment: "",
    user: "",
    countryCode: countryCode && countryCode === "IN" ? "+91" : "+1",
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [createCustomer, setcreateCustomer] = useState("false");
  const [formData, setFormData] = useState(initialValues);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [subAdmin, setSubAdmin] = React.useState([]);

  const userData = useSelector((state) => state.auth.user);

  const createCustomerToggle = () => {
    setcreateCustomer(!createCustomer);
  };

  let validation = {
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number is required"),
    countryCode: Yup.string().required("Please choose a countryCode"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("last name is required"),
    address: Yup.string().required("Address  is required"),
    // apartment: Yup.string().required("Apartment  is required"),
    // email: Yup.string().required("Email is required!").email("Please fill valid email address "),
    // password: Yup.string().required("Password is required!"),
  };

  if (userData && userData.role === 1) {
    validation.user = Yup.string().required("Please select a user");
  }

  const validationSchema = Yup.object().shape(validation);

  const formatStatus = (cell, row) => {
    if (row.blocked) return <span>Blocked</span>;
    return <span>Active</span>;
  };
  function NameFormatter(cell, row) {
    return (
      <span>
        {row.firstName ? row.firstName : ""} {row.lastName ? row.lastName : ""}
      </span>
    );
  }
  const UserNameFormatter = (cell, row) => {
    return (
      <span>
        {row.userId && row.userId.firstName ? row.userId.firstName : ""}{" "}
        {row.userId && row.userId.lastName ? row.userId.lastName : ""}
      </span>
    );
  };

  const PhoneFormatter = (cell, row) => {
    return (
      <span>
        {row.countryCode ? row.countryCode : ""}{" "}
        {row.phoneNumber ? row.phoneNumber : ""}
      </span>
    );
  };

  const handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };
  // const [customerActive, setcustomerActive] = useState(false);
  // const customerActiveToggle = () => setcustomerActive(true);
  // const customerActiveToggle = () => {
  //   setcustomerActive(!customerActive);
  // };
  const [isOpened, setIsOpened] = useState(false);
  const [windowSize, setWindowSize] = useState(null);
  const [taskList, setTaskList] = useState([]);
  const [taskDetail, setTaskDetail] = useState(null);

  // function toggle() {
  //   setIsOpened(wasOpened => !wasOpened);
  // }

  const Remove = (cell, row) => {
    return (
      <>
        <span>
          <span className="filter-edit">
            <NavDropdown
              title={<img src={filterremoveicon} />}
              id="nav-dropdown"
            >
              <NavDropdown.Item
                eventKey="4.1"
                onClick={() => {
                  editHandler(row);
                }}
              >
                Edit
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="4.2"
                onClick={() => {
                  deleteHandler(row);
                }}
              >
                Delete
              </NavDropdown.Item>
            </NavDropdown>
          </span>
        </span>
      </>
    );
  };

  const getUserList = async (params) => {
    const response = await userService.getUserList(params);
    if (response.status == 200) {
      if (response.data && response.data.totalPages) {
        setPageCount(response.data.totalPages);
      }
      let results = [];
      if (
        props.location &&
        props.location.state &&
        props.location.state.selectedId
      ) {
        results = response.data?.results.map(i => {
          if (i.id === props.location.state.selectedId) {
            i.highlight = true
            setSelectedItems([props.location.state.selectedId])
          }
          return i
        })
      }
      else {
        results = response.data?.results
      }
      setUsers(results || []);
    }
    console.log("user list response  :::", response);
  };

  const getUserByRole = async (obj) => {
    const response = await userService.getUsers(obj);
    if (response.status == 200) {
      setSubAdmin(response.data || []);
    }
  };

  const exportToCsv = async () => {
    console.log("i export");
    userService
      .getUsers({ role: 3 })
      .then((result) => {
        if (result.status === 200) {
          let data = [];

          result.data.map((item, index) => {
            let d = {
              firstName: item.firstName || "N/A",
              lastName: item.lastName || "N/A",
              email: item.email || "N/A",
              apartment: item.apartment || "N/A",
              address: item.address || "N/A",
              Latitude:
                item.location && item.location.lat ? item.location.lat : "N/A",
              Longitude:
                item.location && item.location.lng ? item.location.lng : "N/A",
              countryCode: item.countryCode || "N/A",
              phoneNumber: item.phoneNumber || "N/A",
              status: item.blocked ? "UnActive" : "Active",
            };
            data.push(d);
          });

          const options = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: true,
            title: "Customer List",
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: "customers",
          };

          const csvExporter = new ExportToCsv(options);
          if (data.length > 0) {
            csvExporter.generateCsv(data);
          } else {
            errorAlert("No data found");
          }
        } else {
          errorAlert(result.message ? result.message : "Something went wronng");
        }
      })
      .catch(console.log);
  };

  //handlers
  const editHandler = (row) => {
    setFormData({
      id: row.id ? row.id : "",
      firstName: row.firstName ? row.firstName : "",
      lastName: row.lastName ? row.lastName : "",
      phoneNumber: row.phoneNumber ? row.phoneNumber : "",
      email: row.email ? row.email : "",
      address: row.address ? row.address : "",
      apartment: row.apartment ? row.apartment : "",
      countryCode: row.countryCode ? row.countryCode : "",
      user: row.userId ? row.userId.id : "",
    });
    createCustomerToggle();
  };
  const deleteHandler = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      text: "Deleted records can't be restored!",
      color: "#ffffff",
      background: "#151719",
      confirmButtonColor: "#1A1D1F",
      cancelButtonColor: "#1A1D1F",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await userService.deleteUser(row.id);
        if (response) {
          console.log("delete response ::::", response);
          Swal.fire(
            "Deleted!",
            "User has been Deleted Successfully !",
            "success"
          );
          getUserList({ page: currentPage, searchText });
        }
      }
    });
  };
  const RowSelectHandler = (row, isSelect, rowIndex, e) => {
    // ...
    if (isSelect) {
      const item = row.id || "";
      setSelectedItems([...selectedItems, item]);
    } else {
      const items = selectedItems.filter((sItem) => sItem != row.id);
      setSelectedItems(items);
    }
  };

  const selectAllRowsHandler = (isSelect, rows, e) => {
    if (isSelect) {
      const items = rows
        .map((rowItem) => rowItem.id)
        .filter((itemId) => {
          if (!selectedItems.includes(itemId)) return itemId;
        });
      setSelectedItems([...selectedItems, ...items]);
    } else {
      const ids = rows.map((rowItem) => rowItem.id);
      const items = selectedItems.filter((sItem) => {
        if (!ids.includes(sItem)) return sItem;
      });
      setSelectedItems(items);
    }
  };

  const deleteAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        text: "Deleted records can't be restored!",
        color: "#ffffff",
        background: "#151719",
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await userService.deleteManyUsers(selectedItems);
          if (data.success) {
            getUserList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Users Deleted Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const blockAllHandler = () => {
    if (selectedItems && selectedItems.length) {
      Swal.fire({
        title: "Are you sure?",
        text: "You Want to Block selected Users !",
        icon: "warning",
        showCancelButton: true,
        color: "#ffffff",
        background: "#151719",
        confirmButtonColor: "#1A1D1F",
        cancelButtonColor: "#1A1D1F",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await userService.blockManyUsers(selectedItems);
          if (data.success) {
            getUserList({ page: currentPage, searchText });
            successAlert(
              data.message ? data.message : "Users Blocked Successfully"
            );
          } else {
            errorAlert(data.message ? data.message : "Something Went Wrong !");
          }
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
    }
  };

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: RowSelectHandler,
    onSelectAll: selectAllRowsHandler,
    bgColor: "rgb(238, 193, 213) !important",
    // classes: (row, rowIndex) => {
    //   console.log("rrrrrrrrrrrrrrrrowwwwwww", row)
    //   return ""
    // }
  };

  useEffect(() => {
    getUserList({ page: currentPage, searchText });
    if (userData.role === 1) {
      getUserByRole({ role: 2, roleBy: userData.role });
    }
  }, [userData]);

  useEffect(() => {
    console.log("users::::", users);
  }, [users]);

  useEffect(() => {
    console.log("search text ::::", searchText);
    const delayDebounceFn = setTimeout(async () => {
      getUserList({ page: 1, searchText });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const escapeRegex = (str) => {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "").replace(/ /g, "");
  };

  const handleSubmit = async (formValue) => {
    try {
      if (formValue.phoneNumber) {
        let phone = escapeRegex((formValue.phoneNumber.toString()));
        formValue.phoneNumber = phone;
      }
      if (formValue.id) {
        //update here
        const response = await userService.editUser(formValue);

        if (response.data.success) {
          setFormData(initialValues);
          setcreateCustomer("false");
          getUserList({ page: currentPage, searchText });
          successAlert(
            response.data.message
              ? response.data.message
              : "User Updated Successfully !"
          );
        }
        if (!response.data.success) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
      } else {
        const response = await userService.createUser(formValue);
        if (response.data.success) {
          setFormData(initialValues);
          setcreateCustomer("false");
          successAlert(
            response.data.message
              ? response.data.message
              : "User Added Successfully !"
          );
          getUserList({ page: currentPage, searchText });
        }
        if (response.data.success === false) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
      }
    } catch (err) {
      console.log("error:::", err);
    }
  };
  const rightSidebarOpenRef = useRef(false);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
  const getWindowDimensions = async () => {
    const { innerWidth: width, innerHeight: height } = window;
    setWindowSize({ width, height })
    let rightsidebarWidth = 370;
    if (!rightSidebarOpenRef.current) rightsidebarWidth = 0
    let leftSidebarWidth = 270;
    if (!leftSidebarOpen) leftSidebarWidth = 70;
    let mapDiv = document.querySelector(".customer-page-wrapper");
    if (mapDiv) mapDiv.style.width = `${width - rightsidebarWidth - leftSidebarWidth}px`;
    if (!rightSidebarOpenRef.current) {
      let rightSideBarWrapper = document.querySelector(".right-side-bar-wrapper");
      if (rightSideBarWrapper) {
        rightSideBarWrapper.style.display = "none"
      };
      let rightContainer = document.querySelector(".right-sidebar-container")
      if (rightContainer) {
        rightContainer.style.width = "20px";
        rightContainer.style.background = "transparent";
      }
      if (document.querySelector(".side-bar-right-arrow-btns"))
        document.querySelector(".side-bar-right-arrow-btns").style.left = "2px";
      // let taskBarRight = document.querySelector(".task-bar-right")
      // if (taskBarRight) taskBarRight.style.display = "none";
    }
    else {
      let rightSideBarWrapper = document.querySelector(".right-side-bar-wrapper");
      if (rightSideBarWrapper) {
        rightSideBarWrapper.style.display = "flex"
      };
      let rightContainer = document.querySelector(".right-sidebar-container")
      if (rightContainer) {
        document.querySelector(".right-sidebar-container").style.width = "370px";
        document.querySelector(".right-sidebar-container").style.background = "#39394b";
      }
      if (document.querySelector(".side-bar-right-arrow-btns"))
        document.querySelector(".side-bar-right-arrow-btns").style.left = "-10px";
    }
  }

  const history = useHistory();
  useEffect(() => {
    history.replace()
    // reload and pass empty object to clear state
    // we can also use replace option: ..., {replace: true}
  }, []);
  const rightsidebarprops = {
    setRightSidebarOpen,
    rightSidebarOpenRef,
    selectedCustomer,
    taskList,
    taskDetail, setTaskDetail
  }
  useEffect(() => {
    getWindowDimensions();
  }, [rightSidebarOpen, leftSidebarOpen])

  const cancelhandler = () => {
    setFormData(initialValues);
    setcreateCustomer("false");
  };

  const handlePageClick = async ({ selected }) => {
    console.log("current page::", selected);
    if (selected + 1 !== currentPage) {
      setCurrentPage(selected + 1);
      await getUserList({ page: selected + 1, searchText });
    }
  };
  const [bulkOpened, setbulkOpened] = useState(false);

  function bulktoggle() {
    setbulkOpened((wasOpened) => !wasOpened);
  }
  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.searchText
    ) {
      setSearchText(props.location.state.searchText);
    }
  }, []);

  const getTaskList = async (id) => {
    let data = await taskService.getCustomerTaskList({
      customerId: id
    })
    if (data && data.data && data.data.result) {
      setTaskList(data.data.result);
      setRightSidebarOpen(true)
      rightSidebarOpenRef.current = true
    }
  }

  const tableRowEvents = {
    onRowClick: (row) => {
      getTaskList(row.id);
      setSelectedCustomer(row)
    }
  }
  return (
    <>
      <ContentLayout>
        <div className="main-wrapper">
          <div className="customer-page-wrapper d-flex">
            {/* customer layout start */}
            <div className="customer-form-wrapper">
              <div className="customer-heading-box">
                <Row>
                  <Col xs={6}>
                    <h1>Customers</h1>
                  </Col>
                  {/* <Col xs={6}>
                  {((userData && userData.role === 1) || ((userData && userData.role === 2 && !userData.userId) || (userData.userId && userData.userRole === "manager")) || (userData && userData.role === 4 && userData.userRole === "manager")) && <div className="createmsbox">
                    <div onClick={createCustomerToggle}>
                      <Button className="cticon">
                        <img src={Union} alt="icon" />
                      </Button>
                      <span>Add Customer</span>
                    </div>
                    <div>
                      <Button onClick={() => exportToCsv()}>
                        <img src={downloadicon} alt="icon" />
                      </Button>
                    </div>
                  </div>}
                </Col> */}
                </Row>
              </div>
              <div className="customer-searching-box">
                {((userData && userData.role === 1) || ((userData && userData.role === 2 && !userData.userId) || (userData.userId && userData.userRole === "manager")) || (userData && userData.role === 4 && userData.userRole === "manager")) && <Row>
                  <Col xs={6}>
                    <div className="create-action-option mb-3">
                      <Dropdown>
                        <Dropdown.Toggle className="bulk-action">
                          Bulk Action
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={blockAllHandler}>
                            Block All
                          </Dropdown.Item>
                          <Dropdown.Item onClick={deleteAllHandler}>
                            Delete Selected
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="searchbar">
                      <BootstrapForm className="d-flex">
                        <FormControl
                          type="search"
                          placeholder="Search or type a name"
                          className="me-2"
                          autoComplete="off"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          aria-label="Search"
                        />
                        <Button className="searchicon">
                          <img src={searchicon} alt="searchicon" />
                        </Button>
                        {/* <span className="searchF">
                        <img src={searchF} alt="icon" />
                      </span> */}
                      </BootstrapForm>
                    </div>
                  </Col>
                </Row>}
              </div>

              {/* customer list start */}
              <div className="customer-list-wrapper">
                <BootstrapTable
                  options={tableRowEvents}
                  data={users}
                  striped={true}
                  hover={true}
                  selectRow={selectRowProp}
                  wrapperClasses="table-responsive"
                >
                  <TableHeaderColumn
                    dataField="id"
                    isKey={true}
                    dataAlign="left"
                    dataSort={true}
                    dataFormat={NameFormatter}
                    width="200px"
                  >
                    Name <img className="ms-1" src={ShortIcon} alt="" />
                  </TableHeaderColumn>
                  {userData && userData.role === 1 && (
                    <TableHeaderColumn
                      dataField="userId"
                      // isKey={true}
                      dataAlign="left"
                      // dataSort={true}
                      dataFormat={UserNameFormatter}
                      width="200px"
                    >
                      User Name <img className="ms-1" src={ShortIcon} alt="" />
                    </TableHeaderColumn>
                  )}
                  {/* <TableHeaderColumn
                  dataField="countryCode"
                  dataSort={true}
                  width="200px"
                >
                  Country code
                  <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn> */}
                  <TableHeaderColumn
                    dataField="phoneNumber"
                    dataSort={true}
                    width="200px"
                    dataFormat={PhoneFormatter}
                  >
                    Phone
                    <img className="ms-1" src={ShortIcon} alt="" />
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="email"
                    dataSort={true}
                    width="250px"
                  >
                    Email
                    <img className="ms-1" src={ShortIcon} alt="" />
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="block"
                    dataFormat={formatStatus}
                    width="150px"
                  >
                    Status
                    <img className="ms-1" src={ShortIcon} alt="" />
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="address"
                    dataSort={true}
                    width="200px"
                  >
                    Address
                    <img className="ms-1" src={ShortIcon} alt="" />
                  </TableHeaderColumn>
                  {((userData && userData.role === 1) || ((userData && userData.role === 2 && !userData.userId) || (userData.userId && userData.userRole === "manager")) || (userData && userData.role === 4 && userData.userRole === "manager")) && <TableHeaderColumn
                    dataField="Action"
                    dataAlign="right"
                    dataFormat={Remove}
                    width="150px"
                  >
                    Action
                  </TableHeaderColumn>}
                </BootstrapTable>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Prev"
                  renderOnZeroPageCount={null}
                  className="pagination"
                />
              </div>

              {/* customer list end */}
            </div>

            {/* customer layout end */}
          </div>
          <RightSideBar {...rightsidebarprops} />
        </div>

        {/* create customer popup start */}
        <div
          className={
            createCustomer
              ? "opencustomer-popup"
              : "opencustomer-popup createCustomer"
          }
        >
          <div className="addCustomer-popup">
            <div className="addcustomer-slide">
              <div className="addcustomer-field">
                <span className="title">Customer</span>
                <div className="customer-import">
                  {/* <div className="customer-import-field">
                    <Button>
                      <img src={Union} alt="icon" />
                    </Button>
                    <span>import</span>
                    <Button onClick={() => exportToCsv()}>
                      <img src={downloadicon} alt="icon" />
                    </Button>
                  </div> */}
                  <Button
                    className="createcustomer-close"
                    onClick={createCustomerToggle}
                  >
                    <img src={closeicon} alt="icon" />
                  </Button>
                </div>
              </div>

              <div className="addcustomer-information">
                <h2>Customer Information</h2>
                <div className="customer-form">
                  <Formik
                    enableReinitialize
                    initialValues={formData}
                    render={(formProps) => {
                      return (
                        <Form>
                          <Row className="custinfo-form">
                            <Col xs={6}>
                              <InputGroup>
                                <InputGroup.Text id="firstname">
                                  <img src={nameicon} alt="nameicon" />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  placeholder="First Name"
                                  name="firstName"
                                  maxLength="25"
                                  aria-label="First Name"
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                />
                              </InputGroup>
                            </Col>
                            <Col xs={6}>
                              <InputGroup>
                                <InputGroup.Text id="lastname">
                                  <img src={nameicon} alt="lasticon" />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  placeholder="Last Name"
                                  name="lastName"
                                  maxLength="25"
                                  aria-label="Last Name"
                                />
                                <ErrorMessage name="lastName" component="div" />
                              </InputGroup>
                            </Col>
                            <Col xs={6}>
                              <InputGroup className="mb-3 teamsl">
                                <InputGroup.Text id="roleicon">
                                  <img src={roleicon} alt="roleicon" />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  name="countryCode"
                                  as="select"
                                >
                                  <option selected>Select country code</option>
                                  <option value="+91">+91</option>
                                  <option value="+1">+1</option>
                                </Field>
                                <ErrorMessage
                                  name="countryCode"
                                  component="div"
                                />
                              </InputGroup>
                            </Col>
                            <Col xs={6}>
                              <InputGroup>
                                <InputGroup.Text id="phonenumber">
                                  <img src={phoneicon} alt="phoneicon" />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  placeholder="Phone Number"
                                  name="phoneNumber"
                                  type="text"
                                  aria-label="Phone Number"
                                />
                                <ErrorMessage
                                  name="phoneNumber"
                                  component="div"
                                />
                              </InputGroup>
                            </Col>
                            <Col xs={6}>
                              <InputGroup>
                                <InputGroup.Text id="yourworkemail">
                                  <img src={mailicon} alt="emailicon" />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  placeholder="Email"
                                  name="email"
                                  aria-label="Email"
                                />
                                <ErrorMessage name="email" component="div" />
                              </InputGroup>
                            </Col>
                            <Col xs={8}>
                              <InputGroup>
                                <InputGroup.Text id="Addressname">
                                  <img src={countryicon} alt="Addressicon" />
                                </InputGroup.Text>

                                <Field
                                  className="form-control"
                                  placeholder="Address"
                                  name="address"
                                  aria-label="Address"
                                />
                                <ErrorMessage name="address" component="div" />
                              </InputGroup>
                            </Col>
                            <Col xs={4}>
                              <InputGroup>
                                <InputGroup.Text id="AptBld">
                                  <img src={industryicon} alt="AptBldicon" />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  placeholder="Apt, Bld"
                                  name="apartment"
                                  aria-label="AptBld"
                                />
                                <ErrorMessage
                                  name="apartment"
                                  component="div"
                                />
                              </InputGroup>
                            </Col>
                            {userData && userData.role === 1 && (
                              <Col xs={6}>
                                <InputGroup className="mb-3 teamsl">
                                  <InputGroup.Text id="roleicon">
                                    <img src={roleicon} alt="roleicon" />
                                  </InputGroup.Text>
                                  <Field
                                    className="form-control"
                                    name="user"
                                    as="select"
                                  >
                                    <option selected>Select User</option>
                                    {subAdmin &&
                                      subAdmin.map((data) => (
                                        <option value={data.id}>
                                          {data.firstName + " " + data.lastName}
                                        </option>
                                      ))}
                                  </Field>
                                  <ErrorMessage name="user" component="div" />
                                </InputGroup>
                              </Col>
                            )}
                          </Row>
                          <div className="ct-btn">
                            <Button
                              className="cncle-btn"
                              onClick={cancelhandler}
                            >
                              Cancel
                            </Button>
                            <Button className="ctaskbtn" type="submit">
                              Let’s Go
                            </Button>
                          </div>
                        </Form>
                      );
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  ></Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* create customer popup end */}
      </ContentLayout>
      <ToastContainer
        style={{ color: "black" }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Customer;
