import { useDispatch, useSelector } from "react-redux";
import "./payments.scss";
import { useState } from "react";
import { useEffect } from "react";
import { timeZoneHeader } from "../../../helpers/helperFunctions";

import filterremoveicon from "../../../assets/image/filter-removeicon.png";
import Chart from "./chart";
import BootstrapTable from "react-bootstrap-table-next";
import momentTz from "moment-timezone";
import { GET_PAYMENT_API } from "../../../redux/invoicing/actionTypes";
const timezone = { ...timeZoneHeader() }

const Payments = (props) => {
    const { invoiceFormProps } = props;
    const { paymentList, pageLimit, pageCount } = useSelector((state) => state.invoicing)
    const dispatch = useDispatch();
    const tableRowEvents = {
        onRowClick: (row) => {
            //   setSelectedCustomer(row)
        }
    }
    const selectRowProp = {
        mode: "checkbox",
        clickToSelect: false,
        // onSelect: RowSelectHandler,
        // onSelectAll: selectAllRowsHandler,
        bgColor: "rgb(238, 193, 213) !important",
        // classes: (row, rowIndex) => {
        //   console.log("rrrrrrrrrrrrrrrrowwwwwww", row)
        //   return ""
        // }
    };

    const handlePageClick = async ({ selected }) => {
        console.log("current page::", selected);
        if (selected + 1 !== props.currentPage) {
            props.invoiceFormProps.setCurrentPage(selected + 1);
            // await getInvoiceList({ page: selected + 1 });
        }
    };
    useEffect(() => {
        getPaymentList({})
    }, [])

    const getPaymentList = ({ invoiceId }) => {
        let query = {}
        if (invoiceId) {
            query.invoiceId = invoiceId
        }
        dispatch({
            type: GET_PAYMENT_API,
            data: {
                payload: query
            }
        })
    }

    const handleActionClick = (type, row) => {
        if (type === "Edit") {
            let payment = {
                ...row,
                invoiceCustomerId: row.invoiceCustomerId.id,
                invoiceId: row.invoiceId.id,
                invoiceDetails: row.invoiceId
            }
            props.setPaymentForm(payment)
            getPaymentList({
                invoiceId: row.invoiceId?.id
            })
            props.setSelectedTab("INVOICES")
            props.setServices(row.invoiceId?.services)
            props.setButtonText("Edit")
            props.setShowPaymentForm(true)
            props.setCreateInvoice(true)
            invoiceFormProps.setInvoiceForm(row.invoiceId)
        }
    }

    const Remove = (cell, row) => {
        return (
            <>
                <span>
                    <span className="filter-edit">
                        <div className="action">
                            <span onClick={() => { handleActionClick("Edit", row) }}>Edit</span>
                            <span onClick={() => { }}>Send</span>
                        </div>
                    </span>
                </span>
            </>
        );
    };

    const columns = [{
        dataField: 'paymentDate',
        text: 'DATE',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: (cell, row) => {
            return row.invoiceDate ? momentTz(row.invoiceDate).tz(timezone.timezone).format("MM/DD/YYYY") : momentTz().tz(timezone.timezone).format("MM/DD/YYYY")
        },
        headerClasses: 'table-header',
        classes: 'custom-td-class'
    }, {
        dataField: 'invoiceId',
        text: 'NUMBER',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: (cell, row) => {
            return row.invoiceId?.invoiceNumber
        },
        headerClasses: 'table-header',
        classes: 'custom-td-class'
    }, {
        dataField: 'invoiceCustomerId',
        text: 'CUSTOMER DISPLAY NAME',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        formatter: (cell, row) => {
            return row.invoiceCustomerId?.displayName
        },
        headerClasses: 'table-header',
        classes: 'custom-td-class'
    },
    {
        dataField: 'referenceNumber',
        text: 'REFERENCE NUMBER',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        formatter: (cell, row) => {
            return row.referenceNumber
        },
        headerClasses: 'table-header',
        classes: 'custom-td-class'
    },
    {
        dataField: 'amount',
        text: 'AMOUNT',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        formatter: (cell, row) => {
            return row.amount ? `$${row.amount}` : "$0"
        },
        headerClasses: 'table-header',
        classes: 'custom-td-class'
    },
    {
        dataField: 'depositedTo',
        text: 'DEPOSITED TO',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        formatter: (cell, row) => {
            return row.depositedTo?.name
        },
        headerClasses: 'table-header',
        classes: 'custom-td-class'
    },
    {
        dataField: 'paymentMethod',
        text: 'PAYMENT METHOD',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        formatter: (cell, row) => {
            return row.paymentMethod?.name
        },
        headerClasses: 'table-header',
        classes: 'custom-td-class'
    },
    {
        datafield: "selectedOption",
        text: 'ACTION',
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'custom-td-class',
        formatter: Remove,
        // editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
        //     <ActionButton {...editorProps} value={value} />
        // )
    }];
    console.log("paymentList", paymentList)
    const rowClasses = (row, rowIndex) => {
        return 'custom-row-class';
    };
    return (<div className="main-payment-container">
        <div className="list-container">
            <BootstrapTable
                keyField='id'
                options={tableRowEvents}
                data={JSON.parse(JSON.stringify(paymentList))}
                striped={true}
                hover={true}
                rowClasses={rowClasses}
                selectRow={selectRowProp}
                wrapperClasses="table-responsive"
                columns={columns}
            >
            </BootstrapTable>
        </div>
    </div>)
}

export default Payments