import React from 'react';

import './Backdrop.css';

const backdrop = (props) => (
    props.show ? <div className={"Backdrop"} style={{
        display: props.show ? "block" : "none"
    }} onClick={()=>props.updateState(false)}></div> : null
);

export default backdrop;