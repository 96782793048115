import {
    Card,
    Row,
    Col,
    Form,
    InputGroup,
    FormControl,
    Button,
    Dropdown,
    Modal,
    Container,
    NavDropdown,
} from "react-bootstrap";
import cameraicon from "../../assets/image/cameraicon.png";
import signatureicon from "../../assets/image/signatureicon.png";
import noteicon from "../../assets/image/noteicon.png";
import Switch from "react-switch";

const AgentAppTab = (props) => {
    let type = props.settingTypes.OPERATIONS;
    let subType = props.settingSubType.AGENT_APP
    if (props.userData && props.userData.role === 1) {
        type = props.settingTypes.OPERATIONS
        subType = props.settingSubType.COMMON_AGENT_APP
    }
    let roleBasedDisabled = false;
    if (props.userData && props.userData.userId && props.userData.userRole !== "admin") {
        roleBasedDisabled = true
    }
    return (<div className="st-oplist">
        <div className="st-optimization">
            <div className="st-opheading">
                <h1>Distance & Time</h1>
            </div>
            <div className="st-oplistbox">
                <Form>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Auto arrival distance
                        </Form.Label>
                        <Col sm={10}>
                            {/* <Form.Control type="text" value="250 m" /> */}
                            <div style={{ display: "flex" }}>
                                <Col sm={1.5}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={props.settingFormData.autoArrivalDistance}
                                        placeholder="Auto arrival distance"
                                        // disabled={roleBasedDisabled}
                                        disabled={true}
                                        // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskDuration": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                        onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "autoArrivalDistance": e.target.value }], type: type, subType: subType }) }} />
                                </Col>
                                <span style={{ color: "white", padding: "6px", fontSize: "13px", fontWeight: "500" }}>{props.settingFormData.autoArrivalDistanceUnit}</span>
                                <span style={{ color: "white", padding: "6px", fontSize: "13px", fontWeight: "500" }}>Coming soon...</span>
                            </div>
                        </Col>
                    </Form.Group>
                    {/* <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Task delay alert
                        </Form.Label>
                        <Col sm={10}>
                            <div style={{ display: "flex" }}>
                                <Col sm={1.5}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={props.settingFormData.taskDelayAlert}
                                        disabled={roleBasedDisabled}
                                        placeholder="Task delay alert"
                                        // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskDuration": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                        onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "taskDelayAlert": e.target.value }], type: type, subType: subType }) }} />
                                </Col>
                                <span style={{ color: "white", padding: "6px", fontSize: "13px", fontWeight: "500" }}>{props.settingFormData.taskDelayAlertUnit}</span>
                            </div>
                        </Col>
                    </Form.Group> */}
                </Form>
            </div>
        </div>
        <div className="st-Documents">
            <div className="st-opheading">
                <h1>Proof of delivery (POD)</h1>
            </div>
            <div className="st-oplistbox">
                <Form>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            <img src={noteicon} />
                            Notes
                        </Form.Label>
                        <Col sm={10}>
                            <div className="toggle-switch">
                                {/* <Form.Control
                                    type="checkbox"
                                    id="note"
                                    name="note"
                                    value="8"
                                /> */}
                                <Switch
                                    disabled={roleBasedDisabled}
                                    onChange={(e) => { props.handleChangeSetting(e, { key: [{ "notes": e }], type: type, subType: subType }) }}
                                    checked={props.settingFormData.notes}
                                />
                                {/* <label for="note">
                                    <span className="toggle-track"></span>
                                </label> */}
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            <img src={signatureicon} />
                            Signature
                        </Form.Label>
                        <Col sm={10}>
                            <div className="toggle-switch">
                                {/* <Form.Control
                                    type="checkbox"
                                    id="Signature"
                                    name="Signature"
                                    value="8"
                                /> */}
                                <Switch
                                    disabled={roleBasedDisabled}
                                    onChange={(e) => { props.handleChangeSetting(e, { key: [{ "signature": e }], type: type, subType: subType }) }}
                                    checked={props.settingFormData.signature}
                                />
                                {/* <label for="Signature">
                                    <span className="toggle-track"></span>
                                </label> */}
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            <img src={cameraicon} /> Photos
                        </Form.Label>
                        <Col sm={10}>
                            <div className="toggle-switch">
                                {/* <Form.Control
                                    type="checkbox"
                                    id="Photos"
                                    name="Photos"
                                    value="8"
                                /> */}
                                <Switch
                                    disabled={roleBasedDisabled}
                                    onChange={(e) => { props.handleChangeSetting(e, { key: [{ "photos": e }], type: type, subType: subType }) }}
                                    checked={props.settingFormData.photos}
                                />
                                {/* <label for="Photos">
                                    <span className="toggle-track"></span>
                                </label> */}
                            </div>
                        </Col>
                    </Form.Group>
                </Form>
            </div>
        </div>
    </div>)

}

export default AgentAppTab