import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

export const register = createAsyncThunk(
    "auth/register",
    async ({ username, email, password }, thunkAPI) => {
        try {
            const response = await AuthService.register(username, email, password);
            thunkAPI.dispatch(setMessage(response.data.message));
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const login = createAsyncThunk(
    "auth/login",
    async ({ email, password }, thunkAPI) => {
        try {
            const data = await AuthService.login({ email, password });

            if (data.success) {
                return { user: data.user, message: data.message, type: 'success' };
            } else {
                thunkAPI.rejectWithValue();
                return { message: data.message, type: 'danger' }
            }

        } catch (error) {
            console.log("error::", error)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const logout = createAsyncThunk("auth/logout", async () => {
    await AuthService.logout();
});

export const getProfile = createAsyncThunk(
    "auth/getProfile",
    async ({ userId }, thunkAPI) => {
        try {
            const data = await AuthService.getProfile({ userId });
            if (data.data.success) {
                return { user: data.data.user, message: data.data.message, type: 'success' };
            } else {
                thunkAPI.rejectWithValue();
                return { message: data.message, type: 'danger' }
            }

        } catch (error) {
            console.log("error::", error)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);
export const resetMessage = createAsyncThunk("auth/resetMessage", async () => { });

const initialState = user
    ? { isLoggedIn: true, user, message: "", type: "" }
    : { isLoggedIn: false, user: null };

const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: {
        [register.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
        [register.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.message = action.payload.message;
            state.type = action.payload.type;
            state.user = action.payload.user;
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
        [resetMessage.fulfilled]: (state, action) => {
            state.message = '';
            state.type = '';
        },
        [resetMessage.rejected]: (state) => {
            state.message = '';
            state.type = '';
        },
        [getProfile.fulfilled]: (state, action) => {
            state.user = action.payload.user;
        }
    },
});

const { reducer } = authSlice;
export default reducer;