import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ContentLayout from "../../components/contentlayout";
import timeZoneHeader from "../../services/timezone-header";
import "./Planner.scss"
import RightSideBar from "./RightSideBar";
import TopBar from "./TopBar";
import momentTz from "moment-timezone"
import taskService from "../../services/task.service";
import MyMapComponent from "./Map";
import routes from "../dashboard/routes3.json";
import { Rnd } from "react-rnd";
import TaskContainer from "./TaskContainer";
import { selectTasksPolygon, taskSingleClickHandler } from "../../helpers/helperFunctions"
import NewOrder_pin from "../../assets/image/Planner_map_pins/NewOrder_pin.png"
import Friday_pin from "../../assets/image/Planner_map_pins/Friday_pin.png"
import Monday_pin from "../../assets/image/Planner_map_pins/Monday_pin.png"
import Saturday_pin from "../../assets/image/Planner_map_pins/Saturday_pin.png"
import Sunday_pin from "../../assets/image/Planner_map_pins/Sunday_pin.png"
import Thursday_pin from "../../assets/image/Planner_map_pins/Thursday_pin.png"
import Tuesday_pin from "../../assets/image/Planner_map_pins/Tuesday_pin.png"
import UnFriday_pin from "../../assets/image/Planner_map_pins/UnFriday_pin.png"
import UnMonday_pin from "../../assets/image/Planner_map_pins/UnMonday_pin.png"
import UnNewOrder_pin from "../../assets/image/Planner_map_pins/UnNewOrder_pin.png"
import UnSaturday_pin from "../../assets/image/Planner_map_pins/UnSaturday_pin.png"
import UnSunday_pin from "../../assets/image/Planner_map_pins/UnSunday_pin.png"
import UnThursday_pin from "../../assets/image/Planner_map_pins/UnThursday_pin.png"
import UnTuesday_pin from "../../assets/image/Planner_map_pins/UnTuesday_pin.png"
import UnWed_pin from "../../assets/image/Planner_map_pins/UnWed_pin.png"
import Wed_pin from "../../assets/image/Planner_map_pins/Wed_pin.png"
import Pending_pin from "../../assets/image/Planner_map_pins/Pending_pin.png"
import UnPending_pin from "../../assets/image/Planner_map_pins/UnPending_pin.png"
import moment from "moment";
import { Loader } from "../../components/Loader/loader";
import Bottommenu from "../../components/Bottommenu";
import teamService from "../../services/team.service"
import { useRef } from "react";
import Swal from "sweetalert2";
import authService from "../../services/auth.service";

const Planner = () => {
    const [importToggle, setImportToggle] = useState(false);
    const [map, setMap] = useState(null);
    const userData = useSelector((state) => state.auth.user)
    const superAdminRole = userData && userData.role && userData.role === 1 ? true : false;
    const [allTasks, setAllTasks] = useState([]);
    const [plannerLabels, setPlannerLabels] = useState([]);
    const [unassignedTaskList, setUnassignedTaskList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [taskDetail, setTaskDetail] = useState(null);
    const [datedTaskList, setDatedTaskList] = useState([]);
    const [defaultCenter, setDefaultCenter] = useState({
        lat: routes[0].lat,
        lng: routes[0].lng,
    });
    const [bookMarks, setBookMarks] = useState([]);
    const [datedBookMarks, setDatedBookMarks] = useState([]);
    const [pendingBookMarks, setPendingBookMarks] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [lastSelectedTaskIndex, setSelectedTaskIndex] = useState(null);
    const [toggleState, setToggleState] = useState({});
    const [height, setHeight] = useState();
    const [allDates, setAllDates] = useState([]);
    const allDatesRef = useRef();
    const [date, setDate] = useState({ startDate: momentTz().startOf("W"), endDate: momentTz().endOf("W") });
    const [teams, setTeams] = useState([])
    const [taskCount, setTaskCount] = useState([])
    const [selectedBottomMenuTeams, setSelectedBottomMenuTeams] = useState([]);
    const [selectedBottomMenuStatus, setSelectedBottomMenuStatus] = useState([]);
    const [pendingTaskList, setPendingTaskList] = useState([]);
    const [buttonText, setButtonText] = useState("Create Task");
    const [createTask, setcreateTask] = useState(false);
    const [location, setLocation] = useState("");
    const [address, setAddress] = useState("");
    const [addLabel, setAddLabel] = useState(null);

    const defaultDurationTime = 60;
    const defaultMaxMissionDuration = 20;
    const defaultMaxMissionTasks = 8;
    const defaultMissionCapacity = 1000;
    useEffect(() => {
        window.navigator.geolocation.getCurrentPosition((position) => {
            setDefaultCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
        })
        const listener = e => {
            if (e.key === "Escape") {
                setSelectedCenter(null);
            }
        };

        window.addEventListener("keydown", listener);
        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, [])
    const initialValues = {
        id: "",
        orderType: "D",
        merchantId: null,
        customerId: null,
        createdFor: null,
        userId: null,
        notes: "",
        orderDate: null,
        after: "",
        afterTimePicker: null,
        before: "",
        beforeTimePicker: null,
        scheduledTimePicker: null,
        scheduledTime: "",
        afterSeconds: 0,
        beforeSeconds: 0,
        scheduledTimeSeconds: 0,
        durationTime: defaultDurationTime,
        capacity: defaultMissionCapacity,
        capacityUnit: "m3",
        firstName: "",
        lastName: "",
        ISO: "ca",
        countryCode: "+1",
        phoneNumber: "",
        email: "",
        address: "",
        apartment: "",
        afterOpen: false,
        beforeOpen: false,
        scheduleTimeOpen: false,
        taskStatus: "",
        documents: [],
        price: "",
        priceUnitSymbol: "$",
        priceUnit: "USD",
        libraryFiles: [],
        values: []
        // beforeTime: "",
        // afterTime: "",
        // scheduleTime: "",
    };

    useEffect(() => {
        window.navigator.geolocation.getCurrentPosition((position) => {
            setDefaultCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
        })
        const listener = e => {
            if (e.key === "Escape") {
                setSelectedCenter(null);
            }
        };

        window.addEventListener("keydown", listener);
        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, [])

    const [formData, setFormData] = useState(initialValues);
    const [values, setValues] = useState([])
    const [inputValue, setInputValue] = useState("");
    // Debounce search term so that it only gives us latest value ...
    // ... if searchTerm has not been updated within last 500ms.
    // The goal is to only have the API call fire when user stops typing ...
    // ... so that we aren't hitting our API rapidly.
    const debouncedSearchTerm = useDebounce(inputValue, 500);
    // Effect for API call
    useEffect(
        () => {
            console.log("debouncedSearchTerm", debouncedSearchTerm)
            if (debouncedSearchTerm || debouncedSearchTerm === "") {
                submitPlannerLabel(debouncedSearchTerm)
            }
        },
        [debouncedSearchTerm] // Only call effect if debounced search term changes
    );

    function useDebounce(value, delay) {
        // State and setters for debounced value
        const [debouncedValue, setDebouncedValue] = useState(value);
        useEffect(
            () => {
                // Update debounced value after delay
                const handler = setTimeout(() => {
                    setDebouncedValue(value);
                }, delay);
                // Cancel the timeout if value changes (also on delay change or unmount)
                // This is how we prevent debounced value from updating if value is changed ...
                // .. within the delay period. Timeout gets cleared and restarted.
                return () => {
                    clearTimeout(handler);
                };
            },
            [value, delay] // Only re-call effect if value or delay changes
        );
        return debouncedValue;
    }


    const apiDataRef = useRef();
    const timezone = { ...timeZoneHeader() };
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        setHeight(height)
    }

    const submitPlannerLabel = async (label) => {
        if (addLabel) {
            let data = {
                dayOfWeek: addLabel.dayOfWeek,
                label: label,
                type: "PLANNER_LABELS"
            }
            if (addLabel.id) {
                data.id = addLabel.id
            }
            let response = await authService.saveCommonServices(data);
            if (response && response.data) {
                let allDates = getDates(date.startDate, date.endDate, []);
                let dayOfWeeks = allDates.map(i => i.dayOfWeek);
                getPlannerLabels({ dayOfWeeks })
            }
        }
    }
    let getApiData = () => {
        let apiData = {}
        if (selectedBottomMenuTeams.length) {
            apiData.teamIds = selectedBottomMenuTeams
        }
        if (selectedBottomMenuStatus.length) {
            let allDates = allDatesRef.current;
            let dateFilters = [];

            let timezone = { ...timeZoneHeader() }
            for (let status of selectedBottomMenuStatus) {
                if (status === "new") {
                    apiData.new = true
                }
                else if (status === "pending") {
                    apiData.pending = true
                }
                else {
                    if (allDates[status]) {
                        dateFilters.push(JSON.stringify({
                            $gte: momentTz(allDates[status].startDate).tz(timezone.timezone).startOf("d")._d,
                            $lte: momentTz(allDates[status].endDate).tz(timezone.timezone).endOf("d")._d
                        }))
                    }
                }
            }
            if (dateFilters.length) {
                apiData.dateFilters = dateFilters
            }
        }
        apiDataRef.current = apiData
        return apiData;
    }

    useEffect(() => {
        getApiData()
        let apiData = apiDataRef.current;
        getUnassignedTaskList(null, null, apiData)
    }, [selectedBottomMenuStatus, selectedBottomMenuTeams])

    const [size, setSize] = useState({
        width: "100%",
        height: "320px",
    })

    const [mapSize, setMapSize] = useState({
        // width: "100%",
        marginLeft: "10px",
        marginRight: "10px",
        height: "340px",
    })

    useEffect(() => {
        getTeamList();
        getTaskCount();
    }, [])

    useEffect(() => {
        let currentHeight = size.height.split("px");
        let heightNumber = Number(currentHeight[0]);
        setMapSize({
            // width: "100%",
            marginLeft: "10px",
            marginRight: "10px",
            height: `${height - heightNumber}px`,
        })
    }, [size, height])

    useEffect(() => {
        function handleResize() {
            getWindowDimensions();
        }
        getWindowDimensions();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (date) {
            getUnassignedTaskList(date.startDate, date.endDate)
            let allDates = getDates(date.startDate, date.endDate, []);
            let dayOfWeeks = allDates.map(i => i.dayOfWeek);
            getPlannerLabels({ dayOfWeeks })
        }
    }, [date])

    const getPlannerLabels = async ({ dayOfWeeks }) => {
        let params = {
            dayOfWeeks: dayOfWeeks
        }
        if (userData.subAdmin) {
            params.userId = userData.id
        }
        let response = await authService.getCommonServices(params);
        if (response && response.data && response.data.data) {
            setPlannerLabels(response.data.data.results)
        }
    }

    function dragStart(e, task) {
        e.dataTransfer.setData("drag-item", task.id)
    }

    const dragOver = async (ev) => {
        ev.preventDefault();
        // ev.dataTransfer.dropEffect = props.dropEffect;
    }


    const editMenuHandler = async () => {

        setButtonText("Edit Task")
        let currentTask = "";
        if (selectedTasks && selectedTasks.length) {
            if (allTasks && allTasks.length) {
                currentTask = allTasks.find((t) => t.id === selectedTasks[0]);
            }
        }
        if (currentTask) {
            setcreateTask(true);
            if (currentTask.location) {
                setLocation(currentTask.location);
            }
            if (currentTask.address) {
                setAddress(currentTask.address);
            }
            let createdFor = currentTask.merchantId.id;
            let date = momentTz(currentTask.orderDate).tz(timezone.timezone)._a
            let formData = {
                id: currentTask.id,
                orderType: currentTask.orderType,
                merchantId: currentTask.merchantId ? currentTask.merchantId.id : userData.id,
                userId: currentTask.userId ? currentTask.userId.id : userData.id,
                createdFor: currentTask.createdFor ? currentTask.createdFor.id : (createdFor ? createdFor : userData.id),
                customerId: currentTask.customerId.id,
                notes: currentTask.notes,
                orderDate: currentTask.orderDate?moment(date):null,
                after: currentTask.after,
                before: currentTask.before,
                beforeTimePicker: currentTask.beforeTimePicker || null,
                afterTimePicker: currentTask.afterTimePicker || null,
                scheduledTime: currentTask.scheduledTime,
                scheduledTimePicker: currentTask.scheduledTimePicker || null,
                durationTime: currentTask.durationTime,
                capacity: currentTask.capacity,
                price: currentTask.price,
                priceUnit: currentTask.priceUnit,
                priceUnitSymbol: currentTask.priceUnitSymbol,
                firstName: currentTask.customer && currentTask.customer.firstName ? currentTask.customer.firstName : currentTask.customerId.firstName,
                lastName: currentTask.customer && currentTask.customer.lastName ? currentTask.customer.lastName : currentTask.customerId.lastName,
                phoneNumber: currentTask.customerId.phoneNumber,
                countryCode: currentTask.customerId.countryCode || "",
                email: currentTask.email && currentTask.customer.email ? currentTask.customer.email : currentTask.customerId.email,
                address: currentTask.address
                    ? currentTask.address
                    : "",
                apartment: currentTask.apartment,
                afterOpen: false,
                beforeOpen: false,
                scheduleTimeOpen: false,
                documents: currentTask.documents,
                libraryFiles: currentTask.libraryFiles,
                teamIds: currentTask.teamIds.map(i => i.id),
                values: currentTask.teamIds
                // beforeTime: currentTask.beforeTime,
                // afterTime: currentTask.afterTime,
                // scheduleTime: currentTask.scheduleTime
            }
            if (formData.userId !== userData.id) {
                formData.values = []
            }
            setFormData(formData);
        }
    };



    const deleteMenuHandler = async () => {
        if (selectedTasks && selectedTasks.length) {
            Swal.fire({
                title: "Are you sure?",
                text: "You want to delete task(s)!",
                icon: "warning",
                showCancelButton: true,
                color: "#ffffff",
                background: "#151719",
                confirmButtonColor: "#1A1D1F",
                cancelButtonColor: "#1A1D1F",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await taskService.deleteTasks({ taskIds: selectedTasks });
                }
                getUnassignedTaskList(date.startDate, date.endDate);
            })
        }
    };

    const duplicateTaskHandler = () => {
        setButtonText("Create Task")
        let currentTask = "";
        if (selectedTasks && selectedTasks.length) {
            if (allTasks && allTasks.length) {
                currentTask = allTasks.find((t) => t.id == selectedTasks[0]);
            }
        }
        if (currentTask) {
            setcreateTask(true);
            if (currentTask.location) {
                setLocation(currentTask.location);
            }
            if (currentTask.address) {
                setAddress(currentTask.address);
            }
            let orderDate = null
            if (currentTask.orderDate) {
                let date = momentTz(currentTask.orderDate).tz(timezone.timezone)._a;
                orderDate = momentTz(date).startOf("d")._d
            }
            let form = {
                orderType: currentTask.orderType,
                merchantId: currentTask.merchantId ? currentTask.merchantId.id : userData.id,
                userId: currentTask.userId ? currentTask.userId.id : userData.id,
                createdFor: currentTask.createdFor ? currentTask.createdFor.id : userData.id,
                customerId: currentTask.customerId.id,
                notes: currentTask.notes,
                // orderDate: new Date(currentTask.orderDate),
                orderDate: orderDate,
                after: currentTask.after,
                before: currentTask.before,
                beforeTimePicker: currentTask.beforeTimePicker || null,
                afterTimePicker: currentTask.afterTimePicker || null,
                scheduledTime: currentTask.scheduledTime,
                scheduledTimePicker: currentTask.scheduledTimePicker || null,
                durationTime: currentTask.durationTime,
                capacity: currentTask.capacity,
                firstName: currentTask.customer && currentTask.customer.firstName ? currentTask.customer.firstName : currentTask.customerId.firstName,
                lastName: currentTask.customer && currentTask.customer.lastName ? currentTask.customer.lastName : currentTask.customerId.lastName,
                phoneNumber: currentTask.customerId.phoneNumber,
                countryCode: currentTask.customerId.countryCode || "",
                email: currentTask.customer && currentTask.customer.email ? currentTask.customer.email : currentTask.customerId.email,
                address: currentTask.address
                    ? currentTask.address
                    : "",
                apartment: currentTask.apartment,
                afterOpen: false,
                beforeOpen: false,
                scheduleTimeOpen: false,
                values: currentTask.teamIds,
                teamIds: currentTask.teamIds.map(i => i.id),
            }
            if (form.userId !== userData.id) {
                form.values = []
            }
            setFormData(form);
        }
    }

    const getTaskCount = async () => {
        let response = await taskService.getTaskCountDaily({
            userId: userData.id,
            orderDate: selectedDateRange(moment().startOf("M"), moment().add(1, "month").startOf("M"))
        })
        if (response && response.data) {
            if (response.data.result && response.data.result.length) {
                setTaskCount(response.data.result)
            }
        }
    }

    let colors = [
        {
            color: "#AC6AF0",
            markerImage: Monday_pin,
            selectedMarkerImage: UnMonday_pin
        },
        {
            color: "#E78B87",
            markerImage: Tuesday_pin,
            selectedMarkerImage: UnTuesday_pin
        },
        {
            color: "#80BD5E",
            markerImage: Wed_pin,
            selectedMarkerImage: UnWed_pin
        },
        {
            color: "#E49051",
            markerImage: Thursday_pin,
            selectedMarkerImage: UnThursday_pin
        },
        {
            color: "#2681C4",
            markerImage: Friday_pin,
            selectedMarkerImage: UnFriday_pin
        },
        {
            color: "#F7ED8A",
            markerImage: Saturday_pin,
            selectedMarkerImage: UnSaturday_pin
        },
        {
            color: "#8fbdfcff",
            markerImage: Sunday_pin,
            selectedMarkerImage: UnSunday_pin
        },
    ];

    let getDates = (startDate, stopDate, taskList) => {
        let dateArray = [];
        let currentDate = momentTz(startDate).tz(timezone.timezone);
        stopDate = momentTz(stopDate).tz(timezone.timezone);
        let i = 0;
        while (currentDate <= stopDate) {
            let obj = {
                date: momentTz(currentDate).tz(timezone.timezone).format('YYYY-MM-DD'),
                startDate: momentTz(currentDate).tz(timezone.timezone).startOf("d"),
                endDate: momentTz(currentDate).tz(timezone.timezone).endOf("d"),
                dayOfWeek: momentTz(currentDate).tz(timezone.timezone).day(),
                ...colors[i]
            }

            obj.taskList = taskList.filter((item) => {
                let orderDate = moment(item.orderDate)._a;
                return moment(orderDate).format('YYYY-MM-DD') === obj.date
            })
            dateArray.push(obj)
            i++
            currentDate = moment(currentDate).add(1, 'days');
        }
        allDatesRef.current = dateArray
        return dateArray;
    }

    const getTeamList = async (payload, setFieldValue) => {
        let params = { limit: 100 }
        if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
            params.userIds = userData.permittedUsers
        }
        if (userData.teamIds) {
            params.teamIds = userData.teamIds
        }
        if (userData.subAdmin) {
            delete params.userIds;
            delete params.teamIds
        }
        if (payload && payload.userId) {
            params.userId = payload.userId
        }
        const response = await teamService.getTeamList(params);
        if (Number(response.status) === 200) {
            setTeams(response.data.results || []);
        }
    };
    const onDrop = async (ev, data, dropId) => {
        ev.preventDefault();
        let apiData
        let orderDate = new Date()
        let draggedTask = ev.dataTransfer.getData("drag-item")
        if (selectedTasks.length > 1) {
            if (dropId === "unassignedTaskList") {
                orderDate = null
            }
            else if (dropId.split("_")[0] === "date") {
                let timezone = { ...timeZoneHeader() };
                // orderDate = momentTz(new Date(dropId.split("_")[1])).tz(timezone.timezone).startOf("d")._d
                orderDate = new Date(dropId.split("_")[1])
            }
            apiData = {
                taskIds: selectedTasks,
                orderDate: orderDate
            }
        }
        else if (draggedTask) {
            if (dropId === "unassignedTaskList") {
                orderDate = null
            }
            else if (dropId.split("_")[0] === "date") {
                orderDate = new Date(dropId.split("_")[1])
            }
            apiData = {
                taskIds: [draggedTask],
                orderDate: orderDate
            }
        }

        let timezone = { ...timeZoneHeader() };
        if (apiData) {
            if (apiData.orderDate) {
                apiData.orderDate = momentTz(orderDate).tz(timezone.timezone).startOf("d")._d
            }
            await taskService.updateMultipleTasks(apiData)
        }
        setSelectedTasks([])
        await getUnassignedTaskList(date.startDate, date.endDate)
        await getTaskCount()
    }


    const getBookmarks = (tasks, type) => {
        const data = tasks.map((t) => {
            if (t && t.location) {
                let data = {
                    id: t.id, ...t.location, address: t.address, position: t.position, orderType: t.orderType,
                    customerId: t.customerId, agentId: t.agentId, teamId: t.teamId, taskStatus: t.taskStatus,
                    optimised: t.missionId && t.missionId.optimised ? t.missionId.optimised : false,
                    missionDetails: t.missionId && t.missionId.missionDetails ? t.missionId.missionDetails : {},
                    capacity: t.capacity, capacityUnit: t.capacityUnit, eta: "__", etaSeconds: t.etaSeconds,
                    markerType: "TASK", missioned: t.missioned, updatedAt: t.updatedAt, orderCompletedDate: t.orderCompletedDate,
                    selected: t.active ? t.active : false
                }

                if (t.taskStatus === "unassigned") {
                    if (t.actionPendingCustomer) {
                        data.markerImage = Pending_pin;
                        data.selectedMarkerImage = UnPending_pin;
                    }
                    else {
                        data.markerImage = NewOrder_pin;
                        data.selectedMarkerImage = UnNewOrder_pin;
                    }
                }
                if (t.markerImage) {
                    data.markerImage = t.markerImage;
                }
                if (t.selectedMarkerImage) {
                    data.selectedMarkerImage = t.selectedMarkerImage;
                }
                return data;
            }
        });
        if (type === "UNASSIGNED") {
            setBookMarks(data)
        }
        else if (type === "PENDING") {
            console.log("data", data)
            setPendingBookMarks(data)
        }
        else {
            setDatedBookMarks(data)
        }
    }

    let taskClickProps = {
        selectedTasks: selectedTasks,
        setToggleState,
        setSelectedTasks: setSelectedTasks,
        unassignedTaskList: unassignedTaskList,
        datedTaskList: datedTaskList,
        setDatedTaskList: setDatedTaskList,
        bookMarks: bookMarks,
        setBookMarks: setBookMarks,
        // setTaskList: setUnassignedTaskList,
        lastSelectedTaskIndex,
        setUnassignedTaskList: setUnassignedTaskList,
        setSelectedTaskIndex,
        setSelectedCenter: setSelectedCenter,
        setDefaultCenter: setDefaultCenter,
        setDatedBookMarks, datedBookMarks,
        setPendingBookMarks, pendingBookMarks,
        taskDetail, setTaskDetail

    }

    const getUnassignedTaskList = async (startDate, endDate, apiData, setSelectedTask) => {
        if (!startDate || !endDate) {
            startDate = date.startDate;
            endDate = date.endDate;
        }
        let data = {
            limit: 1000,
            orderDate: selectedDateRange(startDate, endDate),
            withoutDate: true
        }
        let newApiData = await getApiData();
        if (!apiData && (newApiData.teamIds || newApiData.dateFilters || newApiData.new || newApiData.pending)) {
            apiData = newApiData
        }
        if (apiData) {
            if (apiData.dateFilters) {
                delete data.orderDate
                delete data.withoutDate
            }
            data = { ...data, ...apiData }
        }
        if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
            data.userIds = userData.permittedUsers
        }
        const response = await taskService.getTaskList(data);

        if (Number(response.status) === 200) {
            if (setSelectedTask) {
                selectTasksPolygon({
                    tasks: response.data.results,
                    unassignedTaskList,
                    setBookMarks,
                    setSelectedTasks,
                    setUnassignedTaskList: setDatedTaskList,
                    bookMarks
                })
            }
            else {
                setAllTasks(response.data.results || [])
                if (response.data && response.data.results) {
                    let findDatedTasks = response.data.results.filter(item => {
                        return item.orderDate !== null
                    })
                    let findNonDatedTasksNew = response.data.results.filter(item => {
                        return item.orderDate === null && (!item.actionPendingCustomer)
                    })
                    let findNonDatedTasksPending = response.data.results.filter(item => {
                        return item.orderDate === null && item.actionPendingCustomer
                    })
                    setUnassignedTaskList(findNonDatedTasksNew || []);
                    setDatedTaskList(findDatedTasks || []);
                    setPendingTaskList(findNonDatedTasksPending || [])
                    let allDates = getDates(date.startDate, date.endDate, findDatedTasks)
                    setAllDates(allDates)
                }
                else {
                    let allDates = getDates(date.startDate, date.endDate, [])
                    setAllDates(allDates)
                }
            }
        }
    };


    useEffect(() => {
        if (unassignedTaskList.length) {
            getBookmarks(unassignedTaskList, "UNASSIGNED")
        }
        else {
            setBookMarks([])
        }
    }, [unassignedTaskList])

    useEffect(() => {
        if (pendingTaskList.length) {
            getBookmarks(pendingTaskList, "PENDING")
        }
        else {
            setPendingBookMarks([])
        }
    }, [pendingTaskList])

    useEffect(() => {
        if (datedTaskList.length) {
            let allDatedTaskList = [];
            let allDates = getDates(date.startDate, date.endDate, datedTaskList)
            setAllDates(allDates)
            for (let dates of allDates) {
                dates.taskList = dates.taskList.map(i => {
                    i.markerImage = dates.markerImage
                    i.selectedMarkerImage = dates.selectedMarkerImage
                    return i
                })
                allDatedTaskList = [...allDatedTaskList, ...dates.taskList]
            }
            getBookmarks(allDatedTaskList, "DATED")
        }
        else {
            setDatedBookMarks([])
        }
    }, [datedTaskList])

    let topbarProps = {
        date, setDate
    }
    let sidebarProps = {
        hideDragger: true,
        menuCollapse: false
    }
    let mapProps = {
        map, setMap,
        defaultCenter,
        bookMarks,
        datedBookMarks,
        pendingBookMarks,
        setDefaultCenter,
        setSelectedCenter,
        taskSingleClickHandler,
        taskClickProps,
        getTaskList: getUnassignedTaskList
    }
    let taskStatus = [{
        name: "New",
        value: "new"
    },
    {
        name: "Pending",
        value: "pending"
    },
    {
        name: "Monday",
        value: 0
    },
    {
        name: "Tuesday",
        value: 1
    },
    {
        name: "Wednesday",
        value: 2
    },
    {
        name: "Thursday",
        value: 3
    },
    {
        name: "Friday",
        value: 4
    },
    {
        name: "Saturday",
        value: 5
    },
    {
        name: "Sunday",
        value: 6
    }]

    let bottomProps = {
        teams,
        taskStatus,
        selectedBottomMenuTeams, setSelectedBottomMenuTeams,
        selectedBottomMenuStatus, setSelectedBottomMenuStatus
    }
    const taskContainerProps = {
        unassignedTaskList, setUnassignedTaskList, setDatedTaskList,
        taskClickProps: { ...taskClickProps, setTaskList: setDatedTaskList },
        date,
        datedTaskList,
        onDrop, dragStart, dragOver, getBookmarks, allDates,
        editMenuHandler, duplicateTaskHandler, deleteMenuHandler,
    }
    let rightsidebarProps = {
        setImportToggle, importToggle,
        unassignedTaskList, setUnassignedTaskList,
        taskClickProps,
        getUnassignedTaskList, selectedTasks,
        date, onDrop, dragStart, dragOver,
        setLoading, taskDetail, setTaskDetail,
        taskCount, pendingTaskList, setPendingTaskList,
        editMenuHandler, duplicateTaskHandler, deleteMenuHandler,
        buttonText, setButtonText,
        createTask, setcreateTask,
        location, setLocation,
        address, setAddress,
        values, setValues, formData, setFormData,
        initialValues, defaultDurationTime, defaultMissionCapacity
    }

    const selectedDateRange = (startDate, endDate) => {
        let timezone = { ...timeZoneHeader() }
        return {
            $gte: momentTz(startDate).tz(timezone.timezone).startOf("d")._d,
            $lt: momentTz(endDate).tz(timezone.timezone).endOf("d")._d
        }
    }

    return (<>
        <ContentLayout {...sidebarProps}>
            <div class="content">
                <div class="content-center">
                    <TopBar {...topbarProps} />
                    <div className="weather-fixed-div">
                        {allDates.map((items) => {
                            return (<div className="weather-details">
                                <div className="day-of-week">
                                    <span>{moment(items.date).format('dddd').substring(0, 3)}</span>
                                </div>
                                <div className="date-of-week">
                                    <span>{moment(items.date).format('DD')}</span>
                                </div>
                            </div>)
                        })}
                    </div>
                    <div className="weather-fixed-div">
                        {allDates.map((items) => (<div className="task-line" style={{ borderColor: items.color }}></div>))}
                    </div>
                    <div className="weather-fixed-div">
                        {allDatesRef && allDatesRef.current && allDatesRef.current.map((items) => {
                            let findLabel = plannerLabels.find(i => Number(i.dayOfWeek) === Number(items.dayOfWeek));
                            return (
                                <input
                                    key={items.dayOfWeek}
                                    id={items.dayOfWeek}
                                    className="planner-labels"
                                    style={{ borderColor: items.color }}
                                    defaultValue={findLabel && findLabel.label ? findLabel.label : ""}
                                    onChange={(e) => {
                                        setAddLabel({ dayOfWeek: Number(items.dayOfWeek), id: findLabel && findLabel.id })
                                        setInputValue(e.target.value)
                                    }}
                                    placeholder="+ Add Label"
                                    type={"text"} />
                            )
                        })}
                    </div>
                    <div>
                        <Rnd
                            className={"resize-container"}
                            disableDragging={true}
                            enableResizing={{ top: false, right: false, bottom: true, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                            size={{ width: size.width, height: size.height }}
                            // position={{ x: this.state.x, y: this.state.y }}
                            // onDragStop={(e, d) => { this.setState({ x: d.x, y: d.y }) }}
                            onResizeStop={(e, direction, ref, delta, position) => {
                                console.log("onResizeStop", e, direction, ref, delta, position)
                                setSize({
                                    width: ref.style.width,
                                    height: ref.style.height,
                                    ...position,
                                });
                            }}
                            onResize={(e, direction, ref, delta, position) => {
                                setSize({
                                    width: ref.style.width,
                                    height: ref.style.height,
                                    ...position,
                                });
                            }}
                        >
                            <TaskContainer {...taskContainerProps} />
                        </Rnd>
                    </div>
                    <div style={{ borderBottom: "4px solid grey", margin: "auto", marginBottom: "5px", marginTop: "5px", borderRadius: "2px", width: "90px", position: "relative" }}></div>
                    <div style={mapSize}><MyMapComponent {...mapProps} /></div>
                    <Bottommenu {...bottomProps} />
                </div>
                <div class="content-right">
                    <RightSideBar {...rightsidebarProps} />
                </div>
            </div>
        </ContentLayout>

        {loading ? <Loader /> : null}
    </>)
}

export default Planner;