import {
  HIDE_WARNING,
  SHOW_WARNING,
  CLEAR_WARNING,
  SHOW_INFO,
} from './actionTypes';

const initialState = {
  status: false,
  alertData: [],
};

const alertModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_WARNING:
      let obj;
      obj = {
        status: true,
        alertTitle: action.payload?.alertTitle ? action.payload?.alertTitle : '',
        alertDesc:action.payload?.alertDesc ? action.payload?.alertDesc : '',
        errorType: action.payload?.errorType ? action.payload?.errorType : 1,
        alertType: action.payload?.alertType ? action.alertType : 'warning',
      };
      return {
        ...state,
        alertData: [...state.alertData, obj],
      };

    case SHOW_INFO:
      return {
        ...state,
        alertData: [...state.alertData, action.payload],
      };

    case HIDE_WARNING:
      return {
        ...state,
        alertData: action.payload,
      };

    case CLEAR_WARNING:
      return {
        ...state,
        alertData: [],
      };

    default:
      return state;
  }
};

export default alertModalReducer;
