import React, { useState, useEffect } from "react";
import "./sidebarmenu.css";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
} from "react-bootstrap";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import logo from "../assets/image/logo.png";
import controller_icon from "../assets/image/menu.png";
import fullscreen from "../assets/image/fullscreen.png";
import question from "../assets/image/question.png";
import plus from "../assets/image/plus.png";
import bell from "../assets/image/bell.png";
import { FaAngleRight } from "react-icons/fa";
import "./sidebarmenu.scss";
import AuthService from "../services/auth.service";
import { extensions } from "../slices/extensions";
import { defaultSubscription } from "../slices/subscription";
import * as SHA from "js-sha256";
import packageJson from "../../package.json";
import moment from "moment";
import { commonServicesTypes, settingSubType } from "../helpers/configDefaults";
import {
  GET_COMMON_SERVICES,
  GET_SETTINGS,
  SAVE_CURRENT_COUNTRY,
} from "../redux/auth/actionTypes";
import SidebarSettingMenu from "./sidebarsettingmenu";
import { useLocation } from "react-router-dom";
import { getProfile } from "../slices/auth";

const Sidebarmenu = (props) => {
  const history = useHistory();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [features, setFeatures] = useState({});
  const [hideDragger, setHideDragger] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [tooltipVisible, setTooltipVisible] = useState(true);


  const userData = useSelector((state) => state.auth.user);
  const extensionData = useSelector((state) => state.extensions);
  const defaultSubscriptionData = useSelector(
    (state) => state.defaultSubscription
  );
  const user = JSON.parse(localStorage.getItem("user"));
  const { commonServices, countryCode, settings } = useSelector(
    (state) => state.authSaga
  );

  const [activeIndex, setActiveIndex] = useState(null);

  const handleIconClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const caching = () => {
    let version = localStorage.getItem("version");
    if (version !== packageJson.version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }
      localStorage.setItem("version", packageJson.version);
    }
  };
  useEffect(() => {
    caching();
    if (
      user &&
      extensionData &&
      extensionData.extension &&
      extensionData.extension.length === 0
    ) {
      let userId = user.id;
      if (user.role === 2 && user.userId) {
        userId = user.userId;
      }
      dispatch(extensions({ userId: userId }));
    }
    if (userData) {
      if (
        userData.role !== 1 &&
        !userData.userId &&
        (!userData.userBuilderId || userData.isSubscriptionCancel) &&
        +moment(userData.endSubscriptionDate) < +moment()
      ) {
        history.push("/Business");
      }
      if (userData.status === "BLOCKED") {
        logout()
      }
      let hmac = SHA.sha256.hmac(
        process.env.REACT_APP_INTERCOM_SERVER_KEY,
        userData.id
      );
      // var hash = HmacSHA256(userData.id, process.env.REACT_APP_INTERCOM_SERVER_KEY)
      let app_id = process.env.REACT_APP_INTERCOM_APP_ID;
      let intercomData = {
        api_base: "https://api-iam.intercom.io",
        app_id: app_id,
        user_id: userData.id,
        name: `${userData.firstName} ${userData.lastName}`, // Full name
        email: userData.email, // Email address
        user_hash: hmac,
      };
      let company = {
        id: userData.id,
      },
        avatar = {};
      if (userData.phoneNumber) {
        intercomData.phone = `${userData.countryCode ? userData.countryCode : ""
          }${userData.phoneNumber}`;
      }
      if (userData.companyName) {
        company.name = userData.companyName;
      }
      if (userData.webSite) {
        company.website = userData.webSite;
      }
      if (userData.industry) {
        company.industry = userData.industry;
      }
      if (userData.dailyDeliveryVolume) {
        let split = userData.dailyDeliveryVolume.split("-");
        company.size = Number(split[1]);
      }
      intercomData.company = company;
      if (userData.logo && userData.logo.original) {
        avatar = {
          type: "avatar",
          image_url: userData.logo.original,
        };
      }
      intercomData.avatar = avatar;
      if (!!window.Intercom) window.Intercom("boot", intercomData);
    }
  }, []);

  useEffect(() => {
    if (userData && userData.token) {
      dispatch(defaultSubscription({ userId: user.id }));
    }
  }, [userData]);

  useEffect(() => {
    if (
      defaultSubscriptionData &&
      defaultSubscriptionData.extension &&
      defaultSubscriptionData.extension.data &&
      defaultSubscriptionData.extension.data.results.length
    ) {
      setFeatures((prev) => {
        return {
          ...prev,
          ...props.features,
          ...defaultSubscriptionData.extension.data.results[0].features,
        };
      });
    }
  }, [defaultSubscriptionData, props.features]);

  useEffect(() => {
    extensionData &&
      extensionData.extension &&
      extensionData.extension.length &&
      extensionData.extension.map((data) => {
        if (data.isEnabled) {
          data.userExtensions.map((extension) => {
            setFeatures((prev) => {
              return {
                ...prev,
                ...extension.features,
                additionalTaskNumber: Number(
                  extension.features.additionalTaskNumber
                ),
              };
            });
          });
        }
      });
  }, [extensionData]);

  useEffect(() => {
    // if (!settings || !settings.length) {
    getSetting();
    // }
    // if (!commonServices || !commonServices.length) {
    getCommonServices();
    // }
  }, [userData]);

  const getSetting = async () => {
    let params = {
      subType: [
        settingSubType.AGENT_APP,
        settingSubType.API_KEY,
        settingSubType.COMMUNICATIONS,
        settingSubType.GROUPS,
        settingSubType.LEGAL_PROTECTION,
        settingSubType.NOTIFICATIONS,
        settingSubType.PERFORMANCE,
        settingSubType.REGIONAL_SETTINGS,
        settingSubType.TASK_AND_MISSION,
        settingSubType.SCHEDULE_DELIVERY,
      ],
    };
    if (userData.role === 1) {
      params.subType = [
        settingSubType.COMMON_AGENT_APP,
        settingSubType.COMMON_API_KEY,
        settingSubType.COMMON_COMMUNICATIONS,
        settingSubType.COMMON_GROUPS,
        settingSubType.COMMON_LEGAL_PROTECTION,
        settingSubType.COMMON_NOTIFICATIONS,
        settingSubType.COMMON_PERFORMANCE,
        settingSubType.COMMON_REGIONAL_SETTINGS,
        settingSubType.COMMON_TASK_AND_MISSION,
        settingSubType.COMMON_SCHEDULE_DELIVERY,
      ];
    }

    let data = {
      payload: params,
      saveInRedux: true,
    };
    dispatch({ type: GET_SETTINGS, data });
  };

  const getCommonServices = async () => {
    let params = {
      userId: userData.id,
      type: [
        commonServicesTypes.CONFIRMATION_DELIVERY,
        commonServicesTypes.CUSTOMER_EXPERIENCE_COMMUNICATIONS,
        commonServicesTypes.CUSTOMER_EXPERIENCE_NOTIFICATIONS,
        commonServicesTypes.RESPONSIBILITY_DISCHARGE,
        commonServicesTypes.TAX_GROUP,
        commonServicesTypes.TERMS,
        commonServicesTypes.PAYMENT_METHOD,
        commonServicesTypes.DEPOSIT_METHOD,
      ],
    };
    if (userData.role === 1) {
      params.type = [
        commonServicesTypes.COMMON_CONFIRMATION_DELIVERY,
        commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_NOTIFICATIONS,
        commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS,
        commonServicesTypes.COMMON_RESPONSIBILITY_DISCHARGE,
        commonServicesTypes.TAX_GROUP,
        commonServicesTypes.TERMS,
        commonServicesTypes.PAYMENT_METHOD,
        commonServicesTypes.DEPOSIT_METHOD,
      ];
    }
    if (userData.role === 1) {
      delete params.userId;
    }

    let data = {
      payload: params,
      saveInRedux: true,
    };
    dispatch({ type: GET_COMMON_SERVICES, data });
  };

  useEffect(() => {
    if (!countryCode && window.navigator?.geolocation) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        displayLocation(position.coords.latitude, position.coords.longitude);
      });
    }
    if (user)
      dispatch(getProfile({ userId: user.id }))
  }, []);

  function displayLocation(latitude, longitude) {
    var geocoder;
    if (window.google) {
      geocoder = new window.google.maps.Geocoder();
      var latlng = new window.google.maps.LatLng(latitude, longitude);

      geocoder.geocode({ latLng: latlng }, function (results, status) {
        let countryCode = "CA";
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            let findCountry = results[0].address_components.find((items) => {
              return items.types[0] === "country";
            });
            if (findCountry) {
              countryCode = findCountry.short_name;
            }
          }
        }
        dispatch({
          type: SAVE_CURRENT_COUNTRY,
          countryCode: countryCode,
        });
      });
    }
  }

  const FullScreenToggle = () => {
    setFullScreen(!fullScreen);
    if (!fullScreen) go_full_screen();
    else {
      handleExitFullScreenClick();
    }
  };

  function go_full_screen() {
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }

  const handleExitFullScreenClick = () => {
    document.webkitExitFullscreen();
  };

  const logout = () => {
    AuthService.logout();
    history.push("/");
  };
  const [isActive, setActive] = useState(false);
  const toggleflip = () => {
    setActive(!isActive);
  };
  const menuToggle = () => {
    setMenuCollapse(!menuCollapse);
    props.setLeftSidebarOpen(!props.leftSidebarOpen);
  };
  const [isDarkMode, setIsDarkMode] = useState(() => false);

  let allAccess = {
    merchantAccess: false,
    plannerAccess: false,
  };
  if (userData) {
    let user = userData;
    let role = userData.role;
    if (role === 2) {
      if (!user.userId || (user.userId && user.userRole !== "viewer")) {
        if (user.user && user.user.plannerAccess) {
          allAccess.plannerAccess = true;
        }
        if ((features && features.plannerOperations) || user.plannerAccess) {
          allAccess.plannerAccess = true;
        }
      }
      if (features && features.accessToMerchant) {
        allAccess.merchantAccess = true;
      }
    } else if (role === 1) {
      allAccess.merchantAccess = true;
      allAccess.plannerAccess = true;
    }
  }

  let companyLogo = logo;
  let companyName = "Gomove";
  if (userData) {
    let user = userData;
    if (user.role !== 1) {
      if (user.userId) {
        // if (user.user.companyName) companyName = user.user.companyName;
        if (user.firstName) companyName = `${user.firstName} ${user.lastName ? user.lastName : ""}`;
        if (user.user.logo && user.user.logo.original)
          companyLogo = user.user.logo.original;
      } else {
        if (user.companyName) companyName = user.companyName;
        if (user.logo && user.logo.original) companyLogo = user.logo.original;
      }
    } else if (user.role === 1 && user.subAdmin) {
      if (user.companyName) companyName = user.companyName;
      if (user.logo && user.logo.original) companyLogo = user.logo.original;
    }
  }
  const sidebarHandler = () => {
    console.log("sidebar-menu-opened")
    setSidebarOpened(!sidebarOpened);
    setTooltipVisible(false);

    // Alternatively, manipulate the DOM to hide the tooltip element
    const tooltipElement = document.querySelector('.tooltip');
    if (tooltipElement) {
      tooltipElement.style.display = 'none';
    }
  };
  return (
    <>
      <div>
        <div className="dashboard-sidebarmenu">
          <Link to="/Dashboard">
            <div className="sidebarheader">
              <img src={logo} alt="logo" width={50} />
            </div>
          </Link>

          <div className="sidebarcontent">

            <div>
              <Link
                data-tooltip="Add tasks or import data"
                className={`sidebaricon ${activeIndex === 0 ? "active" : ""}`}
                to="/Dashboard"
                onClick={() => {
                  handleIconClick(0);
                  props.setButtonText && props.setButtonText("Create Task");
                  props.setCreateTask && props.setCreateTask(true);
                  props.openSidebar && props.openSidebar()
                }}
              >
                <img src={plus} alt="awSnap" />
              </Link>
              <Link
                data-tooltip="Workspace"
                className={`sidebaricon ${activeIndex === 1 ? "active" : ""}`}
                to="/Dashboard"
                onClick={() => handleIconClick(1)}
              >
                <img src={controller_icon} alt="awSnap" />
              </Link>

              <Link
                data-tooltip="Notifications"
                className={`sidebaricon ${activeIndex === 2 ? "active" : ""}`}
                to="/Dashboard"
                onClick={() => handleIconClick(2)}
              >
                <img src={bell} alt="awSnap" />
              </Link>
            </div>
            <div>
              <div className="sidebaricon" onClick={FullScreenToggle} data-tooltip="Fullscreen">
                <img src={fullscreen} alt={"Full screen"} />
              </div>
              <a
                className="sidebaricon"
                href="https://help.gomove.ai/en/"
                target="_blank"
                rel="noreferrer"
                data-tooltip="Get help"
              >
                <img className="question-img" src={question} alt="setting" />
              </a>
              <div
                className="sidebaricon"
                onClick={() => {
                  sidebarHandler();
                }}
                data-tooltip="My account"
              >
                <img className="setting-img" src={companyLogo} alt="awSnap" />
                {menuCollapse && (
                  <span className="setting-name">{companyName}</span>
                )}
                {!tooltipVisible && <div className="tooltip">Tooltip content</div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-sidebarmenu">
        <SidebarSettingMenu
          sidebarOpened={sidebarOpened}
          logout={logout}
          companyName={companyName}
          allAccess={allAccess}
        />
        {!hideDragger && (
          <button className="menusidebar-icon" onClick={menuToggle}>
            <span className={isActive ? "flip" : "inflip"} onClick={toggleflip}>
              <FaAngleRight />
            </span>
          </button>
        )}
      </div>
    </>
  );
};

export default Sidebarmenu;
