import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import taskService from "../../../services/task.service";
import "../Public.scss"
import { ThemeProvider } from 'styled-components';
import Home from "./pages/Home";
import authService from "../../../services/auth.service";
const PublicScheduleDelivery = () => {
    const params = useParams();
    const [step, setStep] = useState(1)
    const [passingData, setPassingData] = useState(null);
    const [settings, setSettings] = useState([]);
    const [slots, setSlots] = useState([{
        id: 1,
        after: "8:00am",
        before: "5:00pm",
        afterSeconds: 8 * 60 * 60,
        beforeSeconds: 17 * 60 * 60
    }])
    const [formData, setFormData] = useState({
        taskId: null
    })
    const [task, setTask] = useState(null)
    useEffect(() => {
        if (params.id) {
            getTask(params.id)
        }
    }, [params.id])

    const updateScheduleDelivery = async (data) => {
        return await taskService.updateScheduleDelivery(data)
    }
    const getTask = async (id) => {
        const response = await taskService.getTaskList({ _id: id });
        if (response && response.data && response.data.result?.results.length) {
            let task = response.data.result?.results[0]
            setFormData({
                taskId: task.id,
            })
            if (task) {
                getSettings(task)
            }
            setTask(task)
        }
    }

    const getSettings = async (task) => {
        let params = {
            userId: task.userId.id,
            subType: ["SCHEDULE_DELIVERY"],
        };
        const response = await authService.getSettings(params);
        if (response && response.data && response.data.data?.results.length) {
            setSettings(response.data.data?.results || [])
        }
    }

    const props = {
        step, setStep,
        passingData, setPassingData,
        task, slots, setSlots,
        updateScheduleDelivery,
        settings
    }
    const theme = {
        colors: {
            whiteColor: '#ffffff',
            whiteColorVariant: '#e3e3e3',
            bgColor: '#3A394A',
            bgColorVariant: '#262633',
            primaryColor: '#213cb4',
            primaryColorVariant: '#1290fa',
            darkTextColor: '#888888',
        },
        tab: '100px',
        mobile: '600px'
    }

    return (<div class="main-container">
        <ThemeProvider theme={theme}>
            <Home {...props} />
        </ThemeProvider>
    </div>)
}

export default PublicScheduleDelivery;