import React, { useState, useEffect } from "react";
import {
  Form as BootstrapForm,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  NavDropdown,
} from "react-bootstrap";
import "../hub/hub.scss";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Union from "../../assets/image/Union.png";
import downloadicon from "../../assets/image/downloadicon.png";
import searchicon from "../../assets/image/searchicon.png";
import ShortIcon from "../../assets/image/filter-icon.png";
import filterremoveicon from "../../assets/image/filter-removeicon.png";
import closeicon from "../../assets/image/close-icon.png";
import roleicon from "../../assets/image/roleicon.png";
import { Formik, Field, ErrorMessage, Form as FormF } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import {
  successAlert,
  errorAlert,
  infoAlert,
} from "../../services/toast.service";
import Swal from "sweetalert2";
import userService from "../../services/user.service";
import extensionService from "../../services/extension.service";

const Extensions = (props) => {
  const initialValues = {
    id: "",
    productId: "",
    roles: [],
    featuresFromProd: "",
    accessToGomoveone: false,
    numberOfTasks: 0,
    numberOfDrivers: 0,
    createAllocateRoutes: false,
    vehicleCapacityOptimization: false,
    liveTrackRouteEta: false,
    importSpreadSheets: false,
    photoAndSignaturePOD: false,
    liveChatSupport: false,
    everyThingInInitiate: false,
    unlimitedTasks: false,
    unlimitedAgents: false,
    smsNotificationsTriggers: false,
    customizeRecipientSms: false,
    everyThingInOperate: false,
    smartDamageManagement: false,
    deliveryConfirmationForm: false,
    linkedTask: false,
    driverChat: false,
    apiIntegration: false,
    standardOnboarding: false,
    everyThingInElevate: false,
    AIPoweredRealTimeRouting: false,
    automaticAppointmentBooking: false,
    AIBasedAutoAllocation: false,
    justInTimeOrderPrep: false,
    reverseLogistics: false,
    billingOnTheGo: false,
    teamSynchro: false,
    premiumOnboardingAndImplementation: false,
    phoneAndLiveChatSupport: false,
    taskCreationForMerchant: false,
    taskCreationAddDocs: false,
    taskDuration: false,
    missionOptimization: false,
    accessToMerchant: false,
    accessToLibrary: false,
    additionalHistory: false,
    brandedTrackingPage: false,
    routeOptimization: false,
    multiMission: false,
    vehicleCapacityManagement: false,
    responsibiltyDischargeForm: false,
    pictureAssessment: false,
    exportMissionPdf: false,
    exportTaskPdf: false,
    exportTaskHistoryToPdf_1: false,
    exportTaskHistoryToPdf_3: false,
    exportTaskHistoryToPdf_all: false,
    thirdPartyDeliveryMerchant: false,
    landingPage: false,
    plannerOperations: false,
  };
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const [createCustomer, setcreateCustomer] = useState("false");
  const [formData, setFormData] = useState(initialValues);
  const [users, setUsers] = useState([]);
  const [extensions, setExtensions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState("");

  const [plans, setPlans] = useState([])
  const createCustomerToggle = () => {
    setcreateCustomer(!createCustomer);
  };

  const validationSchema = Yup.object().shape({
    productId: Yup.string().required("Please select extension"),
  });

  const formatStatus = (cell, row) => {
    if (row.blocked) return <span>Blocked</span>;
    return <span>Active</span>;
  };
  function Features(cell, row) {

    let feature = []
    if (row.features) {
      feature = Object.keys(row.features).filter(key => row.features[key] === true)
    }
    return <span>{feature.toString()}</span>

  }
  const handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  const [isOpened, setIsOpened] = useState(false);


  const Remove = (cell, row) => {
    return (
      <>
        <span>
          <span className="filter-edit">
            <NavDropdown
              title={<img src={filterremoveicon} />}
              id="nav-dropdown"
            >
              <NavDropdown.Item
                eventKey="4.1"
                onClick={() => {
                  editHandler(row);
                }}
              >
                Edit
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="4.2"
                onClick={() => {
                  deleteHandler(row);
                }}
              >
                Delete
              </NavDropdown.Item>
            </NavDropdown>
          </span>
        </span>
      </>
    );
  };



  const getExtensionList = async (params) => {
    const response = await extensionService.getExtensionList(params);
    if (response.status == 200) {

      setExtensions(response.data || []);
    }
    console.log("user list response  :::", response);
  };

  const getStripePlans = async () => {
    const response = await userService.getStripePlans();
    console.log(response, "--response")
    if (response.status == 200) {
      if (response.data && response.data.plansPrices) {

        const data = response.data.plansPrices.filter((data) => data.metadata && data.metadata.planType && (data.metadata.planType === 'extension' || data.metadata.planType === 'gomoveone' ))

        const plan = data.map((planData) => {

          return {
            name: planData.name,
            price: planData.price && planData.price.unit_amount ? planData.price.unit_amount / 100 : 0,
            priceId: planData.price && planData.price.id ? planData.price.id : '',
            description: planData.description,
            status: planData.active,
            productId: planData.id,
            isChecked: false,
            isEnabled: false,
            paymentStatus: 0,
            imgUrl: planData.images && planData.images.length > 0 ? planData.images[0] : '',
            _id: undefined
          }
        })

        setPlans(plan)
      }
    }
    console.log("plan list response  :::", response);
  }

  //handlers
  const editHandler = (row) => {
    console.log(row, "row")
    setFormData({
      productId: row.productId ? row.productId + '##' + row.name : "",
      id: row.id ? row.id : "",
      accessToGomoveone: row.features && row.features.accessToGomoveone ? true : false,
      featuresFromProd: row.features && row.features.featuresFromProd ? row.features.featuresFromProd : "",

      numberOfTasks: row.features && row.features.numberOfTasks ? row.features.numberOfTasks : 0,
      numberOfDrivers: row.features && row.features.numberOfDrivers ? row.features.numberOfDrivers : 0,
      createAllocateRoutes: row.features && row.features.createAllocateRoutes ? true : false,
      advancedRoutesOptimization: row.features && row.features.advancedRoutesOptimization ? true : false,
      vehicleCapacityOptimization: row.features && row.features.vehicleCapacityOptimization ? true : false,
      liveTrackRouteEta: row.features && row.features.liveTrackRouteEta ? true : false,
      importSpreadSheets: row.features && row.features.importSpreadSheets ? true : false,
      photoAndSignaturePOD: row.features && row.features.photoAndSignaturePOD ? true : false,
      liveChatSupport: row.features && row.features.liveChatSupport ? true : false,
      everyThingInInitiate: row.features && row.features.everyThingInInitiate ? true : false,
      unlimitedTasks: row.features && row.features.unlimitedTasks ? true : false,
      unlimitedAgents: row.features && row.features.unlimitedAgents ? true : false,
      smsNotificationsTriggers: row.features && row.features.smsNotificationsTriggers ? true : false,
      customizeRecipientSms: row.features && row.features.customizeRecipientSms ? true : false,
      everyThingInOperate: row.features && row.features.everyThingInOperate ? true : false,
      smartDamageManagement: row.features && row.features.smartDamageManagement ? true : false,
      deliveryConfirmationForm: row.features && row.features.deliveryConfirmationForm ? true : false,
      linkedTask: row.features && row.features.linkedTask ? true : false,
      driverChat: row.features && row.features.driverChat ? true : false,
      apiIntegration: row.features && row.features.apiIntegration ? true : false,
      standardOnboarding: row.features && row.features.standardOnboarding ? true : false,
      everyThingInElevate: row.features && row.features.everyThingInElevate ? true : false,
      AIPoweredRealTimeRouting: row.features && row.features.AIPoweredRealTimeRouting ? true : false,
      automaticAppointmentBooking: row.features && row.features.automaticAppointmentBooking ? true : false,
      AIBasedAutoAllocation: row.features && row.features.AIBasedAutoAllocation ? true : false,
      justInTimeOrderPrep: row.features && row.features.justInTimeOrderPrep ? true : false,
      reverseLogistics: row.features && row.features.reverseLogistics ? true : false,
      billingOnTheGo: row.features && row.features.billingOnTheGo ? true : false,
      teamSynchro: row.features && row.features.teamSynchro ? true : false,
      premiumOnboardingAndImplementation: row.features && row.features.premiumOnboardingAndImplementation ? true : false,
      phoneAndLiveChatSupport: row.features && row.features.phoneAndLiveChatSupport ? true : false,

      taskCreationForMerchant: row.features && row.features.taskCreationForMerchant ? true : false,
      taskCreationAddDocs: row.features && row.features.taskCreationAddDocs ? true : false,
      taskDuration: row.features && row.features.taskDuration ? true : false,
      missionOptimization: row.features && row.features.missionOptimization ? true : false,
      accessToMerchant: row.features && row.features.accessToMerchant ? true : false,
      accessToLibrary: row.features && row.features.accessToLibrary ? true : false,
      additionalHistory: row.features && row.features.additionalHistory ? true : false,
      brandedTrackingPage: row.features && row.features.brandedTrackingPage ? true : false,
      routeOptimization: row.features && row.features.routeOptimization ? true : false,
      multiMission: row.features && row.features.multiMission ? true : false,
      vehicleCapacityManagement: row.features && row.features.vehicleCapacityManagement ? true : false,
      responsibiltyDischargeForm: row.features && row.features.responsibiltyDischargeForm ? true : false,
      pictureAssessment: row.features && row.features.pictureAssessment ? true : false,
      exportMissionPdf: row.features && row.features.exportMissionPdf ? true : false,
      exportHistory_1: row.features && row.features.exportHistory_1 ? true : false,
      exportHistory_3: row.features && row.features.exportHistory_3 ? true : false,
      exportHistory_all: row.features && row.features.exportHistory_all ? true : false,
      thirdPartyDeliveryMerchant: row.features && row.features.thirdPartyDeliveryMerchant ? true : false,
      exportTaskPdf: row.features && row.features.exportTaskPdf ? true : false,
      landingPage: row.features && row.features.landingPage ? true : false,
      plannerOperations: row.features && row.features.plannerOperations ? true : false,
    });
    createCustomerToggle();
  };
  const deleteHandler = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Deleted records can't be restored!",
      icon: "warning",
      showCancelButton: true,
      color: "#ffffff",
      background: '#151719',
      confirmButtonColor: "#1A1D1F",
      cancelButtonColor: "#1A1D1F",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await extensionService.deleteExtension(row.id);
        if (response) {
          console.log("delete response ::::", response);
          Swal.fire(
            "Deleted!",
            "Hub has been Deleted Successfully !",
            "success"
          );
          getExtensionList({ searchText });
        }
      }
    });
  };

  const RowSelectHandler = (row, isSelect, rowIndex, e) => {
    // ...
    if (isSelect) {
      const item = row.id || "";
      setSelectedItems([...selectedItems, item]);
    } else {
      const items = selectedItems.filter((sItem) => sItem != row.id);
      setSelectedItems(items);
    }
  };

  const selectAllRowsHandler = (isSelect, rows, e) => {
    if (isSelect) {
      const items = rows
        .map((rowItem) => rowItem.id)
        .filter((itemId) => {
          if (!selectedItems.includes(itemId)) return itemId;
        });
      setSelectedItems([...selectedItems, ...items]);
    } else {
      const ids = rows.map((rowItem) => rowItem.id);
      const items = selectedItems.filter((sItem) => {
        if (!ids.includes(sItem)) return sItem;
      });
      setSelectedItems(items);
    }
  };



  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: RowSelectHandler,
    onSelectAll: selectAllRowsHandler,
    bgColor: "rgb(238, 193, 213)",
  };

  useEffect(() => {
    getExtensionList({ searchText });
    getStripePlans()
  }, []);


  useEffect(() => {
    console.log("search text ::::", searchText);
    const delayDebounceFn = setTimeout(async () => {
      getExtensionList({ searchText });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const handleSubmit = async (formValue) => {
    try {
      const productData = formValue.productId.split("##")
      let finalFormData = {
        productId: productData[0],
        name: productData[1],
        features: {
          ...(formValue.featuresFromProd && { featuresFromProd: formValue.featuresFromProd }),
          ...(formValue.accessToGomoveone && { accessToGomoveone: formValue.accessToGomoveone }),

          ...(formValue.numberOfTasks && { numberOfTasks: formValue.numberOfTasks }),
          ...(formValue.numberOfDrivers && { numberOfDrivers: formValue.numberOfDrivers }),
          ...(formValue.createAllocateRoutes && { createAllocateRoutes: formValue.createAllocateRoutes }),
          ...(formValue.advancedRoutesOptimization && { advancedRoutesOptimization: formValue.advancedRoutesOptimization }),
          ...(formValue.vehicleCapacityOptimization && { vehicleCapacityOptimization: formValue.vehicleCapacityOptimization }),
          ...(formValue.liveTrackRouteEta && { liveTrackRouteEta: formValue.liveTrackRouteEta }),
          ...(formValue.importSpreadSheets && { importSpreadSheets: formValue.importSpreadSheets }),

          ...(formValue.photoAndSignaturePOD && { photoAndSignaturePOD: formValue.photoAndSignaturePOD }),
          ...(formValue.liveChatSupport && { liveChatSupport: formValue.liveChatSupport }),
          ...(formValue.everyThingInInitiate && { everyThingInInitiate: formValue.everyThingInInitiate }),
          ...(formValue.unlimitedTasks && { unlimitedTasks: formValue.unlimitedTasks }),
          ...(formValue.unlimitedAgents && { unlimitedAgents: formValue.unlimitedAgents }),
          ...(formValue.smsNotificationsTriggers && { smsNotificationsTriggers: formValue.smsNotificationsTriggers }),
          ...(formValue.customizeRecipientSms && { customizeRecipientSms: formValue.customizeRecipientSms }),
          ...(formValue.everyThingInOperate && { everyThingInOperate: formValue.everyThingInOperate }),
          ...(formValue.smartDamageManagement && { smartDamageManagement: formValue.smartDamageManagement }),
          ...(formValue.deliveryConfirmationForm && { deliveryConfirmationForm: formValue.deliveryConfirmationForm }),
          ...(formValue.linkedTask && { linkedTask: formValue.linkedTask }),
          ...(formValue.driverChat && { driverChat: formValue.driverChat }),
          ...(formValue.apiIntegration && { apiIntegration: formValue.apiIntegration }),
          ...(formValue.standardOnboarding && { standardOnboarding: formValue.standardOnboarding }),
          ...(formValue.everyThingInElevate && { everyThingInElevate: formValue.everyThingInElevate }),
          ...(formValue.AIPoweredRealTimeRouting && { AIPoweredRealTimeRouting: formValue.AIPoweredRealTimeRouting }),
          ...(formValue.automaticAppointmentBooking && { automaticAppointmentBooking: formValue.automaticAppointmentBooking }),
          ...(formValue.AIBasedAutoAllocation && { AIBasedAutoAllocation: formValue.AIBasedAutoAllocation }),
          ...(formValue.justInTimeOrderPrep && { justInTimeOrderPrep: formValue.justInTimeOrderPrep }),
          ...(formValue.reverseLogistics && { reverseLogistics: formValue.reverseLogistics }),
          ...(formValue.billingOnTheGo && { billingOnTheGo: formValue.billingOnTheGo }),
          ...(formValue.teamSynchro && { teamSynchro: formValue.teamSynchro }),
          ...(formValue.premiumOnboardingAndImplementation && { premiumOnboardingAndImplementation: formValue.premiumOnboardingAndImplementation }),
          ...(formValue.phoneAndLiveChatSupport && { phoneAndLiveChatSupport: formValue.phoneAndLiveChatSupport }),

          ...(formValue.taskCreationForMerchant && { taskCreationForMerchant: formValue.taskCreationForMerchant }),
          ...(formValue.taskCreationAddDocs && { taskCreationAddDocs: formValue.taskCreationAddDocs }),
          ...(formValue.taskDuration && { taskDuration: formValue.taskDuration }),
          ...(formValue.missionOptimization && { missionOptimization: formValue.missionOptimization }),
          ...(formValue.accessToMerchant && { accessToMerchant: formValue.accessToMerchant }),
          ...(formValue.accessToLibrary && { accessToLibrary: formValue.accessToLibrary }),
          ...(formValue.additionalHistory && { additionalHistory: formValue.additionalHistory }),
          ...(formValue.brandedTrackingPage && { brandedTrackingPage: formValue.brandedTrackingPage }),
          ...(formValue.routeOptimization && { routeOptimization: formValue.routeOptimization }),
          ...(formValue.multiMission && { multiMission: formValue.multiMission }),
          ...(formValue.vehicleCapacityManagement && { vehicleCapacityManagement: formValue.vehicleCapacityManagement }),
          ...(formValue.responsibiltyDischargeForm && { responsibiltyDischargeForm: formValue.responsibiltyDischargeForm }),
          ...(formValue.pictureAssessment && { pictureAssessment: formValue.pictureAssessment }),
          ...(formValue.exportMissionPdf && { exportMissionPdf: formValue.exportMissionPdf }),
          ...(formValue.exportHistory_1 && { exportHistory_1: formValue.exportHistory_1 }),
          ...(formValue.exportHistory_3 && { exportHistory_3: formValue.exportHistory_3 }),
          ...(formValue.exportHistory_all && { exportHistory_all: formValue.exportHistory_all }),
          ...(formValue.thirdPartyDeliveryMerchant && { thirdPartyDeliveryMerchant: formValue.thirdPartyDeliveryMerchant }),
          ...(formValue.exportTaskPdf && { exportTaskPdf: formValue.exportTaskPdf }),
          ...(formValue.landingPage && { landingPage: formValue.landingPage }),
          ...(formValue.plannerOperations && { plannerOperations: formValue.plannerOperations })
        }
      }
      if (formValue.id) {
        //update here
        finalFormData.id = formValue.id
        const response = await extensionService.editExtension({ ...finalFormData });

        if (response.data) {
          setFormData(initialValues);
          setcreateCustomer("false");
          getExtensionList({ searchText });
          successAlert(
            response.data.message
              ? response.data.message
              : "Extension Updated Successfully !"
          );
        }
        if (!response.data) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
      } else {
        const response = await extensionService.createExtension({ ...finalFormData });
        if (response.data.success) {
          setFormData(initialValues);
          setcreateCustomer("false");
          successAlert(
            response.data.message
              ? response.data.message
              : "Extension Added Successfully !"
          );
          getExtensionList({ searchText });
        }
        if (response.data.success === false) {
          errorAlert(
            response.data.message
              ? response.data.message
              : "Something Went Wrong !"
          );
        }
      }
    } catch (err) {
      console.log("error:::", err);
    }
  };

  const cancelhandler = () => {
    setFormData(initialValues);
    setcreateCustomer("false");
  };


  const [bulkOpened, setbulkOpened] = useState(false);

  function bulktoggle() {
    setbulkOpened((wasOpened) => !wasOpened);
  }
  useEffect(() => {
    if (props.location && props.location.state && props.location.state.searchText) {
      setSearchText(props.location.state.searchText)
    }
  }, []);



  return (

    <>
      {/* <ContentLayout> */}
      <div className="customer-page-wrapper d-flex">
        {/* customer layout start */}
        <div className="customer-form-wrapper">
          <div className="customer-heading-box">
            <Row>
              <Col xs={6}>
                <h1>Extensions</h1>
              </Col>
              <Col xs={6}>
                <div className="createmsbox">
                  <div onClick={createCustomerToggle}>
                    <Button className="cticon" >
                      <img src={Union} alt="icon" />
                    </Button>
                    <span>Add Extension</span>
                  </div>
                  <div>
                    <Button>
                      <img src={downloadicon} alt="icon" />
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="customer-searching-box">
            <Row>
              <Col xs={6}>
                <div className="searchbar">
                  <BootstrapForm className="d-flex">
                    <FormControl
                      type="search"
                      placeholder="Search or type a name"
                      className="me-2"
                      autoComplete="off"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      aria-label="Search"
                    />
                    <Button className="searchicon">
                      <img src={searchicon} alt="searchicon" />
                    </Button>
                    {/* <span className="searchF">
                        <img src={searchF} alt="icon" />
                      </span> */}
                  </BootstrapForm>
                </div>
              </Col>
            </Row>
          </div>

          {/* customer list start */}
          <div className="customer-list-wrapper">
            <BootstrapTable
              data={extensions}
              striped={true}
              hover={true}
              selectRow={selectRowProp}
              wrapperClasses="table-responsive"
            >
              <TableHeaderColumn
                dataField="name"
                isKey={true}
                dataAlign="left"
                dataSort={true}
                width="200px"
              >
                Name <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="productId"
                dataSort={true}
                width="200px"
              >
                Extension Id
                <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="features"
                dataSort={true}
                width="200px"
                dataFormat={Features}
              >
                Features
                <img className="ms-1" src={ShortIcon} alt="" />
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="Action"
                dataAlign="right"
                dataFormat={Remove}
                width="150px"
              >
                Action
              </TableHeaderColumn>
            </BootstrapTable>

          </div>

          {/* customer list end */}
        </div>

        {/* customer layout end */}
      </div>
      {/* create customer popup start */}
      <div
        className={
          createCustomer
            ? "opencustomer-popup"
            : "opencustomer-popup createCustomer"
        }
      >
        <div className="addCustomer-popup">
          <div className="addcustomer-slide Extension-slide">
            <div className="addcustomer-field">
              <span className="title">Extension feature</span>
              <div className="customer-import">
                <div className="customer-import-field">
                  <Button>
                    <img src={Union} alt="icon" />
                  </Button>
                  <span>import</span>
                  <Button>
                    <img src={downloadicon} alt="icon" />
                  </Button>
                </div>
                <Button
                  className="createcustomer-close"
                  onClick={() => { createCustomerToggle(); cancelhandler(); }}
                >
                  <img src={closeicon} alt="icon" />
                </Button>
              </div>
            </div>

            <div className="addcustomer-information">
              <h2>Extensions Information</h2>
              {console.log("formData", formData)}
              <div className="customer-form Extension-feature">
                <Formik
                  enableReinitialize
                  initialValues={formData}
                  render={(formProps) => {
                    return (
                      <FormF>
                        <Row>
                          <Col xs={12}>
                            <InputGroup className="mb-3 teamsl">
                              <InputGroup.Text id="roleicon">
                                <img src={roleicon} alt="roleicon" />
                              </InputGroup.Text>
                              <Field className="form-control" name="productId" as="select"
                                value={formData.productId}
                                onChange={(e) => setFormData({ ...formData, productId: e.target.value })}>
                                <option>Select Extension</option>
                                {plans && plans.map((data) =>
                                  <option value={data.productId + '##' + data.name} >{data.name}</option>
                                )}
                              </Field>
                              <ErrorMessage name="productId" component="div" />
                            </InputGroup>
                          </Col>
                          <Col xs={6} className="checkbox-label " style={{ display: "flex" }} >
                            <input
                              style={{
                                width: "90px",
                                paddingLeft: "10px",
                                height: "25px",
                                display: "block",
                                cursor: "inherit"
                              }}
                              type="number"
                              id="no-of-tasks"
                              className="disable-team team_values"
                              value={formData.numberOfTasks}
                              onChange={(e) => setFormData({ ...formData, numberOfTasks: e.target.value })}
                            />
                            <span>Number of Tasks</span>
                          </Col>
                          <Col xs={6} className="checkbox-label " style={{ display: "flex" }} >
                            <input
                              style={{
                                width: "90px",
                                paddingLeft: "10px",
                                height: "25px",
                                display: "block",
                                cursor: "inherit"
                              }}
                              type="text"
                              id="features-from-prod"
                              className="disable-team team_values"
                              value={formData.featuresFromProd}
                              onChange={(e) => setFormData({ ...formData, featuresFromProd: e.target.value })}
                            />
                            <span>Features from prod?</span>
                          </Col>
                          <Col xs={6} className="checkbox-label " style={{ display: "flex" }} >
                            <input
                              style={{
                                width: "90px",
                                paddingLeft: "10px",
                                height: "25px",
                                display: "block",
                                cursor: "inherit"
                              }}
                              type="number"
                              id="no-of-drivers"
                              className="disable-team team_values"
                              value={formData.numberOfDrivers}
                              onChange={(e) => setFormData({ ...formData, numberOfDrivers: e.target.value })}
                            />
                            <span>Number of Drivers</span>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="Access-To-GomoveOne"
                              name="accessToGomoveone"
                              className="checkbox disable-team team_values"
                              checked={formData.accessToGomoveone}
                              defaultChecked={formData.accessToGomoveone}
                              onChange={() => setFormData({ ...formData, accessToGomoveone: !formData.accessToGomoveone })}
                            />
                            <label htmlFor="Access-To-GomoveOne">Access to GomoveOne</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="createAllocateRoutes"
                              name="createAllocateRoutes"
                              className="checkbox disable-team team_values"
                              checked={formData.createAllocateRoutes}
                              defaultChecked={formData.createAllocateRoutes}
                              onChange={() => setFormData({ ...formData, createAllocateRoutes: !formData.createAllocateRoutes })}
                            />
                            <label htmlFor="createAllocateRoutes">Create Allocate Routes</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="advancedRoutesOptimization"
                              name="advancedRoutesOptimization"
                              className="checkbox disable-team team_values"
                              checked={formData.advancedRoutesOptimization}
                              defaultChecked={formData.advancedRoutesOptimization}
                              onChange={() => setFormData({ ...formData, advancedRoutesOptimization: !formData.advancedRoutesOptimization })}
                            />
                            <label htmlFor="advancedRoutesOptimization">Advanced Routes Optimization</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="vehicleCapacityOptimization"
                              name="vehicleCapacityOptimization"
                              className="checkbox disable-team team_values"
                              checked={formData.vehicleCapacityOptimization}
                              defaultChecked={formData.vehicleCapacityOptimization}
                              onChange={() => setFormData({ ...formData, vehicleCapacityOptimization: !formData.vehicleCapacityOptimization })}
                            />
                            <label htmlFor="vehicleCapacityOptimization">Vehicle Capacity Optimization</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="liveTrackRouteEta"
                              name="liveTrackRouteEta"
                              className="checkbox disable-team team_values"
                              checked={formData.liveTrackRouteEta}
                              defaultChecked={formData.liveTrackRouteEta}
                              onChange={() => setFormData({ ...formData, liveTrackRouteEta: !formData.liveTrackRouteEta })}
                            />
                            <label htmlFor="liveTrackRouteEta">Live Track Route Eta</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="importSpreadSheets"
                              name="importSpreadSheets"
                              className="checkbox disable-team team_values"
                              checked={formData.importSpreadSheets}
                              defaultChecked={formData.importSpreadSheets}
                              onChange={() => setFormData({ ...formData, importSpreadSheets: !formData.importSpreadSheets })}
                            />
                            <label htmlFor="importSpreadSheets">Import SpreadSheets</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="photoAndSignaturePOD"
                              name="photoAndSignaturePOD"
                              className="checkbox disable-team team_values"
                              checked={formData.photoAndSignaturePOD}
                              defaultChecked={formData.photoAndSignaturePOD}
                              onChange={() => setFormData({ ...formData, photoAndSignaturePOD: !formData.photoAndSignaturePOD })}
                            />
                            <label htmlFor="photoAndSignaturePOD">Photo And Signature POD</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="liveChatSupport"
                              name="liveChatSupport"
                              className="checkbox disable-team team_values"
                              checked={formData.liveChatSupport}
                              defaultChecked={formData.liveChatSupport}
                              onChange={() => setFormData({ ...formData, liveChatSupport: !formData.liveChatSupport })}
                            />
                            <label htmlFor="liveChatSupport">Live Chat Support</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="everyThingInInitiate"
                              name="everyThingInInitiate"
                              className="checkbox disable-team team_values"
                              checked={formData.everyThingInInitiate}
                              defaultChecked={formData.everyThingInInitiate}
                              onChange={() => setFormData({ ...formData, everyThingInInitiate: !formData.everyThingInInitiate })}
                            />
                            <label htmlFor="everyThingInInitiate">EveryThing In Initiate</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="unlimitedTasks"
                              name="unlimitedTasks"
                              className="checkbox disable-team team_values"
                              checked={formData.unlimitedTasks}
                              defaultChecked={formData.unlimitedTasks}
                              onChange={() => setFormData({ ...formData, unlimitedTasks: !formData.unlimitedTasks })}
                            />
                            <label htmlFor="unlimitedTasks">Unlimited Tasks</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="unlimitedAgents"
                              name="unlimitedAgents"
                              className="checkbox disable-team team_values"
                              checked={formData.unlimitedAgents}
                              defaultChecked={formData.unlimitedAgents}
                              onChange={() => setFormData({ ...formData, unlimitedAgents: !formData.unlimitedAgents })}
                            />
                            <label htmlFor="unlimitedAgents">Unlimited Agents</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="smsNotificationsTriggers"
                              name="smsNotificationsTriggers"
                              className="checkbox disable-team team_values"
                              checked={formData.smsNotificationsTriggers}
                              defaultChecked={formData.smsNotificationsTriggers}
                              onChange={() => setFormData({ ...formData, smsNotificationsTriggers: !formData.smsNotificationsTriggers })}
                            />
                            <label htmlFor="smsNotificationsTriggers">Sms Notifications Triggers</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="customizeRecipientSms"
                              name="customizeRecipientSms"
                              className="checkbox disable-team team_values"
                              checked={formData.customizeRecipientSms}
                              defaultChecked={formData.customizeRecipientSms}
                              onChange={() => setFormData({ ...formData, customizeRecipientSms: !formData.customizeRecipientSms })}
                            />
                            <label htmlFor="customizeRecipientSms">Customize Recipient Sms</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="everyThingInOperate"
                              name="everyThingInOperate"
                              className="checkbox disable-team team_values"
                              checked={formData.everyThingInOperate}
                              defaultChecked={formData.everyThingInOperate}
                              onChange={() => setFormData({ ...formData, everyThingInOperate: !formData.everyThingInOperate })}
                            />
                            <label htmlFor="everyThingInOperate">EveryThing In Operate</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="smartDamageManagement"
                              name="smartDamageManagement"
                              className="checkbox disable-team team_values"
                              checked={formData.smartDamageManagement}
                              defaultChecked={formData.smartDamageManagement}
                              onChange={() => setFormData({ ...formData, smartDamageManagement: !formData.smartDamageManagement })}
                            />
                            <label htmlFor="smartDamageManagement">Smart Damage Management</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="deliveryConfirmationForm"
                              name="deliveryConfirmationForm"
                              className="checkbox disable-team team_values"
                              checked={formData.deliveryConfirmationForm}
                              defaultChecked={formData.deliveryConfirmationForm}
                              onChange={() => setFormData({ ...formData, deliveryConfirmationForm: !formData.deliveryConfirmationForm })}
                            />
                            <label htmlFor="deliveryConfirmationForm">Delivery Confirmation Form</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="linkedTask"
                              name="linkedTask"
                              className="checkbox disable-team team_values"
                              checked={formData.linkedTask}
                              defaultChecked={formData.linkedTask}
                              onChange={() => setFormData({ ...formData, linkedTask: !formData.linkedTask })}
                            />
                            <label htmlFor="linkedTask">Linked Task</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="driverChat"
                              name="driverChat"
                              className="checkbox disable-team team_values"
                              checked={formData.driverChat}
                              defaultChecked={formData.driverChat}
                              onChange={() => setFormData({ ...formData, driverChat: !formData.driverChat })}
                            />
                            <label htmlFor="driverChat">Driver Chat</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="apiIntegration"
                              name="apiIntegration"
                              className="checkbox disable-team team_values"
                              checked={formData.apiIntegration}
                              defaultChecked={formData.apiIntegration}
                              onChange={() => setFormData({ ...formData, apiIntegration: !formData.apiIntegration })}
                            />
                            <label htmlFor="apiIntegration">Api Integration</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="standardOnboarding"
                              name="standardOnboarding"
                              className="checkbox disable-team team_values"
                              checked={formData.standardOnboarding}
                              defaultChecked={formData.standardOnboarding}
                              onChange={() => setFormData({ ...formData, standardOnboarding: !formData.standardOnboarding })}
                            />
                            <label htmlFor="standardOnboarding">Standard Onboarding</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="everyThingInElevate"
                              name="everyThingInElevate"
                              className="checkbox disable-team team_values"
                              checked={formData.everyThingInElevate}
                              defaultChecked={formData.everyThingInElevate}
                              onChange={() => setFormData({ ...formData, everyThingInElevate: !formData.everyThingInElevate })}
                            />
                            <label htmlFor="everyThingInElevate">EveryThing In Elevate</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="AIPoweredRealTimeRouting"
                              name="AIPoweredRealTimeRouting"
                              className="checkbox disable-team team_values"
                              checked={formData.AIPoweredRealTimeRouting}
                              defaultChecked={formData.AIPoweredRealTimeRouting}
                              onChange={() => setFormData({ ...formData, AIPoweredRealTimeRouting: !formData.AIPoweredRealTimeRouting })}
                            />
                            <label htmlFor="AIPoweredRealTimeRouting">AI Powered Real Time Routing</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="automaticAppointmentBooking"
                              name="automaticAppointmentBooking"
                              className="checkbox disable-team team_values"
                              checked={formData.automaticAppointmentBooking}
                              defaultChecked={formData.automaticAppointmentBooking}
                              onChange={() => setFormData({ ...formData, automaticAppointmentBooking: !formData.automaticAppointmentBooking })}
                            />
                            <label htmlFor="automaticAppointmentBooking">Automatic Appointment Booking</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="AIBasedAutoAllocation"
                              name="AIBasedAutoAllocation"
                              className="checkbox disable-team team_values"
                              checked={formData.AIBasedAutoAllocation}
                              defaultChecked={formData.AIBasedAutoAllocation}
                              onChange={() => setFormData({ ...formData, AIBasedAutoAllocation: !formData.AIBasedAutoAllocation })}
                            />
                            <label htmlFor="AIBasedAutoAllocation">AI Based Auto Allocation</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="justInTimeOrderPrep"
                              name="justInTimeOrderPrep"
                              className="checkbox disable-team team_values"
                              checked={formData.justInTimeOrderPrep}
                              defaultChecked={formData.justInTimeOrderPrep}
                              onChange={() => setFormData({ ...formData, justInTimeOrderPrep: !formData.justInTimeOrderPrep })}
                            />
                            <label htmlFor="justInTimeOrderPrep">Just In Time Order Prep</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="reverseLogistics"
                              name="reverseLogistics"
                              className="checkbox disable-team team_values"
                              checked={formData.reverseLogistics}
                              defaultChecked={formData.reverseLogistics}
                              onChange={() => setFormData({ ...formData, reverseLogistics: !formData.reverseLogistics })}
                            />
                            <label htmlFor="reverseLogistics">Reverse Logistics</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="billingOnTheGo"
                              name="billingOnTheGo"
                              className="checkbox disable-team team_values"
                              checked={formData.billingOnTheGo}
                              defaultChecked={formData.billingOnTheGo}
                              onChange={() => setFormData({ ...formData, billingOnTheGo: !formData.billingOnTheGo })}
                            />
                            <label htmlFor="billingOnTheGo">Billing On The Go</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="teamSynchro"
                              name="teamSynchro"
                              className="checkbox disable-team team_values"
                              checked={formData.teamSynchro}
                              defaultChecked={formData.teamSynchro}
                              onChange={() => setFormData({ ...formData, teamSynchro: !formData.teamSynchro })}
                            />
                            <label htmlFor="teamSynchro">Team Synchro</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="premiumOnboardingAndImplementation"
                              name="premiumOnboardingAndImplementation"
                              className="checkbox disable-team team_values"
                              checked={formData.premiumOnboardingAndImplementation}
                              defaultChecked={formData.premiumOnboardingAndImplementation}
                              onChange={() => setFormData({ ...formData, premiumOnboardingAndImplementation: !formData.premiumOnboardingAndImplementation })}
                            />
                            <label htmlFor="premiumOnboardingAndImplementation">Premium Onboarding And Implementation</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="phoneAndLiveChatSupport"
                              name="phoneAndLiveChatSupport"
                              className="checkbox disable-team team_values"
                              checked={formData.phoneAndLiveChatSupport}
                              defaultChecked={formData.phoneAndLiveChatSupport}
                              onChange={() => setFormData({ ...formData, phoneAndLiveChatSupport: !formData.phoneAndLiveChatSupport })}
                            />
                            <label htmlFor="phoneAndLiveChatSupport">Phone And Live Chat Support</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="Task-creation"
                              name="taskCreationForMerchant"
                              className="checkbox disable-team team_values"
                              checked={formData.taskCreationForMerchant}
                              defaultChecked={formData.taskCreationForMerchant}
                              onChange={() => setFormData({ ...formData, taskCreationForMerchant: !formData.taskCreationForMerchant })}
                            />
                            <label htmlFor="Task-creation">Task creation & import for merchant</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              id="task-doc"
                              name="taskCreationAddDocs"
                              className="checkbox disable-team team_values"
                              checked={formData.taskCreationAddDocs}
                              defaultChecked={formData.taskCreationAddDocs}
                              onChange={() => setFormData({ ...formData, taskCreationAddDocs: !formData.taskCreationAddDocs })}
                            />
                            <label htmlFor="task-doc">Task add docs</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="taskDuration"
                              id="Task-duration"
                              className="checkbox disable-team team_values"
                              checked={formData.taskDuration}
                              defaultChecked={formData.taskDuration}
                              onChange={() => setFormData({ ...formData, taskDuration: !formData.taskDuration })}
                            />
                            <label htmlFor="Task-duration">Task duration</label>
                          </Col>
                          {/* <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="missionOptimization"
                              id="Mission-optimization"
                              className="checkbox disable-team team_values"
                              checked={formData.missionOptimization}
                              defaultChecked={formData.missionOptimization}
                              onChange={() => setFormData({ ...formData, missionOptimization: !formData.missionOptimization })}
                            />
                            <label htmlFor="Mission-optimization">Mission optimization</label>
                          </Col> */}
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="accessToMerchant"
                              id="Access-merchant"
                              className="checkbox disable-team team_values"
                              checked={formData.accessToMerchant}
                              defaultChecked={formData.accessToMerchant}
                              onChange={() => setFormData({ ...formData, accessToMerchant: !formData.accessToMerchant })}
                            />
                            <label htmlFor="Access-merchant">Access to merchant</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="accessToLibrary"
                              id="Access-library"
                              className="checkbox disable-team team_values"
                              checked={formData.accessToLibrary}
                              defaultChecked={formData.accessToLibrary}
                              onChange={() => setFormData({ ...formData, accessToLibrary: !formData.accessToLibrary })}
                            />
                            <label htmlFor="Access-library">Access to library</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="additionalHistory"
                              id="Additional-history"
                              className="checkbox disable-team team_values"
                              checked={formData.additionalHistory}
                              defaultChecked={formData.additionalHistory}
                              onChange={() => setFormData({ ...formData, additionalHistory: !formData.additionalHistory })}
                            />
                            <label htmlFor="Additional-history">Additional history & export</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="brandedTrackingPage"
                              className="checkbox disable-team team_values"
                              id="Branded-tracking"
                              checked={formData.brandedTrackingPage}
                              defaultChecked={formData.brandedTrackingPage}
                              onChange={() => setFormData({ ...formData, brandedTrackingPage: !formData.brandedTrackingPage })}
                            />
                            <label htmlFor="Branded-tracking">Branded tracking page</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="routeOptimization"
                              className="checkbox disable-team team_values"
                              id="Route-optimization"
                              checked={formData.routeOptimization}
                              defaultChecked={formData.routeOptimization}
                              onChange={() => setFormData({ ...formData, routeOptimization: !formData.routeOptimization })}
                            />
                            <label htmlFor="Route-optimization">Route optimization</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="multiMission"
                              id="Multi-Mission"
                              className="checkbox disable-team team_values"
                              checked={formData.multiMission}
                              defaultChecked={formData.multiMission}
                              onChange={() => setFormData({ ...formData, multiMission: !formData.multiMission })}
                            />
                            <label htmlFor="Multi-Mission">Multi-Mission</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="vehicleCapacityManagement"
                              id="Vehicle-Capacity"
                              className="checkbox disable-team team_values"
                              checked={formData.vehicleCapacityManagement}
                              defaultChecked={formData.vehicleCapacityManagement}
                              onChange={() => setFormData({ ...formData, vehicleCapacityManagement: !formData.vehicleCapacityManagement })}
                            />
                            <label htmlFor="Vehicle-Capacity">Vehicle Capacity Management</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="responsibiltyDischargeForm"
                              id="Responsibility-discharge"
                              className="checkbox disable-team team_values"
                              checked={formData.responsibiltyDischargeForm}
                              defaultChecked={formData.responsibiltyDischargeForm}
                              onChange={() => setFormData({ ...formData, responsibiltyDischargeForm: !formData.responsibiltyDischargeForm })}
                            />
                            <label htmlFor="Responsibility-discharge">Responsibility discharge form</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="pictureAssessment"
                              id="Picture-assessment"
                              className="checkbox disable-team team_values"
                              checked={formData.pictureAssessment}
                              defaultChecked={formData.pictureAssessment}
                              onChange={() => setFormData({ ...formData, pictureAssessment: !formData.pictureAssessment })}
                            />
                            <label htmlFor="Picture-assessment">Picture assessment</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="exportMissionPdf"
                              id="assessment-Picture"
                              className="checkbox disable-team team_values"
                              checked={formData.exportMissionPdf}
                              defaultChecked={formData.exportMissionPdf}
                              onChange={() => setFormData({ ...formData, exportMissionPdf: !formData.exportMissionPdf })}
                            />
                            <label htmlFor="assessment-Picture">Export Mission PDF</label>
                          </Col>

                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="exportTaskPdf"
                              id="export-task-pdf"
                              className="checkbox disable-team team_values"
                              checked={formData.exportTaskPdf}
                              defaultChecked={formData.exportTaskPdf}
                              onChange={() => setFormData({ ...formData, exportTaskPdf: !formData.exportTaskPdf })}
                            />
                            <label htmlFor="export-task-pdf">Export Task PDF</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="exportHistory_1"
                              id="history-export"
                              className="checkbox disable-team team_values"
                              checked={formData.exportHistory_1}
                              defaultChecked={formData.exportHistory_1}
                              onChange={() => setFormData({ ...formData, exportHistory_1: !formData.exportHistory_1 })}
                            />
                            <label htmlFor="history-export">1 months of history with export</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="exportHistory_3"
                              id="export-history"
                              className="checkbox disable-team team_values"
                              checked={formData.exportHistory_3}
                              defaultChecked={formData.exportHistory_3}
                              onChange={() => setFormData({ ...formData, exportHistory_3: !formData.exportHistory_3 })}
                            />
                            <label htmlFor="export-history">3 months of history with export</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="exportHistory_all"
                              id="time-export"
                              className="checkbox disable-team team_values"
                              checked={formData.exportHistory_all}
                              defaultChecked={formData.exportHistory_all}
                              onChange={() => setFormData({ ...formData, exportHistory_all: !formData.exportHistory_all })}
                            />
                            <label htmlFor="time-export">All time history with export</label>
                          </Col>
                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="thirdPartyDeliveryMerchant"
                              id="delivery-merchant"
                              className="checkbox disable-team team_values"
                              checked={formData.thirdPartyDeliveryMerchant}
                              defaultChecked={formData.thirdPartyDeliveryMerchant}
                              onChange={() => setFormData({ ...formData, thirdPartyDeliveryMerchant: !formData.thirdPartyDeliveryMerchant })}
                            />
                            <label htmlFor="delivery-merchant">Third-Party Delivery - Merchant</label>
                          </Col>

                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="landingPage"
                              id="landing-Page"
                              className="checkbox disable-team team_values"
                              checked={formData.landingPage}
                              defaultChecked={formData.landingPage}
                              onChange={() => setFormData({ ...formData, landingPage: !formData.landingPage })}
                            />
                            <label htmlFor="landing-Page">Landing Page</label>
                          </Col>

                          <Col xs={6} className="checkbox-label">
                            <input
                              type="checkbox"
                              name="plannerOperations"
                              id="planner-operations"
                              className="checkbox disable-team team_values"
                              checked={formData.plannerOperations}
                              defaultChecked={formData.plannerOperations}
                              onChange={() => setFormData({ ...formData, plannerOperations: !formData.plannerOperations })}
                            />
                            <label htmlFor="planner-operations">Planner Operations</label>
                          </Col>
                        </Row>
                        <div className="ct-btn">
                          <Button
                            className="cncle-btn"
                            onClick={cancelhandler}
                          >
                            Cancel
                          </Button>
                          <Button type="submit" className="ctaskbtn">
                            Let’s Go
                          </Button>
                        </div>
                      </FormF>
                    );
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                ></Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* create customer popup end */}
      {/* </ContentLayout> */}
      <ToastContainer
        style={{ color: "black" }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Extensions;
