import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
} from "react-bootstrap";
import industryicon from "../../assets/image/industryicon.png";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import * as Yup from "yup";
import AuthService from "../../services/auth.service";
import userService from "../../services/user.service";
import { MdOutlineCheck } from "react-icons/md";
import {
  successAlert,
  errorAlert,
  infoAlert,
} from "../../services/toast.service";

const Planinfo = ({ step, setStep, formData, setformData }) => {

  const [state, setState] = useState(formData.state ? formData.state : "");
  const [plans, setPlans] = useState([]);
  const [response, setResponse] = useState([]);
  const [productId, setProductId] = useState('');
  const [planName, setPlanName] = useState('');
  const [priceId, setPriceId] = useState('');
  const [price, setPrice] = useState(0);
  const [interval, setInterval] = useState('');

  const continueHandler = () => {
    setStep(step + 1);
  };
  const backHandler = () => {
    setStep(step - 1);
  };


  const [selectedButton, setSelectedButton] = useState("year")

  const getPlanData = async () => {
    const response = await userService.getStripePlans();
    console.log("responseresponse", response)
    setResponse(response)
  }


  const getPlans = async () => {

    if (response.data && response.data.plansPrices) {

      // const data = response.data.plansPrices.filter((data) => data.metadata && data.metadata.planType && data.metadata.planType === 'signup' && data.price.recurring.interval === selectedButton)
      const data = response.data.plansPrices.filter((data) => data.metadata && data.metadata.planType && data.metadata.planType === 'signup')

      let newData = [];
      for (let product of data) {
        for (let plan of product.price) {
          let obj = { ...product }
          obj.plan = plan
          newData.push(obj)
        }
      }
      console.log("newData", newData)
      newData = newData.filter((data) => { return data.plan.recurring.interval === selectedButton })
      const plan = newData.map((planData, index) => {
        planData.price = planData.plan;
        if (index === 0) {
          setProductId(planData.id);
          setPriceId(planData.price && planData.price.id ? planData.price.id : '')
          setPlanName(planData.name)
          setPrice(planData.price && planData.price.unit_amount ? planData.price.unit_amount / 100 : 0)
          setInterval(planData.price && planData.price.recurring && planData.price.recurring.interval ? planData.price.recurring.interval : 'Not defined')
        }
        return {
          name: planData.name,
          price: planData.price && planData.price.unit_amount ? planData.price.unit_amount / 100 : 0,
          priceId: planData.price && planData.price.id ? planData.price.id : '',
          description: planData.description,
          status: planData.active,
          productId: planData.id,
          isChecked: false,
          isEnabled: false,
          paymentStatus: 0,
          interval: planData.price && planData.price.recurring && planData.price.recurring.interval ? planData.price.recurring.interval : 'Not defined',
          imgUrl: planData.images && planData.images.length > 0 ? planData.images[0] : '',
          _id: undefined,
        }
      })

      setPlans(plan)
    } else {
      errorAlert(
        response.data.message ? response.data.message : "Something went wrong"
      );
    }
  };

  useEffect(() => {
    getPlans();
  }, [selectedButton, response]);


  useEffect(() => {
    getPlanData();
  }, []);

  const validationSchema = Yup.object().shape({
    productId: Yup.string().required("please select plan"),
  });

  const handleSubmit = () => {
    setformData((prev) => {
      return { ...prev, productId: productId, priceId: priceId, planName: planName, price: price, interval: interval };
    });
    continueHandler();
  };

  const handleChoose = (productId, priceId, name, price, interval) => {
    setProductId(productId);
    setPriceId(priceId)
    setPlanName(name)
    setPrice(price)
    setInterval(interval)
  }


  const filterPlanHandler = (button) => {
    setSelectedButton(button)
  }

  return (
    <div>
      <div className="signupinfo-form">
        <h1 className="heading">Create your Gomove account</h1>
        <div className="signup-form-box business-info planinfo">
          {/* <h2>Plans info</h2> */}
          <div className="planinfo-buttons">
            <button className={selectedButton === "year" ? `selected` : ""} onClick={() => { filterPlanHandler("year") }}>Yearly</button>
            <button className={selectedButton === "month" ? `selected` : ""} onClick={() => { filterPlanHandler("month") }}>Monthly</button>
          </div>
          <form onSubmit={handleSubmit}  >
            <>
              <Row>
                <section className="plancard">
                  <div className="plan-card-wrapper">

                    {plans && plans.length > 0 && plans.map((data) =>
                      <div className={`card__content ${data.productId === productId ? "selected" : ""}`} onClick={() => handleChoose(data.productId, data.priceId, data.name, data.price, data.interval)}>
                        <div className="card__pricing">
                          <div className="card__pricing-number">
                            <span className="card__pricing-symbol">$</span>{data.price}
                          </div>
                          <span className="card__pricing-month">/{data.interval}</span>
                        </div>

                        <header className="card__header">
                          {/* <div className="card__header-circle">
                            <img
                              src={data.imgUrl}
                              alt="img"
                              className="card__header-img"
                            />
                          </div> */}

                          <h1 className="card__header-title">{data.name}</h1>
                        </header>

                        <ul className="card__list">
                          <li className="card__list-item">
                            <MdOutlineCheck/>
                            <p className="card__list-description">
                              {data.description}
                            </p>
                          </li>

                        </ul>

                      </div>
                    )}


                  </div>
                </section>

              </Row>
              <div className="bsbtn">
                <Row>
                  <Col xs={6}>
                    <div className="submitbtn">
                      <Button onClick={backHandler}>Back</Button>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="submitbtn">
                      <Button type="submit">Continue</Button>
                      <p>
                        This site is protected by reCAPTCHA and the Google
                        Privacy Policy.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Planinfo;
