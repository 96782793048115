import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import filterremoveicon from "../../assets/image/filter-removeicon.png";
import interview from "../../assets/image/question_icon.png";
import Swal from "sweetalert2";
import builderService from "../../services/builder.service";
import userService from "../../services/user.service";
import {
  NavDropdown,
} from "react-bootstrap";
import "./Settings.scss";
import moment from "moment";
import { getProfile } from "../../slices/auth";
import { CountryRegionData } from "react-country-region-selector";
import StripePay from "./StripePay";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import mastercard from "../../assets/image/Credit_asset/mastercard.png";
import amex from "../../assets/image/Credit_asset/amex.png";
import dinersclub from "../../assets/image/Credit_asset/dinersclub.png";
import discover from "../../assets/image/Credit_asset/discover.png";
import jcb from "../../assets/image/Credit_asset/jcb.png";
import visa from "../../assets/image/Credit_asset/visa.png";
import closeicon from "../../assets/image/close-icon.png";

import ModalPopUp from "../../components/UI/Modal/Modal";
import authService from "../../services/auth.service";
import { errorAlert } from "../../services/toast.service";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

let initialValues = {
  interval: "Monthly",
  intervalType: "month",
  teamSize: 1,
  selectedPrice: "",
  price: 0,
  step: 1,
  currency: "USD"
}

let cancellationInitialValues = {
  reason: "",
  comments: ""
}
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

const stripeTestPromise = loadStripe(PUBLIC_KEY);
const Subscription = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [builderData, setBuilderData] = useState([])
  const [allExtensions, setAllExtensions] = useState([])
  const [userSignupSubscriptionData, setUserSignupSubscriptionData] = useState([{}])
  const [showTeamSizeModal, setShowTeamSizeModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [planResponse, setPlanResponse] = useState(null)
  const [ratesResponse, setRatesResponse] = useState([])
  const [formData, setFormData] = useState(initialValues)
  const userData = useSelector((state) => state.auth);
  const [paymentMethods, setPaymentMehods] = useState([])
  const [agentCount, setAgentCount] = React.useState(0);
  const [error, setError] = React.useState("");

  const [createCustomer, setcreateCustomer] = useState(false)
  const [showAddCard, setShowAddCard] = useState(false)
  const createCustomerToggle = () => {
    setcreateCustomer(!createCustomer);
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const getPaymentMethods = async () => {
    const response = await authService.getPaymentMethods({});
    if (response.status == 200) {
      console.log(response.data.paymentMethods, "response-data")
      setPaymentMehods(response.data.paymentMethods || []);
    }
  }


  const getStripePlans = async () => {
    let country = "US"
    console.log("userData.country", userData.user.country)
    let countryData = CountryRegionData.find(i => i[0] === userData.user.country);
    if (countryData && countryData.length) {
      country = countryData[1]
    }
    const response = await userService.getStripePlans({ country: country });
    if (Number(response.status) === 200) {
      if (response.data && response.data.plansPrices) {
        let plans = response.data.plansPrices.find(i => {
          return i.metadata?.type === "PER_AGENT_PRODUCT"
        })
        setPlanResponse(plans);
      }
    }
  }


  const getStripeRates = async () => {
    const response = await userService.getStripeRates();
    if (Number(response.status) === 200) {
      if (response.data && response.data.rates) {
        setRatesResponse(response.data.rates);
      }
    }
  }

  useEffect(() => {
    getBuilder();
    getStripePlans();
    getAgentCount();
    // getStripeRates();
  }, []);

  useEffect(() => {
    let user = []
    user.push(userData.user)
    setUserSignupSubscriptionData(user)
  }, [userData])
  const getBuilder = async () => {
    const response = await builderService.getUserBuilder({});
    if (response.status == 200) {
      console.log(response.data, "response-data")
      setBuilderData(response.data || []);
      let extensions = [];
      if (response.data.length) {
        for (let builder of response.data) {
          extensions = [...extensions, ...builder.extensions]
        }
      }
      setAllExtensions(extensions)
    }
  }

  function dateissued(cell, row) {
    const data = builderData[0]?.subscription.current_period_end ?
      <span>{moment(builderData[0].subscription.current_period_end * 1000).format("MMM DD, YYYY")}</span>
      : <span>No Data</span>

    return data ? data : <span>No Data</span>
  }

  function planName() {
    return (
      <span>{capitalize(builderData[0]?.planName) || "GOMOVE ONE"}</span>
    )
  }


  function planCurrency() {
    return (
      <span>{formData.currency}</span>
    )
  }

  function capitalize(input) {
    return input ? input.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') : "";
  }

  const cancelHandler = async (row, type) => {
    const subscriptionId = row.subscription && row.subscription.id
    Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel this subscription!",
      icon: "warning",
      showCancelButton: true,
      color: "#ffffff",
      background: '#151719',
      confirmButtonColor: "#1A1D1F",
      cancelButtonColor: "#1A1D1F",
      confirmButtonText: "Yes, cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await userService.deleteSubscripton({ subscriptionId: subscriptionId || '', subscriptionType: type });
        if (response) {
          console.log("delete response ::::", response);
          Swal.fire(
            "Unsubscribed!",
            "Unsubscribed Successfully !",
            "success"
          );

          dispatch(getProfile({ userId: userData.user.id }))
          setTimeout(() => {
            history.push("/Business")
          }, 2000)
          getBuilder();
        }
      }
    });
  };

  const ActionAddon = (cell, row) => {
    return (
      <>
        {row.subscription ?
          (!row.isSubscriptionCancel ?
            <span>
              <span className="filter-edit">
                <NavDropdown
                  title={<img src={filterremoveicon} />}
                  id="nav-dropdown"
                >
                  <NavDropdown.Item
                    eventKey="4.2"
                    onClick={() => {
                      cancelHandler(row, 'extension');
                    }}
                  >
                    Cancel subscription
                  </NavDropdown.Item>
                </NavDropdown>
              </span>
            </span>
            : <span>Cancelled</span>
          )
          : <span>No Action</span>
        }
      </>
    );
  };

  const PlanInterval = () => {
    if (builderData[0]?.subscription?.plan?.interval) {
      if (builderData[0].subscription?.plan?.interval === "month") {
        return "Monthly"
      }
      else {
        return "Yearly"
      }
    }
    return ""
  }
  const PlanAmount = () => {
    if (builderData[0]?.subscription?.plan?.amount) {
      return (builderData[0].subscription?.plan?.amount / 100) * builderData[0].subscription.quantity
    }
    return 0
  }

  const agentValues = () => {
    let options = []
    for (let i = 1; i <= 20; i++) {
      options.push(<option key={i} value={`${i}`}>{`${i} agents`}</option>)
    }
    return options
  }

  useEffect(() => {
    selectedPrice()
  }, [planResponse, showTeamSizeModal])

  const selectedPrice = (interval) => {
    let selectedInterval = interval ? interval : formData.interval
    if (selectedInterval === "Monthly") {
      let price = planResponse?.price.find(i => i.recurring.interval === "month" && i.metadata?.type === "PER_AGENT_PRICE");
      if (price) {
        setFormData((prev) => {
          return {
            ...prev,
            price: price.unit_amount,
            selectedPrice: price.id,
            currency: price.currency.toUpperCase()
          }
        })
      }
    }
    else {
      let price = planResponse?.price.find(i => i.recurring.interval !== "month" && i.metadata?.type === "PER_AGENT_PRICE");
      if (price) {
        setFormData((prev) => {
          return {
            ...prev,
            price: price.unit_amount,
            selectedPrice: price.id,
            currency: price.currency.toUpperCase()
          }
        })
      }
    }
  }

  const calculatedPrice = () => {
    return (formData.price / 100) * formData.teamSize
  }

  const calculatedMonthlyPrice = () => {
    let price = formData.price / 100
    if (formData.interval.toLowerCase() === "annualy") {
      price = price / 12;
    }
    return (price) * formData.teamSize
  }

  const calculatedTaxPrice = () => {
    let price = formData.subPrice
    console.log(price)
    let taxPrice = 0;
    let taxPercentage = 0;
    let lineData = formData.subPrice.lines.data;
    for (let data in lineData) {
      if (Number(data) < lineData.length - 1) {
        for (let tax of lineData[data].tax_amounts) {
          taxPrice += tax.amount
        }
      }
    }
    let Balanceprice = calculatedBalancePrice()
    console.log("taxes", taxPrice, Balanceprice, (taxPrice / 100) / Balanceprice)
    taxPercentage = ((taxPrice / 100) / Balanceprice) * 100
    return { taxPrice, taxPercentage }

  }

  const calculatedBalancePrice = () => {
    let finalPrice = 0;
    if (formData.subPrice && formData.subPrice?.lines) {
      finalPrice = formData.subPrice.lines.data.reduce((prev, curr, index) => {
        if (Number(index) < formData.subPrice.lines.data.length - 1) {
          return prev + curr.amount
        }
        return prev
      }, 0)
    }
    if (finalPrice > 0) {
      return finalPrice / 100
    }
    else {
      return calculatedPrice()
    }
  }

  const calculatedTotalPrice = () => {
    let finalPrice = calculatedBalancePrice();
    let tax = calculatedTaxPrice().taxPrice;
    return finalPrice + (tax / 100)
  }

  const handleSubmitStep2 = () => {
    setFormData((prev) => {
      return {
        ...prev,
        step: 3
      }
    })
  }

  const handleSubmitStep3 = async () => {
    let apiData = {
      selectedPrice: formData.selectedPrice,
      teamSize: formData.teamSize,
      paymentMethod: paymentMethods[0].id
    }
    const response = await authService.updateSubscription(apiData)
    if (response && response.data.success) {
      setFormData((prev) => {
        return {
          ...prev,
          step: 1
        }
      })
      dispatch(getProfile({ userId: userData.user.id }));
      setShowAddCard(false)
      setShowTeamSizeModal(false)
    }
    else {
      errorAlert("Something went wrong!!")
    }
  }

  const handleSubmitStep1 = async () => {
    let apiData = {
      selectedPrice: formData.selectedPrice,
      teamSize: formData.teamSize
    }
    let response = await authService.calculateSubscriptionPrice(apiData);
    if (response && response.data) {
      let newSub = false;
      // if(!response.data.result){
      //   newSub = true;
      // }
      setFormData((prev) => {
        return {
          ...prev,
          step: 2,
          subPrice: response.data.result,
          newSub: newSub
        }
      })
    }
  }

  const getAgentCount = async () => {
    const response = await userService.getUserCount({ role: 5 });
    if (response.status == 200) {
      setAgentCount(response.data.result || 0);
    }
    else {
      setAgentCount(0)
    }
  };


  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function planPrice() {
    let price = formData.price / 100;
    if (formData.interval.toLowerCase() === "annualy") {
      price = price / 12
    }
    return price.toFixed(2)
  }

  let cards = {
    "amex": amex,
    "diners": dinersclub,
    "discover": discover,
    "mastercard": mastercard,
    "visa": visa,
    "jcb": jcb
  }
  const stepWiseDiv = () => {
    if (formData?.step === 1) {
      return (<div className="subscription-modal">
        <div className="top-header">
          <span className={`discount-msg ${formData?.interval && formData?.interval === "Annualy" ? "selected" : ""}`} onClick={() => {
            let interval = "Annualy"
            let intervalType = "month"
            setFormData((prev) => {
              return {
                ...prev,
                interval: interval,
                intervalType
              }
            })
            selectedPrice(interval)
          }}>
            Yearly save 18%
          </span>
          <span className={`interval ${formData?.interval && formData?.interval === "Monthly" ? "selected" : ""}`} onClick={() => {
            let interval = "Monthly"
            let intervalType = "month"
            setFormData((prev) => {
              return {
                ...prev,
                interval: interval,
                intervalType
              }
            })
            selectedPrice(interval)
          }}>
            {"Monthly"}
          </span>
        </div>
        <div className="middle-header">
          <h5>Choose a team size</h5>
        </div>
        <div className="agent-selection-div">
          <div>
            <form>
              <select id="agents" name="agents" onChange={(e) => {
                if (Number(e.target.value) < agentCount) {
                  setError(`Please remove ${agentCount - Number(e.target.value)} agents from the list to continue.`)
                }
                else {
                  setFormData((prev) => {
                    return {
                      ...prev,
                      teamSize: e.target.value
                    }
                  })
                  setError("")
                }
              }}>
                {agentValues()}
              </select>
            </form>
          </div>
          <div className="agent-price">
            <span className="price">${formData ? planPrice() : 0}</span>
            <span>agent/{formData.intervalType}</span>
          </div>
        </div>
        <div className="amount-value-div">
          <span className="amount">${calculatedMonthlyPrice().toFixed(2)} {planCurrency()} / {formData.intervalType}</span>
          <span className="interval-msg">Billed {formData.interval}</span>
        </div>
        {error && error.length > 0 && <span style={{ color: "red" }}>{error}</span>}
        <div className="buttons">
          <button type="submit" className="button" onClick={() => { setShowTeamSizeModal(false); setFormData(initialValues) }}>Cancel</button>
          <button type="submit" className="button" disabled={formData.price > 0 || (error && error.length > 0) ? false : true} onClick={() => {
            handleSubmitStep1()
          }}>Checkout</button>
        </div>
      </div>)
    }
    if (formData?.step === 2) {
      return (<div className="subscription-modal">
        <div className="middle-header">
          <h5>Your new plan</h5>
        </div>
        <div className="plan-details">
          <div className="plan-breakout">
            <div className="left">
              <span>{formData.teamSize} agents</span>
            </div>
            <div className="right">
              <span>${calculatedPrice().toFixed(2)} {planCurrency()}</span>
              <span className="alternate-line">Billed {formData.interval}</span>
            </div>
          </div>
          <div className="plan-breakout">
            <div className="left">
              <span>Balance to pay today</span>
              <span className="alternate-line">from your current plan</span>
            </div>
            <div className="right">
              <span>${calculatedBalancePrice().toFixed(2)} {planCurrency()}</span>
            </div>
          </div>
          <div className="plan-breakout">
            <div className="left">
              <span>Tax</span>
              <span className="alternate-line">{(calculatedTaxPrice().taxPercentage).toFixed(2)}%</span>
            </div>
            <div className="right">
              {/* <span>${calculatedPrice().toFixed(2)} {formData.currency}</span> */}
              <span>${((calculatedTaxPrice().taxPrice)/100).toFixed(2)} {planCurrency()}</span>
            </div>
          </div>
        </div>
        <div className="plan-details-final">
          <div className="plan-breakout">
            <div className="left">
              <span>Total</span>
              <span className="alternate-line">Billed immediately</span>
            </div>
            <div className="right">
              <span>${calculatedTotalPrice().toFixed(2)} {planCurrency()}</span>
            </div>
          </div>
        </div>
        <div className="buttons">
          <button type="submit" className="button" onClick={() => {
            setFormData((prev) => {
              return {
                ...prev,
                step: 1
              }
            })
          }}>Back</button>
          <button type="submit" className="button" disabled={formData.price > 0 ? false : true} onClick={() => {
            handleSubmitStep2()
          }}>Submit</button>
        </div>
      </div>)
    }
    else {
      if (paymentMethods && paymentMethods.length) {
        if (showAddCard) {
          return (<div className="subscription-modal">
            <div className="middle-header">
              <h5>Add Payment Method</h5>
            </div>
            <Elements stripe={stripeTestPromise}>
              <StripePay apiData={formData} getPaymentMethods={getPaymentMethods} createCustomerToggle={createCustomerToggle} setShowAddCard={setShowAddCard} showBack={true} setFormData={setFormData}
              />
            </Elements>
          </div>)
        }
        else {
          return (<div className="subscription-modal">
            <div className="middle-header">
              <h5>Payment Method</h5>
            </div>
            <div className="payment-methods">
              {paymentMethods && paymentMethods.length ? <div className="card-details">
                <span className="carddetails">{paymentMethods[0].card ? capitalizeFirstLetter(paymentMethods[0].card.funding) : ""} {paymentMethods[0].type ? capitalizeFirstLetter(paymentMethods[0].type) : "Card"}</span>
                <span className="carddetails"><img className="card-image" src={cards[paymentMethods[0].card.brand] ? cards[paymentMethods[0].card.brand] : mastercard} alt="card" />{paymentMethods[0].card ? paymentMethods[0].card.last4.padStart(8, "*") : ""}</span>
                <button className="change-payment-method" onClick={() => {
                  setFormData((prev) => {
                    return {
                      ...prev,
                      type: "CHANGE_PAYMENT_METHOD",
                      detachPaymentMethod: paymentMethods[0].id
                    }
                  })
                  setShowAddCard(true)
                }}>Change Payment Method</button>
              </div> : <div>
                <button className="add-payment-method" onClick={() => {
                  setFormData((prev) => {
                    return {
                      ...prev,
                      type: "CHANGE_PAYMENT_METHOD"
                    }
                  })
                  setShowAddCard(true)
                }}>Add Payment Method</button>
              </div>}
            </div>
            <div className="buttons">
              <button type="submit" className="button" onClick={() => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    step: 2
                  }
                })
              }}>Back</button>
              <button type="submit" className="button" disabled={formData.price > 0 ? false : true} onClick={() => {
                handleSubmitStep3()
              }}>Submit</button>
            </div>
          </div>
          )
        }
      }
      else {
        return (<div className="subscription-modal">
          <div className="middle-header">
            <h5>Add Payment Method</h5>
          </div>
          <Elements stripe={stripeTestPromise}>
            <StripePay apiData={formData} getPaymentMethods={getPaymentMethods} createCustomerToggle={createCustomerToggle} setShowAddCard={setShowAddCard} showBack={true} setFormData={setFormData}
            />
          </Elements>
        </div>)
      }
    }
  }

  const cancelHandle = () => {
    setShowAddCard(false);
    setFormData((prev) => {
      return {
        ...prev,
        step: 1
      }
    });
    setShowTeamSizeModal(false)
  }

  const Modal = () => {
    return (<ModalPopUp show={showTeamSizeModal} updateState={setShowTeamSizeModal} background={"#ffffff"} textColor={"000000"} cancelHandle={cancelHandle} >
      {stepWiseDiv()}
    </ModalPopUp >)
  }

  const reasonOptions = [{
    value: "TEAM_NOT_ADAPTING",
    label: "My team is not adapting",
  },
  {
    value: "CLOSING_COMPANY",
    label: "Closing company/Closing project/Downsizing",
  },
  {
    value: "MISSING_FEATURES",
    label: "Missing features"
  },
  {
    value: "NOT_VALUE_FOR_PRICE",
    label: "I don't see enough value for the price"
  },
  {
    value: "PRICE_TOO_HIGH",
    label: "Price is too high to afford it"
  },
  {
    value: "ALREADY_PAYING",
    label: "Already paying for another account"
  },
  {
    value: "OTHER",
    label: "Other"
  }
  ]

  const validationSchema = Yup.object().shape({
    reason: Yup.string().required("Select cancellation reason!"),
    // password: Yup.string().required("Password is required!"),
  });

  const onSubmit = async (values) => {
    let apiData = {
      reason: reasonOptions.find(i => i.value === values.reason),
      comments: values.comments
    }

    let response = await authService.cancelSubscription(apiData)
    if (response) {
      setShowCancelModal(false);
      dispatch(getProfile({ userId: userData.user.id }))
    }
  }

  const cancelModal = () => {
    return (<ModalPopUp show={showCancelModal} updateState={setShowCancelModal} background={"#ffffff"} textColor={"000000"} cancelHandle={cancelHandle} >
      <div className="cancel-modal">
        <span className="header">
          It break our hearts to see you leave
        </span>
        <span className="small-header">
          We are eager to improve our product and would appreciate you feedback
        </span>
        <Formik
          initialValues={cancellationInitialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >{({
          setFieldValue,
          setFieldTouched,
          values,
          resetForm,
          errors,
          touched,
        }) => (
          <Form>
            <div className="cancellation-reason">
              <label htmlFor="reason">Why are you cancelling?*</label>
              <Field as="select" id="reason" name="reason">
                <option value="" label="Select a reason" />
                {reasonOptions.map((option) => (
                  <option key={option.value} value={option.value} label={option.label} />
                ))}
              </Field>
              <ErrorMessage name="reason" component="div" className="error" />
            </div>

            <div className="cancellation-comment">
              <label htmlFor="comments">Please provide some details</label>
              <Field as="textarea" id="comments" name="comments" />
              <ErrorMessage name="comments" component="div" className="error" />
            </div>

            <div className="cancel-buttons">
              <button className="submit-button" onClick={() => { setShowCancelModal(false) }}>Keep account</button>
              <button type="submit" className="cancel-button">Cancel</button>
            </div>
          </Form>)}
        </Formik>
      </div>
    </ModalPopUp >)
  }

  return (
    <div className="subscription-field">
      <div className="sb-heading-plan">
        <h1>Plan Details</h1>
        {/* <span><Link to="/Business">Change plan</Link></span> */}
      </div>
      <div className="sb-plan-details">
        <div className="sb-plan-detail">
          <div className="sb-plan">
            <div className="sb-plan-sub-div">
              <span className="heading">Current Plan</span>
              <span>{planName() || "GOMOVE ONE"}</span>
            </div>
            <div className="sb-plan-sub-div">
              <span className="heading">Agents</span>
              <span>{userData.user.teamSize}</span>
            </div>
            <div className="sb-plan-sub-div">
              <span className="heading">Period</span>
              <span>{PlanInterval()}</span>
            </div>
          </div>
          <div className="sb-plan-renewal">
            <div className="sb-plan-sub-div-renewal">
              <span className="heading">Renewal Amount</span>
              <span>${PlanAmount()} {planCurrency()}</span>
              <span className="heading">{dateissued()}</span>
            </div>
          </div>
        </div>

        <div className="sb-change-team">
          <span onClick={() => { setShowTeamSizeModal(true) }}>Change team size</span>
        </div>
      </div>
      <div className="sb-heading-plan">
        <h1>Need help?</h1>
        {/* <span><Link to="/Business">Change plan</Link></span> */}
      </div>
      <div className="sb-need-help-main">
        <div className="questions-main">
          <div className="image">
            <img src={interview} alt="qusetions" />
          </div>
          <div className="right">
            <h4>
              Questions?
            </h4>
            <span>
              For more information about billing and pricing, visit <a href="#">Gomove Academy</a>
            </span>
          </div>
        </div>
        <div className="contact-support-main">
          <h5>
            Contact support
          </h5>
          <span>
            Our support team is here to help with any question you may have!
          </span>
          <div className="buttons">
            <button type="submit" className="button" onClick={() => { }}>Contact support</button>
          </div>
          <span className="cancel-account" onClick={() => { setShowCancelModal(true) }} >Cancel my account</span>
        </div>
      </div>
      {showTeamSizeModal && Modal()}
      {showCancelModal && cancelModal()}
    </div>
  );
}

export default Subscription