import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "users/";


const getUserList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser && currentUser.role !== 1) params.userId = currentUser.id
    if (!params.searchText) delete params.searchText
    return axios.get(API_URL, { headers: authHeader(), params: { limit: 10, ...params, role: 3 } });
};

const getSubAdminList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    if (currentUser.role === 1) delete params.userId;
    if (!params.searchText) delete params.searchText
    return axios.get(API_URL, { headers: authHeader(), params: { limit: 10, ...params } });
};

const getMerchantList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));

    if (currentUser && currentUser.role !== 1) params.userId = currentUser.id
    if (!params.searchText) delete params.searchText
    return axios.get(API_URL, { headers: authHeader(), params: { limit: 10, ...params, role: 4 } });
};
const getAgentList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser && currentUser.role !== 1) params.userId = currentUser.id
    if (!params.searchText) delete params.searchText
    return axios.get(API_URL, { headers: authHeader(), params: { limit: 10, ...params, role: 5 } });
};

const getMemberList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser && currentUser.role !== 1) params.userId = currentUser.id
    if (!params.searchText) delete params.searchText
    return axios.get(API_URL, { headers: authHeader(), params: { limit: 10, ...params } });
};

const getUsers = (params) => {
    if (params.roleBy && params.roleBy === 1) {
        return axios.get(`${API_URL}/all`, { headers: authHeader(), params });
    } else {
        const currentUser = JSON.parse(localStorage.getItem('user'));
        if (currentUser) params.userId = currentUser.id
        return axios.get(`${API_URL}/all`, { headers: authHeader(), params });
    }
};

const createUser = (data) => {
    data = clean(data)
    return axios.post(API_URL, { ...data, role: 3 }, { headers: authHeader() });
};
const createSubAdmin = (data) => {
    data = clean(data)
    return axios.post(API_URL, { ...data }, { headers: authHeader() });
};
const createAgent = (data) => {
    data = clean(data)
    return axios.post(API_URL, { ...data, role: 5 }, { headers: authHeader() });
};

const createMember = (data) => {
    data = clean(data)
    return axios.post(API_URL, { ...data }, { headers: authHeader() });
};
const createMerchant = (data) => {
    data = clean(data)
    return axios.post(API_URL, { ...data, role: 4 }, { headers: authHeader() });
};

const editUser = (data) => {
    data = clean(data)
    // delete data.password
    return axios.patch(`${API_URL}/${data.id}`, data, { headers: authHeader() });
};
const deleteUser = (id) => {
    return axios.delete(`${API_URL}/${id}`, { headers: authHeader() });
};
const blockManyUsers = (userIds) => {
    return axios.patch(API_URL + 'multipleBlock', { userIds }, { headers: authHeader() });

};

const deleteManyUsers = (userIds) => {
    const headerObj = { data: { userIds }, headers: authHeader() }
    return axios.delete(API_URL + 'multipleDelete', headerObj);

};

function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
    }
    return obj
}

const createUserSubscripton = (data) => {
    data = clean(data)
    return axios.post(`${API_URL}/extension/subscription`, { ...data }, { headers: authHeader() });
};

const deleteSubscripton = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    return axios.patch(`${API_URL}/delete-subscription`, params, { headers: authHeader() });
};

const getStripePlans = (params) => {
    return axios.get(`${API_URL}stripe-plans-final`, { params, headers: authHeader() });
};

const getStripeRates = (params) => {
    return axios.get(`${API_URL}stripe-plans-rates`, { params, headers: authHeader() });
};

const getStripeTransactions = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    return axios.get(`${API_URL}/stripe-transactions`, { headers: authHeader(), params });
};
const getUserExtensions = (id) => {
    return axios.get(`${API_URL}extensions/${id}`, { headers: authHeader() });
};
const getUserCount = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    return axios.get(`${API_URL}user/count`, { headers: authHeader(), params });
}

const userService = {
    getUserList,
    createUser,
    editUser,
    deleteUser,
    deleteManyUsers,
    blockManyUsers,
    getMerchantList,
    getAgentList,
    createAgent,
    createMerchant,
    createSubAdmin,
    getSubAdminList,
    createUserSubscripton,
    getUsers,
    getStripePlans,
    deleteSubscripton,
    getStripeTransactions,
    getUserExtensions,
    getUserCount,
    createMember,
    getMemberList,
    getStripeRates
};

export default userService