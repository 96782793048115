// TODO: Resolve lint issues
/* eslint-disable */
import {
    put,
    takeLatest,
    all,
    call,
} from 'redux-saga/effects'

/********************* ACTION TYPE ********************* */
import { BLOCK_MULTIPLE_HUB_API, CREATE_HUB_API, DELETE_MANY_HUB_API, DELETE_HUB_API, EDIT_HUB_API, GET_ALL_HUB_LIST, GET_HUB_LIST } from './actionTypes';

/*************************** ACTIONS *******************/
import { saveHubList } from './actions';
import { apiSagaHandler } from '../network/saga';
import { errorReducerHandler } from '../alert/actions'
import { showWarning } from '../alert/actions';
import { clean } from '../../helpers/helperFunctions';
const API_URL = "hub/"
/************************ USER SAGA *******************************/
function* saga() {
    yield all([
        call(getHubListApi),
        call(createHubApi),
        call(editHubApi),
        call(deleteHubApi),
        call(blockHubApi),
        call(deleteManyHubApi),
        call(getAllHubApi),
    ]);
}

function* getHubListApi() {
    yield takeLatest(GET_HUB_LIST, getHubListHandler)
}

function* createHubApi() {
    yield takeLatest(CREATE_HUB_API, createHubHandler)
}

function* editHubApi() {
    yield takeLatest(EDIT_HUB_API, editHubHandler)
}

function* deleteHubApi() {
    yield takeLatest(DELETE_HUB_API, deleteHubHandler)
}

function* blockHubApi() {
    yield takeLatest(BLOCK_MULTIPLE_HUB_API, blockHubHandler)
}

function* deleteManyHubApi() {
    yield takeLatest(DELETE_MANY_HUB_API, deleteManyHubHandler)
}

function* getAllHubApi() {
    yield takeLatest(GET_ALL_HUB_LIST, getAllHubListHandler)
}

function* getAllHubListHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}all`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* getHubListHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield put(saveHubList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* createHubHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.createApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        yield put(saveHubList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* deleteHubHandler(data) {
    console.log("data?.data", data?.data)
    let { payload, resCall, listPayload } = data?.data;
    let config = {
        "endpoint": `${API_URL}/${payload.id}`,
        "auth": true,
        "method": "DELETE",
        "localApi": false,
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield getHubListHandler({ data: { payload: listPayload } })
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}


function* deleteManyHubHandler(data) {
    let { payload, resCall, listPayload } = data?.data;
    let config = {
        "endpoint": `${API_URL}multipleDelete`,
        "auth": true,
        "method": "DELETE",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield getHubListHandler({ data: { payload: listPayload } })
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* editHubHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}/${payload.id}`,
        "auth": true,
        "method": "PATCH",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.editApi = true;
        yield put(saveHubList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}


function* blockHubHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}multipleBlock`,
        "auth": true,
        "method": "PATCH",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}


export default saga;
