import {
  Card,
  Row,
  Col,
  Form
} from "react-bootstrap";

const RegionalSettingTab = (props) => {
  let type = props.settingTypes.OPERATIONS;
  let subType = props.settingSubType.REGIONAL_SETTINGS
  if (props.userData && props.userData.role === 1 && !props.userData.subAdmin) {
    type = props.settingTypes.OPERATIONS
    subType = props.settingSubType.COMMON_REGIONAL_SETTINGS
  }
  let roleBasedDisabled = false;
  if (props.userData && props.userData.userId && !props.userData.subAdmin && props.userData.userRole !== "admin") {
    roleBasedDisabled = true
  }
  return (<div className="st-oplist">
    <div className="st-optimization">
      <div className="st-opheading">
        <h1>Metrics</h1>
      </div>
      <div className="st-oplistbox">
        <Form>
          <Form.Group as={Row} className="mb-input">
            <Form.Label column sm={2}>
              Distance
            </Form.Label>
            <Col sm={10}>
              <Form.Group className="mb-3 od-dselect">
                <Form.Select
                  defaultValue={props.settingFormData.distanceUnit}
                  disabled={roleBasedDisabled}
                  onChange={(e) => { props.handleChangeSetting(e, { key: [{ "distanceUnit": e.target.value }], type: type, subType: subType }) }}>
                  <option value="km">Kilometers</option>
                  <option value="m">Miles</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-input">
            <Form.Label column sm={2}>
              Weight
            </Form.Label>
            <Col sm={10}>
              <Form.Group className="mb-3 od-dselect">
                <Form.Select
                  defaultValue={props.settingFormData.weightUnit}
                  disabled={roleBasedDisabled}
                  onChange={(e) => { props.handleChangeSetting(e, { key: [{ "weightUnit": e.target.value }], type: type, subType: subType }) }}>
                  <option value="kg">Kilogram</option>
                  <option value="lb">Pounds</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-input">
            <Form.Label column sm={2}>
              Volume
            </Form.Label>
            <Col sm={10}>
              <Form.Group className="mb-3 od-dselect">
                <Form.Select
                  defaultValue={props.settingFormData.volumeUnit}
                  disabled={roleBasedDisabled}
                  onChange={(e) => {
                    props.handleChangeSetting(e, { key: [{ "volumeUnit": e.target.value }], type: type, subType: subType });
                    props.handleChangeSetting(e, { key: [{ "defaultMaxMissionCapacityUnit": e.target.value }, { "defaultTaskCapacityUnit": e.target.value }], type: type, subType: props.settingSubType.TASK_AND_MISSION })
                  }}>
                  <option value="m3">m3</option>
                  <option value="ft3">ft3</option>
                  <option value="box">box</option>
                  <option value="unit">unit</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Form.Group>
        </Form>
      </div>
    </div>
  </div>)
}

export default RegionalSettingTab