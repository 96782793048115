import BootstrapTable from "react-bootstrap-table-next";
import momentTz from "moment-timezone"
import { delay, timeZoneHeader } from "../../../helpers/helperFunctions";
import { Field } from "formik";
import { AiOutlinePlusCircle } from "react-icons/ai";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import bin from "../../../assets/image/bin-white.png";
const timezone = { ...timeZoneHeader() }

const Services = (props) => {
    const NumberInput = (props) => {
        const { value, onUpdate, ...rest } = props;
        return (<input
            {...rest}
            key="price"
            type="number"
            min="0"
            max="100"
            style={{
                border: "1px solid #809FB8",
                // height: "40px",
                borderRadius: "5px",
                padding: "6px"
            }}
            onBlur={(e) => {
                onUpdate(e.target.value)
            }}
        />)
    }

    const columns = [{
        dataField: 'orderDate',
        text: 'SERVICE DATE',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        headerClasses: 'table-header',
        classes: 'custom-td-class',
        editCellClasses: 'custom-editor-cell-class',
        editorClasses: 'custom-editor-class',
        formatter: (cell) => {
            return momentTz(cell).tz(timezone.timezone).format("MM/DD/YYYY")
        },
        editor: {
            type: Type.DATE
        }
    }, {
        dataField: 'recipientName',
        text: 'RECIPIENT NAME',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        headerClasses: 'table-header',
        classes: 'custom-td-class',
        editCellClasses: 'custom-editor-cell-class',
        editorClasses: 'custom-editor-class',
    }, {
        dataField: 'orderType',
        text: 'SERVICE',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        headerClasses: 'table-header',
        classes: 'custom-td-class',
        editCellClasses: 'custom-editor-cell-class',
        editorClasses: 'custom-editor-class',
        editor: {
            type: Type.SELECT,
            options: [{
                value: "Drop",
                label: "Drop"
            }, {
                value: "Drop & Service",
                label: "Drop & Service"
            }, {
                value: "Service",
                label: "Service"
            }, {
                value: "Return",
                label: "Return"
            }, {
                value: "Pick Up",
                label: "Pick Up"
            }, {
                value: "Exchange",
                label: "Exchange"
            }]
        }
    },
    {
        dataField: 'description',
        text: 'DESCRIPTION',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        headerClasses: 'table-header',
        classes: 'custom-td-class',
        editCellClasses: 'custom-editor-cell-class',
        editorClasses: 'custom-editor-class',
    },
    {
        dataField: 'quantity',
        text: 'QTY',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        headerClasses: 'table-header',
        classes: 'custom-td-class',
        editCellClasses: 'custom-editor-cell-class',
        editorClasses: 'custom-editor-class',

        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
            <NumberInput {...editorProps} value={value} />
        )
    }, {
        dataField: 'price',
        text: 'RATE',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        headerClasses: 'table-header',
        classes: 'custom-td-class',
        editCellClasses: 'custom-editor-cell-class',
        editorClasses: 'custom-editor-class',

        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
            <NumberInput {...editorProps} value={value} />
        )
    }, {
        dataField: 'amount',
        text: 'AMOUNT',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        headerClasses: 'table-header',
        classes: 'custom-td-class',
        editCellClasses: 'custom-editor-cell-class',
        editorClasses: 'custom-editor-class',
        editable: false
    },
    {
        text: '',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        isDummyField: true,
        headerClasses: 'table-header',
        classes: 'custom-td-class',
        editCellClasses: 'custom-editor-cell-class',
        editorClasses: 'custom-editor-class',
        editable: false,
        formatter: (cell, row, rowIndex) =>
            <>
                {!props.showPaymentForm && <img onClick={() => {
                    let newServices = [...props.services];
                    newServices.splice(rowIndex, 1)
                    props.setServices(newServices)
                }} className={"delete"} src={bin} alt={"bin"} />}
            </>
    }];
    const rowClasses = (row, rowIndex) => {
        return 'custom-row-class';
    };

    const cellEdit = cellEditFactory({
        mode: 'click',
        blurToSave: true,
        afterSaveCell: (oldValue, newValue, row, column) => {
            let newServices = [...props.services]
            let findTask = newServices.findIndex((i) => i.id === row.id)
            if (findTask >= 0) {
                row.price = Number(row.price)
                row.amount = Number(row.price) * Number(row.quantity)
                row.quantity = Number(row.quantity)
                newServices[findTask] = row;
            }
            props.setServices(newServices)
        },
    });

    const tableRowEvents = {
        onRowClick: (row) => {
            //   setSelectedCustomer(row)
        }
    }
    const selectRowProp = {
        mode: "checkbox",
        clickToSelect: false,
        // onSelect: RowSelectHandler,
        // onSelectAll: selectAllRowsHandler,
        bgColor: "rgb(238, 193, 213) !important",
        // classes: (row, rowIndex) => {
        //   console.log("rrrrrrrrrrrrrrrrowwwwwww", row)
        //   return ""
        // }
    };

    return (<div className="list-container">
        {!props.showPaymentForm ? <BootstrapTable
            keyField='id'
            options={tableRowEvents}
            data={props.services ? props.services : []}
            striped={true}
            hover={true}
            rowClasses={rowClasses}
            selectRow={selectRowProp}
            wrapperClasses="table-responsive"
            columns={columns}
            cellEdit={cellEdit}
        /> : <BootstrapTable
            keyField='id'
            options={tableRowEvents}
            data={props.services ? props.services : []}
            striped={true}
            hover={true}
            rowClasses={rowClasses}
            selectRow={selectRowProp}
            wrapperClasses="table-responsive"
            columns={columns}
        />}
        <div className="add-line" onClick={() => {
            let newServices = [...props.services];
            let obj = {
                id: `dummm-${btoa(
                    Math.random().toString()
                ).substring(0, 16)}`,
                orderDate: new Date(),
                recipientName: "",
                orderType: "",
                taskId: null,
                quantity: 0,
                price: 0 ? Number(0) : 0,
                description: ""
            }
            obj.amount = obj.quantity * obj.price
            newServices.push(obj)
            props.setServices(newServices)
        }}>
            <AiOutlinePlusCircle className="create-icon" />
            <p>Add line</p>
        </div>
        <div className="calculation-container">
            <div className="sub-container">
                <div className="sub-total">
                    <div className="left-container">
                        <span>
                            Subtotal
                        </span>
                    </div>
                    <div className="right-container">
                        <span>
                            ${props.calculateValues().subTotal}
                        </span>
                    </div>
                </div>
                <div className="discount">
                    <div className="left-container">
                        <div className="two-inputs">
                            <div className="input-container">
                                <select name="discounts" className="input-area" id="discount"
                                    defaultValue={"percent"} value={props.discount?.type} onChange={(e) => {
                                        props.setDiscount((prev) => {
                                            return {
                                                ...prev,
                                                ...{
                                                    type: e.target.value,
                                                    value: prev.value || 0
                                                }
                                            }
                                        })
                                    }}>
                                    <option value="percent">Discount percent</option>
                                    <option value="value">Discount value</option>
                                </select>
                            </div>
                            <div className="input-container">
                                <input
                                    className="input-area"
                                    type={"number"}
                                    step={"any"}
                                    defaultValue={props?.discount?.value ? props.discount?.value : 0}
                                    disabled={props.showPaymentForm ? true : false}
                                    // value={props.invoiceFormProps?.invoiceForm?.discount?.value}
                                    onBlur={(e) => {
                                        props.setDiscount((prev) => {
                                            return {
                                                ...prev,
                                                ...{
                                                    type: prev.type,
                                                    value: e.target.value || 0
                                                }
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="right-container">
                        <span>${props.calculateValues().subTotalAfterDiscount}</span>
                    </div>
                </div>
                {props.calculateValues().taxes.map(tax => {
                    return (<div className="tax-groups">
                        <div className="left-container">
                            <span>
                                {tax.taxName}
                            </span>
                            <span>
                                {tax.percentage}%
                            </span>
                        </div>
                        <div className="right-container">
                            <span>${tax.amount}</span>
                        </div>
                    </div>)
                })}
                <div className="sub-total">
                    <div className="left-container">
                        <span>
                            Total
                        </span>
                    </div>
                    <div className="right-container">
                        <span>
                            ${props.calculateValues().amount}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Services