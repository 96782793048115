import React, { memo, useEffect, useRef, useState } from "react";
import { Formik, Form as FormF, Field, ErrorMessage } from "formik";
import {
    Row,
    Col,
    Form,
    FormControl,
    Button,
} from "react-bootstrap";
import Union from "../../assets/image/Union.png";
import downloadicon from "../../assets/image/downloadicon.png";
import closeicon from "../../assets/image/close-icon.png";
import { useSelector } from "react-redux";
import Autocomplete from "react-google-autocomplete";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import uploadplusicon from "../../assets/image/plusuploadicon.png";
import documenticon from "../../assets/image/document.png"
import DatePicker from "react-date-picker";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import Select, { components } from 'react-select';
import teamService from '../../services/team.service'
import taskService from "../../services/task.service";
import { errorAlert } from "../../services/toast.service";
import Swal from "sweetalert2";
import timeZoneHeader from "../../services/timezone-header";
import momentTz from "moment-timezone";
import authService from "../../services/auth.service";
const timezone = { ...timeZoneHeader() }

const CreateTaskComponent = memo((props) => {
    const history = useHistory();
    const uploadInput = useRef(null);
    const userData = useSelector((state) => state.auth.user);
    const superAdminRole = userData && userData.role && userData.role === 1 ? true : false
    const [teams, setTeams] = useState([])
    const [userTeams, setUserTeams] = useState([])

    const deleteDocument = (index, setFieldValue, libraryFiles) => {
        let newLibraryFiles = [...libraryFiles];
        newLibraryFiles.splice(index, 1)
        props.setFormData((prev) => {
            return {
                ...prev,
                libraryFiles: newLibraryFiles
            }
        })
    }

    const handleImageUpload = async (image) => {
        let formDataFile = new FormData();
        formDataFile.append("image", image)
        let response = await authService.uploadFile(formDataFile)
        if (response && response.data) {
            let libraryFiles = props.formData.libraryFiles;
            libraryFiles.push(response.data)
            props.setFormData((prev => {
                return {
                    ...prev,
                    libraryFiles: libraryFiles
                }
            }))
        }
    }
    const uploadLibraryDocument = async (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                handleImageUpload(event.target.files[0])
            };
            reader.readAsDataURL(event.target.files[0]);

        }
    }

    useEffect(() => {
        getTeamList()
    }, [])

    const validateCustom = (values) => {
        let error = {};
        if (!props.address) {
            error.address = 'Address is required';
        } if (!props.location || !props.location.lat || !props.location.lng) {
            error.address = 'Please enter valid address';
        }
        else {
            delete error.address
            delete error.location
        }
        return error;
    };

    const validationSchema = Yup.object().shape({

        email: Yup.string()
            // .required("Email is required!")
            .email("Please fill valid email address"),
        // password: Yup.string().required("Password is required!"),
    });
    const getTeamList = async (payload, setFieldValue, forUser) => {
        let params = { limit: 100 }
        if (userData.role === 1 && userData.userId && userData.permittedUsers && userData.permittedUsers.length) {
            params.userIds = userData.permittedUsers
        }
        if (userData.teamIds) {
            params.teamIds = userData.teamIds
        }
        if (userData.subAdmin) {
            delete params.userIds;
            delete params.teamIds
        }
        if (payload && payload.userId) {
            params.userId = payload.userId
        }
        const response = await teamService.getTeamList(params);
        if (Number(response.status) === 200) {
            if (!forUser) {
                setTeams(response.data.results || []);
            }
            else {
                setUserTeams(response.data.results || [])
            }
            if (!setFieldValue && (!forUser)) props.setFormData((prev) => {
                return {
                    ...prev,
                    values: [],
                    teamIds: response.data && response.data.results ? response.data.results.map(i => i.id) : []
                }
            });
            if (payload && payload.userId && response.data && response.data.results.length && setFieldValue) {
                if (!forUser) setFieldValue("teamIds", response.data.results.map(i => i.id))
                if (!forUser) setFieldValue("values", [])
            }
        }
    };

    const handleSubmit = async (formValue, { resetForm }) => {
        try {
            if ((props.features.includedTaskNumber > 0 &&
                ((props.features.additionalTask && (Number(props.features.additionalTaskNumber) + Number(props.features.includedTaskNumber)) > props.taskCount) || props.features.includedTaskNumber > props.taskCount)) ||
                (props.features && props.features.unlimitedTasks) || (superAdminRole)) {
                await createTaskFunc(formValue, resetForm)
            }
            else {
                if (props.buttonText === "Create Task") {
                    Swal.fire({
                        title: "Exceeded qouta",
                        text: `You have exceeded default qouta, tasks created now will be charged $${props.features.perTaskCharge || 0.15} per task at the end of month. `,
                        icon: "info",
                        showCancelButton: true,
                        showDenyButton: true,
                        color: "#ffffff",
                        background: '#151719',
                        confirmButtonColor: "#1A1D1F",
                        cancelButtonColor: "#1A1D1F",
                        denyButtonColor: "#1A1D1F",
                        denyButtonText: "Check Business Builder",
                        confirmButtonText: "Create Task"
                    }).then(async (result) => {
                        if (result.isDenied) {
                            history.push({
                                pathname: "/Business/unlimitedTasks"
                            })
                        }
                        if (result.isConfirmed) {
                            await createTaskFunc(formValue, resetForm)
                        }
                    });
                }
                else {
                    await createTaskFunc(formValue, resetForm)
                }
            }

        } catch (err) {
            console.log("error:::", err);
        }
    };
    const escapeRegex = (str) => {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "").replace(/ /g, "");
    };

    let createTaskFunc = async (formValue, resetForm) => {
        delete formValue.values
        let usersTeam = userTeams.map(i => i.id)
        if (formValue.createdFor && formValue.createdFor.id !== userData.id)
            formValue.teamIds = [...formValue.teamIds, ...usersTeam]
        if (formValue.phoneNumber) {
            let phone = escapeRegex((formValue.phoneNumber.toString()));
            formValue.phoneNumber = phone;
        }
        if (formValue.orderDate) {
            let timezone = { ...timeZoneHeader() }
            formValue.orderDate = momentTz(formValue.orderDate).tz(timezone.timezone)._d
        }
        const response = await taskService.createTask({
            ...formValue,
            docs: props.selectedDocs,
            address: props.address,
            location: props.location,
        });
        if (response.data.success) {
            // props.setTaskCount((prev) => {
            //     return prev + 1;
            // })
            props.setButtonText("Create Task")
            // successAlert(
            //   response.data.message
            //     ? response.data.message
            //     : "Task Added Successfully !"
            // );
            delete props.initialValues.merchantId
            delete props.initialValues.userId
            delete props.initialValues.createdFor
            props.setcreateTask(!props.createTask);
            // if (userData.role === 1) getTeamList();
            props.setFormData((prev) => {
                return {
                    ...prev,
                    ...props.initialValues,
                    values: []
                }
            });
            props.setAddress("")
            resetForm();

            props.getUnassignedTaskList(props.date && props.date.startDate, props.date && props.date.endDate);
            // refresh the bookmark list in dashboard
            if (response.data.task.id && props.getTaskList) {
                props.getTaskList(response.data.task.id);
            }
            if (props.getMissionList) {
                props.getMissionList();
            }

        } else {
            errorAlert(
                response.data.message
                    ? response.data.message
                    : "Something went Wrong  !"
            );
        }
    }

    const finalTheme = createTheme({
        components: {
            MuiClockPicker: {
                styleOverrides: {
                    root: {
                        "backgroundColor": "#272B30"
                    }
                }
            }
        }
    })


    const CustomSelectField = ({ field, colors, form, ...propss }) => {
        return (
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                closeMenuOnSelect={false}
                defaultValue={form.values.values && form.values.values.length ? form.values.values : props.selectAllOption}
                value={form.values.values && form.values.values.length ? form.values.values : props.selectAllOption}
                placeholder="Select Team List"
                isMulti
                isClearable={false}
                autoFocus={false}
                isSearchable={false}
                options={getOptions()}
                getOptionLabel={(option) => `${option.teamName}`}
                getOptionValue={(option) => option.id}
                onChange={(newValue, actionMeta) => { onChangeValue(newValue, actionMeta, form) }}
            // components={{ ValueContainer }}
            />
        )
    };
    const onChangeValue = (newValue, actionMeta, form) => {
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option" && option.id === props.selectAllOption.id) {
            form.setFieldValue("values", teams);
            form.setFieldValue("teamIds", teams.map(i => i.id));
        }
        else if (action === "clear") {
            form.setFieldValue("values", []);
            form.setFieldValue("teamIds", []);
        }
        else {
            let findAllSelectedValue = newValue.findIndex(i => i.id === props.selectAllOption.id)
            if (findAllSelectedValue > -1) {
                newValue.splice(findAllSelectedValue, 1)
            }
            if (newValue.length) {
                form.setFieldValue("values", newValue);
                form.setFieldValue("teamIds", newValue.map(i => i.id));
            }
            else {
                form.setFieldValue("values", []);
                form.setFieldValue("teamIds", teams.map(i => i.id));
            }
        }
    }

    const getOptions = () => [props.selectAllOption, ...teams];

    const changeProfileClick = (e) => {
        uploadInput.current.click();
    };
    return (<div className={props.createTask ? "createtask-slide createTask" : "createtask-slide"}>
        <Formik
            enableReinitialize
            initialValues={props.formData}
            render={({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
            }) => {
                return (
                    <FormF>
                        <div className="create-task-field">
                            <span>{props.buttonText}</span>
                            <div className="task-import">
                                <div className="task-import-field">
                                    <Button>
                                        <img src={Union} alt="icon" />
                                    </Button>
                                    <span>import</span>
                                    <Button onClick={(e) => props.importPopup()}>
                                        <img src={downloadicon} alt="icon" />
                                    </Button>
                                </div>
                                <Button
                                    className="createtask-close"
                                    onClick={() => { props.createTaskToggle(); props.cancelTask(); }}
                                >
                                    <img src={closeicon} alt="icon" />
                                </Button>
                            </div>
                        </div>
                        {/* Customer Details */}
                        <div className="create-order-form">
                            <Row>
                                <Col xs={6}>
                                    <span>For</span>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="mb-3 select-merchant">
                                        <Field
                                            className="form-control"
                                            name="createdFor"
                                            as="select"
                                            value={values["createdFor"]}
                                            onChange={(e) => {
                                                setFieldValue("createdFor", e.target.value)
                                                let findUser = props.usersList.find(i => i.id === e.target.value);
                                                if (findUser) {
                                                    if (findUser.role === 2) {
                                                        setFieldValue("userId", e.target.value)
                                                        if (userData.role === 1) {
                                                            setFieldValue("merchantId", e.target.value)
                                                        }
                                                        getTeamList({ userId: e.target.value }, setFieldValue, "USER")
                                                    }
                                                    else if (findUser.role === 4) {
                                                        setUserTeams([])
                                                        setFieldValue("merchantId", e.target.value)
                                                    }
                                                }
                                            }}
                                        >
                                            <option value="" selected>
                                                Select User
                                            </option>
                                            {props.usersList &&
                                                props.usersList.length > 0 &&
                                                props.usersList.map((t) => {
                                                    let option = (
                                                        <option value={t.id}>
                                                            {t.firstName ? t.firstName : ""}{" "}
                                                            {t.lastName ? t.lastName : ""}
                                                        </option>
                                                    )
                                                    if (t.role === 2) {
                                                        option = (
                                                            <option value={t.id}>
                                                                {t.companyName ? t.companyName : ""}
                                                            </option>
                                                        )
                                                    }
                                                    return option
                                                })}
                                        </Field>
                                        <ErrorMessage
                                            style={{
                                                fontSize: "10px",
                                                color: "#ea4335",
                                                marginTop: "3px",
                                                marginLeft: "10px",
                                            }}
                                            name="merchantId"
                                            component="div"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <div className="create-order-form">
                            <Row>
                                <Col xs={5}>
                                    <span>Visible by which team</span>
                                </Col>
                                <Col xs={7}>
                                    {/* <Form.Group className="mb-3 select-merchant"> */}
                                    <Field
                                        className="teamIds"
                                        component={CustomSelectField}
                                    >
                                    </Field>
                                    <ErrorMessage
                                        style={{
                                            fontSize: "10px",
                                            color: "#ea4335",
                                            marginTop: "3px",
                                            marginLeft: "10px",
                                        }}
                                        name="teamIds"
                                        component="div"
                                    />
                                    {/* </Form.Group> */}
                                </Col>
                            </Row>
                        </div>
                        <div className="create-customer-detail">
                            <h2>Customer Details</h2>
                            <div className="create-customers-form">
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3">
                                            {/* <Form.Control type="text" placeholder="First Name" /> */}
                                            <Field
                                                className="form-control"
                                                placeholder="First Name"
                                                name="firstName"
                                                maxLength="15"
                                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                                aria-label="First Name"
                                            />
                                            <ErrorMessage
                                                name="firstName"
                                                component="div"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3">
                                            <Field
                                                className="form-control"
                                                placeholder="Last Name"
                                                name="lastName"
                                                maxLength="15"
                                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                                aria-label="Last Name"
                                            />
                                            <ErrorMessage name="lastName" component="div" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} style={{ display: "flex" }}>
                                        <Form.Group className="mb-3 od-dselect">
                                            <Field className="form-control" aria-label="Phone" name="countryCode" as="select"
                                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}>
                                                <option value="+1" selected >+1</option>
                                                <option value="+91">+91</option>
                                            </Field>
                                            <ErrorMessage name="countryCode" component="div" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Field
                                                className="form-control"
                                                placeholder="Phone"
                                                name="phoneNumber"
                                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                                aria-label="Phone"
                                            />
                                            <ErrorMessage
                                                name="phoneNumber"
                                                component="div"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3">
                                            {/* <Form.Control type="email" placeholder="Email" /> */}
                                            <Field
                                                className="form-control"
                                                placeholder="Email"
                                                name="email"
                                                aria-label="Email"
                                            />
                                            <ErrorMessage name="email" component="div" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={8}>
                                        <Form.Group className="mb-3">
                                            {/* <MyAutoCompleteComponent {...autoCompleteProps} /> */}
                                            <Autocomplete
                                                className="form-control"
                                                value={props.address}
                                                onChange={(e) => { props.setAddress(e.target.value); props.setLocation(null) }}
                                                placeholder="Address"
                                                options={{ types: ["establishment", "geocode"], ...(props.countryCode && { componentRestrictions: { country: props.countryCode } }) }}
                                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                                onPlaceSelected={(place) => {
                                                    props.setAddress(place.formatted_address);
                                                    props.setLocation({
                                                        lat: place.geometry.location.lat(),
                                                        lng: place.geometry.location.lng(),
                                                    });
                                                }}
                                            />

                                            {!props.address && (
                                                <ErrorMessage
                                                    name="address"
                                                    component="div"
                                                />
                                            )}
                                            {(!props.location || !props.location.lat || !props.location.lng) && (
                                                <ErrorMessage
                                                    name="address"
                                                    component="div"
                                                />
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Group className="mb-3">

                                            <Field
                                                className="form-control"
                                                placeholder="Apt, Bld"
                                                name="apartment"
                                                maxLength="15"
                                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                                aria-label="Apt, Bld"
                                            />
                                            <ErrorMessage
                                                name="apartment"
                                                component="div"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        {/* Order Details */}
                        <div className="create-order-detail">
                            <h2>Order Details</h2>
                            <div className="create-order-form">
                                <Form.Group className="mb-3 od-dselect">
                                    <Field
                                        className="form-control"
                                        name="orderType"
                                        as="select"
                                        onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                        defaultValue={values["orderType"]}
                                        onChange={(e) => {
                                            let defaultTime = props.defaultDurationTime;
                                            if (e.target.value === "D" || e.target.value === "P") {
                                                defaultTime = props.taskSettingData && (props.taskSettingData.defaultTaskDuration || props.taskSettingData.defaultTaskDuration === 0) ? props.taskSettingData.defaultTaskDuration : props.defaultDurationTime;
                                            }
                                            else if (e.target.value === "DI" || e.target.value === "E") {
                                                defaultTime = props.taskSettingData && (props.taskSettingData.defaultInstallationTime || props.taskSettingData.defaultInstallationTime === 0) ? props.taskSettingData.defaultInstallationTime : props.defaultDurationTime;
                                            }
                                            setFieldValue("durationTime", defaultTime)
                                            // handleOrderTypeChange(e); 
                                            setFieldValue("orderType", e.target.value)
                                        }}
                                    >
                                        <option value="P">Pick up</option>
                                        <option value="D">Drop</option>
                                        <option value="DI">Drop & Service</option>
                                        <option value="R">Return</option>
                                        <option value="E">Exchange</option>
                                        <option value="S">Service</option>
                                    </Field>
                                    <ErrorMessage name="orderType" component="div" />
                                </Form.Group>

                                <div className="create-settime-duration settime-duration-margin">
                                    {/* <h2>Task Duration</h2> */}
                                    <div className="setime-duration-form">
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Group className="mb-3 timedru form-floating">
                                                    {/* <Form.Control type="text" placeholder="Duration time" /> */}
                                                    <Field
                                                        type="number"
                                                        min="0"
                                                        step="any"
                                                        className="form-control"
                                                        placeholder="Duration time"
                                                        name="durationTime"
                                                        onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                                        aria-label="Duration time"
                                                    />
                                                    <ErrorMessage
                                                        name="durationTime"
                                                        component="div"
                                                    />
                                                    <label>Duration time</label>
                                                    <span>min</span>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6} >
                                                <Form.Group className="mb-3 timedru form-floating" onClick={(e) => (!superAdminRole && props.features && !props.features.vehicleCapacityManagement) ? history.push({
                                                    pathname: "/Business/vehicleCapacityManagement"
                                                }) : null}>
                                                    {/* <Form.Control type="text" placeholder="Duration time" /> */}
                                                    <Field
                                                        type="number"
                                                        min="0"
                                                        step="any"
                                                        className="form-control"
                                                        placeholder="Capacity"
                                                        name="capacity"
                                                        onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                                        aria-label="Capacity"
                                                        disabled={(props.features && props.features.vehicleCapacityManagement) || (superAdminRole) ? false : true}
                                                    />
                                                    <ErrorMessage
                                                        name="capacity"
                                                        component="div"
                                                    />
                                                    <label>Capacity</label>
                                                    <span>{values.capacityUnit}</span>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                                <div className="create-settime-duration settime-duration-margin">
                                    {/* <h2>Task Duration</h2> */}
                                    <div className="setime-duration-form">
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Group className="mb-3 timedru form-floating">
                                                    {/* <Form.Control type="text" placeholder="Duration time" /> */}
                                                    <Field
                                                        type="number"
                                                        min="0"
                                                        step="any"
                                                        className="form-control"
                                                        placeholder="Price"
                                                        name="price"
                                                        onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                                        aria-label="Price"
                                                    />
                                                    <ErrorMessage
                                                        name="price"
                                                        component="div"
                                                    />
                                                    <label>Price</label>
                                                    <span>{values.priceUnitSymbol}</span>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                                {/* <hr /> */}
                                <Form.Group className="mb-3">
                                    <Field
                                        className="form-control"
                                        placeholder="Notes,product,apt..."
                                        name="notes"
                                        aria-label="Notes,product,apt..."
                                        as="textarea"
                                    />
                                    <ErrorMessage name="notes" component="div" />
                                </Form.Group>

                                <div className="order-add-doc">

                                    {<div className="upload__image-wrapper" onClick={(e) => {
                                        if ((props.features && !props.features.taskCreationAddDocs) && (!superAdminRole)) {
                                            history.push({
                                                pathname: "/Business/taskCreationAddDocs"
                                            })
                                        }
                                    }}>
                                        {(props.formData.libraryFiles && props.formData.libraryFiles.length < 7) && <div className="library-image" onClick={changeProfileClick}>
                                            <img src={uploadplusicon} alt="icon" />
                                            <input
                                                className="file-upload"
                                                ref={uploadInput}
                                                type="file"
                                                name="image"
                                                accept="image/*,.pdf"
                                                onChange={(e) => {
                                                    uploadLibraryDocument(e);
                                                }}
                                                style={{ 'display': 'none' }}
                                            ></input>
                                            <span>Add doc</span>
                                        </div>}
                                    </div>}
                                    {props.formData.libraryFiles && props.formData.libraryFiles.length ?
                                        props.formData.libraryFiles.map((item, index) => {
                                            if (item.original) {
                                                let name = item.original.split("/")
                                                let ext = item.original.substring(item.original.lastIndexOf('.') + 1, item.original.length).toLowerCase()
                                                return (
                                                    <div className="upload__image-wrapper">
                                                        <div className="selected-docs">
                                                            <div>
                                                                <Button
                                                                    className="selected-close-btn"
                                                                    onClick={() => {
                                                                        deleteDocument(index, setFieldValue, props.formData.libraryFiles)
                                                                    }}
                                                                >
                                                                    <img src={closeicon} alt="icon" />
                                                                </Button>
                                                            </div>
                                                            {ext === "pdf" ? <div onClick={() => {
                                                                if (item && item.original) {
                                                                    window.open(item.original, "_blank")
                                                                }
                                                            }}><span className="pdfvalue">PDF</span></div> : <div onClick={() => {
                                                                if (item && item.original) {
                                                                    window.open(item.original, "_blank")
                                                                }
                                                            }}><img class="original-image" src={item.original} alt="icon" /></div>}
                                                        </div>
                                                        <div className="filename"><span>{name[4]}</span></div>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        })
                                        : null}
                                    {((props.features && props.features.taskCreationAddDocs) || (superAdminRole)) && (!props.formData.libraryFiles || (props.formData.libraryFiles && props.formData.libraryFiles.length === 0)) && (values.documents && values.documents.length) ?
                                        values.documents.map(item => {
                                            return (
                                                <div className="upload__image-wrapper">
                                                    <div className="selected-docs" onClick={() => {
                                                        if (item && item.original) {
                                                            window.open(item.original, "_blank")
                                                        }
                                                    }}>
                                                        <img style={{ position: "relative" }} src={documenticon} alt="icon" />
                                                    </div>
                                                    <div className="filename"><span>{item.fileName}</span></div>
                                                </div>
                                            )
                                        })
                                        : null}
                                </div>
                            </div>
                        </div>
                        {/* Select time range for delivery */}
                        <div className="create-settime-duration">
                            <h2>Select time range for delivery</h2>
                            <div className="setime-duration-form">
                                <DatePicker
                                    dateFormat="MM/dd/yyyy"
                                    name={"orderDate"}
                                    value={values["orderDate"] ? new Date(values["orderDate"]) : ""}
                                    onChange={(e) => {
                                        setFieldValue("orderDate", e);
                                        let todayDate = momentTz(props.currentDate).tz(timezone.timezone).format("YYYY-MM-DD")
                                        let selectedDate = momentTz(e).tz(timezone.timezone).format("YYYY-MM-DD");
                                        if (todayDate !== selectedDate) {
                                            setFieldValue("dateChanged", true)
                                        }
                                        else {
                                            setFieldValue("dateChanged", false)
                                        }
                                    }}
                                    selectsEnd
                                />
                                <Row className="after-input">
                                    <Col xs={6}>
                                        <Form.Group>
                                            <div className="after-before-time">
                                                <ThemeProvider theme={finalTheme}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <TimePicker
                                                            inputProps={{ placeholder: "After" }}
                                                            // inputFormat={"hh:mm"}
                                                            open={values.afterOpen}
                                                            // onOpen={() => setFieldValue("afterOpen", true)}
                                                            onClose={() => setFieldValue("afterOpen", false)}
                                                            value={values.afterTimePicker}
                                                            onChange={(e) => {
                                                                setFieldValue("afterTimePicker", e)
                                                                let date = new Date(e)
                                                                if (!isNaN(date)) {
                                                                    var options = { hour12: false }
                                                                    let timeSplit = date.toLocaleTimeString("en", options).split(":")
                                                                    setFieldValue("after", `${timeSplit[0]}:${timeSplit[1]}`)
                                                                    // setFieldValue("afterTime", `${timeSplit[0]}:${timeSplit[1]} ${timeSplit[0] > 12 ? "pm" : "am"}`)
                                                                    setFieldValue("afterSeconds", Number((date.getHours() * 3600) + (date.getMinutes() * 60)))
                                                                }
                                                            }}
                                                            renderInput={(params) => <TextField {...params}
                                                                onClick={(e) => setFieldValue("afterOpen", true)}
                                                            />
                                                            }
                                                        />
                                                    </LocalizationProvider>
                                                </ThemeProvider>
                                            </div>
                                            <ErrorMessage name="after" component="div" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <div className="after-before-time">
                                                <ThemeProvider theme={finalTheme}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <TimePicker
                                                            inputProps={{ placeholder: "Before" }}
                                                            // inputFormat={"hh:mm a"}
                                                            open={values.beforeOpen}
                                                            // onOpen={() => setFieldValue("beforeOpen", true)}
                                                            onClose={() => setFieldValue("beforeOpen", false)}
                                                            value={values.beforeTimePicker}
                                                            onChange={(e) => {
                                                                setFieldValue("beforeTimePicker", e)
                                                                let date = new Date(e)
                                                                if (!isNaN(date)) {
                                                                    var options = { hour12: false }
                                                                    let timeSplit = date.toLocaleTimeString("en", options).split(":")
                                                                    setFieldValue("before", `${timeSplit[0]}:${timeSplit[1]}`)
                                                                    setFieldValue("beforeSeconds", Number((date.getHours() * 3600) + (date.getMinutes() * 60)))
                                                                    // setFieldValue("beforeOpen", false)
                                                                    // setFieldValue("beforeTime", `${timeSplit[0]}:${timeSplit[1]} ${timeSplit[0] > 12 ? "pm" : "am"}`)
                                                                }
                                                            }}
                                                            renderInput={(params) => <TextField {...params} onClick={(e) => setFieldValue("beforeOpen", true)} />
                                                            }
                                                        />
                                                    </LocalizationProvider>
                                                </ThemeProvider>
                                            </div>
                                            <ErrorMessage name="before" component="div" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <span className="ortext">Or</span>
                                {/* <span className="scheduleText">Scheduled time</span> */}
                                <Col className="scheduleTime" xs={6}>
                                    <Form.Group className="mb-3">
                                        <ThemeProvider theme={finalTheme}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    inputProps={{ placeholder: "Scheduled time" }}
                                                    // inputFormat={"hh:mm a"}
                                                    open={values.scheduleTimeOpen}
                                                    // onOpen={() => setFieldValue("scheduleTimeOpen", true)}
                                                    onClose={() => setFieldValue("scheduleTimeOpen", false)}
                                                    value={values.scheduledTimePicker}
                                                    onChange={(e) => {
                                                        setFieldValue("scheduledTimePicker", e)
                                                        let date = new Date(e)
                                                        if (!isNaN(date)) {
                                                            var options = { hour12: false }
                                                            let timeSplit = date.toLocaleTimeString("en", options).split(":")
                                                            setFieldValue("scheduledTime", `${timeSplit[0]}:${timeSplit[1]}`)
                                                            setFieldValue("scheduledTimeSeconds", Number((date.getHours() * 3600) + (date.getMinutes() * 60)))
                                                            // setFieldValue("scheduleTime", `${timeSplit[0]}:${timeSplit[1]} ${timeSplit[0] > 12 ? "pm" : "am"}`)
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField {...params} onClick={(e) => setFieldValue("scheduleTimeOpen", true)} />
                                                    }
                                                />
                                            </LocalizationProvider>
                                        </ThemeProvider>
                                        <ErrorMessage
                                            name="scheduledTime"
                                            component="div"
                                        />
                                    </Form.Group>
                                </Col>
                            </div>
                        </div>

                        <div className="ct-btn">
                            <Button className="cncle-btn" onClick={props.cancelTask}>
                                Cancel
                            </Button>
                            <Button type="submit" className="ctaskbtn">
                                {props.buttonText}
                            </Button>
                        </div>
                    </FormF>
                );
            }}
            validationSchema={validationSchema}
            validate={validateCustom}
            onSubmit={handleSubmit}
        ></Formik>
    </div>)
})

export default CreateTaskComponent