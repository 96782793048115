import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Card, Row, Col, Button, FormControl, InputGroup, Dropdown, Modal, Container } from 'react-bootstrap';
import './Login.scss';
import logo from '../../assets/image/logo.png';
import passwordicon from '../../assets/image/password.png';
import mailicons from '../../assets/image/mailicons.png';
import view from "../../assets/image/view.png";
import hide from "../../assets/image/hide.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login, resetMessage } from "../../slices/auth";
import { extensions } from "../../slices/extensions";
import { defaultSubscription } from "../../slices/subscription";
import { clearMessage } from "../../slices/message";
import { ToastContainer } from 'react-toastify';
import { successAlert, errorAlert, infoAlert } from '../../services/toast.service';
import * as SHA from 'js-sha256'
import Swal from "sweetalert2";

const Signin = (props) => {
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, message, type } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.auth.user);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required!"),
    password: Yup.string().required("Password is required!"),
  });

  const handleLogin = (formValue) => {
    const { email, password } = formValue;
    setLoading(true);

    dispatch(login({ email, password }))
      .unwrap()
      .then(() => {
        // props.history.push("/dashboard");
        // window.location.reload();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

  }
  useEffect(() => {
    if (type === 'success') {
      let userId = userData.id;
      if (userData.role === 2 && userData.userId) {
        userId = userData.userId
      }
      dispatch(extensions({ userId: userId }))
      dispatch(defaultSubscription({ userId: userId }))
      dispatch(resetMessage())
      let hmac = SHA.sha256.hmac(process.env.REACT_APP_INTERCOM_SERVER_KEY, userData.id);
      // var hash = HmacSHA256(userData.id, process.env.REACT_APP_INTERCOM_SERVER_KEY)
      let app_id = process.env.REACT_APP_INTERCOM_APP_ID;
      if (!!window.Intercom) window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: app_id,
        user_id: userData.id,
        name: `${userData.firstName} ${userData.lastName}`, // Full name
        email: userData.email, // Email address
        user_hash: hmac
      })
      if (!userData.passwordSet) {
        Swal.fire({
          title: "Please set the password",
          icon: "info",
          showCancelButton: true,
          // text: "Deleted records can't be restored!",
          color: "#ffffff",
          background: "#151719",
          confirmButtonColor: "#1A1D1F",
          cancelButtonColor: "#1A1D1F",
          confirmButtonText: "Confirm",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              props.history.push("/set-password");
            }, 2000)
          }
        });
      }
      else {
        successAlert(message ? message : 'Login Successfully')
        setTimeout(() => {
          props.history.push("/dashboard");
        }, 2000)
      }
      //  window.location.reload();
    }
    if (type === 'danger') {
      errorAlert(message ? message : 'Something went wrong !')
      dispatch(resetMessage())
    }
  }, [message, type, userData]);

  // if (isLoggedIn) {
  //   return <Redirect to="/dashboard" />;
  // }


  const CustomPasswordField = ({ field, colors, form, ...props }) => {
    const [showHidePassword, changeShowHidePassword] = useState(false);

    return (
      <div className="input-container">
        <img className="password-icon" src={passwordicon} alt="passwordicon" />
        <input
          type={showHidePassword ? "text" : "password"}
          {...field}
          className="custom-password"
          name="password"
          placeholder="Password"
        />
        <img className="eye-icon" onClick={(e) => { changeShowHidePassword(!showHidePassword) }} src={showHidePassword ? hide : view} alt="passwordicon" />
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="signin-form">
          <div className="logo-bar">
            <img src={logo} alt="logo" />
            <h1 className="heading">Log in to Gomove</h1>
          </div>
          <div className="signup-form-box">
            <p>Log in using your administrator or dispatcher email address and password</p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleLogin}
            >{({ errors, touched }) => (
              <Form inline="true" >
                <Row>
                  <Col xs={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="youremail"><img src={mailicons} alt="emailicon" /></InputGroup.Text>
                      <Field className="form-control" placeholder="Your email" name="email" aria-label="Your email" />
                      <ErrorMessage name="email" component="div" />
                    </InputGroup>

                  </Col>
                  <Col xs={12}>
                    {/* <InputGroup className="mb-3"> */}
                    {/* <InputGroup.Text id="password"><img src={passwordicon} alt="passwordicon" /></InputGroup.Text> */}
                    <Field className="form-control" placeholder="Password" name="password"
                      type="password" aria-label="Password" component={CustomPasswordField} />
                    {errors.password ? <div className="error-message">{errors.password}</div> : null}
                    {/* <ErrorMessage name="password" component="div" /> */}
                    {/* </InputGroup> */}

                  </Col>
                  <Col xs={12}>
                    <div className="continue-btn">
                      <Button variant="primary" type="submit">
                        Go
                      </Button>
                    </div>
                    <div className="recapcta-box">
                      <p>This site is protected by reCAPTCHA and the Google Privacy Policy.</p>
                      <p className="dha-text">Don’t have an account?  <Link to={'/signup'}>Sign up</Link></p>
                      <p className="dha-text">Don’t remember password?  <Link to={'/forgot-password'}>Forgot Password</Link></p>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}

            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer
        style={{ color: 'black' }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );





};

export default Signin;