import React from "react";
import { Router, Route } from "react-router-dom";
import DeliveryConfirmed from "../components/DeliveryConfirmed/DeliveryConfirmed";
import DetailsForm from "../components/DetailsForm/DetailsForm";
import SelectDateAndTimeZone from "../components/SelectDateAndTime/SelectDateAndTimeZone";
import SelectTime from "../components/SelectTime/SelectTime";
import ScrollToTop from "./ScrollToTop";


const Home = (props) => {
  return (
    <>
      <div>
        {props.step === 1 && <SelectDateAndTimeZone {...props} />}
        {props.step === 2 && <SelectTime  {...props} />}
        {props.step === 3 && <DetailsForm  {...props} />}
        {props.step === 4 && <DeliveryConfirmed  {...props} />}
      </div>
      {/* <ScrollToTop>
        <Router>
          <Route exact path="/" element={<SelectDateAndTimeZone />}>
          </Route>
          <Route path="select-time" element={<SelectTime />}></Route>
          <Route path="user-details" element={<DetailsForm />}></Route>
          <Route path="delivery-confirmed" element={<DeliveryConfirmed />}></Route>
        </Router>
      </ScrollToTop> */}
    </>
  );
};

export default Home;
