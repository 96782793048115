// TODO: Resolve lint issues
/* eslint-disable */
import {
    put,
    takeLatest,
    all,
    call,
} from 'redux-saga/effects'

/********************* ACTION TYPE ********************* */
import { BLOCK_MULTIPLE_TEAM_API, CREATE_TEAM_API, DELETE_MANY_TEAM_API, DELETE_TEAM_API, EDIT_TEAM_API, GET_ALL_TEAM_LIST, GET_TEAM_LIST } from './actionTypes';

/*************************** ACTIONS *******************/
import { saveTeamList } from './actions';
import { apiSagaHandler } from '../network/saga';
import { errorReducerHandler } from '../alert/actions'
import { showWarning } from '../alert/actions';
import { clean } from '../../helpers/helperFunctions';
const API_URL = "team/"
/************************ USER SAGA *******************************/
function* saga() {
    yield all([
        call(getTeamListApi),
        call(createTeamApi),
        call(editTeamApi),
        call(deleteTeamApi),
        call(blockTeamApi),
        call(deleteManyTeamApi),
        call(getAllTeamApi),
    ]);
}

function* getTeamListApi() {
    yield takeLatest(GET_TEAM_LIST, getTeamListHandler)
}

function* createTeamApi() {
    yield takeLatest(CREATE_TEAM_API, createTeamHandler)
}

function* editTeamApi() {
    yield takeLatest(EDIT_TEAM_API, editTeamHandler)
}

function* deleteTeamApi() {
    yield takeLatest(DELETE_TEAM_API, deleteTeamHandler)
}

function* blockTeamApi() {
    yield takeLatest(BLOCK_MULTIPLE_TEAM_API, blockTeamHandler)
}

function* deleteManyTeamApi() {
    yield takeLatest(DELETE_MANY_TEAM_API, deleteManyTeamHandler)
}

function* getAllTeamApi() {
    yield takeLatest(GET_ALL_TEAM_LIST, getAllTeamListHandler)
}

function* getAllTeamListHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}all`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* getTeamListHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield put(saveTeamList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* createTeamHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.createApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        yield put(saveTeamList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* deleteTeamHandler(data) {
    console.log("data?.data", data?.data)
    let { payload, resCall, listPayload } = data?.data;
    let config = {
        "endpoint": `${API_URL}/${payload.id}`,
        "auth": true,
        "method": "DELETE",
        "localApi": false,
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield getTeamListHandler({ data: { payload: listPayload } })
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}


function* deleteManyTeamHandler(data) {
    let { payload, resCall, listPayload } = data?.data;
    let config = {
        "endpoint": `${API_URL}multipleDelete`,
        "auth": true,
        "method": "DELETE",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        yield getTeamListHandler({ data: { payload: listPayload } })
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* editTeamHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}/${payload.id}`,
        "auth": true,
        "method": "PATCH",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.editApi = true;
        yield put(saveTeamList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}


function* blockTeamHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}multipleBlock`,
        "auth": true,
        "method": "PATCH",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}


export default saga;
