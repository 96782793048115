// TODO: Resolve lint issues
/* eslint-disable */
import {
    put,
    takeLatest,
    all,
    call,
    select,
    putResolve,
} from 'redux-saga/effects'

/********************* ACTION TYPE ********************* */
import { GET_DEFAULT_SUBSCRIPTION } from './actionTypes';
/*************************** ACTIONS *******************/
import { defaultSubscription } from './actions';
import { apiSagaHandler } from '../network/saga';
import { errorReducerHandler } from '../alert/actions'
import { showWarning } from '../alert/actions';
import { serializeArray } from '../../helpers/helperFunctions';

const API_URL = "auth/"
/************************ USER SAGA *******************************/
function* saga() {
    yield all([
        call(defaultSubscriptionApi)
    ]);
}

function* defaultSubscriptionApi() {
    yield takeLatest(GET_DEFAULT_SUBSCRIPTION, defaultSubscriptionHandler)
}


function* defaultSubscriptionHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}common-services?${serializeArray(payload.type, "type")}`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        // params: {
        //     type: payload.type
        // },
        // paramsSerializer: params => {
        //     return qs.stringify(params)
        // },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        console.log("apiResapiRes", apiRes)
        yield put(defaultSubscription(apiRes.data && apiRes.data.results && apiRes.data.results.length ? apiRes.data.results : []))
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

export default saga;
