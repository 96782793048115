import { put, takeLatest, all, call } from 'redux-saga/effects';
import { ERROR_SAGA } from './actionTypes';
import { clearWarning, showWarning } from './actions';
// import { logOutHandler } from '../userReducer/action';

/******************************************** Sagas Functions ***************************************************** */

function* alertModalSaga() {
	yield all([call(errorSaga)]);
}

function* errorSaga() {
	yield takeLatest(ERROR_SAGA, errorSagaHandler);
}

function* errorSagaHandler({ payload }) {
	let defaultError = "Something went wrong!!!"
	let error = {
		subHeading: '',
		alertTitle: "Error",
		alertDesc: defaultError,
		errorType: 1,
		alertType: "warning"
	};
	if (Number(payload.status) === 401 || Number(payload.status) === 403) {
		error = {
			...error,
			alertDesc: payload.data ?
				payload.data?.errors ? payload.data?.errors : payload.data?.message ? payload.data?.message : defaultError : defaultError,
			//   : payload.data?.message?payload.data?.message: strings.unexpectedError,
		};
		// yield put(logOutHandler())
		// yield put(clearWarning())
	} else if (Number(payload.status) === 400 || Number(payload.status) === 500) {
		error = {
			...error,
			alertDesc: payload.data ?
				payload.data?.errors ? payload.data?.errors : payload.data?.message ? payload.data?.message : defaultError : defaultError,
		};
	} else {
		error = {
			...error,
			alertDesc: payload.data ?
				payload.data?.errors ? payload.data?.errors : payload.data?.message ? payload.data?.message : defaultError : defaultError,
		};
	}

	yield put(showWarning(error));
}

export default alertModalSaga;
