import {
    SAVE_EXTENSIONS,
    SAVE_USER_EXTENSIONS
} from './actionTypes';

export const userExtensions = payload => {
    return {
        type: SAVE_USER_EXTENSIONS,
        payload
    }
}

export const saveExtensions = payload => {
    return {
        type: SAVE_EXTENSIONS,
        payload
    }
}
