import moment from "moment-timezone";

const timeZoneHeader = () => {
    let timezone = "";
    let getTimezone = moment.tz.guess();
    if (getTimezone) {
        timezone = getTimezone;
    }
    return { timezone: timezone };
}
export default timeZoneHeader