
import upicon from "../../assets/image/upicon.png";
import UnAssignedTaskList from "./UnassignedTaskList";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
// import MissionAccordian from "./MissionAccordian";
// const onDragEnd = () => {

// }
const dragOver = async (ev) => {
    ev.preventDefault();
    // ev.dataTransfer.dropEffect = props.dropEffect;
}

const UnassignedCategory = (props) => {
    return (
        <>
            <div className="Unassigned-field "
                onDragOver={dragOver}
                onDrop={(e) => { props.onDrop(e, {}, "unassignedTaskList") }}
            >
                <div
                    className={
                        props.unassignTask
                            ? `${props.fieldBoxClass ? props.fieldBoxClass : "unassigned-field-box"}`
                            : `${props.fieldBoxClass ? props.fieldBoxClass : "unassigned-field-box"} unassignTask`
                    }
                    onClick={props.unassignTaskToggle}
                >
                    <span className="title">{props.UnassignedCategoryTitle ? props.UnassignedCategoryTitle : "Unassigned"}</span>
                    <span className="downicon">
                        <img src={upicon} alt="icon" />
                    </span>
                </div>

                <div className="unassigned-field-list"
                >
                    <UnAssignedTaskList key={"unassignedTaskList"} {...props} />
                    {/* { console.log("ml list ::::", missionList)} */}

                    {/* {props.missionList &&
                        props.missionList.length > 0 &&
                        props.missionList.filter(ml => ml.missionCategory === 'unassigned').map((ml) => {
                            return (
                                <MissionAccordian key={ml.id ? ml.id : ""} data={ml} category={"unassigned"} string={unAssignedMissionString} />
                            );
                        })} */}
                </div>
            </div>
        </>
    );
};

export default UnassignedCategory