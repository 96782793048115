import { ErrorMessage, Field, Formik } from "formik";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import teamicon from "../../../assets/image/teamicon.png"
import { memo } from "react";
import { useSelector } from "react-redux";

export const AssignPopup = memo(({ initialAgentValues, handleSubmitAssign, setPopup, selectedMissions, selectedMissionsRef }) => {
    const { teamList } = useSelector((state) => state.team)
    const { agentList } = useSelector((state) => state.user)
    return (<div className="addcustomer-slide">
        <div className="addcustomer-field">
            <span className="title">Assign Agent</span>
        </div>

        <div className="addcustomer-information">
            <h2>Agent Information</h2>
            <div className="customer-form">
                <Formik
                    enableReinitialize
                    initialValues={initialAgentValues}
                    render={(formProps) => {
                        return (
                            <Form>
                                <Row className="custinfo-form">
                                    <Col xs={6}>
                                        <InputGroup className="mb-3 teamsl">
                                            <InputGroup.Text id="teamicon">
                                                <img src={teamicon} alt="teamicon" />
                                            </InputGroup.Text>
                                            <Field
                                                className="form-control"
                                                name="teamId"
                                                as="select"
                                                value={formProps.values["teamId"]}
                                                onChange={(e) => {
                                                    formProps.setFieldValue("teamId", e.target.value)
                                                }}
                                            >
                                                <option selected>Select Team</option>
                                                {teamList &&
                                                    teamList.length > 0 &&
                                                    teamList.map((t) => (
                                                        <option value={t.id}>
                                                            {t.teamName ? t.teamName : ""}
                                                        </option>
                                                    ))}
                                            </Field>
                                            <ErrorMessage name="teamId" component="div" />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="custinfo-form">
                                    <Col xs={6}>
                                        <InputGroup className="mb-3 teamsl">
                                            <InputGroup.Text id="teamicon">
                                                <img src={teamicon} alt="teamicon" />
                                            </InputGroup.Text>
                                            <Field
                                                className="form-control"
                                                name="agentId"
                                                as="select"
                                            >
                                                <option selected>Select Agent</option>
                                                {agentList &&
                                                    agentList.length > 0 && formProps.values["teamId"] &&
                                                    agentList.filter(a => {
                                                        return a.teamIds.some(t => t.id === formProps.values["teamId"])
                                                    }).map((t) => (
                                                        <option value={t.id}>
                                                            {t.firstName ? t.firstName : ""} {t.lastName ? t.lastName : ""}
                                                        </option>
                                                    ))}
                                            </Field>
                                            <ErrorMessage name="agentId" component="div" />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <div className="ct-btn">
                                    <Button
                                        className="cncle-btn"
                                        onClick={() => {
                                            setPopup({ shpw: false });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button className="ctaskbtn" type="submit" onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmitAssign(formProps.values, selectedMissions, selectedMissionsRef.current)
                                    }}>
                                        Let’s Go
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                    onSubmit={handleSubmitAssign}
                ></Formik>
            </div>
        </div>
    </div>)
})
