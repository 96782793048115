import {
    SAVE_EXTENSIONS,
    SAVE_USER_EXTENSIONS
} from "./actionTypes";

let initialState = {
    extensionList: []
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER_EXTENSIONS:
            return {
                ...state,
                extension: action.payload
            }
        case SAVE_EXTENSIONS:
            let extensionList = JSON.parse(JSON.stringify(state.extensionList))
            if (action.payload.createApi) {
                delete action.payload.createApi;
                extensionList.push(action.payload.extensions)
            }
            else if (action.payload.editApi) {
                delete action.payload.editApi;
                let extensionIndex = extensionList.findIndex(i => {
                    return i.id === action.payload.id
                })
                extensionList[extensionIndex] = action.payload
            }
            else if (action.payload.deleteApi) {
                delete action.payload.deleteApi;
                let extensionIndex = extensionList.findIndex(i => {
                    return i.id === action.payload.id
                })
                extensionList.splice(extensionIndex, 1)
            }
            else {
                extensionList = action.payload
            }
            return {
                ...state,
                extensionList: extensionList
            }
        default:
            return state
    }
}

export default reducer