import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_URL + "auth/";
const API_URL_USER = process.env.REACT_APP_API_URL + "users/stripe-signup-plans/";

const register = (data) => {
    return axios.post(API_URL + "register", { ...data, role: 2 });
};

const login = (data) => {
    return axios
        .post(API_URL + "login", data)
        .then((response) => {
            if (response.data.success) {
                if (response.data.user.token) {
                    localStorage.setItem("user", JSON.stringify(response.data.user));
                }
            }


            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const checkEmailTaken = (params) => {
    return axios.get(API_URL + 'checkEmail', { params: { ...params } });
};

const forgetPassword = (data) => {
    data = clean(data)
    return axios.post(API_URL + 'forget-password', data);
};

const resetPassword = (data) => {
    data = clean(data)
    return axios.post(API_URL + 'reset-password', data);
};

const setPassword = (data) => {
    data = clean(data)
    return axios.post(API_URL + 'set-password', data, { headers: authHeader() });
};

const getStripePlans = () => {
    return axios.get(API_URL_USER, {});
};

function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
    }
    return obj
}
const updateProfile = (data) => {
    // data = clean(data)
    return axios.post(API_URL + 'update-profile', data, { headers: authHeader() });
};

const getProfile = (data) => {
    data = clean(data)
    return axios.get(API_URL + 'getProfile', { headers: authHeader(), params: data });
};

const getSettings = (params) => {
    params = clean(params)
    return axios.get(API_URL + 'settings', { headers: authHeader(), params });
};

const getCommonServices = (params) => {
    params = clean(params)
    return axios.get(API_URL + 'common-services', { headers: authHeader(), params });
};

const saveSettings = (data) => {
    data = clean(data)
    return axios.post(API_URL + 'settings', data, { headers: authHeader() });
};

const saveCommonServices = (data) => {
    // data = clean(data)
    return axios.post(API_URL + 'common-services', data, { headers: authHeader() });
};

const uploadFile = (data) => {
    data = clean(data)
    return axios.post(API_URL + 'upload', data, {});
}


const upgradeUserSubscripton = (data) => {
    data = clean(data)
    return axios.post(`${API_URL}/upgradeSubscription`, { ...data }, { headers: authHeader() });
};
const downgradeUserSubscripton = (data) => {
    data = clean(data)
    return axios.post(`${API_URL}/downgradeSubscription`, { ...data }, { headers: authHeader() });
};

const getPaymentMethods = () => {
    return axios.get(`${API_URL}/paymentMethods`, { headers: authHeader() });
}

const addChangePaymentMethods = (data) => {
    return axios.put(`${API_URL}/paymentMethods`, data, { headers: authHeader() });
}

const calculateSubscriptionPrice = (data) => {
    return axios.post(`${API_URL}/calculateSubscriptionPrice`, data, { headers: authHeader() });
}

const updateSubscription = (data) => {
    return axios.post(`${API_URL}/updateSubscription`, data, { headers: authHeader() });
}
const cancelSubscription = (data) => {
    return axios.post(`${API_URL}/cancelSubscription`, data, { headers: authHeader() });
}
const flatFileAuth = () => {
    return axios.get(`${API_URL}auth-flatfile`, { headers: authHeader() });
}

const authService = {
    register,
    login,
    logout,
    checkEmailTaken,
    forgetPassword,
    resetPassword,
    getStripePlans,
    updateProfile,
    getSettings,
    getCommonServices,
    saveSettings,
    saveCommonServices,
    uploadFile,
    getProfile,
    upgradeUserSubscripton,
    downgradeUserSubscripton,
    flatFileAuth,
    getPaymentMethods,
    addChangePaymentMethods,
    calculateSubscriptionPrice,
    updateSubscription,
    cancelSubscription,
    setPassword
};

export default authService;