import { useEffect, useRef, useState } from "react";
import { ContextMenuTrigger, ContextMenu, MenuItem, showMenu } from "react-contextmenu";
import { useSelector } from "react-redux";
import { taskSingleClickHandler } from "../../helpers/helperFunctions"

const unAssignedTaskString = `dummm-${btoa(Math.random().toString()).substring(
    0,
    16
)}`;

const renderName = (obj) => {
    if (obj.customer && obj.customer.firstName) {
        return `${obj.customer.firstName ? obj.customer.firstName : ''} ${obj.customer.lastName ?
            obj.customer.lastName : ''}`
    }
    else if (obj.customerId) {
        return `${obj.customerId.firstName ? obj.customerId.firstName : ''} ${obj.customerId.lastName ?
            obj.customerId.lastName : ''}`
    }
    else {
        return ``
    }
}

const UnAssignedTaskList = (props) => {
    let ref = useRef();
    const userData = useSelector((state) => state.auth.user)
    const superAdminRole = userData && userData.role && userData.role === 1 ? true : false
    const [parentTop, setParentTop] = useState(0);
    const [parentLeft, setParentLeft] = useState(0);
    const correctContextMenuPosition = () => {
        if (ref.current) {
            let result = ref.current.getBoundingClientRect();
            if (result) {
                setParentLeft((result.left));
                setParentTop(-(result.top));
            }
        }
    }
    useEffect(() => {
        correctContextMenuPosition()
    }, [])

    const ContextMenuContainer = ({ string, id, createdBy }) => {
        return (
            <ContextMenu id={id}
                onShow={(e) => { !props.sideView && correctContextMenuPosition() }}
            >
                {string && (string === unAssignedTaskString) && <MenuItem onClick={props.editMenuHandler}>Edit</MenuItem>}
                {string && (string === unAssignedTaskString) && <MenuItem onClick={props.deleteMenuHandler}>Delete</MenuItem>}
                {string && (string === unAssignedTaskString) && props.sideView && <MenuItem onClick={() => props.setCalendarView(true)}>Schedule Delivery</MenuItem>}
                {string && (string === unAssignedTaskString) &&
                    <MenuItem onClick={props.duplicateTaskHandler}>Duplicate</MenuItem>}
            </ContextMenu>)

    }

    const handleContextClick = (event, id, div_id) => {
        event.persist();
        event.preventDefault();
        const ele = document.getElementById(div_id);
        const positionInfo = ele.getBoundingClientRect();
        const x = positionInfo.right - positionInfo.left + 145;
        showMenu({
            position: { x: x, y: event.pageY },
            target: event,
            id: id
        });
    };

    let ListViewComponent = (task, i) => {
        return (
            <div
                id={"listview"}
                ref={ref}
                draggable
                onDragStart={(e) => { props.dragStart(e, task) }}
                key={task.id}
                className={`uns-field-ck ${task.active && task.active == true ? "active" : ""
                    }`}
                onClick={(e) => taskSingleClickHandler({ e, task, i, ...props.taskClickProps })}
                onContextMenu={(e) => {
                    e.preventDefault()
                    taskSingleClickHandler({ e, task, i, ...props.taskClickProps })
                    setTimeout(() => {
                        handleContextClick(e, task.id, "listview")
                    }, 200);
                }}
            >
                <div className={`unassign-field-box completed-bg ${!props.borderColor ? "unassigned" : ""}`} style={props.borderColor ? { borderColor: `${props.borderColor}` } : {}}>
                    <div className="unassign-detail">
                        <div className="unassign-title">
                            <span className="unls">
                                {task.orderType ? task.orderType : "P"}
                            </span>
                            <span className="title">
                                {i + 1}.{" "}
                                {renderName(task)}
                            </span>
                        </div>
                    </div>
                    <div className="unassign-description">
                        <p>
                            {" "}
                            {task && task.address
                                ? task.address
                                : `124 Verdun St, Montreal, H4G2Y5, Qc, Canada`}
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    return (<>
        {props.unassignedTaskList &&
            props.unassignedTaskList.length > 0 &&
            props.unassignedTaskList.map((task, i) => {
                if (props.sideView) {
                    return (<>
                        {/* <ContextMenuTrigger id={task.id} holdToDisplay={-1} key={task.id}> */}
                        {ListViewComponent(task, i)}
                        {/* </ContextMenuTrigger> */}
                        <ContextMenuContainer string={unAssignedTaskString} id={task.id} /></>
                    );
                }
                else {
                    return (<>
                        {/* <ContextMenuTrigger id={task.id} holdToDisplay={-1} key={task.id}> */}
                        {ListViewComponent(task, i)}
                        {/* </ContextMenuTrigger> */}
                        <div style={{ position: "fixed", top: `${parentTop}px`, left: `${parentLeft}px` }}><ContextMenuContainer string={unAssignedTaskString} id={task.id} /></div></>
                    );
                }
            })}

    </>)
}


export default UnAssignedTaskList;