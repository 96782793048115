// TODO: Resolve lint issues
/* eslint-disable */
import { combineReducers } from 'redux';
/******************* Reducers
 */
import authSagaReducer from './auth/reducers';
import alertReducer from './alert/reducers';
import extensionReducer from './extensions/reducers';
import subscriptionReducer from './subscription/reducers';
import teamReducer from './team/reducers';
import hubReducer from './hub/reducers';
import builderReducer from './builder/reducers';
import userReducer from './user/reducers';
import taskReducer from './task/reducers';
import invoicingReducer from './invoicing/reducers';

//old reducers
import authReducer from "../slices/auth";
import messageReducer from "../slices/message";
import extensionsReducer from "../slices/extensions"
import defaultSubscriptionReducer from "../slices/subscription"


/******************* Root Reducer
 */
const rootReducer = combineReducers({
    authSaga: authSagaReducer,
    alert: alertReducer,
    extensions: extensionReducer,
    subscription: subscriptionReducer,
    team: teamReducer,
    hub: hubReducer,
    builder: builderReducer,
    user: userReducer,
    task: taskReducer,
    // old reducers
    auth: authReducer,
    message: messageReducer,
    extensions: extensionsReducer,
    defaultSubscription: defaultSubscriptionReducer,
    invoicing: invoicingReducer
});

export default rootReducer