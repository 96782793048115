import { BsLayoutSidebar, BsListUl } from "react-icons/bs";
import { MdKeyboardArrowUp } from "react-icons/md";
import { CgMenuBoxed } from "react-icons/cg";
import { useState } from "react";
import appDefaults from "../../../../helpers/app-defaults";
import polygon from "../../../../assets/image/polygon.png";
import Control from "../../../../assets/image/Control.png";
import Filter from "../../../../assets/image/Filter.png";
import Plan from "../../../../assets/image/Plan.png";
import layer from "../../../../assets/image/layer.png";
import "./BottomMenu.scss"
import MenuList from "./MenuList";

const BottomMenu = (props) => {
    const [isHovering, setIsHovering] = useState(false);
    const [isHoveringt, setIsHoveringt] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [hovering, setHovering] = useState(null);

    const handleFilterClick = () => {
        setShowSubMenu(!showSubMenu)
    }
    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const handleMouseOvert = () => {
        setIsHoveringt(true);
    };

    const handleMouseOutt = () => {
        setIsHoveringt(false);
    };

    const handleMouseOverList = () => {
        setHovering({
            type: appDefaults.VIEWS.LIST_VIEW,
            text: "List view"
        });
    };

    const handleMouseOutList = () => {
        setHovering(null);
    };

    const MapControls = () => {
        return (<div className="bottom-map-control">
            <div className="dbt-menu">
                <div
                    className={"menu-btn"}
                >
                    <span className={props.mapControls && props.mapControls.drawPolygon ? "menuicon selected" : "menuicon"} onClick={() => {
                        props.setMapControls((prev) => {
                            return {
                                ...prev,
                                drawPolygon: !props.mapControls.drawPolygon
                            }
                        })
                    }}>
                        <img src={polygon} alt={"polygon"}></img>
                    </span>
                    {/* <span className="menuiconright">
                        <img src={layer} alt={"layer"}></img>
                    </span> */}
                </div>
            </div>
        </div>
        );
    }

    return (<div className="main-container-bottom-menu">
        <div className="left-container">
            <div className="left">
                {isHovering && (
                    <div className="plan-s-text">
                        <p>Plan and Schedule</p>
                    </div>
                )}

                {isHoveringt && (
                    <div className="sup-cont-text">
                        <p>Supervise and Control</p>
                    </div>
                )}
                {hovering && (
                    <div className="hover-cont-text">
                        <p>{hovering?.text}</p>
                    </div>
                )}
                <div className={`icon first-icon ${props.viewType === appDefaults.VIEWS.PLANNER_CONTROL ? "selected" : ""}`}
                    onClick={() => { props.setViewType(appDefaults.VIEWS.PLANNER_CONTROL); props.viewTypeRef.current = appDefaults.VIEWS.PLANNER_CONTROL }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}>
                    <img src={Plan} alt="Plan" />
                </div>
                <div onClick={() => { props.setViewType(appDefaults.VIEWS.SUPERVISE_CONTROL); props.viewTypeRef.current = appDefaults.VIEWS.SUPERVISE_CONTROL }}
                    onMouseOver={handleMouseOvert}
                    onMouseOut={handleMouseOutt}
                    className={`icon middle-icon ${props.viewType === appDefaults.VIEWS.SUPERVISE_CONTROL ? "selected" : ""}`}>
                    <img src={Control} alt="control" />
                </div>
                <div onClick={() => { props.setViewType(appDefaults.VIEWS.LIST_VIEW); props.viewTypeRef.current = appDefaults.VIEWS.LIST_VIEW }}
                    onMouseOver={handleMouseOverList}
                    onMouseOut={handleMouseOutList}
                    className={`icon ${props.viewType === appDefaults.VIEWS.LIST_VIEW ? "selected" : ""}`}>
                    <img src={Filter} alt="Filter" />
                </div>
            </div>
            <div>
                {showSubMenu && <MenuList {...props} />}
                <div className="right" onClick={handleFilterClick}>
                    <p>Filter</p>
                    <MdKeyboardArrowUp className="icon" />
                </div>
            </div>
        </div>
        <div className="right-container">
            {MapControls()}
        </div>
    </div>)
}

export default BottomMenu;