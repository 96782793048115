
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);

const Chart = () => {
    const data = {
        labels: [],
        datasets: [
            {
                label: '# of Votes',
                data: [60, 40],
                backgroundColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 199, 132, 1)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)'
                ],
                borderWidth: 0,
            }
        ],
    }

    const options = { cutout: 25 }

    const plugins = [{
        beforeDraw: function (chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 160).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            var text = "100%",
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    }]



    return (

        <Doughnut
            type="doughnut"
            data={data}
            height="70px"
            width="70px"
            options={options}
            plugins={plugins}
        />
    );
}

export default Chart