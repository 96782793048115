import React, { useState } from "react";
import {
  Button,
} from "react-bootstrap";
import "./Export.scss";
import taskService from "../../services/task.service";
import closeicon from "../../assets/image/close-icon.png";
import uploading from "../../assets/image/importimg.png";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { ExportToCsv } from "export-to-csv";
import moment from "moment";

function OrderTypeFormatter(item) {
  if (item.orderType === "D") return "Drop"
  if (item.orderType === "DI") return "Drop & Service"
  if (item.orderType === "S") return "Service"
  if (item.orderType === "R") return "Return"
  if (item.orderType === "P") return "Pick Up"
  if (item.orderType === "E") return "Exchange"
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


const ExportFile = (props) => {
  const [value, onChange] = useState([new Date(), new Date()]);
  const handleSubmit = async () => {
    let params = {
      limit: 10000,
      startDate: value[0],
      endDate: value[1],
      all: true
    }
    const response = await taskService.getTaskList(params);
    if (response.status === 200 && response.data) {
      let data = [];
      response.data?.result?.results?.map((item, index) => {
        let d = {
          "Task ID": item.taskNumber || "N/A",
          "Customer First Name": item.customer && item.customer.firstName ? `${item.customer.firstName}` : (item.customerId && item.customerId.firstName ? `${item.customerId.firstName}` : "N/A"),
          "Customer Last Name": item.customer && item.customer.lastName ? `${item.customer.lastName}` : (item.customerId && item.customerId.lastName ? `${item.customerId.lastName}` : "N/A"),
          "Customer Email": item.customer && item.customer.email ? `${item.customer.email}` : (item.customerId && item.customerId.email ? `${item.customerId.email}` : "N/A"),
          "Customer Phone Number": item.customerId && item.customerId.phoneNumber ? `${item.customerId.countryCode}${item.customerId.phoneNumber}` : "N/A",
          "Apt/Bld": item.apartment || "N/A",
          "Address": item.address || "N/A",
          "Latitude": item.location ? item.location.lat : "N/A",
          "Longitude": item.location ? item.location.lng : "N/A",
          "Order Type": OrderTypeFormatter(item) || "N/A",
          "Task Notes": item.notes || "N/A",
          "Scheduled Time": item.scheduledTime || "N/A",
          "Delivery After Time": item.after || "N/A",
          "Delivery Before Time": item.before || "N/A",
          "Task Duration": item.durationTime ? `${item.durationTime} ${"min"}` : "N/A",
          "Task Capacity": item.capacity || "N/A",
          "Task Status": capitalizeFirstLetter(item.taskStatus) || "N/A",
          "Failed Reason": item.reason || "N/A",
          "Task Price": item.price ? item.price : "0",
          "Team Name": item.teamId ? item.teamId.teamName : "N/A",
          "Agent Name": item.agentId ? `${item.agentId.firstName ? item.agentId.firstName : ""}${item.agentId.lastName ? item.agentId.lastName : ""}` : "N/A",
          "Agent Notes": item.agentNotes ? item.agentNotes : "",
          "Task Creation Date": item.createdDate ? moment(moment(item.createdDate)._a).format("LL") : "N/A",
          "Task Completion Date": item.orderCompletedDate ? moment(moment(item.orderCompletedDate)._a).format("LL") : "N/A",
          "Task Created By": item.userId ? `${item.userId.companyName ? item.userId.companyName : ""}` : "N/A",
          "Task Created For": item.merchantId ? `${item.userId.merchantId ? item.userId.merchantId : ""}${item.merchantId.lastName ? item.merchantId.lastName : ""}` : "N/A",
        };
        data.push(d);
      });

      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "Tasks List",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "tasks",
      };

      const csvExporter = new ExportToCsv(options);
      if (data.length > 0) {
        csvExporter.generateCsv(data);
      } else {
        props.errorAlert("No data found");
      }
    } else {
      props.errorAlert(response.message ? response.message : "Something went wronng");
    }
  }

  return (
    <>
      <div className="openimport-popup" >
        <div className="addimport-popup">
          <div className="addimport-slide">

            <div className="customer-import">

              <Button
                className="import-close"
                onClick={() => props.setExportTasks(false)}
              >
                <img src={closeicon} alt="icon" />
              </Button>
            </div>
            <div className="addimport-filebox">
              <div className="customer-form">
                <div className="import-pheading">
                  <div className="import-heading">
                    <h2>Export your tasks</h2>
                    <p>Select the date or the date range required.</p>
                  </div>
                </div>

                <div className="export-upload-img">
                  <div className="picker_wrapper">
                    <DateRangePicker
                      showDoubleView={true}
                      onChange={onChange}
                      value={value} />
                  </div>

                  <div className="upload-img">
                    <img src={uploading} alt="img" />
                    <div className="upload-btn">
                      <button className="btn" onClick={() => { handleSubmit() }}>Export</button>
                    </div>
                    <div className="drag-upload">

                    </div>
                  </div>
                  {/* </FileUploader> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportFile;
