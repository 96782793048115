import {
    SAVE_DEFAULT_SUBSCRIPTION
} from "./actionTypes";

let initialState = {}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_DEFAULT_SUBSCRIPTION:
            return {
                ...state,
                defaultSubscription: action.payload
            }
        default:
            return state
    }
}

export default reducer