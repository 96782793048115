import { IoMdTime } from "react-icons/io";
import { MdOutlineAllInbox } from "react-icons/md";
import {
    formatAMPM,
    renderCustomerName,
} from "../../../../helpers/helperFunctions";
import appDefaults from "../../../../helpers/app-defaults";
import { TASK_STATUSES } from "../../../../helpers/configDefaults";

const Tasks = (props) => {
    let task = props.commonProps?.listingSection === appDefaults.LISTING_TYPE.MISSION ? props.item : props.task;
    const SubComponent = () => {
        return (<div onClick={(e) => {
            !props.enablePropagation && e.stopPropagation();
            if (props.commonProps?.listingSection === appDefaults.LISTING_TYPE.MISSION) {
                props.commonProps?.missionTaskClickHandler(e, task)
            }
        }} onContextMenu={(e) => {
            !props.enablePropagation && e.stopPropagation();
            if (props.commonProps?.listingSection === appDefaults.LISTING_TYPE.MISSION) {
                props.commonProps?.missionTaskClickHandler(e, task)
                props.commonProps.missionTaskContextHandler(e, task)
            }
        }} draggable={props.commonProps?.listingSection === appDefaults.LISTING_TYPE.MISSION ? false : true} onDragStart={(e) => props.dragStart(e, task)} className={`${"mission-task"} ${task?.taskStatus ? `title-${task?.taskStatus}` : ""} ${task?.active ? "selected" : ""} ${props.unassignedList ? "unassigned-task" : ""}`}>
            <div className="pic">
                <p>{task?.orderType}</p>
            </div>
            <div className="text">
                <div className="mission-task-top">
                    <div className="name-and-address">
                        <h4>{props.commonProps?.listingSection === appDefaults.LISTING_TYPE.MISSION && `${task.position + 1}. `}{renderCustomerName(task)}</h4>
                        <p>{task?.address}</p>
                    </div>
                    {task?.missioned && <p className="time">{task?.eta ? task?.eta : ""}</p>}
                </div>
                <div className="mission-task-bottom">
                    {task?.merchantId && task?.merchantId.role === 4 && task?.merchantId.companyName && <div className="btn">
                        <MdOutlineAllInbox className='icon' />
                        <p>{`${task?.merchantId.companyName}`}</p>
                    </div>}
                    {task?.merchantId && task?.merchantId.role === 2 && task?.merchantId.companyName && <div className="btn">
                        <MdOutlineAllInbox className='icon' />
                        <p>{`${task?.merchantId.companyName}`}</p>
                    </div>}
                    {task?.scheduledTime && <div className="btn">
                        <IoMdTime className='icon' />
                        <p>{formatAMPM(task?.scheduledTimeSeconds)}</p>
                    </div>}
                    {(task?.after || task?.before) && <div className="btn">
                        <IoMdTime className='icon' />
                        <p>{`${task?.after ? formatAMPM(task?.afterSeconds) : ""}-${task?.before ? formatAMPM(task?.beforeSeconds) : ""}`}</p>
                    </div>}
                </div>
            </div>

        </div>)
    }
    const Draggable = () => {

        const { onMouseDown } = props.dragHandleProps;
        return (<div className="disable-select" draggable>
            <div
                className="disable-select dragHandle"
                // style={{
                //   fontWeight: "600",
                //   transform: "rotate(90deg)",
                //   width: "20px",
                //   height: "20px",
                //   backgroundColor: "black"
                // }}
                onMouseDown={(e) => {
                    !props.enablePropagation && e.stopPropagation();
                    if (props.commonProps?.listingSection === appDefaults.LISTING_TYPE.MISSION) {
                        props.commonProps?.missionTaskClickHandler(e, task)
                    }
                    onMouseDown(e)
                }}
                onTouchEnd={(e) => {
                    // e.target.style.backgroundColor = "black";
                    // document.body.style.overflow = "visible";
                }}
                onMouseUp={() => {
                    // document.body.style.overflow = "visible";
                }}
            >
                <SubComponent />
            </div>
        </div>)
    }
    return (
        props.commonProps?.listingSection === appDefaults.LISTING_TYPE.MISSION ? <Draggable /> : <SubComponent />
    )
}

export default Tasks