import React, { useState, useEffect } from "react";
import StripeForm from "./stripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const Paymentinfo = ({ step, setStep, formData, setformData }) => {
  return (
    <div>
      <Elements stripe={stripeTestPromise}>
        <StripeForm
          step={step}
          setStep={setStep}
          formData={formData}
          setformData={setformData}
        />
      </Elements>
    </div>
  );
};

export default Paymentinfo;
