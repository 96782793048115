import React, { useState, useEffect } from "react";
import "./Team.scss";

import UserComponent from './UserTab'
import { ToastContainer } from "react-toastify";
import ContentLayout from "../../components/contentlayout";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import "react-tabs/style/react-tabs.css";
import { useSelector } from "react-redux";
const Team = (props) => {

  const [countryCode, setCountryCode] = useState("");
  useEffect(() => {
    if (!countryCode) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        displayLocation(position.coords.latitude, position.coords.longitude);
      })
    }
  }, [])
  function displayLocation(latitude, longitude) {
    var geocoder;
    geocoder = new window.google.maps.Geocoder();
    var latlng = new window.google.maps.LatLng(latitude, longitude);

    geocoder.geocode(
      { 'latLng': latlng },
      function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            let findCountry = results[0].address_components.find(items => { return items.types[0] === "country" })
            if (findCountry) {
              setCountryCode(findCountry.short_name)
            }
            else {
              setCountryCode("CA")
            }
          }
          else {
            setCountryCode("CA")
          }
        }
        else {
          setCountryCode("CA")
        }
      }
    );
  }
  const defaultSubscriptionData = useSelector((state) => state.defaultSubscription);
  const extensionData = useSelector((state) => state.extensions);
  const [features, setFeatures] = React.useState({});

  useEffect(() => {
    if (defaultSubscriptionData && defaultSubscriptionData.extension && defaultSubscriptionData.extension.data && defaultSubscriptionData.extension.data.results.length) {
      setFeatures({ ...defaultSubscriptionData.extension.data.results[0].features })
    }
  }, [defaultSubscriptionData])

  useEffect(() => {
    extensionData && extensionData.extension && extensionData.extension.length && extensionData.extension.map((data) => {
      if (data.isEnabled) {
        data.userExtensions.map((extension) => {
          setFeatures((prev) => {
            return {
              ...prev,
              ...extension.features,
              additionalTaskNumber: Number(extension.features.additionalTaskNumber)
            }
          })
        })
      }
    })
  }, [extensionData])

  return (
    <>
      <ContentLayout>
        <div className="customer-page-wrapper d-flex">
          {/* customer layout start */}
          <div className="customer-form-wrapper agent-wrapper">
            <Tabs>
              <TabList>
                <Tab>Team</Tab>
                {/* <Tab>Team</Tab> */}
              </TabList>
              <TabPanel>
                <UserComponent {...props} features={features} setFeatures={setFeatures} countryCode={countryCode} />
              </TabPanel>
              {/* <TabPanel>
                <TeamComponent {...props} features={features} setFeatures={setFeatures} countryCode={countryCode} />
              </TabPanel> */}
            </Tabs>
          </div>

          {/* customer layout end */}
        </div>


        {/* create team popup start */}
        {/* create team popup end */}
        <ToastContainer
          style={{ color: "black" }}
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ContentLayout>
    </>
  );
};

export default Team;
