import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Table,
  Form as BootstrapForm,
  Form,
  Button,
} from "react-bootstrap";
import "./Import.scss";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import closeicon from "../../assets/image/close-icon.png";
import checkicon from "../../assets/image/mapcheckicon.png";
import importTaskMapping from "./importTaskMapping.json";
import { MdOutlineArrowForwardIos } from "react-icons/md";
const ImportPopup = (props) => {
  const continueHandler = () => {
    let findUnMappedValue = props.importData.find(i => {
      return i.value === importTaskMapping[0].value
    })

    if (findUnMappedValue) {
      return props.errorAlert("There are some unmapped values with properties!!")
    }
    props.setStep(props.step + 1)
  }
  const backHandler = () => {
    props.setStep(props.step - 1)
  }

  const handleChange = (e, index) => {
    let importData = [...props.importData];
    importData[index].value = e.target.value;
    let findColumnData = importTaskMapping.find(i => i.value === e.target.value)
    if (findColumnData && findColumnData.name === importData[index].name) {
      importData[index].mapped = true
    }
    else {
      importData[index].mapped = false
    }
    props.setImportData(importData)
  }

  const DropDownItems = ({ selected, index }) => {
    return (
      <Form.Select
        defaultValue={selected}
        onChange={(e) => {
          handleChange(e, index)
        }}
      >
        {importTaskMapping.map((i) => {
          return (
            <option value={i.value}>{i.name}</option>
          )
        })}
      </Form.Select>
    )
  }

  const MapImportedData = () => {
    return props.importData.map((i, index) => {
      return (<tr key={index}>
        <td>{i.name}</td>
        <td>{i.data[0]}</td>
        <td>{i.mapped && <img src={checkicon} />}</td>
        <td>
          <DropDownItems selected={i.value} index={index} />
        </td>
      </tr>)
    })
  }

  return (
    <>
      <div className="openimport-popup">
        <div className="addimport-popup">
          <div className="addimport-slide mapfile-popup">
            <div className="customer-import">
              <Button className="import-close"
                onClick={() => props.setImportToggle(false)}>
                <img src={closeicon} alt="icon" />
              </Button>
            </div>
            <div className="addimport-filebox">
              <div className="customer-form">
                <div className="import-pheading">
                  <div className="import-heading">
                    <h2>Map columns in your file</h2>
                    <p>
                      Each column header below should be match to a property in
                      Gomove.
                    </p>
                  </div>
                  <div className="import-breadcurm">
                    <div className="breadcurm">
                      <Link className="fill">Upload</Link>
                      <MdOutlineArrowForwardIos />
                      <Link className="active">Match</Link>
                      <MdOutlineArrowForwardIos />
                      <Link>Complete</Link>
                    </div>
                    <div className="bkbtn">
                      <Button onClick={backHandler} className="backbtn">Go Back</Button>
                      <Button onClick={continueHandler} className="nextbtn">Next</Button>
                    </div>
                  </div>
                </div>
                <div className="import-mapfile">
                  <Table>
                    <thead>
                      <tr>
                        <th style={{ width: "260px" }}>Column Header</th>
                        <th>Preview</th>
                        <th>Mapped</th>
                        <th style={{ width: "260px" }}>Gomove Property</th>
                      </tr>
                    </thead>
                    <tbody>
                      <MapImportedData />
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportPopup;
