import styled from "styled-components";

export const DeliveryConfirmedContainer = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: ${({ theme }) => theme.colors.bgColor};
  padding: 3rem 0;
  .line {
    display: none;
  }
  .back-btn {
    display: none;
  }
  @media (max-width: 1100px) {
    .line {
      position: absolute;
      display: block;
      width: 89%;
      height: 1px;
      background: ${({ theme }) => theme.colors.darkTextColor};
      top: 230px;
    }
    padding: 1.4rem;
    .back-btn {
      display: block;
      position: relative;
      align-self: flex-start;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.whiteColor};
      }

      .icon {
        z-index: 2;
        width: 40px;
        height: 40px;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.bgColor};
      }
    }
  }
  .wrapper {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
    gap: 1rem;
    padding: 1.4rem;
    background: ${({ theme }) => theme.colors.bgColorVariant};
    .for-pc {
      width: 50%;
      margin: 2rem 0;
    }
    @media (max-width: 1100px) {
      width: 100%;
      text-align: center;
      padding: 1.4rem;
      border-radius: 10px;
      .for-pc {
        display: none;
      }
      h5 {
        font-size: 12px;
      }
    }
    > h4 {
      color: ${({ theme }) => theme.colors.darkTextColor};
      @media (max-width: 1100px) {
      }
    }
    h3 {
      font-size: 17px;
      color: ${({ theme }) => theme.colors.whiteColor};
      @media (max-width: 1100px) {
        margin-top: 2rem;
      }
    }

    p {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.darkTextColor};
    }
    .selected-date-time {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (max-width: 1100px) {
        gap: 8px;
      }
      .selected-time-range {
        display: flex;

        p {
          font-size: 12px;
          margin-left: 2px;
          font-weight: bold;
          color: ${({ theme }) => theme.colors.darkTextColor};
          @media (max-width: 1100px) {
            font-size: 11px;
            font-weight: bold;
          }
        }
        .icon {
          margin-right: 12px;
          color: ${({ theme }) => theme.colors.darkTextColor};
        }
      }
      .selected-date {
        display: flex;
        justify-content: flex-start;
        .icon{
          margin-right: 15px;
         }
        .day {
          margin-left: 1px;
        }

        p {
          padding-left: 1px;
          font-weight: bold;
          font-size: 12px;
          color: ${({ theme }) => theme.colors.darkTextColor};
          @media (max-width: 1100px) {
            font-size: 11px;
            font-weight: bold;
          }
        }
        .icon {
          color: ${({ theme }) => theme.colors.darkTextColor};
        }
      }
      .selected-time-zone {
        @media (max-width: 1100px) {
          margin-left: 30px;
        }
        margin-left: 30px;
        p {
          font-size: 12px;
          font-weight: bold;
          color: ${({ theme }) => theme.colors.darkTextColor};
          @media (max-width: 1100px) {
            font-size: 11px;
            font-weight: bold;
          }
        }
      }
    }
    h5 {
      color: ${({ theme }) => theme.colors.whiteColor};
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }
`;

export const DeliveryContainer = styled.div`
  @media (max-width: 1100px) {
    h2 {
      font-size: 20px;
    }
  }
  text-align: left;
  .furniture-delivery-icn {
    @media (max-width: 1100px) {
      margin-top: 4rem;
    }
    margin-bottom: 1.4rem;
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${({ theme }) => theme.colors.whiteColor};
    .icon {
      color: ${({ theme }) => theme.colors.whiteColor};
      width: 20px;
      height: 20px;
    }
  }
`;
