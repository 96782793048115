import React, { useState } from "react";
import {
  SelectTimeContainer,
  TimePickContainerForMobile,
  TimePickContainerForMobileSelected
} from "./SelectTime-styled";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import TimePicker from "react-time-picker";
import { Days, Months } from "../../data/DatesAndMonths";
import { useHistory } from "react-router-dom";
import moment from "moment";

/**************************This Component for only Mobile view***************************/

const SelectTime = ({ passingData, setStep, setPassingData, slots, setSlots, task, updateScheduleDelivery }) => {
  const [selectedSlot, onSelectedSlot] = useState();
  const sendData = () => {
    let data = {
      selectedSlot: selectedSlot,
      taskIds: [task.id],
      orderDate: passingData.passingDate
    }
    let response = updateScheduleDelivery(data);
    setStep(4)
    setPassingData({
      passingDate: passingData.passingDate,
      passingTimeName: passingData.passingTimeName,
      passingTimeLabal: passingData.passingTimeLabal,
      selectedSlot
    })
  };

  const setSelectedSlot = (i) => {
    onSelectedSlot(i);
    let allSlots = JSON.parse(JSON.stringify(slots));
    allSlots = allSlots.map(s => {
      if (s.id === i.id)
        s.selected = true
      return s
    })
    setSlots(allSlots)
  }

  const goBack = () => {
    setStep(1)
    setPassingData({
      passingDate: passingData.passingDate,
      passingTimeName: passingData.passingTimeName,
      passingTimeLabal: passingData.passingTimeLabal,
      selectedSlot
    })
  };

  return (
    <SelectTimeContainer>
      <hr className="line" />

      <div className="wrapper">
        <div className="back-btn" onClick={() => { goBack() }}>
          <a><BsFillArrowLeftCircleFill className="icon" /></a>
        </div>
        <div className="date">
          <h2 className="day-h">
            {moment(passingData.passingDate).format("dddd")}
          </h2>
          <div className="sub-date">
            <div className="month">
              <p>
                {moment(passingData.passingDate).format("LL")}
              </p>
            </div>
          </div>
        </div>
        <div className="time-zone-container">
          <h5>Time zone</h5>
          <BiTimeFive className="icon" />
          <p>
            {passingData.passingTimeName.slice(0, 20)} &
            {passingData.passingTimeLabal.slice(0, 11)}
          </p>
        </div>
        <h3>Select a Time</h3>
        <div className="button-container">
          {slots?.map((i, index) => {
            if (i.selected) {
              return (<TimePickContainerForMobileSelected key={index} onClick={() => {
                setSelectedSlot(i)
              }}>
                <div className="time-container">
                  <p className={`slot`}>{i.after} - {i.before}</p>
                </div>
              </TimePickContainerForMobileSelected>)
            }
            else {
              return (<TimePickContainerForMobile key={index} onClick={() => {
                setSelectedSlot(i)
              }}>
                <div className="time-container">
                  <p className={`slot`}>{i.after} - {i.before}</p>
                </div>
              </TimePickContainerForMobile>)
            }
          })}
          <button className="confirm-btn" onClick={() => sendData()}>
            confirm
          </button>
        </div>
      </div>
    </SelectTimeContainer>
  );
};

export default SelectTime;
