
export const commonServicesTypes = {
    RESPONSIBILITY_DISCHARGE: "RESPONSIBILITY_DISCHARGE",
    CONFIRMATION_DELIVERY: "CONFIRMATION_DELIVERY",
    CUSTOMER_EXPERIENCE_NOTIFICATIONS: "CUSTOMER_EXPERIENCE_NOTIFICATIONS",
    CUSTOMER_EXPERIENCE_COMMUNICATIONS: "CUSTOMER_EXPERIENCE_COMMUNICATIONS",
    COMMON_RESPONSIBILITY_DISCHARGE: "COMMON_RESPONSIBILITY_DISCHARGE",
    COMMON_CONFIRMATION_DELIVERY: "COMMON_CONFIRMATION_DELIVERY",
    COMMON_CUSTOMER_EXPERIENCE_NOTIFICATIONS: "COMMON_CUSTOMER_EXPERIENCE_NOTIFICATIONS",
    COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS: "COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS",
    PLANNER_LABELS: "PLANNER_LABELS",
    TAX_GROUP: "TAX_GROUP",
    COMMON_TAX_GROUP: "COMMON_TAX_GROUP",
    TERMS: "TERMS",
    COMMON_TERMS: "COMMON_TERMS",
    PAYMENT_METHOD: "PAYMENT_METHOD",
    DEPOSIT_METHOD: "DEPOSIT_METHOD"
}

export const settingSubType = {
    TASK_AND_MISSION: "TASK_AND_MISSION",
    AGENT_APP: "AGENT_APP",
    GROUPS: "GROUPS",
    COMMUNICATIONS: "COMMUNICATIONS",
    NOTIFICATIONS: "NOTIFICATIONS",
    API_KEY: "API_KEY",
    PERFORMANCE: "PERFORMANCE",
    REGIONAL_SETTINGS: "REGIONAL_SETTINGS",
    LEGAL_PROTECTION: "LEGAL_PROTECTION",
    SCHEDULE_DELIVERY: "SCHEDULE_DELIVERY",

    COMMON_TASK_AND_MISSION: "COMMON_TASK_AND_MISSION",
    COMMON_AGENT_APP: "COMMON_AGENT_APP",
    COMMON_GROUPS: "COMMON_GROUPS",
    COMMON_COMMUNICATIONS: "COMMON_COMMUNICATIONS",
    COMMON_NOTIFICATIONS: "COMMON_NOTIFICATIONS",
    COMMON_API_KEY: "COMMON_API_KEY",
    COMMON_PERFORMANCE: "COMMON_PERFORMANCE",
    COMMON_REGIONAL_SETTINGS: "COMMON_REGIONAL_SETTINGS",
    COMMON_LEGAL_PROTECTION: "COMMON_LEGAL_PROTECTION",
    COMMON_SCHEDULE_DELIVERY: "COMMON_SCHEDULE_DELIVERY"
}

export const settingTypes = {
    CUSTOMER_EXPERIENCE: "CUSTOMER_EXPERIENCE",
    ADVANCE_SETTINGS: "ADVANCE_SETTINGS",
    OPERATIONS: "OPERATIONS"
}

export const USER_ROLES = {
    ADMIN: 1,
    USER: 2,
    CUSTOMER: 3,
    MERCHANT: 4,
    AGENT: 5
}

export const TASK_STATUSES = {
    UNASSIGNED: "unassigned",
    ASSIGNED: "assigned",
    ONGOING: "ongoing",
    STARTED: "started",
    REACHED: "reached",
    DELAYED: "delayed",
    COMPLETED: "completed",
    FAILED: "failed"
}