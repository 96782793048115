import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "team/";


const getTeamList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser && currentUser.role !== 1) params.userId = currentUser.id
    if (!params.searchText) delete params.searchText
    return axios.get(API_URL, { headers: authHeader(), params: { limit: 10, ...params } });
};

const getAllTeams = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    return axios.get(`${API_URL}all`, { headers: authHeader(), params });
};

const createTeam = (data) => {
    data = clean(data)
    return axios.post(API_URL, data, { headers: authHeader() });
};

const editTeam = (data) => {
    data = clean(data)
    return axios.patch(`${API_URL}/${data.id}`, data, { headers: authHeader() });
};
const deleteTeam = (id) => {
    return axios.delete(`${API_URL}/${id}`, { headers: authHeader() });
};
const blockManyTeams = (teamIds) => {
    return axios.patch(API_URL + 'multipleBlock', { teamIds }, { headers: authHeader() });

};

const deleteManyTeams = (teamIds) => {
    const headerObj = { data: { teamIds }, headers: authHeader() }
    console.log("headers::", headerObj)

    return axios.delete(API_URL + 'multipleDelete', headerObj);

};

function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
    }
    return obj
}

const userService = {
    getTeamList,
    createTeam,
    editTeam,
    deleteTeam,
    deleteManyTeams,
    blockManyTeams,
    getAllTeams
};

export default userService