import React, { useState, useEffect } from "react";
import "./Import.scss";
import ImportFile from "./Importfile";
import { Loader } from "../../components/Loader/loader";
import MapFile from "./Mapfile";
import Importdata from "./Importdata";
const ImportPopup = (props) => {
  const [step, setStep] = useState(1)
  const [rows, setRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [importData, setImportData] = useState([])
  const [importApiData, setImportApiData] = useState([])

  let getTaskListDashboard
  if (props.getTaskList) {
    getTaskListDashboard = props.getTaskList
  }
  else if (props.getUnassignedTaskList) {
    getTaskListDashboard = props.getUnassignedTaskList
  }
  let importProps = {
    ...props,
    getTaskListDashboard,
    step: step,
    setStep: setStep,
    setImportData,
    importData,
    importApiData,
    setImportApiData,
    setRows,
    rows,
    setLoading
  }
  return (
    <>
      <div className="import-form">
        {step === 1 && <ImportFile {...importProps} />}
        {step === 2 && <MapFile {...importProps} />}
        {step === 3 && <Importdata {...importProps} />}
        {loading ? <Loader popup={true} /> : null}
      </div>
    </>
  );
};

export default ImportPopup;
