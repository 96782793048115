// TODO: Resolve lint issues
/* eslint-disable */
import {
    put,
    takeLatest,
    all,
    call,
    select,
    putResolve,
    takeEvery,
} from 'redux-saga/effects'

/********************* ACTION TYPE ********************* */
import {
    AUTH_REGISTER_USER,
    AUTH_LOGIN,
    AUTH_GET_PROFILE,
    FORGET_PASSWORD,
    GET_SETTINGS,
    AUTH_LOGOUT,
    GET_COMMON_SERVICES,
    AUTH_CHECK_EMAIL,
    RESET_PASSWORD,
    SAVE_COMMON_SERVICES_API,
    SAVE_SETTINGS_API,
    SAVE_PROFILE_API,
    UPLOAD_FILE,
    DELETE_COMMON_SERVICES_API
} from './actionTypes';

/*************************** ACTIONS *******************/
import { login, saveProfile, logOutHandler, saveSettings, saveCommonServices } from './actions';
import { apiSagaHandler } from '../network/saga';
import { errorReducerHandler } from '../alert/actions'
import { showWarning } from '../alert/actions';
import { serializeArray } from '../../helpers/helperFunctions';

const API_URL = "auth/"
/************************ USER SAGA *******************************/
function* authSaga() {
    yield all([
        call(registerUser),
        call(startUserLogin),
        call(getProfileApi),
        call(forgetPasswordApi),
        call(getSettingsApi),
        call(startUserLogOut),
        call(getCommonServicesApi),
        call(checkEmailApi),
        call(resetPasswordApi),
        call(saveSettingsApi),
        call(saveCommonServicesApi),
        call(saveProfileApi),
        call(uploadFileApi),
        call(deleteCommonServiceApi)
    ]);
}

/************************ REGISTER_USER *******************/
function* registerUser() {
    yield takeLatest(AUTH_REGISTER_USER, registerHandler);
}


/************************ START USER LOGIN *******************/
function* startUserLogin() {
    yield takeLatest(AUTH_LOGIN, loginHandler);
}

function* getProfileApi() {
    yield takeLatest(AUTH_GET_PROFILE, getProfileHandler)
}

function* forgetPasswordApi() {
    yield takeLatest(FORGET_PASSWORD, forgetPasswordHandler)
}

function* getSettingsApi() {
    yield takeEvery(GET_SETTINGS, getSettingsHandler)
}

function* saveSettingsApi() {
    yield takeEvery(SAVE_SETTINGS_API, saveSettingHandler)
}

function* saveCommonServicesApi() {
    yield takeEvery(SAVE_COMMON_SERVICES_API, saveCommonServicesHandler)
}

function* saveProfileApi() {
    yield takeLatest(SAVE_PROFILE_API, saveProfileHandler)
}

function* deleteCommonServiceApi() {
    yield takeLatest(DELETE_COMMON_SERVICES_API, deleteCommonServiceHandler)
}

// const upgradeUserSubscripton = (data) => {
//     data = clean(data)
//     return axios.post(`${API_URL}/upgradeSubscription`, { ...data }, { headers: authHeader() });
// };
// const downgradeUserSubscripton = (data) => {
//     data = clean(data)
//     return axios.post(`${API_URL}/downgradeSubscription`, { ...data }, { headers: authHeader() });
// };

// const flatFileAuth = () => {
//     return axios.get(`${API_URL}/auth-flatfile`, { headers: authHeader() });
// }


export function* registerHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}register`,
        "auth": false,
        "method": "POST",
        "localApi": false,
        body: payload,
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes?.success) {
        resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        resCall(apiRes)
    }
}


export function* loginHandler(data) {
    let { payload, resCall } = data?.data
    let config = {
        "endpoint": `${API_URL}login`,
        "auth": false,
        "method": "POST",
        "body": payload
    }
    const apiRes = yield apiSagaHandler(config);
    if (apiRes && apiRes.user && apiRes.success) {
        yield putResolve(login(apiRes.user));
        resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        resCall(apiRes)
    }
}

export function* getProfileHandler(data) {
    let config = {
        "endpoint": `${API_URL}getProfile`,
        "auth": true,
        "method": "GET"
    }
    const apiRes = yield apiSagaHandler(config);
    if (apiRes && apiRes.user && apiRes.success) {
        yield putResolve(saveProfile(apiRes.user));
        if (data && data.data && data.data.resCall) {
            data.data.resCall(apiRes)
        }
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

export function* saveProfileHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}update-profile`,
        "auth": true,
        "method": "POST",
        body: payload
    }
    const apiRes = yield apiSagaHandler(config);
    if (apiRes && apiRes.user && apiRes.success) {
        yield putResolve(saveProfile(apiRes.user));
        resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}


export function* forgetPasswordHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}forget-password`,
        "auth": false,
        "method": "POST",
        "body": payload
    }
    const apiRes = yield apiSagaHandler(config);
    if (apiRes && apiRes.success) {
        resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        resCall(apiRes)
    }
}

export function* getSettingsHandler(data) {
    let { payload, resCall, saveInRedux } = data?.data;
    let config = {
        "endpoint": `${API_URL}settings?${serializeArray(payload.subType, "subType")}`,
        "auth": true,
        "method": "GET"
    }
    const apiRes = yield apiSagaHandler(config);
    if (apiRes && apiRes.success) {
        if (saveInRedux) {
            yield put(saveSettings(apiRes?.data?.results || []))
        }
        // resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        // resCall(apiRes)
    }
}


export function* saveSettingHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}settings`,
        "auth": true,
        "method": "POST",
        body: payload
    }
    const apiRes = yield apiSagaHandler(config);
    if (apiRes && apiRes.success) {
        apiRes.data.saveApi = true
        yield put(saveSettings(apiRes?.data || null))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        // resCall(apiRes)
    }
}

/************************ START USER LOGOUT *********************************/
function* startUserLogOut() {
    yield takeLatest(AUTH_LOGOUT, startUserLogOutHandler);
}

function* startUserLogOutHandler() {
    yield put(logOutHandler());
}

function* getCommonServicesApi() {
    yield takeEvery(GET_COMMON_SERVICES, getCommonServicesHandler);
}

function* getCommonServicesHandler(data) {
    let { payload, resCall, saveInRedux, commonPayload } = data?.data;
    console.log(data?.data)
    let config = {
        "endpoint": `${API_URL}common-services?${payload.type ? serializeArray(payload.type, "type") : serializeArray(payload.dayOfWeeks, "dayOfWeeks")}`,
        "auth": true,
        "method": "GET",
        "params": {
            ...(payload.userId && { userId: payload.userId })
        }
    }
    console.log("config", config)
    const apiRes = yield apiSagaHandler(config);
    if (apiRes && apiRes.success) {
        if (saveInRedux) {
            yield put(saveCommonServices(apiRes?.data?.results || []))
        }
        if ((!apiRes || (apiRes && !apiRes.data) || (apiRes && apiRes.data && apiRes.data.results.length === 0)) && (commonPayload && !payload.common)) {
            yield getCommonServicesHandler({ data: { payload: { ...commonPayload, common: true } } })
        }
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* saveCommonServicesHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}common-services`,
        "auth": true,
        "method": "POST",
        "body": payload
    }
    const apiRes = yield apiSagaHandler(config);
    if (apiRes && apiRes.success) {
        apiRes.data.saveApi = true
        yield put(saveCommonServices(apiRes?.data || null))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}


function* deleteCommonServiceHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}common-services/multipleDelete/`,
        "auth": true,
        "method": "DELETE",
        "body": payload
    }
    const apiRes = yield apiSagaHandler(config);
    if (apiRes && apiRes.success) {
        apiRes.deleteApi = true
        yield put(saveCommonServices(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* checkEmailApi() {
    yield takeLatest(AUTH_CHECK_EMAIL, checkEmailHandler)
}

function* checkEmailHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}checkEmail?email=${payload.email}`,
        "auth": false,
        "method": "GET"
    }
    const apiRes = yield apiSagaHandler(config);
    if (apiRes && apiRes.success) {
        resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        // resCall(apiRes)
    }
}

function* resetPasswordApi() {
    yield takeLatest(RESET_PASSWORD, resetPasswordHandler)
}

function* resetPasswordHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}reset-password`,
        "auth": false,
        "method": "POST",
        "body": payload
    }
    const apiRes = yield apiSagaHandler(config);
    if (apiRes && apiRes.success) {
        resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        // resCall(apiRes)
    }
}

function* uploadFileApi() {
    yield takeLatest(UPLOAD_FILE, uploadFileHandler)
}

function* uploadFileHandler(data) {
    let { payload, resCall } = data?.data;
    let config = {
        "endpoint": `${API_URL}upload`,
        "auth": false,
        "method": "POST",
        "body": payload
    }
    const apiRes = yield apiSagaHandler(config);
    if (apiRes && apiRes.success) {
        resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
        // resCall(apiRes)
    }
}

export default authSaga;
