import React, { useState } from "react";
// import "./mission.scss"
import { BsFillPersonFill } from "react-icons/bs"
import { MdOutlineAllInbox } from "react-icons/md"
import { IoMdTime } from "react-icons/io"
import DraggableList from 'react-draggable-list'
import { useRef } from 'react'
import Tasks from './Task'
import appDefaults from '../../../../helpers/app-defaults'
import lefticon from "../../../../assets/image/lefticon.png"
import righticon from "../../../../assets/image/righticon.png"
import recurring from "../../../../assets/image/recurring.png"
import optimize from '../../../../helpers/OptimizeMission'

const MissionSuprviceSide = (props) => {
  const containerRef = useRef();
  let mission = props.mission;
  const missionTaskSingleNonDraggableClickHandler = (e, mission, i) => {
    let selectedCenter = {
      ...mission.location,
      address: mission.address,
      from: "task",
      id: mission.orderType === "SL" ? mission.id : mission.id + 1,
    };
    props.setSelectedCenter(selectedCenter);
  };
  const NonDraggableItem = (props) => {
    return (
      <div
        className={`start-end-location-ck`}
        onClick={(e) => {
          e.stopPropagation();
          missionTaskSingleNonDraggableClickHandler(e, props.item, 0);
        }}
      >
        <div className="start-end-field-box">
          <div className="start-end-detail">
            <div className="start-end-title">
              <span className="icon">
                <img
                  style={{ width: "7px" }}
                  src={props.item.icon}
                  alt="icon"
                />
              </span>
              <span className="title">
                {props.item.title} -{" "}
                {props.item.hubName ? props.item.hubName : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const calculateCapacity = () => {
    return mission["tasks"].reduce(
      (partialSum, a) => partialSum + a.capacity,
      0
    );
  };

  return (
    <div style={{ width: "100%" }}>

      {
        mission.tasks.length > 0 ? <>
          <div
            className={`mission-task header-mission-task ${mission?.missionStatus ? `title-${mission?.missionStatus}` : ""
              }`}
            onDragOver={(e) => props.dragOver(e)}
            onDrop={(e) => props.onDropMission(e, mission, `mission_${mission.id}`)}
          >
            <div className="top">
              <input
                key={mission.name}
                id={mission.id}
                className="mission-labels"
                defaultValue={mission.name ? mission.name : "mission"}
                // value={inputValue}
                onChange={(e) => {
                  // setMissionData({ id: data.id })
                  // setInputValue(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    props.updateMission(
                      { id: mission.id, name: e.target.value },
                      "UPDATE"
                    );
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                placeholder="+ Add Label"
                type={"text"}
              />
              {mission.teamId && mission.agentId && (
                <div className="name-and-team">
                  <div className="name">
                    <BsFillPersonFill className="icon" />
                    <p>
                      {mission && mission.agentId ? mission.agentId.firstName : ""}{" "}
                      {mission && mission.agentId ? mission.agentId.lastName : ""}
                    </p>
                  </div>
                  <p className="team">
                    {mission && mission.teamId ? mission.teamId.teamName : ""}
                  </p>
                </div>
              )}
              {mission.recurring && (<div>
                  <img className="recurring-img" src={recurring} alt={"recurring"}/>
              </div>)}
            </div>
            <div className="bottom">
              <div className="btn">
                {" "}
                {mission.tasks && mission.tasks.length
                  ? mission.tasks.length
                  : 0}{" "}
                Tasks
              </div>
              <div className="btn">
                {
              /*mission.optimised && */ mission.missionDetails &&
                    mission.missionDetails.totalTime
                    ? `${mission.missionDetails.totalTime} Hours`
                    : "__ Hours"
                }
              </div>
              <div className="btn">
                {mission.missionTaskCapacity
                  ? `${calculateCapacity()} ${mission.missionTaskCapacityUnit}`
                  : "__ Cap"}
              </div>
              <div className="btn">
                {
              /*mission.optimised && */ mission.missionDetails &&
                    (mission.missionDetails.totalDist ||
                      mission.missionDetails.totalDist === 0)
                    ? `${props.distance(mission.missionDetails.totalDist)}`
                    : "__ Km"
                }
              </div>
            </div>
          </div>
        </> : null
      }



      {props.toggleState && props.toggleState[mission.id] ? (
        <div className="mission-task-container">
          {mission.optimised &&
            mission.startFrom &&
            mission.startFrom.location && (
              <NonDraggableItem
                item={{
                  title: "START",
                  ...mission.startFrom,
                  orderType: "SL",
                  ...mission,
                  icon: lefticon,
                }}
              />
            )}
          {!mission.optimised && mission.startLocation && mission.address && (
            <NonDraggableItem
              item={{
                title: "START",
                ...mission.startLocation,
                address: mission.address,
                orderType: "SL",
                ...mission,
                icon: lefticon,
              }}
            />
          )}
          <DraggableList
            css={{ left: "5px" }}
            itemKey="id"
            // category={category}
            template={Tasks}
            commonProps={{
              ...props,
              listingSection: appDefaults.LISTING_TYPE.MISSION
            }}
            list={mission.tasks}
            onMoveEnd={(newList) => props._onListChange(newList, mission)}
            container={() => containerRef.current}
          />
          {mission.optimised && mission.startFrom && mission.endAt.location && (
            <NonDraggableItem
              item={{
                title: "END",
                ...mission.endAt,
                orderType: "EL",
                ...mission,
                icon: righticon,
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default MissionSuprviceSide;
