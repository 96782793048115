import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Card, Row, Col, Button, FormControl, InputGroup, Dropdown, Modal, Container } from 'react-bootstrap';
import './Login.scss';
import logo from '../../assets/image/logo.png';
import passwordicon from '../../assets/image/password.png';
import mailicons from '../../assets/image/mailicons.png';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login, resetMessage } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import { successAlert, errorAlert, infoAlert } from '../../services/toast.service';



const Passwordassistance = (props) => {


  // if (isLoggedIn) {
  //   return <Redirect to="/dashboard" />;
  // }



  return (
    <>
      <div>
        <div className="signin-form">
          <div className="logo-bar">
            <img src={logo} alt="logo" />
            <h1 className="headingpassword">Password assistance</h1>
          </div>
          <div className="signup-form-box password-asbox">
            <p>We just send you a security code to your email.<br></br>Enter the security code and press Continue</p>
            <Formik>
              <Form >
                <Row>
                  <Col xs={12}>
                  <div className="delivery-form-box">

                    <div className="counter-box">
                    <Field className="form-control" type="text" name="ps" aria-label="ps"  value="5" />
                    <Field className="form-control" type="text" name="ps" aria-label="ps"  value="6" />
                    <Field className="form-control" type="text" name="ps" aria-label="ps"  value="8" />
                    <Field className="form-control" type="text" name="ps" aria-label="ps"  value="3" />
                    
                    </div>
                    </div>

                  </Col>
                  <Col xs={12}>
                    <div className="continue-btn">
                      <Button variant="primary" type="submit">
                      Continue
                      </Button>
                    </div>
                    <div className="recapcta-box">
                      <p>This site is protected by reCAPTCHA and the Google Privacy Policy.</p>
                      <p className="dha-text">Don’t have an account?  <Link to={'/signup'}>Sign up</Link></p>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
  
    </>
  );





};

export default Passwordassistance;