import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Calendar from "react-calendar";

import closeicon from "../../assets/image/close-icon.png";
import taskService from "../../services/task.service";
import momentTz from "moment-timezone";
import timeZoneHeader from "../../services/timezone-header";
import { errorAlert } from "../../services/toast.service";

const ScheduleDelivery = (props) => {
    const ref = useRef();
    const [hoverText, setHoverText] = useState({ message: "", subMessage: "" })
    const [selectedDates, setSelectedDates] = useState([])

    function onChange(nextValue) {
        let currentDate = moment(nextValue).format("DD-MM-YYYY")
        let selected = [...selectedDates]
        let selectedfind = selected.findIndex(i => i.date === currentDate)
        let todayDate = moment();
        let calendarDate = moment(nextValue)
        let diff = todayDate.diff(calendarDate, "d");
        if (diff <= 0) {
            if (selectedfind >= 0) {
                selected.splice(selectedfind, 1)
            }
            else {
                let dayOfWeek = moment(nextValue).day();
                let slots = props.scheduleDeliverySettingData && props.scheduleDeliverySettingData.dailyAvailableSlots.find(i => i.day === dayOfWeek).slots;
                if (slots === 0) {
                    selected.push({ date: currentDate, type: "selected" })
                }
                else if (slots > 0) {
                    selected.push({ date: currentDate, type: "selected" })
                }
            }
            setSelectedDates(selected);
        }
    }

    const handleHoverDate = (date) => {
        let dayOfWeek = moment(date).day();
        let dateFormatted = moment(date).format("DD-MM-YYYY");
        let slots = props.scheduleDeliverySettingData && props.scheduleDeliverySettingData.dailyAvailableSlots.find(i => i.day === dayOfWeek).slots;
        let todayDate = moment();
        let calendarDate = moment(date)
        let diff = todayDate.diff(calendarDate, "d");
        let bigMessage = ""
        let smallMessage = ""
        if (diff > 0) {
            bigMessage = `There is no availability for this day.`;
        }
        else {
            if (slots || slots === 0) {
                if (slots === 0) {
                    smallMessage = `over a total of ${slots}`
                    let findTaskOfDate = props.taskCount.find(i => i._id === dateFormatted.toString())
                    if (findTaskOfDate) {
                        let count = findTaskOfDate.count;
                        bigMessage = `0 slots available for this day.`
                        if (count > 0) {
                            bigMessage = `${count} overbooked for this day.`
                        }
                    }
                    else {
                        bigMessage = `0 slots available for this day.`
                    }
                }
                else if (slots > 0) {
                    let totalSlots = slots;
                    smallMessage = `over a total of ${slots}`
                    let findTaskOfDate = props.taskCount.find(i => i._id === dateFormatted.toString())
                    if (findTaskOfDate) {
                        let count = findTaskOfDate.count;
                        if (count > 0) {
                            totalSlots = totalSlots - count
                        }
                    }
                    let findDate = selectedDates.find(i => i.date === dateFormatted)
                    if (findDate) {
                        let selectedTasks = props.selectedTasks.length;
                        totalSlots = totalSlots - Number(selectedTasks);
                    }
                    if (totalSlots >= 0) {
                        bigMessage = `${totalSlots} slots available for this day.`
                    }
                    else {
                        bigMessage = `${-(totalSlots)} overbooked for this day.`
                    }
                }
            }
            else {
                bigMessage = `There is no availability for this day.`
            }
        }


        setHoverText({ message: bigMessage, subMessage: smallMessage })
    }

    function tileClassName({ date, view }) {
        if (
            view === 'month'
        ) {
            if (!props.scheduleDeliverySettingData || (props.scheduleDeliverySettingData && !props.scheduleDeliverySettingData.dailyAvailableSlots)) {
                return "unavailable"
            }
            else {
                let dayOfWeek = moment(date).day();
                let dateFormatted = moment(date).format("DD-MM-YYYY");
                let slots = props.scheduleDeliverySettingData && props.scheduleDeliverySettingData.dailyAvailableSlots.find(i => i.day === dayOfWeek)?.slots;
                let todayDate = moment();
                let calendarDate = moment(date)
                let diff = todayDate.diff(calendarDate, "d");
                if (slots || slots === 0) {
                    if (slots === 0) {
                        let findDate = selectedDates.find(i => i.date === dateFormatted)
                        if (findDate) {
                            return findDate.type;
                        }
                        else if (diff > 0) {
                            return "unavailable"
                        }
                        else {
                            return "default";
                        }
                    }
                    else if (slots > 0) {
                        let findDate = selectedDates.find(i => i.date === dateFormatted)
                        let totalSlots = slots;
                        let findTaskOfDate = props.taskCount.find(i => i._id === dateFormatted.toString())
                        if (findTaskOfDate) {
                            let count = findTaskOfDate.count;
                            if (count > 0) {
                                totalSlots = totalSlots - count
                            }
                        }
                        if (findDate) {
                            return findDate.type;
                        }
                        else {
                            if (totalSlots > 0) {
                                if (diff > 0) {
                                    return "unavailable"
                                }
                                else {
                                    return "available"
                                }
                            }
                            else {
                                if (diff > 0) {
                                    return "unavailable"
                                }
                                else {
                                    return "default"
                                }
                            }
                        }
                    }

                }
                else {
                    return "unavailable"
                }

            }
            // else { return "default" }
        }
    }

    function tileDisabled({ date, view }) {
        // Disable tiles in month view only
        if (view === 'month') {
            // Check if a date React-Calendar wants to check is on the list of disabled dates
            return moment(date).startOf('d') < moment().startOf("d");
        }
    }
    const handleSubmit = async (date) => {
        if (selectedDates && selectedDates.length) {
            let timezone = { ...timeZoneHeader() }
            let proposedDates = selectedDates.map((i) => {
                let dates = i.date.split("-");
                return {
                    date: momentTz(`${dates[2]}/${dates[1]}/${dates[0]}`).tz(timezone.timezone).startOf("d")._d,
                    formattedDate: i.date
                }
            })
            let apiData = {
                proposedDates,
                taskIds: props.selectedTasks
            }
            let response = await taskService.sendScheduleDelivery(apiData);
            if (response && response.data && response.data.success) {
                await props.getUnassignedTaskList(props.date ? props.date.startDate : null, props.date ? props.date.endDate : null);
                props.setCalendarView(false)
            }
            else {
                props.errorAlert("Something went wrong")
            }
        }
        else {
            props.errorAlert("Please select dates.")
        }
    }

    const tileContent = ({ date, view }) => (
        <p type="button" className="tile-content" onMouseOut={() => { setHoverText({ message: "", subMessage: "" }) }} onMouseEnter={(e) => handleHoverDate(date)} >{moment(date).get("date")}</p>
    )
    return (<div className="schedule-delivery-wrapper">
        <div className="schedule-delivery-close">
            <img src={closeicon} alt={"close-icon"} onClick={() => props.setCalendarView(false)} />
        </div>
        <div className="schedule-delivery-title">
            <span>Set your availability</span>
        </div>
        <div className="calendar-container"><Calendar
            ref={ref}
            tileClassName={tileClassName}
            tileContent={tileContent}
            // tileDisabled={tileDisabled}
            onChange={onChange}
            value={props.currentDate}
        /></div>
        <div className="bottom-container">
            {hoverText && <div className="hover-title"><span>{hoverText.message}</span><span className="small-text">{hoverText.subMessage}</span></div>}
            <div ><Button className="button" onClick={(e) => { handleSubmit() }}>Send</Button></div>
        </div>
    </div>)
}

export default ScheduleDelivery;