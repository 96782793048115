import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import UserService from "../services/user.service";

const user = JSON.parse(localStorage.getItem("user"));

export const extensions = createAsyncThunk(
    "users/extensions",
    async ({ userId }, thunkAPI) => {
        try {
            console.log(userId,"userId")
            const data = await UserService.getUserExtensions(userId);
            console.log(data,"--data")
            if (data.status === 200) {
               
                return { extension: data.data, message: data.message, type: 'success' };
            } else {
                thunkAPI.rejectWithValue();
                return { message: data.message, type: 'danger' }
            }

        } catch (error) {
            console.log("error::", error)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);


const initialState = {  extension:[], message: "", type: "" }
    

const extensionsSlice = createSlice({
    name: "extensions",
    initialState,
    extraReducers: {
        [extensions.fulfilled]: (state, action) => {
            state.message = action.payload.message;
            state.type = action.payload.type;
            state.extension = action.payload.extension;
        },
        [extensions.rejected]: (state, action) => {
            state.extension = null;
            state.message = action.payload.message;
            state.type = action.payload.type;
        }
    },
});

const { reducer } = extensionsSlice;
export default reducer;