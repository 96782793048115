// TODO: Resolve lint issues
/* eslint-disable */
import {
    AUTH_REGISTER_USER,
    AUTH_LOGIN,
    AUTH_SAVE_DATA,
    HIDE_LOADER,
    SHOW_LOADER,
    SAVE_PROFILE_DATA,
    SAVE_SETTINGS,
    USER_LOGOUT,
    SAVE_COMMON_SERVICES,
    SAVE_CURRENT_COUNTRY
} from './actionTypes';

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
    ? { isLoggedIn: true, user, message: "", type: "", loading: false, settings: [], commonServices: [], countryCode: "" }
    : { isLoggedIn: false, user: null, loading: false, settings: [], commonServices: [], countryCode: "" };

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_SAVE_DATA:
            if (action.payload && action.payload.permittedUsers && action.payload.permittedUsers.length) {

            }
            localStorage.setItem("user", JSON.stringify(action.payload))
            return {
                ...state,
                isLoggedIn: true,
                type: "success",
                user: action.payload
            }

        case SAVE_PROFILE_DATA:
            if (action.payload && action.payload.permittedUsers && action.payload.permittedUsers.length) {

            }
            localStorage.setItem("user", JSON.stringify(action.payload))
            return {
                ...state,
                user: action.payload
            }

        case SAVE_SETTINGS:
            let savedData = state.settings
            if (action.payload && action.payload.saveApi) {
                let newSettings = JSON.parse(JSON.stringify(state.settings))
                let findSettingIndex = newSettings.findIndex(i => {
                    return i.id === action.payload.id
                })
                delete action.payload.saveApi
                if (findSettingIndex >= 0) {
                    newSettings[findSettingIndex] = action.payload;
                }
                else {
                    newSettings.push(action.payload)
                }
                savedData = newSettings
            }
            else {
                if (action.payload) {
                    savedData = action.payload
                }
            }
            return {
                ...state,
                settings: savedData
            }

        case SAVE_COMMON_SERVICES:
            let savedcommonServiceData = state.commonServices
            if (action.payload && action.payload.saveApi) {
                let newCommonServices = JSON.parse(JSON.stringify(state.commonServices))
                let findSettingIndex = newCommonServices.findIndex(i => {
                    return i.id === action.payload.id
                })
                delete action.payload.saveApi
                if (findSettingIndex >= 0) {
                    newCommonServices[findSettingIndex] = action.payload;
                }
                else {
                    newCommonServices.push(action.payload)
                }
                savedcommonServiceData = newCommonServices
            }
            else if (action.payload && action.payload.deleteApi) {
                let newCommonServices = JSON.parse(JSON.stringify(state.commonServices))
                let findSettingIndex = newCommonServices.findIndex(i => {
                    return i.id === action.payload?.commonService?.id
                })
                if (findSettingIndex >= 0) {
                    newCommonServices.splice(findSettingIndex, 1)
                }
                savedcommonServiceData = newCommonServices
                delete action.payload.deleteApi
            }
            else {
                if (action.payload) {
                    savedcommonServiceData = action.payload
                }
            }
            return {
                ...state,
                commonServices: savedcommonServiceData
            }

        case USER_LOGOUT:
            localStorage.removeItem("user");
            return {
                ...state,
                isLoggedIn: false, user: null, loading: false
            }
        case HIDE_LOADER:
            return {
                ...state,
                loading: false
            }
        case SHOW_LOADER:
            return {
                ...state,
                loading: true
            }
        case SAVE_CURRENT_COUNTRY:
            return {
                ...state,
                countryCode: action.countryCode
            }
        default:
            return state;
    }
};

export default userReducer;
