import axios from 'axios';
import { apiConfig } from './apiConfig';
/*********************** INSTANCE ********************************** */
const instance = axios.create({
	...apiConfig,
});

/**
 * 
 * @param {api config props} apiData 
 * @returns 
 */

/*********************** API CALL ********************************** */
export const callApi = async apiData => {
  /******************************** PROPS
   */ const {
		method = 'get',
		endpoint = '/',
		body = null,
		params = null,
		headers = {},
		auth = false,
		paramsSerializer,
		localApi = false,
		responseType
	} = apiData;
	/************************** BODY **********************************************/
	try {
		const config = {
			method,
			url: endpoint,
		};
		if (body) {
			config.data = body;
		}
		if (params) {
			config.params = params;
		}
		if (paramsSerializer) {
			config.paramsSerializer = paramsSerializer;
		}
		if (headers) {
			config.headers = { ...headers };
		}
		if (responseType) {
			config.responseType = responseType
		}
		if (auth) {
			instance.defaults.headers.common['Authorization'] = `Bearer ${auth}`;
		}
		let res
		res = await instance({
			...config,
		});
		return res;

	} catch (error) {
		return {
			code: '89D',
			msg: error.message,
		};
	}
};
