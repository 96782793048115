import React, { Component } from 'react';

import './Modal.scss';
import Aux from '../Auxiliary/Auxiliary';
import Backdrop from '../Backdrop/Backdrop';
import { Button } from 'react-bootstrap';

import closeicon from "../../../assets/image/close-icon.png";

const Modal = (props) => {
    return (
        <Aux>
            <Backdrop show={props.show} updateState={props.updateState}
            />
            <div
                className="modal-popup"
                style={{
                    ...(props.background && { background: props.background }),
                    ...(props.borderColor && { borderColor: props.borderColor }),
                    transform: props.show ? 'translateY(0)' : 'translateY(-50%)',
                    display: props.show ? 'block' : 'none',
                    top: props.top ? props.top : '30%',
                    left: props.left ? props.left : '30%',
                    width: props.width ? props.width : "70%"
                }}>
                <div className="modal-container">
                    <div>
                        <Button
                            className="modal-close-btn"
                            onClick={() => {
                                props.updateState(false);
                                if (props.cancelHandle) {
                                    props.cancelHandle()
                                }
                            }}
                        >
                            {!props.hideCloseButton && <img src={closeicon} alt="icon" />}
                        </Button>
                    </div>
                </div>
                {props.children}
            </div>
        </Aux>
    )
}

export default Modal;