import {
  HIDE_WARNING,
  SHOW_WARNING,
  ERROR_SAGA,
  CLEAR_WARNING,
  SHOW_INFO
} from './actionTypes';

export const showWarning = (payload) => ({
  type: SHOW_WARNING,
  payload,
});

export const showInfo = (payload) => ({
  type: SHOW_INFO,
  payload
})

export const hideWarning = payload => ({
  type: HIDE_WARNING,
  payload,
});

export const clearWarning = () => ({
  type: CLEAR_WARNING,
});

export const errorReducerHandler = payload => ({
  type: ERROR_SAGA,
  payload,
});
