import React, { useState } from "react";
import {
  Form,
} from "react-bootstrap";
import upwhiteicon from "../assets/image/upwhiteicon.png";
const Bottommenu = (props) => {
  const [statusmenu, setstatusmenu] = useState("false");
  const statusmenuToggle = () => {
    setstatusmenu(!statusmenu);
  };
  const [viewmenu, setviewmenu] = useState("false");
  const viewmenuToggle = () => {
    console.log("viewmenu", viewmenu)
    setviewmenu(!viewmenu);
  };
  const [teammenu, setteammenu] = useState("false");
  const teammenuToggle = () => {
    setteammenu(!teammenu);
  };
  const [Merchantmenu, setMerchantmenu] = useState("false");
  const MerchantmenuToggle = () => {
    setMerchantmenu(!Merchantmenu);
  };
  const [Usermenu, setUsermenu] = useState("false");
  const UsermenuToggle = () => {
    setUsermenu(!Usermenu);
  };

  const handleAgentCheck = (e) => {
    if (e.target.name === "agentList") {
      let resultArray = []
      if (e.target.checked)      //if checked (true), then add this id into checkedList
      {
        resultArray = props.selectedBottomMenuAgent.filter(CheckedId =>
          CheckedId !== e.target.id
        )
        resultArray.push(e.target.id)
      }
      else                    //if not checked (false), then remove this id from checkedList
      {
        resultArray = props.selectedBottomMenuAgent.filter(CheckedId =>
          CheckedId !== e.target.id
        )
      }
      props.setSelectedBottomMenuAgent(resultArray)
    }
    else if (e.target.name === "taskStatus") {
      let resultArray = []
      let id = e.target.id;
      if (!id) id = 0
      if (e.target.checked)      //if checked (true), then add this id into checkedList
      {
        resultArray = props.selectedBottomMenuStatus.filter(CheckedId =>
          CheckedId !== id
        )
        resultArray.push(id)
      }
      else                    //if not checked (false), then remove this id from checkedList
      {
        resultArray = props.selectedBottomMenuStatus.filter(CheckedId =>
          CheckedId !== id
        )
      }
      props.setSelectedBottomMenuStatus(resultArray)
    }
    else if (e.target.name === "merchantList") {
      let resultArray = []
      if (e.target.checked)      //if checked (true), then add this id into checkedList
      {
        resultArray = props.selectedBottomMenuMerchant.filter(CheckedId =>
          CheckedId !== e.target.id
        )
        resultArray.push(e.target.id)
      }
      else                    //if not checked (false), then remove this id from checkedList
      {
        resultArray = props.selectedBottomMenuMerchant.filter(CheckedId =>
          CheckedId !== e.target.id
        )
      }
      props.setSelectedBottomMenuMerchant(resultArray)
    }
    else if (e.target.name === "userList") {
      let resultArray = []
      if (e.target.checked)      //if checked (true), then add this id into checkedList
      {
        resultArray = props.selectedBottomMenuUser.filter(CheckedId =>
          CheckedId !== e.target.id
        )
        resultArray.push(e.target.id)
      }
      else                    //if not checked (false), then remove this id from checkedList
      {
        resultArray = props.selectedBottomMenuUser.filter(CheckedId =>
          CheckedId !== e.target.id
        )
      }
      props.setSelectedBottomMenuUser(resultArray)
    }
    else if (e.target.name === "teamList") {
      let resultArray = []
      if (e.target.checked)      //if checked (true), then add this id into checkedList
      {
        resultArray = props.selectedBottomMenuTeams.filter(CheckedId =>
          CheckedId !== e.target.id
        )
        resultArray.push(e.target.id)
      }
      else                    //if not checked (false), then remove this id from checkedList
      {
        resultArray = props.selectedBottomMenuTeams.filter(CheckedId =>
          CheckedId !== e.target.id
        )
      }
      props.setSelectedBottomMenuTeams(resultArray)
    }
  }


  return (
    <>
      <div className="dashboard-bottom-menubar">
        <div className="bottom-menubar">
          {props.views && <div className="dbt-menu">
            <div
              className={viewmenu ? "menu-btn " : "menu-btn viewsmenu"}
              onClick={viewmenuToggle}
            >
              <h2>View</h2>
              <span className="menuicon">
                <img src={upwhiteicon} alt="icon" />
              </span>
            </div>
            <div className="status-list">
              <div className="btm-menu-list">
                <label htmlFor="Map" className="checkbox-custom-label">
                  Map
                </label>
                <Form.Check
                  defaultChecked
                  type="radio"
                  id="Map"
                  name="view"
                  className="checkbox-custom"
                  onChange={(e) => { props.setView("MAP"); viewmenuToggle() }}
                />
              </div>
              <div className="btm-menu-list">
                <label htmlFor="List" className="checkbox-custom-label">
                  List
                </label>
                <Form.Check
                  type="radio"
                  id="List"
                  name="view"
                  className="checkbox-custom"
                  onChange={(e) => { props.setView("LIST"); viewmenuToggle() }}
                />
              </div>

            </div>
          </div>}
          <div className="dbt-menu">
            <div
              className={statusmenu ? "menu-btn " : "menu-btn statusmenu"}
              onClick={statusmenuToggle}
            >
              <h2>Status</h2>
              <span className="menuicon">
                <img src={upwhiteicon} alt="icon" />
              </span>
            </div>
            <div className="status-list unass-menu">
              {props.taskStatus.map(items => {
                return (<div className="btm-menu-list">
                  <label htmlFor={items.name} className="checkbox-custom-label">
                    {items.name}
                  </label>
                  <Form.Check
                    onChange={(e) => { handleAgentCheck(e) }}
                    type="checkbox"
                    name="taskStatus"
                    id={items.value}
                    className="checkbox-custom"
                  />
                </div>)
              })}
            </div>
          </div>
          {props.dashboardTeams && <div className="dbt-menu">
            <div
              className={teammenu ? "menu-btn " : "menu-btn Teammenu"}
              onClick={teammenuToggle}
            >
              <h2>Team</h2>
              <span className="menuicon">
                <img src={upwhiteicon} alt="icon" />
              </span>
            </div>
            <div className="status-list">
              <div className="allteam-option">
                <Form.Group className="mb-3 od-dselect">
                  <Form.Select onChange={(e) => { props.setSelectedBottomMenuTeam(e.target.value) }}>
                    <option value={"all"}>All Team</option>
                    {props.teams.map(items => {
                      return <option value={items.id}>{items.teamName}</option>
                    })}
                  </Form.Select>
                </Form.Group>
              </div>
              <h2>Agent</h2>
              {props.agentList && props.agentList.length > 0 &&
                props.agentList.filter((t) => {
                  if (props.selectedBottomMenuTeam === "all") {
                    return t
                  }
                  else {
                    return t.teamId && t.teamId.id === props.selectedBottomMenuTeam
                  }
                }).map((items) => {
                  return (<div className="btm-menu-list">
                    <label htmlFor={items.id} className="checkbox-custom-label">
                      {items.firstName} {items.lastName}
                    </label>
                    <Form.Check
                      onChange={(e) => { handleAgentCheck(e) }}
                      type="checkbox"
                      name="agentList"
                      id={items.id}
                      className="checkbox-custom"
                    />
                  </div>)
                })}
            </div>
          </div>}
          {props.teams && < div className="dbt-menu">
            <div
              className={
                Merchantmenu ? "menu-btn " : "menu-btn Merchantmenu"
              }
              onClick={MerchantmenuToggle}
            >
              <h2>Team</h2>
              <span className="menuicon">
                <img src={upwhiteicon} alt="icon" />
              </span>
            </div>
            <div className="status-list">
              {props.teams && props.teams.map(items => {
                return (<div className="btm-menu-list">
                  <label htmlFor={items.id} className="checkbox-custom-label">
                    {items.teamName}
                  </label>
                  <Form.Check
                    onChange={(e) => { handleAgentCheck(e) }}
                    type="checkbox"
                    id={items.id}
                    name="teamList"
                    className="checkbox-custom"
                  />
                </div>)
              })}
            </div>
          </div>}
          {props.dashboard && props.userData && props.userData.role === 2 && < div className="dbt-menu">
            <div
              className={
                Merchantmenu ? "menu-btn " : "menu-btn Merchantmenu"
              }
              onClick={MerchantmenuToggle}
            >
              <h2>Merchant</h2>
              <span className="menuicon">
                <img src={upwhiteicon} alt="icon" />
              </span>
            </div>
            <div className="status-list">
              {props.merchantList && props.merchantList.map(items => {
                return (<div className="btm-menu-list">
                  <label htmlFor={items.id} className="checkbox-custom-label">
                    {items.firstName} {items.lastName}
                  </label>
                  <Form.Check
                    onChange={(e) => { handleAgentCheck(e) }}
                    type="checkbox"
                    id={items.id}
                    name="merchantList"
                    className="checkbox-custom"
                  />
                </div>)
              })}
            </div>
          </div>}
          {props.dashboard && props.userData && props.userData.role === 1 &&
            <div className="dbt-menu">
              <div
                className={
                  Usermenu ? "menu-btn " : "menu-btn Usermenu"
                }
                onClick={UsermenuToggle}
              >
                <h2>User</h2>
                <span className="menuicon">
                  <img src={upwhiteicon} alt="icon" />
                </span>
              </div>
              <div className="status-list">
                {props.userList && props.userList.map(items => {
                  return (<div className="btm-menu-list">
                    <label htmlFor={items.id} className="checkbox-custom-label">
                      {items.firstName} {items.lastName}
                    </label>
                    <Form.Check
                      onChange={(e) => { handleAgentCheck(e) }}
                      type="checkbox"
                      id={items.id}
                      name="userList"
                      className="checkbox-custom"
                    />
                  </div>)
                })}
              </div>
            </div>}
        </div>
      </div>

    </>
  );
};

export default Bottommenu;