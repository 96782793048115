import {
    SAVE_DELETE_MISSION_REDUX,
    SAVE_DELETE_TASKS_REDUX,
    SAVE_MISSIONS_REDUX,
    SAVE_MULTI_MISSION_REDUX,
    SAVE_REMOVE_TASKS_REDUX,
    SAVE_TASKS_REDUX,
    SAVE_TASK_HISTORY_REDUX
} from './actionTypes';

export const saveTasksList = payload => {
    return {
        type: SAVE_TASKS_REDUX,
        payload
    }
}
export const saveMissionsList = payload => {
    return {
        type: SAVE_MISSIONS_REDUX,
        payload
    }
}

export const saveDeleteMissionsList = payload => {
    return {
        type: SAVE_DELETE_MISSION_REDUX,
        payload
    }
}

export const saveDeleteTasksList = payload => {
    return {
        type: SAVE_DELETE_TASKS_REDUX,
        payload
    }
}

export const saveRemoveTasksList = payload => {
    return {
        type: SAVE_REMOVE_TASKS_REDUX,
        payload
    }
}

export const saveMultiMissionUpdateList = payload => {
    return {
        type: SAVE_MULTI_MISSION_REDUX,
        payload
    }
}

export const saveTaskHistory = payload => {
    return {
        type: SAVE_TASK_HISTORY_REDUX,
        payload
    }
}