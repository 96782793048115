import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
} from "react-bootstrap";
import passwordicon from "../../assets/image/password.png";
import {
  successAlert,
  errorAlert,
  infoAlert,
} from "../../services/toast.service";
import AuthService from "../../services/auth.service";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Loader } from "../Loader/loader";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

const StripeForm = ({ step, setStep, formData, setformData }) => {
  let history = useHistory();
  const [newsLetter, setNewsLetter] = useState(
    formData.newsLetter ? formData.newsLetter : false
  );
  const [termsAndConditions, setTermsAndConditions] = useState(
    formData.termsAndConditions ? formData.termsAndConditions : false
  );

  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const goHandler = async (e) => {
    console.log("formData", formData)
    e.preventDefault();
    setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!error) {
      let date = new Date();
      date.setHours(8);
      date.setMinutes(0);
      let timeSplit = date.toLocaleTimeString().split(":")
      let afterTime = `${timeSplit[0]}:${timeSplit[1]}`
      let afterSeconds = Number((date.getHours() * 3600) + (date.getMinutes() * 60))
      formData.defaultAfterTime = afterTime
      formData.defaultAfterTimeSeconds = afterSeconds
      formData.defaultAfterTimePicker = date;
      let beforedate = new Date();
      beforedate.setHours(17);
      beforedate.setMinutes(0);
      let beforetimeSplit = beforedate.toLocaleTimeString().split(":")
      let beforeTime = `${beforetimeSplit[0]}:${beforetimeSplit[1]}`
      let beforeSeconds = Number((beforedate.getHours() * 3600) + (beforedate.getMinutes() * 60))
      formData.defaultBeforeTime = beforeTime
      formData.defaultBeforeTimeSeconds = beforeSeconds
      formData.defaultBeforeTimePicker = beforedate;
      const response = await AuthService.register({
        ...formData,
        newsLetter,
        termsAndConditions,
        paymentMethod
      });
      if (response && response.data && response.data.success) {
        setLoading(false);
        successAlert(response.data.message);
        setTimeout(() => {
          history.push("/login");
        }, 3000);
      } else {
        setLoading(false);
        errorAlert(
          response.data.message ? response.data.message : "Something went wrong"
        );
      }
    } else {
      setLoading(false);
      errorAlert(error.message ? error.message : "Something went wrong");
    }
  };
  const backHandler = () => {
    setStep(step - 1);
  };
  return (
    <div>
      <div className="signupinfo-form">
        <h1 className="heading">Create your Gomove account</h1>
        <div className="signup-form-box payment-info">
          <h2>Payment info</h2>
          {formData.trialDays && <p>
            Don’t worry - your card will NOT be charged before the end of your {formData.trialDays}-day free trial.
          </p>}
          {!formData.trialDays && <p>
            Your card will be charged from now to end of this {formData.interval} after that plan is auto-renewed until cancelled.
          </p>}
          <Form inline="true">
            <fieldset className="FormGroup">
              <div className="FormRow">
                <CardElement options={CARD_OPTIONS} />
              </div>
            </fieldset>

            <div className="payment-information">
              <div className="allpayment">
                <span>
                  <img src={passwordicon} alt="icon" />
                </span>
                <p>
                  All payment information is stored securely. Your card won’t be
                  charged unless you explicitly select a plan and confirm your
                  subscription type.
                </p>
              </div>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  value={newsLetter}
                  checked={newsLetter === true}
                  onChange={(e) => setNewsLetter(!newsLetter)}
                  label="Yes! I’d like to receive Delivered, Gomove’s curated industry newsletter"
                />
              </Form.Group>
              <Form.Group
                className="mb-3 privacypolicy"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  id="customCheck"
                  checked={termsAndConditions === true}
                  value={termsAndConditions}
                  onChange={(e) => setTermsAndConditions(!termsAndConditions)}
                  name="example1"
                />
                <label htmlFor="customCheck">
                  I agree to the <a href="https://gomove.ai/terms-conditions/" target="_blank" rel="noreferrer">terms of service </a>
                  and <a href="https://gomove.ai/privacy-policy/" target="_blank" rel="noreferrer">privacy policy</a>
                </label>
              </Form.Group>
            </div>
            <div className="bsbtn">
              <Row>
                <Col xs={6}>
                  <div className="submitbtn">
                    <Button onClick={backHandler}>Back</Button>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="submitbtn">
                    <Button
                      type="submit"
                      disabled={!termsAndConditions}
                      onClick={goHandler}
                    >
                      Go
                    </Button>
                    <p>
                      This site is protected by reCAPTCHA and the Google Privacy
                      Policy.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        {loading ? <Loader /> : null}
      </div>
    </div>
  );
};

export default StripeForm;
