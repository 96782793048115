import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Card, Row, Col, Button, Dropdown, Modal, Container } from 'react-bootstrap';
import './Signup.scss';
import logo from '../../assets/image/logo.png';
import Personalinfo from "../../components/signup/personalinfo";
import Businessinfo from '../../components/signup/businessinfo';
import Paymentinfo from '../../components/signup/paymentinfo';
import Planinfo from '../../components/signup/planinfo';
import Deliveryinfo from '../../components/signup/delivery';
import MultiStep from 'react-multistep';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login, resetMessage } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import { ToastContainer } from 'react-toastify';
import { successAlert, errorAlert, infoAlert } from '../../services/toast.service';
import Subscription from "../../components/signup/subscription";

const Signup = () => {
  const [step, setStep] = useState(1)
  const [formData, setformData] = useState({
    firstName: "", lastName: "", phoneNumber: "", email: "",
    password: "", companyName: "", month: "", industry: "", country: "", state: "", productId: "", interval: ""
  })


  return (
    <>
      <div>
        <div className="signuptopbar">
          <Container fluid>
            <p>Already a member?  <Link to={'/'}>Sign in</Link></p>
          </Container>
        </div>
        <div className="signupform">
          <div className="logo-bar">
            <img src={logo} alt="logo" />
          </div>
          <div className="information-form">
            {step === 1 && <Personalinfo step={step} setStep={setStep} formData={formData} setformData={setformData} />}
            {step === 2 && <Businessinfo step={step} setStep={setStep} formData={formData} setformData={setformData} />}
            {/* {step === 3 && <Planinfo step={step} setStep={setStep} formData={formData} setformData={setformData} />} */}
            {step === 3 && <Subscription step={step} setStep={setStep} formData={formData} setformData={setformData} />}
            {step === 4 && <Paymentinfo step={step} setStep={setStep} formData={formData} setformData={setformData} />}
          </div>
        </div>
      </div>
      <ToastContainer
        style={{ color: 'black' }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Signup;