import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { Chart } from "react-google-charts";
import {
  Form,
  Form as BootstrapForm,
  Row,
  Col,
  FormControl,
  Button,
} from "react-bootstrap";
import "./Performance.scss";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import logo from "../../assets/image/logo.png";
import Sidebarmenu from "../../components/sidebarmenu";
import ContentLayout from "../../components/contentlayout";
import searchicon from "../../assets/image/searchicon.png";
import filtericon from "../../assets/image/filtericon.png";
import justifyicon from "../../assets/image/justifyicon.png";
const Performance = (props) => {
  const Taskdata = [
    ["Task", "Number of Task"],
    ["Pickup", 11],
    ["Dropoff", 2],
    ["Install", 2],
    ["Exchange", 2],
    ["Return", 7],
  ];

  const Taskoptions = {
    title: "Number of Task",
    backgroundColor: "#151719",
    titleTextStyle: {
      color: "#ffffff",
      fontSize: 18,
      bold: true,
      italic: false,
      textalign:'center'
    },
    legend:{
      position: 'right',
      alignment:'center'
    },
    
    chartArea:{left:50,top:70,width:'100%',height:'80%'}
  };

  const Taskstatusdata = [
    ["Task", "Task status"],
    ["Completed", 11],
    ["Delayed", 2],
    ["Failed", 2],
  ];

  const Taskstatusoptions = {
    title: "Task status",
    backgroundColor: "#151719",
    titleTextStyle: {
      color: "#ffffff",
      fontSize: 18,
      bold: true,
      italic: false,
      textalign:'center'
    },
    legend:{
      position: 'right',
      alignment:'center'
    },
    
    chartArea:{left:50,top:70,width:'100%',height:'80%'}
  };

  const Distancedata = [
    ["Task", "Total Distance"],
    ["En Route", 11],
    ["Idle", 2],
  ];

  const Distanceoptions = {
    title: "Total Distance",
    backgroundColor: "#151719",
    titleTextStyle: {
      color: "#ffffff",
      fontSize: 18,
      bold: true,
      italic: false,
    },
    legend:{
      position: 'right',
      alignment:'center'
    },
    chartArea:{left:50,top:70,width:'100%',height:'80%'}
  };


  const Timedata = [
    ["Task", "Service Time"],
    ["0-5 Min", 11],
    ["5-10 Min", 2],
    ["10-20 Min", 2],
    ["20-30 Min", 2],
    ["30+ Min", 7],
  ];

  const Timeoptions = {
    title: "Service Time",
    backgroundColor: "#151719",
    titleTextStyle: {
      color: "#ffffff",
      fontSize: 18,
      bold: true,
      italic: false,
    },
    legend:{
      position: 'right',
      alignment:'center'
    },
    chartArea:{left:50,top:70,width:'100%',height:'80%'}
  };


  const data = [
    ["Year", "Sales", "Expenses", "Profit"],
    ["Tue", 1000, 400, 200],
    ["Wed", 1170, 460, 250],
    ["Thu", 660, 1120, 300],
    ["Fri", 1030, 540, 350],
    ["Sat", 1030, 540, 350],
    ["Sun", 1030, 540, 350],
    ["Mon", 1030, 540, 350],
  ];

  const chartoption = {
    vAxis: { title: "Cups", titleTextStyle: { color: "#ffffff" } },
    seriesType: "bars",
    series: { 1: { type: "line" } },
    backgroundColor: "#151719",
    titleTextStyle: {
      color: "#ffffff",
      fontSize: 18,
      bold: true,
      italic: false,
    },
    legend:{
      position: 'bottom',
      alignment:'center',
    },
    vAxis: {
      textStyle: { color: "#FFF" },
    },
  };
  return (
    <>
      <ContentLayout>
        {/* Performance layout */}
        <div className="performance-page-wrapper">
          <div className="performance-heading-box">
            <h1>perfomance</h1>
          </div>
          <div className="perfomance-chart">
            <div className="perforance-searchbar">
            <div className="searchbar">
                    <BootstrapForm className="d-flex">
                      <FormControl
                        type="search"
                        placeholder="Search or type a name"
                        className="me-2"
                        autoComplete="off"
                        aria-label="Search"
                      />
                      <Button className="searchicon" >
                        <img src={searchicon} alt="searchicon" />
                      </Button>
                      {/* <span className="searchF">
                        <img src={searchF} alt="icon" />
                      </span> */}
                    </BootstrapForm>
                  </div>
                  <div className="right-selectoption">
                    <div className="date-select">
                    <Form.Select>
                      <option>Jan 27 - 3 feb</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                    </div>
                    <Button className="scicon"><img src={filtericon} alt="icon"/></Button>
                    <Button className="scicon"><img src={justifyicon} alt="icon"/></Button>
                  </div>
            </div>
            <div className="performance-task">
              <h1>Task</h1>
            <Row>
              <Col xs={5}>
              <div className="chartbar">
                  <Chart
                    chartType="PieChart"
                    data={Taskdata}
                    options={Taskoptions}
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </Col>
              <Col xs={7}>
                <div className="chartbar">
                  <Chart
                    chartType="ComboChart"
                    width="100%"
                    height="400px"
                    data={data}
                    options={chartoption}
                  />
                </div>
              </Col>
              </Row>
              <Row>
              <Col xs={5}>
              <div className="chartbar">
                  <Chart
                    chartType="PieChart"
                    data={Taskstatusdata}
                    options={Taskstatusoptions}
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </Col>
              <Col xs={7}>
                <div className="chartbar">
                  <Chart
                    chartType="ComboChart"
                    width="100%"
                    height="400px"
                    data={data}
                    options={chartoption}
                  />
                </div>
              </Col>
              </Row>
              </div>
              <div className="performance-task">
              <h1>Distance</h1>
            <Row>
              <Col xs={5}>
              <div className="chartbar">
                  <Chart
                    chartType="PieChart"
                    data={Distancedata}
                    options={Distanceoptions}
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </Col>
              <Col xs={7}>
                <div className="chartbar">
                  <Chart
                    chartType="ComboChart"
                    width="100%"
                    height="400px"
                    data={data}
                    options={chartoption}
                  />
                </div>
              </Col>
              </Row>
              </div>
              <div className="performance-task">
              <h1>Time</h1>
            <Row>
              <Col xs={5}>
              <div className="chartbar">
                  <Chart
                    chartType="PieChart"
                    data={Timedata}
                    options={Timeoptions}
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </Col>
              <Col xs={7}>
                <div className="chartbar">
                  <Chart
                    chartType="ComboChart"
                    width="100%"
                    height="400px"
                    data={data}
                    options={chartoption}
                  />
                </div>
              </Col>
              </Row>
              
              </div>
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default Performance;
