import {
    Card,
    Row,
    Col,
    Form,
    InputGroup,
    FormControl,
    Button,
    Dropdown,
    Modal,
    Container,
    NavDropdown,
} from "react-bootstrap";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import filterremoveicon from "../../assets/image/filter-removeicon.png";
import ShortIcon from "../../assets/image/filter-icon.png";

const GroupTab = (props) => {
    let type = props.settingTypes.OPERATIONS;
    let subType = props.settingSubType.LEGAL_PROTECTION
    if (props.userData && props.userData.role === 1) {
        type = props.settingTypes.OPERATIONS
        subType = props.settingSubType.COMMON_LEGAL_PROTECTION
    }
    const Remove = (cell, row) => {
        return (
            <>
                <span>
                    <span className="filter-edit">
                        <NavDropdown
                            title={<img src={filterremoveicon} alt={"dropdownimage"} />}
                            id="nav-dropdown"
                        >
                            <NavDropdown.Item eventKey="4.1">Edit</NavDropdown.Item>
                            <NavDropdown.Item eventKey="4.2">Delete</NavDropdown.Item>
                        </NavDropdown>
                    </span>
                </span>
            </>
        );
    };
    const userlist = [
        {
            id: 1,
        },
        {
            id: 2,
        },
        {
            id: 3,
        },
        {
            id: 4,
        },
        {
            id: 5,
        },
    ];
    const selectRowProp = {
        mode: "checkbox",
        clickToSelect: true,
        bgColor: "rgb(238, 193, 213)",
    };
    function group() {
        return <span>Salim Bouafia</span>;
    }
    function Teamlead() {
        return <span>Salim Bouafia </span>;
    }

    function Email() {
        return <span>alex.charboneau@ikea.ca</span>;
    }
    function Phone() {
        return <span>+1 (514) 649 - 2141 </span>;
    }
    function user() {
        return <span>6 </span>;
    }

    return (<div className="company-settings">
        <div className="customer-heading-box">
            <Row>
                <Col xs={6}>
                    <div className="create-action-option">
                        <Form>
                            <Form.Group className="mb-3 od-dselect">
                                <Button
                                    className="bulk-action"
                                    onClick={props.bulktoggle}
                                >
                                    Bulk Action
                                </Button>
                                {props.bulkOpened && (
                                    <div className="bulkoption">
                                        <Button>Block All</Button>
                                        <Button>Delete All</Button>
                                    </div>
                                )}
                            </Form.Group>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>

        {/* user list start */}
        <div className="customer-list-wrapper">
            <BootstrapTable
                data={userlist}
                striped={true}
                hover={true}
                selectRow={selectRowProp}
                pagination
                wrapperClasses="table-responsive"
            >
                <TableHeaderColumn
                    dataAlign="left"
                    dataSort={true}
                    dataFormat={group}
                    width="200px"
                >
                    Group{" "}
                    <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    isKey={true}
                    dataAlign="left"
                    dataSort={true}
                    dataFormat={Teamlead}
                    width="200px"
                >
                    Team Lead{" "}
                    <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="Phone"
                    dataSort={true}
                    dataFormat={Phone}
                    width="200px"
                >
                    Phone
                    <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="Email"
                    dataSort={true}
                    dataFormat={Email}
                    width="250px"
                >
                    Email
                    <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="Address"
                    dataSort={true}
                    dataFormat={user}
                    width="150px"
                >
                    Users
                    <img className="ms-1" src={ShortIcon} alt="" />
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="Action"
                    dataAlign="right"
                    dataFormat={Remove}
                >
                    Action
                </TableHeaderColumn>
            </BootstrapTable>
        </div>

        {/* user list end */}
    </div>)
}

export default GroupTab