import React from "react";
import Sidebarmenu from "./sidebarmenu.js";
import Rightsidebar from './rightsidebar.js';

function Layout(props) {
  let sidebarProps = {
    ...props,
    menuCollapse: true
  }
  return (
    <>
      <div className="dashboard-page-wrapper">
        <Sidebarmenu  {...sidebarProps}/>
        <div className="main-content-wrapper">
        {props.children}
        </div>
       <Rightsidebar {...props}   />
      </div>
    </>
  );
}

export default Layout;
