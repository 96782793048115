import { io } from "socket.io-client";

let socket
const socketConnection = (userData) => {
    let userType = "ADMIN";
    if ((userData.user && userData.user.role === 2) || (userData.user && userData.user.subAdmin)) {
        userType = "USER"
    }
    else if (userData.user && userData.user.role === "public") {
        userType = "PUBLIC_USER"
    }
    socket = io(process.env.REACT_APP_SOCKET_URL, {
        query: {
            accessToken: userData.user.token,
            ...(userData.user.id && { id: userData.user.id }),
            userType
        },
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 2000,
        reconnectionAttempts: 10
    })

    console.log("socketsocketsocket", socket)
    return {
        socket
    }
};

const listenToEvent = (eventName, fn) => {
    if (!socket) return (true);
    let s = socket.on(eventName, (data) => {
        return fn(data);
    });
}

const cleanUpEvent = (eventName, fn) => {
    if (!socket) return (true);
    let s = socket.off(eventName, (data) => {
        return fn(data);
    });
}

const emitEvent = (eventName, data) => {
    if (socket) socket.emit(eventName, data);
}

export {
    socketConnection,
    listenToEvent,
    emitEvent,
    cleanUpEvent
};