import React, { Component } from "react";

class Page404 extends Component {

  render() {
    return (
      <>
         <h2 className="text-center"><span>Page404</span></h2>
      </>
    );
  }
}

export default Page404;