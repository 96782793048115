// TODO: Resolve lint issues
/* eslint-disable */
import { all, call } from 'redux-saga/effects';
import authSaga from './auth/saga';
import alertSaga from './alert/saga';
import extensionSaga from './extensions/saga';
import networkSaga from './network/saga';
import subscriptionSaga from './subscription/saga';
import teamSaga from './team/saga';
import hubSaga from './hub/saga';
import builderSaga from './builder/saga';
import userSaga from './user/saga';
import taskSaga from './task/saga'
import invoicingSaga from './invoicing/saga'

function* rootSaga() {
  yield all([
    call(authSaga),
    call(alertSaga),
    call(extensionSaga),
    call(networkSaga),
    call(taskSaga),
    call(subscriptionSaga),
    call(teamSaga),
    call(hubSaga),
    call(builderSaga),
    call(userSaga),
    call(invoicingSaga)
  ]);
}

export default rootSaga;
