import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Card, Row, Col, InputGroup, FormControl, Button, Dropdown, Modal, Container } from 'react-bootstrap';
import nameicon from '../../assets/image/nameicon.png';
import mailicon from '../../assets/image/mailicon.png';
import companyicon from '../../assets/image/companyicon.png';
import passwordicon from '../../assets/image/password.png';
import phoneicon from '../../assets/image/phoneicon.png';
import view from "../../assets/image/view.png";
import hide from "../../assets/image/hide.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import AuthService from '../../services/auth.service';
import { successAlert, errorAlert, infoAlert } from '../../services/toast.service';
import * as Yup from "yup";
const Personalinfo = ({ step, setStep, formData, setformData }) => {
    const continueHandler = () => {
        setStep(step + 1)
    }

    const initialValues = {
    };
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone Number is required"),
        companyName: Yup.string().required("Company name is required"),
        email: Yup.string().required().email("Please fill valid email address "),
        password: Yup.string().required("Password is required"),
    });

    const handleSubmit = async (formValue) => {
        console.log("signup next::: ", formValue)
        // check email is already taken or not 
        const response = await AuthService.checkEmailTaken(formValue)
        if (response && response.data && response.data.exist) {
            infoAlert(response.data.message)
            return
        }

        setformData(prev => {
            return { ...prev, ...formValue }
        })
        continueHandler()


    }

    const CustomPasswordField = ({ field, colors, form, ...props }) => {
        const [showHidePassword, changeShowHidePassword] = useState(false);

        return (
            <div className="input-container">
                <img className="password-icon" src={passwordicon} alt="passwordicon" />
                <input
                    type={showHidePassword ? "text" : "password"}
                    {...field}
                    className="custom-password"
                    name="password"
                    placeholder="Password"
                />
                <img className="eye-icon" onClick={(e) => { changeShowHidePassword(!showHidePassword) }} src={showHidePassword ? hide : view} alt="passwordicon" />
            </div>
        );
    }

    return (
        <div>
            <div className="signupinfo-form">
                <h1 className="heading">Create your Gomove account</h1>
                <h2 className="personal-info-subheading">Personal Info</h2>
                <div className="signup-form-box">
                    <Formik
                        initialValues={formData}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >{({ errors, touched }) => (<Form inline="true" >
                        <Row>
                            <Col xs={6}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="firstname"><img src={nameicon} alt="nameicon" /></InputGroup.Text>
                                    <Field className="form-control" placeholder="First Name" name="firstName" maxLength="15" aria-label="First Name" />
                                    <ErrorMessage name="firstName" component="div" />
                                </InputGroup>
                            </Col>
                            <Col xs={6}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="lastname"><img src={nameicon} alt="lasticon" /></InputGroup.Text>
                                    <Field className="form-control" placeholder="Last Name" name="lastName" maxLength="15" aria-label="Last Name" />
                                    <ErrorMessage name="lastName" component="div" />
                                </InputGroup>
                            </Col>
                            <Col xs={6}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="phonenumber"><img src={phoneicon} alt="phoneicon" /></InputGroup.Text>
                                    <Field className="form-control" placeholder="Phone Number" type="number" name="phoneNumber" aria-label="Phone Number" />
                                    <ErrorMessage name="phoneNumber" component="div" />

                                </InputGroup>
                            </Col>
                            <Col xs={6}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="yourworkemail"><img src={mailicon} alt="emailicon" /></InputGroup.Text>
                                    <Field className="form-control" placeholder="Your work email" name="email" aria-label="Your email" />
                                    <ErrorMessage name="email" component="div" />

                                </InputGroup>
                            </Col>
                            <Col xs={6}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="companyname"><img src={companyicon} alt="companyicon" /></InputGroup.Text>
                                    <Field className="form-control" placeholder="Company name" maxLength="25" name="companyName" aria-label="Company Name" />
                                    <ErrorMessage name="companyName" component="div" />
                                </InputGroup>
                            </Col>
                            <Col xs={6}>
                                {/* <InputGroup className="mb-3"> */}
                                {/* <InputGroup.Text id="createpassword"><img src={passwordicon} alt="passwordicon" /></InputGroup.Text> */}
                                <Field className="form-control" type="password" placeholder="Create Password" name="password" aria-label="Create Password" component={CustomPasswordField} />
                                {errors.password ? <div className={"error-message"} name="password" component="div" >
                                    {errors.password}
                                </div> : null}

                                {/* </InputGroup> */}
                            </Col>
                            <Col xs={12}>
                                <div className="continue-btn">
                                    <div className="submitbtn">
                                        <Button type="submit"   >Continue</Button>
                                        <p>This site is protected by reCAPTCHA and the Google Privacy Policy.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>)}

                    </Formik>

                </div>
            </div>
        </div>
    );
};

export default Personalinfo;