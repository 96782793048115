import {
    SAVE_USER_BUILDER_LIST
} from "./actionTypes";

let initialState = {
    userBuilderList: []
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER_BUILDER_LIST:
            let userBuilderList = JSON.parse(JSON.stringify(state.userBuilderList))
            userBuilderList = action.payload.result || []
            return {
                ...state,
                userBuilderList: userBuilderList
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;