import { MdOutlineAllInbox, MdOutlineClose } from "react-icons/md"
import { formatAMPM, renderCustomerName, timeZoneHeader } from "../../helpers/helperFunctions"
import TaskRightSideDetails from "../SupervisePlanner/components/Rightsidebar/RightSideTaskDetails"
import "./rightsidebar.scss"
import moment from "moment"
import { IoMdTime } from "react-icons/io"
const timezone = { ...timeZoneHeader() }

const RightSideBar = (props) => {
    const taskDetailProps = {
        taskDetail: props.taskDetail,
        setTaskDetail: props.setTaskDetail,
    }

    const Tasks = (task) => {
        return (<div onClick={(e) => {
            !props.enablePropagation && e.stopPropagation();
            props.setTaskDetail(task)
        }} className={`${"mission-task"} ${task?.taskStatus ? `title-${task?.taskStatus}` : ""} ${task?.active ? "selected" : ""} ${props.unassignedList ? "unassigned-task" : ""}`}>
            <div className="pic">
                <p>{task?.orderType}</p>
            </div>
            <div className="text">
                <div className="mission-task-top">
                    <div className="name-and-address">
                        <h4>{renderCustomerName(task)}</h4>
                        <p>{task?.address}</p>
                    </div>
                    {task?.missioned && <p className="time">{task?.eta ? task?.eta : ""}</p>}
                </div>
                <div className="mission-task-bottom">
                    {task?.merchantId && task?.merchantId.role === 4 && task?.merchantId.companyName && <div className="btn">
                        <MdOutlineAllInbox className='icon' />
                        <p>{`${task?.merchantId.companyName}`}</p>
                    </div>}
                    {task?.merchantId && task?.merchantId.role === 2 && task?.merchantId.companyName && <div className="btn">
                        <MdOutlineAllInbox className='icon' />
                        <p>{`${task?.merchantId.companyName}`}</p>
                    </div>}
                    {task?.scheduledTime && <div className="btn">
                        <IoMdTime className='icon' />
                        <p>{formatAMPM(task?.scheduledTimeSeconds)}</p>
                    </div>}
                    {(task?.after || task?.before) && <div className="btn">
                        <IoMdTime className='icon' />
                        <p>{`${task?.after ? formatAMPM(task?.afterSeconds) : ""}-${task?.before ? formatAMPM(task?.beforeSeconds) : ""}`}</p>
                    </div>}
                </div>
            </div>

        </div>)
    }

    return (<div className="right-sidebar-container">
        {!props.createTask && <div className="right-side-bar-wrapper">
            {props.taskDetail ? <TaskRightSideDetails {...taskDetailProps} /> : <>
                <div className="top">
                    <div className="close-btn">
                        <MdOutlineClose className="icon" onClick={() => { props.setRightSidebarOpen(false); props.rightSidebarOpenRef.current = false }} />
                    </div>
                </div>
                <div className="bottom">
                    {props.selectedCustomer && <div className="customer-name">
                        <span>{`${props.selectedCustomer.firstName} ${props.selectedCustomer.lastName ? props.selectedCustomer.lastName : ""}`}</span>
                    </div>}
                    <div className="task-list-wrapper">
                        {props.taskList?.map((i) => {
                            return (<div className="task-item">
                                <h2>{i._id ? moment(i._id).format('dddd, LL') : "New Tasks"}</h2>
                                {i.tasks?.map(task => { return (<>{Tasks(task)}</>) })}
                            </div>)
                        })}
                    </div>
                </div>
            </>}
        </div>}
    </div>)
}

export default RightSideBar