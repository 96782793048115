import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Card,
  Form as BootstrapForm,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
  NavDropdown,
} from "react-bootstrap";
import "./Import.scss";
import taskService from "../../services/task.service";
import fileDownload from 'js-file-download';
import { useDropzone } from 'react-dropzone'
import XLSX from "xlsx";
import closeicon from "../../assets/image/close-icon.png";
import uploading from "../../assets/image/importimg.png";
import importTaskMapping from "./importTaskMapping.json";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const ImportPopup = (props) => {
  const [file, setFile] = useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    let file = acceptedFiles[0];
    const reader = new FileReader()
    setFile(file);
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = (e) => {
      // console.log("reader.result", reader.result)
      // Do whatever you want with the file contents
      const binaryStr = reader.result;
      var workbook = XLSX.read(binaryStr, { type: "array", cellDates: true, dateNF: 'mm/dd/yyyy;@' });
      const wsname = workbook.SheetNames[0];
      const ws = workbook.Sheets[wsname];
      /* Convert array of arrays */
      var range = XLSX.utils.decode_range(ws['!ref']);
      for (var R = range.s.r; R <= range.e.r; ++R) {
        for (var C = range.s.c; C <= range.e.c; ++C) {
          var coord = XLSX.utils.encode_cell({ r: R, c: C }), cell = ws[coord];
          if (!cell || !cell.v) continue;
          // clean up raw value of string cells
          if (cell.t == 's') cell.v = cell.v.trim();
          // clean up formatted text
          if (cell.w) cell.w = cell.w.trim();
        }
      }
      const data = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, blankrows: false });

      console.log("workbookworkbook", data)
      setXLSXData(data)
      continueHandler()
    }
    reader.readAsArrayBuffer(file)
  }, [])

  const setXLSXData = (xlsData) => {
    let columnData = [];
    let defaultValue = importTaskMapping[0].value;
    let allData = [];
    let data = [];
    for (let i of xlsData) {
      if (i.length) {
        data.push(i)
      }
    }
    for (let key of data[0]) {
      let findValueFromDefaultColumns = importTaskMapping.find(i => i.name === key)
      let obj = {
        name: key,
        value: findValueFromDefaultColumns && findValueFromDefaultColumns.value ? findValueFromDefaultColumns.value : defaultValue,
        mapped: findValueFromDefaultColumns && findValueFromDefaultColumns.value ? true : false
      }
      columnData.push(obj)
    }

    for (let column in columnData) {
      columnData[column].data = []
      for (let i = 1; i < data.length; i++) {
        columnData[column].data.push(data[i][column])
      }
      allData.push(columnData[column])
    }
    props.setRows(Number(data.length - 1))
    // console.log(allData)

    // for (let i = 1; i < data.length; i++) {
    //   let obj = {};
    //   for (let j = 0; j < data[i].length; j++) {
    //     obj[columnData[j]] = data[i][j];
    //   }
    //   allData.push(obj);
    // }

    props.setImportData(allData)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  })

  const continueHandler = () => {
    props.setStep(props.step + 1)
  }

  const exportSampleTaskSheet = async () => {
    let response = await taskService.exportTaskSheet();
    console.log(response)
    if (response && response.data && response.status === 200) {
      fileDownload(response.data, "Sample Task Import.xlsx")
    }
    else {
      props.errorAlert("Something went wrong")
    }
  }

  return (
    <>
      <div className="openimport-popup" >
        <div className="addimport-popup">
          <div className="addimport-slide">

            <div className="customer-import">

              <Button
                className="import-close"
                onClick={() => props.setImportToggle(false)}
              >
                <img src={closeicon} alt="icon" />
              </Button>
            </div>
            <div className="addimport-filebox">
              <div className="customer-form">
                <div className="import-pheading">
                  <div className="import-heading">
                    <h2>Add multiple address at once</h2>
                    <p>You can upload any .csv, .tsv, .xls, .xlsx, .xml, .txt.</p>
                  </div>
                  <div className="import-breadcurm">
                    <div className="breadcurm">
                      <Link className="active">Upload</Link><MdOutlineArrowForwardIos />
                      <Link>Match</Link><MdOutlineArrowForwardIos />
                      <Link>Complete</Link>
                    </div>
                    <Button onClick={exportSampleTaskSheet}>Download sample import file</Button>
                  </div>
                </div>

                <div className="import-upload-img" {...getRootProps()}>
                  <div className="upload-img">
                    <img src={uploading} alt="img" />
                    <div className="upload-btn">
                      <input type="file" id="fileID" {...getInputProps()} />
                      <button className="btn">Import</button>
                    </div>
                    <div className="drag-upload">
                      <h2>{file ? `File name: ${file.name}` : "...or drag your file here!"}</h2>
                    </div>
                  </div>
                  {/* </FileUploader> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportPopup;
