import { useRef, useState } from "react";
import ContentLayout from "../../../components/contentlayout";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import { Loader } from "../../../components/Loader/loader";
import Customer from "../pages/customer";
import { useEffect, useLayoutEffect } from "react";
import authService from "../../../services/auth.service";
import { GET_COMMON_SERVICES } from "../../../redux/auth/actionTypes";


const initialValues = {
    currency: "CAD",
    companyName: "",
    isMerchant: false,
    displayName: "",
    email: "",
    countryCode: "+1",
    phoneNumber: "",
    website: "",
    nameOnCheque: "",
    businessNumber: "",
    shippingAddress: {
        sameAsBillingAddress: false,
        streetAddress: "",
        city: "",
        province: "",
        postalCode: "",
        country: ""
    },
    billingAddress: {
        streetAddress: "",
        city: "",
        province: "",
        postalCode: "",
        country: ""
    },
    taxId: "",
    tax: null,
    merchantInfo: {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        userRole: ""
    },
    taxValues: []
}

const MainContainer = () => {
    const ref = useRef();
    const dispatch = useDispatch();
    const { commonServices, countryCode, settings, loading } = useSelector(
        (state) => state.authSaga
    );
    const userData = useSelector((state) => state.auth.user);
    const [customerForm, setCustomerForm] = useState(initialValues)

    const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
    const [buttonText, setButtonText] = useState("Create");
    const [windowSize, setWindowSize] = useState(null);
    const rightSidebarOpenRef = useRef(false);
    const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
    const getWindowDimensions = async () => {
        const { innerWidth: width, innerHeight: height } = window;
        setWindowSize({ width, height })
        let rightsidebarWidth = 370;
        if (!rightSidebarOpenRef.current) rightsidebarWidth = 0
        let leftSidebarWidth = 270;
        if (!leftSidebarOpen) leftSidebarWidth = 70;
        let mapDiv = document.querySelector(".customer-page-wrapper");
        if (mapDiv) mapDiv.style.width = `${width - rightsidebarWidth - leftSidebarWidth}px`;
        if (!rightSidebarOpenRef.current) {
            let rightSideBarWrapper = document.querySelector(".right-side-bar-wrapper");
            if (rightSideBarWrapper) {
                rightSideBarWrapper.style.display = "none"
            };
            let rightContainer = document.querySelector(".right-sidebar-customer-container")
            if (rightContainer) {
                rightContainer.style.width = "20px";
                rightContainer.style.background = "transparent";
            }
            if (document.querySelector(".side-bar-right-arrow-btns"))
                document.querySelector(".side-bar-right-arrow-btns").style.left = "2px";
            // let taskBarRight = document.querySelector(".task-bar-right")
            // if (taskBarRight) taskBarRight.style.display = "none";
        }
        else {
            let rightSideBarWrapper = document.querySelector(".right-side-bar-wrapper");
            if (rightSideBarWrapper) {
                rightSideBarWrapper.style.display = "flex"
            };
            let rightContainer = document.querySelector(".right-sidebar-customer-container")
            if (rightContainer) {
                if (window.location?.pathname?.toLowerCase() === "/invoice") {
                    document.querySelector(".right-sidebar-customer-container").style.background = "#272735";
                }
                else
                    document.querySelector(".right-sidebar-customer-container").style.background = "#39394b";
                document.querySelector(".right-sidebar-customer-container").style.width = "370px";

            }
            if (document.querySelector(".customer-form")) {
                document.querySelector(".customer-form").style.maxHeight = `${height - 120}px`;
            }
        }
    }

    useEffect(() => {
        window.addEventListener("resize", getWindowDimensions);
        // Call handler right away so state gets updated with initial window size
        getWindowDimensions();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", getWindowDimensions);
    }, [leftSidebarOpen, rightSidebarOpen])

    const createCustomerToggle = (page) => {
        setRightSidebarOpen(!rightSidebarOpen)
        rightSidebarOpenRef.current = !rightSidebarOpenRef.current;
    };

    const leftSidebarProps = {
        leftSidebarOpen, setLeftSidebarOpen,
        setRightSidebarOpen, rightSidebarOpen
    }

    const customerProps = {
        createCustomerToggle,
        rightSidebarOpenRef,
        rightSidebarOpen,
        windowSize, setWindowSize,
        buttonText, setButtonText,
        customerForm, setCustomerForm,
        initialValues
    }

    const rightsidebarprops = {
        createCustomerToggle,
        setRightSidebarOpen,
        rightSidebarOpenRef,
        windowSize,
        buttonText, setButtonText,
        customerForm, setCustomerForm,
        initialValues
    }

    return (
        <>
            <ContentLayout {...leftSidebarProps}>
                <div className="main-control-container" ref={ref}>
                    {window.location?.pathname?.toLowerCase() === "/customer" && <Customer {...customerProps} rightsidebarprops={rightsidebarprops} />}
                </div>
                <ToastContainer
                    style={{ color: "black" }}
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    // closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                {loading ? <Loader /> : null}
            </ContentLayout>
        </>
    );
};

export default MainContainer;
