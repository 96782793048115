import { MdOutlineAllInbox, MdOutlineClose } from "react-icons/md"
import { formatAMPM, renderCustomerName, timeZoneHeader } from "../../../helpers/helperFunctions"
import TaskRightSideDetails from "../../SupervisePlanner/components/Rightsidebar/RightSideTaskDetails"
import "./createInvoice.scss"
import moment from "moment"
import { IoMdTime } from "react-icons/io"
import bin from "../../../assets/image/bin-white.png";
import downicon from "../../../assets/image/down-icon-white.png";
import { Formik, Form as FormF, Field, ErrorMessage, FieldArray } from "formik"
import { Button, Form } from "react-bootstrap"
import Select, { components } from 'react-select';
import { useRef } from "react"
import { useState } from "react"
import { useEffect } from "react"
import DatePicker from "react-date-picker"
import momentTz from "moment-timezone"
import { useDispatch, useSelector } from "react-redux"
import { DELETE_COMMON_SERVICES_API, SAVE_COMMON_SERVICES_API } from "../../../redux/auth/actionTypes"
import * as Yup from "yup"
import { CREATE_INVOICE_API, CREATE_PAYMENT_API, EDIT_INVOICE_API, EDIT_PAYMENT_API, GET_PAYMENT_API } from "../../../redux/invoicing/actionTypes"
import { errorAlert, successAlert } from "../../../services/toast.service"

const timezone = { ...timeZoneHeader() }

const taxSchema = Yup.object().shape({
    name: Yup.string().required("Name Required")
});

const initialValuesPaymentForm = {
    name: ""
}

const PaymentForm = (props) => {
    const dispatch = useDispatch();
    const { setInvoiceForm, rightsidebarprops, invoiceFormProps, setTaskValues } = props;
    const { customerList, pageCount, pageLimit, paymentList } = useSelector((state) => state.invoicing)

    const { commonServices } = useSelector((state) => state.authSaga)
    const [allPaymentMethods, setAllPaymentMethods] = useState([])
    const [allDepositMethods, setAllDepositMethods] = useState([])
    const [addNewPaymentMethod, setAddNewPaymentMethod] = useState(false)
    const [addNewDepositMethod, setAddNewDepositMethod] = useState(false)
    const [topTermForm, setTopTermForm] = useState(0)
    const [TermForm, setTermForm] = useState(0)

    const { teamList } = useSelector((state) => state.team)
    let { agentList } = useSelector((state) => state.user);

    const ValueContainer = ({ children, ...props }) => {
        return (
            components.ValueContainer && (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            )
        );
    };

    const CustomMenuList = ({ selectProps, ...props }) => {
        const { onTeamInputChange, addTeamInputValue, onMenuInputFocus, addInput, setAddInput, setAddNew } = selectProps;
        // Copied from source
        const ariaAttributes = {
            "aria-autocomplete": "list",
            "aria-label": selectProps["aria-label"],
            "aria-labelledby": selectProps["aria-labelledby"]
        };

        return (
            <div>
                <div
                    className="MenuButton"
                    onClick={(e) => {
                        setTopTermForm(e.clientY)
                        if (addInput === "PAYMENT_METHOD") {
                            setAddNewPaymentMethod(true)
                        }
                        else {
                            setAddNewDepositMethod(true)
                        }
                    }}
                >
                    {/* <img style={{ width: "18px" }} src={"Union"} alt="icon" /> */}
                    <span className={"AddTeamSpan"}>+ Add new</span>
                </div>
                <components.MenuList {...props} selectProps={selectProps} />
            </div >
        );
    };

    const validate = (values, props /* only available when using withFormik */) => {
        const errors = {};
        if (!values.paymentMethodId) {
            errors.paymentMethodId = 'Select valid payment method';
        }
        else if (!values.depositedToId) {
            errors.depositedToId = 'Select valid account';
        }
        //...
        console.log("errors", errors, values)
        return errors;
    };
    const handleSubmitTaxForm = (data) => {
        let type = "DEPOSIT_METHOD"
        if (addNewPaymentMethod) type = "PAYMENT_METHOD"
        let dataForApi = {
            type: type,
            ...data
        };
        let dataApi = {
            payload: dataForApi,
            // resCall: (res) => { handleSaveCommonServiceResponse(res) }
        }
        dispatch({ type: SAVE_COMMON_SERVICES_API, data: dataApi })
    }

    const CustomSelectField = ({ field, colors, form, ...props }) => {
        const containerRef = useRef(null);
        const [isFocused, setIsFocused] = useState(false);
        const [inputValue, setInputValue] = useState("");
        const [addInput, setAddInput] = useState("PAYMENT_METHOD");

        const onDomClick = (e) => {
            let menu = containerRef.current.querySelector(".select__menu");
            if (
                !containerRef.current.contains(e.target) ||
                !menu ||
                !menu.contains(e.target)
            ) {
                setIsFocused(false);
                setInputValue("");
            }
        };

        useEffect(() => {
            document.addEventListener("mousedown", onDomClick);
            return () => {
                document.removeEventListener("mousedown", onDomClick);
            };
        }, []);

        return (
            <div ref={containerRef}>
                <Select
                    className="react-tax-select-container  select-container"
                    classNamePrefix="react-tax-select"
                    closeMenuOnSelect={true}
                    formProps={{ form }}
                    // defaultValue={allPaymentMethods[0]}
                    value={form.values["paymentMethod"]}
                    placeholder="Select payment method"
                    // isMulti
                    menuIsOpen
                    autoFocus={false}
                    isSearchable={false}
                    addInput={addInput}
                    options={getPaymentOptions()}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => option.id}
                    onChange={(newValue, actionMeta) => {
                        const { action, option, removedValue } = actionMeta;
                        form.setFieldValue("paymentMethodId", newValue.id)
                        form.setFieldValue("paymentMethod", JSON.parse(JSON.stringify(newValue)))
                    }}
                    components={{
                        MenuList: CustomMenuList,
                        ValueContainer: ValueContainer,
                        Option: CustomTermSelectOption
                    }}
                    {...{
                        menuIsOpen: isFocused || undefined,
                        isFocused: isFocused || undefined
                    }}
                />
            </div>
        );
    };

    const CustomTermSelectField = ({ field, colors, form, ...props }) => {
        const containerRef = useRef(null);
        const [isFocused, setIsFocused] = useState(false);
        const [inputValue, setInputValue] = useState("");
        const [addInput, setAddInput] = useState("DEPOSIT_METHOD");

        const onDomClick = (e) => {
            let menu = containerRef.current.querySelector(".select__menu");
            if (
                !containerRef.current.contains(e.target) ||
                !menu ||
                !menu.contains(e.target)
            ) {
                setIsFocused(false);
                setInputValue("");
            }
        };

        useEffect(() => {
            document.addEventListener("mousedown", onDomClick);
            return () => {
                document.removeEventListener("mousedown", onDomClick);
            };
        }, []);

        return (
            <div ref={containerRef}>
                <Select
                    className="react-tax-select-container"
                    classNamePrefix="react-tax-select"
                    closeMenuOnSelect={true}
                    formProps={{ form }}
                    // defaultValue={allTerms[0]}
                    value={form.values["depositedTo"]}
                    placeholder="Select an account"
                    // isMulti
                    menuIsOpen
                    autoFocus={false}
                    addInput={addInput}
                    isSearchable={false}
                    options={getOptions()}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => option.id}
                    onChange={(newValue, actionMeta) => {
                        const { action, option, removedValue } = actionMeta;
                        form.setFieldValue("depositedToId", newValue.id);
                        form.setFieldValue("depositedTo", JSON.parse(JSON.stringify(newValue)));
                    }}
                    components={{
                        MenuList: CustomMenuList,
                        ValueContainer: ValueContainer,
                        Option: CustomTermSelectOption
                    }}
                    {...{
                        menuIsOpen: isFocused || undefined,
                        isFocused: isFocused || undefined
                    }}
                />
            </div>
        );
    };

    const handleDeleteCommonService = (id) => {
        let dataApi = {
            payload: {
                commonServiceId: id
            }
        }
        dispatch({ type: DELETE_COMMON_SERVICES_API, data: dataApi })
    }

    const CustomTermSelectOption = props => (
        <components.Option {...props}>
            <div>
                {props.data.name}{props.data.id !== "all" && <img onClick={(e) => { e.stopPropagation(); handleDeleteCommonService(props.data.id) }} src={bin} style={{ width: "18px", float: "right", cursor: "pointer" }} alt="icon" />}
            </div>
        </components.Option>
    )
    useEffect(() => {
        let paymentMethods = [];
        paymentMethods = commonServices?.filter(cm => cm.type === "PAYMENT_METHOD")
        setAllPaymentMethods(paymentMethods)

        let depositedTo = [];
        depositedTo = commonServices?.filter(cm => cm.type === "DEPOSIT_METHOD")
        setAllDepositMethods(depositedTo)
        // let newFormData = { ...props.invoiceForm }
        // newFormData.termId = taxes[0]?.id;
        // newFormData.terms = taxes[0];
        // let date = moment(newFormData.invoiceDate).add(Number(taxes[0].days), "day")
        // newFormData.dueDate = date;
        // props.setInvoiceForm(newFormData)
    }, [commonServices])

    const getOptions = () => [...allDepositMethods];
    const getPaymentOptions = () => [...allPaymentMethods];

    let taxFormRef = useRef();

    const CreateTaxForm = () => {
        let addNewMethod = setAddNewDepositMethod;
        if (addNewPaymentMethod) {
            addNewMethod = setAddNewPaymentMethod
        }
        let addNewText = "payment method";
        if (addNewDepositMethod) {
            addNewText = "deposit method"
        }
        return (
            <Formik
                initialValues={initialValuesPaymentForm}
                validationSchema={taxSchema}
                // validate={taxValidate}
                onSubmit={handleSubmitTaxForm}
                enableReinitialize={true}
            >{({
                setFieldValue,
                setFieldTouched,
                values,
                resetForm,
                errors,
                touched,
            }) => (
                <div className="tax-form" style={{ top: `${topTermForm}px` }} ref={taxFormRef}>
                    <FormF>
                        <div className="sub-heading">
                            <span className="text">Add new {addNewText}</span>
                        </div>
                        <Form.Group className="input-invoice-container">
                            <label>Name</label>
                            <Field
                                className="input-area"
                                placeholder=""
                                name={"name"}
                                maxLength="35"
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                aria-label="Group Name"
                            />
                            {errors.name && touched.name ? (
                                <div>{errors.name}</div>
                            ) : null}
                        </Form.Group>
                        <div className="buttons">
                            <Button onClick={() => {
                                setTermForm(initialValuesPaymentForm)
                                addNewMethod(false)
                            }}>
                                Cancel
                            </Button>
                            <Button type="submit" onClick={(e) => { }}>
                                {"Create"}
                            </Button>
                        </div>
                    </FormF>
                </div>
            )}
            </Formik >
        );
    }


    const handleSubmit = (formValues) => {
        delete formValues.depositedTo.userId
        delete formValues.paymentMethod.userId
        delete formValues.invoiceDetails
        let apiData = {
            amount: formValues.amount,
            paymentDate: momentTz(formValues.paymentDate).tz(timezone.timezone).startOf("D")._d,
            depositedTo: formValues.depositedTo,
            depositedToId: formValues.depositedToId,
            paymentMethod: formValues.paymentMethod,
            paymentMethodId: formValues.paymentMethodId,
            invoiceId: formValues.invoiceId,
            invoiceCustomerId: formValues.invoiceCustomerId
        }
        if(formValues.id){
            apiData.paymentId = formValues.id
        }
        let apiType = CREATE_PAYMENT_API;
        if (props.buttonText === "Edit") {
            console.log("EditEditEdit", )
            apiType = EDIT_PAYMENT_API
        }
        dispatch({
            type: apiType,
            data: {
                payload: apiData,
                resCall: (res) => {
                    if (!res.success) {
                        errorAlert(res.message || "Something went wrong")
                    }
                    else {
                        props.setButtonText("Create and close");
                        // if (props.buttonText !== "Create and new") props.setCreateInvoice(false);
                        // if (props.buttonText !== "Create and send") {
                        //     successAlert(`${formValues.invoiceNumber} sent successfully`)
                        // }
                        props.setCreateInvoice(false);
                        props.setShowPaymentForm(false);
                        props.setPaymentForm(props.initialValues);
                    }
                },
                sortBy: props.sortBy,
                pageLimit: pageLimit,
                pageCount: pageCount,
                currentPage: props.currentPage
            }
        })
    }

    const calculateAmount = () => {
        let amountRecieved = paymentList?.reduce((curr, next) => {
            return Number(curr) + Number(next.amount)
        }, 0)
        let amountRemaining = Number(props.paymentForm?.invoiceDetails?.amount) - amountRecieved
        return {
            amountRecieved,
            amountRemaining
        }
    }

    return (
        <div className="invoice-form-container">
            <Formik
                initialValues={props?.paymentForm}
                // validationSchema={validationSchema}
                validate={validate}
                onSubmit={handleSubmit}
                enableReinitialize={false}
            >{({
                setFieldValue,
                setFieldTouched,
                values,
                resetForm,
                errors,
                touched,
            }) => (
                <FormF>
                    <Form.Group className="input-container">
                        <label>Payment</label>
                        <Field
                            className="input-area"
                            placeholder=""
                            name="amount"
                            maxLength="35"
                            type={"number"}
                            step={"any"}
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            aria-label="Payment"
                        />
                        <ErrorMessage className="error-message-invoice"
                            name="amount"
                            component="div"
                        />
                    </Form.Group>
                    <div className="two-inputs">
                        <div className="input-container">
                            <label>Payment date</label>
                            {/* <div className="input-area"> */}
                            <DatePicker
                                dateFormat="MM/dd/yyyy"
                                name={"paymentDate"}
                                value={values["paymentDate"] ? new Date(values["paymentDate"]) : ""}
                                onChange={(e) => {
                                    setFieldValue("paymentDate", e);
                                }}
                                selectsEnd
                            />
                        </div>
                        <div className="input-container">
                            <label>Payment method</label>
                            <Field
                                className="form-control  select-container"
                                name="paymentMethod"
                                // as="select"
                                component={CustomSelectField}
                            >
                            </Field>
                            <>{errors.paymentMethodId ? (
                                <div>{errors.paymentMethodId}</div>
                            ) : null}</>
                        </div>
                    </div>
                    <div className="two-inputs">
                        <Form.Group className="input-container">
                            <label>Reference number</label>
                            <Field
                                className="input-area"
                                placeholder=""
                                name="referenceNumber"
                                maxLength="35"
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                aria-label="Reference number"
                            />
                            <ErrorMessage className="error-message-invoice"
                                name="referenceNumber"
                                component="div"
                            />
                        </Form.Group>
                        <div className="input-container">
                            <label>Deposit to</label>
                            <Field
                                className="form-control"
                                name="depositTo"
                                // as="select"
                                component={CustomTermSelectField}
                            >
                            </Field>
                            <>{errors.depositedToId ? (
                                <div>{errors.depositedToId}</div>
                            ) : null}</>
                        </div>
                    </div>
                    <div className="buttons">
                        <Button onClick={() => {
                            props.handleCancel()
                        }}>
                            Cancel
                        </Button>
                        <div className="submit-dd-button">
                            <Button type='submit'>
                                {props.buttonText}
                            </Button>
                        </div>
                    </div>
                </FormF>
            )}
            </Formik>
            <div className="account-balance">
                <div className="amount-received">
                    <span>
                        Amount Received
                    </span>
                    <span>
                        ${calculateAmount().amountRecieved}
                    </span>
                </div>
                <div className="sub-sections-received">
                    <span>
                        DUE DATE
                    </span>
                    <span>
                        {momentTz(props.paymentForm?.invoiceDetails?.dueDate).format("LL")}
                    </span>
                </div>
                <div className="sub-sections-received">
                    <span>
                        ORIGINAL AMOUNT
                    </span>
                    <span>
                        ${props.paymentForm?.invoiceDetails?.amount}
                    </span>
                </div>
                <div className="sub-sections-received">
                    <span>
                        OPEN BALANCE
                    </span>
                    <span>
                        ${calculateAmount().amountRemaining}
                    </span>
                </div>
            </div>
            {paymentList?.length > 0 && <div className="payment-history">
                <div className="amount-received">
                    <span>
                        Payment history
                    </span>
                </div>
                {paymentList.map((payment, index) => {
                    return (<div className="history">
                        <div className="sub-sections-received">
                            <span>
                                PAYMENT DATE
                            </span>
                            <span>
                                {momentTz(payment.paymentDate).format("LL")}
                            </span>
                        </div>
                        <div className="sub-sections-received">
                            <span>
                                PAYMENT AMOUNT
                            </span>
                            <span>
                                ${payment.amount}
                            </span>
                        </div>
                        <div className="sub-sections-received">
                            <span>
                                PAYMENT METHOD
                            </span>
                            <span>
                                {payment.paymentMethod.name}
                            </span>
                        </div>
                        <div className="sub-sections-received">
                            <span>
                                DEPOSIT TO
                            </span>
                            <span>
                                {payment.depositedTo.name}
                            </span>
                        </div>
                    </div>)
                })}
            </div>}
            {(addNewPaymentMethod || addNewDepositMethod) && <CreateTaxForm />}
        </div>
    );
}


export default PaymentForm